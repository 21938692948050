import React from 'react';
import { Segment, Grid } from 'semantic-ui-react'
import { Trans } from 'react-i18next';

// stateless component
const ProductToSMessage = (
  {
    centered
  }
) => {
  if (centered) {
    return (
      <Grid>
        <Grid.Row>
          <Segment basic>
            <Trans i18nKey='BuySubscriptionToS&PPMessage'>
              <span style={{ fontSize: '0.8em' }}>
                Buying a subscription from Betareader.io indicates that you have read and accept our
                <a href='https://betareader.io/tos/'>
                  {' '}
                  terms of service
                </a>
                {' '}
                &
                {' '}
                <a href='https://betareader.io/privacy/'>
                  privacy policy
                </a>
              </span>
            </Trans>
          </Segment>
        </Grid.Row>
      </Grid>
    );
  }
  return (
    <Segment basic>
      <Trans i18nKey='BuySubscriptionToS&PPMessage'>
        <span style={{ fontSize: '0.8em' }}>
          Buying a subscription from Betareader.io indicates that you have read and accept our
          <a href='https://betareader.io/tos/'>
            {' '}
            terms of service
          </a>
          {' '}
          &
          {' '}
          <a href='https://betareader.io/privacy/'>
            privacy policy
          </a>
        </span>
      </Trans>
    </Segment>
  );
};

export default (ProductToSMessage);
