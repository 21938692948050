import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, List, Icon
} from 'semantic-ui-react';
import moment from 'moment';

// i18n
import { withTranslation } from 'react-i18next';

// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  sendReadingReminder
} from '../../../modules/reader';

export class InActiveReadersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.readerEntries.length === 0) {
      this.props.onClose();
    }
  }

  // custom methods
  sendReminder = (reader) => {
    const {idToken} = this.props;
    this.props.sendReadingReminder(idToken, reader);
  }

  // rendering
  render() {
    const { readerEntries, readOnly, t } = this.props;
    const threeDaysAgo = new Date();
    threeDaysAgo.setDate(threeDaysAgo.getDate()-3);
    return (
      <List selection>
        {
          (readerEntries || []).map((reader, index) => {
            const allowReminding = (reader.invitation.remindedAt === undefined || new Date(reader.invitation.remindedAt) < threeDaysAgo);
            return (
              <List.Item key={reader._id}>
                <List.Content floated='right' verticalAlign='middle'>
                  <Button
                    disabled={!allowReminding || readOnly}
                    loading={reader.updating}
                    compact
                    onClick={() => {this.sendReminder(reader)}}>
                    <Icon name='mail'/> {t('SendReminder')}
                  </Button>
                  {
                    !allowReminding &&
                    <div style={{fontSize:'0.8em',maxWidth:150}}>{t('YouCanSendAReminderEvery3Days')}</div>
                  }
                </List.Content>
                <List.Header>
                  {reader.name}
                </List.Header>
                <List.Content>
                  <div style={{fontSize:'0.9em'}}>
                    <div>
                      {t('LastSeenAt')} <strong>{reader.readingData.lastSeenAtPartTitle}</strong> {moment(reader.readingData.lastSeenAtDate || reader.createdAt).fromNow()}
                      <br/>
                    </div>
                    {
                      reader.remindedAt &&
                      <div>
                        <span>{t('ReminderSentAt')}: {moment(reader.invitation.remindedAt).fromNow()}</span>
                      </div>
                    }
                  </div>
                </List.Content>
              </List.Item>
            );
          })
        }
    </List>
    )

  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  currentBook: state.book.currentBook,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  sendReadingReminder,
}, dispatch)

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(InActiveReadersList)));
