import React, {useRef, useState, useEffect} from 'react';

import {useHistory} from 'react-router-dom';
import {Icon, Header, Dimmer} from 'semantic-ui-react';
import ReactDOMServer from 'react-dom/server';
import DropzoneComponent from 'react-dropzone-component';
import {toast} from 'react-toastify';

// redux
import {useDispatch, useSelector} from 'react-redux';

// i18n
import {useTranslation} from 'react-i18next';

import './dropzone.css';
import ChapterFilePostProcessor from './chapterFilePostProcessor';
import ux from '../../../../utils/uxAnalyticsUtil';
import authUtil from '../../../../utils/authUtil';

import {addChapters, createBookAndChapters} from '../../../../modules/book';
import {PostProcessingContextProvider} from './postProcessingContext';

let dropzone;

const ChapterFileUpload = ({
  uploadHeader,
  uploadMessage,

  onWaitingForFile,
  onClose,
  createBook
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const toastId = useRef(null);

  const mountTimestamp = useRef(Date.now());
  const uploadMessageTimeout = useRef(0);

  const currentBook = useSelector(state => state.book.currentBook);
  const currentContentVersion = useSelector(
    state => state.book.currentContentVersion
  );
  const userProfile = useSelector(state => state.user.userProfile);
  const activeAccount = useSelector(state => state.account.activeAccount);

  const [uploadState, setUploadState] = useState();
  const [localUploadMessage, setLocalUploadMessage] = useState();
  const [documentData, setDocumentData] = useState();
  const [currentDocumentData, setCurrentDocumentData] = useState();

  useEffect(() => {
    mountTimestamp.current = Date.now();
    if (onWaitingForFile) {
      onWaitingForFile();
    }
    return () => {
      clearTimeout(uploadMessageTimeout.current);
    };
  }, []);

  const onProcessing = () => {
    setUploadState('PROCESSING');
    uploadMessageTimeout.current = setTimeout(() => {
      setLocalUploadMessage(t('ThisMightTakeAWhile'));
    }, 3000);
  };

  const handleUploadedDocumentData = documentData => {
    clearTimeout(uploadMessageTimeout.current);
    setDocumentData(documentData);
    setCurrentDocumentData(documentData);
    setUploadState(undefined);
  };

  const convertManuscript = async file => {
    console.log('converting manuscript', file);
    const postData = new FormData();
    postData.append('file', file);
    try {
      const fileEnding = file.name.split('.').pop();
      const response = await fetch(
        `${
          process.env.NODE_ENV === 'development'
            ? `${process.env.REACT_APP_FUNCTIONS_HOST}/func`
            : ''
        }/func/convert?format=${fileEnding}`,
        {
          method: 'POST',
          body: postData
        }
      );
      if (response.ok) {
        const chapters = await response.json();
        return chapters;
      }
      const {msg} = await response.json();
      toast.error(msg);
      throw new Error(msg);
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const enrichPart = async (part, index) => {
    try {
      const response = await fetch(
        `${
          process.env.NODE_ENV === 'development'
            ? `${process.env.REACT_APP_FUNCTIONS_HOST}/func`
            : ''
        }/func/enrich`,
        {
          method: 'POST',
          body: JSON.stringify({
            ...part,
            title: part.title || `${t('Chapter')} ${index + 1}`
          })
        }
      );
      const enrichedPart = await response.json();
      return enrichedPart;
    } catch (e) {
      console.error('failed to enrich part', e);
      return false;
    }
  };

  const cancelUpload = () => {
    const timeFromMountToCancel = mountTimestamp.current - Date.now();
    setDocumentData(undefined);
    setCurrentDocumentData(undefined);
    ux.timing({
      category: ux.categories.UPLOAD,
      variable: 'mount-to-cancel',
      value: timeFromMountToCancel, // in milliseconds
      label: 'Upload'
    });
    ux.trackEvent({
      category: ux.categories.UPLOAD,
      action: 'Cancelled upload'
    });
    if (onClose) {
      onClose();
    }
  };

  const submitParts = async () => {
    ux.trackEvent({
      category: 'Imports',
      action: 'clicked-complete-upload'
    });
    const timeFromMountToUpload = mountTimestamp.current - Date.now();
    ux.timing({
      category: ux.categories.UPLOAD,
      variable: 'mount-to-upload',
      value: parseInt(timeFromMountToUpload, 10), // in milliseconds
      label: 'Upload'
    });

    toastId.current = toast.info(t('ImportStarted'), {autoClose: false});
    onProcessing();
    submitPart(
      {
        parts: currentDocumentData.parts,
        currentIndex: 0,
        book: createBook ? null : currentBook,
        contentVersionId: createBook ? null : currentContentVersion._id
      },
      function done({book, contentVersionId, partsAdded}) {
        toast.update(toastId.current, {
          render: t('Done'),
          type: toast.TYPE.INFO,
          autoClose: 5000
        });
        switch (createBook) {
          case false:
            // Find the new version number and navigate to it
            const versionNumber =
              currentBook.content.findIndex(v => v._id === contentVersionId) +
              1;
            history.push(`/books/${book._id}/content/v${versionNumber}`);
            break;
          case true:
          default:
            // Navigate to the new book
            history.push(`/books/${book._id}`);
            break;
        }
      }
    );
  };

  const submitPart = async (
    {parts, currentIndex, book, contentVersionId},
    done
  ) => {
    // get enriched part data
    const currentPart = parts[currentIndex];
    const idToken = await authUtil.getFreshIdToken();
    if (!currentPart) {
      if (!!book && !!contentVersionId) {
        done({
          book,
          contentVersionId,
          partsAdded: currentIndex
        });
      }
      return;
    }
    toast.update(toastId.current, {
      render: t('ImportingChapterXofY', {
        _x: currentIndex + 1,
        _y: parts?.length ?? 0
      }),
      type: toast.TYPE.INFO
    });
    const enrichedPart = await enrichPart(currentPart, currentIndex);
    if (!enrichedPart) {
      // failed to enrich - abort here
      toast.error(t('SomethingWentWrong'));
      return;
    }
    // succeeded with enrich part, proceed to creating book or appending chapters
    if (currentIndex === 0 && book === null) {
      // 1st chapter and no book - create book
      const bookData = {
        ...currentDocumentData,
        parts: [enrichedPart]
      };
      // Create book and chapters
      dispatch(
        createBookAndChapters(
          idToken,
          {
            ...bookData,
            accountId: activeAccount
              ? activeAccount._id
              : userProfile.account._id
          },
          createdBook => {
            // move on to submit the next part
            submitPart(
              {
                parts,
                currentIndex: currentIndex + 1,
                book: createdBook,
                contentVersionId: createdBook.content[0]._id
              },
              done
            );
          }
        )
      );
    } else {
      // a book already exists, append chapters to it instead of creating a new one
      dispatch(
        addChapters(
          idToken,
          book._id,
          contentVersionId,
          [enrichedPart],
          newChapters => {
            // move on to submit the next part
            submitPart(
              {
                parts,
                currentIndex: currentIndex + 1,
                book,
                contentVersionId
              },
              done
            );
          }
        )
      );
    }
  };

  return (
    <DropzoneComponent
      config={{
        iconFiletypes: ['.docx', '.pdf'],
        showFiletypeIcon: false,
        multiple: false,
        postUrl: 'nourl'
      }}
      eventHandlers={{
        init: dropzoneComponent => {
          dropzone = dropzoneComponent;
        },
        accept: (file, done) => {
          console.log('file', file);
          done();
        },
        addedfile: async file => {
          onProcessing();
          const documentData = await convertManuscript(file);
          handleUploadedDocumentData(documentData);
          dropzone.removeFile(file);
        },
        processing: () => {},
        uploadprogress: () => {},
        complete: () => {}
      }}
      djsConfig={{
        createImageThumbnails: false,
        acceptedFiles:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf',
        autoProcessQueue: false,
        dictDefaultMessage: uploadMessage,
        params: {
          name: 'file'
        },
        headers: {},
        previewTemplate: ReactDOMServer.renderToStaticMarkup(
          <div
            className='dz-preview dz-file-preview'
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
            <div>
              <Icon size='huge' name='spinner' loading />
            </div>
            <div className='dz-error-message'>
              <span data-dz-errormessage='true'></span>
            </div>
          </div>
        )
      }}>
      <Header icon className='dz-message'>
        <Icon name='upload' />
        {uploadHeader && <span>{uploadHeader}</span>}
        {(localUploadMessage || uploadMessage) && (
          <p style={{fontSize: '0.7em'}}>
            <span>{localUploadMessage || uploadMessage}</span>
          </p>
        )}
      </Header>
      {
        // we are uploading - block the background elements
        uploadState === 'PROCESSING' && (
          <Dimmer active page>
            <Header as='h2' icon inverted>
              <Icon name='spinner' loading />
              {t('Processing')}
              {(localUploadMessage || uploadMessage) && (
                <p style={{fontSize: '0.6em'}}>{t('ThisMightTakeAWhile')}</p>
              )}
            </Header>
          </Dimmer>
        )
      }
      {
        // received the document data. switch to post processor.
        documentData && (
          <PostProcessingContextProvider
            initialValue={documentData}
            onUpdate={newDocumentData =>
              setCurrentDocumentData(newDocumentData)
            }>
            <ChapterFilePostProcessor
              documentData={documentData}
              onCancelClicked={cancelUpload}
              onCompleteClicked={submitParts}
            />
          </PostProcessingContextProvider>
        )
      }
    </DropzoneComponent>
  );
};

export default ChapterFileUpload;
