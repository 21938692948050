import React, { Component } from 'react';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import {
  Segment, Container, Button, Header, Dimmer, Loader
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';

// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import uxAnalyticsUtil from '../../utils/uxAnalyticsUtil';

export class AcceptCollaboratorInvitation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { history } = this.props;
    uxAnalyticsUtil.trackPageView(history.location.pathname);
    this.fetchInvitation();
  }

  componentWillUnmount() {

  }

  fetchInvitation = async () => {
    const { idToken, t, location } = this.props;
    const parsedQueryString = queryString.parse(location.search);
    const { token, bookId } = parsedQueryString;

    try {
      this.setState({
        loading: true
      });
      fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/invitations/${token}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'br-token': idToken
          }
        })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error();
        })
        .then((invitation) => {
          this.setState({
            invitation
          })
        })
        .catch((err) => {
          uxAnalyticsUtil.trackException({
            description: err.message,
            fatal: true
          });
          toast.error(t('SomethingWentWrong'));
        })
        .finally(() => {
          this.setState({
            loading: false
          });
        });
    } catch (e) {
      toast.error(t('SomethingWentWrong'));
    }
  }

  acceptInvitation = () => {
    const {
      t, idToken, history
    } = this.props;
    const { invitation } = this.state;
    try {
      this.setState({
        isAcceptingInviation: true
      });
      fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${invitation.book._id}/accept-invitation`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'br-token': idToken
          },
          body: JSON.stringify({
            token: invitation.token
          })
        })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error();
        })
        .then(() => {
          toast.info(t('InvitationAccepted'));
          history.replace(`/books/${invitation.book._id}`);
        })
        .catch(() => {
          toast.error(t('SomethingWentWrong'));
        })
        .finally(() => {
          this.setState({
            isAcceptingInviation: false
          });
        });
    } catch (e) {
      toast.error(t('SomethingWentWrong'));
      uxAnalyticsUtil.trackException({
        description: e.message,
        fatal: true
      });
      this.setState({
        isAcceptingInviation: false
      });
    }
  }

  render() {
    const { userProfile, t } = this.props;
    const { isAcceptingInviation, invitation, loading } = this.state;
    if (loading) {
      return (
        <Segment placeholder>
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        </Segment>
      );
    }
    if (userProfile && invitation) {
      return (
        <Container>
          <Segment placeholder clearing textAlign='center'>
            <Header
              content={t('YouHaveBeenInvitedToCollaborateOnX', { book: invitation.book.title, author: invitation.book.authorName })}
              subheader={t('AcceptCollaborationInvitationMsg')}
            />
            <Segment.Inline>
              <Button
                positive
                fluid
                content={t('Accept')}
                onClick={this.acceptInvitation}
                loading={isAcceptingInviation}
              />
            </Segment.Inline>
          </Segment>
        </Container>
      );
    }
    return null;
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile
});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceptCollaboratorInvitation)));
