import queryString from 'query-string';

export const EDIT_CURRENT_SURVEY = 'survey/EDIT_CURRENT_SURVEY';
export const REPLACE_CURRENT_SURVEY = 'survey/REPLACE_CURRENT_SURVEY';
export const UPDATE_SURVEY = 'survey/UPDATE_SURVEY';
export const UPDATE_SURVEY_ANSWER = 'survey/UPDATE_SURVEY_ANSWER';
export const UPDATE_SURVEY_ANSWERS = 'survey/UPDATE_SURVEY_ANSWERS';
export const UPDATE_QUESTION_DOM_REFERENCE = 'survey/UPDATE_QUESTION_DOM_REFERENCE';
export const CLEAR_SURVEY = 'survey/CLEAR_SURVEY';
export const UPDATE_CURRENT_SURVEY = 'survey/UPDATE_CURRENT_SURVEY';
export const ADD_QUESTION = 'survey/ADD_QUESTION';
export const EDIT_CURRENT_SURVEY_QUESTION = 'survey/EDIT_CURRENT_SURVEY_QUESTION';
export const DELETE_SURVEY_QUESTION = 'survey/DELETE_SURVEY_QUESTION';
export const CHANGE_TITLE = 'survey/CHANGE_TITLE';
export const CHANGE_DESCRIPTION = 'survey/CHANGE_DESCRIPTION';
export const UPDATE_ANSWERS = 'survey/UPDATE_ANSWERS';
export const UPDATING_SIGNUP_SURVEY = 'survey/UPDATING_SIGNUP_SURVEY';
export const UPDATE_SIGNUP_SURVEY = 'survey/UPDATE_SIGNUP_SURVEY';

const initialState = {
  currentSurvey: {
    title: '',
    description: '',
    questions: []
  },
  questionDomReferences: {},
  answers: [],
  fetchedAnswers: false,
  signUpSurvey: [],
  updatingSignUpSurvey: false
};

// reducers
export default (state = initialState, action) => {
  let updatedQuestionList;
  switch (action.type) {
    case EDIT_CURRENT_SURVEY:
      return {
        ...state,
        currentSurvey: {
          ...state.currentSurvey,
          ...action.surveyData
        },
        hasChanged: action.hasChanged
      };
    case UPDATE_SURVEY_ANSWER: {
      let updatedAnswers = [...state.answers];
      const answerIndex = updatedAnswers.findIndex(answer => answer.surveyQuestion === action.answer.surveyQuestion);
      if (answerIndex >= 0) {
        // if an answer already exists
        updatedAnswers.splice(answerIndex, 1, action.answer);
      } else {
        // if not
        updatedAnswers.push(action.answer);
      }

      return {
        ...state,
        answers: updatedAnswers
      };
    }
    case UPDATE_SURVEY_ANSWERS:
      return {
        ...state,
        answers: action.answers,
        fetchedAnswers: action.fetchedAnswers
      };
    case UPDATE_QUESTION_DOM_REFERENCE:
      var updatedDomReferences = {...state.questionDomReferences};
      updatedDomReferences[action.questionId] = action.reference;
      return {
        ...state,
        questionDomReferences: updatedDomReferences
      };
    case REPLACE_CURRENT_SURVEY:
      return {
        ...state,
        currentSurvey: {
          ...state.currentSurvey,
          ...action.surveyData
        },
        hasChanged: false
      };
    case UPDATE_SURVEY:
      return {
        ...state,
        currentSurvey: action.survey
      };
    case CHANGE_TITLE:
      return {
        ...state,
        currentSurvey: {
          ...state.currentSurvey,
          title: action.title
        },
        hasChanged: action.hasChanged
      };
    case CHANGE_DESCRIPTION:
      return {
        ...state,
        currentSurvey: {
          ...state.currentSurvey,
          description: action.description
        },
        hasChanged: action.hasChanged
      };
    case ADD_QUESTION:
      updatedQuestionList = [...state.currentSurvey.questions];
      updatedQuestionList.push({
        question: '',
        description: '',
        kind: 'LONG_TEXT'
      });
      return {
        ...state,
        currentSurvey: {
          ...state.currentSurvey,
          questions: updatedQuestionList
        },
        hasChanged: action.hasChanged
      };
    case UPDATE_CURRENT_SURVEY:
      return {
        ...state,
        currentSurvey: action.survey
      };
    case EDIT_CURRENT_SURVEY_QUESTION: {
      updatedQuestionList = [...state.currentSurvey.questions];
      let questionIndex = action.questionData.index;
      if (questionIndex < 0 || questionIndex > updatedQuestionList.length) {
        return state;
      }
      updatedQuestionList[questionIndex] = {
        ...updatedQuestionList[questionIndex],
        ...action.questionData
      };
      return {
        ...state,
        currentSurvey: {
          ...state.currentSurvey,
          questions: updatedQuestionList
        },
        hasChanged: action.hasChanged
      };
    }
    case DELETE_SURVEY_QUESTION:
      updatedQuestionList = [...state.currentSurvey.questions];
      if (updatedQuestionList[action.questionIndex]) {
        // if there is a question, remove it
        updatedQuestionList.splice(action.questionIndex, 1);
      }
      return {
        ...state,
        currentSurvey: {
          ...state.currentSurvey,
          questions: updatedQuestionList
        },
        hasChanged: action.hasChanged
      };
    case UPDATE_ANSWERS:
      return {
        ...state,
        answers: action.answers,
        fetchedAnswers: action.fetchedAnswers
      };
    case UPDATING_SIGNUP_SURVEY:
      return {
        ...state,
        updatingSignUpSurvey: action.value
      };
    case UPDATE_SIGNUP_SURVEY:
      return {
        ...state,
        signUpSurvey: action.signUpSurvey
      };
    case CLEAR_SURVEY:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};

// actions
export const saveSurvey = (idToken, data, callback) => {
  let survey = { ...data.survey };
  survey.questions = [...survey.questions]
    .filter(question => question.question && question.question.length > 0);
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${data.bookId}/surveys/${survey._id || 'new'}`, {
      method: `${survey._id !== undefined ? 'PUT' : 'POST'}`,
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      },
      body: JSON.stringify(survey)
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        }
        return res.json();
      })
      .then((updatedSurvey) => {
        dispatch({
          type: UPDATE_SURVEY,
          survey: updatedSurvey,
          hasChanged: false
        });
        if (callback) {
          callback(updatedSurvey);
        }
      })
      .catch((err) => {
        console.error(err);
        if (callback) {
          callback(false);
        }
        dispatch({
          type: 'error'
        });
      });
  };
};

export const fetchCurrentSurvey = (idToken, bookId, partId) => (dispatch) => {
  fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/surveys/${partId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'br-token': idToken
    }
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.error);
      }
      return res.json();
    })
    .then((survey) => {
      dispatch({
        type: UPDATE_CURRENT_SURVEY,
        survey
      });
    })
    .catch((err) => {
      console.error(err);
    });
};


export const fetchSurveyAnswers = (idToken, data, options) => (dispatch) => {
  let path = `/books/${data.bookId}/surveys/${data.surveyId}/answers`;
  if (options) {
    path += `?${queryString.stringify(options)}`;
  }
  fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}${path}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'br-token': idToken
    }
  })
    .then(res => res.json())
    .then((answers) => {
      dispatch({
        type: UPDATE_ANSWERS,
        answers,
        fetchedAnswers: true
      });
    })
    .catch((err) => {
      console.error(err);
    });
};


export const addQuestion = () => (dispatch) => {
  dispatch({
    type: ADD_QUESTION,
    hasChanged: true
  });
};


export const editCurrentSurveyQuestion = questionData => (dispatch) => {
  dispatch({
    type: EDIT_CURRENT_SURVEY_QUESTION,
    questionData,
    hasChanged: true
  });
};

export const deleteSurveyQuestion = questionIndex => (dispatch) => {
  dispatch({
    type: DELETE_SURVEY_QUESTION,
    questionIndex,
    hasChanged: true
  });
};

export const editCurrentSurvey = surveyData => (dispatch) => {
  dispatch({
    type: EDIT_CURRENT_SURVEY,
    surveyData,
    hasChanged: true
  });
};

export const replaceCurrentSurvey = surveyData => (dispatch) => {
  dispatch({
    type: REPLACE_CURRENT_SURVEY,
    surveyData
  });
};


export const changeTitle = newTitle => (dispatch) => {
  dispatch({
    type: CHANGE_TITLE,
    title: newTitle,
    hasChanged: true
  });
};

export const changeDescription = newDescription => (dispatch) => {
  dispatch({
    type: CHANGE_DESCRIPTION,
    description: newDescription,
    hasChanged: true
  });
};

export const startNewSurvey = () => (dispatch) => {
  dispatch({
    type: UPDATE_SURVEY,
    survey: null,
    hasChanged: false
  });
};

export const clearSurvey = () => (dispatch) => {
  dispatch({
    type: CLEAR_SURVEY
  });
};

export const updateSurveyAnswer = (idToken, bookId, surveyId, questionId, newAnswer) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/surveys/${surveyId}/answers/${questionId}`;
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'br-token': idToken
    },
    body: JSON.stringify({
      answer: newAnswer
    })
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.error);
      }
      return res.json();
    })
    .then(answer => dispatch({
      type: UPDATE_SURVEY_ANSWER,
      surveyId,
      answer
    }))
    .catch((err) => {
      console.error('Failed to post answer', questionId, surveyId);
    });
};

export const submitSurveyAnswers = (idToken, data) => (dispatch) => {
  fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${data.bookId}/surveys/${data.surveyId}/answers?filter=all`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'br-token': idToken
    },
    body: JSON.stringify({
      answers: data.answers
    })
  })
    .then(res => res.json())
    .then(answers => dispatch({
      type: UPDATE_SURVEY_ANSWERS,
      surveyId: data.surveyId,
      answers
    }));
};

export const updateQuestionDomReference = (questionId, reference) => dispatch => dispatch({
  type: UPDATE_QUESTION_DOM_REFERENCE,
  questionId,
  reference
});

export const updateReaderSignupQuestions = (idToken, data, callback) => (dispatch) => {
  dispatch({
    type: UPDATING_SIGNUP_SURVEY,
    value: true
  });
  fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${data.bookId}/surveys/reader-signup-survey`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'br-token': idToken
    },
    body: JSON.stringify(data.surveyData)
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.error);
      }
      return res.json();
    })
    .then((updatedSurvey) => {
      if (callback) {
        callback(updatedSurvey);
      }
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({
        type: UPDATING_SIGNUP_SURVEY,
        value: false
      });
    });
};
