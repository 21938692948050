import React from 'react';

import { Link } from 'react-router-dom';
import { Segment, Header, Step, Icon } from 'semantic-ui-react'
import { withTranslation, Trans } from 'react-i18next';

const _isValidProfile = (userProfile, user) => {
  let { displayName, emailVerified } = userProfile;
  // there is a delay in email verified state via the api,
  // so take this directly from the user object
  if (!emailVerified && user) {
    emailVerified = user.emailVerified;
  }
  return displayName && displayName.length > 0 && emailVerified;
};

const _hasValidBook = (writing) => {
  return writing.filter((book) => {
    if (book.privacy !== 'listed') {
      return false;
    }
    if (book.wordCount <= 0) {
      return false;
    }
    // listed books with wordCount greater than 0 are considered valid
    return true;
  }).length > 0;
};

// stateless component
const UnlockSignupBonusMessage = ({ userProfile, user, writing = [] }) => (
  <Segment color='teal'>
    <Trans i18nKey='unlockBonusText'>
      <Header size='medium' style={{ fontWeight: 'normal' }}>Unlock your signup bonus!</Header>
      <p style={{ fontWeight: 'lighter' }}>It looks like you were referred by a friend - follow the below steps to unlock your mutual sign-up bonus!</p>
      <Step.Group widths={3}>
        <Step as={Link} link to='/account/profile'>
          <Icon name={_isValidProfile(userProfile, user) ? 'check' : 'user'} color={_isValidProfile(userProfile) ? 'green' : undefined} />
          <Step.Content>
            <Step.Title>Validate account</Step.Title>
            <Step.Description>You need a valid name and email address</Step.Description>
          </Step.Content>
        </Step>
        <Step as={Link} link to='/books/new'>
          <Icon name={_hasValidBook(writing) ? 'check' : 'book'} color={_hasValidBook(writing) ? 'green' : undefined} />
          <Step.Content>
            <Step.Title>List manuscript</Step.Title>
            <Step.Description>List the manuscript you want to test</Step.Description>
          </Step.Content>
        </Step>
        <Step
          as='a'
          link
          onClick={() => {
            window.open(process.env.REACT_APP_READER_HOST, 'betareader.io_reader');
          }}
        >
          <Icon name='clock outline' />
          <Step.Content>
            <Step.Title>Wait</Step.Title>
            <Step.Description>It takes around 3 days for us to validate</Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
    </Trans>
  </Segment>
);

export default withTranslation()(UnlockSignupBonusMessage);
