import React from 'react';
import {Label, Item, Icon, Popup} from 'semantic-ui-react';
// i18n
import {useTranslation} from 'react-i18next';
// tracking
import ReactGA from 'react-ga';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import privacy from '../../config/privacy';
// image handling
import imageUtil from '../../utils/imageUtil';
import ReviewItem from '../review/reviewItem';

import PublishingSettings from '../../containers/book/detail/publishingSettings';
import {MediaQuery} from '../../constants/size';
import {Media} from 'src/utils/Media';

export const DEFAULT_COVER_BACKGROUND =
  'https://storage.googleapis.com/beta-reader-prod.appspot.com/defaults/DEFAULT_COVER_BACKGROUND.png';

const ResponsiveImage = styled(Item.Image)`
  ${MediaQuery.phone} {
    display: none !important;
  }
  > img {
    border-radius: 10px !important;
  }
`;

const BookItem = ({book}) => {
  const history = useHistory();
  const {t} = useTranslation();

  const blockClick = event => {
    event.stopPropagation();
  };

  const goToBookSubPage = (event, subPage) => {
    blockClick(event);
    history.push(`/books/${book._id}/${subPage}`);
    ReactGA.event({
      category: 'General',
      action: `Clicked Book ${subPage}`
    });
  };

  if (!book) return null;
  return (
    <Item
      key={book._id}
      style={{cursor: 'pointer'}}
      onClick={() => {
        history.push(`/books/${book._id}`);
        ReactGA.event({
          category: 'General',
          action: 'Clicked Book Card'
        });
      }}>
      <ResponsiveImage
        /* hide when the list of items flips to "vertical view"
          because it causes the image to overtake everything */
        size='tiny'
        src={imageUtil.getImageUrl({
          operation: 'width',
          size: 200,
          originalImageUrl:
            book?.cover?.highRes?.url ?? DEFAULT_COVER_BACKGROUND
        })}
      />
      <Item.Content>
        <Item.Header style={{display: 'flex'}}>
          <Popup
            hoverable
            trigger={
              <Icon
                name={privacy.get(book, 'iconName')}
                style={{alignSelf: 'flex-start'}}
                onClick={blockClick}
              />
            }
            content={<PublishingSettings book={book} />}
          />
          <span style={{flex: 1}}>{book.title}</span>
          {book.referenceId !== undefined && (
            <span style={{fontSize: '0.8em', fontWeight: 'normal'}}>
              {` (${book.referenceId})`}
            </span>
          )}
          <Media at='mobile'>
            {(className, renderChildren) =>
              renderChildren && (
                <span style={{marginLeft: 'auto'}}>
                  <Label size='mini' color='teal' basic>
                    <Icon name='comment outline' />
                    {book.totalComments || 0}
                  </Label>
                  <Label size='mini' color='teal' basic>
                    <Icon name='feed' />
                    {book.totalFollowers || 0}
                  </Label>
                  <Label size='mini' color='teal' basic>
                    <Icon name='eye' />
                    {book.totalReviews || 0}
                  </Label>
                  {book.avgOverAllRating && (
                    <Label size='mini' color='teal' basic>
                      <Icon name='star outline' />
                      {(book.avgOverAllRating || 0).toFixed(1)}
                    </Label>
                  )}
                </span>
              )
            }
          </Media>
          {book.role === 'collaborator' && (
            <Label
              size='mini'
              basic
              content={t('collaborator')}
              style={{marginLeft: '1em'}}
            />
          )}
        </Item.Header>
        <Item.Meta>
          {t('by')} {book.authorName}
          <span>
            ({t('ChapterCount', {count: book.chapterCount || 0})},{' '}
            {t('WordCountString', {count: book.wordCount || 0})})
          </span>
        </Item.Meta>
        <Item.Extra>
          {book.description && book.description.length > 250
            ? `${book.description.substring(0, 250)}...`
            : book.description}
        </Item.Extra>
        <Item.Extra />
      </Item.Content>
      <Media greaterThan='mobile'>
        <Item.Extra className='book-item-right'>
          <Label
            basic
            color='teal'
            onClick={event => goToBookSubPage(event, 'feedback/comments')}>
            <Icon name='comment outline' />
            {book.totalComments || 0}
          </Label>
          <Label
            basic
            color='teal'
            onClick={event => goToBookSubPage(event, 'followers')}>
            <Icon name='feed' />
            {book.totalFollowers || 0}
          </Label>
          <Label
            basic
            color='teal'
            onClick={event => goToBookSubPage(event, 'feedback/reviews')}>
            <Icon name='eye' />
            {book.totalReviews || 0}
          </Label>
        </Item.Extra>
        <ReviewItem reviewData={book} t={t} />
      </Media>
    </Item>
  );
};

export default BookItem;
