import React from 'react';

import { Link } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

// stateless component
const ProductLimitMessage = ({ reachedCap, basic }) => (
  basic ? (
    <div>
      {
        reachedCap === 'maxReaders' && (
          <Trans key='basicmaxReadersLimitMessage' i18nKey='maxReadersLimitMessage'>
            <strong>
            You have reached your reader limit. <Link to='/products'>Upgrade</Link> to send more invitations in our reader index or approve read requests. In our free plan you can suggest to <Link to='/discover'>swap</Link> with other writers or invite as many readers you want directly through their email address.
            </strong>
          </Trans>
        )
      }
      {
        reachedCap === 'maxBooks' && (
          <Trans key='basicmaxBooks' i18nKey='maxBooksLimitMessage'>
            <strong>
              You have reached your book limit. <Link to='/products'>Upgrade</Link> to be able to test several manuscripts in parallel.
            </strong>
          </Trans>
        )
      }
    </div>
  ) : (
    <Message color='orange'>
      {
        reachedCap === 'maxReaders' && (
          <Trans key='maxReaders' i18nKey='maxReadersLimitMessage'>
            <strong>
            You have reached your reader limit. <Link to='/products'>Upgrade</Link> to send more invitations in our reader index or approve read requests. In our free plan you can suggest to <Link to='/discover'>swap</Link> with other writers or invite as many readers you want directly through their email address.
            </strong>
          </Trans>
        )
      }
      {
        reachedCap === 'maxBooks' && (
          <Trans key='maxBooks' i18nKey='maxBooksLimitMessage'>
            <strong>
              You have reached your book limit. <Link to='/products'>Upgrade</Link> to be able to test several manuscripts in parallel.
            </strong>
          </Trans>
        )
      }
    </Message>
  )
);

export default withTranslation()(ProductLimitMessage);
