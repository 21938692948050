import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Modal, Icon, Image
} from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import { withTranslation, Trans } from 'react-i18next';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  changeSubscription
} from '../../../modules/subscription';

import {
  getUserProfile
} from '../../../modules/user';

export class PaymentSuccessModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  close = () => {
    const { idToken } = this.props;
    this.props.getUserProfile(idToken);
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleClick = () => {
  }

  render() {
    const { open, paymentSuccessData } = this.props;
    return (
      [
        <Helmet key='paymentSuccessData-key'>
          <script>
            {`
              fbq('track', 'Subscribe', {
                value: ${paymentSuccessData ? parseFloat(paymentSuccessData.checkout.prices.vendor.total) : 0},
                currency: '${paymentSuccessData ? paymentSuccessData.checkout.prices.vendor.currency : 'USD'}',
              });
            `}
          </script>
          <script type='text/javascript'>
            {`
              twttr.conversion.trackPid('o1onw', {
                tw_sale_amount: ${paymentSuccessData ? parseFloat(paymentSuccessData.checkout.prices.vendor.total) : 0},
                tw_order_quantity: 1,
              });
            `}
          </script>
          <noscript>
            {`
              <img height="1" width="1" style="display:none;" alt="" src="https://analytics.twitter.com/i/adsct?txn_id=o1onw&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0" />
              <img height="1" width="1" style="display:none;" alt="" src="//t.co/i/adsct?txn_id=o1onw&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0" />
          `}
          </noscript>
          <script>
            {`
              gtag('event', 'conversion', {
                'send_to': 'AW-779841759/Ff9ACKL94ZgBEN_h7fMC',
                'value': ${paymentSuccessData ? parseFloat(paymentSuccessData.checkout.prices.vendor.total) : 0},
                'currency': '${paymentSuccessData ? paymentSuccessData.checkout.prices.vendor.currency : 'USD'}',
                'transaction_id': ''
              });
            `}
          </script>
        </Helmet>,
        <Modal
          key='PaymentSuccessModal'
          open={open}
          closeOnEscape
          closeOnDimmerClick
          size='tiny'
          onClose={this.close}
          centered
          closeIcon={<Icon name='close' style={{ color: '#ffffff' }} />}
          className='PaymentSuccessModal'
        >
          <Modal.Header style={{ backgroundColor: '#3FC59D', textAlign: 'center', height: '6em' }}>
            <div style={{ height: '100%', width: '100%', verticalAlign: 'middle' }}>
              <Icon size='huge' style={{ color: '#ffffff', verticalAlign: 'middle' }} name='checkmark' />
            </div>
          </Modal.Header>
          <Modal.Content style={{ height: '9em' }}>
            <Modal.Description style={{ textAlign: 'center' }}>
              <Trans i18nKey='PaymentSuccessMessage'>
                <span>
                  <h1>
                    Thanks for your purchase!
                  </h1>
                  <p>
                    <Image centered size='small' src='/assets/images/BR-orange-horizontal.png' />
                  </p>
                </span>
              </Trans>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      ]
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changeSubscription,
  getUserProfile,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentSuccessModal)));
