import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Components
import {
  Grid, Button, TextArea
} from 'semantic-ui-react';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// i18n
import { withTranslation } from 'react-i18next';
import { authUtil } from '../../utils';
import {
  replyToConversation,
  startConversation,
  onTypingNewMessage
} from '../../modules/message';
// styling
import './chat.css';

export class InputBar extends Component {
  constructor(props) {
    super(props);
    this.state = { text: '' };
  }

  handleMessageChange = (event, data) => {
    const { currentConversation, userProfile } = this.props;
    this.setState({
      text: data.value
    });
    if (data.value && data.value.length > 0) {
      this.props.onTypingNewMessage({ currentConversation: currentConversation._id, userId: userProfile._id, displayName: userProfile.displayName, isTyping: true });
    }
    if (!data.value) {
      this.props.onTypingNewMessage({ currentConversation: currentConversation._id, userId: userProfile._id, displayName: userProfile.displayName, isTyping: false });
    }
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 13 && event.metaKey) {
      // this is a CMD+Enter or Ctrl+Enter event. Submit reply
      this.sendMessage();
    }
  }

  sendMessage = async () => {
    const {
      currentConversation, userProfile
    } = this.props;
    const { text } = this.state;
    if (!this.canSendMessage()) {
      return;
    }
    const idToken = await authUtil.getFreshIdToken();
    if (currentConversation._id) {
      // conversation exists, simply reply to it
      this.props.replyToConversation(idToken, currentConversation._id, { text }, () => {
      });
    } else if (currentConversation.isNew) {
      // this is just a placeholder conversation
      // start a new conversation with said user
      const conversationData = {
        subject: currentConversation.subject,
        members: currentConversation.members.map(user => user._id),
        text
      };
      // if this is a new support conversation
      if (currentConversation.type === 'support') {
        conversationData.type = 'support';
      }
      this.props.startConversation(idToken, conversationData, (response) => {
        if (response.success) {
          this.props.onTypingNewMessage({ currentConversation: currentConversation._id, userId: userProfile._id, displayName: userProfile.displayName, isTyping: false });
        }
      });
    }
    this.props.onTypingNewMessage({ currentConversation: currentConversation._id, userId: userProfile._id, displayName: userProfile.displayName, isTyping: false });
    this.setState({ text: '' });
  }

  canSendMessage = () => {
    const { currentConversation } = this.props;
    const { text } = this.state;
    return text && text.length > 0 && currentConversation.members.length > 0;
  }

  render() {
    const { currentConversation, t, sendingMessage } = this.props;
    const { text } = this.state;
    if (currentConversation) {
      return (
        <Grid columns='equal' style={{ marginRight: 0 }}>
          <Grid.Row columns='equal' style={{ width: '100%' }}>
            <Grid.Column className='inputContainer'>
              <TextArea
                style={{
                  marginLeft: 10, width: '100%', border: 'none', outline: 'none', background: 'transparent'
                }}
                rows={1}
                placeholder={t('NewMessage')}
                onKeyDown={this.handleKeyDown}
                onChange={this.handleMessageChange}
                value={text}
              />
              <Button
                style={{ marginRight: 10, marginTop: 5 }}
                floated='right'
                size='tiny'
                circular
                basic={!text || text === ''}
                loading={sendingMessage}
                disabled={!this.canSendMessage()}
                onClick={this.sendMessage}
                content={t('SendMessage')}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

      );
    }
    return null;
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  userProfile: state.user.userProfile,
  idToken: state.user.idToken,
  conversations: state.message.conversations,
  currentConversation: state.message.currentConversation,
  sendingMessage: state.message.sendingMessage
});

const mapDispatchToProps = dispatch => bindActionCreators({
  replyToConversation,
  startConversation,
  onTypingNewMessage,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(InputBar)));
