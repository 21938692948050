import queryString from 'query-string';
import {toast} from 'react-toastify';
import i18n from '../utils/i18n/i18n';
import {v4 as uuidv4} from 'uuid';

export const ADD_COMMENT = 'comment/ADD_COMMENT';
export const ADD_REPLY = 'comment/ADD_REPLY';
export const FETCH_COMMENTS = 'comment/FETCH_COMMENTS';
export const ADD_COMMENTS = 'comment/ADD_COMMENTS';
export const FETCHING_COMMENTS = 'comment/FETCHING_COMMENTS';
export const UPDATE_COMMENTS = 'comment/UPDATE_COMMENTS';
export const UPDATE_COMMENT = 'comment/UPDATE_COMMENT';
export const UPDATE_COMMENT_FILTER = 'comment/UPDATE_COMMENT_FILTER';
export const SET_ACTIVE_INLINE_COMMENT = 'comment/SET_ACTIVE_INLINE_COMMENT';
export const EDIT_COMMENT = 'comment/EDIT_COMMENT';
export const EDIT_REPLY = 'comment/EDIT_REPLY';
export const UPDATE_REPLY = 'comment/UPDATE_REPLY';
export const REMOVE_REPLY = 'comment/REMOVE_REPLY';
export const CLEAR_ACTIVE_INLINE_COMMENT =
  'comment/CLEAR_ACTIVE_INLINE_COMMENT';
export const REMOVE_INLINE_PLACEHOLDER = 'comment/REMOVE_INLINE_PLACEHOLDER';
export const REMOVE_EMPTY_INLINE_PLACEHOLDER =
  'comment/REMOVE_EMPTY_INLINE_PLACEHOLDER';
export const EDIT_INLINE_PLACEHOLDER = 'comment/EDIT_INLINE_PLACEHOLDER';
export const UPDATE_COMMENT_DOM_INLINE_REFERENCE =
  'comment/UPDATE_COMMENT_DOM_INLINE_REFERENCE';
export const UPDATE_COMMENT_DOM_SIDEBAR_REFERENCE =
  'comment/UPDATE_COMMENT_DOM_SIDEBAR_REFERENCE';
export const UPDATE_COMMENTS_SIDEBAR_TOP =
  'comment/UPDATE_COMMENTS_SIDEBAR_TOP';
export const SET_ACTIVE_READER = 'comment/SET_ACTIVE_READER';
export const REMOVE_COMMENT = 'comment/REMOVE_COMMENT';

export const initialState = {
  comments: undefined,
  commentReferences: {},
  activeInlineComment: undefined,
  filteredReaders: undefined,
  filters: [],
  commentStatusOptions: [
    {
      key: 'TODO',
      text: 'To do',
      value: 'TODO',
      label: {color: 'black', empty: true, circular: true}
    },
    {
      key: 'DOING',
      text: 'Doing',
      value: 'DOING',
      label: {color: 'blue', empty: true, circular: true}
    },
    {
      key: 'DONE',
      text: 'Done',
      value: 'DONE',
      label: {color: 'green', empty: true, circular: true}
    },
    {
      key: 'IGNORE',
      text: 'Ignore',
      value: 'IGNORE',
      label: {color: 'grey', empty: true, circular: true}
    }
  ]
};

// reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMMENTS:
      return {
        ...state,
        comments: action.comments
      };
    case ADD_COMMENTS:
      return {
        ...state,
        comments: [...state.comments, ...action.comments]
      };
    case FETCHING_COMMENTS:
      return {
        ...state,
        fetchingComments: action.status
      };
    case UPDATE_COMMENTS:
      return {
        ...state,
        comments: action.comments
      };
    case UPDATE_COMMENT: {
      const commentIndex = state.comments?.findIndex(
        comment => comment._id === action.comment._id
      );
      // if we found the comment
      if (commentIndex !== undefined && commentIndex !== -1) {
        const updatedComments = [...state.comments];
        // keep the layout props
        const newCommentData = {
          ...updatedComments[commentIndex],
          ...action.comment,
          editing: false
        };
        updatedComments.splice(commentIndex, 1, newCommentData);
        return {
          ...state,
          comments: updatedComments
        };
      }
      return {...state};
    }
    case UPDATE_COMMENT_FILTER:
      localStorage.setItem(
        'br-feedback-filters',
        JSON.stringify(action.filters)
      );
      return {
        ...state,
        filters: action.filters
      };
    case ADD_COMMENT: {
      const updatedComments = [...state.comments];
      let newComment = action.comment;
      if (!newComment.layout) {
        newComment = {
          ...action.comment,
          layout: {
            sidebar: undefined,
            inline: undefined
          }
        };
      }
      updatedComments.push(newComment);
      return {
        ...state,
        comments: updatedComments
      };
    }
    case ADD_REPLY: {
      const updatedComments = [...state.comments];
      const commentIndex = updatedComments.findIndex(
        comment => comment._id === action.reply.comment
      );

      if (commentIndex !== -1) {
        const newComment = state.comments[commentIndex];
        if (!newComment.replies) {
          newComment.replies = [];
        }
        newComment.replies.push(action.reply);
        updatedComments.splice(commentIndex, 1, {
          ...newComment
        });
      }
      return {
        ...state,
        comments: updatedComments
      };
    }
    case REMOVE_COMMENT: {
      const updatedComments = [...state.comments];
      const commentIndex = updatedComments.findIndex(
        comment => comment._id === action.commentId
      );
      updatedComments.splice(commentIndex, 1);
      return {
        ...state,
        comments: updatedComments
      };
    }
    case SET_ACTIVE_INLINE_COMMENT: {
      // update the state to allow the app to highlight the active comment
      return {
        ...state,
        activeInlineComment: action.commentId
      };
    }
    case EDIT_COMMENT: {
      const updatedComments = [...state.comments];
      const commentIndex = updatedComments.findIndex(
        commentEntry => commentEntry._id === action.commentId
      );
      // if we found the comment
      if (commentIndex !== -1) {
        const newComment = {
          ...updatedComments[commentIndex],
          editing: action.editing
        };
        updatedComments.splice(commentIndex, 1, newComment);
      }
      return {
        ...state,
        comments: updatedComments
      };
    }
    case EDIT_REPLY: {
      const updatedComments = [...state.comments];
      const commentIndex = updatedComments.findIndex(
        commentEntry => commentEntry._id === action.commentId
      );
      // if we found the comment
      if (Number.isInteger(commentIndex) && commentIndex !== -1) {
        const replyIndex = updatedComments[commentIndex].replies.findIndex(
          replyEntry => replyEntry._id === action.replyId
        );
        // if we found the reply
        if (replyIndex !== -1) {
          const newReply = {
            ...updatedComments[commentIndex].replies[replyIndex],
            editing: action.editing
          };
          updatedComments[commentIndex].replies.splice(replyIndex, 1, newReply);
        }
      }
      return {
        ...state,
        comments: updatedComments
      };
    }
    case UPDATE_REPLY: {
      const updatedComments = [...state.comments];
      const commentIndex = updatedComments?.findIndex(
        commentEntry => commentEntry._id === action.reply.comment
      );
      // if we found the comment
      if (Number.isInteger(commentIndex) && commentIndex !== -1) {
        const replyIndex = updatedComments[commentIndex].replies.findIndex(
          replyEntry => replyEntry._id === action.reply._id
        );
        // if we found the reply
        if (replyIndex !== -1) {
          const newReply = {
            ...action.reply,
            editing: false
          };
          updatedComments[commentIndex].replies.splice(replyIndex, 1, newReply);
        }
      }
      return {
        ...state,
        comments: updatedComments
      };
    }
    case REMOVE_REPLY: {
      const updatedComments = [...state.comments];
      const commentIndex = updatedComments?.findIndex(
        commentEntry => commentEntry._id === action.commentId
      );
      // if we found the comment
      if (Number.isInteger(commentIndex) && commentIndex !== -1) {
        const replyIndex = updatedComments[commentIndex].replies?.findIndex(
          replyEntry => replyEntry._id === action.replyId
        );
        // if we found the reply, remove it
        if (replyIndex !== -1) {
          updatedComments[commentIndex].replies.splice(replyIndex, 1);
        }
      }
      return {
        ...state,
        comments: updatedComments
      };
    }
    case CLEAR_ACTIVE_INLINE_COMMENT:
      return {
        ...state,
        activeInlineComment: undefined
      };
    case REMOVE_INLINE_PLACEHOLDER: {
      console.log('REMOVE_INLINE_PLACEHOLDER', action.id);
      const commentPlaceHolderIndex = state?.comments?.findIndex(
        comment => comment._id === action.id
      );
      if (
        Number.isInteger(commentPlaceHolderIndex) &&
        commentPlaceHolderIndex !== -1 &&
        state?.comments?.length > 0
      ) {
        const updatedComments = [...state.comments];
        updatedComments.splice(commentPlaceHolderIndex, 1);
        return {
          ...state,
          comments: updatedComments
        };
      }
      return {
        ...state
      };
    }
    case EDIT_INLINE_PLACEHOLDER: {
      const commentPlaceHolderIndex = state?.comments?.findIndex(
        comment => comment._id === action.id
      );
      if (
        Number.isInteger(commentPlaceHolderIndex) &&
        commentPlaceHolderIndex !== -1 &&
        state?.comments?.length > 0
      ) {
        let oldCommentPlaceholder = state.comments[commentPlaceHolderIndex];
        const updatedCommentPlaceholder = {
          ...oldCommentPlaceholder,
          text: action.text
        };
        const updatedComments = [...state.comments];
        updatedComments.splice(
          commentPlaceHolderIndex,
          1,
          updatedCommentPlaceholder
        );
        return {
          ...state,
          comments: updatedComments
        };
      }
      return {
        ...state
      };
    }
    case REMOVE_EMPTY_INLINE_PLACEHOLDER: {
      const commentPlaceHolderIndex = state?.comments?.findIndex(
        comment => comment.text?.length === 0
      );
      if (
        Number.isInteger(commentPlaceHolderIndex) &&
        commentPlaceHolderIndex !== -1 &&
        state?.comments?.length > 0
      ) {
        const updatedComments = [...state.comments];
        updatedComments.splice(commentPlaceHolderIndex, 1);
        return {
          ...state,
          comments: updatedComments
        };
      }
      return {
        ...state
      };
    }
    case UPDATE_COMMENTS_SIDEBAR_TOP: {
      return {
        ...state,
        comments: action.comments
      };
    }
    case UPDATE_COMMENT_DOM_INLINE_REFERENCE: {
      const updatedCommentsWithLayout = state.comments;
      const commentIndex = state?.comments?.findIndex(
        oldComment => oldComment._id === action.commentId
      );
      // if we found
      if (Number.isInteger(commentIndex) && commentIndex !== -1) {
        const oldComment = state.comments[commentIndex];
        const updatedComment = {
          ...oldComment,
          layout: {
            ...oldComment.layout,
            inline: action.layout
          }
        };
        updatedCommentsWithLayout.splice(commentIndex, 1, updatedComment);
      }
      return {
        ...state,
        comments: updatedCommentsWithLayout
      };
    }

    case UPDATE_COMMENT_DOM_SIDEBAR_REFERENCE: {
      const updatedCommentsWithLayout = state.comments;
      const commentIndex = state.comments?.findIndex(
        oldComment => oldComment._id === action.commentId
      );
      // if we found
      if (Number.isInteger(commentIndex) && commentIndex !== -1) {
        const oldComment = state.comments[commentIndex];
        updatedCommentsWithLayout.splice(commentIndex, 1, {
          ...oldComment,
          layout: {
            ...oldComment.layout,
            sidebar: action.layout
          }
        });
      }
      return {
        ...state,
        comments: updatedCommentsWithLayout
      };
    }
    case SET_ACTIVE_READER:
      return {
        ...state,
        filteredReaders: action.filteredReaders
      };
    default:
      return state;
  }
};

// actions
export const fetchComments = (idToken, bookId, chapterId, options) => {
  let path = `/books/${bookId}`;
  if (chapterId) {
    path += `/chapters/${chapterId}`;
  }
  path += '/comments';

  if (options) {
    path += `?${queryString.stringify(options)}`;
  }

  return dispatch => {
    dispatch({
      type: UPDATE_COMMENTS,
      comments: []
    });
    dispatch({
      type: FETCHING_COMMENTS,
      status: true
    });
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}${path}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => res.json())
      .then(comments => {
        if (comments.err) {
          throw new Error(comments.err);
        }
        if (comments !== undefined) {
          return (
            dispatch({
              type: FETCH_COMMENTS,
              comments
            }),
            dispatch({
              type: FETCHING_COMMENTS,
              status: false
            })
          );
        }
        return (
          dispatch({
            type: FETCH_COMMENTS,
            comments: []
          }),
          dispatch({
            type: FETCHING_COMMENTS,
            status: false
          })
        );
      })
      .catch(err => {
        dispatch({
          type: FETCHING_COMMENTS,
          status: false
        });
        console.error(err);
      });
  };
};

export const fetchMoreComments = (idToken, bookId, chapterId, options) => {
  let path = `/books/${bookId}`;
  if (chapterId) {
    path += `/chapters/${chapterId}`;
  }
  path += '/comments';

  if (options) {
    path += `?${queryString.stringify(options)}`;
  }

  return dispatch => {
    dispatch({
      type: FETCHING_COMMENTS,
      status: true
    });
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}${path}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => res.json())
      .then(comments => {
        if (comments.err) {
          throw new Error(comments.err);
        }
        if (comments !== undefined) {
          return dispatch({
            type: ADD_COMMENTS,
            comments
          });
        }
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        dispatch({
          type: FETCHING_COMMENTS,
          status: false
        });
      });
  };
};

export const submitComment = (
  idToken,
  bookId,
  chapterId,
  comment,
  data,
  callback
) => {
  return async dispatch => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/chapters/${chapterId}/comments`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'br-token': idToken
          },
          body: JSON.stringify({
            reaction: data && data.reaction ? data.reaction : null,
            text: comment ? comment : null,
            kind: data ? data.kind : null,
            inlineInfo: data ? data.inlineInfo : null
          })
        }
      );
      const savedComment = await response.json();
      if (savedComment?._id) {
        callback && callback(savedComment._id);
        dispatch({
          type: ADD_COMMENT,
          comment: savedComment
        });
        dispatch({type: CLEAR_ACTIVE_INLINE_COMMENT});
      } else {
        callback && callback(false);
        toast.error(i18n.t('SomethingWentWrong'));
      }
    } catch (error) {
      callback && callback(false);
      toast.error(i18n.t('SomethingWentWrong'));
    }
  };
};

export const deleteComment = (idToken, commentId) => {
  return dispatch => {
    dispatch({type: CLEAR_ACTIVE_INLINE_COMMENT});
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/comments/${commentId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => {
        if (!res.ok) {
          throw new Error(res.error);
        }
        return res.json();
      })
      .then(() => {
        dispatch({
          type: REMOVE_COMMENT,
          commentId
        });
      })
      .catch(err => {
        console.error(err);
        toast.error(i18n.t('SomethingWentWrong'));
      });
  };
};

export const updateComment = (idToken, data) => dispatch => {
  fetch(
    `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${data.bookId}/chapters/${data.chapterId}/comments/${data.commentId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      },
      body: JSON.stringify(data)
    }
  )
    .then(res => res.json())
    .then(comment => {
      dispatch({
        type: UPDATE_COMMENT,
        comment
      });
      dispatch({type: CLEAR_ACTIVE_INLINE_COMMENT});
    });
};

export const updateReply = (idToken, data) => dispatch => {
  fetch(
    `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${data.bookId}/chapters/${data.chapterId}/comments/${data.commentId}/replies/${data.replyId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      },
      body: JSON.stringify(data)
    }
  )
    .then(res => res.json())
    .then(reply => {
      dispatch({
        type: UPDATE_REPLY,
        reply
      });
      dispatch({
        type: SET_ACTIVE_INLINE_COMMENT,
        commentId: reply.comment,
        show: undefined
      });
    });
};

export const submitReply = (idToken, comment, reply, callback) => {
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${comment.book._id}/chapters/${comment.part._id}/comments/${comment._id}/replies`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify({
          commentId: comment._id,
          text: reply.text
        })
      }
    )
      .then(res => res.json())
      .then(savedReply => {
        if (callback) {
          callback(savedReply);
        }
        if (savedReply) {
          dispatch({
            type: ADD_REPLY,
            reply: savedReply
          });
          dispatch({
            type: SET_ACTIVE_INLINE_COMMENT,
            commentId: savedReply.comment,
            show: undefined
          });
        }
      })
      .catch(err => {
        console.log('failed to save reply', err);
      });
  };
};

export const deleteReply = (idToken, data) => dispatch => {
  dispatch({type: CLEAR_ACTIVE_INLINE_COMMENT});
  fetch(
    `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${data.bookId}/chapters/${data.chapterId}/comments/${data.commentId}/replies/${data.replyId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    }
  )
    .then(res => {
      if (!res.ok) {
        throw new Error(res.error);
      }
      return res.json();
    })
    .then(() => {
      dispatch({
        type: REMOVE_REPLY,
        replyId: data.replyId,
        commentId: data.commentId
      });
    })
    .catch(err => {
      console.error(err);
      toast.error(i18n.t('SomethingWentWrong'));
    });
};

export const setActiveInlineComment = ({commentId, show} = {}) => {
  return dispatch => {
    if (commentId && commentId !== 'placeHolder') {
      dispatch({type: REMOVE_EMPTY_INLINE_PLACEHOLDER});
    }
    if (commentId) {
      dispatch({
        type: SET_ACTIVE_INLINE_COMMENT,
        commentId,
        show
      });
    } else {
      dispatch({type: CLEAR_ACTIVE_INLINE_COMMENT});
    }
  };
};

export const editComment =
  ({commentId, editing} = {}) =>
  dispatch => {
    if (commentId) {
      dispatch({
        type: EDIT_COMMENT,
        commentId,
        editing
      });
      dispatch({
        type: SET_ACTIVE_INLINE_COMMENT,
        commentId,
        show: undefined
      });
    }
  };

export const editReply =
  ({commentId, replyId, editing} = {}) =>
  dispatch => {
    if (commentId) {
      dispatch({
        type: EDIT_REPLY,
        commentId,
        replyId,
        editing
      });
      dispatch({
        type: SET_ACTIVE_INLINE_COMMENT,
        commentId,
        show: undefined
      });
    }
  };

export const addNewInlineCommentPlaceholder = (
  xPosition,
  yPosition,
  selectionState,
  userProfile
) => {
  if (yPosition) {
    const comment = {
      _id: `placeHolder-${uuidv4()}}`,
      user: {
        displayName: userProfile.displayName
      },
      createdAt: Date.now(),
      top: yPosition,
      left: xPosition,
      layout: {
        sidebar: undefined,
        inline: undefined
      },
      text: '',
      kind: 'InlineComment',
      inlineInfo: {
        startKey: selectionState.getStartKey(),
        startOffset: selectionState.getStartOffset(),
        endKey: selectionState.getEndKey(),
        endOffset: selectionState.getEndOffset()
      }
    };
    return dispatch => {
      dispatch({
        type: ADD_COMMENT,
        comment
      });
      dispatch({
        type: SET_ACTIVE_INLINE_COMMENT,
        commentId: comment._id,
        show: 'sidebar'
      });
    };
  }
};

export const editCommentPlaceHolderText = (placeHolderId, text) => {
  return dispatch => {
    dispatch({type: EDIT_INLINE_PLACEHOLDER, id: placeHolderId, text});
  };
};

export const removeInlineCommentPlaceholder = placeHolderId => {
  return dispatch => {
    dispatch({type: REMOVE_INLINE_PLACEHOLDER, id: placeHolderId});
  };
};

export const removeEmptyInlineCommentPlaceholder = () => {
  return dispatch => {
    dispatch({type: REMOVE_EMPTY_INLINE_PLACEHOLDER});
  };
};

export const addNewInlineComment = commentData => {
  const comment = {
    ...commentData,
    layout: {
      sidebar: undefined,
      inline: undefined
    }
  };
  return dispatch => {
    dispatch({
      type: ADD_COMMENT,
      comment
    });
  };
};

export const setFilteredReaders = filteredReaders => dispatch => {
  dispatch({
    type: SET_ACTIVE_READER,
    filteredReaders
  });
};

export const removeFilteredReaders = () => dispatch => {
  dispatch({
    type: SET_ACTIVE_READER,
    undefined
  });
};

export const updateCommentFilters = newFilters => dispatch => {
  dispatch({
    type: UPDATE_COMMENT_FILTER,
    filters: newFilters
  });
};

export const updateCommentDomInlineReference = (commentId, layout) => {
  return dispatch => {
    dispatch({
      type: UPDATE_COMMENT_DOM_INLINE_REFERENCE,
      commentId,
      layout
    });
  };
};

export const updateCommentDomSidbarReference = (commentId, layout) => {
  return dispatch => {
    dispatch({
      type: UPDATE_COMMENT_DOM_SIDEBAR_REFERENCE,
      commentId,
      layout
    });
  };
};

export const updateCommentsWithSidebarTop = comments => {
  return dispatch => {
    dispatch({
      type: UPDATE_COMMENTS_SIDEBAR_TOP,
      comments
    });
  };
};

export const clearCommentList = () => dispatch => {
  dispatch({
    type: UPDATE_COMMENTS,
    comments: undefined
  });
};

export const removeComment = commentId => dispatch => {
  dispatch({
    type: REMOVE_COMMENT,
    commentId
  });
};

export const updateInlineCommentPosition = comment => dispatch => {
  dispatch({
    type: UPDATE_COMMENT,
    comment
  });
};
