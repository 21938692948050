import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Icon} from 'semantic-ui-react';

const ReaderSignupQuestionsItem = ({questions, initialOpen}) => {
  const {t} = useTranslation();
  const [show, setShow] = useState(initialOpen);
  if (!questions) {
    return null;
  }
  return (
    <div>
      <strong
        style={{fontSize: '0.8em', padding: 0, cursor: 'pointer'}}
        onClick={() => setShow(!show)}>
        {t('ReaderSignUpForm')}{' '}
        {show ? <Icon name='chevron right' /> : <Icon name='chevron down' />}
      </strong>
      {show &&
        questions.map((question, index) => {
          return (
            <div key={index} style={{fontSize: '0.8em', padding: 0}}>
              <strong>{question.question}</strong>
              <br />
              <em>
                {question.answer ? question.answer.answer : t('NoAnswer')}
              </em>
            </div>
          );
        })}
    </div>
  );
};

export default ReaderSignupQuestionsItem;
