import React, { Component } from 'react';
import {
  Icon,
  Divider,
  Dimmer,
  Header,
  Segment,
  Card,
  Button,
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import firebase from '../../../config/fire';
import { setActiveAccount, resetActiveAccount } from '../../../modules/account';

import { loggedOut } from '../../../modules/user';

const LAST_ACTIVE_ACCOUNT_KEY = 'br-laa';

export class AccountSelector extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { userProfile } = this.props;
    if (userProfile.accountMembership.length === 1) {
      // only one membership, go for that account immediately
      this.setActiveAccount(userProfile.account);
    } else {
      // multiple memberships, go for pre-selected account or ask user
      const lastActiveAccountId = window.localStorage.getItem(
        LAST_ACTIVE_ACCOUNT_KEY
      );
      const activeMembership = userProfile.accountMembership.find(
        membership => membership.account._id === lastActiveAccountId
      );
      if (activeMembership) {
        this.setActiveAccount(activeMembership.account);
      }
    }
  }

  setActiveAccount = account => {
    window.localStorage.setItem(LAST_ACTIVE_ACCOUNT_KEY, account._id);
    this.props.setActiveAccount({ account });
  };

  handleSignout = () => {
    this.props.resetActiveAccount();
    this.props.loggedOut();
    firebase.auth().signOut();
  };

  render() {
    const { activeAccount, userProfile, t } = this.props;
    if (!userProfile || activeAccount) {
      return null;
    }
    return (
      <Dimmer active>
        <Segment>
          <Header size="large">{t('Account')}</Header>
          <Card.Group itemsPerRow={1}>
            {userProfile.accountMembership.map(membership => (
              <Card
                key={membership.account._id}
                onClick={() => this.setActiveAccount(membership.account)}
                style={{ color: 'black' }}
              >
                <Card.Content
                  textAlign="left"
                  header={[
                    <span key="name">
                      {membership.account.owner.displayName}
                    </span>,
                  ]}
                  description={t(`member_role_${membership.role}`)}
                />
              </Card>
            ))}
          </Card.Group>
          <Divider hidden />
          <Button
            basic
            onClick={e => {
              e.preventDefault();
              this.handleSignout();
            }}
            icon="log out"
            content={t('SignOut')}
          />
        </Segment>
      </Dimmer>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  userProfile: state.user.userProfile,
  activeAccount: state.account.activeAccount,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setActiveAccount,
      resetActiveAccount,
      loggedOut,
      changePage: newPage => push(newPage),
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountSelector))
);
