import React from 'react';
import {useSelector} from 'react-redux';
import {Switch, Route, Redirect} from 'react-router-dom';
import styled from 'styled-components';

import OpportunityOverview from './opportunityOverview';
import DiscoverBookList from './discoverBookList';

import {MediaQuery} from '../../constants/size';

const Wrapper = styled.div`
  display: flex;
  padding-bottom: 4em;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  ${MediaQuery.tablet} {
    max-width: calc(
      100vw - ${props => (props.sidebarVisible ? 250 : 0)}px - 2rem
    );
  }
  ${MediaQuery.phone} {
    max-width: calc(100vw - 2rem);
  }
`;

const BetaOpportunities = ({}) => {
  const userProfile = useSelector(state => state.user.userProfile);
  const defaultFontSize = useSelector(state => state.app.defaultFontSize);
  const sidebarVisible = useSelector(state => state.app.sidebarVisible);

  const currentFontSize =
    userProfile !== undefined
      ? userProfile.readerSettings.fontSize
      : defaultFontSize;

  return (
    <Wrapper
      sidebarVisible={sidebarVisible}
      className={`br-text font-size-${currentFontSize}`}>
      <Switch>
        <Route
          exact
          path='/discover/:bookId'
          render={props => (
            <Redirect to={`/books/${props.match.params.bookId}`} />
          )}
        />
        <Route path='/discover/genre/:genre' component={DiscoverBookList} />
        <Route path='/discover' component={OpportunityOverview} />
      </Switch>
    </Wrapper>
  );
};

export default BetaOpportunities;
