import React from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Icon, Button, List, Image, Header, Responsive} from 'semantic-ui-react';
import {Media} from 'src/utils/Media';

import {updateJoinBetaRequest} from '../../modules/book';
import {removeInvitation} from '../../modules/reader';

import {authUtil} from '../../utils';
import {DeviceMinWidths} from '../../constants/size';

const InvitationsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {t} = useTranslation();

  const user = useSelector(state => state.user.user);
  const invitations = useSelector(state => state.reader.invitations);

  const acceptInvitationAndGoToBook = (event, data) => {
    if (event) event.stopPropagation();
    history.push(`/books/${data.bookid}/overview?show=join`);
  };

  const declineInvitation = async (event, data) => {
    if (event) event.stopPropagation();
    const idToken = await authUtil.getFreshIdToken();
    const options = {
      status: 'DECLINED'
    };
    if (!user) {
      goToSignIn();
    } else {
      dispatch(
        updateJoinBetaRequest(idToken, data.bookid, options, succeeded => {
          dispatch(removeInvitation(data.bookid));
        })
      );
    }
  };

  const goToSignIn = () => {
    history.push(
      `/signin?redirect=${encodeURIComponent(
        location.pathname + location.search
      )}`
    );
  };

  return (
    <List selection style={{maxWidth: '100vw'}}>
      <Header style={{paddingTop: '10px', paddingLeft: '10px'}}>
        {t('Invitations')}
      </Header>
      {invitations &&
        invitations.map(invitation => (
          <List.Item key={invitation._id} style={{paddingTop: '5px'}}>
            <div>
              <List.Content floated='left'>
                <Image size='mini' src={invitation.cover.highRes.url} />
              </List.Content>
              <Media
                as={List.Content}
                greaterThanOrEqual='tablet'
                floated='right'>
                <Button
                  bookid={invitation._id}
                  circular
                  negative
                  size='mini'
                  onClick={declineInvitation}>
                  {t('Decline')}
                </Button>
                <Button
                  bookid={invitation._id}
                  circular
                  positive
                  size='mini'
                  onClick={acceptInvitationAndGoToBook}>
                  {t('Accept')}
                </Button>
              </Media>
              <Link
                style={{textOverflow: 'ellipsis'}}
                to={`/books/${invitation._id}`}>
                {invitation.title}
              </Link>
              <List.Description>
                {t('ByAuthor', {_authorName: invitation.authorName})}
              </List.Description>
              <List.Description as='a'>
                <span
                  style={{
                    fontSize: '0.9em',
                    color: 'grey',
                    marginRight: '10px'
                  }}>
                  <Icon name='font' color='grey' />
                  <span style={{fontSize: '0.8em'}}>
                    {invitation.wordCount.toLocaleString()}
                  </span>
                </span>
                <span style={{fontSize: '0.9em', color: 'grey'}}>
                  <Icon name='list ul' color='grey' />
                  <span style={{fontSize: '0.8em', marginLeft: '10px'}}>
                    {invitation.chapterCount.toLocaleString()}
                  </span>
                </span>
              </List.Description>
            </div>
            <Media at='mobile'>
              {(className, renderChildren) =>
                renderChildren && (
                  <List.Content>
                    <Button.Group>
                      <Button
                        bookid={invitation._id}
                        circular
                        negative
                        size='mini'
                        onClick={declineInvitation}>
                        {t('Decline')}
                      </Button>
                      <Button
                        bookid={invitation._id}
                        circular
                        positive
                        size='mini'
                        onClick={acceptInvitationAndGoToBook}>
                        {t('Accept')}
                      </Button>
                    </Button.Group>
                  </List.Content>
                )
              }
            </Media>
          </List.Item>
        ))}
    </List>
  );
};

export default InvitationsList;
