import React, { Component } from 'react';
import {
  Form, Segment, Header, Image, Label, Icon, Button
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

import PostPrivacySelector from '../../components/feed/postPrivacySelector';
import TextAreaWithSave from '../../components/inputs/TextAreaWithSave';

let dropzoneDragCounter = 0;

export class NewBookPost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      files: [],
      privacy: 0
    };
  }

  componentDidMount() {
    // this.fileSelector = this.buildFileSelector();
  }

  onTextChanged = (event, data) => {
    this.setState({
      text: data.value
    });
  }

  postBookPost = () => {
    const { text, privacy, files } = this.state;
    const postData = new FormData();
    postData.append('text', text);
    postData.append('privacy', privacy);
    files.forEach((file, index) => {
      postData.append('images', file, `image_${index}`);
    });
    if (this.props.onNewBookPost) {
      this.props.onNewBookPost(postData, (success) => {
        if (success) {
          this.setState({
            text: '',
            files: []
          });
        }
      });
    }
  }

  onNewFiles = (event) => {
    // Prevent default behavior (Prevent file from being opened)
    let files;
    if (event.target.files) {
      files = [...event.target.files];
    } else if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      files = [...event.dataTransfer.items];
    } else {
      // Use DataTransfer interface to access the file(s)
      files = [...event.dataTransfer.files];
    }
    this.handleFiles(files);
  }

  handleFiles = (files) => {
    const newFiles = [];
    files.forEach((file) => {
      if (file.getAsFile) {
        newFiles.push(file.getAsFile());
      } else {
        newFiles.push(file);
      }
    });
    this.setState(prevState => ({
      files: [...prevState.files, ...newFiles]
    }), () => {
      [...this.state.files].forEach(this.generatePreview);
    });
  }

  generatePreview = (file, index) => {
    // create a file reader
    if (file.preview) {
      return;
    }
    const reader = new FileReader();
    // handle the result of the file reader
    reader.onloadend = () => {
      this.setState((prevState) => {
        const updatedFiles = [...prevState.files];
        updatedFiles[index].preview = reader.result;
        return ({
          files: [...updatedFiles]
        });
      });
    };
    // start reading the file
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  canPost = () => {
    const { text, files } = this.state;
    return (text !== undefined && text.length > 0) || (files !== undefined && files.length > 0);
  }

  toggleImageUpload = (event) => {
    event.preventDefault();
    if (this.fileSelector) {
      this.fileSelector.click();
    }
  }

  fileInputRef = (ref) => {
    this.fileSelector = ref;
  };

  removeFile = (fileIndex) => {
    this.setState(prevState => ({
      files: [...prevState.files].filter((file, index) => index !== fileIndex)
    }));
  }

  preventDefaults = (event) => {
    event.stopPropagation();
    event.preventDefault();
  }

  onDragEnter = (event) => {
    this.preventDefaults(event);
    dropzoneDragCounter += 1;
    if (dropzoneDragCounter > 0) {
      this.setState({
        dropzoneReady: true
      });
    }
  }

  onDragLeave = (event) => {
    this.preventDefaults(event);
    dropzoneDragCounter -= 1;
    if (dropzoneDragCounter === 0) {
      this.setState({
        dropzoneReady: false
      });
    }
  }

  onDrop = (event) => {
    this.preventDefaults(event);
    dropzoneDragCounter = 0;
    this.setState({
      dropzoneReady: false
    });
    this.onNewFiles(event);
  }

  handlePostPrivacyChange = (event, data) => {
    this.setState({ privacy: data.value });
  }

  render() {
    const { t, isPosting, dragging } = this.props;
    const {
      text, files, dropzoneReady, privacy
    } = this.state;
    return (
      <Segment
        as={Form}
        onSubmit={this.postBookPost}
        onDragOver={this.preventDefaults}
        onDragEnter={this.preventDefaults}
        onDragLeave={this.preventDefaults}
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}
      >
        <Header content={t('CreatePost')} />
        {
          (!dragging && !dropzoneReady)
          && (
            [
              <Form.TextArea
                control={TextAreaWithSave}
                key='text'
                placeholder={t('NewBookPostPlaceholder')}
                // autoHeight
                style={{ outline: '0px!important' }}
                value={text}
                onChange={this.onTextChanged}
                onSave={this.postBookPost}
                disabled={isPosting}
              />,
              files ? (
                <Image.Group key='images' size='tiny'>
                  { files.filter(file => file.preview).map((file, index) => {
                    return <Image key={file.preview} bordered src={file.preview} label={<Label onClick={() => this.removeFile(index)} corner='right' as='a'><Icon name='remove' style={{ cursor: 'pointer' }} /></Label>} />
                  })
                  }
                </Image.Group>
              ) : null,
              <Form.Field key='buttons' style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  circular
                  basic
                  icon='image'
                  onClick={this.toggleImageUpload}
                />
                <input ref={this.fileInputRef} multiple='multiple' accept='image/x-png,image/jpeg' type='file' name='images' onChange={this.onNewFiles} style={{ height: 1, visibility: 'hidden', flex: 1 }} />
                <PostPrivacySelector
                  style={{ alignSelf: 'center', marginRight: '1em' }}
                  value={privacy}
                  onChange={this.handlePostPrivacyChange}
                />
                <Button
                  compact
                  disabled={!this.canPost()}
                  content={t('Share')}
                  type='submit'
                  circular
                  loading={isPosting}
                />
              </Form.Field>
            ]
          )
        }
        {
          (dragging || dropzoneReady)
          && (
            <Segment
              style={{ height: 100 }}
              placeholder
              secondary
              className='dropzone'
              raised={dropzoneReady}
              onDragOver={this.preventDefaults}
              onDragEnter={this.onDragEnter}
              onDragLeave={this.onDragLeave}
              onDrop={this.onDrop}
            >
              <Header icon>
                <Icon name='add' />
                {t('DropFilesHere')}
              </Header>
            </Segment>
          )
        }
      </Segment>
    );
  }
}
export default withTranslation()(NewBookPost);
