import React, {Component} from 'react';
import {Popup, Menu, Icon} from 'semantic-ui-react';
import {Media} from 'src/utils/Media';

// redux

import {push} from 'connected-react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
// i18n
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';

import {updateSearchTerm} from '../../modules/betaOpportunities';
import {toggleBrowseMenu} from '../../modules/app';
import genres from '../../shared/constants/genres';

import uxAnalyticsUtil from '../../utils/uxAnalyticsUtil';
import GenreGrid from './genreGrid';
import {DeviceMinWidths} from '../../constants/size';

const Browse = ({
  showBrowseMenu,

  changePage,
  t,
  toggleBrowseMenu,
  updateSearchTerm
}) => {
  const handleOpen = () => {
    uxAnalyticsUtil.trackEvent({
      category: 'Discover',
      action: 'clicked-browse',
      label: 'browse'
    });
    toggleBrowseMenu(true);
  };

  const handleClose = () => {
    toggleBrowseMenu(false);
  };

  const toggleOpen = () => {
    toggleBrowseMenu(!showBrowseMenu);
  };

  const selectedDimension = dimension => {
    uxAnalyticsUtil.trackEvent({
      category: 'Discover',
      action: `clicked-genre-${dimension}`,
      label: 'browse'
    });
    handleClose();
    updateSearchTerm('');
    changePage(`/discover/genre/${dimension}`);
  };

  const handlePress = (event, data) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return [
    <Media key='genrePopup' greaterThan='mobile'>
      {(className, renderChildren) =>
        renderChildren && (
          <Popup
            className={className}
            basic
            trigger={<Menu.Item key='browse'>{t('Browse')}</Menu.Item>}
            open={showBrowseMenu}
            on='click'
            onClose={handleClose}
            onOpen={handleOpen}
            flowing>
            <GenreGrid genres={genres} onClose={handleClose} />
          </Popup>
        )
      }
    </Media>,
    <Media key='genreDropdown' at='mobile'>
      {(className, renderChildren) =>
        renderChildren && (
          <Menu.Item className={className} onClick={toggleOpen}>
            <Icon name='th' />
            {showBrowseMenu && (
              <div
                onClick={handlePress}
                className='browse-responsive-container'>
                <GenreGrid genres={genres} onClose={handleClose} />
              </div>
            )}
          </Menu.Item>
        )
      }
    </Media>
  ];
};

// redux stuff
const mapStateToProps = state => ({
  loadingOpportunities: state.betaOpportunities.loadingOpportunities,
  searchTerm: state.betaOpportunities.searchTerm,
  showBrowseMenu: state.app.showBrowseMenu
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateSearchTerm,
      toggleBrowseMenu,
      changePage: newPage => push(newPage)
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Browse))
);
