import React, {useRef, useState} from 'react';

import {Modal, Button, Input, Form, Icon} from 'semantic-ui-react';

import countWords from 'word-character-count';
import styled from 'styled-components';

// i18n
import {withTranslation, Trans} from 'react-i18next';

import ux from '../../../../utils/uxAnalyticsUtil';
import usePostProcessingContext from './postProcessingContext';
import textUtil from '../../../../utils/textUtil';

const HorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ChapterParser = ({t}) => {
  const {originalDocumentData, setDocumentData, resetDocumentData} =
    usePostProcessingContext();
  const [chapterSeparator, setChapterSeparator] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const timeoutRef = useRef(null);
  const onCancelParse = () => {
    setChapterSeparator('');
    resetDocumentData();
  };
  const updateChapterSeparator = (event, data) => {
    const newChapterSeparator = data.value;
    setChapterSeparator(newChapterSeparator);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (newChapterSeparator.length === 0) {
      setDocumentData({
        ...originalDocumentData
      });
      return;
    }

    // update chapter separation
    timeoutRef.current = setTimeout(() => {
      try {
        setIsProcessing(true);

        // merge all chapters into one
        let doc = '';
        for (let i = 0; i < originalDocumentData.parts.length; i += 1) {
          doc += originalDocumentData.parts[i].html;
        }
        // find all new parts
        let newParts = [];
        const el = document.createElement('body');
        el.innerHTML = doc;
        const blocks = el.children;
        for (let b = 0; b < blocks.length; b += 1) {
          const block = blocks[b];
          const content = block.innerText;
          if (
            newChapterSeparator === '{CAPS}' &&
            textUtil.isCapitalized(content)
          ) {
            // found new part based on all caps check
            newParts.push({
              title: block.innerText,
              html: '',
              wordCount: 0,
              characterCount: 0
            });
          } else if (content.startsWith(newChapterSeparator)) {
            // found new part start
            newParts.push({
              title: block.innerText,
              html: '',
              wordCount: 0,
              characterCount: 0
            });
          } else if (b === 0) {
            // if we didn't get a separator match, but are at the first block, create a part
            newParts.push({
              title: undefined,
              html: block.outerHTML,
              wordCount: 0,
              characterCount: 0
            });
          } else {
            // continuing on an existing part
            const currentPart = newParts.pop() ?? {};
            currentPart.html += block.outerHTML;
            currentPart.wordCount += block.innerText.split(' ').length - 1;
            currentPart.characterCount += block.innerText.length;
            newParts.push(currentPart);
          }
        }
        // remove any parts with 0 word count
        newParts = newParts.filter(p => p.wordCount !== 0);
        setDocumentData({
          ...originalDocumentData,
          parts: [...newParts]
        });
      } catch (error) {
        console.log('failed to find occurences', error);
      } finally {
        setIsProcessing(false);
      }
    }, 1000);
  };
  return (
    <>
      <HorizontalWrapper>{t('ChapterSeparator')}</HorizontalWrapper>
      <Input
        type='text'
        value={chapterSeparator}
        onChange={updateChapterSeparator}
        loading={isProcessing}
        action>
        <input style={{maxWidth: 140}} />
        <Button icon onClick={onCancelParse}>
          <Icon name='close' />
        </Button>
      </Input>
    </>
  );
};

export default withTranslation()(ChapterParser);
