import { track } from '../utils/tracker';

export const ADD_TIME_SPENT = 'monitor/ADD_TIME_SPENT'
export const TOGGLE_SESSION_ACTIVITY = 'monitor/TOGGLE_SESSION_ACTIVITY'
export const CLEAR_TIME_SPENT = 'monitor/CLEAR_TIME_SPENT'
export const MERGE_TIME_SPENT = 'monitor/MERGE_TIME_SPENT'
export const UPDATE_LAST_SEEN = 'monitor/UPDATE_LAST_SEEN'
export const READING_SESSION_STARTED = 'monitor/READING_SESSION_STARTED'
export const UPDATE_FOCUS = 'monitor/UPDATE_FOCUS'
export const UPDATE_OUTSIDE_READER = 'monitor/UPDATE_OUTSIDE_READER'

const initialState = {
  activeReadingTime: 0,
  readingTimeCounters: localStorage.getItem('br-reading-time-tracker') ? JSON.parse(localStorage.getItem('br-reading-time-tracker')) || {} : {},
  readingSessionStarted: undefined,
  lastSeen: undefined,
  isFocused: true,
  isOutsideReader: false
}

// reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FOCUS:
      return {
        ...state,
        isFocused: action.isFocused
      }
    case UPDATE_OUTSIDE_READER:
      return {
        ...state,
        isOutsideReader: action.isOutsideReader
      }
    case ADD_TIME_SPENT:
      var readingTimeCounters = { ...state.readingTimeCounters };
      if (!readingTimeCounters.hasOwnProperty(action.book)) {
        readingTimeCounters[action.book] = {};
      }
      if (!readingTimeCounters[action.book].hasOwnProperty(action.trackingId)) {
        readingTimeCounters[action.book][action.trackingId] = action.data;
      }
      if (!readingTimeCounters[action.book][action.trackingId].hasOwnProperty('event')) {
        readingTimeCounters[action.book][action.trackingId].event = action.data.event;
      } else {
        readingTimeCounters[action.book][action.trackingId].event.count += action.data.event.count;
      }

      // store in local storage to safeguard from browser shutting down
      localStorage.setItem('br-reading-time-tracker', JSON.stringify(readingTimeCounters));

      return {
        ...state,
        activeReadingTime: state.activeReadingTime + action.data.event.count,
        readingTimeCounters: readingTimeCounters
      }
    case CLEAR_TIME_SPENT:
      localStorage.setItem('br-reading-time-tracker', JSON.stringify({}));
      return {
        ...state,
        readingTimeCounters: {}
      }
    default:
      return state
  }
}

// actions
export const addTimeSpent = (data) => {
  return dispatch => {
    dispatch({
      type: ADD_TIME_SPENT,
      book: data.book.id,
      trackingId: `e=${data.event.id}&b=${data.book?.id}&c=${data.chapter?.id}&p=${data.paragraph?.number}`,
      data: data
    })
  }
}

// actions
export const trackTimeSpent = (readingTimeCounters) => {
  return dispatch => {
    // clear the time tracking bucket (to be refilled if we fail to track)
    dispatch({
      type: CLEAR_TIME_SPENT
    });

    for (const book in readingTimeCounters) {
      if (book !== undefined && book !== 'undefined' && readingTimeCounters.hasOwnProperty(book)) {
        const bookCounters = readingTimeCounters[book];
        // create log rows
        const logRows = [];
        for (var trackingId in bookCounters) {
          // check if the property/key is defined in the object itself, not in parent
          if (bookCounters.hasOwnProperty(trackingId)) {
            logRows.push(bookCounters[trackingId]);
          }
        }
        // track log rows
        if (logRows.length > 0) {
          track({
            book: book,
            data: logRows
          });
        }
      }
    }
  }
}

export const updateFocus = (isFocused) => {
  return dispatch => {
    dispatch({
      type: UPDATE_FOCUS,
      isFocused: isFocused
    });
  }
}

export const outSideReader = (isOutsideReader) => {
  return dispatch => {
    dispatch({
      type: UPDATE_OUTSIDE_READER,
      isOutsideReader: isOutsideReader
    });
  }
}