import React, {useState} from 'react';
import {Form, Input, Button, Dropdown} from 'semantic-ui-react';

import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {createReader, updateReader} from '../../modules/reader';

import {authUtil, userProfileUtil} from '../../utils';

const InvitationForm = ({reader, invitationSent, disabled}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentBook = useSelector(state => state.book.currentBook);
  const updatingReader = useSelector(state => state.reader.updatingReader);

  const [userEmail, setUserEmail] = useState(reader?.invitation?.email ?? '');
  const [sendMail, setSendMail] = useState(true);
  const [contentVersion, setContentVersion] = useState();

  const submit = async () => {
    const idToken = await authUtil.getFreshIdToken();
    if (!verifyEmail()) {
      return;
    }
    if (reader) {
      const data = {
        'invitation.email': userEmail,
        contentVersion
      };
      dispatch(updateReader(idToken, currentBook._id, reader._id, data));
    } else {
      dispatch(
        createReader(idToken, {
          bookId: currentBook._id,
          contentVersion:
            contentVersion ||
            currentBook.content[currentBook.content.length - 1]._id, // use the latest version if none is specified
          userEmail,
          sendMail,
          source: 'book'
        })
      );
    }
    if (invitationSent) {
      invitationSent();
    }
  };

  const handleUserEmailChange = (event, data) => {
    setUserEmail(data.value);
  };

  const handleContentVersionChange = (event, data) => {
    setContentVersion(data.value);
    submit(); // todo - do this on useEffect instead?
  };

  const verifyEmail = () => {
    return userProfileUtil.validateEmail(userEmail);
  };

  const contentVersionOptions = currentBook.content.map((version, index) => ({
    key: version._id,
    value: version._id,
    text:
      index === currentBook.content.length - 1
        ? `v${version.versionNumber || 1} (${t('latestVersion')})`
        : `v${version.versionNumber || 1}`
  }));
  return (
    <Form onSubmit={submit}>
      <Form.Field>
        <Input
          fluid
          disabled={disabled}
          placeholder='email@example.com'
          value={userEmail}
          onChange={handleUserEmailChange}
          action={
            contentVersionOptions.length > 1 ? (
              <Dropdown
                floating
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                button
                text={t('Invite')}
                options={contentVersionOptions}
                onChange={handleContentVersionChange}
                loading={!!updatingReader}
                disabled={!verifyEmail()}
              />
            ) : (
              <Button
                type='submit'
                icon='send'
                compact
                loading={!!updatingReader}
                disabled={!verifyEmail()}
              />
            )
          }
        />
      </Form.Field>
    </Form>
  );
};

export default InvitationForm;
