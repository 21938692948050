import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Header, Segment, Container, Visibility, Menu, Icon
} from 'semantic-ui-react';

// i18n
import { withTranslation } from 'react-i18next';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { authUtil } from '../../../utils';
import UserBadge from '../../../components/user/userBadge';
import { PaddedWrapper } from  '../../../style';

export class BookFollowers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      followers: []
    };
  }

  componentDidMount() {
    this.fetchFollowers();
  }

  fetchFollowers = async () => {
    const idToken = await authUtil.getFreshIdToken();
    const { currentBook } = this.props;
    this.setState({
      isLoading: true
    }, () => {
      fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${currentBook._id}/followers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.error);
          }
          return res.json();
        })
        .then((followers) => {
          this.setState({
            followers: followers || []
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.setState({
            isLoading: false
          });
        });
    });
  }

  fetchMoreFollowers = async () => {
    const idToken = await authUtil.getFreshIdToken();
    const { currentBook } = this.props;
    const { followers } = this.state;
    this.setState({
      isUpdating: true
    }, () => {
      fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${currentBook._id}/followers?skip=${followers.length}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.error);
          }
          return res.json();
        })
        .then((moreFollowers) => {
          if (moreFollowers) {
            this.setState(prevState => ({
              followers: [...prevState.followers, ...moreFollowers]
            }));
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.setState({
            isUpdating: false
          });
        });
    });
  }

  render() {
    const { t } = this.props;
    const {
      followers = [],
      isUpdating
    } = this.state;

    return (
      <PaddedWrapper>
        <Header
          content={t('Followers')}
        />
        <Segment.Group>
          {
            followers.map((user, index) => (
              <UserBadge
                key={user._id || index}
                user={user}
                showInviteButton={false}
              />
            ))
          }
        </Segment.Group>
        {
          followers.length > 0 && !followers[followers.length - 1].isLast && (
            /* Add a load more button */
            <Visibility
              offset={[10, 10]}
              once={false}
              onTopVisible={this.fetchMoreFollowers}
              as={Menu}
              text
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Menu.Item
                onClick={this.fetchMoreFollowers}
                content={(
                  <span>
                    {
                      !isUpdating && t('LoadMore')
                    }
                    {
                      isUpdating && (
                        <Icon name='spinner' loading />
                      )
                    }
                  </span>
                )}
              />
            </Visibility>
          )
        }
      </PaddedWrapper>
    );
  }
}


// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  currentBook: state.book.currentBook
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(BookFollowers)));
