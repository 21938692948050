import React, { Component } from 'react';
import Avatar from 'react-avatar';
import styled from 'styled-components';
import { Modal, Icon } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { userColorUtil } from '../../utils';
import PublicUserProfile from '../../containers/user/publicUserProfile';

const AvatarFrame = styled.div`
  display: inline-block;
  border: 2px solid #fff;
  width: ${props => props.diameter ? props.diameter : 20}px;
  height: ${props => props.diameter ? props.diameter : 20}px;
  -webkit-border-radius: 100%; /* Saf3+, Chrome */
  border-radius: 100%; /* Opera 10.5, IE 9 */
  /*-moz-border-radius: 100%;  Disabled for FF1+ */
`;

const AvatarContent = styled(Avatar)`
  width: ${props => props.diameter ? props.diameter : 20}px;
  height: ${props => props.diameter ? props.diameter : 20}px;
  -webkit-border-radius: 100%; /* Saf3+, Chrome */
  border-radius: 100%; /* Opera 10.5, IE 9 */
  /*-moz-border-radius: 100%;  Disabled for FF1+ */
`;

class AvatarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserProfile: false
    };
  }

  getInitials = (name = '') => {
    let initials = (name || '').match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

  showUser = () => {
    this.setState({
      showUserProfile: true
    });
  }

  render() {
    const {
      name, src, diameter = 20, userId, nameOnly, style = {}
    } = this.props;
    const { showUserProfile } = this.state;
    return (
      <React.Fragment>
        {
          nameOnly && (
            <div onClick={userId && this.showUser} style={{ cursor: userId ? 'pointer' : null, ...style }}>
              {name}
            </div>
          )
        }
        {
          !nameOnly && (
            <div style={{ width: diameter, height: diameter }}>
              <AvatarFrame diameter={diameter}>
                <AvatarContent
                  onClick={userId && this.showUser}
                  diameter={diameter}
                  style={{
                    fontWeight: 'bold',
                    margin: 0,
                    display: 'block',
                    cursor: userId ? 'pointer' : null
                  }}
                  textSizeRatio={this.getInitials(name).length <= 1 ? 3 : 2}
                  name={(name || '')}
                  src={src}
                  initials={this.getInitials(name)}
                  round
                  size='100%'
                  color={`${userColorUtil.getUserColor(name || '')}`}
                  {...this.props}
                />
              </AvatarFrame>
            </div>
          )
        }
        {
          userId && showUserProfile && (
            <Modal
              size='small'
              open
              closeIcon
              onClose={() => this.setState({ showUserProfile: false })}
            >
              <Modal.Content>
                <PublicUserProfile userId={userId} />
              </Modal.Content>
            </Modal>
          )
        }
      </React.Fragment>
    );
  }
}

export default withRouter(AvatarComponent);
