export const UPDATE_READING_DATA = 'readerApp/UPDATE_READING_DATA';
export const UPDATE_GLOBAL_PERCENTAGE_PASSED = 'readerApp/UPDATE_GLOBAL_PERCENTAGE_PASSED';
export const UPDATE_SPINE_INDEX = 'readerApp/UPDATE_SPINE_INDEX';
export const RECEIVED_READING_DATA_FROM_BACKEND = 'readerApp/RECEIVED_READING_DATA_FROM_BACKEND';
export const RECEIVED_NO_READING_DATA_FROM_BACKEND = 'readerApp/RECEIVED_NO_READING_DATA_FROM_BACKEND';
export const IGNORE_BACKEND_READING_DATA = 'readerApp/IGNORE_BACKEND_READING_DATA';
export const UPDATED_LOCATION = 'readerApp/UPDATED_LOCATION';
export const RESET_READER = 'readerApp/RESET_READER';

export var pushedReadingDataToDBTimestamp = Date.now();
export const secondsBetweenDBupdates = process.env.NODE_ENV !== 'production' ? 10 : 30;

const initialState = {
  readingData: localStorage.getItem('br-reading-data') ? JSON.parse(localStorage.getItem('br-reading-data')) || {} : {},
  trackingInterval: 1000,
  percentagePassed: undefined,
  currentSpineIndex: undefined,
  backendReadingData: undefined,
  ignoreBackendReadingData: false
};

// reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SPINE_INDEX:
      return {
        ...state,
        currentSpineIndex: action.newSpineIndex
      };
    case UPDATE_READING_DATA:

      // store in localStorage
      localStorage.setItem('br-reading-data', JSON.stringify(action.readingData));
      return {
        ...state,
        readingData: action.readingData
      };
    case UPDATE_GLOBAL_PERCENTAGE_PASSED:
      return {
        ...state,
        percentagePassed: action.percentagePassed
      };
    case RECEIVED_READING_DATA_FROM_BACKEND:
      return {
        ...state,
        backendReadingData: action.backendReadingData
      };
    case RECEIVED_NO_READING_DATA_FROM_BACKEND:
      return {
        ...state,
        backendReadingData: { nodata: true }
      };
    case IGNORE_BACKEND_READING_DATA:
      return {
        ...state,
        ignoreBackendReadingData: true,
        backendReadingData: undefined
      };
    case RESET_READER:
      return {
        ...state, // initialState <- don't go back to initial state
        currentSpineIndex: undefined,
        percentagePassed: 0,
        backendReadingData: undefined,
        ignoreBackendReadingData: false
      };
    default:
      return state;
  }
};

export const saveReadingData = (user, idToken, bookId, data, forceSaveToBackend) => {
  if (!bookId) {
    return dispatch => {};
  }
  // first check if reading data exists
  let readingData = {};
  if (localStorage.getItem('br-reading-data') !== null) {
    readingData = JSON.parse(localStorage.getItem('br-reading-data'));
  }
  // then check if reading data exists for this user
  if (readingData[user.uid] === undefined) {
    readingData[user.uid] = {};
  }
  // then store the reading data for this book
  readingData[user.uid][bookId] = data;

  return (dispatch) => {
    // update the application with the new readingData
    dispatch({
      type: UPDATE_READING_DATA,
      readingData
    });
    if (data && data.globalPercentagePassed !== undefined) {
      // update globalPercentagePassed
      dispatch({
        type: UPDATE_GLOBAL_PERCENTAGE_PASSED,
        percentagePassed: data.globalPercentagePassed
      });
    }
    // if we updated the database longer ago than we should, then push to the database as well (and reset the timestamp)
    const timeSinceDBStorage = (Date.now() - pushedReadingDataToDBTimestamp) / 1000;

    if (timeSinceDBStorage > secondsBetweenDBupdates || forceSaveToBackend) {
      // update the timestamp so that we don't run trigger another request before we get the response
      pushedReadingDataToDBTimestamp = Date.now();

      fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/reading-positions/books/${bookId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify(data)
      })
        .then((res) => {
          if (!res.ok) return Promise.reject(new Error(`HTTP Error ${res.status}`));
          return res.json();
        })
        .then((parsedResponse) => {
          // update the timestamp
          pushedReadingDataToDBTimestamp = Date.now();
        })
        .catch((err) => { console.log('failed to send reading position to backend', err); });
    }
  };
};

export const updateGlobalPercentagePassed = (percentagePassed) => {
  return dispatch => {
    dispatch({
      type: UPDATE_GLOBAL_PERCENTAGE_PASSED,
      percentagePassed: percentagePassed
    })
  }
}


export const updateSpineIndex = (newSpineIndex) => {
  if (newSpineIndex < 0) newSpineIndex = 0;
  return dispatch => {
    dispatch({
      type: UPDATE_SPINE_INDEX,
      newSpineIndex
    })
  }
}

export const fetchReadingData = ({ idToken }, bookId) => {
  if (!bookId) {
    return dispatch => {};
  }
  return dispatch => {
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/reading-positions/books/${bookId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    })
    .then(res => {
      if (!res.ok) return Promise.reject(new Error(`HTTP Error ${res.status}`));
      return res.json();
    })
    .then(readingData => {
      // update the application with the new readingData
      dispatch({
        type: RECEIVED_READING_DATA_FROM_BACKEND,
        backendReadingData: readingData
      })
      if (readingData.globalPercentagePassed !== undefined) {
        dispatch({
          type: UPDATE_GLOBAL_PERCENTAGE_PASSED,
          percentagePassed: readingData.globalPercentagePassed
        });
      }
    })
    .catch(err => {
      console.log('failed to get reading position from backend', err);
      dispatch({
        type: RECEIVED_NO_READING_DATA_FROM_BACKEND
      })
    });
  }
}

export const ignoreBackendReadingData = () => {
  return dispatch => {
    dispatch({
      type: IGNORE_BACKEND_READING_DATA
    })
  }
}

export const updateReadingData = (user, bookId, data, callback) => {
  const readingData = localStorage.getItem('br-reading-data') && JSON.parse(localStorage.getItem('br-reading-data')) || {};
  // then check if reading data exists for this user
  if (readingData[user.uid] === undefined) {
    readingData[user.uid] = {};
  }
  // then store the reading data for this book
  readingData[user.uid][bookId] = data;
  return dispatch => {
    dispatch({
      type: UPDATE_READING_DATA,
      readingData: readingData
    });
    if (data && data.globalPercentagePassed !== undefined) {
      dispatch({
        type: UPDATE_GLOBAL_PERCENTAGE_PASSED,
        percentagePassed: data.globalPercentagePassed
      });
    }
    callback(readingData);
  }
}

export const goToLocation = (chapter, x, y) => {
  //window.scrollTo(0,0);
  return dispatch => {
    dispatch({
      type: UPDATED_LOCATION,
      newLocation: { x, y }
    })
  }
}

// actions
export const resetReader = () => {
  return dispatch => {
    dispatch({
      type: RESET_READER
    })
  }
}
