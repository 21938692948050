const getSymbol = (currency) => {
  let symbol = '';
  switch (currency) {
    case 'USD':
      symbol = '$';
      break;
    default:
  }
  return symbol;
};

export default {
  getSymbol
};
