import React from 'react';

import {
  Dropdown, Icon
} from 'semantic-ui-react';

// i18n
import { withTranslation } from 'react-i18next';

class PostPrivacySelector extends React.PureComponent {
  render() {
    const {
      value, t, onChange, style = {}
    } = this.props;
    const postPrivacyOptions = [
      {
        key: 0,
        value: 0,
        text: t('Readers'),
        icon: <Icon name='users' />
      },
      {
        key: 1,
        value: 1,
        text: t('ReadersAndFollowers'),
        icon: <Icon name='feed' />
      }
    ];
    return (
      <Dropdown
        trigger={postPrivacyOptions[value || 0].icon}
        header={t('WhoCanSeeThis')}
        options={postPrivacyOptions}
        value={value}
        onChange={onChange}
        style={{ ...style }}
      />
    );
  }
}

export default withTranslation()(PostPrivacySelector);
