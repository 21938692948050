import React, {Component} from 'react';

import {
  Button,
  Item,
  Visibility,
  Loader,
  Divider,
  Icon,
  Menu
} from 'semantic-ui-react';
import {withTranslation, Trans} from 'react-i18next';
import {push} from 'connected-react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import queryString from 'query-string';

import {withRouter, Link} from 'react-router-dom';
import {
  listOpportunities,
  loadMoreOpportunities,
  listRecommendations,
  listPremium,
  updateSearchTerm,
  updateSearchFilters
} from '../../modules/betaOpportunities';
import BetaOpportunityItem from './betaOpportunityItem';

import {getFreshIdToken} from '../../utils/authUtil';
import ContentSlider from './contentSlider';
import availableGenres from '../../shared/constants/genres';

let searchTimeout;
const searchTimeoutDelay = 1000;

class OpportunityOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {user, searchFilters = {}} = this.props;
    const {location} = this.props;
    const query = queryString.parse(location.search);
    const {search, lang} = query;
    if (lang) {
      let language = [];
      try {
        language = JSON.parse(lang);
        this.props.updateSearchFilters({
          ...searchFilters,
          language
        });
      } catch (e) {
        // do nothing
      }
    }
    if (search) {
      // trigger search
      this.props.updateSearchTerm(search);
    }
    const options = {
      dimension: 'genre',
      genres: JSON.stringify(availableGenres.slice(0, 3)),
      lang: lang && Array.isArray(lang) && JSON.stringify(lang)
    };
    this.listOpportunities(options);
    // not yet verified to work
    this.listPremium();
    if (user) {
      this.listRecommendations();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userProfile !== this.props.userProfile) {
      this.listRecommendations();
    }
    if (
      this.props.searchTerm !== undefined &&
      prevProps.searchTerm !== this.props.searchTerm
    ) {
      this.searchOpportunities();
    }
    if (
      this.props.searchFilters &&
      prevProps.searchFilters !== this.props.searchFilters
    ) {
      this.searchOpportunities();
    }
  }

  listOpportunities = async queryOptions => {
    const idToken = await getFreshIdToken();
    let options = queryOptions;
    if (!queryOptions) {
      options = this.getQueryOptions();
    }
    // if we don't have any options fallback to default filter
    if (
      typeof options === 'object' &&
      Object.entries(options).length === 0 &&
      options.constructor === Object
    ) {
      options = {
        dimension: 'genre',
        genres: JSON.stringify(availableGenres.slice(0, 3))
      };
    }
    this.props.listOpportunities(idToken, options);
  };

  listPremium = async () => {
    const idToken = await getFreshIdToken();
    const options = {};
    this.props.listPremium(idToken, options);
  };

  searchOpportunities = async () => {
    const idToken = await getFreshIdToken();
    const {searchTerm, searchFilters} = this.props;
    // let options = this.getQueryOptions();
    // if we don't have any options fallback to default filter
    if (searchTimeout) {
      // clear any old timeout
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      const options = {
        search: searchTerm,
        lang:
          (searchFilters?.language ?? []).length > 0
            ? JSON.stringify(searchFilters.language)
            : undefined
      };
      this.props.changePage(`/discover?${queryString.stringify(options)}`);
      this.props.listOpportunities(idToken, options);
    }, searchTimeoutDelay);
  };

  getQueryOptions = () => {
    const {location, userProfile} = this.props;
    const query = queryString.parse(location.search);
    const {search, genres, dimension, skip, limit, dLimit} = query;
    const options = {};
    if (genres) {
      options.genres = JSON.stringify(query.genres.split(','));
    }
    if (dimension) {
      options.dimension = dimension;
    }
    if (skip) {
      options.skip = skip;
    }
    if (limit) {
      options.limit = limit;
    }
    if (dLimit) {
      options.dLimit = dLimit;
    }
    if (search) {
      options.search = search;
    }
    if (userProfile && userProfile.preferredLanguages) {
      options.lang = JSON.stringify(userProfile.preferredLanguages);
    }
    return options;
  };

  listRecommendations = async () => {
    const idToken = await getFreshIdToken();
    this.props.listRecommendations(idToken, this.getQueryOptions());
  };

  loadMore = async () => {
    const idToken = await getFreshIdToken();
    const {books, searchTerm, searchFilters} = this.props;
    this.props.loadMoreOpportunities(idToken, {
      search: searchTerm,
      skip: books.length,
      lang: searchFilters?.language && JSON.stringify(searchFilters.language)
    });
  };

  loadMoreDimensions = async () => {
    const {booksByDimension, searchFilters} = this.props;
    if (availableGenres.length === booksByDimension.length) {
      return;
    }
    const idToken = await getFreshIdToken();
    const filterGenres = availableGenres;
    const options = {
      dimension: 'genre',
      genres: JSON.stringify(
        filterGenres.slice(
          booksByDimension.length,
          availableGenres.length >= booksByDimension.length + 3
            ? booksByDimension.length + 3
            : availableGenres.length
        )
      ),
      lang: searchFilters?.language
    };
    this.props.loadMoreOpportunities(idToken, options);
  };

  getSearchTerm = location => {
    const query = queryString.parse(location.search);
    const {search} = query;
    return search;
  };

  render() {
    const {
      books,
      t,
      loadingOpportunities,
      loadingMoreOpportunities,
      recommendations,
      premium,
      searchTerm,
      booksByDimension,
      searchFilters
    } = this.props;
    // create a placeholder array with the length of the placeholdersSliders we want to create
    const placeholderBooksByDimensions = [1, 2, 3];
    const hasFilter = (searchFilters?.language ?? []).length > 0;
    return (
      <div style={{marginLeft: '5%', marginRight: '5%'}}>
        {!searchTerm && !hasFilter ? (
          <>
            {premium &&
              premium.length > 0 && [
                <ContentSlider
                  key='Premium'
                  large
                  books={premium}
                  header={{
                    title: t('titleDiscoveryPremium'),
                    subheader: t('subheaderDiscoveryPremium')
                  }}
                />,
                <Divider key='dividerPremium' />
              ]}
            {recommendations &&
              recommendations.recommendations &&
              recommendations.recommendations.length > 0 && [
                <ContentSlider
                  key='Recommended'
                  books={recommendations.recommendations}
                  header={{
                    title: t('titleDiscoveryRecommended'),
                    subheader: t('subheaderDiscoveryRecommended')
                  }}
                />,
                <Divider key='dividerRecommended' />
              ]}
            {recommendations &&
              recommendations.recentlyAdded &&
              recommendations.recentlyAdded.length > 0 && (
                <ContentSlider
                  key='contentSliderRecentlyAdded'
                  books={recommendations.recentlyAdded}
                  header={{
                    title: t('titleDiscoveryRecentlyAdded'),
                    subheader: t('subheaderDiscoveryRecentlyAdded')
                  }}
                />
              )}
            {booksByDimension &&
              booksByDimension.length > 0 &&
              booksByDimension.map(dimension => {
                if (dimension.books && !dimension.books.length > 0) {
                  return null;
                }
                return [
                  recommendations && recommendations.recentlyAdded && (
                    <Divider key='dividerRecentlyAddedInGenre' />
                  ),
                  <ContentSlider
                    key='contentSliderRecentlyAddedInGenre'
                    books={dimension.books}
                    genre={dimension.genre}
                    header={{
                      title: dimension.genre,
                      subheader: t('discoveryRecentlyAddedTitlesInGenre', {
                        x: dimension.genre
                      })
                    }}
                  />
                ];
              })}
            {loadingOpportunities &&
              placeholderBooksByDimensions.map(() => [
                <Divider key='dividerPlaceHolder' />,
                <ContentSlider loading key='contentSliderPlaceHolder' />
              ])}
            {booksByDimension && booksByDimension.length > 0 && (
              <Visibility
                offset={[10, 10]}
                once={false}
                onTopVisible={this.loadMoreDimensions}
                as={Menu}
                text
                style={{display: 'flex', justifyContent: 'center'}}>
                <Menu.Item
                  onClick={this.loadMoreDimensions}
                  content={
                    <span>
                      {!loadingOpportunities && t('LoadMore')}
                      {loadingOpportunities && <Icon name='spinner' loading />}
                    </span>
                  }
                />
              </Visibility>
            )}
          </>
        ) : (
          <>
            {books && books.length > 0 && (
              <Item.Group unstackable>
                {loadingOpportunities && <Loader active />}
                {books &&
                  books.map(book => (
                    <BetaOpportunityItem key={book._id} book={book} />
                  ))}
              </Item.Group>
            )}
            {books && books.length === 0 && (
              <Trans i18nKey='NoBookMsg'>
                <p>There is nothing here!</p>
                <p>
                  <span>Do you want to </span>
                  <Link to='/mymanuscripts'>add your own manuscript?</Link>
                </p>
              </Trans>
            )}
            {books.length > 0 && !books[books.length - 1].isLast && (
              <Visibility
                offset={[10, 10]}
                onTopVisible={this.loadMore}
                once={false}
                as={Menu}
                text
                style={{display: 'flex', justifyContent: 'center'}}>
                <Menu.Item
                  onClick={this.loadMore}
                  content={
                    <span>
                      {!loadingMoreOpportunities && t('LoadMore')}
                      {loadingMoreOpportunities && (
                        <Icon name='spinner' loading />
                      )}
                    </span>
                  }
                />
              </Visibility>
            )}
          </>
        )}
      </div>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  userProfile: state.user.userProfile,
  idToken: state.user.idToken,
  books: state.betaOpportunities.books,
  booksByDimension: state.betaOpportunities.booksByDimension,
  recommendations: state.betaOpportunities.recommendations,
  premium: state.betaOpportunities.premium,
  loadingRecommendations: state.betaOpportunities.loadingRecommendations,
  loadingOpportunities: state.betaOpportunities.loadingOpportunities,
  loadingMoreOpportunities: state.betaOpportunities.loadingMoreOpportunities,
  searchTerm: state.betaOpportunities.searchTerm,
  searchFilters: state.betaOpportunities.searchFilters
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      listOpportunities,
      loadMoreOpportunities,
      listRecommendations,
      listPremium,
      updateSearchTerm,
      updateSearchFilters,
      changePage: newPage => push(newPage)
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(OpportunityOverview))
);
