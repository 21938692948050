import React, { Component } from 'react';

import {
  PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip
} from 'recharts';
import { List } from 'semantic-ui-react';

const colors = ['#FC9C04', '#FFBB28', '#5B5A54', '#000000', '#CCCCCC'];

class MultipleChoiceSurveyAnswersChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  parseData = (answers) => {
    const newData = [];
    answers.forEach((answer) => {
      const entryIndex = newData.findIndex(entry => entry.name === answer.answer);
      if (entryIndex >= 0) {
        newData[entryIndex].value += 1;
        newData[entryIndex].readers.add(answer.reader._id);
      } else {
        const newEntry = {
          name: answer.answer,
          value: 1,
          readers: new Set([answer.reader._id])
        };
        newData.push(newEntry);
      }
    });
    return newData;
  }

  render() {
    const { answers } = this.props;
    if (!answers || answers.length === 0) {
      return (
        <List divided>
          <List.Item key='noanswer'>
            <List.Content>
              No answers yet.
            </List.Content>
          </List.Item>
        </List>
      );
    }
    const parsedData = this.parseData(answers);
    return (
      <ResponsiveContainer width='100%' height={250}>
        <PieChart>
          <Pie
            data={parsedData}
            dataKey='value'
            cx='50%'
            cy='50%'
            // innerRadius={60}
            outerRadius={80}
            label
          >
            {
              parsedData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index]}
                  onClick={() => this.props.onFilter([...entry.readers])}
                />
              ))
            }
          </Pie>
          <Legend align='right' layout='vertical' />
          <Tooltip cursor={{ stroke: 'red', strokeWidth: 2 }} />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}

export default MultipleChoiceSurveyAnswersChart;
