import React, { Component } from 'react';
import {
  Dropdown, Header, Form, Button
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

class GenderGetter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      gender: props.initialValue || null
    };
  }

  getGenderOptions = () => {
    const { t } = this.props;
    const genderOptions = [
      {
        key: 'm',
        value: 'm',
        text: t('male')
      },
      {
        key: 'f',
        value: 'f',
        text: t('female')
      },
      {
        key: 'o',
        value: 'o',
        text: t('other')
      },
      {
        key: 'na',
        value: 'na',
        text: t('DontWantToShare')
      }
    ];
    return genderOptions;
  }

  handleChange = (event, data) => {
    const { wizardMode } = this.props;
    this.setState({ gender: data.value }, () => {
      if (wizardMode) {
        this.submit();
      }
    });
  }

  handleKeyDown = (event) => {
    const { gender } = this.state;
    if (event.keyCode === 13 && gender) {
      this.submit();
    }
  }

  submit = () => {
    const { gender } = this.state;
    if (this.props.onUpdate) {
      this.props.onUpdate({ gender });
    }
  }

  // render
  render() {
    const { t, updating, wizardMode } = this.props;
    const { gender } = this.state;
    return [
      <Header
        key='header'
        size='small'
        content={t('Gender')}
        subheader={t('OnYourProfile')}
      />,
      <Form.Field
        control={Dropdown}
        key='input'
        placeholder={t('Gender')}
        fluid
        search
        icon={false}
        value={gender}
        options={this.getGenderOptions()}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        scrolling={false}
      />,
      <Button
        style={{ marginTop: '1em' }}
        key='next'
        floated='right'
        compact
        content={wizardMode ? (gender === null ? t('Skip') : t('Next')) : t('Save')}
        onClick={this.submit}
        loading={!!updating}
      />
    ];
  }
}

export default withTranslation()(GenderGetter);
