import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Message, Icon, Menu } from 'semantic-ui-react';

// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { userProfileUtil } from '../../../utils';

import ProfileInfoGetter from './profileInfoGetter';
import ThankYouMessage from './thankYouMessage';

const Wrapper = styled.div`
  position: fixed;
  z-index: 102;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  justify-content: center;
`;

const ASKED_TO_WAIT = 'br-wait-with-user-data';

class UserProfileBuilder extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const userAskedToWait = localStorage.getItem(ASKED_TO_WAIT);
    if (userAskedToWait) {
      try {
        const timeSinceUserAskedToWait = new Date(userAskedToWait) - Date.now();
        if (timeSinceUserAskedToWait > 1000 * 60 * 60 * 24 * 7) {
          // if the user asked to wait with this more than 7 days ago
          localStorage.removeItem(ASKED_TO_WAIT);
        } else {
          this.setState({
            userAskedToWait: true
          });
        }
      } catch (e) {
        console.error('failed to get timestamp');
      }
    }
  }

  onDoThisLater = (event) => {
    event.preventDefault();
    localStorage.setItem(ASKED_TO_WAIT, Date.now());
    this.setState({ userAskedToWait: true });
  }

  onDoThisNow = (event) => {
    event.preventDefault();
    localStorage.removeItem(ASKED_TO_WAIT);
    this.setState({
      userAskedToWait: false
    });
  }

  // render
  render() {
    const { userProfile, t } = this.props;
    const { askedForProfileData, userAskedToWait } = this.state;
    if (userProfile && userProfileUtil.isMissingInformation(userProfile).length > 0) {
      // return small top message if user asked to wait
      if (userAskedToWait) {
        return (
          <Message style={{ marginTop: 0, minHeight: 45, cursor: 'pointer' }} color='orange' attached='bottom' onClick={this.onDoThisNow}>
            {t('UpdateProfileHeader')}
            <Icon name='chevron right' />
          </Message>
        );
      }
      // else
      return (
        <Wrapper>
          <Menu
            text
            size='mini'
            style={{
              position: 'fixed', top: 0, right: 10
            }}
          >
            <Menu.Item
              position='right'
              onClick={this.onDoThisLater}
            >
              {t('DoThisLater')}
              <Icon name='close' />
            </Menu.Item>
          </Menu>
          <ProfileInfoGetter
            missingInformation={userProfileUtil.isMissingInformation(userProfile)}
            onAsked={() => this.setState({ askedForProfileData: true })}
          />
        </Wrapper>
      );
    }
    if (askedForProfileData) {
      return (
        <Wrapper>
          <ThankYouMessage onCompleted={() => { this.setState({ askedForProfileData: false }); }} />
        </Wrapper>
      );
    }
    return null;
  }
}

// redux stuff
const mapStateToProps = state => ({
  userProfile: state.user.userProfile
});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileBuilder)));
