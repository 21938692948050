import React from 'react';
import {Grid, Placeholder, Responsive} from 'semantic-ui-react';
import {Media} from 'src/utils/Media';

const SliderItemPlaceholder = ({key}) => (
  <Grid
    columns={1}
    style={{marginLeft: 10, marginRight: 10, maxWidth: '500px'}}>
    <Grid.Row key={`${key}placeholderRowArray`} columns={2}>
      <Grid.Column width={6}>
        <Placeholder>
          <Media
            as={Placeholder.Image}
            greaterThanOrEqual='tablet'
            style={{width: '100px', height: '150px'}}
          />
          <Media
            as={Placeholder.Image}
            at='mobile'
            style={{width: '75px', height: '112px'}}
          />
        </Placeholder>
      </Grid.Column>
      <Grid.Column width={10}>
        <Placeholder fluid>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line length='full' />
            <Placeholder.Line length='full' />
            <Placeholder.Line length='full' />
            <Placeholder.Line length='short' />
          </Placeholder.Paragraph>
        </Placeholder>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default SliderItemPlaceholder;
