import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Grid, Label, Popup, Progress, Icon
} from 'semantic-ui-react';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import moment from 'moment';

class ReadingGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
  }

  renderReadingGrid() {
    const { readingProgress, chapters } = this.props;

    return (
      <Grid container divided={true} stretched={true} columns={chapters.length}>
        {
          (readingProgress !== undefined && chapters !== undefined) &&
            <Grid.Row>
              {
                chapters.map((chapter, index, array) => {
                  var color;
                  var progressEntry = readingProgress.find(progressEntry => {
                    return (progressEntry.chapterId === chapter);
                  });

                  if (progressEntry === undefined) {
                    color = 'grey'
                  } else if (progressEntry.COMPLETED_CHAPTER === undefined) {
                    color = 'green'
                  } else if (progressEntry.STARTED_CHAPTER !== undefined) {
                    color = 'yellow';
                  }

                  return (
                    <Grid.Column key={'ch_' + index} width={1} color={color} style={{height:'5px', marginRight:'3px'}}/>
                  )
              })
            }
            </Grid.Row>
        }
      </Grid>
    )
  }


  renderReadingSVG() {
    return (
      <svg width="100%" height="20">
        <rect x="5" y="5" width="5" height="5"/>
      </svg>
    )
  }


  renderReadingLabels() {
    const { readingProgress, chapters } = this.props;

    return (
      <div>
        {
          (readingProgress !== undefined && chapters !== undefined) &&
          (
            <div>
              {
                chapters.map((chapter, index, array) => {
                  let color;
                  let popupContent = '';
                  const progressEntry = readingProgress
                    .find(entry => entry.chapterId === chapter);

                  if (progressEntry === undefined) {
                    color = 'grey';
                    popupContent = (<span>Not yet started</span>);
                  } else if (progressEntry.COMPLETED_CHAPTER !== undefined) {
                    color = 'green';
                    popupContent = (<span>Finished reading {new Date(progressEntry.COMPLETED_CHAPTER).toLocaleDateString()}</span>);
                  } else if (progressEntry.STARTED_CHAPTER !== undefined) {
                    color = 'yellow';
                    popupContent = (<span>Started reading {new Date(progressEntry.STARTED_CHAPTER).toLocaleDateString()}</span>);
                  }

                  return (
                    <Popup
                      key={index}
                      basic flowing
                      trigger={<Label key={index} color={color} empty size='tiny' circular/>}
                      header={chapter + '-' + index}
                      content={popupContent}
                    />

                  )
                })
              }
            </div>
          )
        }
      </div>
    )
  }

  renderReadingIcons() {
    const { readingProgress, chapters } = this.props;
    return (
      <div>
        {
          (readingProgress !== undefined && chapters !== undefined) &&
            <div>
            {
                chapters.map((chapter, index, array) => {
                  var icon;
                  var color;
                  var popupContent = '';
                  var progressEntry = readingProgress.find(progressEntry => {
                    return (progressEntry.chapterId === parseInt(chapter.id));
                  });
                  if (progressEntry === undefined) {
                    icon = 'square';
                    color = 'grey';
                    popupContent = (<span>Not yet started</span>);
                  } else {
                    if (progressEntry.STARTED_CHAPTER !== undefined) {
                      icon = 'square';
                      color = 'orange';
                      popupContent = (<span>Started reading {new Date(progressEntry.STARTED_CHAPTER).toLocaleString()}</span>);
                    }
                    if (progressEntry.COMPLETED_CHAPTER !== undefined) {
                      icon = 'square';
                      color = 'green';
                      popupContent = (<div>{popupContent}<br/><span>Finished reading {new Date(progressEntry.COMPLETED_CHAPTER).toLocaleString()}</span></div>);
                    }
                  }

                  return (
                    <Popup
                      key={index}
                      basic flowing
                      trigger={<Icon key={index} size='tiny' name={icon} color={color} />}
                      header={chapter.title}
                      content={popupContent}
                    />

                  )
              })
            }
          </div>
        }
      </div>
    )
  }

  renderReadingProgress() {
    const { lastRead, chapters } = this.props;
    const index = chapters.findIndex(chapter => {
      return chapter._id === lastRead.chapter;
    })

    return (
      <Progress value={index} total={chapters.length} progress='ratio' />
    )
  }

  renderReadingProgressText() {
    const { readingData, parts, type } = this.props;
    if (readingData) {
      var seenAtDate = type === 'firstSeen' ? readingData.firstSeenAtDate : readingData.lastSeenAtDate;
      var seenAtPart = type === 'firstSeen' ? readingData.firstSeenAtPart : readingData.lastSeenAtPart;
      if (seenAtPart) {
        const index = parts.findIndex(part => {
          return part._id === seenAtPart;
        })
        if (index > -1) {
          if (seenAtDate) {
            return (
              <span><strong>{parts[index].title}</strong><br/>{moment(new Date(seenAtDate)).fromNow()}</span>
            )
          } else {
            return (<span>First seen data not available.</span>)
          }
        } else {
          return (<span>Part info not available</span>)
        }
      }
    } else {
      return (
        <span>{`Not yet started`}</span>
      )
    }

  }

  render() {
    const {
      date, failureMessage
    } = this.props;
    if (date) {
      return (
        <React.Fragment>
          {moment(new Date(date)).fromNow()}<br/>
          <span style={{fontSize:'0.8em', color: 'grey'}}>{moment(new Date(date)).format('L')}</span>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {failureMessage}
      </React.Fragment>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  stats: state.book.stats
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage)
}, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadingGraph));
