import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Divider, Rating, Header, Placeholder
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  updateUserProfile,
  updateUser
} from '../../../modules/user';
import UserReview from './userReview';
import LeaveReview from './leaveReview';
import { authUtil, uxAnalyticsUtil } from '../../../utils';

const UserReviews = ({
  reviewedUser,
  isCurrentUser,
  averageRating,
  reviewCount,

  t,
}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [reviews, setReviews] = useState();
  const [leaveReview, setLeaveReview] = useState(false);

  useEffect(() => {
    fetchUserReviews();
    uxAnalyticsUtil.trackModalView('user-reviews');
  }, []);

  const fetchUserReviews = async () => {
    if (!reviewedUser) {
      return;
    }
    const idToken = await authUtil.getFreshIdToken();
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/users/${reviewedUser._id}/reviews`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        }
        return res.json();
      })
      .then((revs) => {
        setReviews(revs);
      })
      .catch(err => console.error(err))
      .finally(() => {
        setIsLoading(false);
      });
  }

  const onReviewChanged = () => {
    toggleLeaveReview();
    fetchUserReviews();
  }

  const toggleLeaveReview = () => setLeaveReview(!leaveReview);

  // render
  if (isLoading) {
    return (
      <Placeholder>
        <Placeholder.Header>
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      </Placeholder>
    );
  }
  if (leaveReview) {
    return (
      <LeaveReview
        reviewedUser={reviewedUser}
        onReviewChanged={onReviewChanged}
        onCancel={toggleLeaveReview}
      />
    );
  }
  return (
    <React.Fragment>
      <Header
        style={{ display: 'flex', justifyContent: 'space-between' }}
        content={(
          <React.Fragment>
            <span>{reviewedUser.displayName}</span>
            <span>
              <Rating icon='star' rating={averageRating} maxRating={5} disabled />
              <span style={{ fontSize: '0.7em', fontWeight: 'normal', marginLeft: 5 }}>
                (
                  {reviewCount}
                  )
                </span>
            </span>
          </React.Fragment>
        )}
      />
      <Header content={t('Reviews')} />
      {
        !isCurrentUser && (
          <Link as='button' to='#' onClick={toggleLeaveReview}>
            {t('LeaveReview')}
          </Link>
        )
      }
      {
        reviews && reviews.length > 0 && reviews.map((review, index, array) => (
          <UserReview
            review={review}
            lastInList={index < array.length - 1}
          />
        ))
      }
      {
        reviews && reviews.length === 0 && (
          <React.Fragment>
            <Divider horizontal hidden />
            <span>{t('NothingHere')}</span>
          </React.Fragment>
        )
      }
    </React.Fragment>
  );
}

export default withTranslation()(withRouter(UserReviews));
