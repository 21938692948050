import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Message, List, Modal, Icon
} from 'semantic-ui-react';
import queryString from 'query-string';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// i18n
import { withTranslation } from 'react-i18next';

import {
  sendReadingReminder,
  approveJoinRequest,
  declineJoinRequest,
  sendSurveyReminder
} from '../../../modules/reader';

import InActiveReadersList from './inactiveReadersList';
import NonStartersList from './nonStartersList';
import accessUtil from '../../../utils/accessUtil';

export class ReadingNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.getQuery(props.location.search).show
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      // query has changed, update dependencies
      this.setState({
        show: this.getQuery(nextProps.location.search).show
      })
    }
  }

  getQuery = (search) => {
    return queryString.parse(search);
  }

  show = (modal) => {
    const url = `${this.props.location.pathname}?show=${modal}`;
    this.props.history.replace(url)
  }

  showInActiveReaders = (e) => {
    // e.preventDefault();
    this.show('inActiveReaders');
  }

  showNonStarters = (e) => {
    // e.preventDefault();
    this.show('nonStarters');
  }

  showJoinRequests = (e) => {
    // e.preventDefault();
    this.show('joinRequests');
  }

  closeModals = () => {
    this.show('');
  }

  // rendering
  render() {
    const {
      inActiveReaders, joinRequests, nonStarters, t, access
    } = this.props;
    const showNotificationMessage = (inActiveReaders && inActiveReaders.length > 0) || (joinRequests && joinRequests.length > 0) || (nonStarters && nonStarters.length > 0);
    const readOnly = !accessUtil.isAllowed(access, ['edit']);
    if (showNotificationMessage) {
      return (
        <Message info>
          <List selection>
            {
              inActiveReaders && inActiveReaders.length > 0 &&
              <Modal
                id='inactive-readers-modal'
                open={this.state.show === 'inActiveReaders'}
                closeIcon
                onClose={this.closeModals}
                trigger={
                  <List.Item onClick={this.showInActiveReaders}>
                    <List.Content floated='left'>
                      <Icon name='clock outline' />
                    </List.Content>
                    <List.Content floated='right'><Link to={'#'}>{t('show')}</Link></List.Content>
                    <List.Content>{t('XReadersInactiveForYDays', {count: inActiveReaders.length, days: 7})}</List.Content>
                  </List.Item>
                }>
                <Modal.Header><Icon name='users'/> {t('InactiveReaders')}</Modal.Header>
                <Modal.Content>
                  <InActiveReadersList readOnly={readOnly} readerEntries={inActiveReaders} onClose={this.closeModals}/>
                </Modal.Content>
              </Modal>
            }
            {
              nonStarters && nonStarters.length > 0 &&
              <Modal
                id='nonstarters-modal'
                open={this.state.show === 'nonStarters'}
                closeIcon
                onClose={this.closeModals}
                trigger={
                  <List.Item onClick={this.showNonStarters}>
                    <List.Content floated='left'>
                      <Icon name='clock outline' />
                    </List.Content>
                    <List.Content floated='right'><Link to={'#'}>{t('show')}</Link></List.Content>
                    <List.Content>{t('XReadersNotStartedForYDays', {count: nonStarters.length, days: 7})}</List.Content>
                  </List.Item>
                }>
                <Modal.Header><Icon name='users'/> {t('ReadersWhoHaveNotAcceptedTheInvitation')}</Modal.Header>
                <Modal.Content>
                  <NonStartersList readOnly={readOnly} readerEntries={nonStarters} onClose={this.closeModals}/>
                </Modal.Content>
              </Modal>
            }
          </List>

        </Message>
      )
    } else {
      return null;
    }

  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  currentBook: state.book.currentBook,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  sendReadingReminder,
  approveJoinRequest,
  declineJoinRequest,
  sendSurveyReminder,
  changePage: (newPage) => push(newPage),
}, dispatch)

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadingNotifications)));
