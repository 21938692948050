import React from 'react';
import {Route, Switch} from 'react-router-dom';
// UI components
import {Grid, Divider, Responsive} from 'semantic-ui-react';
import {Media} from 'src/utils/Media';
import InboxMenu from './inboxMenu';
import Conversation from './conversation';
import ConversationList from './conversationList';
import ConversationHeader from './conversationHeader';
import InputBar from './inputBar';

// styling
import './chat.css';

const MessageInbox = props => (
  <Grid style={{flex: 1, marginRight: 0}}>
    <Grid.Row>
      <Media // Large screens
        greaterThan='mobile'>
        {(className, renderChildren) =>
          renderChildren && (
            <Grid.Column width={4}>
              <Route path={`${props.match.path}`}>
                <InboxMenu newMessageButton />
              </Route>
            </Grid.Column>
          )
        }
      </Media>
      <Media // medium screens
        at='tablet'>
        {(className, renderChildren) =>
          renderChildren && (
            <Grid.Column width={4}>
              <Grid.Row>
                <Route path={`${props.match.path}`}>
                  <InboxMenu newMessageButton />
                </Route>
              </Grid.Row>
            </Grid.Column>
          )
        }
      </Media>
      <Media // small screens
        at='mobile'>
        {(className, renderChildren) =>
          renderChildren && (
            <Grid.Column width={2}>
              <Grid.Row>
                <Switch>
                  <Route
                    path={`${props.match.path}/conversations/:conversationId`}>
                    <InboxMenu backButton />
                  </Route>
                  <Route path={`${props.match.path}/`}>
                    <InboxMenu newMessageButton />
                  </Route>
                </Switch>
              </Grid.Row>
            </Grid.Column>
          )
        }
      </Media>
      <Media // small screens
        at='mobile'>
        {(className, renderChildren) =>
          renderChildren && (
            <Grid.Column width={14}>
              <Grid.Row>
                <Switch>
                  <Route
                    path={`${props.match.path}/conversations/:conversationId`}>
                    <ConversationHeader />
                  </Route>
                </Switch>
              </Grid.Row>
            </Grid.Column>
          )
        }
      </Media>
      <Media // small screens
        greaterThan='mobile'>
        {(className, renderChildren) =>
          renderChildren && (
            <Grid.Column width={12}>
              <Grid.Row>
                <Switch>
                  <Route
                    path={`${props.match.path}/conversations/:conversationId`}>
                    <ConversationHeader />
                  </Route>
                </Switch>
              </Grid.Row>
            </Grid.Column>
          )
        }
      </Media>
    </Grid.Row>
    <Divider // dividing header and message list / newMessage / active conversation
      fitted
      style={{marginTop: 0, marginBottom: 0}}
    />
    <Grid.Row style={{paddingTop: 0, paddingBottom: 0, width: '100%'}}>
      <Media // Large screens
        greaterThan='tablet'>
        {(className, renderChildren) =>
          renderChildren && (
            <Grid.Column width={4}>
              <Grid.Row
                className='scrollable-container'
                style={{height: '82vh'}}>
                <Switch>
                  <Route
                    path={`${props.match.path}`}
                    component={ConversationList}
                  />
                </Switch>
              </Grid.Row>
            </Grid.Column>
          )
        }
      </Media>
      <Media greaterThan='tablet'>
        {(className, renderChildren) =>
          renderChildren && (
            <Grid.Column
              width={12}
              style={{
                flex: 1,
                flexGrow: 1,
                paddingRight: 0
              }}>
              <div
                style={{
                  height: '70vh'
                }}
                id='message-list-scrollable'
                className='scrollable-container'>
                <Switch>
                  <Route
                    path={`${props.match.path}/conversations/:conversationId`}>
                    <Conversation />
                  </Route>
                  <Route path={`${props.match.path}`}>
                    <Conversation />
                  </Route>
                </Switch>
              </div>
              <InputBar key='inputbar' />
            </Grid.Column>
          )
        }
      </Media>
      <Media // medium screens
        at='tablet'>
        {(className, renderChildren) =>
          renderChildren && (
            <Grid.Column
              width={3}
              className='scrollable-container'
              style={{
                display: 'flex',
                height: '65vh'
              }}>
              <ConversationList collapsed />
            </Grid.Column>
          )
        }
      </Media>
      <Media at='tablet'>
        {(className, renderChildren) =>
          renderChildren && (
            <Grid.Column
              width={13}
              style={{
                flex: 1,
                paddingRight: 0,
                flexGrow: 1
              }}>
              <div
                style={{
                  height: '65vh'
                }}
                id='message-list-scrollable'
                className='scrollable-container'>
                <Switch>
                  <Route
                    path={`${props.match.path}/conversations/:conversationId`}
                    component={Conversation}
                  />
                  <Route
                    path={`${props.match.path}/`}
                    component={Conversation}
                  />
                </Switch>
              </div>
              <InputBar key='inputbar' />
            </Grid.Column>
          )
        }
      </Media>
      <Media // small screens
        at='mobile'>
        {(className, renderChildren) =>
          renderChildren && (
            <Grid.Column
              width={16}
              style={{
                paddingRight: 0
              }}>
              <div
                style={{
                  height: '65vh'
                }}
                id='message-list-scrollable'
                className='scrollable-container'>
                <Switch>
                  <Route
                    path={`${props.match.path}/conversations/:conversationId`}
                    component={Conversation}
                  />
                  <Route
                    path={`${props.match.path}/`}
                    component={ConversationList}
                  />
                </Switch>
              </div>
              <InputBar key='inputbar' />
            </Grid.Column>
          )
        }
      </Media>
    </Grid.Row>
  </Grid>
);

export default MessageInbox;
