import React, { Component } from 'react';
import {
  Label
} from 'semantic-ui-react';

class LanguageList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      languages, noInfoMessage
    } = this.props;
    if (!languages || !Array.isArray(languages) || languages.length === 0) {
      return (
        <span>
          {noInfoMessage}
        </span>
      );
    }

    return languages.map(language => (
      <Label
        key={language.key}
        style={{ marginRight: 5, marginTop: 5 }}
        content={language.name || language.nativeName || language.englishName}
      />
    ));
  }
}

export default LanguageList;
