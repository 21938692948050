import React, { Component } from 'react';
import {
  Dropdown, Header, Button, Divider, Confirm, Checkbox, Form
} from 'semantic-ui-react';
import {
  withRouter, Link
} from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components';


import {
  deleteVersion,
  duplicateVersion
} from '../../modules/book';
import VersionSelector from '../menu/versionSelector';
import DownloadVersion from './downloadVersion';
import PartsList from '../menu/partsList';
import { authUtil } from '../../utils';

const Wrapper = styled.div`
  flex: 1;
  max-width: 768px;
  padding: 0 1em 0 1em;
`;

export class VersionOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copyComments: false,
      migrateReaders: false
    };
  }

  addNewVersion = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { idToken, currentBook } = this.props;
    this.props.createNewVersion(idToken, currentBook._id, [], (newBookVersion) => {
      this.props.history.replace(`/books/${currentBook._id}/content/v${newBookVersion.content.length}`)
    });
  }

  prompt = (prompt) => {
    this.setState({
      prompt
    });
  }

  confirmDelete = async () => {
    const { currentBook, currentContentVersion } = this.props;
    const idToken = await authUtil.getFreshIdToken();
    this.props.deleteVersion(idToken, currentBook._id, currentContentVersion._id, () => {
      this.setState({
        prompt: false
      });
      this.props.history.replace(`/books/${currentBook._id}/content/v${currentBook.content.length}`)
    });
  }

  confirmDuplicate = async () => {
    const { currentBook, currentContentVersion } = this.props;
    const { copyComments, migrateReaders } = this.state;
    this.setState({
      isDuplicating: true
    });
    const idToken = await authUtil.getFreshIdToken();
    this.props.duplicateVersion(idToken, {
      bookId: currentBook._id,
      contentVersionId: currentContentVersion._id,
      copyComments,
      migrateReaders,
      callback: (updatedBook) => {
        this.setState({
          prompt: false,
          isDuplicating: false
        });
        this.props.history.replace(`/books/${currentBook._id}/content/v${updatedBook.content[updatedBook.content.length - 1].versionNumber}`)
      }
    });
  }

  handleCopyCommentsChange = (event, data) => {
    this.setState({
      copyComments: data.checked
    });
  }

  handleMigrateReadersChange = (event, data) => {
    this.setState({
      migrateReaders: data.checked
    });
  }

  render() {
    const {
      t, currentContentVersion, currentBook
    } = this.props;
    const {
      prompt, copyComments, migrateReaders, isDuplicating
    } = this.state;
    if (!currentContentVersion) {
      return null;
    }
    const parts = currentContentVersion.parts || [];
    const chapters = parts.filter(part => part.kind === 'Chapter');
    const surveys = parts.filter(part => part.kind === 'Survey');
    const canDeleteVersion = parts.length === 0 && currentBook.content.length > 1;
    const canDuplicateVersion = parts.length > 0;
    return (
      <Wrapper>
        <Header
          content={(
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ flex: 1 }}>
                {t('Version')}
                :
                {' '}
                <VersionSelector key='version-selector' inverted size='tiny' pointing='left' />
              </span>
              <Button.Group>
                <DownloadVersion
                  compact
                  bookId={currentBook._id}
                  content={[currentContentVersion]}
                />
                <Button
                  basic
                  disabled={!canDuplicateVersion}
                  icon='clone'
                  onClick={() => this.prompt('duplicate')}
                />
              </Button.Group>
            </div>
          )}
          subheader={`${t('ChapterCount', { count: chapters.length })}, ${t('SurveyCount', { count: surveys.length })}`}
        />
        <span>
          <Link to={`/books/${currentBook._id}/content/v${currentContentVersion.versionNumber}/new-chapter`}>{t('NewChapter')}</Link>
          {' '}
          <Link to={`/books/${currentBook._id}/content/v${currentContentVersion.versionNumber}/new-survey`}>{t('NewSurvey')}</Link>
        </span>
        <PartsList />
        <Divider />
        {currentBook.content.length > 1 && (
          <Button
            fluid
            negative
            disabled={!canDeleteVersion}
            content={t('Delete')}
            onClick={() => this.prompt('delete')}
          />
        )}
        {
          prompt === 'delete' && (
            <Confirm
              open
              onCancel={() => this.prompt(false)}
              onConfirm={this.confirmDelete}
              header={<Header content={t('AreYouSure?')} />}
              content={t('ThisCannotBeUndone')}
              confirmButton={t('Delete')}
            />
          )
        }
        {
          prompt === 'duplicate' && (
            <Confirm
              open
              onCancel={() => this.prompt(false)}
              onConfirm={this.confirmDuplicate}
              header={<Header content={t('Duplicate')} />}
              content={(
                <div style={{ padding: '1.5rem', lineHeight: 1.4 }}>
                  <span>{t('DuplicateVersionInfo')}</span>
                  <Divider hidden />
                  <Form>
                    <Form.Field>
                      <Checkbox
                        label={t('CopyComments')}
                        toggle
                        checked={copyComments}
                        onChange={this.handleCopyCommentsChange}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Checkbox
                        label={t('MigrateReaders')}
                        toggle
                        checked={migrateReaders}
                        onChange={this.handleMigrateReadersChange}
                      />
                    </Form.Field>
                  </Form>
                </div>
              )}
              confirmButton={(
                <Button
                  content={t('Duplicate')}
                  loading={isDuplicating}
                />
              )}
            />
          )
        }
      </Wrapper>
    );
  }
}


// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  currentBook: state.book.currentBook,
  currentContentVersion: state.book.currentContentVersion
});

const mapDispatchToProps = dispatch => bindActionCreators({
  deleteVersion,
  duplicateVersion
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(VersionOverview)));
