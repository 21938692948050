import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Modal, Dimmer, Button, Loader, Form, Header, Radio, TextArea
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import fire from '../../../config/fire';


import {
  deleteUser
} from '../../../modules/user';

import {
  getUserProfile
} from '../../../modules/user';

import { authUtil, uxAnalyticsUtil } from '../../../utils';

const STAGE = {
  INITIATED: 0,
  ANSWERING_SURVEY: 1,
  COMPLETING: 2,
  DONE: 3
};

const reasonOptions = [
  'technical_issues',
  'too_expensive',
  'finished_my_beta',
  'switching_to_another_product',
  'missing_features',
  'did_not_meet_expectations',
  'other'
];

const DeleteAccountModal = ({
  changePage,
  deleteUser,
  t
}) => {
  const [currentStage, setCurrentStage] = useState(STAGE.INITIATED);
  const [reason, setReason] = useState('');
  const [feedback, setFeedback] = useState('');
  const [open, setOpen] = useState(false);

  const handleDeleteUser = async () => {
    const idToken = await authUtil.getFreshIdToken();
    setCurrentStage(STAGE.COMPLETING);
    deleteUser({
      idToken,
      reason,
      feedback
    }, (success) => {
      if (!success) {
        toast.error(t('SomethingWentWrong'));
        uxAnalyticsUtil.trackException({
          description: 'Failed to delete user',
          fatal: true
        });
        setOpen(false);
      } else {
        // track cancellation to GA
        uxAnalyticsUtil.trackEvent({
          category: 'User',
          action: 'deleted-account'
        });
        onSuccess();
      }
    });
  }

  const onSuccess = async () => {
    // log out with message
    await fire.auth().signOut();
    changePage('/goodbye');
  }

  const handleConfirmClick = () => {
    switch (currentStage) {
      case STAGE.INITIATED:
        setCurrentStage(STAGE.ANSWERING_SURVEY);
        break;
      case STAGE.ANSWERING_SURVEY:
        handleDeleteUser();
        break;
      default:
        break;
    }
  }

  const handleReasonChange = (event, data) => {
    setReason(data.value);
  }

  const handleFeedbackChange = (event, data) => {
    setFeedback(data.value);
  }

  return (
    <>
      <Button
        content={t('DeleteAccount')}
        icon='trash alternate'
        negative
        onClick={() => setOpen(!open)}
      />
      <Modal
        key='delete-account-modal'
        open={open}
        closeOnEscape
        closeOnDimmerClick
        size='tiny'
        onClose={() => setOpen(false)}
      >
        <Modal.Header>{t('DeleteAccount')}</Modal.Header>
        <Modal.Content>
          {currentStage === STAGE.INITIATED && (
            <Header content={t('DeleteAccountConfirmation')} size='tiny' />
          )}
          {currentStage === STAGE.COMPLETING && (
            <Dimmer active inverted>
              <Loader
                active
                inline='centered'
                content={t('WaitingForConfirmation')}
              />
            </Dimmer>
          )}
          {currentStage === STAGE.ANSWERING_SURVEY && (
            <Form>
              <Header content={t('CancelReason')} size='tiny' />
              {reasonOptions.map(reasonOption => (
                <Form.Field key={reasonOption}>
                  <Radio
                    label={t(`cancel_reason_${reasonOption}`)}
                    name='CancelReason'
                    value={reasonOption}
                    checked={reason === reasonOption}
                    onChange={handleReasonChange}
                  />
                </Form.Field>
              ))}
              <Form.Field>
                <TextArea
                  placeholder={t('TellUsMore')}
                  value={feedback}
                  onChange={handleFeedbackChange}
                />
              </Form.Field>
            </Form>
          )}
          {currentStage === STAGE.DONE && (
            <Header content={t('YourAccountHasBeenDeleted')} size='tiny' />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color='grey'
            basic
            disabled={currentStage === STAGE.COMPLETING}
            onClick={() => setOpen(false)}
          >
            {currentStage === STAGE.INITIATED && t('No')}
            {currentStage === STAGE.ANSWERING_SURVEY && t('Back')}
          </Button>
          <Button
            negative
            disabled={currentStage === STAGE.COMPLETING}
            onClick={handleConfirmClick}
          >
            {currentStage === STAGE.INITIATED && t('Yes')}
            {currentStage === STAGE.ANSWERING_SURVEY && t('DeleteAccount')}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  userProfile: state.user.userProfile
});

const mapDispatchToProps = dispatch => bindActionCreators({
  deleteUser,
  getUserProfile,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteAccountModal)));
