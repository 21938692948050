import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import {
  Container, Button, Grid, Header, Rating, Form, TextArea, Message
} from 'semantic-ui-react';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';

import {
  fetchReview, updateReview, submitReview, clearReview
} from '../../../../modules/review';
import { fetchCurrentBookAsReader, resetCurrentBook } from '../../../../modules/book';

import { getFreshIdToken } from '../../../../utils/authUtil';

export class Review extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviewText: '',
      overAllRating: null,
      plotRating: null,
      grammarRating: null,
      overAllRatingMissing: false,
      reviewAlreadyAdded: false,
      textAreaHeight: 100
    };
  }

  componentDidMount() {
    const { idToken, user } = this.props;
    const bookId = this.getCurrentBookId();
    this.setState({ bookId });
  }

  componentDidUpdate(prevProps) {
    const { bookId } = this.state;
    const {
      currentBook, loadingCurrentBook, idToken, user, activeReview, fetchingReview
    } = this.props;
    if (user !== undefined && currentBook === undefined && idToken !== undefined && !loadingCurrentBook) {
      this.props.fetchCurrentBookAsReader(idToken, bookId);
    }
    if (idToken !== undefined && !activeReview && bookId !== undefined && currentBook !== undefined && !fetchingReview) {
      this.props.fetchReview(idToken, bookId, review => this.setState({
        reviewText: review.text !== null ? review.text : '',
        overAllRating: review.overAllRating,
        plotRating: review.plotRating,
        grammarRating: review.grammarRating
      }));
    }
  }

  componentWillUnmount() {
    this.props.clearReview();
    this.props.resetCurrentBook();
  }

  getCurrentBookId = () => {
    const parsedQueryString = queryString.parse(this.props.location.search);
    const bookId = parsedQueryString.book;
    return bookId;
  }

  onChangeGeneralRating = (value) => {
    this.setState({
      overAllRating: value.rating,
      overAllRatingMissing: false
    });
  }

  onChangeplotRating = (value) => {
    this.setState({
      plotRating: value.rating
    });
  }

  onChangeGrammarRating = (value) => {
    this.setState({
      grammarRating: value.rating
    });
  }

  onChangeReviewText = (event, data) => {
    const textAreaHeight = event.currentTarget ?
      event.currentTarget.scrollHeight : 100;
    this.setState({
      reviewText: data.value,
      currentLength: data.value ? data.value.length : 0,
      textAreaHeight
    });
  }

  addReview = async () => {
    const {
      reviewText, overAllRating, plotRating, grammarRating, bookId
    } = this.state;
    const { activeReview } = this.props;
    const review = {
      overAllRating,
      plotRating,
      grammarRating,
      text: reviewText
    };
    if (!review.overAllRating) {
      this.setState({ overAllRatingMissing: true })
      return;
    }
    const idToken = await getFreshIdToken();
    // submit review and go to home view
    if (activeReview && activeReview.overAllRating) {
      this.props.updateReview(idToken, bookId, activeReview._id, review, e => this.handleServerResponse(e));
    } else {
      this.props.submitReview(idToken, bookId, review, e => this.handleServerResponse(e));
    }
  }

  handleServerResponse = (e) => {
    const { bookId } = this.state;
    if (e.status && e.status === 403) {
      this.setState({ reviewAlreadyAdded: true });
    } else {
      this.props.changePage(`/discover/${bookId}`)
    }
  }

  render() {
    const {
      t, userProfile, defaultFontSize, currentBook, activeReview, history
    } = this.props;
    const {
      reviewText, textAreaHeight, overAllRatingMissing, reviewAlreadyAdded, currentLength
    } = this.state;
    const currentFontSize = userProfile !== undefined ? userProfile.readerSettings.fontSize : defaultFontSize;
    const minValue = 0;
    const maxValue = 5;
    let review = null;
    if (activeReview) {
       review = activeReview;
    }

    return (
      <Container className={`br-text font-size-${currentFontSize}`}>
        <Grid stackable={userProfile && userProfile.readerSettings.fontSize === 'big' ? true : false} verticalAlign='middle' columns='equal'>
          <Grid.Row>
            <Grid.Column>
              <Header as='h1' className='br-text'>
                {t('Review')}
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign='left'>
              <Header as='h2' className={`br-text font-size-${currentFontSize}`}>
                {currentBook && currentBook.title}
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{paddingBottom:0}}>
            <Grid.Column textAlign='left' mobile={6} tablet={3} computer={3}>
              <Header as='h5' className={`br-text font-size-${currentFontSize}`}>
                {t('Overall')}
              </Header>
            </Grid.Column>
            <Grid.Column>
              <div>
                { review !== null && (
                  <Rating
                    icon='star'
                    defaultRating={activeReview.overAllRating !== null ? activeReview.overAllRating : minValue}
                    maxRating={maxValue}
                    size='massive'
                    onRate={(event, data) => {
                      this.onChangeGeneralRating(data)
                    }}
                  />
                )
                }
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Grid.Column textAlign='left' mobile={6} tablet={3} computer={3}>
              <Header as='h5' className={`br-text font-size-${currentFontSize}`}>
                {t('Plot')}
              </Header>
            </Grid.Column>
            <Grid.Column>
              {review !== null && (
                <Rating
                  icon='star'
                  defaultRating={review.plotRating !== null ? review.plotRating : minValue}
                  maxRating={maxValue}
                  size='massive'
                  onRate={(event, data) => {
                    this.onChangeplotRating(data);
                  }}
                />
              )
              }
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Grid.Column textAlign='left' mobile={6} tablet={3} computer={3}>
              <Header as='h5' className={`br-text font-size-${currentFontSize}`}>
                {t('Grammar')}
              </Header>
            </Grid.Column>
            <Grid.Column>
              {
                review !== null && (
                  <Rating
                    icon='star'
                    defaultRating={review.grammarRating !== null ? review.grammarRating : minValue}
                    maxRating={maxValue}
                    size='massive'
                    onRate={(event, data) => {
                      this.onChangeGrammarRating(data);
                    }}
                  />
                )
              }
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <div style={{ width: '100%', paddingLeft: 14, paddingRight: 14 }}>
              <Form onSubmit={this.addReview}>
                <Form.Field
                  style={{ height: textAreaHeight }}
                  className={`answer br-text font-size-${currentFontSize}`}
                  id='form-textarea-control-opinion'
                  control={TextArea}
                  maxLength={20000}
                  placeholder={t('LeaveReview')}
                  value={reviewText}
                  onChange={this.onChangeReviewText}
                />
                {
                  currentLength && currentLength > 0 && (
                    <span style={{ float: 'right' }}>
                      {`${currentLength} / ${Number(20000).toLocaleString()}`}
                    </span>
                  )
                }
                {
                  overAllRatingMissing && (
                    <Message info>
                      <Message.Header>
                        {t('OverallRatingRequired')}
                      </Message.Header>
                    </Message>
                  )
                }
                {
                  reviewAlreadyAdded && (
                    <Message info>
                      <Message.Header>
                        {t('Review already added')}
                      </Message.Header>
                    </Message>
                  )
                }
                <Form.Field
                  inline
                  floated='right'
                  id='form-button-control-public'
                  control={Button}
                  content={t('LeaveReview')}
                  type='submit'
                />
              </Form>
              <Button
                basic
                color='grey'
                style={{ marginRight: 5 }}
                floated='right'
                onClick={() => history.push('/')}
              >
                {t('Skip')}
              </Button>
            </div>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  currentLanguage: state.user.currentLanguage,
  currentBook: state.book.currentBook,
  loadingCurrentBook: state.book.loadingCurrentBook,
  fontSizes: state.app.fontSizes,
  defaultFontSize: state.app.defaultFontSize,
  reviews: state.review.reviews,
  addReview: state.review.addReview,
  activeReview: state.review.activeReview,
  fetchingReview: state.review.fetchingReview,
  previousReviewExists: state.review.previousReviewExists
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchReview,
  updateReview,
  submitReview,
  fetchCurrentBookAsReader,
  clearReview,
  resetCurrentBook,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Review)));
