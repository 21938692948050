import React from 'react';
import {
  withRouter
} from 'react-router-dom';
import {
  Menu, Ref
} from 'semantic-ui-react';

// i18n
import { withTranslation } from 'react-i18next';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import PartsList from './partsList';

const BookContentMenu = ({
  currentBook
}) => {
  if (!currentBook) return null;
  return (
    <Menu.Item
      key='parts'
      content={(
        <PartsList key='parts-list' inverted size='tiny' />
      )}
    />
  )
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  currentBook: state.book.currentBook
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(BookContentMenu)));
