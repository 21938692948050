import React, {Component} from 'react';
import {Grid, Image, Message, Header} from 'semantic-ui-react';
import {Redirect, withRouter} from 'react-router-dom';
import queryString from 'query-string';

// i18n
import {withTranslation, Trans} from 'react-i18next';

import {Helmet} from 'react-helmet';
// GA tracking
import gaTracker from '../../utils/gaTracker';

class Registered extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    gaTracker.trackEvent({
      category: 'SignUp',
      action: 'CreatedAccount',
      label: 'Author'
    });

    const {location} = this.props;
    const parsedQueryString = queryString.parse(location.search);
    const {redirect} = parsedQueryString;

    const self = this;
    setTimeout(() => {
      self.setState({
        redirect: redirect || '/mymanuscripts'
      });
    }, 3000);
  }

  render() {
    const {user, verificationEmailMessage} = this.props;
    const {redirect} = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    if (user !== undefined && !user.isAnonymous && !user.emailVerified) {
      return (
        <div>
          <Helmet>
            <script>
              {`
                gtag('event', 'conversion', {'send_to': 'AW-779841759/8orJCM6--o0BEN_h7fMC'});
              `}
            </script>
            <script type='text/javascript'>
              {`
                twttr.conversion.trackPid('o0kcu', { tw_sale_amount: 0, tw_order_quantity: 0 });
              `}
            </script>
            <noscript>
              {`
                <img height="1" width="1" style="display:none;" alt="" src="https://analytics.twitter.com/i/adsct?txn_id=o0kcu&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0" />
                <img height="1" width="1" style="display:none;" alt="" src="//t.co/i/adsct?txn_id=o0kcu&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0" />
            `}
            </noscript>
            <script>
              {`
                fbq('track', 'CompleteRegistration');
              `}
            </script>
          </Helmet>
          <Grid
            textAlign='center'
            style={{height: '100%'}}
            verticalAlign='middle'>
            <Grid.Column style={{maxWidth: 450}} textAlign='left'>
              <Image
                src='/assets/images/BR-orange-horizontal.png'
                href='/mymanuscripts'
              />
              <Message size='large'>
                <Trans i18nKey='SignUpMsg' email={user.email}>
                  <Header>Thanks for signing up!</Header>
                  <p>
                    A verification email has been sent to{' '}
                    <strong>{{email: user.email}}</strong>
                  </p>
                </Trans>
                {verificationEmailMessage !== undefined && (
                  <p>{verificationEmailMessage}</p>
                )}
              </Message>
            </Grid.Column>
          </Grid>
        </div>
      );
    }
    return null;
  }
}

export default withTranslation()(withRouter(Registered));
