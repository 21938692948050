import React, { Component } from 'react';
import { Loader, Message, Header } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';

// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateUser } from '../../modules/user';

import firebase from '../../config/fire';

class VerifyEmail extends Component {
  state = {
    error: '',
    validCode: null,
    verifiedCode: false,
  };

  componentDidMount() {
    // Try to apply the email verification code.
    firebase
      .auth()
      .applyActionCode(this.props.actionCode)
      .then(
        () => {
          // Email address has been verified.
          firebase.auth().currentUser.reload();
          this.props.updateUser(firebase.auth().currentUser);
          this.setState({ validCode: true, verifiedCode: true });
        },
        error => {
          // Code is invalid or expired. Ask the user to verify their email address
          // again.
          this.setState({
            error: error.message,
            validCode: false,
            verifiedCode: true,
          });
        }
      );
  }

  render() {
    const { error, validCode, verifiedCode } = this.state;

    const { user } = this.props;

    const { userProfile } = this.props;

    let component;
    if (user && user.emailVerified) {
      component = (
        <Message color="teal">
          <Header>Your email has already been verified</Header>
          <span>
            You can now <Link to="/profile">sign in</Link> to your account.
          </span>
        </Message>
      );
    } else if (!verifiedCode) {
      component = <Loader />;
    } else if (verifiedCode && validCode) {
      component = (
        <Message color="teal">
          <Header>Your email has been verified</Header>
          {userProfile !== undefined && userProfile.id !== undefined && (
            <span>
              You can now <Link to="/profile">sign in</Link> with your new
              account
            </span>
          )}
          {(userProfile === undefined ||
            (userProfile !== undefined && userProfile.id === undefined)) && (
            <span>
              You can now <Link to="/profile">sign in</Link> with your new
              account
            </span>
          )}
        </Message>
      );
    } else if (verifiedCode && !validCode) {
      component = (
        <Message error>
          <Header>Verification failed</Header>
          <p>{error}</p>
          <p>
            Check your <Link to="/profile">account profile</Link> to see your
            current email settings.
          </p>
        </Message>
      );
    }

    return component;
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  userProfile: state.user.userProfile,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateUser,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VerifyEmail)
);
