import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom'

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// custom
import Overview from './overview';


class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Switch>
          <Route exact path={`${this.props.match.path}`} component={Overview} />
        </Switch>
      </div>
    );
  }
}


// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage)
}, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Report));
