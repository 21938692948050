import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Message, Container } from 'semantic-ui-react';
import VerifyEmail from './verifyEmail';

import {
  updateUserProfile,
  updateUser,
  getUserProfile
} from '../../modules/user';

class Profile extends Component {
  renderMessage = (mode, actionCode) => {
    switch (mode) {
      case 'recoverEmail':
        // Display reset password handler and UI.
        return <VerifyEmail actionCode={actionCode} />;
      /*case 'resetPassword':
        // Display email recovery handler and UI.
        return <ResetPassword actionCode={actionCode} />;*/
      case 'verifyEmail':
        // Display email verification handler and UI.
        return <VerifyEmail actionCode={actionCode} />;
      default:
        // Error: invalid mode.
        return (
          <Message error header='Something went wrong' content="You should only end up here you've tried to change your email or password. If that's exactly what you were trying to do, please contact us at info@betareader.io and let us know what went wrong."/>
        );
    }
  }


  render() {

    const parsedQueryString = queryString.parse(this.props.location.search);

    // Get the action to complete.
    const mode = parsedQueryString.mode;

    // Get the one-time code from the query parameter.
    const actionCode = parsedQueryString.oobCode;

    // (Optional) Get the API key from the query parameter.
    // const apiKey = props.location.query.apiKey;
    return (<Container text>{this.renderMessage(mode, actionCode)}</Container>);

  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile
})

const mapDispatchToProps = dispatch => bindActionCreators({
  updateUserProfile,
  updateUser,
  getUserProfile,
  changePage: (newPage) => push(newPage)
}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile))
