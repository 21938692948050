import { Component } from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

export class ContentRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this.changePage(this.props);
  }

  changePage = (props) => {
    const { currentBook } = props;
    let selectedVersion;
    if (currentBook) {
      const contentVersions = currentBook.content;
      if (contentVersions && contentVersions.length > 0) {
        // there is content versionNumber in props
        const { versionNumber } = props.match.params;
        if (versionNumber) {
          selectedVersion = contentVersions.find(version => parseInt(version.versionNumber) === parseInt(versionNumber));
        } else {
          selectedVersion = contentVersions[contentVersions.length - 1];
        }
      }
    }
    if (selectedVersion) {
      this.props.changePage(`/books/${currentBook._id}/content/v${selectedVersion.versionNumber}`);
    } else {
      this.props.changePage(`/books/${currentBook._id}/content/v1`);
    }
  }

  render() {
    return null;
  }
}

// redux stuff
const mapStateToProps = state => ({
  currentBook: state.book.currentBook
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage)
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentRouter);
