import { DeviceMinWidths } from "../constants/size";

const isSmallScreen = () => {
  const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  // consider viewports smaller than 768 pixels as small screens
  return viewportWidth < DeviceMinWidths.tablet;
};

export default {
  isSmallScreen
};
