import React, { Component } from 'react';

// UI components
import {
  Menu, Icon
} from 'semantic-ui-react';

// routing
import { withRouter } from 'react-router-dom';

// i18n
import { withTranslation } from 'react-i18next';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setCurrentConversation, clearCurrentConversation } from '../../modules/message';

class MessageListMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onStartNewMessage = () => {
    const { currentConversation } = this.props;
    if ((currentConversation && !currentConversation.isNew) || !currentConversation) {
      this.props.clearCurrentConversation();
      this.props.changePage(`/inbox/conversations/new`);
    }
  }

  onBackToInbox = () => {
    this.props.changePage(`/inbox/`);
    this.props.setCurrentConversation(undefined);
  }

  render() {
    const { backButton, newMessageButton, t } = this.props;
    return (
      <Menu icon borderless secondary>
        { backButton
          && (
            <Menu.Item
              attached='top'
              position='left'
              onClick={this.onBackToInbox}
            >
              <Icon size='large' name='chevron left' />
            </Menu.Item>
          )
        }
        { newMessageButton
          && (
            <Menu.Item
              attached='top'
              position='left'
              onClick={this.onStartNewMessage}
            >
              <Icon size='large' name='edit' />
              {' '}
              {t('New')}
            </Menu.Item>
          )
        }
      </Menu>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  currentConversation: state.message.currentConversation
});

const mapDispatchToProps = dispatch => bindActionCreators({
  clearCurrentConversation,
  setCurrentConversation,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageListMenu)));
