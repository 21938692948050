const white = '#ffffff';
const black = '#000000';
const gray = '#f1f1f1!';
const warningColor = 'red';

const bright = {
  background: {
    primary: white,
    contextMenu: gray
  },
  font: {
    primary: black,
    warning: warningColor
  }
};

const dark = {
  background: {
    primary: '#1B1C1D',
    contextMenu: black
  },
  font: {
    primary: '#rgba(240, 240, 240, 1)',
    warning: warningColor
  }
}

const themes = {
  bright,
  dark
}

export const getTheme = (theme) => themes[theme] || bright;