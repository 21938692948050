export const SET_PRODUCTS = 'subscription/SET_PRODUCTS';
export const UPDATE_LOCAL_PRICING = 'subscription/UPDATE_LOCAL_PRICING';
export const UPDATE_NEXT_PAYMENT = 'subscription/UPDATE_NEXT_PAYMENT';

export const initialState = {
  products: [],
  nextPayment: undefined
};

// reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.products
      };
    case UPDATE_NEXT_PAYMENT:
      return {
        ...state,
        nextPayment: action.nextPayment
      };
    default:
      return state;
  }
};

// actions
export const fetchProducts = (idToken) => {
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/products`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    })
      .then(res => res.json())
      .then((products) => {
        dispatch({
          type: SET_PRODUCTS,
          products
        });
      })
      .catch((error) => {
        console.error('Failed to fetch products', error);
      });
  };
};

export const getNextSubscriptionPayment = (idToken, subscriptionId) => {
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/billing/subscriptions/${subscriptionId}/next-payment`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    })
      .then(res => res.json())
      .then((nextPayment) => {
        if (nextPayment) {
          dispatch({
            type: UPDATE_NEXT_PAYMENT,
            nextPayment
          });
        } else {
          dispatch({
            type: UPDATE_NEXT_PAYMENT,
            nextPayment
          });
        }
      })
      .catch(() => {
        // do nothing
      });
  };
};

// actions
export const changeSubscription = (idToken, subscriptionId, newSubscriptionPlanId, callback) => {
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/billing/subscriptions/${subscriptionId}/actions/upgrade`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      },
      body: JSON.stringify({
        newSubscriptionPlanId
      })
    })
      .then((res) => {
        if (callback) {
          callback(res);
        }
      });
  };
};

export const cancelSubscription = ({
  idToken, subscriptionId, cancelReason, cancelFeedback
}, callback) => {
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/billing/subscriptions/${subscriptionId}/actions/cancel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      },
      body: JSON.stringify({
        cancelReason,
        cancelFeedback
      })
    })
      .then((res) => {
        if (callback) {
          callback(res);
        }
      });
  };
};
