import React, { useState, useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import queryString from 'query-string';

// i18n
import { withTranslation } from 'react-i18next';

// redux
import { useDispatch, useSelector } from 'react-redux';

import {
  Form, Header, Image,
  Button, Message, Tab
} from 'semantic-ui-react';

import {
  sendJoinBetaRequest,
  updateJoinBetaRequest,
  withdrawJoinBetaRequest
} from '../../modules/book';

import BasicSignIn from '../signin/basicSignIn';
import InviteToSwap from './inviteToSwap';

import { Question } from '../content/reader/survey/question';
import { getFreshIdToken } from '../../utils/authUtil';
import imageUtil from '../../utils/imageUtil';
import { DEFAULT_COVER_BACKGROUND } from '../../components/book/bookItem';

const JoinBetaForm = ({

  onJoin,
  onClose,
  onWithdraw,
  t,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const currentBook = useSelector(state => state.book.currentBook);
  const user = useSelector(state => state.user.user);

  const [email, setEmail] = useState('');
  const [answers, setAnswers] = useState([]);
  const [fetchedAnswers, setFetchedAnswers] = useState(false);
  const [showSwapOptions, setShowSwapOptions] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    if (currentBook?.readerSignupSurvey?.questions?.length > 0) {
      fetchReaderSignupSurveyAnswers(currentBook);
    } else {
      setFetchedAnswers(true);
    }
  }, [currentBook]);

  const fetchReaderSignupSurveyAnswers = async (currentBook) => {
    const idToken = await getFreshIdToken();
    if (!idToken) return;
    const url = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${currentBook._id}/surveys/${currentBook.readerSignupSurvey._id}/answers`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        } else {
          return res.json();
        }
      })
      .then((surveyAnswers) => {
        setAnswers(surveyAnswers);
        setFetchedAnswers(true);
      })
      .catch((err) => {
        setFetchedAnswers(true);
      });
  }

  /*
  Sends request to join this book beta to the book redux action
  Then closes the form.
  */
  const sendOrUpdateJoinBetaRequest = async (event, data) => {
    const idToken = await getFreshIdToken();
    const parsedQueryString = queryString.parse(location.search);
    const { token } = parsedQueryString;

    const options = {
      email: user && email,
      userId: user && user.uid,
      token: token && token
    };

    if (data.swap) {
      // this join request comes with a swap suggestion
      options.swap = data.swap;
    }
    if (data.swapContentVersion) {
      options.swapContentVersion = data.swapContentVersion;
    }

    if (answers && answers.length > 0) {
      options.readerSignupSurveyAnswers = answers;
    }
    if (currentBook.reader === undefined) {
      // send a new join request if no reader entity exists
      dispatch(sendJoinBetaRequest(idToken, currentBook._id, options, () => {
        onJoin();
        onClose();
      }));
    } else {
      // otherwise update the existing one
      if (currentBook.reader.invitation.status === 'DECLINED') {
        // assume that the user wants to request to join again
        options.status = 'REQUESTED';
      }
      if (currentBook.reader.invitation.status === 'SENT') {
        // set the invitation to ACCEPTED if
        options.status = 'ACCEPTED';
      }
      dispatch(updateJoinBetaRequest(idToken, currentBook._id, options, () => {
        onJoin();
        onClose();
      }));
    }
  }

  const withdrawJoinRequest = async () => {
    const idToken = await getFreshIdToken();
    dispatch(withdrawJoinBetaRequest(idToken, currentBook._id, currentBook.reader._id, () => {
      onWithdraw();
      onClose();
    }));
  }

  const emailChanged = (event, data) => {
    setEmail(data.value);
  }

  const answerChanged = (questionIndex, questionId, newAnswer) => {
    const updatedAnswers = [...answers];
    const answerIndex = updatedAnswers.findIndex(answer => answer.surveyQuestion === questionId);
    if (answerIndex < 0) {
      updatedAnswers.push({ surveyQuestion: questionId, answer: newAnswer });
    } else {
      updatedAnswers.splice(answerIndex, 1, { surveyQuestion: questionId, answer: newAnswer });
    }
    setAnswers(updatedAnswers);
  }

  const goToAuthorDashboard = (event) => {
    preventDefaults(event);
    window.open(`${process.env.REACT_APP_WEB_HOST}/mymanuscripts`, '_blank');
  }

  const preventDefaults = (event) => {
    event.preventDefault();
    event.stopPropagation();
  }

  const getSendRequestElement = (readerEntry) => {
    let readerStatus = 'none';
    if (readerEntry) {
      readerStatus = readerEntry.invitation.status;
    }

    // disable sending join request if one is already sent
    const disableSendingRequest = currentBook.state !== undefined;
    let cover = currentBook?.cover?.highRes?.url ?? DEFAULT_COVER_BACKGROUND;

    const render = [
      <Header
        key='header'
        image={
          (
            <Image
              size='small'
              src={imageUtil.getImageUrl({
                operation: 'width',
                size: 80,
                originalImageUrl: cover
              })}
            />
          )
        }
        content={currentBook.title}
        subheader={currentBook.reader && currentBook.reader.swap ? t('RequestedToSwapForX', { x: currentBook.reader.swap.title }) : null}
      />
    ];

    if (currentBook.readerSignupSurvey) {
      currentBook.readerSignupSurvey.questions.forEach((question, index) => {
        const answer = answers.find(entry => entry.surveyQuestion === question._id) || {};
        if (fetchedAnswers) {
          render.push(
            <Question
              style={{ marginTop: index === 0 ? '2em' : '0em', marginBottom: '2em' }}
              key={question._id}
              question={question}
              answer={answer}
              fetchedAnswers={true /*fake fetched answers as we don't need to do that here*/}
              questionIndex={index}
              handleReference={() => { }}
              onAnswerChanged={(newAnswer, isDone) => {
                answerChanged(index, question._id, newAnswer, isDone)
              }}
            />
          );
        }
      });
    }

    render.push(
      <Form.Group key='join-request' inline widths='equal'>
        <Button.Group floated='right'>
          {
            ['none', 'DECLINED'].includes(readerStatus) && [
              <Button
                key='swap-button'
                loading={currentBook.status === 'sending-swap-request'}
                disabled={disableSendingRequest}
                onClick={() => setShowSwapOptions(true)}
                content={t('SuggestASwap')}
              />,
              <Button.Or key='or-button' text={t('Or').substr(0, 2).toLowerCase()} />,
              <Button
                key='read-button'
                loading={currentBook.state === 'sending-read-request'}
                disabled={disableSendingRequest}
                onClick={sendOrUpdateJoinBetaRequest}
                content={t('IJustWantToRead')}
              />
            ]
          }
          {
            readerStatus === 'REQUESTED' && [
              <Button
                key='withdraw-button'
                loading={currentBook.state === 'withdrawing'}
                onClick={withdrawJoinRequest}
                content={t('WithdrawRequest')}
              />
            ]
          }
          {
            ['SENT', 'CREATED'].includes(readerStatus) && [
              <Button
                key='cancel-button'
                loading={currentBook.state === 'withdrawing'}
                onClick={withdrawJoinRequest}
                content={t('Decline')}
              />,
              <Button
                key='accept-button'
                loading={currentBook.state === 'sending-read-request'}
                onClick={sendOrUpdateJoinBetaRequest}
                content={t('Send')}
              />
            ]
          }
        </Button.Group>
      </Form.Group>
    );

    return render;
  }

  if (!currentBook) {
    return null;
  }

  const haveAccountPane = (
    <Tab.Pane>
      <Form error={errorMessage !== undefined}>
        <Form.Field>
          <Message error>
            {errorMessage}
          </Message>
        </Form.Field>
      </Form>
      {
        !user
        && <BasicSignIn />
      }
    </Tab.Pane>
  );

  if (!user) {
    return haveAccountPane;
  }
  if (showSwapOptions) {
    return <InviteToSwap onSendSwap={sendOrUpdateJoinBetaRequest} />;
  }

  // no request exists and this is a new reader
  return getSendRequestElement(currentBook.reader);
}

export default withTranslation()(withRouter(JoinBetaForm));
