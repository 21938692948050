import React, { Component } from 'react';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Container,
  Header,
  Icon,
  Segment
} from 'semantic-ui-react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { authUtil } from '../../utils';

export class Actions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const parsedQueryString = queryString.parse(location.search);
    const action = parsedQueryString.a;
    const userId = parsedQueryString.u;
    const list = parsedQueryString.l;
    if (action === 'unsubscribe') {
      this.unsubscribeFromList({ userId, list });
    }
  }

  unsubscribeFromList = async ({ userId, list }) => {
    const { t } = this.props;
    const idToken = await authUtil.getFreshIdToken();
    this.setState({
      loading: true
    });
    await fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/link-actions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      },
      body: JSON.stringify({
        action: 'unsubscribe',
        userId,
        list
      })
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        throw new Error('Failed to unsubscribe. Email support@betareader.io if this continues.');
      })
      .then(() => {
        this.setState({
          confirmationStatus: true,
          confirmationMessage: t('UnsubscribeSuccessful')
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          confirmationStatus: false,
          confirmationMessage: t('UnsubscribeUnsuccessful')
        });
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

  render() {
    const { loading, confirmationStatus, confirmationMessage } = this.state;
    return (
      <Container>
        <Segment placeholder>
          <Header icon>
            {
              loading && (
                <Icon name='spinner' loading />
              )
            }
            {
              confirmationStatus === true && (
                <Icon color='green' name='checkmark' />
              )
            }
            {
              confirmationStatus === false && (
                <Icon name='broken chain' />
              )
            }
            {
              confirmationMessage && (
                <span>
                  {confirmationMessage}
                </span>
              )
            }
          </Header>
        </Segment>
      </Container>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(Actions));
