import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Image, Container, Header, Button
} from 'semantic-ui-react';

class NotFound extends Component {
  render() {
    return (
      <Container text textAlign='center'>
        <Header size='huge'>
          Nothing to see here.
        </Header>
        <Image
          src='/assets/images/empty-typewriter.jpeg'
          centered
          size='large'
        />
        <Header size='medium' color='grey'>
          Are you sure you entered the right URL?
        </Header>
        <Button size='large' href='/'>
          Take me home
        </Button>
      </Container>
    );
  }
}

export default withRouter(NotFound);
