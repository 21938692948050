import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Button, Form, Input, Label} from 'semantic-ui-react';

import {convertToRaw, EditorState} from 'draft-js';

// i18n
import {withTranslation} from 'react-i18next';

// redux
import {push} from 'connected-react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Editor
import TextEditor from '../../../components/textEditor/textEditor';
import {addChapters} from '../../../modules/book';

import {accessUtil} from '../../../utils';

class NewChapter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      content: EditorState.createEmpty().getCurrentContent(),
      wordCount: 0,
      paragraphCount: 0,
      charCount: 0
    };
  }

  componentDidMount = () => {
    this.createChapter();
  };

  createChapter = () => {
    const {content, title, wordCount, paragraphCount, charCount, paragraphs} =
      this.state;
    const {idToken, match, currentContentVersion, currentBook} = this.props;
    const {bookId} = match.params;
    const rawContent = convertToRaw(content);

    this.props.addChapters(
      idToken,
      bookId,
      currentContentVersion._id,
      [
        {
          title: title || this.getDefaultChapterTitle(),
          content: JSON.stringify(rawContent),
          wordCount,
          paragraphCount,
          charCount,
          beginning: content.getPlainText().substring(0, 50),
          paragraphs
        }
      ],
      savedChapters => {
        const firstSavedChapter = savedChapters[0];
        if (firstSavedChapter && firstSavedChapter._id) {
          const versionNumber =
            currentBook.content.findIndex(
              v => v._id === currentContentVersion._id
            ) + 1;
          this.props.changePage(
            `/books/${bookId}/content/v${versionNumber}/${firstSavedChapter._id}`
          );
        }
      }
    );
  };

  getWordCount = text => {
    return text.split(/\b\S+\b/g).length - 1;
  };

  handleTitleChange = (event, data) => {
    this.setState({
      title: data.value,
      titleHasChanged: data.value !== this.state.originalTitle
    });
  };

  onDocumentDataUpdated = newDocumentData => {
    const {
      content,
      rawContentWithoutComments,
      wordCount,
      charCount,
      paragraphCount,
      paragraphs,
      differentFromOriginal
    } = newDocumentData;
    this.setState({
      ...this.state,
      content: content,
      rawContentWithoutComments: rawContentWithoutComments,
      charCount: charCount,
      wordCount: wordCount,
      paragraphCount: paragraphCount,
      paragraphs: paragraphs,
      textHasChanged: differentFromOriginal
    });
  };

  getDefaultChapterTitle = () => {
    const {currentBook, t} = this.props;
    let chapterTitle = `${t('Chapter')} ${
      currentBook.parts.filter(part => part.kind === 'Chapter').length + 1
    }`;
    return chapterTitle;
  };

  render() {
    const {wordCount, charCount} = this.state;
    const {currentBook, t} = this.props;
    const enabledButtons = {
      bold: true,
      italic: true,
      underline: true,
      H2: true,
      H3: true,
      comment: false
    };
    if (!currentBook) {
      return null;
    }
    return (
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 768,
          padding: '0 1em 0 1em'
        }}>
        <Form style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
          <Form.Group
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}>
            <Input
              style={{flex: 1}}
              className={`text-line`}
              transparent
              size='massive'
              placeholder={this.getDefaultChapterTitle()}
              value={this.state.title}
              onChange={this.handleTitleChange}
            />
            <Form.Field
              style={{marginLeft: 'auto'}}
              control={Button}
              disabled={
                !accessUtil.isAllowed(currentBook.access, ['edit']) ||
                charCount === 0
              }
              onClick={this.createChapter}>
              {t('Save')}
            </Form.Field>
          </Form.Group>
          <Label.Group circular>
            <Label>{t('WordCountString', {count: wordCount})}</Label>
            <Label>{t('CharCountString', {count: charCount})}</Label>
          </Label.Group>
          <div style={{flex: 1}}>
            <TextEditor
              manuscriptStyle={currentBook.manuscriptStyle}
              enableButtons={enabledButtons}
              differentFromOriginal={this.differentFromOriginal}
              onDocumentDataUpdated={this.onDocumentDataUpdated}
            />
          </div>
        </Form>
      </div>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  currentBook: state.book.currentBook,
  currentLanguage: state.user.currentLanguage,
  currentContentVersion: state.book.currentContentVersion
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addChapters,
      changePage: newPage => push(newPage)
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(NewChapter))
);
