import firebase from '../config/fire';
export const getFreshIdToken = async () => {
  if (firebase.auth().currentUser) {
    const idToken = await firebase.auth().currentUser.getIdToken();
    return idToken;
  } else {
    return new Promise((resolve, reject) => {
      try {
        const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
          if (user) {
            const idToken = await user.getIdToken();
            resolve(idToken);
          } else {
            resolve(undefined);
          }
          unsubscribe();
        });
      } catch (e) {
        reject(e);
      }
    });
  }
};

export default {
  getFreshIdToken,
};
