import React, {useEffect, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Header, Segment} from 'semantic-ui-react';

import TextSurveyAnswersList from './answer/textSurveyAnswersList';
import MultipleChoiceSurveyAnswersChart from './answer/multipleChoiceSurveyAnswersChart';
import LinearScaleSurveyAnswersChart from './answer/linearScaleSurveyAnswersChart';
import CheckboxSurveyAnswersChart from './answer/checkboxSurveyAnswersChart';

import {
  updateReaderFilters,
  clearReadingData,
  clearSurveyData,
  clearReviewData
} from '../../../modules/report';
import {authUtil} from '../../../utils';

const SurveyResults = ({
  answers = [],
  bookId,
  surveyId,
  questionTitleFilter,
  versionNumber
}) => {
  const dispatch = useDispatch();

  const currentBook = useSelector(state => state.book.currentBook);

  const [survey, setSurvey] = useState();

  useEffect(() => {
    if (bookId && surveyId) {
      fetchSurvey(bookId, surveyId);
    }
  }, [bookId, surveyId]);

  const fetchSurvey = async (bookId, surveyId) => {
    const idToken = await authUtil.getFreshIdToken();
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/surveys/${surveyId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => {
        if (!res.ok) {
          throw new Error(res.error);
        }
        return res.json();
      })
      .then(survey => {
        setSurvey(survey);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const filterQuestions = readerIds => {
    const filter = [
      {
        operator: '$in',
        value: readerIds
      }
    ];
    dispatch(clearReadingData());
    dispatch(clearSurveyData());
    dispatch(clearReviewData());
    dispatch(updateReaderFilters(filter, currentBook._id));
  };

  const getAnswers = useCallback(() => {
    return survey?.questions
      ?.filter(question => {
        // if we have a valid question title filter, remove any non-matching questions
        if (questionTitleFilter?.length > 0) {
          return question?.question
            ?.toLowerCase()
            ?.includes(questionTitleFilter.toLowerCase());
        }
        // otherwise, return all questions
        return true;
      })
      .map(question => {
        // filter out the answers for this question
        const answersToThisQuestion = answers.filter(
          answer => answer.surveyQuestion?._id === question._id
        );
        return (
          <Segment key={question._id}>
            <Header content={question.question} size='small' />
            {question.kind === 'LONG_TEXT' && (
              <TextSurveyAnswersList
                answers={answersToThisQuestion}
                onFilter={filterQuestions}
              />
            )}
            {question.kind === 'MULTIPLE_CHOICE' && (
              <MultipleChoiceSurveyAnswersChart
                answers={answersToThisQuestion}
                onFilter={filterQuestions}
              />
            )}
            {question.kind === 'LINEAR_SCALE' && (
              <LinearScaleSurveyAnswersChart
                options={question.options}
                answers={answersToThisQuestion}
                onFilter={filterQuestions}
              />
            )}
            {question.kind === 'CHECKBOX' && (
              <CheckboxSurveyAnswersChart
                options={question.options}
                answers={answersToThisQuestion}
                onFilter={filterQuestions}
              />
            )}
          </Segment>
        );
      });
  }, [survey, questionTitleFilter, answers]);

  console.log('survey', survey);

  return (
    <div style={{marginTop: 10}}>
      <Header
        content={`${survey?.surveyName} (v${versionNumber})`}
        subheader={survey?.description}
      />
      {getAnswers()}
    </div>
  );
};

export default SurveyResults;
