import React, {Component} from 'react';
import queryString from 'query-string';
import {Media} from 'src/utils/Media';

import '../../index.css';

// i18n
import {withTranslation} from 'react-i18next';

// Routes
import {withRouter} from 'react-router-dom';

// redux
import {push} from 'connected-react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Components
import {
  Container,
  Header,
  Grid,
  Label,
  Image,
  Ref,
  TextArea,
  Button,
  Segment
} from 'semantic-ui-react';

import {fetchNotifications} from '../../modules/notification';

// Analytics
import ux from '../../utils/uxAnalyticsUtil';

const scores = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export class NetPromoterScoreContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.resetScoreTimeout = null;
    this.feedbackTextarea = null;
  }

  componentDidMount() {
    const {match, location} = this.props;
    ux.trackPageView(match.path);
    const query = queryString.parse(location.search);
    const {uid, score} = query;
    if (!uid) {
      window.location = 'https://betareader.io';
    }
    this.setState(
      {
        user: uid,
        score: parseInt(score, 10) || undefined
      },
      () => {
        // auto post the score if it's included in the url
        if (this.state.score) {
          this.postNPS();
        }
      }
    );
  }

  hoverOn = event => {
    if (this.resetScoreTimeout) {
      clearTimeout(this.resetScoreTimeout);
      this.resetScoreTimeout = null;
    }
    this.setState({
      hoverScore: parseInt(event.currentTarget.dataset.score, 10)
    });
  };

  hoverOff = () => {
    const self = this;
    this.resetScoreTimeout = setTimeout(() => {
      self.setState(prevState => ({
        hoverScore: prevState.score
      }));
    }, 250);
  };

  setScore = (event, data) => {
    this.setState(
      {
        score: parseInt(data['data-score'], 10)
      },
      () => {
        this.postNPS();
      }
    );
  };

  feedbackChanged = (event, data) => {
    this.setState({
      feedback: data.value
    });
  };

  submitFeedback = () => {
    this.setState(
      {
        done: true
      },
      () => {
        this.postNPS();
        setTimeout(() => {
          window.location = 'https://read.betareader.io/discover';
        }, 2000);
      }
    );
  };

  postNPS = callback => {
    const {user, score, feedback} = this.state;
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/nps`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user,
          score,
          feedback
        })
      }
    )
      .then(res => res.json())
      .then(nps => {
        this.setState({
          ...nps
        });
      });
  };

  handleTextAreaRef = ref => {
    if (!this.feedbackTextarea) {
      this.feedbackTextarea = ref;
      this.feedbackTextarea.focus();
    }
  };

  render() {
    const {t} = this.props;
    const {score, hoverScore, feedback, enterFeedback, done} = this.state;

    if (done) {
      return (
        <Container textAlign='center' style={{width: 550}}>
          <Image src='/assets/images/BR-orange-horizontal.png' />
          <Segment basic>
            <Segment.Inline>
              <Header content={t('Thanks!')} />
            </Segment.Inline>
          </Segment>
        </Container>
      );
    }

    return (
      <Container textAlign='center' style={{width: 550}}>
        <Image src='/assets/images/BR-orange-horizontal.png' />
        <Header
          textAlign='left'
          content={t('HowLikelyAreYouToRecommendBetaReaderToAFriend')}
        />
        <Grid verticalAlign='middle' centered columns='equal' stackable={false}>
          <Grid.Row onMouseLeave={this.hoverOff}>
            {scores.map(value => {
              let color;
              if (score >= value || hoverScore >= value) {
                color = 'black';
              }
              return (
                <Grid.Column key={value} style={{padding: '0.5em'}}>
                  <Media
                    greaterThan='mobile'
                    as={Label}
                    circular
                    data-score={value}
                    size='huge'
                    onMouseEnter={this.hoverOn}
                    style={{cursor: 'pointer'}}
                    color={color}
                    onClick={this.setScore}>
                    {value}
                  </Media>
                  <Media
                    at='mobile'
                    as={Label}
                    circular
                    data-score={value}
                    size='small'
                    onMouseEnter={this.hoverOn}
                    style={{cursor: 'pointer'}}
                    color={color}
                    onClick={this.setScore}>
                    {value}
                  </Media>
                </Grid.Column>
              );
            })}
          </Grid.Row>
          <Grid.Row style={{paddingTop: 0}}>
            <Grid.Column textAlign='left'>{t('NotAtAllLikely')}</Grid.Column>
            <Grid.Column textAlign='right'>{t('ExtremelyLikely')}</Grid.Column>
          </Grid.Row>
        </Grid>
        {score && !enterFeedback && (
          <Grid style={{width: '100%'}} columns='equal'>
            <Grid.Row>
              <Grid.Column>
                <Header
                  textAlign='left'
                  content={t('ThanksDoYouWantToTellUsWhy')}
                />
                <Ref innerRef={this.handleTextAreaRef}>
                  <TextArea
                    style={{width: '100%'}}
                    rows={3}
                    value={feedback || ''}
                    onChange={this.feedbackChanged}
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign='right'>
              <Grid.Column textAlign='right'>
                <Button positive floated='right' onClick={this.submitFeedback}>
                  {t('Submit')}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Container>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchNotifications,
      changePage: newPage => push(newPage)
    },
    dispatch
  );

export default withTranslation()(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(NetPromoterScoreContainer)
  )
);
