import React, { useState, useEffect } from 'react';

import {
  Header, List, Image, Form, Icon, Dropdown
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  fetchSwappableBook,
  resetSwappableBook,
  fetchSwappables
} from '../../modules/book';

import { authUtil, imageUtil } from '../../utils';

const DEFAULT_COVER_BACKGROUND = 'https://storage.googleapis.com/beta-reader-prod.appspot.com/defaults/DEFAULT_COVER_BACKGROUND.png';

const InviteToSwap = ({
  reader,
  onSendSwap,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const currentBook = useSelector(state => state.book.currentBook);
  const selectedBook = useSelector(state => state.book.swappableBook);
  const swappables = useSelector(state => state.book.swappables);

  const [message, setMessage] = useState('');
  const [existingEntries, setExistingEntries] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState();
  const [sendingInvitation, setSendingInvitation] = useState();

  useEffect(() => {
    getBooks();
    getExistingReaderEntries();
    return () => {
      dispatch(resetSwappableBook());
    }
  }, []);

  const getBooks = async () => {
    const idToken = await authUtil.getFreshIdToken();
    dispatch(fetchSwappables(idToken));
  }

  const getExistingReaderEntries = async () => {
    const idToken = await authUtil.getFreshIdToken();
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/reader-index/user/${currentBook.author._id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        throw new Error('Failed to load reader index');
      })
      .then(user => setExistingEntries(user.existingEntries))
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
      });
  }

  const selectBook = async (bookId) => {
    const idToken = await authUtil.getFreshIdToken();
    dispatch(fetchSwappableBook(idToken, bookId));
  }

  const unselectBook = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(resetSwappableBook());
  }

  const goToBook = book => history.push(`/books/${book._id}`);

  const inviteUserToBook = () => {
    onSendSwap(
      {},
      {
        swap: selectedBook._id,
        swapContentVersion: selectedVersion || selectedBook
          .content[selectedBook.content.length - 1]._id
      }
    );
  }

  const changeSelectedVersion = (event, data) => {
    setSelectedVersion(data.value);
  }

  const changeMessage = (event, data) => {
    setMessage(data.value);
  }

  const getCoverUrl = (book) => {
    return book?.cover?.highRes?.url ?? DEFAULT_COVER_BACKGROUND;
  }

  return (
    <div style={{ padding: 10 }}>
      <Header
        icon={(
          <Icon
            size='small'
            color='grey'
            name='exchange'
            floated='right'
          />
        )}
        content={t('SuggestASwap')}
        subheader={t('SwapsDontAffectReaderLimit')}
      />
      <List selection divided>
        {
          selectedBook && (
            <List.Item
              key={selectedBook._id}
              onClick={() => goToBook(selectedBook)}
            >
              <List.Content>
                <Right>
                  <Dropdown
                    inline
                    direction='left'
                    options={
                      selectedBook.content.map(version => ({
                        key: version._id,
                        value: version._id,
                        text: `${t('Version')} ${version.versionNumber}`,
                        description: t('WordCountString', {
                          count: version.parts
                            .reduce((total, part) => total + (part.wordCount || 0), 0)
                        })
                      }))
                    }
                    defaultValue={selectedBook.content[selectedBook.content.length - 1]._id}
                    value={selectedVersion}
                    onChange={changeSelectedVersion}
                  />
                  <Icon
                    name='close'
                    link
                    onClick={unselectBook}
                  />
                </Right>
                {selectedBook.description && (
                  <List.Description>
                    <Header sub size='small'>{t('Description')}</Header>
                    {selectedBook.description}
                  </List.Description>
                )}
                {selectedBook.feedbackGuidelines && (
                  <List.Description>
                    <Header sub size='small'>{t('FeedbackGuidelines')}</Header>
                    {selectedBook.feedbackGuidelines}
                  </List.Description>
                )}

              </List.Content>
            </List.Item>
          )
        }
        {
          !selectedBook && swappables && Array.isArray(swappables) && swappables.map((book) => {
            if (book.privacy === 'unpublished') {
              return null; // don't show unpublished books
            }
            const alreadyInvited = existingEntries
              .map(entry => entry.book).includes(book._id);
            const canInvite = !alreadyInvited;
            return (
              <List.Item
                key={book._id}
                onClick={canInvite ? () => selectBook(book._id) : null}
                style={{ opacity: canInvite ? 1 : 0.8 }}
              >
                <List.Content>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Image
                      src={imageUtil.getImageUrl({
                        originalImageUrl: getCoverUrl(book),
                        operation: 'fit',
                        size: '50x50'
                      })}
                      style={{ width: 50, height: 50 }}
                    />
                    <div style={{ marginLeft: 5 }}>
                      <List.Header>{book.title}</List.Header>
                      <List.Description>
                        {
                          alreadyInvited && (
                            <div>
                              {t('UserAlreadyInvited', { user: currentBook.authorName })}
                            </div>
                          )
                        }
                      </List.Description>
                    </div>
                  </div>
                </List.Content>
              </List.Item>
            );
          })
        }
      </List>
      {
        selectedBook && (
          <Form>
            <Form.Button
              primary
              floated='right'
              content={t('Send')}
              onClick={inviteUserToBook}
              loading={sendingInvitation}
            />
          </Form>
        )
      }
    </div>
  );
}

const Right = styled.div`
  float: right;
`;

export default InviteToSwap;