import React from 'react';
import { Input, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import DiscoverFilter from './discoverFilter';

const SearchInput = ({
  loading,
  value,
  placeholder,

  onChange,
  // onFocus,
  onKeyDown,
  open,
  onClose
}) => {
  return (
    <Wrapper>
      <Icon
        name={loading ? 'spinner' : 'search'}
        loading={loading}
        style={{ opacity: 0.4 }}
      />
      <StyledInput
        fluid
        transparent
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        open={open}
      />
      {value?.length > 0 && (
        <Icon
          link
          name='close'
          onClick={onClose}
          style={{ opacity: 0.4 }}
        />
      )}
      <DiscoverFilter />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 0 1rem 0 1rem;
  border: solid 1px rgba(34,36,38,.15);
  border-radius: 20px;
  margin: 2px;
`;

const StyledInput = styled(Input)`
  background-color: transparent;
  border: none;
  flex: 1;
  display: flex;
`;

export default SearchInput;