import { useRef, useEffect } from 'react';

// used to identify clicks outside a specific component, e.g. to trigger the closing of a modal
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePrevious;