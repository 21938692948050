import React from 'react';
import { TextArea } from 'semantic-ui-react';

const TextAreaWithSave = ({
  onSave,
  onKeyDown,
  ...props
}) => {
  /**
   * Triggers onSave for CMD + S (Mac) or Ctrl + S (Win)
   * or CMD + Enter (Mac) or Ctrl + Enter (Win) 
   * @param {event} event 
   */
  const handleKeyDown = (event) => {
    if (event.keyCode === 83 && (event.metaKey || event.ctrlKey)) {
      onSave && onSave();
    } else if (event.keyCode === 13 && (event.metaKey || event.ctrlKey)) {
      onSave && onSave();
    }
    onKeyDown && onKeyDown(event);
  }
  return (
    <TextArea
      {...props}
      onKeyDown={handleKeyDown}
    />
  )
}

export default TextAreaWithSave;