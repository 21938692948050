import React, {Component} from 'react';

import '../../App.css';

// i18n
import {withTranslation} from 'react-i18next';
import moment from 'moment';

// Routes
import {withRouter, Link, useHistory} from 'react-router-dom';

// redux
import {push} from 'connected-react-router';
import {bindActionCreators} from 'redux';
import {connect, useDispatch, useSelector} from 'react-redux';

// Components
import {List, Header} from 'semantic-ui-react';

import {
  markNotificationsAsRead,
  fetchNotifications,
  markAllNotificationsAsRead
} from '../../modules/notification';

import {authUtil, uxAnalyticsUtil} from '../../utils';

import Avatar from '../../components/avatar/avatar';

const NotificationsList = ({onClose, t}) => {
  const notifications = useSelector(state => state.notification.notifications);
  const userProfile = useSelector(state => state.user.userProfile);
  const unreadCount = useSelector(state => state.notification.unreadCount);

  const dispatch = useDispatch();
  const history = useHistory();

  const loadMoreNotifications = async event => {
    event.preventDefault();
    event.stopPropagation();
    uxAnalyticsUtil.trackEvent({
      category: 'Notifications',
      action: 'load-more'
    });
    let before;
    if (notifications && notifications.length > 0) {
      // get the timestamp of the freshest notification
      before = new Date(
        notifications[notifications.length - 1].createdAt
      ).getTime();
    }
    const idToken = await authUtil.getFreshIdToken();
    dispatch(fetchNotifications(idToken, {before}));
  };

  const markAllAsRead = async event => {
    event.preventDefault();
    event.stopPropagation();
    uxAnalyticsUtil.trackEvent({
      category: 'Notifications',
      action: 'mark-all-notifications-read'
    });
    const idToken = await authUtil.getFreshIdToken();
    dispatch(markAllNotificationsAsRead(idToken));
  };

  const goToObject = async notification => {
    uxAnalyticsUtil.trackEvent({
      category: 'Notifications',
      action: 'clicked-notification'
    });
    const url = await getNotificationUrl(notification);
    if (notification && notification.url) {
      const currentHost = `${window.location.protocol}//${window.location.host}`;
      const idToken = await authUtil.getFreshIdToken();
      if (!notification.read) {
        // mark as read only if it isn't already in order to not produce unnecessary load
        dispatch(markNotificationsAsRead(idToken, [notification._id]));
      }
      if (url.includes(currentHost)) {
        // this is a url within our current app. use router.
        history.push(url.replace(currentHost, ''));
      } else {
        // this is to another app. use browser navigation.
        window.location = url;
      }
      onClose && onClose();
    }
  };

  const getNotificationUrl = async notification => {
    let {url, urlMetadata} = notification;
    try {
      if (urlMetadata.role === 'READER') {
        url = notification.readerUrl;
      }
    } catch (e) {
      // do nothing
    }
    return url;
  };

  return (
    <React.Fragment>
      <Header content={t('Notifications')} />
      {unreadCount > 0 && (
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <Link
            key='mark-all-as-read'
            to='/notifications'
            onClick={markAllAsRead}
            style={{marginLeft: 'auto', marginRight: '5px', fontSize: '0.8em'}}>
            {t('MarkAllAsRead')}
          </Link>
        </div>
      )}
      <List key='list' selection relaxed>
        {(!notifications || notifications.length === 0) && (
          <List.Item key='no-notifications'>
            <List.Content style={{color: 'black'}}>
              {t('NothingHere')}
            </List.Content>
          </List.Item>
        )}
        {notifications &&
          notifications.map(notification => (
            <List.Item
              key={notification._id}
              onClick={() => {
                goToObject(notification);
              }}
              className={
                notification.read
                  ? 'notification-dropdown-item-read'
                  : 'notification-dropdown-item'
              }>
              <List.Icon style={{paddingLeft: 5}}>
                <Avatar
                  diameter={25}
                  name={
                    notification.subject && notification.subject.displayName
                  }
                />
              </List.Icon>
              <List.Content
                className={
                  notification.read ? 'notification-dropdown-item-read' : ''
                }>
                <List.Header
                  as='a'
                  className={
                    notification.read ? 'notification-dropdown-item-read' : ''
                  }>
                  {notification.subject && notification.subject.displayName}
                </List.Header>
                <List.Description
                  className={
                    notification.read ? 'notification-dropdown-item-read' : ''
                  }>
                  {` ${t(`notification_verb_${notification.verb}`)} `}
                  <a
                    className={
                      notification.read ? 'notification-dropdown-item-read' : ''
                    }>
                    {notification.object?.title ?? ''}
                  </a>
                </List.Description>
                {notification.message && (
                  <span
                    style={{
                      textOverflow: 'ellipsis'
                    }}>{`: ${notification.message}`}</span>
                )}
                <List.Description
                  as='a'
                  className={
                    notification.read ? 'notification-dropdown-item-read' : ''
                  }>
                  {moment(notification.createdAt).fromNow()}
                </List.Description>
              </List.Content>
            </List.Item>
          ))}
      </List>
      {notifications &&
        notifications.length > 0 &&
        !notifications[notifications.length - 1].isLast && (
          <Link
            key='load-more'
            to='/notifications'
            onClick={loadMoreNotifications}
            style={{float: 'right', fontSize: '0.8em'}}>
            {t('LoadMore')}
          </Link>
        )}
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(NotificationsList));
