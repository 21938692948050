import React, {useState} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import {
  Checkbox,
  Button,
  List,
  Icon,
  Dropdown,
  Table,
  Popup
} from 'semantic-ui-react';
import {useSelector} from 'react-redux';
import moment from 'moment';
import {withTranslation, Trans, useTranslation} from 'react-i18next';
import ReadingGraph from './readingGraph';
import JoinRequestItem from './joinRequest/joinRequestsItem';
import Avatar from '../../components/avatar/avatar';
import InvitationForm from './invitationForm';
import ReaderSignupQuestionsItem from './readerSignupQuestionsItem';

type Props = {
  reader: any; // TODO use IReader
  isEditable: boolean;
  contentVersionOptions: {key: string; value: string; text: string}[];
  canAddMoreReaders: boolean;
  isSelectable: boolean;
  onDelete: (readerId: string) => void;
  onMessage: (readerId: string) => void;
  onGoToProfile: (readerId: string) => void;
  onToggleEnabled: (readerId: string) => void;
  onShowReaderProfile: (userId: string) => void;
  onSelectedChange: (readerId: string) => void;
  onCopyURLToClipBoard: (token: string) => void;
  onApproveJoinRequest: (reader: any, content?: string) => void;
  onDeclineJoinRequest: (readerId: string) => void;
  onSendInvitation: (readerId: string) => void;
  onUpdateReader: (book: any, readerId: string, data: any) => Promise<void>;
};

const ReaderEntryTableRow: React.FC<Props> = ({
  reader,
  isEditable,
  contentVersionOptions,
  canAddMoreReaders,
  isSelectable,
  onDelete,
  onMessage,
  onGoToProfile,
  onToggleEnabled,
  onShowReaderProfile,
  onSelectedChange,
  onCopyURLToClipBoard,
  onApproveJoinRequest,
  onDeclineJoinRequest,
  onSendInvitation,
  onUpdateReader
}) => {
  const {t} = useTranslation();
  const route = useRouteMatch();

  const currentBook = useSelector(state => state.book.currentBook);
  const updatingReader = useSelector(state => state.reader.updatingReader);

  const [showInvitationForm, setShowInvitationForm] = useState<boolean>(false);

  const getVersionOptions = (reader: any) => {
    const versionOptions = currentBook.content.map((content: any) => ({
      key: content._id,
      value: content._id,
      text: `v${content.versionNumber}`,
      onClick: () =>
        onUpdateReader(reader.book, reader._id, {
          contentVersion: content._id
        })
    }));
    return versionOptions;
  };

  const toggleInvitationForm = () => {
    setShowInvitationForm(prev => !prev);
  };

  const disabledAccess =
    !reader.enabled || reader.invitation.status === 'ABANDONED';
  const x = reader?.swap?.title;

  return (
    <Table.Row
      key={reader._id}
      error={reader.invitation.status === 'BOUNCED'}
      verticalAlign='middle'>
      {isSelectable && (
        <Table.Cell
          width={1}
          textAlign='center'
          style={{
            cursor: 'pointer'
          }}>
          <Checkbox
            checked={reader.selected}
            data-reader-id={reader._id}
            onChange={() => onSelectedChange(reader._id)}
          />
        </Table.Cell>
      )}
      <Table.Cell
        width={1}
        textAlign='center'
        data-user-id={reader.user && reader.user._id}
        style={{
          cursor: 'pointer'
        }}>
        <Avatar
          name={reader.user && reader.user.displayName}
          diameter={30}
          selected={reader.selected}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            onShowReaderProfile(reader.user?._id);
          }}
        />
        {reader.swap && (
          <Popup
            hoverable
            content={
              ['SENT', 'REQUESTED'].includes(reader.invitation?.status) ? (
                <Trans
                  i18nKey='ThisIsASwapRequestForX'
                  values={{x: reader.swap?.title ?? 'another book'}}>
                  This is a swap request for{' '}
                  <a
                    href={`/discover/${reader.swap._id}`}
                    target='betareader.io_reader'>
                    {{x}}
                  </a>
                  . If you approve this request, you will be added as a reader
                  for {{x}}.
                </Trans>
              ) : (
                <Trans
                  i18nKey='ApprovedSwapRequestForX'
                  values={{x: reader.swap?.title ?? 'another book'}}>
                  This is a swap request for{' '}
                  <a
                    href={`/discover/${reader.swap._id}`}
                    target='betareader.io_reader'>
                    {x}
                  </a>
                  .
                </Trans>
              )
            }
            key='exchange'
            header={t('SwapRequest')}
            trigger={
              <Icon
                size='small'
                color='grey'
                name='exchange'
                floated='right'
                style={{position: 'absolute'}}
              />
            }
          />
        )}
      </Table.Cell>
      <Table.Cell>
        {reader.invitation.status !== 'REQUESTED' &&
          !showInvitationForm &&
          `${reader.name} ${reader.externalId ? `(${reader.externalId})` : ''}`}
        {!reader.enabled &&
          reader.invitation.status !== 'REQUESTED' &&
          ' (access disabled)'}
        {reader.invitation.status === 'APPROVED' && (
          <span style={{fontSize: '0.8em', color: 'grey'}}>
            <br />
            Approved on{' '}
            {new Date(reader.invitation.approvedAt).toLocaleDateString()}
          </span>
        )}
        {reader.invitation.status === 'ACCEPTED' && (
          <span style={{fontSize: '0.8em', color: 'grey'}}>
            <br />
            Accepted on{' '}
            {new Date(reader.invitation.acceptedAt).toLocaleDateString()}
          </span>
        )}
        {reader.invitation.status === 'DECLINED' && (
          <span style={{fontSize: '0.8em', color: 'grey'}}>
            <br />
            Declined on{' '}
            {new Date(reader.invitation.declinedAt).toLocaleDateString()}
          </span>
        )}
        {reader.invitation.status === 'CREATED' && showInvitationForm && (
          <InvitationForm
            invitationSent={toggleInvitationForm}
            reader={reader}
            dismissable
          />
        )}
        {reader.invitation.status === 'CREATED' && !showInvitationForm && (
          <span style={{fontSize: '0.8em', color: 'grey'}}>
            <br />
            <Icon size='small' color='red' name='warning sign' />
            {`${t('FailedToSendInvitation')} `}
            {updatingReader === reader._id ? (
              'Sending invitation'
            ) : (
              <Link
                to='#'
                onClick={toggleInvitationForm}
                data-reader-id={reader._id}>
                {t('EditInvitation').toLowerCase()}
              </Link>
            )}
          </span>
        )}
        {reader.invitation.status === 'SENT' && (
          <span style={{fontSize: '0.8em', color: 'grey'}}>
            <br />
            Invited on {new Date(
              reader.invitation.sentAt
            ).toLocaleDateString()}{' '}
            {updatingReader === reader._id && 'Sending invitation'}
          </span>
        )}
        {reader.invitation.status === 'ABANDONED' && (
          <span style={{fontSize: '0.8em', color: 'grey'}}>
            <br />
            Abandoned on{' '}
            {new Date(reader.invitation.abandonedAt).toLocaleDateString()}
          </span>
        )}
        {reader.invitation.status === 'REQUESTED' && (
          <JoinRequestItem reader={reader} />
        )}
        <ReaderSignupQuestionsItem
          questions={reader.readerSignupSurveyQuestions}
          initialOpen={reader.invitation.status === 'REQUESTED' ? true : false}
        />
      </Table.Cell>
      <Table.Cell disabled={disabledAccess} collapsing>
        {moment(new Date(reader.createdAt)).fromNow()}
        <br />
        <span style={{fontSize: '0.8em', color: 'grey'}}>
          {moment(new Date(reader.createdAt)).format('L')}
        </span>
      </Table.Cell>
      <Table.Cell disabled={disabledAccess} collapsing>
        {`v${reader.contentVersionNumber || 1}`}
        <br />
      </Table.Cell>
      {['APPROVED', 'ACCEPTED', 'ABANDONED'].includes(
        reader.invitation.status
      ) && (
        <Table.Cell disabled={disabledAccess}>
          {['APPROVED', 'CREATED', 'SENT'].includes(
            reader.invitation.status
          ) && <span>Not started.</span>}
          {['ACCEPTED', 'ABANDONED'].includes(reader.invitation.status) && (
            <ReadingGraph
              date={
                reader.completedBookAt || reader.readingData?.lastSeenAtDate
              }
              failureMessage='Not started'
            />
          )}
        </Table.Cell>
      )}
      {['APPROVED', 'ACCEPTED', 'ABANDONED'].includes(
        reader.invitation.status
      ) && (
        <Table.Cell disabled={disabledAccess} id={`last_seen_cell_$`}>
          <Link
            to={`${route.url}/${reader._id}`}
            onClick={e => e.stopPropagation()}>
            {['APPROVED', 'CREATED', 'SENT'].includes(
              reader.invitation.status
            ) && <span>Not started.</span>}
            {['ACCEPTED', 'ABANDONED'].includes(reader.invitation.status) && (
              <ReadingGraph
                date={
                  reader.completedBookAt || reader.readingData?.lastSeenAtDate
                }
                failureMessage='Not started'
              />
            )}
          </Link>
        </Table.Cell>
      )}
      {['APPROVED', 'ACCEPTED', 'ABANDONED'].includes(
        reader.invitation.status
      ) && (
        <Table.Cell
          id={`last_seen_cell_${reader._id}`}
          disabled={disabledAccess}>
          {['APPROVED'].includes(reader.invitation.status) && (
            <span>Not started.</span>
          )}
          {['ACCEPTED', 'ABANDONED'].includes(reader.invitation.status) && (
            <div>
              {reader.completedBookAt &&
                t('FinishedReadingAt', {
                  x: new Date(reader.completedBookAt).toLocaleDateString()
                })}
              {!reader.completedBookAt &&
                reader.readingData?.lastSeenAtPartTitle}
            </div>
          )}
        </Table.Cell>
      )}
      <Table.Cell textAlign='right' collapsing>
        <Button.Group size='mini' icon disabled={!isEditable}>
          {['REQUESTED'].includes(reader.invitation.status) &&
            contentVersionOptions &&
            contentVersionOptions.length === 1 && (
              <Button
                color='green'
                basic
                disabled={reader?.source !== 'book' && !canAddMoreReaders}
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  onApproveJoinRequest(reader);
                }}>
                <Icon name='checkmark' color='green' />
                {reader.swap ? t('ApproveAndJoin') : t('Approve')}
              </Button>
            )}
          {['REQUESTED'].includes(reader.invitation.status) &&
            reader?.source !== 'book' &&
            contentVersionOptions &&
            contentVersionOptions.length !== 1 &&
            !canAddMoreReaders && (
              <Button color='green' basic disabled={!canAddMoreReaders}>
                <Icon name='checkmark' color='green' />
                {t('Accept')}
              </Button>
            )}
          {['REQUESTED'].includes(reader.invitation.status) &&
            contentVersionOptions &&
            contentVersionOptions.length !== 1 &&
            (reader?.source === 'book' || canAddMoreReaders) && (
              <Dropdown
                trigger={
                  <Button
                    icon='checkmark'
                    basic
                    color='green'
                    content={t('Accept')}
                  />
                }>
                <Dropdown.Menu>
                  <Dropdown.Header>
                    <div
                      style={{
                        color: 'black',
                        fontSize: 10,
                        fontWeight: 'bold'
                      }}>
                      {t('PickVersion')}
                    </div>
                  </Dropdown.Header>
                  {contentVersionOptions.map(option => (
                    <Dropdown.Item
                      key={option.key}
                      content={option.text}
                      onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        onApproveJoinRequest(reader, option.value);
                      }}
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          {['REQUESTED'].includes(reader.invitation.status) && (
            <Button
              data-reader-id={reader._id}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                onDeclineJoinRequest(reader._id);
              }}
              icon='remove'
              color='red'
              basic
              content={t('Decline')}
            />
          )}
          {['APPROVED', 'SENT', 'ACCEPTED', 'CREATED', 'ABANDONED'].includes(
            reader.invitation.status
          ) && (
            <Popup
              trigger={
                <Button
                  disabled={!isEditable}
                  icon='ellipsis vertical'
                  basic
                  color='grey'
                  onClick={event => event.stopPropagation()}
                />
              }
              content={
                <List link>
                  <List.Item
                    as='a'
                    icon='user'
                    content={t('Profile')}
                    data-reader-id={reader._id}
                    data-user-id={reader.user && reader.user._id}
                    onClick={() => onGoToProfile(reader.user?._id)}
                  />
                  {!!reader.user &&
                    !['CREATED', 'SENT', 'APPROVED'].includes(
                      reader.invitation.status
                    ) && (
                      <List.Item
                        as='a'
                        icon='mail'
                        content={t('Message')}
                        data-reader-id={reader._id}
                        onClick={() => onMessage(reader._id)}
                      />
                    )}
                  <List.Item
                    as='a'
                    disabled={reader.invitation.status === 'ACCEPTED'}
                    icon='delete'
                    content={t('Delete')}
                    data-reader-id={reader._id}
                    onClick={() => onDelete(reader._id)}
                  />
                  <List.Item
                    as='a'
                    disabled={
                      reader?.source !== 'book' &&
                      !canAddMoreReaders &&
                      !reader.enabled
                    }
                    icon={
                      <Icon
                        name={reader.enabled ? 'toggle on' : 'toggle off'}
                        color={reader.enabled ? 'green' : 'grey'}
                      />
                    }
                    content={reader.enabled ? 'Disable' : 'Enable'}
                    data-reader-id={reader._id}
                    onClick={() => onToggleEnabled(reader._id)}
                  />
                  {contentVersionOptions.length > 1 &&
                    reader.invitation.status !== 'ABANDONED' && (
                      <List.Item
                        as='a'
                        icon='edit outline'
                        content={
                          <Dropdown
                            disabled={!isEditable}
                            text={t('SwitchVersion')}
                            options={getVersionOptions(reader)}
                          />
                        }
                        data-reader-id={reader._id}
                      />
                    )}
                  {['CREATED', 'SENT'].includes(reader.invitation.status) && ( // only expose inviation link for invitations that the author has created themselves
                    <List.Item
                      as='a'
                      icon='linkify'
                      content='Get invitation link'
                      data-token={reader.invitation.token}
                      data-email={reader.invitation.email}
                      onClick={() =>
                        onCopyURLToClipBoard(reader.invitation.token)
                      }
                    />
                  )}
                  {reader.invitation.status === 'SENT' && (
                    <List.Item
                      as='a'
                      icon='mail'
                      content='Resend invitation'
                      onClick={() => onSendInvitation(reader._id)}
                      data-reader-id={reader._id}
                    />
                  )}
                </List>
              }
              on='click'
              position='top right'
            />
          )}
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );
};

export default ReaderEntryTableRow;
