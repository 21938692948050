import React, {useState, useEffect, useRef, useCallback} from 'react';
import {Visibility} from 'semantic-ui-react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import {toggleShowFixedMenu} from '../../../../modules/app';

import {
  setActiveInlineComment,
  removeInlineCommentPlaceholder,
  removeEmptyInlineCommentPlaceholder,
  fetchComments
} from '../../../../modules/comment';

import {
  STARTED_CHAPTER,
  COMPLETED_CHAPTER,
  SCROLLED_UP,
  SCROLLED_DOWN,
  CHAPTER_MID_POINT,
  CHAPTER_FIRST_QUARTILE,
  CHAPTER_THIRD_QUARTILE,
  track
} from '../../../../utils/tracker';

import TextEditor from '../../../../components/textEditor/textEditor';
import {authUtil} from '../../../../utils';

const readingWrapperStyle = {fontFamily: 'Georgia', wordWrap: 'break-word'};

const ReadingWrapper = styled.div`
  font-family: Georgia, 'Times New Roman', Times, serif;
  word-wrap: break-word;
`;

const TextEditorWrapper = styled.div`
  position: relative;
`;

const textSegmentStyle = {
  // ...readingScrollStyle
  fontFamily: 'Georgia!important'
};

const getBottomPercentagePassed = (topPixelsPassed, elementHeight) => {
  const viewPortHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
  const bottomPixelsPassed = viewPortHeight + topPixelsPassed;
  let bottomPercentage = bottomPixelsPassed / elementHeight;
  if (bottomPercentage > 1) {
    bottomPercentage = 1;
  } else if (bottomPercentage < 0) {
    bottomPercentage = 0;
  }
  return bottomPercentage;
};

const TextSegment = ({
  chapter,
  dir,
  initialPercentagePassed,
  showFixedMenu,

  percentagePassedUpdated
}) => {
  const dispatch = useDispatch();
  const mouseDownTimestamp = useRef();
  const wrapperRef = useRef();

  const currentBook = useSelector(state => state.book.currentBook);
  const currentSpineIndex = useSelector(
    state => state.readerApp.currentSpineIndex
  );
  const defaultFontSize = useSelector(state => state.app.defaultFontSize);
  const comments = useSelector(state => state.comment.comments);
  const activeInlineComment = useSelector(
    state => state.comment.activeInlineComment
  );
  const user = useSelector(state => state.user.user);
  const userProfile = useSelector(state => state.user.userProfile);

  const textSegmentRef = useRef();
  const [percentagePassed, setPercentagePassed] = useState(
    initialPercentagePassed
  );
  const [tracked, setTracked] = useState({});
  const [initiated, setInitiated] = useState(false);
  const [content, setCurrentContent] = useState();
  const [lastScrollPosition, setLastScrollPosition] = useState({
    up: 0,
    down: 0
  });

  useEffect(() => {
    const height = textSegmentRef.current.scrollHeight;
    const elementTop = textSegmentRef.current.getBoundingClientRect().top;
    const pixelsPassedTop =
      percentagePassed < 0.001
        ? parseInt(percentagePassed)
        : parseInt(percentagePassed * height + elementTop);
    window.scrollTo(0, pixelsPassedTop > 0 ? pixelsPassedTop : 1);
    setInitiated(true);
    const bottomPercentage = getBottomPercentagePassed(pixelsPassedTop, height);
    percentagePassedUpdated({top: percentagePassed, bottom: bottomPercentage});
    if (!!currentBook && currentSpineIndex !== undefined) {
      resetTracking();
      // fetchComments();
    }
  }, [currentSpineIndex, currentBook]);

  const handleTextClick = event => {
    dispatch(toggleShowFixedMenu(!showFixedMenu));
  };

  const handleMouseDown = event => {
    mouseDownTimestamp.current = Date.now();
  };

  const handleMouseUp = event => {
    const timeSinceClick = Date.now() - mouseDownTimestamp.current; // time in milliseconds since last mouse down
    if (timeSinceClick < 500) {
      handleTextClick(event);
    }
  };

  const handleVisibilityChange = useCallback(
    (event, {calculations}) => {
      const setTrackedQuartile = event => {
        let updatedTracked = {...tracked};
        updatedTracked[event] = true;
        setTracked(updatedTracked);
      };
      const resetTrackedQuartile = event => {
        let updatedTracked = {...tracked};
        updatedTracked[event] = false;
        setTracked(updatedTracked);
      };

      const insideTrackingWindow = (event, calculations) => {
        const {pixelsPassed, height} = calculations;

        const start = 0;
        const firstQ = height * 0.25;
        const mid = height * 0.5;
        const thirdQ = height * 0.75;
        const complete = height * 1;

        const viewPort = {
          top: pixelsPassed,
          bottom: pixelsPassed + window.innerHeight
        };
        switch (event) {
          case STARTED_CHAPTER:
            return start >= viewPort.top && start < viewPort.bottom;
          case CHAPTER_FIRST_QUARTILE:
            return firstQ >= viewPort.top && firstQ < viewPort.bottom;
          case CHAPTER_MID_POINT:
            return mid >= viewPort.top && mid < viewPort.bottom;
          case CHAPTER_THIRD_QUARTILE:
            return thirdQ >= viewPort.top && thirdQ < viewPort.bottom;
          case COMPLETED_CHAPTER:
            return complete >= viewPort.top && complete < viewPort.bottom;
          default:
            return false;
        }
      };

      const handleVisibilityTracking = calculations => {
        const {topVisible, percentagePassed, pixelsPassed, direction, height} =
          calculations;

        // hide and show fixed menu
        let pixelsUpBeforeShowingMenu = window.innerHeight
          ? window.innerHeight * 0.05
          : 25; // 5% of the browser window height or 25 pixels
        if (calculations.direction === 'down' && !topVisible) {
          dispatch(toggleShowFixedMenu(false));
        } else if (
          !showFixedMenu &&
          calculations.direction === 'up' &&
          lastScrollPosition.down - pixelsPassed > pixelsUpBeforeShowingMenu
        ) {
          dispatch(toggleShowFixedMenu(true));
        }

        const bottomPercentage = getBottomPercentagePassed(
          pixelsPassed,
          height
        );
        percentagePassedUpdated({
          top: percentagePassed,
          bottom: calculations.bottomPassed ? 1 : bottomPercentage || 0
        });

        let considerScrolled = window.innerHeight
          ? window.innerHeight / 10
          : 50; // 10% of the browser window height or 50 pixels

        if (
          direction === 'up' &&
          !tracked[SCROLLED_UP] &&
          lastScrollPosition.down - pixelsPassed > considerScrolled
        ) {
          // track scrolled up if moving up more than considerScrolled amount from last newScrollPosition
          trackEvent(SCROLLED_UP);
          let newTracked = {...tracked};
          newTracked[SCROLLED_UP] = true;
          newTracked[SCROLLED_DOWN] = false;
          setTracked(newTracked);
        }

        if (
          direction === 'down' &&
          !tracked[SCROLLED_DOWN] &&
          tracked[SCROLLED_UP] &&
          pixelsPassed - lastScrollPosition.up > considerScrolled
        ) {
          // track scrolled down if moving down and we have moved up before
          trackEvent(SCROLLED_DOWN);
          let newTracked = {...tracked};
          newTracked[SCROLLED_DOWN] = true;
          newTracked[SCROLLED_UP] = false;
          setTracked(newTracked);
        }

        // track started
        if (
          insideTrackingWindow(STARTED_CHAPTER, calculations) &&
          !tracked[STARTED_CHAPTER]
        ) {
          trackEvent(STARTED_CHAPTER);
          setTrackedQuartile(STARTED_CHAPTER);
        }
        if (
          tracked[STARTED_CHAPTER] &&
          !insideTrackingWindow(STARTED_CHAPTER, calculations)
        ) {
          resetTrackedQuartile(STARTED_CHAPTER);
        }
        // track first quartile
        if (
          !tracked[CHAPTER_FIRST_QUARTILE] &&
          insideTrackingWindow(CHAPTER_FIRST_QUARTILE, calculations)
        ) {
          trackEvent(CHAPTER_FIRST_QUARTILE);
          setTrackedQuartile(CHAPTER_FIRST_QUARTILE);
        }
        if (
          tracked[CHAPTER_FIRST_QUARTILE] &&
          !insideTrackingWindow(CHAPTER_FIRST_QUARTILE, calculations)
        ) {
          resetTrackedQuartile(CHAPTER_FIRST_QUARTILE);
        }
        // track mid point
        if (
          !tracked[CHAPTER_MID_POINT] &&
          insideTrackingWindow(CHAPTER_MID_POINT, calculations)
        ) {
          trackEvent(CHAPTER_MID_POINT);
          setTrackedQuartile(CHAPTER_MID_POINT);
        }
        if (
          tracked[CHAPTER_MID_POINT] &&
          !insideTrackingWindow(CHAPTER_MID_POINT, calculations)
        ) {
          resetTrackedQuartile(CHAPTER_MID_POINT);
        }
        // track third quartile
        if (
          !tracked[CHAPTER_THIRD_QUARTILE] &&
          insideTrackingWindow(CHAPTER_THIRD_QUARTILE, calculations)
        ) {
          trackEvent(CHAPTER_THIRD_QUARTILE);
          setTrackedQuartile(CHAPTER_THIRD_QUARTILE);
        }
        if (
          tracked[CHAPTER_THIRD_QUARTILE] &&
          !insideTrackingWindow(CHAPTER_THIRD_QUARTILE, calculations)
        ) {
          resetTrackedQuartile(CHAPTER_THIRD_QUARTILE);
        }
        // track completed
        if (
          insideTrackingWindow(COMPLETED_CHAPTER, calculations) &&
          !tracked[COMPLETED_CHAPTER]
        ) {
          trackEvent(COMPLETED_CHAPTER);
          setTrackedQuartile(COMPLETED_CHAPTER);
        }
        if (
          tracked[COMPLETED_CHAPTER] &&
          !insideTrackingWindow(COMPLETED_CHAPTER, calculations)
        ) {
          resetTrackedQuartile(COMPLETED_CHAPTER);
        }

        let newScrollPosition = {...lastScrollPosition};
        newScrollPosition[direction] = pixelsPassed;
        setPercentagePassed(calculations.percentagePassed);
        setLastScrollPosition(newScrollPosition);
      };

      // enable scroll detection
      if (initiated) {
        handleVisibilityTracking(calculations);
      }
    },
    [initiated, tracked, lastScrollPosition, percentagePassed]
  );

  const resetTracking = () => {
    setTracked({});
    setLastScrollPosition({
      up: 0,
      down: 0
    });
  };

  const trackEvent = useCallback(
    event => {
      track({
        book: currentBook._id,
        data: [
          {
            event: {id: event},
            user: {id: user.uid},
            book: {id: currentBook._id},
            chapter: {id: currentBook.parts[currentSpineIndex]._id}
          }
        ]
      });
    },
    [currentBook?._id, user?.uid, currentSpineIndex, currentBook?.parts]
  );

  const onTextEditorInitialized = async () => {
    const idToken = await authUtil.getFreshIdToken();
    dispatch(
      fetchComments(
        idToken,
        currentBook._id,
        currentBook.parts[currentSpineIndex]._id
      )
    );
  };

  const handleCopyAttempt = event => {
    event.preventDefault();
  };

  const getInlineComments = useCallback(
    () => comments?.filter(comment => comment.kind === 'InlineComment'),
    [comments]
  );
  const getCurrentFontSize = useCallback(
    () => userProfile?.readerSettings?.fontSize ?? defaultFontSize,
    [userProfile?.readerSettings?.fontSize, defaultFontSize]
  );

  if (chapter.content !== undefined) {
    return (
      <ReadingWrapper
        onCopy={handleCopyAttempt}
        onCut={handleCopyAttempt}
        ref={wrapperRef}>
        <Visibility
          context={wrapperRef}
          fireOnMount
          continuous
          onUpdate={handleVisibilityChange}>
          <TextEditorWrapper
            ref={textSegmentRef}
            dir={dir}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}>
            <TextEditor
              manuscriptStyle={currentBook.manuscriptStyle}
              editorMode='reading'
              language={currentBook.language}
              className={`br-text font-size-${getCurrentFontSize()}`}
              // ref={textSegmentRef}
              style={textSegmentStyle}
              enableButtons={{
                comment: true,
                reactions: true
              }}
              allowComments={
                currentBook !== undefined && currentBook.enableChapterComments
              }
              dir={dir}
              readOnly // no changing of text allowed when reading the book
              inlineToolbarType='reader'
              content={chapter.content}
              inlineComments={getInlineComments()}
              clearActiveInlineComment={() =>
                dispatch(setActiveInlineComment())
              }
              removeInlineCommentPlaceholder={() =>
                dispatch(removeInlineCommentPlaceholder())
              }
              removeEmptyInlineCommentPlaceholder={() =>
                dispatch(removeEmptyInlineCommentPlaceholder())
              }
              contentChanged={content => {
                setCurrentContent(content);
              }}
              activeInlineComment={activeInlineComment}
              onInitialized={onTextEditorInitialized}
            />
          </TextEditorWrapper>
        </Visibility>
      </ReadingWrapper>
    );
  }
  return null;
};

export default TextSegment;
