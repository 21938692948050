import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Form, Button, Message, Container, Dropdown, TextArea, Loader
} from 'semantic-ui-react';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import genres from '../../shared/constants/genres';
import {
  updateUserProfile,
  updateUser,
  getUserProfile
} from '../../modules/user';

class NewUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: undefined,
      preferredGenres: undefined,
      sentVerification: localStorage.getItem('sent-verification')
    };
    localStorage.removeItem('sent-verification');
  }

  displayNameChanged = (event, data) => {
    this.setState({
      displayName: data.value
    })
  }
  saveDisplayName = () => {
    const {updateUserProfile, idToken} = this.props;
    updateUserProfile(idToken, {
      displayName: this.state.displayName
    });
  }
  saveRole = (role) => {
    const {updateUserProfile, idToken} = this.props;
    updateUserProfile(idToken, {
      role: role
    });
  }
  preferredGenresUpdated = (event,data) => {
    this.setState({
      preferredGenres: data.value
    });
  }
  savePreferredGenres = () => {
    const {updateUserProfile, idToken} = this.props;
    updateUserProfile(idToken, {
      preferredGenres: this.state.preferredGenres
    });
  }
  saveReceiveEmails = (receiveEmails) => {
    const {updateUserProfile, idToken} = this.props;
    updateUserProfile(idToken, {
      receiveEmails: receiveEmails
    });
  }
  motivationChanged = (event, data) => {
    this.setState({
      motivation: data.value
    });
  }
  saveMotivation = () => {
    const {updateUserProfile, idToken} = this.props;
    updateUserProfile(idToken, {
      motivation: this.state.motivation
    });
  }

  render() {
    let { userProfile, userProfileLoaded } = this.props;
    const genreOptions = genres.map((genre) => {
      return { key: genre, value: genre, text: genre }
    });
    if (userProfile === undefined) {
      userProfile = {};
    }
    const {
      displayName, role, preferredGenres, receiveEmails, motivation
    } = userProfile;
    if(!userProfileLoaded) {
      return (<Loader/>)
    } else {
      return (
        <Container text>
          {(displayName === undefined) &&
            <div>
              <Message info size='large'>
                Great! Now, tell us a bit about you.
              </Message>
              <Form size='big' onSubmit={this.saveDisplayName}>
              <Form.Group widths='equal'>
                <Form.Input
                  size='big'
                  fluid
                  placeholder='What is your name?'
                  onChange={this.displayNameChanged}
                />
              </Form.Group>
              <Button size='big' content='Next' icon='right arrow' fluid labelPosition='right' type='submit'/>
            </Form>
            </div>
          }
          {(displayName !== undefined && role === undefined) &&
            <div>
              <Message info size='large'>
                Thanks, {displayName}! Are you a reader or a writer?
              </Message>
              <Form size='big'>
                <Button.Group fluid size='huge'>
                  <Button onClick={() => {this.saveRole('WRITER')}}>Writer</Button>
                  <Button.Or/>
                  <Button onClick={() => {this.saveRole('BOTH')}}>Both</Button>
                  <Button.Or/>
                  <Button onClick={() => {this.saveRole('READER')}}>Reader</Button>
                </Button.Group>
              </Form>
            </div>
          }
          {(displayName !== undefined && role !== undefined && preferredGenres === undefined) &&
            <div>
              <Message info size='large'>
                {role === 'WRITER' && 'A fellow writer!'}
                {role === 'READER' && 'We also love to read!'}
                {role === 'BOTH' && 'Reading and writing do go hand in hand :)'}
                {' '} Which genres do you prefer{role === 'WRITER' && ' to write in'}{role === 'READER' && ' to read'}?
              </Message>
              <Form size='big'>
                <Form.Group widths='equal'>
                  <Form.Field control={Dropdown} placeholder='Preferred genres' fluid multiple search selection options={genreOptions} onChange={this.preferredGenresUpdated}/>
                </Form.Group>
              </Form>
              <Button content='Next' size='big' icon='right arrow' fluid labelPosition='right' onClick={this.savePreferredGenres}/>
            </div>
          }
          {(displayName !== undefined && role !== undefined && preferredGenres !== undefined && receiveEmails === undefined) &&
            <div>
              <Message info size='large'>
                {preferredGenres[0]} is great! Almost finished now: do you want to receive emails when things change?
                {' '}
                {role === 'READER' && '(For example when the writer updates a chapter)'}
                {role === 'BOTH' && '(For example when a reader leaves a feedback note)'}
              </Message>
              <Form size='big'>
                <Button.Group fluid size='huge'>
                  <Button onClick={() => {this.saveReceiveEmails(true)}}>Yes, please!</Button>
                  <Button.Or/>
                  <Button onClick={() => {this.saveReceiveEmails(false)}}>No, thanks!</Button>
                </Button.Group>
              </Form>
            </div>
          }
          {(displayName !== undefined && role !== undefined && preferredGenres !== undefined && receiveEmails !== undefined && motivation === undefined) &&
            <div>
              <Message info size='large'>
                Got it! Last question: Why do you want to use BetaReader?
              </Message>
              <Form size='big' onSubmit={this.saveMotivation}>
                <Form.Field control={TextArea}
                  placeholder='We are keen to know...'
                  onChange={this.motivationChanged} />
                <Form.Field size='big' control={Button} type='submit'>Submit</Form.Field>
              </Form>
            </div>
          }
          {(displayName !== undefined && role !== undefined && preferredGenres !== undefined && receiveEmails !== undefined && motivation !== undefined) &&
            <div>
              <Message info size='large'>
                That's all!
              </Message>
              <Form size='big'>
                <Form.Field size='big' control={Button} href='/profile'>Complete account creation</Form.Field>
              </Form>
            </div>
          }
        </Container>
      );
    }
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  userProfileLoaded: state.user.userProfileLoaded
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateUserProfile,
  updateUser,
  getUserProfile,
  changePage: newPage => push(newPage)
}, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(NewUser));
