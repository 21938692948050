import React, {Component} from 'react';
import {Segment, Label, Button, Icon, Dropdown} from 'semantic-ui-react';
import {withTranslation} from 'react-i18next';
import {Media} from 'src/utils/Media';
import Avatar from '../avatar/avatar';

class UserBadge extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggleShowAllGenres = () =>
    this.setState(prevState => ({
      showAllGenres: !prevState.showAllGenres
    }));

  handleInviteClick = () => {
    if (this.props.onInvite) {
      this.props.onInvite();
    }
  };

  render() {
    const {t, user, showInviteButton = true} = this.props;
    const {showAllGenres} = this.state;
    if (!user) {
      return null;
    }
    return (
      <Segment
        key={user._id}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start'
        }}
        content={
          <React.Fragment>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start'
              }}>
              <div style={{width: 50, marginRight: 15}}>
                <Avatar
                  name={user.displayName}
                  userId={user._id}
                  src={user.photoUrl}
                  diameter={50}
                />
              </div>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start'
                  }}>
                  <strong>
                    <Avatar
                      name={user.displayName}
                      userId={user._id}
                      nameOnly
                    />
                  </strong>
                  <span style={{marginLeft: 5}}>
                    {user.preferredLanguages &&
                      user.preferredLanguages.map(language => (
                        <Label
                          key={language}
                          basic
                          size='mini'
                          content={
                            language.nativeName ? language.nativeName : language
                          }
                        />
                      ))}
                  </span>
                </div>
                <div>
                  {user.preferredGenres &&
                    user.preferredGenres.map((genre, index, array) => {
                      const showMoreLimit = window.innerWidth > 450 ? 3 : 1;
                      if (!showAllGenres) {
                        // toggle show all if there are more genres to show
                        if (
                          index === showMoreLimit &&
                          array.length > showMoreLimit + 1
                        ) {
                          return (
                            <Icon
                              key='toggle'
                              link
                              name='ellipsis horizontal'
                              onClick={this.toggleShowAllGenres}
                            />
                          );
                        }
                        // stop rendering labels if not showing all
                        if (index > showMoreLimit - 1) {
                          return null;
                        }
                      }
                      return (
                        <Label
                          size='small'
                          key={genre}
                          circular
                          content={genre}
                          style={{marginTop: 2}}
                        />
                      );
                    })}
                </div>
                <p
                  style={{
                    paddingTop: 5,
                    whiteSpace: 'pre-line',
                    hyphens: 'auto',
                    wordBreak: 'break-word'
                  }}>
                  {user.bio}
                </p>
              </div>
              {showInviteButton && [
                <Media
                  as={Button}
                  color='orange'
                  greaterThan='mobile'
                  style={{
                    marginLeft: 'auto',
                    wordBreak: 'normal',
                    whiteSpace: 'pre'
                  }}
                  content={t('Invite')}
                  icon='mail'
                  onClick={this.handleInviteClick}
                  key='large-button'
                />,
                <Media
                  as={Dropdown}
                  at='mobile'
                  direction='left'
                  style={{marginLeft: 'auto'}}
                  icon='ellipsis vertical'
                  key='small-button'>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      icon='mail'
                      text={t('Invite')}
                      onClick={this.handleInviteClick}
                    />
                  </Dropdown.Menu>
                </Media>
              ]}
            </div>
          </React.Fragment>
        }
      />
    );
  }
}

export default withTranslation()(UserBadge);
