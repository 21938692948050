import React, { Component } from 'react';
import {
  Grid, Container
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';

// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ReaderBonusOverview from './readerBonusOverview';
import GetMoreReadersMessage from '../../components/standard-messages/getMoreReadersMessage';
import UnlockSignupBonusMessage from '../../components/standard-messages/unlockSignupBonusMessage';

import {
  updateWritingList
} from '../../modules/book';

import {
  updateUserProfile,
  updateUser,
  getUserProfile,
  refreshUserProfile
} from '../../modules/user';

import {
  authUtil,
  uxAnalyticsUtil
} from '../../utils';

export class ReaderBonus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { history } = this.props;
    uxAnalyticsUtil.trackPageView(history.location.pathname);
    this.fetchWritingList();
  }

  fetchWritingList = async () => {
    console.log('fetchWritingList');
    const { activeAccount, userProfile } = this.props;
    const idToken = authUtil.getFreshIdToken();
    let accountId = activeAccount && activeAccount._id;
    if (accountId === undefined) {
      accountId = userProfile && userProfile.account._id;
    }
    this.props.updateWritingList({
      idToken,
      accountId
    });
  }

  render() {
    const { userProfile, user, writing } = this.props;
    if (user !== undefined && userProfile !== undefined) {
      return (
        <React.Fragment>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <GetMoreReadersMessage user={user} userProfile={userProfile} />
              </Grid.Column>
            </Grid.Row>
            {
              userProfile && !!userProfile.wasReferred && !userProfile.signupReferralBonus && (
                <Grid.Row>
                  <Grid.Column>
                    <UnlockSignupBonusMessage key='unlock-bonus' userProfile={userProfile} user={user} writing={writing} />
                  </Grid.Column>
                </Grid.Row>
              )
            }
            <Grid.Row>
              <Grid.Column>
                <ReaderBonusOverview />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </React.Fragment>
      );
    }
    return null;
  }
}

// redux stuff
const mapStateToProps = state => ({
  activeAccount: state.account.activeAccount,
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  writing: state.book.writing
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateUserProfile,
  updateUser,
  getUserProfile,
  refreshUserProfile,
  updateWritingList
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ReaderBonus)));
