import React from 'react';
import {
  Rating, Grid, Feed
} from 'semantic-ui-react';
import moment from 'moment';
import i18n from '../../utils/i18n/i18n';
import Avatar from '../avatar/avatar';

const ReviewItem = (
  {
    reviewData
  }
) => (
  <Feed.Event key={reviewData._id}>
    {
      reviewData.displayName && (
        <Feed.Label>
          <Avatar name={reviewData.displayName} />
        </Feed.Label>
      )
    }
    <Feed.Content>
      {
        reviewData.displayName && (
          <Feed.Summary>
            <Feed.User>{reviewData.displayName}</Feed.User>
            <Feed.Date>{moment(new Date(reviewData.updatedAt)).fromNow()}</Feed.Date>
          </Feed.Summary>
        )
      }
      <Feed.Extra text>
        <Grid columns='equal'>
          <Grid.Row>
            <table className='plain-table'>
              <tbody>
                <tr>
                  <td>
                    {i18n.t('OverallRating')}
                    :
                  </td>
                  <td>
                    <Rating floated='right' icon='star' defaultRating={reviewData.overAllRating ? reviewData.overAllRating : reviewData.avgOverAllRating} maxRating={5} disabled />
                  </td>
                </tr>
                <tr>
                  <td>
                    {i18n.t('PlotRating')}
                    :
                  </td>
                  <td>
                    <Rating icon='star' defaultRating={reviewData.plotRating ? reviewData.plotRating : reviewData.avgPlotRating} maxRating={5} disabled />
                  </td>
                </tr>
                <tr>
                  <td>
                    {i18n.t('GrammarRating')}
                    :
                  </td>
                  <td>
                    <Rating icon='star' defaultRating={reviewData.grammarRating ? reviewData.grammarRating : reviewData.avgGrammarRating} maxRating={5} disabled />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div id={`${reviewData._id}_reviewtext`} style={{ whiteSpace: 'pre-wrap' }}>
                      {reviewData.text}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid.Row>
        </Grid>
      </Feed.Extra>
    </Feed.Content>
  </Feed.Event>
);

export default (ReviewItem);
