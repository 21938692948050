import React, { Component } from 'react';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import {
  Container, Image, Grid, Header, Button, Item, Icon, Label, Divider
} from 'semantic-ui-react';

import { withTranslation } from 'react-i18next';
import { updateSpineIndex } from '../../../../modules/readerApp';
import { updateJoinBetaRequest } from '../../../../modules/book';
import { getFreshIdToken } from '../../../../utils/authUtil';

// image handling
import imageUtil from '../../../../utils/imageUtil';

class Cover extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.props.percentagePassedUpdated({top: 0, bottom: 0});
  }

  // Set reader to ACCEPTED state first time started reading
  acceptInvitationAndGoToBook = async () => {
    const idToken = await getFreshIdToken();
    const { currentBook } = this.props;
    const options = {
      status: 'ACCEPTED'
    }
    this.props.updateJoinBetaRequest(idToken, currentBook.id, options, (succeeded) => {
      if (succeeded) {
        this.moveForward();
      }
    });
  }

  moveForward = (event) => {
    if (!!event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const { currentBook, currentSpineIndex, updateSpineIndex } = this.props;
    if (currentSpineIndex >= currentBook.parts.length - 1) {
      // at end of book, do nothing
      return;
    }
    updateSpineIndex(currentSpineIndex + 1);
    window.scrollTo(0, 0);
  }

  // custom methods
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const {
      t, user, currentBook, cover
    } = this.props;
    const { height } = this.state;
    const isAuthor = currentBook.author._id === user.uid;
    const startReadingFn = !isAuthor && currentBook.readerStatus === 'APPROVED' ? this.acceptInvitationAndGoToBook : this.moveForward;
    return (
      <Container>
        <Grid
          columns='equal'
          stackable
          verticalAlign='middle'
        >
          <Grid.Row>
            <Grid.Column textAlign='center'>
              <Image
                style={{ maxHeight: height - 100 }}
                verticalAlign='middle'
                centered
                src={
                  imageUtil.getImageUrl({
                    operation: 'width',
                    size: 500,
                    originalImageUrl: cover.highRes.url
                  })
                }
              />
            </Grid.Column>
            <Grid.Column>
              <Item.Group>
                <Item>
                  <Item.Content>
                    <Header as='h1' className='br-text'>
                      {currentBook.title}
                      <Header.Subheader className='br-text'>
                        {`${t('by')} ${currentBook.authorName}`}
                      </Header.Subheader>
                    </Header><br/><br/>
                    <Header as='h3' className='br-text'>{t("Description")}</Header>
                    <Divider />
                    <Item.Description>
                      {currentBook.description &&
                        <div className='br-text' dangerouslySetInnerHTML={{__html: currentBook.description.replace(/\n/g, "<br />")}}/>
                      }
                    </Item.Description>
                    <br/>
                    <Header as='h3' className='br-text'>{t("Genres")}</Header>
                    <br/>
                    { currentBook !== null && currentBook.genres.length > 0 &&
                      currentBook.genres.map(genre => {
                        return (
                          <Label style={{marginTop:5}} key={genre}>{genre}</Label>
                        )
                      })
                    }
                    <Divider hidden />
                    <Header as='h3' className='br-text'>{t("Feedback guidelines")}</Header>
                    <Divider />
                      <Item.Description>
                        {currentBook.feedbackGuidelines &&
                          <div className='br-text' dangerouslySetInnerHTML={{__html: currentBook.feedbackGuidelines.replace(/\n/g, "<br />")}}/>
                        }
                      </Item.Description>
                    <br/>
                    <Header as='h3' className='br-text'>{t("CopyrightInfo")}</Header>
                    <Item.Extra as='i'>
                    {currentBook.copyRightInfo &&
                      <div className='br-text' dangerouslySetInnerHTML={{__html: currentBook.copyRightInfo.replace(/\n/g, "<br />")}}/>
                    }
                  </Item.Extra>

                    <Item.Extra>
                    <Button onClick={startReadingFn}>
                      {t("StartReading")}
                      <Icon name='right chevron' />
                    </Button>
                  </Item.Extra>
                  </Item.Content>
                </Item>
              </Item.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        </Container>
    )
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  currentBook: state.book.currentBook,
  currentSpineIndex: state.readerApp.currentSpineIndex,
  currentLanguage: state.user.currentLanguage
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateSpineIndex,
  updateJoinBetaRequest,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Cover)));
