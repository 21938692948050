import React, { Component } from 'react';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  Container, Header, Button, Segment, List, Grid, Statistic, Image, Icon
} from 'semantic-ui-react';

import moment from 'moment';

import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { toast } from 'react-toastify';

import { getFreshIdToken } from '../../utils/authUtil';


const queryString = require('query-string');

class ReadathonIndex extends Component {
  constructor(props) {
    super(props);
    const parsedQueryString = queryString.parse(props.location.search);
    this.state = { parsedQueryString };
  }

  componentDidMount() {
    // code to handle case when old acceptinvitation was redirected to discover without a bookId
    // this.fetchReadathonStatus();
  }

  fetchReadathonStatus = async () => {
    const { t } = this.props;
    const idToken = await getFreshIdToken();
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/readathon`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return res.json().then((json) => {
          const error = new Error(json.msg || res.statusText)
          error.response = res;
          // add book if we get it in the response so we can show proper error message
          if (json.book) {
            error.book = json.book;
          }
          throw error;
        });
      })
      .then(({ current, finished }) => {
        this.setState({
          current, finished
        });
      })
      .catch((err) => {
        toast.info(t(err));
      });
  }

  render() {
    const {
      t, userProfile
    } = this.props;
    const { current, finished } = this.state;
    return (
      <Container columns='equal' className='br-text font-size-normal'>
        <Image centered src='/assets/images/readathon-logo.png' />
        <Header content={t('ReadathonHasEnded')} subheader={t('ReadathonInfo')} />
        <List>
          <List.Item>
            <Icon name='certificate' size='large' color='yellow' />
            <List.Content>
              {t('ReadathonPrize1')}
            </List.Content>
          </List.Item>
          <List.Item>
            <Icon name='certificate' size='large' color='grey' />
            <List.Content>
              {t('ReadathonPrize23')}
            </List.Content>
          </List.Item>
        </List>
        {
          !userProfile && [
            <Header key='signinheader' as='h3' content={t('SignInToJoin')} />,
            <Button key='signinbutton' color='black' compact content={t('SignIn')} onClick={() => this.props.changePage('/signup?redirect=/readathon')} />
          ]
        }
        {
          finished && [
            <Statistic.Group key='stats' columns={3}>
              {
                finished.books && finished.books.length > 0 && (
                  <Statistic
                    style={{ flex: 1 }}
                    label={t('WordsReviewedByYou')}
                    value={(finished.words ? finished.words.toLocaleString() : t('N/A'))}
                  />
                )
              }
              <Statistic
                style={{ flex: 1 }}
                label={t('WordsReviewedByEveryone')}
                value={finished.wordsTotal ? finished.wordsTotal.toLocaleString() : t('N/A')}
              />
              {/* {
                finished.books && finished.books.length > 0 && (
                  <Statistic
                    style={{ flex: 1 }}
                    label={t('ChanceOfWinning')}
                    value={!isNaN(finished.chance) ? `${finished.chance * 100}%` : t('N/A')}
                  />
                )
              } */}
            </Statistic.Group>,
            <Segment color='orange' key='status'>
              <Header content={t('Status')} />
              <Grid columns='equal'>
                <Grid.Column>
                  <Header
                    sub
                    content={t('Completed')}
                  />
                  <List>
                    {
                      finished.books && finished.books.map(book => (
                        <List.Item key={book._id} style={{ cursor: 'pointer' }} onClick={() => { this.props.changePage(`/read?book=${book._id}`)}}>
                          <List.Header>{book.title} ({t('WordCountString', { count: book.words.toLocaleString() })})</List.Header>
                          {t('CompletedAt')}
                          {': '}
                          {moment(book.completedAt).format('L')}
                        </List.Item>
                      ))
                    }
                  </List>
                </Grid.Column>
                <Grid.Column>
                  <Header sub content={t('CurrentlyReading')} />
                  <List>
                    {
                      current.books && current.books.map(book => (
                        <List.Item key={book._id} style={{ cursor: 'pointer' }} onClick={() => { this.props.changePage(`/discover/${book._id}`)}}>
                          <List.Header>{book.title}</List.Header>
                          {
                            book.status === 'REQUESTED' && (
                              <span>{t('Pending')}</span>
                            )
                          }
                          {
                            book.status !== 'REQUESTED' && (
                              <span>
                                {t('Position')}
                                {': '}
                                {((book.position || 0) * 100).toFixed(0)}
                                %
                              </span>
                            )
                          }
                        </List.Item>
                      ))
                    }
                  </List>
                </Grid.Column>
              </Grid>
            </Segment>,
            <Button
              key='discover'
              color='orange'
              onClick={() => { this.props.changePage('/discover'); }}
              content={t('FindSomethingToRead')}
            />
          ]
        }
      </Container>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  userProfile: state.user.userProfile,
  defaultFontSize: state.app.defaultFontSize,
  idToken: state.user.idToken
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadathonIndex)));
