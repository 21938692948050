import React from 'react';
import { Label, Icon } from 'semantic-ui-react';

const ReaderFilterLabel = (props) => {
  const { filteredReaders, onRemove } = props;
  return (
    <Label key='active-user'>
      {filteredReaders.displayName}
      <Icon
        name='delete'
        onClick={onRemove}
      />
    </Label>
  );
};

export default ReaderFilterLabel;
