import React from 'react';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import {Image, List, Header} from 'semantic-ui-react';
import {withTranslation} from 'react-i18next';
import styled from 'styled-components';

import './salesPitch.css';

const Container = styled.div`
  max-width: 450px;
`;

const AuthorPitch = ({t}) => (
  <React.Fragment>
    <Header
      size='small'
      content={t('AuthorsAndPublishers')}
      textAlign='center'
      subheader={t('FindOutWhatYourReadersThink')}
    />
    <Image src='/assets/images/author-pitch.png' floated='left' />
    <List>
      <List.Item>
        <List.Icon name='lock' />
        <List.Content>
          <List.Header>{t('PrivateSharing')}</List.Header>
          <List.Description>{t('PrivateSharingDescription')}</List.Description>
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Icon name='comments' />
        <List.Content>
          <List.Header>{t('CollectUnbiasedFeedback')}</List.Header>
          <List.Description>
            {t('CollectUnbiasedFeedbackDescription')}
          </List.Description>
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Icon name='area graph' />
        <List.Content>
          <List.Header>{t('AnalyzeReadingPatterns')}</List.Header>
          <List.Description>
            {t('AnalyzeReadingPatternsDescription')}
          </List.Description>
        </List.Content>
      </List.Item>
    </List>
  </React.Fragment>
);

const ReaderPitch = ({t}) => (
  <React.Fragment>
    <Header
      size='small'
      content={t('Readers')}
      textAlign='center'
      subheader={t('ReadAndInfluenceTheNextBestseller')}
    />
    <Image src='/assets/images/reader-pitch.png' floated='left' />
    <List>
      <List.Item>
        <List.Icon name='certificate' />
        <List.Content>
          <List.Header>{t('ReadItFirst')}</List.Header>
          <List.Description>{t('ReadItFirstDescription')}</List.Description>
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Icon name='comments' />
        <List.Content>
          <List.Header>{t('InfluenceTheStory')}</List.Header>
          <List.Description>
            {t('InfluenceTheStoryDescription')}
          </List.Description>
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Icon name='user' />
        <List.Content>
          <List.Header>{t('ConnectWithAuthors')}</List.Header>
          <List.Description>
            {t('ConnectWithAuthorsDescription')}
          </List.Description>
        </List.Content>
      </List.Item>
    </List>
  </React.Fragment>
);

// stateless component
const SalesPitch = ({t}) => (
  <AliceCarousel
    autoPlay
    autoWidth
    autoPlayInterval={10000}
    buttonsDisabled
    disableButtonsControls
    items={[<AuthorPitch t={t} />, <ReaderPitch t={t} />]}
    stagePadding={{
      paddingLeft: 0,
      paddingRight: 0
    }}
  />
);

export default withTranslation()(AuthorPitch);
