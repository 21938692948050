export const SET_READER_BONUS_LIST = 'readerBonus/SET_READER_BONUS_LIST';
export const APPEND_TO_READER_BONUS_LIST = 'readerBonus/APPEND_TO_READER_BONUS_LIST';

const initialState = {
  readerBonusList: []
};

// reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_READER_BONUS_LIST:
      return {
        ...state,
        readerBonusList: action.readerBonusList
      };
    case APPEND_TO_READER_BONUS_LIST:
      return {
        ...state,
        readerBonusList: [...state.readerBonusList, ...action.readerBonusList]
      };
    default:
      return state;
  }
};

// actions
export const fetchReaderBonusList = (idToken, accountId) => {
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/accounts/${accountId}/reader-bonus`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        } else {
          return res.json();
        }
      })
      .then(readerBonusList => dispatch({
        type: SET_READER_BONUS_LIST,
        readerBonusList
      }))
      .catch((err) => {
        console.error(err);
      });
  };
};

export const appendToReaderBonusList = (readerBonusList) => {
  return (dispatch) => {
    dispatch({
      type: APPEND_TO_READER_BONUS_LIST,
      readerBonusList
    });
  };
};
