import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Modal, Button, Segment
} from 'semantic-ui-react';

// i18n
import { withTranslation, Trans } from 'react-i18next';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ChapterFileUpload from './chapterFileUpload';
import {
  addChapters,
  createBookAndChapters,
  createNewVersion,
  fetchCurrentBook
} from '../../../../modules/book';
import ux from '../../../../utils/uxAnalyticsUtil';

class ChapterUploadModal extends Component {
  uploadMessageTimeout;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    ux.trackModalView('upload-manuscript');
  }

  render() {
    const {
      t, onClose, pendingChapters, createBook
    } = this.props;

    return (
      <div>
        <Modal open onClose={onClose}>
          <Modal.Header>
            {t('Upload')}
          </Modal.Header>
          <Modal.Content scrolling>
            <Segment
              placeholder
              style={{
                paddingLeft: 0, paddingRight: 0
              }}
            >
              <ChapterFileUpload
                createBook={createBook}
                onClose={onClose}
                uploadMessage={t('ClickToUploadDoc')}
              />
            </Segment>
            {
              createBook && (
                <em><Trans i18nKey='ImportBooksHelpMsg'>No one will be able to see your manuscript without your approval. Read about <a href='https://betareader.io/2020/03/10/importing-manuscripts/'>importing books</a>.</Trans></em>
              )
            }
          </Modal.Content>
          <Modal.Actions disabled={!pendingChapters}>
            <Button onClick={onClose}>{t('Cancel')}</Button>
            <Button disabled={!pendingChapters} icon='upload' color='green' onClick={this.submitParts} content={t('Import')} />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  currentBook: state.book.currentBook,
  pendingBook: state.book.pendingBook,
  pendingChapters: state.book.pendingChapters,
  chapterUploadMessage: state.book.chapterUploadMessage
});

const mapDispatchToProps = dispatch => bindActionCreators({
  addChapters,
  createBookAndChapters,
  createNewVersion,
  fetchCurrentBook,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ChapterUploadModal)));
