import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

// Components
import {
  Label, Icon, Item
} from 'semantic-ui-react';

export class InvitationsSubMenuItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect();
  }

  render() {
    const { invitations, t } = this.props;

    return (
      <React.Fragment>
        <Item onClick={this.onClick}>
          <Item.Content verticalAlign='middle'>
            <Label circular color='red' key='invitations-label-nr'>
              {invitations.length}
            </Label>
            <span style={{ paddingLeft: '10px' }}>
              {t('Invitations')}
            </span>
            <span style={{ position: 'absolute', right: '10px' }}>
              <Icon name='chevron right' />
            </span>
          </Item.Content>
        </Item>
      </React.Fragment>
    );
  }
}

export default withTranslation()(InvitationsSubMenuItem);
