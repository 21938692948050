import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Label, Image, Header, Icon, Popup, Dropdown } from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { Media } from 'src/utils/Media';

import { useTranslation } from 'react-i18next';
import { updateSearchTerm } from '../../modules/betaOpportunities';
import CoverRater from './coverRater';
import { bookStatusUtil, imageUtil, uxAnalyticsUtil } from '../../utils';
import { useHistory } from 'react-router';
import { MediaQuery } from '../../constants/size';

const LARGE_WIDTH = 200;
const SMALL_WIDTH = 100;
const DESCRIPTION_MAX_CHARS_LARGE = 200;
const DESCRIPTION_MAX_CHARS_SMALL = 50;

const Wrapper = styled.div<{ large?: boolean }>`
  ${MediaQuery.tablet} {
    display: grid;
    ${({ large }) => css`
      grid-template-columns: minmax(0, ${large ? LARGE_WIDTH : SMALL_WIDTH}px) 1fr;
    `}
    padding: 0px 0px 10px 0px;
  }
`;

const RoundedImage = styled(Image)`
  border-radius: 10px;
`;

const CoverContainer = styled.div``;

const InfoContainer = styled.div`
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
`;

type Props = {
  book: any;
  openInReader?: boolean;
  descriptionMaxLength: number;
  showLeaveOption: boolean;
  large: boolean;
};

const BetaOpportunityItem: React.FC<Props> = ({
  book,
  openInReader,
  descriptionMaxLength,
  showLeaveOption,
  large,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [coverRaterButtonClicked, setCoverRaterButtonClicked] = useState(false);

  const descriptionMaxChars = useMemo(
    () => (large ? DESCRIPTION_MAX_CHARS_LARGE : DESCRIPTION_MAX_CHARS_SMALL),
    [large]
  );
  const descriptionText = useMemo(
    () =>
      `${book.description?.substr(0, descriptionMaxChars) ?? ''}${
        book.description?.length > descriptionMaxChars ? '...' : ''
      }`,
    [book.description, descriptionMaxChars]
  );

  const openBook = event => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(updateSearchTerm(''));
    if (openInReader) {
      history.push(`${process.env.PUBLIC_URL}/read?book=${book._id}`);
    } else {
      history.push(`${process.env.PUBLIC_URL}/books/${book._id}`);
    }
  };

  const selectGenre = (genre: string) => {
    uxAnalyticsUtil.trackEvent({
      category: 'Discover',
      action: `clicked-genre-${genre}`,
      label: 'sliderItem',
    });
    history.push(`/discover/genre/${genre}`);
  };

  const trackCoverRating = event => {
    if (isMobile) {
      return;
    }

    if (event.type === 'mouseenter') {
      uxAnalyticsUtil.trackEvent({
        category: 'Experiment',
        action: 'Displayed cover-rater',
        label: 'Cover-rater',
      });
      return;
    }
    if (event === 'like' || event === 'dislike') {
      setCoverRaterButtonClicked({ coverRaterButtonClicked: event });
      uxAnalyticsUtil.trackEvent({
        category: 'Experiment',
        action: 'Clicked rate cover',
        label: 'Cover-rater',
        value: event === 'like' ? 1 : 0,
      });
    }
  };

  let thumbnail =
    'https://storage.googleapis.com/beta-reader-prod.appspot.com/defaults/DEFAULT_COVER_BACKGROUND.png';
  if (book.cover !== undefined && book.cover.highRes !== undefined) {
    thumbnail = book.cover.highRes.url;
  }

  return (
    <Wrapper large={large}>
      <CoverContainer>
        <Popup
          on="hover"
          hoverable
          flowing
          disabled={
            thumbnail ===
            'https://storage.googleapis.com/beta-reader-prod.appspot.com/defaults/DEFAULT_COVER_BACKGROUND.png'
          }
          hideOnScroll
          onOpen={trackCoverRating}
          position="bottom center"
          trigger={
            <div>
              <RoundedImage
                onClick={openBook}
                className="cover-image"
                style={{ cursor: 'pointer' }}
                src={imageUtil.getImageUrl({
                  operation: `width=${large ? LARGE_WIDTH : SMALL_WIDTH}`,
                  originalImageUrl: thumbnail,
                })}
              />
              {book.pubDate && (
                <span style={{ fontSize: '0.8em' }}>
                  {t('PublicationDate')} {moment(book.pubDate).format('L')}
                </span>
              )}
            </div>
          }
        >
          <Popup.Header>{t('LikeThisCover')}</Popup.Header>
          <Popup.Content>
            <CoverRater
              coverRaterButtonClicked={coverRaterButtonClicked}
              clickedCoverRating={trackCoverRating}
            />
          </Popup.Content>
        </Popup>
      </CoverContainer>
      <InfoContainer>
        <Header
          as="h4"
          className="header-link br-text"
          onClick={openBook}
          style={{
            marginBottom: '0.4em',
            fontSize: '1.2em',
            cursor: 'pointer',
          }}
        >
          {book.title}
        </Header>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={{ color: 'grey', flex: 1 }}>
            {`${t('by')} ${book.authorName}`}{' '}
            {book.isPremium && (
              <Popup
                basic
                className="br-text"
                content={t('PremiumAuthorInfo')}
                trigger={<Icon link name="star" />}
              />
            )}
          </span>
          {showLeaveOption && (
            <Dropdown
              style={{ alignSelf: 'flex-end', margin: 'auto' }}
              icon="ellipsis vertical"
              direction="left"
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  text={t('LeaveBeta')}
                  onClick={() => history.push(`/exit-survey?book=${book._id}`)}
                />
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        <br />
        <div>
          <span
            style={{ fontSize: '0.9em', color: 'grey', marginRight: '10px' }}
          >
            <Icon name="font" color="grey" />
            <span style={{ fontSize: '0.8em' }}>
              {book.wordCount.toLocaleString()}
            </span>
          </span>
          <span
            style={{ fontSize: '0.9em', color: 'grey', marginRight: '10px' }}
          >
            <Icon name="list ul" color="grey" />
            <span style={{ fontSize: '0.8em' }}>
              {book.chapterCount.toLocaleString()}
            </span>
          </span>
          <span
            style={{ fontSize: '0.9em', color: 'grey', marginRight: '10px' }}
          >
            <Icon name="globe" color="grey" />
            <span style={{ fontSize: '0.8em' }}>
              {book.language &&
                book.language.charAt(0).toUpperCase() + book.language.slice(1)}
            </span>
          </span>
        </div>
        <div>
          {book.status && (
            <Popup
              content={t(`MSStatusDescription_${book.status}`)}
              trigger={
                <Label
                  size="mini"
                  color={bookStatusUtil.getStatusColor(book.status)}
                >
                  {book.status}
                </Label>
              }
            />
          )}
          {book.isMature && (
            <Label size="mini" color="red">
              {t('MatureContent')}
            </Label>
          )}
        </div>
        <div
          style={{
            color: 'grey',
            marginTop: '0.5em',
          }}
        >
          <Media greaterThan="mobile">
            {() => <p style={{ fontWeight: '100' }}>{descriptionText}</p>}
          </Media>
          <Media at="mobile">
            {(className, renderChildren) =>
              renderChildren && (
                <p
                  style={{
                    fontSize: '0.8em',
                  }}
                >
                  {descriptionText}
                </p>
              )
            }
          </Media>
        </div>
        <Header.Subheader style={{ marginTop: 'auto' }}>
          {book.genres &&
            book.genres.map((genre, index, array) => {
              if (index < 2) {
                return (
                  <Label
                    className="label-link"
                    onClick={() => selectGenre(genre)}
                    basic
                    size="tiny"
                    key={`${index + genre}`}
                    style={{ marginTop: '2px', cursor: 'pointer' }}
                    content={genre}
                  />
                );
              }
              if (array.length === index + 1 && array.length - 2 > 0) {
                return (
                  <Label
                    onClick={openBook}
                    basic
                    size="tiny"
                    color="grey"
                    key={`${index + genre}`}
                    style={{ marginTop: '2px', cursor: 'pointer' }}
                    content={`+${array.length - 2} ${t('LoadMore')}`}
                  />
                );
              }
              return null;
            })}
        </Header.Subheader>
      </InfoContainer>
    </Wrapper>
  );
};

export default BetaOpportunityItem;
