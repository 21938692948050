import socketIOClient from 'socket.io-client';

const socketIoReduxMiddleware = () => {
  let socket;
  let idToken;
  return storeAPI => next => (action) => {
    switch (action.type) {
      case 'user/USER_LOGGED_IN': {
        ({ idToken } = action);
        if (socket && typeof socket.close === 'function') { // close socket if open before connecting
          socket.close();
        }
        socket = socketIOClient(`${process.env.REACT_APP_API_HOST}/api/v2/conversations?token=${action.idToken}`);
        socket.on('newChatMessage', (message) => {
          storeAPI.dispatch({
            type: 'message/ON_RECEIVED_NEW_MESSAGE',
            message
          });
        });
        socket.on('onTypingNewChatMessage', (conversationData) => {
          storeAPI.dispatch({
            type: 'message/ON_RECEIVED_TYPING_NEW_MESSAGE',
            conversationData
          });
        });
        break;
      }
      case 'user/USER_LOGGED_OUT': {
        idToken = null;
        socket.close();
        break;
      }
      // when idToken is updated make sure to reconnect
      case 'user/UPDATE_ID_TOKEN': {
        if (action.idToken && action.idToken !== idToken) {
          if (socket && typeof socket.close === 'function') { // close socket if open before connecting
            socket.close();
          }
          socket = socketIOClient(`${process.env.REACT_APP_API_HOST}/api/v2/conversations?token=${action.idToken}`);
          socket.on('newChatMessage', (message) => { // when we receive a message we'll pass it on to redux store
            storeAPI.dispatch({
              type: 'message/ON_RECEIVED_NEW_MESSAGE',
              message
            });
          });
          socket.on('onTypingNewChatMessage', (conversationData) => { // when we reveive an event that someone starts/stops typing pass it along to redux
            storeAPI.dispatch({
              type: 'message/ON_RECEIVED_TYPING_NEW_MESSAGE',
              conversationData
            });
          });
        }
        break;
      }
      case 'message/SET_CURRENT_CONVERSATION': { // join the conversation
        if (action.conversation) {
          socket.emit('join', action.conversation._id);
        }
        break;
      }
      case 'message/ADD_CONVERSATION': { // send event of new chat message when creating a new conversation
        if (action.conversation && action.conversation._id) {
          socket.emit('newChatMessage', action.conversation.messages[0]);
        }
        break;
      }
      case 'message/ADD_MESSAGES_TO_CONVERSATION': { // send event with new message when replying to existing conversation
        if (action.conversationId && action.placement === 'start') {
          socket.emit('newChatMessage', action.messages[0]);
        }
        break;
      }
      case 'message/ON_TYPING_NEW_MESSAGE': { // send event when starting or stopping to type in conversation
        if (action.conversationData) {
          socket.emit('onTypingNewChatMessage', action.conversationData);
        }
        break;
      }
      default: return next(action);
    }
    return next(action);
  };
};

export default socketIoReduxMiddleware;
