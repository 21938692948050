import React, {Component} from 'react';
import '../../App.css';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {push} from 'connected-react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Menu, Icon} from 'semantic-ui-react';
import {loggedIn} from '../../modules/user';
import {toggleAppSidebar} from '../../modules/app';

import {appUtil} from '../../utils';
import BetaReaderLogoAndName from '../../components/svg/BetaReaderLogoAndName';

const Left = styled.div`
  text-align: left;
`;

export class AppMenu extends Component {
  unsubScribeHistoryListener;

  constructor(props) {
    super(props);
    this.state = {};
    this.unsubScribeHistoryListener = props.history.listen(() => {
      // close submenu if changing location in small screen mode
      if (appUtil.isSmallScreen()) {
        this.setState({
          showSubMenu: false
        });
      }
    });
  }

  componentWillUnmount() {
    this.unsubScribeHistoryListener();
  }

  handlePageClick = (e, {name}) => {
    // if we are on a small screen, close side bar after menu selection
    if (window && window.innerWidth && window.innerWidth < 768) {
      this.props.toggleAppSidebar(false);
    }
    this.props.changePage(`/${name}`);
  };

  openInNewWindow = url => {
    window.open(url, 'betareader.io_reader');
  };

  isOnPath = name => {
    const {location} = this.props;
    return location && location.pathname.startsWith(name);
  };

  showSubMenu = menu => {
    this.setState({
      showSubMenu: menu
    });
  };

  shouldShowSubMenu = menu => {
    const {showSubMenu} = this.state;
    const isActive = showSubMenu === menu;
    if (!isActive) {
      return this.isOnPath(`/${menu}`);
    }
    return isActive;
  };

  render() {
    const {userProfile, t} = this.props;
    return [
      <Menu.Item key='about' content={<BetaReaderLogoAndName />} />,
      userProfile && (
        <Menu.Item
          position='left'
          key='mymanuscripts'
          name='mymanuscripts'
          active={this.isOnPath('/mymanuscripts')}
          onClick={this.handlePageClick}
          content={
            <Left>
              <Icon name='book' /> {t('MyManuscripts')}
            </Left>
          }
        />
      ),
      userProfile && (
        <Menu.Item
          key='readingList'
          name='readinglist'
          active={this.isOnPath('/readinglist')}
          onClick={this.handlePageClick}
          content={
            <Left>
              <Icon name='bookmark' /> {t('ReadingList')}
            </Left>
          }
        />
      ),
      <Menu.Item
        key='discover'
        name='discover'
        active={this.isOnPath('/discover')}
        onClick={this.handlePageClick}
        content={
          <Left>
            <Icon name='search' /> {t('Discover')}
          </Left>
        }
      />,
      <Menu.Item
        key='feed'
        name='feed'
        active={this.isOnPath('/feed')}
        onClick={this.handlePageClick}
        content={
          <Left>
            <Icon name='feed' /> {t('Feed')}
          </Left>
        }
      />,
      userProfile && (
        <Menu.Item
          key='readers'
          name='readers'
          active={this.isOnPath('/readers')}
          onClick={this.handlePageClick}
          content={
            <Left>
              <Icon name='list ul' /> {t('ReaderList')}
            </Left>
          }
        />
      ),
      userProfile && (
        <Menu.Item
          key='inbox'
          name='inbox'
          active={this.isOnPath('/inbox')}
          onClick={this.handlePageClick}
          content={
            <Left>
              <Icon name='chat' /> {t('Chat')}
            </Left>
          }
        />
      )
    ];
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  currentBook: state.book.currentBook,
  alerts: state.app.alerts
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loggedIn,
      toggleAppSidebar,
      changePage: newPage => push(newPage)
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AppMenu))
);
