import i18n from 'i18next';
import lngDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translations from '../../shared/constants/translations';


// the translations
// (tip move them in a JSON file and import them)
const resources = translations;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(lngDetector)
  .init({
    resources,
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'en', // use en if detected lng is not available
    defaultNS: 'translations',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
