import supportedLanguages from './languages';

const languageOptions = [
  {
    key: 'de', text: 'Deutsch (beta)', value: 'de', dir: 'ltr'
  },
  {
    key: 'en', text: 'English', value: 'en', dir: 'ltr'
  },
  {
    key: 'es', text: 'Español (beta)', value: 'es', dir: 'ltr'
  },
  {
    key: 'pt', text: 'Portugues (beta)', value: 'pt', dir: 'ltr'
  },
  {
    key: 'fr', text: 'Français (beta)', value: 'fr', dir: 'ltr'
  },
  {
    key: 'sv', text: 'Svenska', value: 'sv', dir: 'ltr'
  },
  {
    key: 'da', text: 'Dansk (beta)', value: 'da', dir: 'ltr'
  },
  {
    key: 'nb', text: 'Norsk (beta)', value: 'nb', dir: 'ltr'
  },
  {
    key: 'fi', text: 'Suomea (beeta)', value: 'fi', dir: 'ltr'
  },
  {
    key: 'is', text: 'Íslensku (beta)', value: 'is', dir: 'ltr'
  }
];

const getAvailableLanguages = () => {
  const languagesWithCode = supportedLanguages.map((langage) => {
    const languageCode = 'alpha2';
    const newLanguageObject = {
      name: langage.English,
      code: langage[languageCode]
    };
    return newLanguageObject;
  });
  return languagesWithCode;
};

const getBrowserLanguage = () => {
  return navigator.language.split('-')[0];
}

const getTextDirection = (language) => {
  var languageOption = languageOptions.find(option => {
    return option.key === language;
  });
  var direction;
  if (languageOption) {
    direction = languageOption.dir;
  } else {
    direction = 'ltr';
  }
  return direction;
}

const getTextAlign = (language) => {
  const direction = getTextDirection(language);
  return direction === 'rtl' ? 'right' : 'left';
}

const getPrimaryPosition = (language) => {
  const direction = getTextDirection(language);
  return direction === 'rtl' ? 'right' : 'left';
}

const getSecondaryPosition = (language) => {
  const direction = getTextDirection(language);
  return direction === 'rtl' ? 'left' : 'right';
}

const language = {
  languageOptions,
  getBrowserLanguage,
  getAvailableLanguages,
  getTextDirection,
  getTextAlign,
  getPrimaryPosition,
  getSecondaryPosition
};
export default language;
