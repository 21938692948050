import React, { Component } from 'react';

import {
  Container,
  Header,
  Segment
} from 'semantic-ui-react';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

class PrivacyPolicy extends Component {
  render() {
    return (
      <div>
        <Segment style={{ padding: '0em 0em 3em' }} vertical>
          <Container text>
            <Header size='large' content='Privacy Policy' subheader='Last Updated September 3, 2017'/>
            The statement below outlines the BetaReader.io website ("BetaReader") Privacy Policy. BetaReader may update its Privacy Policy to reflect changes in the products and services that BetaReader offers, however, the underlying goal of protecting user privacy remains the same. By using BetaReader's service, each user consents to the collection, use or disclosure of their Personal Information in accordance with this Privacy Policy. Your continued use of the BetaReader service following the "last-revised" date on this Privacy Policy will represent your consent to the collection, use or disclosure of your Personal Information under this Privacy Policy as modified. You are advised to review this page regularly for any changes. If users have questions or concerns regarding this statement, or feel that BetaReader has not complied with the Policy, they should first contact info@betareader.io.
            <Header content='Storage and user of user information'/>
            BetaReader stores information as described below that could be used to identify users ("Personal Information") on the BetaReader servers for the sole purpose of providing the BetaReader service to users. BetaReader also stores certain non-personally identifiable information using log files.
            The following paragraph describes the Personal Information collected on BetaReader servers: email address, name, payment and usage records.
            <Header content='Communications and notifications'/>
            The email address of account holder is used for confirmation of a successful account creation. Account holders can opt out from the updates at any time by logging into their account to modify their update preference. Users also have the right to opt in or opt out of communications related to such services as, but not limited to, new application launches, promotions, BetaReader contests and events.
            <Header content='Service announcements'/>
            On rare occasions it is necessary to send out a strictly service related announcement. For instance, if the service is temporarily suspended for maintenance, BetaReader might send users an email. Generally, users may not opt-out of these communications, though they can deactivate their account. However, these communications are not promotional in nature.
            <Header content='Customer service'/>
            BetaReader communicates with users on a regular basis to provide requested services. In regards to issues relating to their account, BetaReader replies via email or chat, in accordance with the user's wishes.
            <Header content='Eligibility'/>
            BetaReader does not intentionally collect or use personal information about individuals under the age of 13. Consequently, use of this web and mobile internet site and all related services by children under the age of 13 is prohibited.
            <Header content='Cookies'/>
            A cookie is a piece of data stored on the users' computer or cellular phone tied to information about the user. BetaReader uses cookies. However, the user must opt-in to the usage of cookies by indicating that their login credentials be remembered when they login in subsequent sessions. BetaReader may also use cookies that are not linked to any Personal Information to store certain preferences about the user's use of the services. For example, cookies may be used to allow a user to set a preference to skip a verification screen when capturing information.
            <Header content='Web and mobile site activity'/>
            Like most standard Web and mobile internet sites, BetaReader stores and uses web server log files. These files include anonymous information about web browsers, such as internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, platform type, date/time stamp, and number of clicks to analyze trends, administer the site, track users' movement in the aggregate, and gather broad demographic information for aggregate use. Such log files are not linked to Personal Information through the BetaReader service.
            <Header content='Sharing of information'/>
            When a Reader views an Author’s work, BetaReader will collect usage and feedback data from the Reader and share with the Author as part of providing our services.
            <Header content='Links to other sites'/>
            This Web or mobile internet site contains links to other sites. Please be aware that BetaReader is not responsible for the privacy practices of such other sites. BetaReader encourages its users to be aware when they leave the BetaReader site and to read the privacy statements of each and every Web site that collects Personal Information. This privacy statement applies solely to information collected by the BetaReader web and internet mobile site.
            <Header content='Security'/>
            BetaReader employs security measures in order to prevent the loss, misuse, unauthorized access, theft and alteration of Personal Information under BetaReader's control. However, no transmission over the Internet can be guaranteed to be completely secure. Consequently, BetaReader, its affiliates, subsidiaries, service providers and their respective officers, directors, employees and agents do not represent, warrant or guarantee that Personal Information will be protected against misuse, loss or alterations and do not accept any liability for Personal Information submitted to or collected by them nor for your or third parties' use or misuse of Personal Information.
            <Header content='Correcting, updating, or deleting personal information'/>
            If a user's Personal Information changes (such as phone, email address or cellular phone number), the user may update this information on the BetaReader website or on their cellular phone. If a user no longer desires to use BetaReader's service, BetaReader provides a way to delete/deactivate users' Personal Information. Account deletion/deactivation can be done online at www.betareader.io
            <Header content='Notification of changes'/>
            Should BetaReader decide to change its privacy policy, changes to this privacy statement will be posted on the BetaReader web site.
            <Header content='Contact information'/>
            If users have any questions or suggestions regarding BetaReader's privacy policy, or wish to obtain information on how to access their personal information held by BetaReader, please contact BetaReader at: info@betareader.io
          </Container>
        </Segment>
      </div>
    )
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken
})

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: (newPage) => push(newPage)
}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivacyPolicy));
