import React, { Component } from 'react';
import {
  Checkbox, Header, Form, Button
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

class EmailPrefGetter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props.emailPreferences
    };
  }

  updateReceiveCompanyUpdates = (event, data) => {
    this.setState({
      receiveCompanyUpdates: data.checked
    });
  }

  updateReceiveBetaSuggestions = (event, data) => {
    this.setState({
      receiveBetaSuggestions: data.checked
    });
  }

  updateReceiveBookActivityUpdates = (event, data) => {
    this.setState({
      receiveBookActivityUpdates: data.checked
    });
  }

  updateReceiveMessageUpdates = (event, data) => {
    this.setState({
      receiveMessageUpdates: data.checked
    });
  }

  submit = () => {
    const {
      receiveCompanyUpdates,
      receiveBetaSuggestions,
      receiveBookActivityUpdates,
      receiveMessageUpdates
    } = this.state;
    if (this.props.onUpdate) {
      this.props.onUpdate({
        emailPreferences: {
          receiveCompanyUpdates: !!receiveCompanyUpdates,
          receiveBetaSuggestions: !!receiveBetaSuggestions,
          receiveBookActivityUpdates: !!receiveBookActivityUpdates,
          receiveMessageUpdates: !!receiveMessageUpdates
        }
      });
    }
  }

  // render
  render() {
    const { t, updating } = this.props;
    const {
      receiveCompanyUpdates,
      receiveBetaSuggestions,
      receiveBookActivityUpdates,
      receiveMessageUpdates
    } = this.state;
    return [
      <Header
        key='header'
        size='small'
        content={t('WhenDoYouWantToHearFromUs')}
      />,
      <Form key='input'>
        <Form.Field
          toggle
          label={t('ReceiveCompanyUpdates')}
          control={Checkbox}
          onChange={this.updateReceiveCompanyUpdates}
          checked={receiveCompanyUpdates}
        />
        <Form.Field
          toggle
          label={t('ReceiveBookEmails')}
          control={Checkbox}
          onChange={this.updateReceiveBetaSuggestions}
          checked={receiveBetaSuggestions}
        />
        <Form.Field
          toggle
          label={t('ReceiveBookActivityUpdates')}
          control={Checkbox}
          onChange={this.updateReceiveBookActivityUpdates}
          checked={receiveBookActivityUpdates}
        />
        <Form.Field
          toggle
          label={t('ReceiveMessageUpdates')}
          control={Checkbox}
          onChange={this.updateReceiveMessageUpdates}
          checked={receiveMessageUpdates}
        />
        <Button
          style={{ marginTop: '1em' }}
          key='next'
          floated='right'
          compact
          content={t('Next')}
          onClick={this.submit}
          loading={!!updating}
        />
      </Form>
    ];
  }
}

export default withTranslation()(EmailPrefGetter);
