export default [
  'Action and Adventure',
  'Anthology',
  'Art',
  'Autobiographies',
  'Biographies',
  'Body, Mind & Spirit',
  'Business',
  "Children's books",
  'Chick Lit',
  'Comedy',
  'Comics',
  'Coming of age',
  'Cookbooks',
  'Crime',
  'Depression & Mental Health',
  'Diaries',
  'Dictionaries',
  'Diet & Nutrition',
  'Diseases, Illnesses & Injuries',
  'Drug & Alchohol Abuse',
  'Drama',
  'Edu-Fiction',
  'Encyclopedias',
  'Erotica',
  'Fantasy',
  'Feel-good',
  'Feel-bad',
  'Fitness & Exercise',
  'Gothic',
  'Guide',
  'Halloween Scream',
  'Health',
  'Historical fiction',
  'History',
  'Horror',
  'Journals',
  'LGBTQ+',
  'Literary fiction',
  'LITRPG',
  'Math',
  'Memoir',
  'Middle Grade',
  'Mystery',
  'New Adult',
  'Paranormal',
  'Poetry',
  'Personal development',
  'Prayer books',
  'Religion',
  'Romance',
  'Satire',
  'Science',
  'Science fiction',
  'Self Esteem',
  'Self Help',
  'Series',
  'Sexuality & Pregnacy',
  'Speculative fiction',
  'Spirituality & New Age',
  'Supernatural',
  'Thriller',
  'Travel',
  'Trilogy',
  'Up market',
  'Valentine Crime 2022',
  "Women's Fiction",
  'Young Adult'
];
