import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  Grid, Form, Button
} from 'semantic-ui-react';
// i18n
import { withTranslation } from 'react-i18next';
import '../../../App.css';
import Question from './question/question';

import {
  saveSurvey,
  startNewSurvey,
  addQuestion,
  deleteSurveyQuestion,
  editCurrentSurveyQuestion,
  changeTitle,
  changeDescription,
  clearSurvey
} from '../../../modules/survey';

import {
  addPart
} from '../../../modules/book';


class EditSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      questions: []
    };
  }

  componentDidMount() {
  }

  saveSurvey = () => {
    const { idToken, currentBook, match, currentContentVersion } = this.props;
    const { title, description, questions } = this.state;
    const self = this;

    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${currentBook._id}/content/${currentContentVersion._id}/surveys`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      },
      body: JSON.stringify({
        title,
        description,
        questions
      })
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        }
        return res.json();
      })
      .then((survey) => {
        this.props.addPart(survey, currentContentVersion._id);
        const versionNumber = currentBook.content.findIndex(v => v._id === currentContentVersion._id) + 1;
        self.props.history.replace(`/books/${this.props.match.params.bookId}/content/v${versionNumber}/${survey._id}`);
      })
      .catch((err) => {
        console.error(err);
      })

  }

  toggleSurveyEnabled = () => {
    this.saveSurvey({enabled: !this.props.currentSurvey.enabled}, false);
  }

  activate = () => {
    this.setState({
      titleActive: true
    })
  }

  deactivate = () => {
    this.setState({
      titleActive: false
    })
  }

  addQuestion = () => {
    var updatedQuestionList = [...this.state.questions];
    updatedQuestionList.push({
      kind: 'LONG_TEXT',
      question: '',
      description: '',
    })
    this.setState({
      questions: updatedQuestionList
    })
  }

  updateQuestion = (questionData) => {
    var updatedQuestionList = [...this.state.questions];
    var questionIndex = questionData.index;
    if (questionIndex < 0 || questionIndex > updatedQuestionList.length) {
      return;
    } else {
      updatedQuestionList[questionIndex] = {
        ...updatedQuestionList[questionIndex],
        ...questionData
      }
    }
    this.setState({
      questions: updatedQuestionList
    })
  }

  handleChangeSurveyTitle = (event, data) => {
    this.setState({
      title: data.value
    })
  }

  handleChangeSurveyDescription = (event, data) => {
    this.setState({
      description: data.value
    });
  }

  deleteQuestion = (questionIndex) => {
    const { questions } = this.state;
    const updatedQuestionList = questions;
    if (updatedQuestionList[questionIndex]) {
      // if there is a question, remove it
      updatedQuestionList.splice(questionIndex, 1);
    }
    // update state
    this.setState({
      questions: updatedQuestionList
    });
  }

  render() {
    const { t } = this.props;
    const {
      description, questions
    } = this.state;
    if (questions && questions.length === 0) {
      questions.push({
        kind: 'LONG_TEXT',
        question: '',
        description: '',
      });
    }

    return (
      <div style={{maxWidth: 768}}>
        <Grid container columns={16}>
          <Grid.Row>
            <Grid.Column width={16}>
              <Form.Input
                className={`text-line`}
                transparent
                size='massive'
                fluid
                placeholder={t('EnterSurveyName')}
                value={this.state.title}
                onFocus={this.activate}
                onBlur={this.deactivate}
                onChange={this.handleChangeSurveyTitle}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Form.TextArea
                size='small'
                placeholder={t('EnterSurveyDescription')}
                value={description}
                onChange={this.handleChangeSurveyDescription}
              />
            </Grid.Column>
          </Grid.Row>
          {questions && questions.map((question, index) => {
            return (
              <Grid.Row key={`question_${index}`}>
                <Grid.Column width={16}>
                  <Question
                    key={question.id || index}
                    index={index}
                    question={question}
                    updateQuestion={this.updateQuestion}
                    deleteQuestion={() => this.deleteQuestion(index)}
                  />
                </Grid.Column>
              </Grid.Row>
            )
          })}
          <Grid.Row verticalAlign='middle' columns='equal'>
            <Grid.Column>
              <Button fluid basic onClick={this.addQuestion} content={'Add question'} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row verticalAlign='middle'>
            <Grid.Column width={16} verticalAlign='middle'>
              <Button onClick={this.saveSurvey}>
                {t('Save')}
              </Button>
            </Grid.Column>
          </Grid.Row>
          </Grid>
      </div>
    );
  }


}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  currentBook: state.book.currentBook,
  title: state.survey.currentSurvey.title,
  description: state.survey.currentSurvey.description,
  questions: state.survey.currentSurvey.questions,
  surveyEnabled: state.survey.currentSurvey.enabled,
  hasChanged: state.survey.hasChanged,
  currentSurvey: state.survey.currentSurvey,
  currentContentVersion: state.book.currentContentVersion
});

const mapDispatchToProps = dispatch => bindActionCreators({
  saveSurvey,
  startNewSurvey,
  addQuestion,
  deleteSurveyQuestion,
  editCurrentSurveyQuestion,
  changeTitle,
  changeDescription,
  addPart,
  clearSurvey,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSurvey)));
