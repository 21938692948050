import React, {useRef} from 'react';
import {useDispatch} from 'react-redux';
import {Grid, Icon, Header, Divider, Placeholder} from 'semantic-ui-react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import {Media} from 'src/utils/Media';
import BetaOpportunityItem from './betaOpportunityItem';
import {updateSearchTerm} from '../../modules/betaOpportunities';
import SliderItemPlaceholder from '../../components/placeholders/sliderItemPlaceholder';

const ContentSlider = ({books, header, genre, loading, large}) => {
  const carouselRef = useRef();
  const dispatch = useDispatch();

  const prevClick = () => {
    if (carouselRef.current) {
      carouselRef.current.slidePrev();
    }
  };

  const nextClick = () => {
    if (carouselRef.current) {
      carouselRef.current.slideNext();
    }
  };

  const selectGenre = genre => {
    dispatch(updateSearchTerm(genre));
  };

  const getGallery = () => {
    let betaOpportunities = [];
    if (loading) {
      // create placeholders if we are loading
      for (let i = 0; i < 15; i += 1) {
        betaOpportunities.push(<SliderItemPlaceholder />);
      }
    } else {
      // once we get the real opportunities we create them instead
      betaOpportunities =
        books &&
        books.map(book => (
          <BetaOpportunityItem
            large={large}
            key={book._id}
            book={book}
            descriptionMaxLength={230}
          />
        ));
    }
    const responsive = {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1024: {
        items: 2
      }
    };

    return (
      <div>
        <AliceCarousel
          startIndex={0}
          disableDotsControls
          disableButtonsControls
          infinite={loading ? false : books.length > 2}
          stagePadding={{paddingLeft: 0, paddingRight: 50}}
          items={betaOpportunities}
          mouseDragEnabled={false}
          responsive={responsive}
          ref={carouselRef}
        />
      </div>
    );
  };

  return (
    <Grid>
      <Grid.Row columns='equal' verticalAlign='middle'>
        <Media greaterThan='mobile'>
          {(className, renderChildren) =>
            renderChildren && (
              <Grid.Column width={1} textAlign='left'>
                <Icon
                  link
                  color='grey'
                  size='big'
                  onClick={prevClick}
                  name='chevron left'
                />
              </Grid.Column>
            )
          }
        </Media>
        <Grid.Column>
          {loading && (
            <Grid.Row columns={1} style={{height: '50px'}}>
              <Placeholder>
                <Placeholder.Paragraph>
                  <Placeholder.Line length='very short' />
                  <Placeholder.Line length='short' />
                </Placeholder.Paragraph>
              </Placeholder>
            </Grid.Row>
          )}
          {header && !loading && (
            <Grid.Row textAlign='left'>
              <Header
                className={genre ? 'header-link  br-text' : 'br-text'}
                onClick={genre ? () => selectGenre(genre) : null}>
                {header.title}
                <Header.Subheader className='br-text'>
                  {header.subheader}
                </Header.Subheader>
              </Header>
              <Divider hidden />
            </Grid.Row>
          )}
          <Grid.Row>{getGallery()}</Grid.Row>
        </Grid.Column>
        <Media greaterThan='mobile'>
          {(className, renderChildren) =>
            renderChildren && (
              <Grid.Column width={1} textAlign='right'>
                <Icon
                  link
                  color='grey'
                  size='big'
                  onClick={nextClick}
                  name='chevron right'
                />
              </Grid.Column>
            )
          }
        </Media>
      </Grid.Row>
    </Grid>
  );
};

export default ContentSlider;
