import { composeDecorators } from '@draft-js-plugins/editor';
import createImagePlugin from '@draft-js-plugins/image';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createFocusPlugin from '@draft-js-plugins/focus';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import '@draft-js-plugins/image/lib/plugin.css';
import '@draft-js-plugins/alignment/lib/plugin.css';
import '@draft-js-plugins/focus/lib/plugin.css';

// styles
import 'draft-js/dist/Draft.css';
import './textEditor.css';

// create plugins
export const focusPlugin = createFocusPlugin();
export const resizeablePlugin = createResizeablePlugin();
export const alignmentPlugin = createAlignmentPlugin();


const decorator = composeDecorators(
  focusPlugin.decorator,
  resizeablePlugin.decorator,
  alignmentPlugin.decorator,
);

export const { AlignmentTool } = alignmentPlugin;

export const imagePlugin = createImagePlugin({ decorator });

// export all plugins
export const plugins = [
  imagePlugin,
  focusPlugin,
  alignmentPlugin,
  resizeablePlugin,
];
