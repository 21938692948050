import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Placeholder, Header, Button, Divider, Icon, Dimmer, Loader
} from 'semantic-ui-react';

import moment from 'moment';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';

import CancelSubscriptionModal from './cancelSubscriptionModal';

import { getNextSubscriptionPayment } from '../../../modules/subscription';

import currencyUtil from '../../../utils/currencyUtil';

export class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { userProfile } = this.props;
    if (userProfile.account.subscription && userProfile.account.subscription.pricingPlan && userProfile.account.subscription.pricingPlan.externalId) {
      this.getNextPayment(userProfile.account.subscription._id);
    }
  }

  getNextPayment = async (subscriptionId) => {
    const { idToken } = this.props;
    this.props.getNextSubscriptionPayment(idToken, subscriptionId);
  }

  changePaymentInfo = () => {
    const { userProfile } = this.props;
    if (userProfile && userProfile.account.subscription && userProfile.account.subscription.updateUrl) {
      this.setState({
        loadingPaddleCheckout: true
      }, () => {
        window.Paddle.Checkout.open({
          override: userProfile.account.subscription.updateUrl,
          loadCallback: this.loadedPaddleCheckout
        });
      });
    }
  }

  loadedPaddleCheckout = () => {
    this.setState({
      loadingPaddleCheckout: false
    });
  }

  upgradeSubscription = () => {
    const { changePage } = this.props;
    changePage('/products');
  }

  showCancelModal = (show) => {
    this.setState({
      showCancelModal: show
    });
  }

  render() {
    const { loadingPaddleCheckout } = this.state;
    const { userProfile, nextPayment, t } = this.props;
    if (!userProfile || !userProfile.account.subscription) {
      return (
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line length='medium' />
            <Placeholder.Line length='short' />
          </Placeholder.Paragraph>
        </Placeholder>
      );
    }
    const { subscription } = userProfile.account;
    const { showCancelModal } = this.state;

    return (
      <div>
        <CancelSubscriptionModal open={showCancelModal} onClose={() => this.showCancelModal(false)} />
        {
          subscription.status !== 'deleted' && (
            <div>
              <Header as='h4'>
                { subscription.pricingPlan && t(subscription.pricingPlan.product.title) }
                { subscription.pricingPlan && subscription.pricingPlan.product.internalId !== 'free'
                && (
                  [
                    <Button
                      key='cancelSubscriptionButton'
                      compact
                      floated='right'
                      basic
                      negative
                      content={t('Cancel')}
                      onClick={() => this.showCancelModal(true)}
                    />,
                    <Header.Subheader key='cancelSubscriptionHeader'>
                      {
                        nextPayment && (
                          <div>
                            {`${t('NextPayment')}: ${nextPayment.amount}${currencyUtil.getSymbol(nextPayment.currency)} (${moment(nextPayment.payout_date).format('L')})` }
                            {
                              subscription.status === 'past_due'
                              && (
                                <div style={{ color: 'red' }}>
                                  <br />
                                  {t('PaymentPastDueMessage', { nextAttempt: new Date(subscription.nextRetryDate).toLocaleDateString() })}
                                </div>
                              )
                            }
                          </div>
                        )
                      }
                    </Header.Subheader>
                  ])
                }
              </Header>
              <Divider fitted />
              { subscription.pricingPlan && subscription.pricingPlan.product.internalId !== 'free'
              && (
                [
                  <Header as='h4' key='change-payment-information-header'>
                    {t('ChangePaymentInformation')}
                    <Button key='change-payment-information-button' compact floated='right' basic color='grey' content={t('Update')} onClick={this.changePaymentInfo} />
                    <Header.Subheader key='change-payment-information-subheader'>
                      <Icon name='credit card outline' />
                      <Icon name='cc paypal' style={{ color: '#3b7bbf' }} />
                    </Header.Subheader>
                  </Header>,
                  <Divider key='change-payment-information-divider' fitted />
                ]
              )
            }
            </div>
          )
        }
        {
          subscription.status === 'deleted'
          && (
            <Header as='h4'>
              { subscription.pricingPlan && t(subscription.pricingPlan.product.title) }
              <Header.Subheader>
                {t('YourSubscriptionHasBeenCancelled')}
                <br />
                {t('ValidUntil')}
                {' '}
                {moment(subscription.validUntilDate).format('L')}
              </Header.Subheader>
            </Header>
          )
        }
        <Header as='h4'>
          {t('UpgradeSubscriptionTitle')}
          <Button compact floated='right' basic positive content={t('Subscriptions')} onClick={this.upgradeSubscription} />
          <Header.Subheader>
            {t('UpgradeSubscriptionPromoText')}
          </Header.Subheader>
        </Header>
        {
          loadingPaddleCheckout && (
            <Dimmer active inverted page>
              <Loader>{t('Loading')}</Loader>
            </Dimmer>
          )
        }
      </div>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  nextPayment: state.subscription.nextPayment
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getNextSubscriptionPayment,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Subscription)));
