import React, { Component } from 'react';

import moment from 'moment';

// UI components
import {
  Dimmer, Loader, Grid, Button, Segment
} from 'semantic-ui-react';

// routing
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';

// i18n
import { withTranslation } from 'react-i18next';

// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  fetchCurrentConversation,
  replyToConversation,
  clearCurrentConversation,
  fetchEarlierMessages,
  markMessageAsSeen
} from '../../modules/message';
import { authUtil } from '../../utils';
import Message from './message';
import Avatar from '../../components/avatar/avatar';

const typingAnimation = (
  <React.Fragment>
    <span className='typing-dot' />
    <span className='typing-dot' />
    <span className='typing-dot' />
  </React.Fragment>
);

class Conversation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { conversationId } = this.props.match.params;

    if (conversationId !== undefined) {
      this.fetchConversation(conversationId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      currentConversation,
      loadingConversation,
      fetchedEerlierMessenges,
      conversations,
      history
    } = this.props;
    const { conversationId } = this.props.match.params;
    const objDiv = document.getElementById('message-list-scrollable');
    // don't scroll to end if we pressed fetchedEerlierMessenges button
    if (!fetchedEerlierMessenges && objDiv && objDiv.scrollHeight) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
    if (conversationId && conversationId !== prevProps.match.params.conversationId && !loadingConversation) {
      this.fetchConversation(conversationId);
    }
    // only replace when previous type was support
    if (currentConversation && currentConversation._id && ((conversationId === 'support') || (conversationId === 'new')) &&
   prevProps.currentConversation && prevProps.currentConversation.type && prevProps.currentConversation.type === 'support') {
      history.replace(`/inbox/conversations/${currentConversation._id}`);
    }
    if (!prevProps.conversationId && !conversationId && conversations && conversations.length > 0) {
      this.getLatestConversation();
    }
  }

  componentWillUnmount() {
    this.props.clearCurrentConversation();
  }

  updateCurrentConversation = async () => {
    const idToken = await authUtil.getFreshIdToken();
    if (this._getCurrentConversationId() && idToken) {
      this.props.fetchCurrentConversation(idToken, this._getCurrentConversationId());
    }
  }

  _getCurrentConversationId = () => {
    const { match } = this.props;
    const currentConversationId = match.params.conversationId;
    return currentConversationId;
  }

  fetchConversation = async (conversationId) => {
    const { onTypingNewMessage } = this.state;
    const idToken = await authUtil.getFreshIdToken();
    this.props.clearCurrentConversation();
    if (conversationId && idToken) {
      this.props.fetchCurrentConversation(idToken, conversationId);
    }
  }

  getLatestConversation = () => {
    const { conversations } = this.props;
    if (conversations && conversations.length > 0) {
      this.props.changePage(`/inbox/conversations/${conversations[0]._id}`);
    }
  }

  loadEarlierMessages = async (data) => {
    const { currentConversation } = this.props;
    const idToken = await authUtil.getFreshIdToken();
    this.props.fetchEarlierMessages(idToken, currentConversation._id, currentConversation.messages.length)
  }

  getDateHeader = (currentDate, previousDate, index) => {
    const newDate = moment(new Date(currentDate)).format('MMM D, YYYY').toUpperCase();
    if (index === 0) {
      return newDate;
    }
    const date = moment(new Date(currentDate)).format('YYYY-MM-DD');
    const prevDate = moment(new Date(previousDate)).format('YYYY-MM-DD');
    if (moment(prevDate).isBefore(moment(date))) {
      return newDate;
    }
    return null;
  }

  markMessageAsSeen = async (conversationId, messageId) => {
    if (conversationId !== 'support') {
      const idToken = await authUtil.getFreshIdToken();
      this.props.markMessageAsSeen({ idToken, conversationId, messageId });
    }
  }

  render() {
    const {
      currentConversation, t, user, loadingConversation, loadingEarlierMessenges
    } = this.props;
    let sortedMessages = [];
    // sort messages on createdAt
    if (!currentConversation) {
      return null;
    }

    if (currentConversation && currentConversation.messages) {
      sortedMessages = currentConversation.messages.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateA - dateB;
      });
    }
    if (!currentConversation && loadingConversation) {
      return (
        <Dimmer active inverted>
          <Loader>{t('Loading')}</Loader>
        </Dimmer>
      );
    }
    if (currentConversation && sortedMessages.length > 0) {
      return (
        <Grid columns={1}>
          <Grid.Column>
            {
              currentConversation
              && currentConversation.totalMessageCount > 20
              && currentConversation.messages
              && currentConversation.messages.length < currentConversation.totalMessageCount && (
              <Segment
                basic
                textAlign='center'
              >
                <Button
                  circular
                  basic
                  content={t('LoadMore')}
                  onClick={this.loadEarlierMessages}
                  loading={loadingEarlierMessenges}
                />
              </Segment>
            )}
            {
              sortedMessages.map((message, index, array) => ([
                <div key={message.createdAt} className='chat-message-date-header'>
                  <span>
                    {this.getDateHeader(message.createdAt, array[index > 0 ? index - 1 : 0].createdAt, index)}
                  </span>
                </div>,
                <Message
                  key={message._id}
                  text={message.text}
                  currentUserId={user.uid}
                  isCurrentUser={user.uid === (message.from && message.from._id)}
                  createdAt={message.createdAt}
                  seenByUsers={message.seenByUsers}
                  seenBy={message.seenBy}
                  from={message.from}
                  user={{
                    id: message.from && message.from._id,
                    name: message.from && message.from.displayName
                  }}
                  // if this is the last message, mark the conversation as read
                  onSeen={() => this.markMessageAsSeen(message.conversation, message._id)}
                />
              ]
              ))
            }
            {
              currentConversation?.onTypingNewMessage?.isTyping && (
                <div className='chat-message'>
                  <div className='chat-message-box'>
                    <Grid columns='equal'>
                      <Grid.Row>
                        <Grid.Column width={1}>
                          <Avatar name={currentConversation?.onTypingNewMessage.displayName} size={20} />
                        </Grid.Column>
                        <Grid.Column>
                          <Grid.Row style={{ float: 'left' }}>
                            <div className='chat-message-content'>
                              <span className='chat-message-content-text'>
                                <div id='typing-indicator'>
                                  {typingAnimation}
                                </div>
                              </span>
                            </div>
                          </Grid.Row>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                </div>
              )
            }
          </Grid.Column>
        </Grid>
      );
    }
    return null;
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  userProfile: state.user.userProfile,
  currentConversation: state.message.currentConversation,
  conversations: state.message.conversations,
  sendingMessage: state.message.sendingMessage,
  loadingConversation: state.message.loadingConversation,
  fetchedEerlierMessenges: state.message.fetchedEerlierMessenges,
  loadingEarlierMessenges: state.message.loadingEarlierMessenges
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchCurrentConversation,
  replyToConversation,
  clearCurrentConversation,
  fetchEarlierMessages,
  markMessageAsSeen,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Conversation)));
