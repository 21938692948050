import React from 'react';
import {Grid} from 'semantic-ui-react';
import {uxAnalyticsUtil} from '../../utils';
import {useDispatch} from 'react-redux';
import useOutsideClick from '../../hooks/useOutsideClick';
import {
  UPDATE_SEARCH_TERM,
  UPDATE_OPPORTUNITIES_LIST
} from '../../modules/betaOpportunities';
import {useHistory} from 'react-router';

const GenreGrid = ({genres, onClose}) => {
  const gridRef = React.useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  useOutsideClick(gridRef, () => {
    if (onClose) {
      onClose();
    }
  });

  const selectedDimension = React.useCallback(genre => {
    uxAnalyticsUtil.trackEvent({
      category: 'Discover',
      action: `clicked-genre-${genre}`,
      label: 'browse'
    });
    // close grid
    if (onClose) {
      onClose();
    }

    // clear search
    dispatch({
      type: UPDATE_SEARCH_TERM,
      searchTerm: ''
    });
    dispatch({
      type: UPDATE_OPPORTUNITIES_LIST,
      books: []
    });

    // go to selected genre
    history.push(`/discover/genre/${genre}`);
  }, []);

  return (
    <div ref={gridRef}>
      <Grid columns={5} stackable>
        <Grid.Column textAlign='left'>
          {genres.map((genre, index) => {
            if (index < 15) {
              return (
                <p
                  onClick={() => selectedDimension(genre)}
                  key={`${index + genre}`}
                  className='label-link'>
                  {genre}
                </p>
              );
            }
          })}
        </Grid.Column>
        <Grid.Column textAlign='left'>
          {genres.map((genre, index) => {
            if (index >= 15 && index < 30) {
              return (
                <p
                  onClick={() => selectedDimension(genre)}
                  key={`${index + genre}`}
                  className='label-link'>
                  {genre}
                </p>
              );
            }
          })}
        </Grid.Column>
        <Grid.Column textAlign='left'>
          {genres.map((genre, index) => {
            if (index >= 30 && index < 45) {
              return (
                <p
                  onClick={() => selectedDimension(genre)}
                  key={`${index + genre}`}
                  className='label-link'>
                  {genre}
                </p>
              );
            }
          })}
        </Grid.Column>
        <Grid.Column textAlign='left'>
          {genres.map((genre, index) => {
            if (index >= 45 && index < 60) {
              return (
                <p
                  onClick={() => selectedDimension(genre)}
                  key={`${index + genre}`}
                  className='label-link'>
                  {genre}
                </p>
              );
            }
          })}
        </Grid.Column>
        <Grid.Column textAlign='left'>
          {genres.map((genre, index) => {
            if (index >= 60) {
              return (
                <p
                  onClick={() => selectedDimension(genre)}
                  key={`${index + genre}`}
                  className='label-link'>
                  {genre}
                </p>
              );
            }
          })}
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default GenreGrid;
