import React, {useEffect} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import ReaderList from './readerList';
import Reader from './reader';
import {resetReaders} from '../../modules/reader';

const Readers = () => {
  const dispatch = useDispatch();
  const router = useRouteMatch();

  useEffect(() => {
    return () => {
      dispatch(resetReaders());
    };
  }, []);

  return (
    <div>
      <Switch>
        <Route exact path={`${router.path}/:readerId`} component={Reader} />
        <Route exact path={`${router.path}`} component={ReaderList} />
      </Switch>
    </div>
  );
};

export default Readers;
