import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Media } from 'src/utils/Media';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory, useLocation, useParams } from 'react-router-dom';

import queryString from 'query-string';

import {
  Icon,
  Button,
  Grid,
  Header,
  Label,
  Modal,
  Message,
  Popup,
  Form,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { authUtil } from '../../../utils';
import { updateJoinBetaRequest } from '../../../modules/book';

import JoinBetaForm from '../../discover/joinBetaForm';
import CurrentBetaIsFullMessage from '../../../components/standard-messages/currentBetaIsFull';
import { PaddedWrapper } from '../../../style';

type Props = {
  toggleFollowBook: (action: any) => Promise<void>;
};
const ViewBookDetails: React.FC<Props> = ({ toggleFollowBook }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const user = useSelector(state => state.user.user);
  const userProfile = useSelector(state => state.user.userProfile);
  const currentBook = useSelector(state => state.book.currentBook);

  const [token, setToken] = useState<string | undefined>();

  useEffect(() => {
    const checkQuery = () => {
      const parsedQueryString = queryString.parse(location.search);
      const { show: parsedShow, token: parsedToken } = parsedQueryString;
      setToken(parsedToken);
    };
    checkQuery();
  }, [location]);

  const goToSwapRequest = () => {
    if (currentBook.swap) {
      history.push(`/books/${currentBook.swap._id}/readers`);
    }
  };

  const doShowJoinBetaForm = () => {
    if (!user) {
      history.push(
        `/signin?redirect=${encodeURIComponent(
          location.pathname + location.search
        )}`
      );
    } else {
      const queryStringObject = queryString.parse(location.search);
      queryStringObject.show = 'join';
      history.push(
        `${location.pathname}?${queryString.stringify(queryStringObject)}`
      );
    }
  };

  const closeJoinBetaForm = () => {
    const queryStringObject = queryString.parse(location.search);
    queryStringObject.show = '';

    history.push(
      `${location.pathname}?${queryString.stringify(queryStringObject)}`
    );
  };

  const goToBook = () => {
    const bookURL = `/read?book=${params.bookId}`;
    history.push(bookURL);
  };

  const declineInvitation = async () => {
    const idToken = await authUtil.getFreshIdToken();
    const options = {
      status: 'DECLINED',
      token,
    };
    if (!user) {
      window.localStorage.setItem('br-invitation-declined', token as string);
      goToSignIn();
    } else {
      dispatch(
        updateJoinBetaRequest(idToken, params.bookId, options, succeeded => {
          console.log('succeeded', succeeded);
        })
      );
    }
  };

  const acceptInvitationAndGoToBook = async () => {
    const idToken = await authUtil.getFreshIdToken();
    const options = {
      status: 'ACCEPTED',
      token,
    };
    if (!user) {
      window.localStorage.setItem('br-invitation-accepted', token);
      goToSignIn();
    } else {
      dispatch(
        updateJoinBetaRequest(idToken, params.bookId, options, succeeded => {
          if (succeeded) {
            setTimeout(() => {
              toggleFollowBook('follow');
              goToBook();
            }, 1000);
          }
        })
      );
    }
  };

  const goToSignUp = () => {
    history.push(
      `/signin?redirect=${encodeURIComponent(
        location.pathname + location.search
      )}`
    );
  };

  const goToSignIn = () => {
    history.push(
      `/signin?redirect=${encodeURIComponent(
        location.pathname + location.search
      )}`
    );
  };

  const goToDiscover = () => {
    history.push('/discover');
  };

  const selectGenre = genre => {
    dispatch(updateSearchTerm(''));
    history.push(`/discover/genre/${genre}`);
  };

  const isAuthor = () => {
    const isAuthor =
      userProfile &&
      currentBook.author &&
      currentBook.author._id === userProfile._id;
    return isAuthor;
  };

  // rendering
  const queryStringObject = queryString.parse(location.search);
  const { show } = queryStringObject;

  if (!currentBook) {
    return null;
  }

  const invitationPending =
    currentBook.reader &&
    ['SENT', 'CREATED', 'DECLINED'].includes(
      currentBook.reader.invitation.status
    );

  let joinButtonText = 'JoinBeta';
  let onClickFn = doShowJoinBetaForm;
  if (currentBook.swap) {
    joinButtonText = 'GoToSwapRequest';
    onClickFn = goToSwapRequest;
  }
  if (currentBook.reader) {
    switch (currentBook.reader.invitation.status) {
      case 'CREATED':
      case 'SENT':
      case 'DECLINED':
        joinButtonText = 'Accept';
        if (
          !currentBook.readerSignupSurvey ||
          currentBook.readerSignupSurvey.questions.length === 0
        ) {
          onClickFn = acceptInvitationAndGoToBook;
        }
        break;
      case 'ACCEPTED':
        joinButtonText = 'Read';
        onClickFn = goToBook;
        break;
      case 'APPROVED':
        joinButtonText = 'Read';
        onClickFn = acceptInvitationAndGoToBook;
        break;
      case 'REQUESTED':
        joinButtonText = 'Requested';
        break;
      case 'ABANDONED':
        joinButtonText = 'Abandoned';
        break;
      default:
    }
  }
  const declineButton = (
    <Button
      disabled={
        currentBook.reader &&
        currentBook.reader.invitation.status === 'DECLINED'
      }
      key="declineInvitationButtonKey"
      onClick={declineInvitation}
      content={t('Decline')}
    />
  );

  const joinButton = (
    <Button
      key="joinButtonKey"
      id="joinbutton"
      disabled={
        (currentBook.reader &&
          currentBook.reader.invitation.status === 'ABANDONED') ||
        (currentBook.currentBetaIsFull && currentBook.reader)
      }
      className="primary-br-button"
      onClick={onClickFn}
      color="orange"
      content={t(joinButtonText)}
    />
  );

  return (
    <PaddedWrapper>
      <Form>
        <Form.Field>
          <Header className="br-text">
            {currentBook.title}
            <Header.Subheader className="br-text">
              {`${t('by')} ${currentBook.authorName}`}
              {currentBook.isPremium && (
                <Popup
                  basic
                  className="br-text"
                  content={t('PremiumAuthorInfo')}
                  trigger={
                    <span>
                      {' '}
                      <Icon link name="star" />
                    </span>
                  }
                />
              )}
              {currentBook.pubDate && (
                <div style={{ float: 'right', display: 'inline-block' }}>
                  <span>
                    {t('PublicationDate')}{' '}
                    {moment(currentBook.pubDate).format('L')}
                  </span>
                </div>
              )}
            </Header.Subheader>
          </Header>
        </Form.Field>
        <Media greaterThanOrEqual="tablet">
          {(className, renderChildren) =>
            renderChildren && (
              <Form.Field>
                <Button.Group key="joinAndDeclineButtonKey" compact>
                  {invitationPending && declineButton}
                  {joinButton}
                </Button.Group>
              </Form.Field>
            )
          }
        </Media>
        {currentBook.swap &&
          currentBook.swap.invitation &&
          currentBook.swap.invitation.status &&
          ['REQUESTED', 'APPROVED', 'ACCEPTED'].includes(
            currentBook.swap.invitation.status
          ) && (
            <Form.Field>
              <Message info>
                <em style={{ fontSize: '0.8em', opacity: 0.6 }}>
                  {t('AuthorXHasProposedToSwapThisForY', {
                    x: currentBook.authorName,
                    y: currentBook.swap.title,
                  })}
                </em>
              </Message>
              {currentBook.currentBetaIsFull && (
                <CurrentBetaIsFullMessage key="CurrentBetaIsFullMessage" />
              )}
            </Form.Field>
          )}
        {currentBook.reader &&
          currentBook.reader.invitation.status === 'REQUESTED' && (
            <Form.Field>
              <Message info>
                {currentBook.linkedJoinRequest
                  ? t('RequestedWithDifferentEmail')
                  : t('AnsweredOrRequestedNotice')}
              </Message>
            </Form.Field>
          )}
        {invitationPending &&
          !currentBook.swap &&
          currentBook.reader.invitation.status !== 'DECLINED' && (
            <Form.Field>
              <Message floating info>
                {t('YouHaveAPendingInvitation')}
              </Message>
              {currentBook.currentBetaIsFull && (
                <CurrentBetaIsFullMessage key="CurrentBetaIsFullMessageInvitation" />
              )}
            </Form.Field>
          )}
        {invitationPending &&
          !currentBook.swap &&
          currentBook.reader.invitation.status === 'DECLINED' && (
            <Form.Field>
              <Message floating info>
                {t('DeclinedInvitationMessage')}
              </Message>
              {currentBook.currentBetaIsFull && (
                <CurrentBetaIsFullMessage key="CurrentBetaIsFullMessageInvitation" />
              )}
            </Form.Field>
          )}
        <Form.Field>
          {currentBook.genres &&
            currentBook.genres.map((genre, index) =>
              index >= 0 ? (
                <Label
                  className="label-link"
                  onClick={() => selectGenre(genre)}
                  color="grey"
                  size="tiny"
                  key={`${index + genre}`}
                  style={{ marginTop: '2px', cursor: 'pointer' }}
                  content={genre}
                />
              ) : null
            )}
        </Form.Field>
        <Form.Field>
          <Header sub className="br-text">
            {t('Description')}
          </Header>
          <div
            id="description"
            className="br-text"
            dangerouslySetInnerHTML={{
              __html: currentBook.description
                ? currentBook.description.replace(/\n/g, '<br />')
                : t('OpportunityWithoutDescriptionMessage'),
            }}
          />
        </Form.Field>
        <Form.Field>
          <Header sub className="br-text">
            {t('FeedbackGuidelines')}
          </Header>
          <div
            id="feedback-guidelines"
            className="br-text"
            dangerouslySetInnerHTML={{
              __html: currentBook.feedbackGuidelines
                ? currentBook.feedbackGuidelines.replace(/\n/g, '<br />')
                : t('NoGuidelines'),
            }}
          />
        </Form.Field>
        <Form.Field>
          <Header sub className="br-text">
            {t('CopyrightInfo')}
          </Header>
          {currentBook.copyRightInfo && (
            <div
              id="copyright"
              className="br-text"
              dangerouslySetInnerHTML={{
                __html: currentBook.copyRightInfo.replace(/\n/g, '<br />'),
              }}
            />
          )}
        </Form.Field>
      </Form>
      <Media at="mobile">
        {(className, renderChildren) =>
          renderChildren && (
            <div
              style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100vw',
                padding: 5,
              }}
            >
              <Button.Group fluid size="big">
                {invitationPending && declineButton}
                {joinButton}
              </Button.Group>
            </div>
          )
        }
      </Media>
      <Media at="mobile">
        {(className, renderChildren) =>
          renderChildren && <div style={{ height: '3em' }}></div>
        }
      </Media>
      <Modal
        open={show === 'join'}
        closeIcon
        onClose={closeJoinBetaForm}
        style={{ color: 'black' }}
      >
        <Modal.Content className="color-mode-bright">
          <Grid>
            <Grid.Row verticalAlign="middle">
              <Grid.Column width={16}>
                <JoinBetaForm
                  onClose={closeJoinBetaForm}
                  onJoin={() => toggleFollowBook('follow')}
                  onWithdraw={() => toggleFollowBook('unfollow')}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    </PaddedWrapper>
  );
};

export default ViewBookDetails;
