import React, { Component } from 'react';
import { RichUtils } from 'draft-js';
import { Button, List, Icon } from 'semantic-ui-react';
import ReactionsComponent from './reactionsComponent';

export class ReactionsButtons extends Component {
  onMouseDown = (event) => {
    event.preventDefault();
  };

  render() {
    return (
      <ReactionsComponent {...this.props} />
    );
  }
}

export class BoldButton extends Component {
  onClick = (event) => {
    event.preventDefault();
    const newEditorState = RichUtils.toggleInlineStyle(this.props.getEditorState(), 'BOLD');
    this.props.setEditorState(newEditorState);
  }

  onMouseDown = event => event.preventDefault();

  render() {
    return (
      <Button
        icon='bold'
        onMouseDown={this.onMouseDown}
        onClick={this.onClick}
      />
    );
  }
}

export class UnderlineButton extends Component {
  onClick = (event) => {
    event.preventDefault();
    const newEditorState = RichUtils.toggleInlineStyle(this.props.getEditorState(), 'UNDERLINE');
    this.props.setEditorState(newEditorState);
  }

  onMouseDown = event => event.preventDefault();

  render() {
    return (
      <Button
        icon='underline'
        onMouseDown={this.onMouseDown}
        onClick={this.onClick}
      />)
  }
}

export class ItalicButton extends Component {
  onClick = (event) => {
    event.preventDefault();
    const newEditorState = RichUtils.toggleInlineStyle(this.props.getEditorState(), 'ITALIC');
    this.props.setEditorState(newEditorState);
  }

  onMouseDown = (event) => {
    event.preventDefault();
  }

  render() {
    return (
      <Button
        icon='italic'
        onMouseDown={this.onMouseDown}
        onClick={this.onClick}
      />
    );
  }
}

export class HeaderTwoButton extends Component {
  onClick = (event) => {
    event.preventDefault();
    const newEditorState = RichUtils.toggleBlockType(this.props.getEditorState(), 'header-two');
    this.props.setEditorState(newEditorState);
  }

  onMouseDown = event => event.preventDefault();

  render() {
    return (
      <Button icon onClick={this.onClick} onMouseDown={this.onMouseDown}>
        <span style={{ fontSize: '1em' }}>H</span>
      </Button>
    );
  }
}

export class HeaderThreeButton extends Component {
  onClick = (event) => {
    event.preventDefault();
    const newEditorState = RichUtils.toggleBlockType(this.props.getEditorState(), 'header-three');
    this.props.setEditorState(newEditorState);
  }

  onMouseDown = event => event.preventDefault();

  render() {
    return (
      <Button icon onClick={this.onClick} onMouseDown={this.onMouseDown}>
        <span style={{ fontSize: '0.8em' }}>H</span>
      </Button>
    );
  }
}

export class SplitChapterButton extends Component {
  onMouseDown = event => event.preventDefault()

  onClick = () =>
    this.props.onSplit();

  render() {
    return (
      <Button icon onClick={this.onClick} onMouseDown={this.onMouseDown}>
        <Icon.Group>
          <Icon name='file alternate outline' />
          <Icon
            name='cut'
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              fontSize: '0.8em'
            }}
          />
        </Icon.Group>
      </Button>
    );
  }
}
