import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';

class CoverRater extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  clickedCoverRating = (value) => {
    this.props.clickedCoverRating(value);
  }

  render() {
    const { coverRaterButtonClicked } = this.props;
    return (
      <div style={{ textAlign: 'center' }}>
        <Icon
          onClick={() => this.clickedCoverRating('dislike')}
          style={{ cursor: 'pointer' }}
          circular
          bordered
          disabled={coverRaterButtonClicked === 'like'}
          color='red'
          name='close'
        />
        <Icon
          onClick={() => this.clickedCoverRating('like')}
          style={{ cursor: 'pointer' }}
          circular
          bordered
          disabled={coverRaterButtonClicked === 'dislike'}
          color='green'
          name='heart'
        />
      </div>
    );
  }
}

export default CoverRater;
