import React, {Component} from 'react';
import {push} from 'connected-react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Media} from 'src/utils/Media';

import {withRouter, Link} from 'react-router-dom';
import ReactGA from 'react-ga';
import {Button, Icon, Menu, Dropdown, Responsive} from 'semantic-ui-react';
import {withTranslation} from 'react-i18next';

import {updateSpineIndex} from '../../../../modules/readerApp';
import {submitSurveyAnswers} from '../../../../modules/survey';

import language from '../../../../config/language';

// tracking
import {COMPLETED_BOOK, track} from '../../../../utils/tracker';
import {getFreshIdToken} from '../../../../utils/authUtil';

class ChapterMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentItemPercentagePassed: undefined
    };
  }

  moveBack = event => {
    event.preventDefault();
    const {currentSpineIndex, updateSpineIndex} = this.props;
    if (currentSpineIndex <= 0) {
      // at beginning of book, do nothing
      return;
    }
    updateSpineIndex(currentSpineIndex - 1);
    const main = document.getElementById('main');
    ReactGA.event({
      category: 'Reading',
      action: 'Moved back',
      value: currentSpineIndex - 1
    });
    document.getElementById('main').scrollTop = 0;
  };

  moveForward = event => {
    event.preventDefault();
    event.stopPropagation();
    const {currentBook, currentSpineIndex, updateSpineIndex} = this.props;
    if (currentSpineIndex >= currentBook.parts.length - 1) {
      // at end of book, do nothing
      return;
    }
    updateSpineIndex(currentSpineIndex + 1);
    ReactGA.event({
      category: 'Reading',
      action: 'Moved forward',
      value: currentSpineIndex + 1
    });
    document.getElementById('main').scrollTop = 0;
  };

  completeBook = event => {
    event.preventDefault();
    event.stopPropagation();
    const {user, currentBook, history} = this.props;
    track({
      book: currentBook._id,
      data: [
        {
          event: {id: COMPLETED_BOOK},
          user: {id: user.uid},
          book: {id: currentBook._id}
        }
      ]
    });
    ReactGA.event({
      category: 'Reading',
      action: 'Completed book'
    });
    // go to book review page if enabled by Author and user is not the Author
    if (currentBook.enableBookReviews && currentBook.author._id !== user.uid) {
      history.push(`/review?book=${currentBook._id}`);
    } else {
      history.push('/');
    }
  };

  submitAndMoveForward = async event => {
    event.preventDefault();
    event.stopPropagation();
    const idToken = await getFreshIdToken();

    const {currentBook, currentSpineIndex, submitSurveyAnswers, answers} =
      this.props;
    const surveyId = currentBook.parts[currentSpineIndex]._id;
    submitSurveyAnswers(
      idToken,
      {
        bookId: currentBook._id,
        surveyId,
        answers: answers[surveyId]
      },
      this.moveForward
    );
  };

  render() {
    const {
      t,
      currentSpineIndex,
      currentBook,
      userProfile,
      defaultColorMode,
      standAlone,
      currentLanguage
    } = this.props;
    const currentItem = currentBook.parts[currentSpineIndex];
    const currentColorMode =
      userProfile !== undefined
        ? userProfile.readerSettings.colorMode
        : defaultColorMode;
    let goToNextDisabled = true;

    if (currentItem.kind === 'Chapter') {
      goToNextDisabled = false;
    } else if (currentItem.kind === 'Survey') {
      const {answers} = this.props;
      const surveyAnswers = answers[currentItem._id] || [];
      let respondedToAllRequiredQuestions = true;
      currentItem.questions.forEach(question => {
        let answer = surveyAnswers.find(answer => {
          return answer.surveyQuestion === question._id;
        });
        if (
          question.required &&
          (answer === undefined ||
            answer.answer === undefined ||
            answer.answer.trim() === '')
        ) {
          respondedToAllRequiredQuestions = false;
          return;
        }
      });
      goToNextDisabled = !respondedToAllRequiredQuestions;
    }

    const isAtStart = currentSpineIndex === 0;
    const isAtEnd = currentSpineIndex === currentBook.parts.length - 1;

    let goBackButton, goForwardButton, jumpToMenu;
    const goForwardIcon = 'chevron right';
    // const goForwardIcon = `chevron ${language.getSecondaryPosition(currentLanguage)}`;
    const goBackIcon = 'chevron left';
    // const goBackIcon = `chevron ${language.getPrimaryPosition(currentLanguage)}`;

    if (standAlone) {
      goBackButton = (
        <div
          // style={{float: 'left'}}
          style={{float: language.getPrimaryPosition(currentLanguage)}}>
          <Link to='/' onClick={this.moveBack} className='br-text'>
            <Button
              inverted={currentColorMode === 'dark'}
              color={currentColorMode === 'sepia' ? 'brown' : null}
              icon={goBackIcon}
              circular
            />{' '}
            {t('Previous')}
          </Link>
        </div>
      );
      goForwardButton = (
        <div style={{float: language.getSecondaryPosition(currentLanguage)}}>
          <Link
            to='/'
            onClick={isAtEnd ? this.completeBook : this.moveForward}
            className={goToNextDisabled ? 'disabled-link' : 'br-text'}>
            {!isAtEnd ? t('Next') : t('LeaveReview')}{' '}
            <Button
              icon={goForwardIcon}
              circular
              inverted={currentColorMode === 'dark'}
              color={currentColorMode === 'sepia' ? 'brown' : null}
              disabled={goToNextDisabled}
            />
          </Link>
        </div>
      );
    } else {
      goBackButton = (
        <Menu.Item
          position='left'
          link
          onClick={this.moveBack}
          content={
            <div>
              <Media
                at='mobile'
                as='div'
                className={standAlone ? 'br-text' : ''}>
                <Icon name={goBackIcon} />
              </Media>
              <Media
                greaterThan='mobile'
                as='div'
                className={standAlone ? 'br-text' : ''}>
                <Icon name={goBackIcon} /> {t('Previous')}
              </Media>
            </div>
          }
        />
      );
      goForwardButton = (
        <Menu.Item
          position='right'
          link
          onClick={isAtEnd ? this.completeBook : this.moveForward}
          content={
            <div>
              <Media at='mobile'>
                <Icon name={goForwardIcon} />
              </Media>
              <Media greaterThan='mobile'>
                <div>
                  {!isAtEnd ? t('Next') : t('LeaveReview')}{' '}
                  <Icon name={goForwardIcon} />
                </div>
              </Media>
            </div>
          }
        />
      );
      jumpToMenu = (
        <Menu.Menu>
          <Dropdown item text={t('Browse')}>
            <Dropdown.Menu>
              <Dropdown.Item onClick={e => this.props.browseTo(0)}>
                {t('to beginning')}
              </Dropdown.Item>
              <Dropdown.Item onClick={this.props.toggleChapterSelector}>
                {t('to chapter...')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      );
    }

    if (standAlone) {
      return (
        <div>
          {goBackButton}
          {goForwardButton}
        </div>
      );
    } else if (!isAtStart) {
      return (
        <Menu.Menu position='right' style={{maxWidth: 700, width: '100%'}}>
          {goBackButton}
          {jumpToMenu}
          {goForwardButton}
        </Menu.Menu>
      );
    } else {
      return null;
    }
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  currentBook: state.book.currentBook,
  readingData: state.readerApp.readingData,
  currentSpineIndex: state.readerApp.currentSpineIndex,
  answers: state.survey.answers,
  defaultColorMode: state.app.defaultColorMode,
  currentLanguage: state.user.currentLanguage
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: newPage => push(newPage),
      updateSpineIndex,
      submitSurveyAnswers
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ChapterMenu))
);
