import React, { Component } from 'react';
import {
  Form, Button, Header
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

const roles = {
  WRITER: 'WRITER',
  READER: 'READER',
  BOTH: 'BOTH'
};

class RoleGetter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      role: props.initialValue
    };
  }

  setRole = (role) => {
    this.setState({ role }, () => {
      this.submit();
    });
  }

  submit = () => {
    const { role } = this.state;
    if (this.props.onUpdate) {
      this.props.onUpdate({ role })
    }
  }


  // render
  render() {
    const { t, updating } = this.props;
    const { role } = this.state;
    return (
      <React.Fragment>
        <Header
          size='small'
          content={t('RoleHeader')}
          subheader={t('RoleInfo')}
        />
        <Form.Field style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Button
            fluid
            toggle
            content={t('RoleWriter')}
            active={role === roles.WRITER}
            onClick={() => this.setRole(roles.WRITER)}
          />
          <Button
            fluid
            toggle
            content={t('RoleReader')}
            active={role === roles.READER}
            onClick={() => this.setRole(roles.READER)}
          />
          <Button
            fluid
            toggle
            content={t('RoleBoth')}
            active={role === roles.BOTH}
            onClick={() => this.setRole(roles.BOTH)}
          />
        </Form.Field>
      </React.Fragment>
    );
  }
}

export default withTranslation()(RoleGetter)
