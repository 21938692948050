import Ux from './uxAnalyticsUtil';

const track = (data) => {
  let passthroughData = null;
  if (data && data.checkoutData && data.checkoutData.passthrough) {
    passthroughData = JSON.parse(data.checkoutData.passthrough);
  }
  switch (data.event) {
    case 'Checkout.Coupon.Applied':
      Ux.trackEvent({
        category: 'Subscriptions',
        action: 'checkout-coupon-applied',
        label: passthroughData ? passthroughData.pricingPlan : ''
      });
      break;

    case 'Checkout.Coupon.Remove':
      Ux.trackEvent({
        category: 'Subscriptions',
        action: 'checkout-coupon-remove',
        label: passthroughData ? passthroughData.pricingPlan : ''
      });
      break;

    case 'Checkout.PaymentComplete':
      Ux.trackEvent({
        category: 'Subscriptions',
        action: 'checkout-payment-complete',
        label: passthroughData ? passthroughData.pricingPlan : '',
        value: (data && data.eventData.checkout && data.eventData.checkout.prices && data.eventData.checkout.prices.vendor && data.eventData.checkout.prices.vendor.total) ? parseInt(data.eventData.checkout.prices.vendor.total) : 0
      });
      break;

    case 'Checkout.Close':
      Ux.trackEvent({
        category: 'Subscriptions',
        action: 'checkout-closed',
        label: passthroughData ? passthroughData.pricingPlan : ''
      });
      break;

    case 'Checkout.Error':
      Ux.trackErrorEvent({
        description: 'checkout-error',
        fatal: false
      });
      break;
    default:
  }
};

export default {
  track
};
