import React, { Component } from 'react';
import {
  Input, Button, Header
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

class DisplayNameGetter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      displayName:  props.initialValue || ''
    };
  }

  handleDisplayNameChange = (event, data) => {
    this.setState({ displayName: data.value });
  }

  submit = () => {
    const { displayName } = this.state;
    if (this.props.onUpdate) {
      this.props.onUpdate({ displayName })
    }
  }

  isInvalid = () => {
    const { displayName } = this.state;
    return !displayName || displayName.trim().length === 0;
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      // enter
      this.submit();
    }
  }

  // render
  render() {
    const { t, updating } = this.props;
    const { displayName } = this.state;
    return [
      <Header
        key='header'
        size='small'
        content={t('WhatsYourName')}
        subheader={t('OnYourProfile')}
      />,
      <Input
        key='input'
        fluid
        placeholder={t('EnterYourName')}
        value={displayName}
        onChange={this.handleDisplayNameChange}
        onKeyDown={this.handleKeyDown}
        action={(
          <Button
            icon='chevron right'
            disabled={this.isInvalid()}
            onClick={this.submit}
            loading={updating}
          />
        )}
      />
    ];
  }
}

export default withTranslation()(DisplayNameGetter)
