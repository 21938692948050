import React from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';

import { DeviceMinWidths, MediaQuery } from '../../constants/size';
import useOutsideClick from '../../hooks/useOutsideClick';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ApplicationMenuContainer from './index';
import { TOGGLE_APP_SIDEBAR } from '../../modules/app';

const Sidebar = ({ sidebarVisible }) => {
  const sidebarRef = React.useRef();
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();

  useOutsideClick(sidebarRef, () => {
    if (sidebarVisible && windowDimensions.width < DeviceMinWidths.tablet) {
      // hide sidebar when clicking outside it on mobile screen dimensions
      dispatch({ type: TOGGLE_APP_SIDEBAR, showAppSidebar: false });
    }
  });

  return (
    <SidebarWrapper ref={sidebarRef} sidebarVisible={sidebarVisible}>
      <ApplicationMenuContainer />
    </SidebarWrapper>
  );
}

export default Sidebar;

const SidebarWrapper = styled.div`
  background-color: black;
  width: 250px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  ${MediaQuery.phone} {
    ${({ sidebarVisible }) => sidebarVisible ? css`
      position: fixed;
      top: 0;
      max-height: 100vh;
      min-height: 100vh;
      z-index: 200;
    ` : css`
      display: none;
    `}
  }
  ${MediaQuery.tablet} {
    --offset: var(--space);
    position: sticky;
    top: var(--offset);
    width: 250px;
    max-height: calc(100vh - var(--offset) - var(--header-height));
    ${MediaQuery.tablet} {
      ${({ sidebarVisible }) => sidebarVisible ? css`
      ` : css`
        display: none;
      `}
    }
  }
`;
