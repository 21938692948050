import React, {useRef, useEffect, useState, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getReaction} from '../../utils/reactionUtil';
import {
  setActiveInlineComment,
  updateCommentDomInlineReference
} from '../../modules/comment';

import {toggleCommentsSidebar} from '../../modules/app';

const CommentBlock = ({children, contentState, entityKey}) => {
  // create a ref to store the inlineCommentRef DOM element
  const inlineCommentRef = useRef();

  const dispatch = useDispatch();
  const activeInlineComment = useSelector(
    state => state.comment.activeInlineComment
  );
  const comments = useSelector(state => state.comment.comments);
  const filteredReaders = useSelector(state => state.comment.filteredReaders);

  const [entityComments, setEntityComments] = useState([]);

  const _isActive = useCallback(
    containingComments =>
      activeInlineComment && containingComments.includes(activeInlineComment),
    [activeInlineComment]
  );
  const entity = useMemo(
    () => contentState.getEntity(entityKey),
    [contentState, entityKey]
  );
  const isActive = _isActive(entityComments);

  useEffect(() => {
    const element = inlineCommentRef.current;
    if (
      element !== undefined &&
      entity !== undefined &&
      entity.get('data') &&
      entity.get('data').comments &&
      entity.get('data').comments.length > 0
    ) {
      entity.get('data').comments.forEach(id => {
        dispatch(updateCommentDomInlineReference(id, element));
      });
      setEntityComments(entity.get('data').comments);
    }

    return () => {
      entityComments.forEach(id => {
        dispatch(updateCommentDomInlineReference(id, undefined));
      });
    };
  }, [entityKey, entity]);

  useEffect(() => {
    if (activeInlineComment) {
      // if this comment just became active, scroll it into view
      const justBecameActive = _isActive(entity.get('data').comments);
      if (justBecameActive) {
        const commentElement =
          document.getElementById(`br-inline-cid-${activeInlineComment}`) ||
          document.getElementById(`br-comment-cid-${activeInlineComment}`);
        if (commentElement) {
          commentElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start'
          });
        }
      }
    }
  }, [activeInlineComment]);

  const commentClicked = event => {
    console.log('commentClicked');
    event.preventDefault();
    event.stopPropagation();
    // show comments sidebar
    dispatch(toggleCommentsSidebar(true));
    // highlight the active comment
    dispatch(
      setActiveInlineComment({
        commentId: entity.get('data').id,
        show: 'sidebar'
      })
    );
  };

  const getStyle = type => {
    const style = `inline-comment-block reaction-selection-${
      entity.get('data').reaction
        ? entity.get('data').reaction.toLowerCase()
        : 'comment'
    } ${type === 'active' && 'reaction-active'}`;
    return style;
  };

  let hideThisComment = false;

  if (filteredReaders) {
    // find all relevant comment objects
    const relevantComments = comments.filter(comment =>
      entity.get('data').id.includes(comment._id)
    );
    // check if any of the comments belong to the active reader
    if (
      relevantComments.every(
        comment => comment.user._id !== filteredReaders._id
      )
    ) {
      hideThisComment = true;
    }
  }

  return (
    <span
      ref={inlineCommentRef}
      id={`br-inline-cid-${entity.get('data').id}`}
      className={
        !hideThisComment
          ? isActive
            ? getStyle('active')
            : getStyle('inactive')
          : null
      }
      onClick={commentClicked}>
      {!hideThisComment && (
        <span className='reactionIcon-inline'>
          {getReaction(entity.get('data').reaction)}
        </span>
      )}
      {children}
    </span>
  );
};

export default CommentBlock;
