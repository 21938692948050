import React, { Component } from 'react';

import {
  Button, Item, Container, Visibility, Loader, Header
} from 'semantic-ui-react';
// i18n
import { withTranslation } from 'react-i18next';
// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { listOpportunities, loadMoreOpportunities, listRecommendations } from '../../modules/betaOpportunities';
import BetaOpportunityItem from './betaOpportunityItem';

import { getFreshIdToken } from '../../utils/authUtil';
import Ux from '../../utils/uxAnalyticsUtil';

class DiscoverBookList extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { history } = this.props;
    Ux.trackPageView(history.location.pathname);
    this.listOpportunities();
  }

  componentDidUpdate(prevProps) {
    const { location, history } = this.props;
    if (location !== prevProps.location) {
      Ux.trackPageView(history.location.pathname);
      // check if we are currently searching and toggle content carusels
      // fetch new opportunities
      this.listOpportunities();
    }
  }

  listOpportunities = async () => {
    const { match } = this.props;
    const idToken = await getFreshIdToken();
    const genre = match.params.genre;
    // if we don't have any options fallback to default filter
   const options = {
      dimension: 'genre',
      genres: genre
    };
    this.props.listOpportunities(idToken, options);
  }

  loadMore = async () => {
    const idToken = await getFreshIdToken();
    const { match, booksByDimension } = this.props;
    const genre = match.params.genre;

    this.props.loadMoreOpportunities(idToken, {
      dimension: 'genre',
      genres: genre,
      skip: booksByDimension[0].books.length
    });
  }

  render() {
    const {
      t, loadingOpportunities, booksByDimension
    } = this.props;
    return (
      <Container>
        {
          booksByDimension && booksByDimension.length > 0
          && <Header>{booksByDimension[0].genre}</Header>
        }
        <Item.Group unstackable>
          { loadingOpportunities
            && <Loader active />
          }
          {
            booksByDimension && booksByDimension.length > 0 && booksByDimension[0].books.length > 0
            && booksByDimension[0].books.map(book => <BetaOpportunityItem key={book._id} book={book} />)
          }
          {
            booksByDimension && booksByDimension.length > 0 && booksByDimension[0].books.length === 0
            && <p>{t('NothingHere')}</p>
          }
        </Item.Group>
        {
          booksByDimension && booksByDimension.length > 0 && booksByDimension[0].books.length > 0
          && (
            <Visibility offset={[10, 10]} onTopVisible={this.loadMore}>
              <Button circular basic fluid content={t('LoadMore')} onClick={this.loadMore} loading={loadingOpportunities} />
            </Visibility>
          )
        }
      </Container>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  books: state.betaOpportunities.books,
  booksByDimension: state.betaOpportunities.booksByDimension,
  recommendations: state.betaOpportunities.recommendations,
  loadingRecommendations: state.betaOpportunities.loadingRecommendations,
  loadingOpportunities: state.betaOpportunities.loadingOpportunities,
  searchTerm: state.betaOpportunities.searchTerm
});

const mapDispatchToProps = dispatch => bindActionCreators({
  listOpportunities,
  loadMoreOpportunities,
  listRecommendations,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(DiscoverBookList)));
