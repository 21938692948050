import React, { Component } from 'react'
import qs from 'qs';
import {
  Button, Header, Icon, Segment, Label, Divider, List
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
// i18n
import { withTranslation } from 'react-i18next';
// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  updateReaderFilters
} from '../../modules/readerIndex';

import ReaderIndexFilter from './readerIndexFilter';

class ReaderIndexFilterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReaderFilters: false
    };
  }

  componentDidMount() {
    this.initiateFiltersFromURL();
  }

  /*
  Get filters if any from URL and set the existing ones to that
  */
  initiateFiltersFromURL = () => {
    const { location } = this.props;
    const queryObject = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (queryObject.filter) {
      // a filter exists in the query string, try to apply it
      this.updateFilterList(queryObject.filter);
    }
  }

  addFilter = (index) => {
    const { readerFilters } = this.props;
    const updatedReaderFilters = [...readerFilters];
    updatedReaderFilters.splice(index + 1, 0, {});
    this.updateFilterList(updatedReaderFilters);
  }

  removeFilter = (index) => {
    const { readerFilters } = this.props;
    const updatedReaderFilters = [...readerFilters];
    updatedReaderFilters.splice(index, 1);
    if (updatedReaderFilters.length === 0) {
      updatedReaderFilters.push({});
      this.toggleReaderFilters();
    }
    this.updateFilterList(updatedReaderFilters);
  }

  handleFilterChange = (data) => {
    const {
      index, type, option, value
    } = data;
    const updatedReaderFilters = [...this.props.readerFilters];
    updatedReaderFilters[index] = type === 'any' ? {} : { type, option, value };
    this.updateFilterList(updatedReaderFilters);
  }

  updateFilterList = (newFilters) => {
    this.props.updateReaderFilters(newFilters, (success) => {
      if (success) {
        const { onUpdate, history } = this.props;
        // update the url query string in order to let users share a filter query
        const newURL = `${history.location.pathname}${qs.stringify({ filter: newFilters }, { addQueryPrefix: true })}`;
        history.replace(newURL);
        // let parent component know there's been an update
        onUpdate();
      }
    });
  }

  getFilterText = () => {
    const { readerFilters, t } = this.props;
    if (readerFilters && readerFilters.length === 1 && Object.keys(readerFilters[0]).length === 0 && readerFilters[0].constructor === Object) {
      return t('AddFilter')
    } else if (readerFilters && readerFilters.length > 0) {
      return `${readerFilters.length} reader filter${readerFilters.length > 1 ? 's' : ''}`
    } else {
      return t('AddFilter');
    }
  }

  toggleReaderFilters = (event, data) => {
    const { showReaderFilters } = this.state;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({
      showReaderFilters: !showReaderFilters
    });
  }

  render() {
    const { showReaderFilters, readerOptions, versionOptions } = this.state;
    const { readerFilters, t } = this.props;
    return (
      <div style={{ width: '100%' }}>
        { !showReaderFilters && (
          <Button onClick={this.toggleReaderFilters} key='filterTop' as='div' labelPosition='right'>
            <Button icon>
              <Icon name='filter' />
            </Button>
            <Label as='a' onClick={this.toggleReaderFilters} basic>{this.getFilterText()}</Label>
          </Button>
        )}
        {
          (showReaderFilters && readerFilters)
          && (
            <Segment key='filterBody'>
              <Header size='tiny'>
                {t('Filters')}
                <Icon link size='mini' name='chevron up' onClick={this.toggleReaderFilters} />
              </Header>
              <List selection>
                {
                  readerFilters.map((readerFilter, index) => (
                    [
                      index !== 0 && <Divider key={`filter-divider-${readerFilter.type}`} horizontal>{t('And')}</Divider>,
                      <ReaderIndexFilter
                        key={index}
                        index={index}
                        type={readerFilter.type}
                        option={readerFilter.option}
                        value={readerFilter.value}
                        removeFilter={this.removeFilter}
                        addFilter={this.addFilter}
                        readerOptions={readerOptions}
                        versionOptions={versionOptions}
                        handleFilterChange={this.handleFilterChange}
                        refreshData={this.refreshData}
                      />
                    ]
                  ))
                }
              </List>
            </Segment>
          )
        }
        <Divider key='divider' hidden />
      </div>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  readerFilters: state.readerIndex.readerFilters
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateReaderFilters
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ReaderIndexFilterContainer)));
