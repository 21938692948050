import React from 'react';
import {
  Rating, Grid, Card
} from 'semantic-ui-react';
import moment from 'moment';
import i18n from '../../utils/i18n/i18n';
import Avatar from '../avatar/avatar';

const ReviewItem = (
  {
    reviewData
  }
) => (
  <Card key={reviewData._id} fluid>
    <Card.Content>
      {
        reviewData.user && (
          <React.Fragment>
            <Card.Meta
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Avatar name={reviewData.user.displayName} />
                <span style={{ marginLeft: '1em' }}>
                  {reviewData.user.displayName}
                </span>
              </div>
              <span style={{ fontSize: '0.8em', justifyContent: 'flex-end' }}>
                {moment(reviewData.updatedAt).format('LLL')}
              </span>
            </Card.Meta>
          </React.Fragment>
        )
      }
      <Card.Description>
        <Grid columns='equal'>
          <Grid.Row>
            <Grid.Column>
              <table className='plain-table'>
                <tbody>
                  <tr>
                    <td>
                      {i18n.t('OverallRating')}
                      :
                    </td>
                    <td>
                      <Rating floated='right' icon='star' defaultRating={reviewData.overAllRating} maxRating={5} disabled />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {i18n.t('PlotRating')}
                      :
                    </td>
                    <td>
                      <Rating icon='star' defaultRating={reviewData.plotRating} maxRating={5} disabled />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {i18n.t('GrammarRating')}
                      :
                    </td>
                    <td>
                      <Rating icon='star' defaultRating={reviewData.grammarRating} maxRating={5} disabled />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <div style={{ whiteSpace: 'pre-wrap' }}>
                        {reviewData.text}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Description>
    </Card.Content>
  </Card>
);

export default (ReviewItem);
