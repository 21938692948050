import React, {Component} from 'react';

import {Button, List, Popup} from 'semantic-ui-react';

import {toast} from 'react-toastify';

import {withRouter} from 'react-router-dom';

// i18n
import {withTranslation} from 'react-i18next';

// redux
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {deleteVersion} from '../../modules/book';

export class DownloadVersion extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  downloadDocx = async versionId => {
    const {t} = this.props;
    this.setState({
      downloadingDocx: true
    });
    const {idToken, bookId} = this.props;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/content/${versionId}?format=docx`,
        {
          method: 'GET',
          headers: {
            'br-token': idToken
          }
        }
      );
      const result = await response.json();
      if (result.url) {
        window.open(result.url);
      } else {
        toast.error(t('DownloadFailedMsg'));
      }
    } catch (e) {
      console.error('failed to download version', e);
      return false;
    } finally {
      this.setState({
        downloadingDocx: false
      });
    }
  };

  downloadTxtFile = csvData => {
    const element = document.createElement('a');
    const file = new Blob([csvData], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = 'feedback.csv';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  downloadCSV = async versionId => {
    this.setState({
      downloadingCSV: true
    });
    const {idToken, bookId} = this.props;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/content/${versionId}?format=csv`,
        {
          method: 'GET',
          headers: {
            'br-token': idToken
          }
        }
      );
      const result = await response.text();
      this.downloadTxtFile(result);
    } catch (e) {
      console.error('failed to download version', e);
      return false;
    } finally {
      this.setState({
        downloadingCSV: false
      });
    }
  };

  render() {
    const {t, content, style = {}, text, compact} = this.props;
    const {downloadingCSV, downloadingDocx} = this.state;
    if (!content || content.length === 0) {
      return null;
    }

    if (content.length === 1 && !compact) {
      return (
        <>
          <Button
            content={t('DOCX')}
            loading={downloadingDocx}
            fluid
            onClick={() => this.downloadDocx(content[0]._id)}
            style={style}
          />
          <Button
            content={t('CSV')}
            loading={downloadingCSV}
            fluid
            onClick={() => this.downloadCSV(content[0]._id)}
            style={style}
          />
        </>
      );
    }
    if (content.length === 1 && compact) {
      return (
        <>
          <Button
            icon='file word'
            basic
            loading={downloadingDocx}
            style={style}
            onClick={() => this.downloadDocx(content[0]._id)}
          />
          <Button
            icon='file alternate'
            basic
            loading={downloadingCSV}
            style={style}
            onClick={() => this.downloadCSV(content[0]._id)}
          />
        </>
      );
    }
    return (
      <Popup
        pinned
        on='click'
        trigger={
          <Button
            style={style}
            icon='download'
            content={t('DOCX')}
            loading={downloadingDocx}
          />
        }
        content={
          <List>
            {content.map(version => (
              <List.Item
                as='a'
                key={version._id}
                onClick={() => this.downloadDocx(version._id)}>
                {`${t('PickVersion')} `}
                {version.versionNumber}
              </List.Item>
            ))}
          </List>
        }
      />
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  idToken: state.user.idToken
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteVersion
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DownloadVersion))
);
