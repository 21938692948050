import React from 'react';
import {
  Route, Switch, withRouter
} from 'react-router-dom';

import BroadcastMessageList from './broadcastMessageList';
import BroadcastMessageContainer from './broadcastMessageContainer';

const BroadcastMessages = ({ match }) => {
  return (
    <Switch>
      <Route key='message' path={`${match.path}/:messageId`} component={BroadcastMessageContainer} />
      <Route key='message-list' component={BroadcastMessageList} />
    </Switch>
  );
};

export default withRouter(BroadcastMessages);
