import React from 'react';
import {Trans} from 'react-i18next';

const ForgotPasswordWidget = () => (
  <>
    <Trans i18nKey='ForgotPasswordPrompt'>
      <a
        style={{fontSize: '0.9em'}}
        href={`${
          window.location.protocol + '//' + window.location.host
        }/reset-password`}>
        Forgot your password?
      </a>
    </Trans>
  </>
);

export default ForgotPasswordWidget;
