import React, { useEffect } from 'react';
// routing
import { withRouter } from 'react-router-dom';
// semantic components
import {
  Grid, Statistic, Message, Container, Loader, Segment, Button, Dimmer, Header
} from 'semantic-ui-react';
// i18n
import { useTranslation } from 'react-i18next';
// time formatting
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { useDispatch, useSelector } from 'react-redux';
// custom
import ProgressChart from './progressChart';
import ReactionChart from './reactionChart';

import {
  fetchReadingData,
  clearReadingData,
  changeSelectedPart
} from '../../../../modules/report';

import filterBuilder from '../../../../utils/filterBuilder';
import { authUtil } from '../../../../utils';

const colors = ['#FC9C04', '#FFBB28', '#5B5A54', '#000000', '#CCCCCC'];

const durationFormat = 'd[d] h[h] m[m] s[s]';

momentDurationFormatSetup(moment);

const secondsToMinutes = (seconds) => `${Number(seconds / 60).toFixed(1)} min`;

const MinuteTick = ({ x, y, stroke, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={-12} textAnchor="end" fill="#999" fontSize="12">{secondsToMinutes(payload.value)}</text>
    </g>
  )
}

const ReaderTick = ({ x, y, stroke, payload }) => {
  const { t } = useTranslation();
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={-12} textAnchor="end" fill="#999" fontSize="12">{t('XReaders')}</text>
    </g>
  )
}

export const ReadingDataReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentBook = useSelector(state => state.book.currentBook);
  const versionData = useSelector(state => state.report.versionData);

  const fetchingReadingData = useSelector(state => state.report.fetchingReadingData);
  const invited = useSelector(state => state.report.invited);
  const started = useSelector(state => state.report.started);
  const finished = useSelector(state => state.report.finished);
  const abandoned = useSelector(state => state.report.abandoned);
  const readerFilters = useSelector(state => state.report.readerFilters);
  const versionFilter = useSelector(state => state.report.versionFilter);

  useEffect(() => {
    if (!!currentBook) {
      fetchReport();
    }
  }, [currentBook, readerFilters, versionFilter]);

  const fetchReport = async (type) => {
    const idToken = await authUtil.getFreshIdToken();
    if (!idToken) return;
    dispatch(fetchReadingData(idToken, currentBook._id, versionFilter, readerFilters, type));
  }

  const downloadReport = async () => {
    fetchReport('csv');
  }

  if (!versionData || !currentBook) {
    return null;
  }

  if (!(currentBook.content[0]?.parts?.filter(part => part.kind === 'Chapter')?.length > 0)) {
    return (
      <Message>
        {t('YourBookDoesNotHaveAnyChaptersYet')}
      </Message>
    );
  }
  if (fetchingReadingData) {
    return (
      <Container>
        <Loader active inline='centered' />
      </Container>
    );
  }

  return (
    <div>
      {versionData && (
        <Dimmer.Dimmable as={Segment} basic>
          <Button basic floated='right' icon='download' onClick={downloadReport} />
          <Grid key='overview' columns='equal' textAlign='center'>
            <Grid.Row>
              <Statistic.Group size='small'>
                <Statistic>
                  <Statistic.Value>{invited.readerCount}</Statistic.Value>
                  <Statistic.Label>{t('Invited')}</Statistic.Label>
                </Statistic>
                <Statistic>
                  <Statistic.Value>{started.readerCount}</Statistic.Value>
                  <Statistic.Label>{t('Started')}</Statistic.Label>
                </Statistic>
                <Statistic>
                  <Statistic.Value>{finished.readerCount}</Statistic.Value>
                  <Statistic.Label>{t('Finished')}</Statistic.Label>
                </Statistic>
                <Statistic>
                  <Statistic.Value>{abandoned.readerCount}</Statistic.Value>
                  <Statistic.Label>{t('Abandoned')}</Statistic.Label>
                </Statistic>
              </Statistic.Group>
            </Grid.Row>
          </Grid>
          <Grid stackable columns='equal'>
            <Grid.Row>
              <Grid.Column textAlign='center' as={Segment}>
                <Header sub content={t('ReaderProgress')} />
                {
                  versionData.map(version => (
                    <ProgressChart
                      key={`ReaderProgress_${version.versionId}`}
                      title={`${versionData.length > 1 ? `v${version.versionNumber}` : ''}`}
                      unit={t('Readers')}
                      dataKey='readerCount'
                      payloadKey='parts'
                      syncId='versionData'
                      data={version.readingData}
                      lineColor={colors[2]}
                      areaColor={colors[0]}
                    />
                  ))
                }
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign='center' as={Segment}>
                <Header sub content={t('AvgTimeToComplete')} />
                {
                  versionData.map(version => (
                    <ProgressChart
                      key={`AvgTimeToComplete_${version.versionId}`}
                      title={`${versionData.length > 1 ? `v${version.versionNumber}` : ''}`}
                      unit={t('AvgTimeToComplete')}
                      dataKey='avgTimeSpent'
                      payloadKey='parts'
                      syncId='versionData'
                      data={version.readingData}
                      lineColor={colors[2]}
                      areaColor={colors[0]}
                      customValueParser={secondsToMinutes}
                      customTick={MinuteTick}
                    />
                  ))
                }
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign='center' as={Segment}>
                <Header sub content={t('Engagement')} />
                {
                  versionData.map(version => (
                    <ReactionChart
                      key={`Engagement_${version.versionId}`}
                      title={`${versionData.length > 1 ? `v${version.versionNumber}` : ''}`}
                      name={t('Engagement')}
                      dataKey='avgTimeSpent'
                      payloadKey='parts'
                      syncId='versionData'
                      data={version.readingData}
                      areaColor={colors[0]}
                      customValueParser={secondsToMinutes}
                    />
                  ))
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Dimmer.Dimmable>
      )}
      {/* <ReaderTable key='readerTable' /> */}
    </div>
  );
}

export default ReadingDataReport;
