import React, { useCallback } from 'react';

import '../../App.css';

// i18n
import { withTranslation } from 'react-i18next';

// Routes
import { withRouter } from 'react-router-dom';

import styled from 'styled-components';
import PropTypes from 'prop-types';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import {
  Menu, Icon, Button, Dropdown
} from 'semantic-ui-react';

import {
  saveBook
} from '../../modules/book';
import useAutoSaveContext, { SAVE_STATE } from '../../hooks/useAutoSaveContext';

const ContentAutoSaveButton = ({
  t
}) => {

  const {
    save,
    saveState
  } = useAutoSaveContext();

  const getButtonText = () => {
    switch (saveState) {
      case SAVE_STATE.save:
        return t('Save');
      case SAVE_STATE.saving:
        return t('Saving');
      case SAVE_STATE.saved:
      default:
        return t('Saved');
    }
  }
  return (
    <Button
      compact
      primary={saveState === SAVE_STATE.save}
      disabled={saveState !== SAVE_STATE.save}
      loading={saveState === SAVE_STATE.saving}
      onClick={save}
    >
      {getButtonText()}
    </Button>
  )
}

// redux stuff
const mapStateToProps = state => ({
  contentSaveState: state.book.contentSaveState,
  saveContentFn: state.book.saveContentFn
});

const mapDispatchToProps = dispatch => bindActionCreators({
  saveBook,
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentAutoSaveButton)));
