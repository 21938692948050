import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom'

// semantic components
import { Tab, Menu } from 'semantic-ui-react'

// time formatting
import moment from 'moment';
import momentDurationFormatSetup from "moment-duration-format";

// redux
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect, useDispatch, useSelector } from 'react-redux'

// custom
import ReadingDataReport from './readingData/readingDataReport';
import SurveyDataReport from './surveyData/surveyDataReport';
import ReviewOverview from './reviewData/reviewDataReport'
import AbandonedReadersReport from './abandonedReaders/abandonedReadersReport'

import ReaderJourneyFilterContainer from './readerJourneyFilterContainer';

import queryString from 'query-string';

// i18n
import { useTranslation } from 'react-i18next';

import {
  fetchReadingData,
  changeSelectedPart,
  fetchSurveyData,
  clearReadingData,
  clearReviewData,
  clearSurveyData,
  clearAbandonedReadersData
} from '../../../modules/report';
import styled from 'styled-components';

const durationFormat = 'd[d] h[h] m[m] s[s]'

momentDurationFormatSetup(moment);

const ReportOverview = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const readingProgress = useSelector(state => state.report.readingProgress);

  useEffect(() => {
    return () => {
      dispatch(clearReadingData());
      dispatch(clearSurveyData());
      dispatch(clearReviewData());
      dispatch(clearAbandonedReadersData());
    }
  }, []);

  const goToPane = (paneName) => {
    const parsedQueryString = queryString.parse(location.search);
    parsedQueryString.pane = paneName;
    const newQueryString = queryString.stringify(parsedQueryString);
    history.push(`${location.pathname}?${newQueryString}`);
  }

  const changeSelectedPart = (dataIndex) => {
    dispatch(changeSelectedPart(readingProgress[dataIndex]));
  }

  const panes = [
    { key: 'reading', menuItem: <Menu.Item key='reading' onClick={() => { goToPane('reading'); }}>{t('ReadingDataReport')}</Menu.Item>, render: () => <Tab.Pane key='reading'><ReadingDataReport /></Tab.Pane> },
    { key: 'survey', menuItem: <Menu.Item key='survey' onClick={() => { goToPane('survey'); }}>{t('SurveyAnswersReport')}</Menu.Item>, render: () => <Tab.Pane key='survey'><SurveyDataReport /></Tab.Pane> },
    { key: 'abandoned', menuItem: <Menu.Item key='abandoned' onClick={() => { goToPane('abandoned'); }}>{t('AbandonedReadersReport')}</Menu.Item>, render: () => <Tab.Pane key='abandoned'><AbandonedReadersReport /></Tab.Pane> },
  ]

  const parsedQueryString = queryString.parse(location.search);
  const paneName = parsedQueryString.pane;
  let activeIndex = panes.findIndex(pane => pane.key === paneName);
  if (activeIndex === -1) {
    activeIndex = 0;
  }

  return (
    <ReportWrapper>
      <ReaderJourneyFilterContainer />
      <Tab
        menu={{ secondary: true, pointing: true, attached: true }}
        activeIndex={activeIndex}
        panes={panes}
      />
    </ReportWrapper>
  );
}

const ReportWrapper = styled.div`
  padding-left: 1em;
  padding-right: 1em;
`;


export default ReportOverview;
