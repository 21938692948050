import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Menu, Button
} from 'semantic-ui-react';

const BackMenuButton = ({
  toPath,

  changePage,
}) => {
  return (
    <Menu.Item fitted>
      <Button
        onClick={() => changePage(toPath)}
        circular
        icon='chevron left'
      />
    </Menu.Item>
  );
}

// redux stuff
const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(BackMenuButton)));
