import React, { Component } from 'react';

import {
  Button,
  Container,
  Header,
  Icon,
  Segment
} from 'semantic-ui-react';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

class About extends Component {
  render() {
    const {user, changePage} = this.props;
    return (
      <div>
        <Segment style={{ padding: '0em 0em 3em' }} vertical>
          <Container text>
            <Header as='h3' style={{ fontSize: '1.33em' }}>What is this?</Header>
            <div style={{ fontSize: '1.1em' }}>
            <p>
              <strong>BetaReader.io</strong> is the brainchild of Swedish writer and tinkerer Jonas Frid. The idea for the tool came when I was a few weeks into the beta reading process of my coming novel, and I got frustrated by how bad of an overview I had of the entire thing.
            </p>
            <p>
              I had 20+ beta readers who all wanted the book delivered in different shapes and formats. Once I had sorted that and waited for their much valued feedback, I had no idea how far each reader had come <em>(I had to push myself not to ping them everyday and ask!)</em>. And then, when the feedback finally started coming in, only half of my readers provided it in the format I had requested, and they all sent it through different channels.
            </p>
            <p>
              Somewhere around this time, the epiphany hit me – this process can and should be simpler. Enter BetaReader, a beautiful web app that works everywhere. One, simple place to share my work and manage my readers and feedback.
            </p>
            <p>
              "How hard can that be?" I thought. When I don't make up stories, I'm a tinkerer and programmer at heart, so I, if anyone, should be able to pull it off!
            </p>
            <p>
              Turns out, "that" was quite hard – almost as hard as finishing a novel. Good thing I'm not the type who gives up. Many sweaty nights later, <strong>BetaReader.io</strong> was ready to be shared with the world. It's currenly (fittingly) in beta, which means I'd love you to try it out and help me make it better.
            </p>
            { user === undefined &&
              <div>
                <p>
                  Why don't you sign up and let me know what you think?
                </p>
                <Button size='large' color='orange' onClick={()=>{changePage('/signup')}}>
                  Join our Private Beta (it's free!)
                  <Icon name='right arrow' />
                </Button>
              </div>
            }
          </div>
          </Container>
        </Segment>
      </div>
    )
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken
})

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: (newPage) => push(newPage)
}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(About))
