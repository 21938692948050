import React, { useEffect, useRef } from 'react';
import { useParams, withRouter } from 'react-router-dom';

import { Divider, Form, Button } from 'semantic-ui-react';
// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// i18n
import { withTranslation } from 'react-i18next';
import Question from './question/question';
import { authUtil } from '../../../utils';

import {
  saveSurvey,
  editCurrentSurvey,
  replaceCurrentSurvey,
  fetchCurrentSurvey,
  startNewSurvey,
  addQuestion,
  editCurrentSurveyQuestion,
  deleteSurveyQuestion,
  changeTitle,
  changeDescription,
  clearSurvey
} from '../../../modules/survey';

import {
  replacePart
} from '../../../modules/book';
import useAutoSaveContext from '../../../hooks/useAutoSaveContext';
import usePrevious from '../../../hooks/usePrevious';
import InputWithSave from '../../../components/inputs/InputWithSave';
import TextAreaWithSave from '../../../components/inputs/TextAreaWithSave';


const EditSurvey = ({
  currentBook,
  currentSurvey,

  addQuestion,
  clearSurvey,
  deleteSurveyQuestion,
  editCurrentSurveyQuestion,
  editCurrentSurvey,
  fetchCurrentSurvey,
  replaceCurrentSurvey,
  replacePart,
  t,
}) => {
  const { bookId, versionNumber, partId } = useParams();
  const {
    setSaveFn,
    save,
    resetAutoSave
  } = useAutoSaveContext();
  const previousSurvey = usePrevious(currentSurvey);

  useEffect(() => {
    resetAutoSave();
    return () => {
      // runs when component unmounts
      clearSurvey();
    }
  }, []);

  useEffect(() => {
    if (currentSurvey?._id !== partId) {
      fetchSurvey();
    };
  }, [partId, currentSurvey?._id]);

  useEffect(() => {
    const hasChanged = !!previousSurvey?._id // a previous survey ref exists
      && (currentSurvey?._id !== previousSurvey?._id // they either have different ids...
        || currentSurvey?.title !== previousSurvey?.title // ...different titles...
        || currentSurvey?.description !== previousSurvey?.description // ...different descriptions...
        || currentSurvey?.questions.some((currentQ, index) => {
          const prevQ = (previousSurvey?.questions ?? [])[index];
          return currentQ?.question !== prevQ?.question
            || currentQ?.description !== prevQ?.description
            || currentQ?.kind !== prevQ?.kind
            || JSON.stringify(currentQ?.options) !== JSON.stringify(prevQ?.options)
        }));

    // if a saveable value has been changed, send the save function to autoSaveContext
    setSaveFn(hasChanged ? () => saveSurvey : undefined);
  }, [currentSurvey]);

  const fetchSurvey = async () => {
    console.log('change_fetchSurvey');
    if (currentBook?._id, partId) {
      const idToken = await authUtil.getFreshIdToken();
      fetchCurrentSurvey(idToken, bookId, partId);
    }
  }

  const saveSurvey = async () => {
    const idToken = await authUtil.getFreshIdToken();
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/surveys/${partId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      },
      body: JSON.stringify({
        ...currentSurvey
      })
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        }
        return res.json();
      })
      .then((updatedSurvey) => {
        replaceCurrentSurvey(updatedSurvey);
        replacePart(updatedSurvey, versionNumber);
      })
      .catch(err => console.error(err));
  }

  const handleAddQuestion = () => {
    addQuestion();
  }

  const updateQuestion = (questionData) => {
    editCurrentSurveyQuestion(questionData);
  }

  const deleteQuestion = (questionIndex) => {
    deleteSurveyQuestion(questionIndex);
  }

  const handleChangeSurveyTitle = (event, data) => {
    editCurrentSurvey({ title: data.value });
  }

  const handleChangeSurveyDescription = (event, data) => {
    editCurrentSurvey({ description: data.value });
  }

  if (!currentSurvey) {
    return null;
  }
  const { title, description, questions } = currentSurvey;
  if (questions && questions.length === 0) {
    questions.push({
      question: '',
      description: '',
      kind: 'LONG_TEXT'
    });
  }

  return (
    <div
      style={{
        flex: 1, display: 'flex', flexDirection: 'column', maxWidth: 768, padding: '0 1em 0 1em'
      }}
    >
      <Form.Input
        control={InputWithSave}
        onSave={save}
        className='text-line'
        transparent
        fluid
        size='massive'
        placeholder='Untitled Survey'
        value={title}
        onChange={handleChangeSurveyTitle}
      />
      <Form.TextArea
        control={TextAreaWithSave}
        onSave={save}
        size='small'
        placeholder='Survey Description'
        value={description}
        onChange={handleChangeSurveyDescription}
      />
      {questions && questions.map((question, index) => (
        <Question
          key={`${question._id}_${index}`}
          index={index}
          question={question}
          deleteQuestion={() => {
            deleteQuestion(index);
          }}
          updateQuestion={updateQuestion}
        />
      ))}
      <Divider horizontal hidden />
      <Button
        fluid
        basic
        onClick={handleAddQuestion}
        content='Add question'
      />
    </div>
  );
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  currentBook: state.book.currentBook,
  currentSurvey: state.survey.currentSurvey,
  surveyHasChanged: state.survey.hasChanged,
  currentContentVersion: state.book.currentContentVersion
});

const mapDispatchToProps = dispatch => bindActionCreators({
  saveSurvey,
  fetchCurrentSurvey,
  editCurrentSurvey,
  replaceCurrentSurvey,
  startNewSurvey,
  addQuestion,
  editCurrentSurveyQuestion,
  deleteSurveyQuestion,
  changeTitle,
  changeDescription,
  replacePart,
  clearSurvey,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSurvey)));
