export default {
  getFilterObject: (readerFilters = []) => {
    const cleanedFilters = readerFilters.map(filter => {
      if (Object.keys(filter).length === 0 && filter.constructor === Object) {
        // filter is empty, return null
        return {};
      } else {
        var filterObject = {};
        filterObject[filter.type] = {};
        filterObject[filter.type][filter.option] = filter.value
        return filterObject;
      }
    })
    return cleanedFilters.length === 0 ? undefined : {filter: { $and: cleanedFilters}};
  }
}
