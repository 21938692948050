import React, {useState, useEffect} from 'react';

import {
  Divider,
  Header,
  List,
  Image,
  Form,
  Icon,
  Dropdown
} from 'semantic-ui-react';
// i18n
import {Trans, useTranslation} from 'react-i18next';

// Routes
import {Link, useHistory} from 'react-router-dom';

// redux
import {useDispatch, useSelector} from 'react-redux';

import {
  fetchSwappableBook,
  resetSwappableBook,
  fetchSwappables
} from '../../modules/book';

import {createReader, fetchReaders} from '../../modules/reader';

import {authUtil, imageUtil, productCapUtil} from '../../utils';

const DEFAULT_COVER_BACKGROUND =
  'https://storage.googleapis.com/beta-reader-prod.appspot.com/defaults/DEFAULT_COVER_BACKGROUND.png';

const InviteReader = ({
  reader,
  source,

  onClose
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedBook = useSelector(state => state.book.swappableBook);
  const swappables = useSelector(state => state.book.swappables);

  const [message, setMessage] = useState('');
  const [existingEntries, setExistingEntries] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState();
  const [sendingInvitation, setSendingInvitation] = useState(false);

  useEffect(() => {
    getBooks();
    getExistingReaderEntries();
    return () => {
      dispatch(resetSwappableBook());
    };
  }, []);

  const getBooks = async () => {
    const idToken = await authUtil.getFreshIdToken();
    dispatch(fetchSwappables(idToken));
  };

  const getExistingReaderEntries = async () => {
    const idToken = await authUtil.getFreshIdToken();
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/reader-index/user/${reader._id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => {
        if (res.status === 200) {
          return res.json();
        }
        throw new Error('Failed to load reader index');
      })
      .then(user => setExistingEntries(user.existingEntries))
      .catch(err => {
        console.error(err);
      })
      .finally(() => {});
  };

  const selectBook = async bookId => {
    const idToken = await authUtil.getFreshIdToken();
    dispatch(fetchSwappableBook(idToken, bookId));
  };

  const unselectBook = event => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(resetSwappableBook());
  };

  const goToBook = book => history.push(`/books/${book._id}`);

  const inviteUserToBook = async () => {
    const idToken = await authUtil.getFreshIdToken();
    setSendingInvitation(true);

    dispatch(
      createReader(
        idToken,
        {
          bookId: selectedBook._id,
          // use the latest version if none is specified
          contentVersion:
            selectedVersion ||
            selectedBook.content[selectedBook.content.length - 1]._id,
          userId: reader._id,
          source,
          sendMail: true,
          message
        },
        success => {
          setSendingInvitation(false);
          if (success && onClose) {
            onClose();
          }
        }
      )
    );
  };

  const changeSelectedVersion = (event, data) => {
    setSelectedVersion(data.value);
  };

  const changeMessage = (event, data) => {
    setMessage(data.value);
  };

  const getCoverUrl = book =>
    book?.cover?.highRes?.url ?? DEFAULT_COVER_BACKGROUND;

  return (
    <div style={{padding: 10}}>
      <Header content={t('SelectBook')} />
      <Divider />
      <List selection verticalAlign='middle'>
        {selectedBook && (
          <List.Item
            key={selectedBook._id}
            onClick={() => goToBook(selectedBook)}>
            <Image
              src={imageUtil.getImageUrl({
                originalImageUrl: getCoverUrl(selectedBook),
                operation: 'cropfit',
                size: '100x100'
              })}
              style={{width: 50}}
            />
            <List.Content>
              <List.Header>{selectedBook.title}</List.Header>
            </List.Content>
            <List.Content floated='right'>
              <Dropdown
                inline
                direction='left'
                options={selectedBook.content.map(version => ({
                  key: version._id,
                  value: version._id,
                  text: `${t('Version')} ${version.versionNumber}`,
                  description: t('WordCountString', {
                    count: version.parts.reduce(
                      (total, part) => total + (part.wordCount || 0),
                      0
                    )
                  })
                }))}
                defaultValue={
                  selectedBook.content[selectedBook.content.length - 1]._id
                }
                value={selectedVersion}
                onChange={changeSelectedVersion}
              />
              <Icon name='close' link onClick={unselectBook} />
            </List.Content>
          </List.Item>
        )}
        {!selectedBook &&
          swappables &&
          Array.isArray(swappables) &&
          swappables.length > 0 &&
          swappables.map(book => {
            if (book.privacy === 'unpublished') {
              return null; // don't show unpublished books
            }
            const alreadyInvited = existingEntries
              .map(entry => entry.book)
              .includes(book._id);

            const canInvite = !alreadyInvited && book.canAddMoreReaders;

            return (
              <List.Item
                key={book._id}
                onClick={canInvite ? () => selectBook(book._id) : null}
                style={{opacity: canInvite ? 1 : 0.8}}>
                <List.Content>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Image
                      src={imageUtil.getImageUrl({
                        originalImageUrl: getCoverUrl(book),
                        operation: 'cropfit',
                        size: '100x100'
                      })}
                      style={{width: 50}}
                    />
                    <div>
                      <List.Header>{book.title}</List.Header>
                      <List.Description>
                        {alreadyInvited && (
                          <div>
                            {t('UserAlreadyInvited', {
                              user: reader.displayName
                            })}
                          </div>
                        )}
                        {!book.canAddMoreReaders && (
                          <div>
                            <Trans key='maxReadersLimitMessage'>
                              <div>
                                You have reached your reader limit.{' '}
                                <Link to='/products'>Upgrade</Link> to be able
                                to send and approve further invitations.
                              </div>
                            </Trans>
                          </div>
                        )}
                      </List.Description>
                    </div>
                  </div>
                </List.Content>
              </List.Item>
            );
          })}
        {!selectedBook && (!swappables || swappables.length === 0) && (
          <List.Item
            key='add-book'
            onClick={() => history.push('/mymanuscripts')}>
            <List.Content>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <div>
                  <List.Header>{t('NothingHere')}</List.Header>
                  <List.Description>
                    <Trans i18nKey='NoBookMsg'>
                      <p>There is nothing here!</p>
                      <p>
                        <span>Do you want to </span>
                        <Link to='/mymanuscripts'>
                          add your own manuscript?
                        </Link>
                      </p>
                    </Trans>
                  </List.Description>
                </div>
              </div>
            </List.Content>
          </List.Item>
        )}
      </List>
      {selectedBook && (
        <Form>
          <Form.TextArea
            label={t('Message')}
            value={message}
            placeholder={t('InvitationMessagePlaceholder')}
            onChange={changeMessage}
          />
          <Form.Button
            primary
            floated='right'
            content={t('Send')}
            onClick={inviteUserToBook}
            loading={sendingInvitation}
          />
        </Form>
      )}
    </div>
  );
};

export default InviteReader;
