import ReactGA from 'react-ga';

const trackPageView = (page) => {
  ReactGA.pageview(page);
}

const trackEvent = (eventData) => {
  ReactGA.event(eventData);
}

export default {
  trackPageView,
  trackEvent
}
