import React, { Component } from 'react';

import {
  Container,
  Header,
  Segment
} from 'semantic-ui-react';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

class TermsOfService extends Component {
  render() {
    return (
      <div>
        <Segment style={{ padding: '0em 0em 3em' }} vertical>
          <Container text>
            <Header size='large' content='Terms of Service' subheader='Last Updated October 11, 2017'/>
              By using BetaReader.io (https://betareader.io), you accept the terms and conditions noted below, as well as BetaReader.io's Privacy Policy, found at http://www.betareader.io/privacy.
              Although we may attempt to notify you of changes made to these Terms & Conditions, you should periodically review the most up-to-date version on the BetaReader.io website. BetaReader.io may, in its sole discretion, modify or revise these Terms of Service and policies at any time, and you agree to be bound by such modifications or revisions. Nothing in this Agreement shall be deemed to confer any third-party rights or benefits.
            <Header content="General"/>
              In order to access some features of BetaReader.io, you will have to create a BetaReader.io account. You may never use another user's account without their explicit permission. When creating your account, you must provide accurate and complete information. You are solely responsible for the activity that occurs on your account, and you must keep your account password secure. You must notify BetaReader.io immediately of any breach of security or unauthorized use of your account. You must not use BetaReader.io if you are under 13 years of age.
            <Header content="Authors"/>
              Authors retain all rights to works posted on BetaReader.io, and can add or remove content at their sole discretion. BetaReader.io makes no claim to any of the works posted on the site.
              Authors agree that works posted do not breach any individual's privacy, do not defame, do not plagiarise or pirate any other work or infringe any copyright or trademark, do not advocate or support any heinous acts or crimes and do not contain visual examples (images) of sexual or explicit content.
              Work posted to BetaReader.io is private by default. Work becomes visible to other individuals only when Authors choose to share it via one of the means available on the site. Authors control who can see their work, and can add or remove permissions for other individuals to view the work at any time.
              Authors assume all responsibility for regulating Reader access to their works and moderating reader behavior and feedback. BetaReader.io makes no effort to monitor or police Reader behavior or feedback.
              By posting work on BetaReader.io, Authors grant the site administrators permission to view the work as necessary to operate the site and provide technical support.
            <Header content="Readers"/>
              You may be invited by Authors to view one or more of their works on BetaReader.io. As a Reader, you agree not to copy or distribute any text shared by Authors on BetaReader.io without the Author's explicit permission.
              Readers may be removed from a book by the Author or by the site administrators for any reason at any time.
            <Header content="BetaReader.io Rights and Disclaimers"/>
              BetaReader.io has the right to remove any work and/or permanently ban any user from the site for any reason at any time.
              BetaReader.io does not monitor or police content posted to the site by Authors or Readers.
          </Container>
        </Segment>
      </div>
    )
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken
})

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: (newPage) => push(newPage)
}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsOfService));
