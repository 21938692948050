const copyItemToClipBoard = (str) => {
  // Unselect everything on the HTML document
  document.getSelection().removeAllRanges();
  // Create a <textarea> element
  let el = document.createElement('textarea', { contentEditable: true, readOnly: false });
  // Set its value to the string that you want copied
  el.value = str;
  el.style.position = 'absolute';
  // Move outside the screen to make it invisible
  el.style.left = '-9999px';
  // Append the <textarea> element to the HTML document
  document.body.appendChild(el);
  // resolve the element
  el = (typeof el === 'string') ? document.querySelector(el) : el;
  // handle iOS as a special case
  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    // save current contentEditable/readOnly status
    let editable = el.contentEditable;
    let readOnly = el.readOnly;
    // convert to editable with readonly to stop iOS keyboard opening
    el.contentEditable = true;
    el.readOnly = true;
    // create a selectable range
    let range = document.createRange();
    range.selectNodeContents(el);
    // select the range
    let selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    el.setSelectionRange(0, 999999);
    // restore contentEditable/readOnly to original state
    el.contentEditable = editable;
    el.readOnly = readOnly;
  } else {
    el.select();
  }
  // execute copy command
  document.execCommand('copy');
  // Remove the <textarea> element
  document.body.removeChild(el);
};

export default {
  copyItemToClipBoard
};
