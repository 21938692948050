import React from 'react';
import {
  Placeholder, Divider
} from 'semantic-ui-react';

const BookItemPlaceholder = () => (
  <React.Fragment>
    <Placeholder style={{ marginTop: '1em', marginBottom: '1em', width: '100%' }}>
      <Placeholder.Header image>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Header>
    </Placeholder>
    <Divider />
  </React.Fragment>
);

export default BookItemPlaceholder;
