import ReactGA from 'react-ga';
import uuidv4 from 'uuid/v4';

import firebase from '../config/fire';

export const OPENED_BOOK = 0;
export const CLOSED_BOOK = 1;
export const TIME_SPENT = 2;
export const STARTED_CHAPTER = 3;
export const COMPLETED_CHAPTER = 4;
export const COMPLETED_BOOK = 5;
export const STARTED_BOOK = 6;
export const CHAPTER_FIRST_QUARTILE = 7;
export const CHAPTER_MID_POINT = 8;
export const CHAPTER_THIRD_QUARTILE = 9;
export const ABANDONED_BOOK = 12;
export const REACHED_PERCENTILE = 13;
export const SCROLLED_UP = 100;
export const SCROLLED_DOWN = 101;

const trackingURL = '/tracker/track';

var trackingBacklog = undefined; // the tracking backlog + delay is here for the rare cases when a large part of a chapter is on screen at the same time, leading to several events being fired of simultaneously, which risks leading to several session ids issued by the backend for the conceptually same session.
const defaultTrackingDelay = 500; // wait 500 ms to collect any subsequent tracking data before pulling the trigger.
const errorRetryDelay = 1000;

export const getSessionData = userId => {
  var session;
  try {
    var storedUserData = localStorage.getItem(`br-user-${userId}`);

    if (storedUserData !== undefined) {
      var userData = JSON.parse(storedUserData);
    }
    if (userData) {
      session = userData.session;
    }
  } catch (err) {
    console.error(err);
  }
  return session;
};

export const updateSessionData = (userId, newSessionData) => {
  var userData;
  try {
    userData = JSON.parse(window.localStorage.getItem(`br-user-${userId}`));
    userData.session = newSessionData;
  } catch (err) {
    userData = {
      session: newSessionData,
    };
  }

  localStorage.setItem(`br-user-${userId}`, JSON.stringify(userData));
};

export const track = async (trackingInfo, options) => {
  if (!options) {
    options = {};
  }
  var { attempt, delay } = options;
  if (!attempt) {
    attempt = 1;
  }
  if (!delay) {
    delay = defaultTrackingDelay;
  }

  var originalTrackingInfo = { ...trackingInfo };
  var user = firebase.auth().currentUser;
  var session = user ? getSessionData(user.uid) : null;
  if (session) {
    trackingInfo = {
      ...trackingInfo,
      session,
    };
  }

  trackingInfo.data = setTimestamp(trackingInfo.data);

  if (trackingBacklog) {
    // one or more events were recently scheduled for tracking. hop on the train and wait for departure!

    trackingBacklog.data = trackingBacklog.data.concat(trackingInfo.data);
  } else {
    // no tracking events in backlog. start a new backlog and schedule departure in 500 ms!
    trackingBacklog = { ...trackingInfo }; // set the tracking backlog to be the current trackingInfo. if no more arrives before the POST, this is what we'll track.
    setTimeout(async function () {
      try {
        trackingInfo = { ...trackingBacklog }; // get everything from the trackingBacklog
        trackingBacklog = undefined; // reset the trackingBacklog
        var postURL = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}${trackingURL}`;
        var postPackage = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...trackingInfo,
          }),
          credentials: 'same-origin',
        };

        var res = await fetch(postURL, postPackage);

        if (!res.ok) {
          throw new Error(`HTTP Error ${res.status}`);
        }

        var response = await res.json();

        if (response.session) {
          updateSessionData(user.uid, response.session);
        }
        // flush the session backlog if we have had sporadically broken connection and put stuff there
        flushSessionBacklog();
      } catch (err) {
        var errorMessage = getErrorMessage({ trackingInfo, attempt, err });
        ReactGA.exception({
          description: errorMessage,
          fatal: false,
        });
        if (attempt <= 2) {
          track(trackingInfo, { attempt: attempt + 1, delay: errorRetryDelay });
        } else {
          // failed 3 tracking attempts, store in local storage for later tracking attempts
          if (user) {
            storeInSessionBacklog({ trackingInfo, user });
          }
        }
      }
    }, delay);
  }
};

export const getErrorMessage = ({ trackingInfo, attempt, err }) => {
  var failedEvents = {};
  trackingInfo.data.forEach((item, index) => {
    failedEvents[item.event.id] = true;
  });
  var eventString = Object.keys(failedEvents).toString();
  return `Failed to track ${
    trackingInfo.data.length
  } events (${eventString}) on attempt ${attempt}. Error: ${err.toString()}`;
};

export const setTimestamp = events => {
  var currentTimestamp = Date.now();
  return events.map(event => {
    event.event.timestamp = currentTimestamp;
    return event;
  });
};

export const storeInSessionBacklog = ({ trackingInfo, user }) => {
  var sessionBacklogKey = `br-session-backlog`;
  var sessionBacklog = {};
  try {
    sessionBacklog = JSON.parse(localStorage.getItem(sessionBacklogKey)) || {};
  } catch (err) {
    // console.error('err', err);
  }
  if (!sessionBacklog[user.uid]) {
    sessionBacklog[user.uid] = {};
  }
  // if there is no session, or if the session has expired, generate a new one

  generateSessionIfNeeded(trackingInfo);

  if (!sessionBacklog[user.uid][trackingInfo.session._id]) {
    // if there is no offline data stored for this session, create it
    sessionBacklog[user.uid][trackingInfo.session._id] = trackingInfo;
  } else {
    // otherwise, append the tracking data and update the session
    sessionBacklog[user.uid][trackingInfo.session._id].data = sessionBacklog[
      user.uid
    ][trackingInfo.session._id].data.concat(trackingInfo.data);
  }
  updateSessionTimestamps(
    sessionBacklog[user.uid][trackingInfo.session._id].session
  );
  updateSessionData(
    user.uid,
    sessionBacklog[user.uid][trackingInfo.session._id].session
  );

  localStorage.setItem(sessionBacklogKey, JSON.stringify(sessionBacklog));
};

export const updateSessionTimestamps = session => {
  var now = Date.now();
  var expires = now + 60000;
  session.expires = expires;
  session.updated = now;
};

// generate a new session if there is none or the old one has expired
export const generateSessionIfNeeded = trackingInfo => {
  var now = Date.now();
  var expires = now + 60000;
  if (!trackingInfo.session || trackingInfo.session.expires < now) {
    trackingInfo.session = {
      _id: uuidv4(),
      expires: expires,
      updated: now,
    };
  }
};

export const trackSessionBacklog = (sessionArray, options) => {
  var { attempt, delay } = options;
  if (!delay) {
    delay = errorRetryDelay;
  }
  var postURL = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}${trackingURL}?o=1`;
  var postPackage = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sessions: [...sessionArray],
    }),
    credentials: 'same-origin',
  };
  fetch(postURL, postPackage)
    .then(res => {
      if (!res.ok) return Promise.reject(new Error(`HTTP Error ${res.status}`));
      return res.json();
    })
    .then(response => {})
    .catch(err => {
      var errorMessage = `Failed to track offline usage (${
        sessionArray && sessionArray.length
      } sessions)`;
      ReactGA.exception({
        description: errorMessage,
        fatal: false,
      });
      if (attempt <= 2) {
        trackSessionBacklog(sessionArray, {
          attempt: attempt + 1,
          delay: errorRetryDelay,
        });
      } else {
        console.error(errorMessage);
        // failed 3 tracking attempts, abandon this for now
      }
    });
};

export const flushSessionBacklog = () => {
  var sessionBacklogKey = `br-session-backlog`;
  var sessionBacklog = {};
  try {
    sessionBacklog = JSON.parse(localStorage.getItem(sessionBacklogKey)) || {};
  } catch (err) {
    console.error('err', err);
  }
  // remove the item asap so that it won't be accidentally logged twice
  localStorage.removeItem(sessionBacklogKey);
  const sessionArray = [];
  Object.keys(sessionBacklog).forEach(userKey => {
    Object.keys(sessionBacklog[userKey]).forEach(userSessionKey => {
      sessionArray.push(sessionBacklog[userKey][userSessionKey]);
    });
  });
  // if there is anything to flush, do it
  if (sessionArray.length > 0) {
    trackSessionBacklog(sessionArray, { attempt: 1 });
  }
};
