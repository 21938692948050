import React, { useEffect } from 'react';

// i18n
import { withTranslation } from 'react-i18next';
import {
  Button,
  Divider, Icon, List
} from 'semantic-ui-react';

// Routes
import { withRouter } from 'react-router-dom';


// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { connect, useSelector, useDispatch } from 'react-redux';

import {
  fetchInboxes
} from '../../modules/manuscriptInbox';

import { authUtil, featureToggleUtil, uxAnalyticsUtil } from '../../utils';
import { PaddedWrapper } from '../../style';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const ManuscriptInboxList = ({
  match, changePage, fetchInboxes, inboxes, userProfile, t
}) => {
  useEffect(() => {
    const fetchOwnInboxes = async () => {
      const idToken = await authUtil.getFreshIdToken();
      fetchInboxes(idToken, { filter: 'own' });
    }
    fetchOwnInboxes();
    uxAnalyticsUtil.trackPageView(match.path);
  }, []);

  const goToInbox = (event) => {
    const data = event.currentTarget.dataset;
    changePage(`/manuscript-inbox/${data.inboxId}`)
  }

  return (
    <PaddedWrapper>
      {featureToggleUtil.hasFeatureAccess(userProfile, 'manuscript-inbox') && (
        <ButtonWrapper>
          <Button icon='add' primary content={t('Add')} />
        </ButtonWrapper>
      )}
      <List divided selection>
        {
          inboxes.map(inbox => (
            <List.Item
              key={inbox._id}
              data-inbox-id={inbox._id}
              onClick={goToInbox}
            >
              <Icon name='inbox' size='big' />
              <List.Content>
                <List.Header>{inbox.title}</List.Header>
                <List.Description>
                  {/* <Linkify> */}
                  {inbox.description}
                  {/* </Linkify> */}
                </List.Description>
                <List.Description>
                  <Divider horizontal />
                </List.Description>
              </List.Content>
            </List.Item>
          ))
        }
      </List>
    </PaddedWrapper>
  );
}

// redux stuff
const mapStateToProps = state => ({
  userProfile: state.user.userProfile,
  inboxes: state.manuscriptInbox.inboxes
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchInboxes,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ManuscriptInboxList)));
