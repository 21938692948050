import React, {useCallback} from 'react';

import {useTranslation, withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';

// redux
import {push} from 'connected-react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Components
import {Button} from 'semantic-ui-react';

export const SubmitManuscriptButton = ({
  currentBook,
  location,
  userProfile,
  size,
  color,

  changePage
}) => {
  const {t} = useTranslation();
  const shouldShowItem = useCallback(() => {
    return (
      currentBook?._id !== undefined &&
      currentBook?.language === 'sv' &&
      userProfile !== undefined &&
      userProfile?._id === currentBook?.author?._id &&
      location.pathname !== '/read'
    );
  }, [
    currentBook?._id,
    currentBook?.language,
    currentBook?.author,
    userProfile?._id,
    location.pathname
  ]);

  const goToManuscriptInbox = () => {
    changePage(`/manuscript-inbox/submission?bookId=${currentBook._id}`);
  };

  if (!shouldShowItem()) {
    return null;
  }

  return (
    <Button
      fluid
      compact
      size={size}
      color={color}
      content={t('Submit')}
      onClick={goToManuscriptInbox}
    />
  );
};

// redux stuff
const mapStateToProps = state => ({
  userProfile: state.user.userProfile,
  currentBook: state.book.currentBook
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: newPage => push(newPage)
    },
    dispatch
  );

export default withTranslation()(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SubmitManuscriptButton)
  )
);
