import React, {Component} from 'react';
import {withRouter, Redirect, Link} from 'react-router-dom';
import {Segment, Grid, Header, Form, Message} from 'semantic-ui-react';
import queryString from 'query-string';

// redux
import {push} from 'connected-react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

class SignIn extends Component {
  constructor(props) {
    super(props);
    const parsedQueryString = queryString.parse(this.props.location.search);
    const redirect = parsedQueryString.redirect;

    this.state = {
      redirect: redirect
    };
    const self = this;
    firebase
      .auth()
      .getRedirectResult()
      .then(function (result) {
        if (result.credential) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          // var token = result.credential.accessToken;
          // ...
        }
        // The signed-in user info.
        localStorage.removeItem('sign-in-mode');
        var user = result.user;
        self.setState({
          user: user,
          redirect: self.state.redirect || '/mymanuscripts'
        });
      })
      .catch(function (error) {
        // Handle Errors here.
        // var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        // var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // ...
        self.setState({
          errorMessage: errorMessage
        });
      });
  }

  componentDidMount() {
    const {location} = this.props.history;
    ReactGA.pageview(location.pathname + location.hash + location.search);
    this.setState({msg: undefined, errorMessage: undefined});
  }

  emailChanged = (event, data) => {
    this.setState({
      email: data.value,
      errorMessage: undefined
    });
  };

  resetPassword = () => {
    var {email} = this.state;
    const self = this;
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        self.setState({
          msg: (
            <span>
              We've sent a password reset email to {email}. Please follow the
              instructions in the email, and then{' '}
              <Link to='signin'>sign in.</Link>
            </span>
          )
        });
      })
      .catch(function (error) {
        console.log(error);
        self.setState({errorMessage: <span>{error.message}</span>});
      });
  };

  render() {
    const {redirect, msg} = this.state;
    const {user} = this.props;
    if (user !== undefined) {
      return <Redirect to={{pathname: redirect || '/profile'}} />;
    } else {
      return (
        <Grid
          textAlign='center'
          style={{height: '100%', width: '100%'}}
          verticalAlign='middle'>
          <Grid.Column style={{maxWidth: 450}}>
            {msg && <Message info>{msg}</Message>}
            {!msg && (
              <Form
                error={this.state.errorMessage !== undefined}
                onSubmit={() => {
                  this.resetPassword();
                }}>
                <Header as='h2' textAlign='center'>
                  Reset password
                </Header>
                <Segment attached={this.state.errorMessage !== undefined}>
                  <Form.Input
                    size='large'
                    fluid
                    icon='user'
                    iconPosition='left'
                    placeholder='E-mail address'
                    onChange={this.emailChanged}
                  />
                  <Form.Button primary type='submit' fluid>
                    SEND PASSWORD RESET EMAIL
                  </Form.Button>
                </Segment>
                <Message
                  error
                  attached='bottom'
                  content={this.state.errorMessage}
                />
                Remember your password? <Link to='/signin'>Sign in here</Link>.
              </Form>
            )}
          </Grid.Column>
        </Grid>
      );
    }
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: newPage => push(newPage)
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));
