import React, {useCallback, useEffect, useState} from 'react';
import {List, Dropdown, Button, Responsive} from 'semantic-ui-react';
import {Media} from 'src/utils/Media';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

const ReaderFilter = ({
  index,
  operator,
  value = [],
  removeFilter,
  addFilter,
  readerOptions = [],
  handleFilterChange
}) => {
  const {t} = useTranslation();

  const getFilterOperatorOptions = useCallback(
    () => [
      {key: 'in', text: t('Include'), value: '$in'},
      {key: 'nin', text: t('Exclude'), value: '$nin'}
    ],
    []
  );

  const getCurrentFilterOperator = useCallback(
    () => operator ?? getFilterOperatorOptions()[0].value,
    [operator]
  );
  const getCurrentFilterValue = useCallback(() => value ?? [], [value]);

  const handleReaderFilterOperatorChange = newOperator => {
    handleFilterChange({
      operator: newOperator,
      value: getCurrentFilterValue(),
      index: index
    });
  };

  const handleReaderFilterValueChange = newValue => {
    handleFilterChange({
      operator: getCurrentFilterOperator(),
      value: newValue,
      index: index
    });
  };

  return (
    <List.Item>
      <StyledListContent verticalAlign='middle'>
        <Dropdown
          labeled
          button
          compact
          inline
          options={getFilterOperatorOptions()}
          value={getCurrentFilterOperator()}
          onChange={(event, data) =>
            handleReaderFilterOperatorChange(data.value)
          }
        />
        <Dropdown
          placeholder={t('Readers')}
          multiple
          fluid
          search
          inline
          selection
          closeOnBlur
          icon={null}
          value={getCurrentFilterValue()}
          options={readerOptions}
          onChange={(event, data) => handleReaderFilterValueChange(data.value)}
        />
        <Media greaterThan='mobile'>
          {(className, renderChildren) =>
            renderChildren && (
              <ButtonContainer>
                <Button
                  size='mini'
                  basic
                  circular
                  icon='minus'
                  onClick={() => removeFilter(index)}
                />
                <Button
                  size='mini'
                  basic
                  circular
                  icon='plus'
                  onClick={() => addFilter(index)}
                />
              </ButtonContainer>
            )
          }
        </Media>
        <Media at='mobile'>
          {(className, renderChildren) =>
            renderChildren && (
              <ButtonContainer
                icon='ellipsis vertical'
                className='link item'
                direction='left'>
                <Dropdown>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      icon='minus'
                      text='remove rule'
                      onClick={() => removeFilter(index)}
                    />
                    <Dropdown.Item
                      icon='plus'
                      text='add rule'
                      onClick={() => addFilter(index)}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </ButtonContainer>
            )
          }
        </Media>
      </StyledListContent>
    </List.Item>
  );
};

const StyledListContent = styled(List.Content)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonContainer = styled.div`
  margin-left: auto;
  white-space: nowrap;
  padding-left: 5px;
`;

export default ReaderFilter;
