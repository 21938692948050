import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Container, Loader, Grid, Message
} from 'semantic-ui-react';
import queryString from 'query-string';
// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import CommentsList from '../../comment/commentsList';
import FeedbackFilter from './feedbackFilter';
import { uxAnalyticsUtil, authUtil } from '../../../utils';
import {
  fetchComments,
  fetchMoreComments,
  clearCommentList
} from '../../../modules/comment';
import LoadMore from '../../../components/lists/loadMore';

const limit = 50;
const lastSupport = true;

class Feedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notes: [],
      ratings: [],
      statistics: [],
      isLoading: false,
      search: undefined
    };
  }

  componentDidMount() {
    const { match } = this.props;
    uxAnalyticsUtil.trackPageView(match.path);
    this.fetchComments();
  }

  componentDidUpdate(prevProps, prevState) {
    const { sort, search } = this.state;
    if (sort !== prevState.sort || search !== prevState.search) {
      this.fetchComments();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { location } = nextProps;
    if (!location) {
      return;
    }
    const query = queryString.parse(location.search);
    const { search } = query;
    if (prevState.search !== search) {
      return {
        ...prevState,
        search
      }
    };
    return {
      ...prevState
    };
  }

  fetchComments = async () => {
    const {
      user, match
    } = this.props;
    const idToken = await authUtil.getFreshIdToken();
    const { bookId } = match.params;
    const { search, sort = 'desc' } = this.state;
    if (idToken !== undefined && user !== undefined) {
      this.props.fetchComments(idToken, bookId, undefined, {
        sort, search, limit, lastSupport
      });
    }
  }

  fetchMoreComments = async () => {
    const {
      user, match, comments
    } = this.props;
    const idToken = await authUtil.getFreshIdToken();
    const { bookId } = match.params;
    const { search, sort = 'desc' } = this.state;
    if (idToken !== undefined && user !== undefined) {
      this.props.fetchMoreComments(idToken, bookId, undefined, {
        sort, search, limit, skip: comments.length, lastSupport
      });
    }
  }

  renderNoNotesMessage() {
    const { currentBook, user } = this.props;
    const isAuthor = currentBook.author._id === user.uid;
    const message = isAuthor ? (
      <p>You haven't received any feedback yet. Why don't you <Link to={`/books/${currentBook.id}/readers`}> invite</Link> some more readers?</p>
    ) : (
      <p>You haven't left any feedback yet. You can leave feedback after you've finished reading a <Link to={`/books/${currentBook._id}#overview`}>chapter</Link>.</p>
    )
    return (
      <Grid.Row columns={1}>
        <Message info>
          {message}
        </Message>
      </Grid.Row>
    )
  }

  render() {
    const {
      isLoading, search
    } = this.state;
    const { book, comments } = this.props;
    if (!isLoading) {
      return (
        <Container textAlign='center'>
          <Grid centered stackable columns={1}>
            <Grid.Row>
              <Grid.Column>
                <FeedbackFilter book={book} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <CommentsList
                  search={search}
                  sort='desc'
                  disableLeavingComments
                  enableLeavingReplies
                  showChapterLinks
                />
                {
                  comments?.length > 0 && !comments[comments.length - 1].isLast && (
                    <LoadMore
                      onLoadMore={this.fetchMoreComments}
                      loading={isLoading}
                    />
                  )
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      )
    }
    return (
      <Container>
        <Loader active inline='centered' />
      </Container>
    )
  }
}


// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  currentBook: state.book.currentBook,
  comments: state.comment.comments
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchComments,
  fetchMoreComments,
  clearCommentList
}, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Feedback));
