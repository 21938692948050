import React, { Component } from 'react';
import { Form, Icon, Grid } from 'semantic-ui-react'

export class MultipleChoiceAnswer extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillUnmount() {
    if (this.props && this.props.clearSurvey) {
      this.props.clearSurvey();
    }
  }

  render() {
    const {question, answer} = this.props;
    return (
      <Grid columns={3} stackable>
        {
          (!question.options || !question.options.options) ? null : question.options.options.map((option, index) => {
            const key = typeof option === 'string' ? option : option.key;
            const value = typeof option === 'string' ? option : option.value;
            return (
              <Grid.Column
                key={index}>
                <Form.Field
                  style={{cursor: 'pointer'}}
                  onClick={() => {this.props.onChange(key)}}
                  onKeyDown={(event) =>{this.handleKeyDown(event, key)}}
                  >
                    <Icon name={key === answer.answer ? 'circle' : 'circle outline'} style={{marginRight: '1em'}}/>
                    <span>
                      {value}
                    </span>
                  </Form.Field>
              </Grid.Column>
            );
          })
        }
      </Grid>
    );
  }

}

export default MultipleChoiceAnswer
