import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import account from './account';
import user from './user';
import book from './book';
import betaOpportunities from './betaOpportunities';
import feed from './feed';
import comment from './comment';
import app from './app';
import manuscriptInbox from './manuscriptInbox';
import message from './message';
import monitor from './monitor';
import notification from './notification';
import reader from './reader';
import readerApp from './readerApp';
import readerBonus from './readerBonus';
import survey from './survey';
import changeLog from './changeLog';
import bookPost from './bookPost';
import readerIndex from './readerIndex';
import report from './report';
import review from './review';
import subscription from './subscription';

const history = createBrowserHistory();

export default combineReducers({
  router: connectRouter(history),
  account,
  app,
  betaOpportunities,
  reader,
  user,
  book,
  feed,
  comment,
  manuscriptInbox,
  message,
  monitor,
  notification,
  readerApp,
  readerIndex,
  survey,
  changeLog,
  bookPost,
  report,
  readerBonus,
  review,
  subscription
});
