import React, {Component} from 'react';
import {Dropdown, Grid, Form, Responsive} from 'semantic-ui-react';
import {Media} from 'src/utils/Media';
import {Slider} from 'react-semantic-ui-range';
import '../../../../App.css';

// i18n
import {withTranslation} from 'react-i18next';

const fromScaleOptions = [
  {
    key: 0,
    value: 0,
    text: 0
  },
  {
    key: 1,
    value: 1,
    text: 1
  }
];

const toScaleOptions = [];
const maxToScale = 10;
for (let i = 2; i <= maxToScale; i += 1) {
  toScaleOptions.push({
    key: i,
    value: i,
    text: i
  });
}

const defaultOptions = {
  minValue: 0,
  maxValue: 5,
  minLabel: '',
  maxLabel: ''
};

class LinearScaleOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minValue: props.options
        ? props.options.minValue
        : defaultOptions.minValue,
      maxValue: props.options ? props.options.maxValue : defaultOptions.maxValue
    };
  }

  componentDidMount() {
    const {options} = this.props;
    this.props.onOptionsChanged(options ? {...options} : {...defaultOptions});
  }

  handleMinValueChanged = (event, data) => {
    const {options} = this.props;
    const updatedOptions = options ? {...options} : {...defaultOptions};
    updatedOptions.minValue = data.value;
    this.props.onOptionsChanged(updatedOptions);
  };

  handleMaxValueChanged = (event, data) => {
    const {options} = this.props;
    const updatedOptions = options ? {...options} : {...defaultOptions};
    updatedOptions.maxValue = data.value;
    this.props.onOptionsChanged(updatedOptions);
  };

  handleMinLabelChanged = (event, data) => {
    const {options} = this.props;
    const updatedOptions = options ? {...options} : {...defaultOptions};
    updatedOptions.minLabel = data.value;
    this.props.onOptionsChanged(updatedOptions);
  };

  handleMaxLabelChanged = (event, data) => {
    const {options} = this.props;
    const updatedOptions = options ? {...options} : {...defaultOptions};
    updatedOptions.maxLabel = data.value;
    this.props.onOptionsChanged(updatedOptions);
  };

  render() {
    const {options, t} = this.props;

    if (!options) {
      return null;
    }

    return (
      <Grid columns='equal'>
        <Grid.Row style={{paddingBottom: 0}}>
          <Grid.Column floated='left' textAlign='left' verticalAlign='top'>
            <Dropdown
              options={fromScaleOptions}
              defaultValue={1}
              value={options.minValue}
              onChange={this.handleMinValueChanged}
            />
          </Grid.Column>
          <Grid.Column floated='right' textAlign='right' verticalAlign='top'>
            <Dropdown
              inline
              options={toScaleOptions}
              defaultValue={5}
              value={options.maxValue}
              onChange={this.handleMaxValueChanged}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Slider
              color='black'
              inverted={false}
              settings={{
                start: options.minValue,
                min: options.minValue,
                max: options.maxValue,
                step: 1
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{paddingTop: 0}}>
          <Grid.Column floated='left' textAlign='left' verticalAlign='top'>
            <Form.Input
              className='text-line'
              transparent
              value={options.minLabel}
              placeholder={t('LabelOptional')}
              onChange={this.handleMinLabelChanged}
            />
          </Grid.Column>
          <Media at='tablet'>{() => <Grid.Column />}</Media>
          <Grid.Column floated='right' textAlign='right' verticalAlign='top'>
            <Form.Input
              style={{textAlign: 'right!important'}}
              className='text-line'
              transparent
              value={options.maxLabel}
              placeholder={t('LabelOptional')}
              onChange={this.handleMaxLabelChanged}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default withTranslation()(LinearScaleOptions);
