import React, { Component } from 'react';
import {
  Icon
} from 'semantic-ui-react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';

// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// pop-ups
import { toast } from 'react-toastify';

// i18n
import { withTranslation, Trans } from 'react-i18next';

import SharingWidget from '../../components/sharing/sharingWidget';

import copyToClipBoardUtil from '../../utils/copyToClipBoardUtil';

import {
  appendToReaderBonusList
} from '../../modules/readerBonus';

export class ShareAuthorApp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // custom methods
  copyURLToClipBoard = (event, data) => {
    const { userProfile } = this.props;

    const sharingUrl = this.getSharingUrl(userProfile);
    copyToClipBoardUtil.copyItemToClipBoard(sharingUrl);
    toast.info(
      <span>
        <Icon name='linkify' />
        {`Copied '${sharingUrl}' to the clipboard.`}
      </span>
    );
  }

  getSharingUrl = (userProfile) => {
    const sharingUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/signup?invitationToken=${userProfile.refererToken}`;
    return sharingUrl;
  }

  render() {
    const {
      user, userProfile, t, idToken
    } = this.props;
    if (user !== undefined && userProfile !== undefined) {
      const sharingUrl = `${this.getSharingUrl(userProfile)}&${queryString.stringify({ utm_campaign: 'share-author-app', utm_source: 'link', utm_medium: 'button' })}`;
      const sharingMessage = t('referralMessage');
      const sharingBody = t('referralBody', { x: sharingUrl });
      const emailSuccessMessage = (
        <Trans i18nKey='ShareAuthorAppEmailSuccessMessage'>
          Invitation sent successfully. Check their status <Link to='/account/reader-bonus'>here</Link>.
        </Trans>
      );
      const emailSharingEndpoint = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/share/share-app`;
      return (
        <SharingWidget
          url={sharingUrl}
          utmCampaign='share-author-app'
          message={sharingMessage}
          body={sharingBody}
          shareTo={['facebook', 'twitter', 'clipboard', 'email-address']}
          hashtags={['amwriting', 'betareaders', 'writingcommunity']}
          buttonSize='small'
          emailSuccessMessage={emailSuccessMessage}
          emailSharingEndpoint={emailSharingEndpoint}
          emailSuccessMethod={this.props.appendToReaderBonusList}
          idToken={idToken}
        />
      );
    }
    return null;
  }
}

// redux stuff
const mapStateToProps = state => ({
  idToken: state.user.idToken
});

const mapDispatchToProps = dispatch => bindActionCreators({
  appendToReaderBonusList
}, dispatch);

export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareAuthorApp));
