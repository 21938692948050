import React, { useEffect, useCallback, useState } from 'react';
import {
  Message, Button, Input, Segment, Dimmer
} from 'semantic-ui-react';

import SurveyResults from '../../../content/survey/surveyResults';

import {
  fetchSurveyData
} from '../../../../modules/report';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { authUtil } from '../../../../utils';

const SurveyDataReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentBook = useSelector(state => state.book.currentBook);
  const surveyData = useSelector(state => state.report.surveyData);

  const fetchingReadingData = useSelector(state => state.report.fetchingReadingData);
  const readerFilters = useSelector(state => state.report.readerFilters);
  const versionFilter = useSelector(state => state.report.versionFilter);

  const [questionTitleFilter, setQuestionTitleFilter] = useState('');

  const getSurveyResults = useCallback(() => {
    if (surveyData) {
      console.log('surveyData', surveyData);
      return surveyData.map(version => version.surveyData?.map(survey => (
        <SurveyResults
          style={{ marginBottom: '3em' }}
          versionNumber={version.versionNumber}
          key={survey.surveyId}
          bookId={currentBook._id}
          surveyId={survey.surveyId}
          answers={survey.answers}
          questionTitleFilter={questionTitleFilter}
        />
      )))
    } else {
      return null;
    }
  }, [surveyData, questionTitleFilter, currentBook]);

  useEffect(() => {
    if (!!currentBook) {
      fetchReport();
    }
  }, [currentBook, readerFilters, versionFilter]);

  const fetchReport = async (type) => {
    const idToken = await authUtil.getFreshIdToken();
    if (!idToken) return;
    dispatch(fetchSurveyData(idToken, currentBook._id, versionFilter, readerFilters, type));
  }

  const downloadReport = async () => {
    fetchReport('csv');
  }

  const handleQuestionFilterChange = (event, data) => {
    setQuestionTitleFilter(data.value);
  }

  if (fetchingReadingData) {
    return (
      <Dimmer.Dimmable as={Segment} dimmed />
    )
  }

  if (!surveyData || !(surveyData?.length > 0)) {
    return (
      <Message>
        {t('NoAnswer')}
      </Message>
    )
  }

  return (
    <React.Fragment>
      <Input
        size='small'
        placeholder={t('FindASpecificQuestion')}
        icon='filter'
        onChange={handleQuestionFilterChange}
      />
      <Button basic floated='right' icon='download' onClick={downloadReport} />
      {getSurveyResults()}
    </React.Fragment>
  );
}

export default SurveyDataReport;
