import React from 'react';

export const getReactionEmoji = (reactionType) => {
  switch (reactionType) {
    case 'LIKE':
      return String.fromCodePoint(0x1F44D);
    case 'LOVE':
      return String.fromCodePoint(0x2764);
    case 'LAUGH':
      return String.fromCodePoint(0x1F602);
    case 'SURPRISE':
      return String.fromCodePoint(0x1F62E);
    case 'SAD':
      return String.fromCodePoint(0x1F625);
    case 'ANGRY':
      return String.fromCodePoint(0x1F621);
    case 'COMMENT':
    default:
      return String.fromCodePoint(0x1F4AC);
  }
};

export const getReaction = (reactionType) => {
  switch (reactionType) {
    case 'LIKE':
      return <div className='reactionIcon'>{String.fromCodePoint(0x1F44D)}</div>;
    case 'LOVE':
      return <div className='reactionIcon reaction-heart'>{String.fromCodePoint(0x2764)}</div>;
    case 'LAUGH':
      return <div className='reactionIcon'>{String.fromCodePoint(0x1F602)}</div>;
    case 'SURPRISE':
      return <div className='reactionIcon'>{String.fromCodePoint(0x1F62E)}</div>;
    case 'SAD':
      return <div className='reactionIcon'>{String.fromCodePoint(0x1F625)}</div>;
    case 'ANGRY':
      return <div className='reactionIcon'>{String.fromCodePoint(0x1F621)}</div>;
    case 'COMMENT':
    default:
      return <div className='reactionIcon'>{String.fromCodePoint(0x1F4AC)}</div>;
  }
};

export default { getReaction, getReactionEmoji };
