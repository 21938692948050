import React, { useCallback, useState, useEffect } from 'react';
import { useRouteMatch, withRouter, matchPath, useLocation } from 'react-router-dom';
import {
  List, Icon, Dropdown, Ref, Dimmer
} from 'semantic-ui-react';

// pop-ups
import { toast } from 'react-toastify';

import styled from 'styled-components';

// i18n
import { withTranslation } from 'react-i18next';

// drag and drop
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  deletePart,
  duplicatePart,
  movePart,
  updatePart,
  addChapters,
  toggleChapterUpload,
  updateContentVersion,
} from '../../modules/book';
import {
  updateSpineIndex
} from '../../modules/readerApp';

import {
  createBookPost
} from '../../modules/bookPost';

import ChapterUploadModal from '../content/chapter/upload/chapterUploadModal';

import ContentUpdateConfirmationModal from '../content/contentUpdateConfirmationModal';

import accessUtil from '../../utils/accessUtil';
import authUtil from '../../utils/authUtil';

const PartListContainer = styled.div`
  height: 100%;
`;

export const PartsList = ({
  currentContentVersion,
  currentBook,
  currentSpineIndex,
  partIcons,
  showChapterUpload,
  inverted,
  size,

  changePage,
  createBookPost,
  deletePart,
  duplicatePart,
  updatePart,
  updateSpineIndex,
  t,
  toggleChapterUpload,
  updateContentVersion,
}) => {
  const [localContentVersion, setLocalContentVersion] = useState();
  const [movingPart, setMovingPart] = useState();
  const [confirmMoveOpen, setConfirmMoveOpen] = useState(false);
  const location = useLocation();
  const match = matchPath(location.pathname, location.pathname === '/read' ? {
    path: '/read'
  } : {
    path: '/books/:bookId/content/v:versionNumber/:partId'
  });
  // set localcontentversion whenever the prop changes
  useEffect(() => {
    setLocalContentVersion(currentContentVersion);
  }, [currentContentVersion]);

  useEffect(() => {
    if (!movingPart) {
      return;
    }
    const { affectedReaders } = movingPart;
    if (!affectedReaders || affectedReaders.length === 0) {
      // no readers affected, move without awaiting confirmation
      _movePart();
    } else {
      setConfirmMoveOpen(true);
    }
  }, [movingPart, confirmMoveOpen]);

  const editable = useCallback(() => accessUtil.isAllowed(currentBook?.access, ['edit']), [currentBook?.access]);

  if (!currentBook) {
    return null;
  }

  const handleDeletePartClick = async (event, data) => {
    event.preventDefault();
    event.stopPropagation();
    const { partId } = event.currentTarget.dataset;
    const idToken = await authUtil.getFreshIdToken();
    deletePart(idToken, currentBook, partId, localContentVersion);
  }
  const handleDuplicatePartClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { partId } = event.currentTarget.dataset;
    const idToken = await authUtil.getFreshIdToken();
    duplicatePart(idToken, currentBook, partId, localContentVersion);
  }
  const handlePartStartNotificationClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { partIndex } = event.currentTarget.dataset;
    const idToken = await authUtil.getFreshIdToken();
    const part = {
      ...localContentVersion.parts[parseInt(partIndex)]
    };
    if (part) {
      part.notifyWhenStarted = !part.notifyWhenStarted
      updatePart(idToken, currentBook, part);
    }
  }
  const handlePartCompleteNotificationClick = async (event, data) => {
    event.preventDefault();
    event.stopPropagation();
    const { partIndex } = event.currentTarget.dataset;
    const idToken = await authUtil.getFreshIdToken();
    const part = {
      ...localContentVersion.parts[parseInt(partIndex)]
    };
    if (part) {
      part.notifyWhenCompleted = !part.notifyWhenCompleted
      updatePart(idToken, currentBook, part);
    }
  }
  const getPartMenu = (part, index) => {
    return (
      <List.Content className='part-menu'>
        <Dropdown icon='ellipsis vertical' basic className='part-menu-dropdown' direction='left'>
          <Dropdown.Menu>
            {
              part.kind === 'Chapter'
              && [
                <Dropdown.Item
                  className='start-notification'
                  key='startNotification'
                  content={
                    (
                      <span>
                        <Icon color={part.notifyWhenStarted ? 'green' : 'black'} name={part.notifyWhenStarted ? 'toggle on' : 'toggle off'} />
                        {t('NotifyMe')}
                        {' '}
                        {t('WhenReadersStart')}
                      </span>
                    )
                  }
                  data-book-id={currentBook._id}
                  data-part-kind={part.kind}
                  data-part-id={part._id}
                  data-part-index={index}
                  onClick={handlePartStartNotificationClick}
                />,
                <Dropdown.Item
                  key='completeNotification'
                  content={(
                    <span>
                      <Icon color={part.notifyWhenCompleted ? 'green' : 'black'} name={part.notifyWhenCompleted ? 'toggle on' : 'toggle off'} />
                      {t('NotifyMe')}
                      {' '}
                      {t('WhenReadersComplete')}
                    </span>
                  )}
                  data-book-id={currentBook._id}
                  data-part-kind={part.kind}
                  data-part-id={part._id}
                  data-part-index={index}
                  onClick={handlePartCompleteNotificationClick}
                />
              ]
            }
            {
              part.kind === 'Survey' && accessUtil.isAllowed(currentBook.access, ['edit']) && [
                <Dropdown.Item
                  key='duplicate'
                  content={(
                    <span>
                      <Icon name='clone' />
                      {t('Duplicate')}
                    </span>
                  )}
                  data-book-id={currentBook._id}
                  data-part-kind={part.kind}
                  data-part-id={part._id}
                  data-part-index={index}
                  onClick={handleDuplicatePartClick}
                />
              ]
            }
            {
              accessUtil.isAllowed(currentBook.access, ['edit']) && [
                <Dropdown.Item
                  key='delete'
                  content={(
                    <span>
                      <Icon name='trash alternate' color='red' />
                      {t('Delete')}
                    </span>
                  )}
                  data-book-id={currentBook._id}
                  data-part-kind={part.kind}
                  data-part-id={part._id}
                  data-part-index={index}
                  onClick={handleDeletePartClick}
                />
              ]
            }
          </Dropdown.Menu>
        </Dropdown>
      </List.Content>
    )
  }
  const getPartContent = (part) => {
    return (
      <List.Content>
        <List.Header
          style={{
            maxWidth: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'
          }}
        >
          {part.title}
        </List.Header>
        <List.Description>
          {part.kind === 'Chapter'
            && (
              <span>
                {t('WordCountString', { count: part.wordCount })}
              </span>
            )
          }
          {part.kind === 'Survey' && part.questions
            && (
              <span>
                {t('QuestionCountString', { count: part.questions.length })}
              </span>
            )
          }
        </List.Description>
      </List.Content>
    );
  }

  const getPartsList = () => {
    // render reader view partslist
    if (currentBook?.parts?.length > 0 && location?.pathname === '/read') {
      return (
        currentBook.parts.map((part, index) => (
          <List.Item
            key={part._id}
            active={index === currentSpineIndex}
            onClick={() => {
              // scroll to top and update spine index
              document.getElementById('main').scrollTop = 0;
              updateSpineIndex(index);
            }}
          >
            <table width='100%'>
              <tbody>
                <tr style={{ whiteSpace: 'nowrap' }}>
                  <td style={{ textAlign: 'left' }}>
                    <Icon name={partIcons[part.kind]} size='large' />
                  </td>
                  <td style={{ textAlign: 'left', width: '100%' }}>
                    {getPartContent(part)}
                  </td>
                </tr>
              </tbody>
            </table>
          </List.Item>
        ))
      );
    }
    if (!currentBook || !currentContentVersion) return null;
    // edit view partslist, we have a content version, go ahead and list its parts
    return (
      currentContentVersion.parts.map((part, index) => (
        <Draggable
          draggableId={part._id}
          index={index}
          key={part._id}
        >
          {provided => (
            <Ref innerRef={provided.innerRef}>
              <List.Item
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                key={part._id}
                active={match?.params?.partId === part._id}
                onClick={() => {
                  // scroll to top and go to part
                  document.getElementById('main').scrollTop = 0;
                  const newPage = `/books/${currentBook._id}/content/v${currentContentVersion.versionNumber}/${part._id}`;
                  changePage(newPage);
                }}
              >
                <table width='100%'>
                  <tbody>
                    <tr style={{ whiteSpace: 'nowrap' }}>
                      <td style={{ textAlign: 'left' }}>
                        <Icon name={partIcons[part.kind]} size='large' />
                      </td>
                      <td style={{ textAlign: 'left', width: '100%' }}>
                        {getPartContent(part)}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {getPartMenu(part, index)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </List.Item>
            </Ref>
          )}
        </Draggable>
      ))
    );
  }

  const getAffectedReaders = async (partId, from, to) => {
    const idToken = await authUtil.getFreshIdToken();
    return fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${currentBook._id}/content/${localContentVersion._id}/affected-readers`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      },
      body: JSON.stringify({ partId, from, to })
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Failed to delete chapter');
      });
  }

  const onDragEnd = (data) => {
    const { source, destination, draggableId } = data;
    if (!source || !destination) {
      // abort
      return;
    }
    prepareToMovePart(draggableId, source.index, destination.index);
  };

  const prepareToMovePart = async (partId, from, to) => {
    const part = localContentVersion.parts[from];
    try {
      const affectedReaders = await getAffectedReaders(partId, from, to);
      setMovingPart({
        title: part.title,
        partId,
        from,
        to,
        affectedReaders
      });
    } catch (e) {
      toast.error('Failed to move part', e);
    }
  }
  const cancelMovePart = () => {
    setConfirmMoveOpen(false);
    setMovingPart(false);
  }
  const _movePart = async (updateText) => {
    const idToken = await authUtil.getFreshIdToken();
    // clone the parts list
    const newPartsList = [...localContentVersion.parts];
    // get the element to move
    const part = newPartsList[movingPart.from];
    // remove it from its original position
    newPartsList.splice(movingPart.from, 1);
    // put it in its new position
    newPartsList.splice(movingPart.to, 0, part);
    updateContentVersion(idToken, {
      bookId: currentBook._id,
      contentVersionId: localContentVersion._id,
      parts: newPartsList,
      bookPost: {
        text: updateText
      }
    }, () => {
      // create update message
      if (updateText) {
        createBookPost(idToken, currentBook._id, { text: updateText });
      }
      toast.info(t('MovedXFromYtoZ', { x: movingPart?.title, y: movingPart?.from + 1, z: movingPart?.to + 1 }));
      setConfirmMoveOpen(false);
      setMovingPart(false);
    });

    setLocalContentVersion({
      ...localContentVersion,
      parts: newPartsList
    });
  }

  const partsList = getPartsList();
  const addPart = localContentVersion && editable && (
    <List.Content floated='right'>
      <Dropdown text={t('Add')} direction='left'>
        <Dropdown.Menu>
          <Dropdown.Item content={<span><Icon name='upload' />{t('UploadChapters')}</span>} onClick={() => toggleChapterUpload(true)} />
          <Dropdown.Item content={<span><Icon name={partIcons['Chapter']} />{t('NewChapter')}</span>} data-book-id={currentBook._id} onClick={() => { changePage(`/books/${currentBook._id}/content/v${localContentVersion.versionNumber}/new-chapter`) }} />
          <Dropdown.Item content={<span><Icon name={partIcons['Survey']} />{t('NewSurvey')}</span>} data-book-id={currentBook._id} onClick={() => { changePage(`/books/${currentBook._id}/content/v${localContentVersion.versionNumber}/new-survey`) }} />
        </Dropdown.Menu>
      </Dropdown>
    </List.Content>
  );
  if (partsList) {
    return (
      <PartListContainer>
        {
          partsList.length > 10 && accessUtil.isAllowed(currentBook.access, ['edit']) && (
            <List
              key='addparttop'
              size={size}
              inverted={inverted}
              selection
              verticalAlign='middle'
            >
              <List.Item key='new-part-bottom'>
                {addPart}
              </List.Item>
            </List>
          )
        }
        <DragDropContext key='drag-drop-context' onDragEnd={onDragEnd}>
          <Droppable droppableId='partlist'>
            {provided => (
              <Ref innerRef={provided.innerRef}>
                <List
                  inverted={inverted}
                  size={size}
                  key='partsList'
                  selection
                  verticalAlign='middle'
                  {...provided.droppableProps}
                >
                  {partsList}
                </List>
              </Ref>
            )}
          </Droppable>
        </DragDropContext>
        {
          accessUtil.isAllowed(currentBook.access, ['edit']) && (
            <List
              key='addpartbottom'
              size={size}
              inverted={inverted}
              selection
              verticalAlign='middle'
            >
              <List.Item key='new-part-bottom'>
                {addPart}
              </List.Item>
            </List>
          )
        }
        {
          showChapterUpload && (
            <ChapterUploadModal
              key='uploadModal'
              open
              onClose={() => toggleChapterUpload(false)}
              toggleOpen={() => toggleChapterUpload(true)}
            />
          )
        }
        {
          !!movingPart && confirmMoveOpen && (
            <ContentUpdateConfirmationModal
              key='confirm-move-part'
              open
              affectedReaders={movingPart.affectedReaders}
              updateText={movingPart && t('MovedXFromYtoZ', { x: movingPart.title, y: movingPart.from + 1, z: movingPart.to + 1 })}
              onCancel={cancelMovePart}
              onConfirm={_movePart}
              cancelButton={t('No')}
              confirmButton={t('Yes')}
            />
          )
        }
        {
          !!movingPart && (
            <Dimmer key='dimmer' inverted={!inverted} active />
          )
        }
      </PartListContainer>
    );
  }
  return null;
}


// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  currentBook: state.book.currentBook,
  showChapterUpload: state.book.showChapterUpload,
  pendingChapters: state.book.pendingChapters,
  currentContentVersion: state.book.currentContentVersion,
  chapterUploadMessage: state.book.chapterUploadMessage,
  partIcons: state.app.partIcons,
  currentSpineIndex: state.readerApp.currentSpineIndex
});

const mapDispatchToProps = dispatch => bindActionCreators({
  deletePart,
  duplicatePart,
  movePart,
  updatePart,
  addChapters,
  toggleChapterUpload,
  updateContentVersion,
  createBookPost,
  updateSpineIndex,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PartsList)));
