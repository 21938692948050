import React from 'react';
import {
  Button, Header, Modal, Icon, Grid, Divider
} from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

const SupportModal = ({
  t, onClose, open, onStartSupportChat, signedIn, mail = 'support@betareader.io'
}) => (
  <Modal id='support-modal' open={open} closeIcon onClose={onClose} size='tiny'>
    <Modal.Header>{t('Support')}</Modal.Header>
    <Modal.Content>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={2}>
            <Icon name='help' color='grey' size='big' />
          </Grid.Column>
          <Grid.Column width={14}>
            <Modal.Description>
              <Header>{t('HelpArticles')}</Header>
              <Trans i18nKey='FindHelpArticlesMessage'>
                <span style={{ fontSize: '1em' }}>
                  Need help? check out our
                  <a href='https://betareader.io/help/' target={'blank'}>
                  {' '}
                  help
                  {' '}
                </a>
                section, full of help articles to guide you.
                </span>
              </Trans>
            </Modal.Description>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column width={2}>
            <Icon name='chat' color='grey' size='big' />
          </Grid.Column>
          <Grid.Column width={14}>
            <Modal.Description>
              <Header>{t('TalkToSupport')}</Header>
              {
                signedIn && [
                  <Button fluid key='chat-button' id='support-modal-chat-button' icon labelPosition='left' onClick={onStartSupportChat}>
                    <Icon name='arrow circle right' />
                    {t('StartSupportChat')}
                  </Button>,
                  <Divider key='or-divider' horizontal>{t('Or')}</Divider>
                ]
              }
              <Trans i18nKey='EmailSupportMessage'>
                <span style={{ fontSize: '1em' }}>
                  Send an email to <a href={`mailto:${mail}`}>{{ mail }}</a>, and we will answer as soon as we can.
                </span>
              </Trans>
            </Modal.Description>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Modal.Content>
  </Modal>
);

export default withTranslation()(SupportModal);
