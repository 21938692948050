import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Modal, Dimmer, Button, Loader, Card, Message
} from 'semantic-ui-react';

import { withTranslation, Trans } from 'react-i18next';

// pop-ups
import { toast } from 'react-toastify';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  changeSubscription
} from '../../../modules/subscription';

import {
  getUserProfile
} from '../../../modules/user';
import uxAnalyticsUtil from '../../../utils/uxAnalyticsUtil';
import ProductCard from './productCard';

let refreshUserProfileTimer;
let refreshedUserProfileCount = 0;

export class ChangeSubscriptionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changingSubscription: false
    };
  }

  componentDidMount() {
    refreshedUserProfileCount = 0;
  }

  componentDidUpdate = (prevProps) => {
    const { userProfile } = this.props;
    if (prevProps.userProfile) {
      const previousPricingPlanId = prevProps.userProfile.account
      && prevProps.userProfile.account.subscription
      && prevProps.userProfile.account.subscription.pricingPlan
      && prevProps.userProfile.account.subscription.pricingPlan.externalId;
      const currentPricingPlanId = userProfile.account
      && userProfile.account.subscription
      && userProfile.account.subscription.pricingPlan
      && userProfile.account.subscription.pricingPlan.externalId;
      // check if we moved plan
      if (previousPricingPlanId !== currentPricingPlanId) {
        this.close();
      }
    }
  }

  changeSubscription = () => {
    const {
      idToken, userProfile, t, newPricingPlanId
    } = this.props;
    this.setState({
      changingSubscription: true
    }, () => {
      this.props.changeSubscription(idToken, userProfile.account.subscription._id, newPricingPlanId, (response) => {
        if (!response.ok) {
          toast.error(t('SubscriptionChangeError'));
          uxAnalyticsUtil.trackException({
            description: t('SubscriptionChangeError'),
            fatal: true
          });
          this.setState({ changingSubscription: false });
        } else {
          this.onSuccessfulChange();
        }
      });
    });
  }

  onSuccessfulChange = () => {
    // repeat with the interval of 1.5 seconds
    const self = this;
    refreshUserProfileTimer = setInterval(() => {
      if (refreshedUserProfileCount < 3) {
        refreshedUserProfileCount += 1;
        const { idToken } = this.props;
        self.props.getUserProfile(idToken);
      } else {
        self.close();
      }
    }, 1500);
  }

  close = () => {
    const { idToken } = this.props;
    this.props.getUserProfile(idToken);
    clearInterval(refreshUserProfileTimer);
    refreshUserProfileTimer = 0;
    this.setState({ changingSubscription: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    const {
      open, t, newPricingPlanId, newProductId, products, planIntervalFilter
    } = this.props;
    const { changingSubscription } = this.state;
    const newProduct = products.find(product => product._id === newProductId);
    let newPricingPlan;
    if (newProduct) {
      newPricingPlan = newProduct.pricingPlans.filter(pricingPlan => pricingPlan.externalId === newPricingPlanId);
    }
    return (
      <Modal
        key='changePlanModal'
        open={open}
        closeOnEscape
        closeOnDimmerClick
        size='mini'
        onClose={this.close}
        centered
      >
        <Modal.Header>{t('ChangePlan')}</Modal.Header>
        <Modal.Content>
          { changingSubscription
            && (
              <Dimmer active inverted>
                <Loader
                  active
                  inline='centered'
                  content={t('WaitingForConfirmation')}
                />
              </Dimmer>
            )
          }
          <Message warning>
            <Trans i18nKey='couponsOrDiscountsWillNotBeKept'>
              <p>
                Existing discounts will not follow to the new plan.
              </p>
            </Trans>
          </Message>
          { newProduct && (
            <Card.Group centered>
              <ProductCard
                key={newProductId}
                hideButton
                product={newProduct}
                pricingPlans={newPricingPlan}
                planIntervalFilter={planIntervalFilter}
                onPress={pricingPlan => this.clickedProductCard(pricingPlan)}
              />
            </Card.Group>
          )
          }
        </Modal.Content>
        <Modal.Actions>
          <Button
            color='grey'
            basic
            onClick={this.close}
          >
            {t('Cancel')}
          </Button>
          <Button
            positive
            disabled={changingSubscription}
            onClick={this.changeSubscription}
            content={t('ChangePlan')}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  products: state.subscription.products
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changeSubscription,
  getUserProfile,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeSubscriptionModal)));
