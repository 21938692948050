import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom'
// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import EditSurvey from './editSurvey';
import SurveyResults from './surveyResults';

class Survey extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <Switch>
        <Route exact path={`${this.props.match.path}/:surveyId`} component={EditSurvey} />
        <Route exact path={`${this.props.match.path}/:surveyId/results`} component={SurveyResults} />
      </Switch>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  currentBook: state.book.currentBook
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage)
}, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Survey));
