import React, { Component } from 'react';

import '../../App.css';

// i18n
import { withTranslation } from 'react-i18next';

// Routes
import { withRouter } from 'react-router-dom';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import {
  Container, Header
} from 'semantic-ui-react';

import NotificationsList from './notificationsList';

// Analytics
import ux from '../../utils/uxAnalyticsUtil';

export class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    ux.trackPageView(this.props.match.path);
  }

  render() {
    const { t } = this.props;
    return (
      <Container text>
        <Header>
          <Header.Content>
            {t('YourNotifications')}
          </Header.Content>
          <Header.Subheader style={{ textAlign: 'right' }}>
            {`(${t('BETA')})`}
          </Header.Subheader>
        </Header>
        <NotificationsList />
      </Container>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  notifications: state.notification.notifications
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications)));
