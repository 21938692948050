import React, { useEffect, useState } from 'react';
import Linkify from 'linkifyjs/react';

import {
  List, Icon, Divider, Dropdown, Label
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';

import { fetchCurrentManuscriptInbox, fetchRankedSubmissions } from '../../modules/manuscriptInbox';
import { authUtil, uxAnalyticsUtil } from '../../utils';
import ReceivedManuscriptsTabPane from '../../components/manuscript-inbox/recievedMansucriptsTabPane';
import { PaddedWrapper } from '../../style';

const sortOptions = [
  {
    key: 'ranking',
    text: 'ranking',
    value: 'ranking'
  },
  {
    key: 'createdAt',
    text: 'createdAt',
    value: 'createdAt'
  }
];
export const Inbox = () => {
  const [sortBy, setSortBy] = useState('createdAt');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { inboxId } = useParams();
  const location = useLocation();

  const currentManuscriptInbox = useSelector(state => state.manuscriptInbox.currentManuscriptInbox);

  useEffect(() => {
    const getInbox = async () => {
      const idToken = await authUtil.getFreshIdToken();
      dispatch(fetchCurrentManuscriptInbox(idToken, { _id: inboxId }));
      dispatch(fetchRankedSubmissions(idToken, { _id: inboxId, sortBy }));
    };
    getInbox();
    uxAnalyticsUtil.trackPageView(location.pathname);
  }, [inboxId]); // only run on first render

  useEffect(() => {
    const updateSubmissionsList = async () => {
      const idToken = await authUtil.getFreshIdToken();
      dispatch(fetchRankedSubmissions(idToken, { _id: inboxId, sortBy }));
    };
    uxAnalyticsUtil.trackEvent({
      category: uxAnalyticsUtil.categories.MANUSCRIPT_INBOX,
      action: 'changed_sorting',
      label: sortBy
    });
    updateSubmissionsList();
  }, [sortBy, inboxId]); // run every time sortBy changes

  const handleSortingChange = (event, data) => {
    setSortBy(data.value);
  };

  const SortDropDownMenu = () => (
    <Dropdown
      placeholder='SortBy'
      // icon='filter'
      // floating
      // labeled
      button
      // className='icon'
      selection
      options={sortOptions}
      onChange={handleSortingChange}
      value={sortBy}
    />
  );


  if (currentManuscriptInbox) {
    return (
      <PaddedWrapper>
        <List divided>
          <List.Item key={currentManuscriptInbox._id}>
            <Icon name='inbox' size='huge'>
              <Label style={{ position: 'absolute', marginLeft: -20, marginTop: -6 }} circular color={Array.isArray(currentManuscriptInbox.submissions) ? 'orange' : 'grey'}>
                {Array.isArray(currentManuscriptInbox.submissions) ? currentManuscriptInbox.submissions.length : 0}
              </Label>
            </Icon>

            <List.Content>
              <List.Header>{currentManuscriptInbox.title}</List.Header>
              <List.Description style={{ whiteSpace: 'pre-wrap' }}>
                <Linkify>
                  {currentManuscriptInbox.description}
                </Linkify>
              </List.Description>
              <List.Description>
                <Divider horizontal />
              </List.Description>
            </List.Content>
          </List.Item>
        </List>
        {SortDropDownMenu()}
        {
          currentManuscriptInbox.submissions?.length > 0
          && <ReceivedManuscriptsTabPane submissions={currentManuscriptInbox.submissions} />
        }
      </PaddedWrapper>
    );
  }
  return (
    <PaddedWrapper>
      <List divided>
        <List.Item key='nothingHere'>
          <List.Header>{t('NothingHere')}</List.Header>
        </List.Item>
      </List>
    </PaddedWrapper>
  );
}

export default Inbox;
