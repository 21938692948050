import React, { Component } from 'react';
import {
  Dropdown, Header, Form, Button
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

class YoBGetter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      yob: props.initialValue || null
    };
  }

  getYoBOptions = () => {
    const yobOptions = [];
    const currentYear = new Date().getFullYear();
    for (let i = 1920; i <= currentYear; i += 1) {
      yobOptions.push({
        key: i.toString(),
        value: i,
        text: i.toString()
      });
    }
    return yobOptions;
  }

  handleYoBChange = (event, data) => {
    const { wizardMode } = this.props;
    this.setState({ yob: data.value }, () => {
      if (wizardMode) {
        this.submit();
      }
    });
  }

  handleKeyDown = (event) => {
    const { yob } = this.state;
    if (event.keyCode === 13 && yob) {
      this.submit();
    }
  }

  submit = () => {
    const { yob } = this.state;
    if (this.props.onUpdate) {
      this.props.onUpdate({ yob });
    }
  }

  // render
  render() {
    const { t, updating, wizardMode } = this.props;
    const { yob } = this.state;
    return [
      <Header
        key='header'
        size='small'
        content={t('WhenWereYouBorn')}
        subheader={t('OnYourProfile')}
      />,
      <Form.Field
        control={Dropdown}
        key='input'
        placeholder={t('YoB')}
        fluid
        search
        icon={false}
        value={yob}
        options={this.getYoBOptions()}
        onChange={this.handleYoBChange}
        onKeyDown={this.handleKeyDown}
        scrolling={false}
      />,
      <Button
        style={{ marginTop: '1em' }}
        key='next'
        floated='right'
        compact
        content={wizardMode ? (yob === null ? t('Skip') : t('Next')) : t('Save')}
        onClick={this.submit}
        loading={!!updating}
      />
    ];
  }
}

export default withTranslation()(YoBGetter);
