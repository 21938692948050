import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Container, Loader, List } from 'semantic-ui-react';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ReadingSession from './readingSession';

import {
  fetchCurrentReadingSessions,
  clearCurrentReadingSessions
} from '../../modules/reader';

class ReadingSessionsFeed extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    const { idToken, currentBook } = this.props;
    const { readerId } = this.props.match.params;

    this.props.fetchCurrentReadingSessions(idToken, { bookId: currentBook._id, readerId: readerId })
  }

  componentWillUnmount() {
    this.props.clearCurrentReadingSessions();
  }

  render() {
    const { currentReadingSessions } = this.props;
    if (!currentReadingSessions || !Array.isArray(currentReadingSessions)) {
      return (
        <Container>
          <Loader />
        </Container>
      );
    }
    return (
      <List selection>
        {
          currentReadingSessions.map((session, index) => {
            return (
              <ReadingSession key={session._id} session={session} startOpen={index === 0} />
            )
          })
        }
      </List>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  currentBook: state.book.currentBook,
  currentReader: state.reader.currentReader,
  currentReadingSessions: state.reader.currentReadingSessions,
  readingSessionEventData: state.reader.readingSessionEventData
})

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchCurrentReadingSessions,
  clearCurrentReadingSessions,
  changePage: (newPage) => push(newPage)
}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadingSessionsFeed))
