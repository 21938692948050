import React, { useEffect } from 'react';
import moment from 'moment';

import {
  Grid
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import Linkify from 'linkifyjs/react';
import Avatar from '../../components/avatar/avatar';

// stateless component
const Message = ({
  currentUserId, text, createdAt, user, isCurrentUser, seenBy, onSeen
}) => {
  useEffect(() => {
    if (!seenBy || seenBy.filter(log => (log.user && log.user._id) === currentUserId).length === 0) {
      // the current user has not yet seen this message.
      // mark it as seen.
      onSeen();
    }
  }, []);

  return (
    <div className='chat-message'>
      <div className={isCurrentUser ? 'chat-message-box-isCurrentUser' : 'chat-message-box'}>
        <Grid columns='equal'>
          <Grid.Row>
            {
              !isCurrentUser && (
                <Grid.Column width={1}>
                  <Avatar name={user.name} size={20} />
                </Grid.Column>
              )
            }
            <Grid.Column>
              <Grid.Row style={isCurrentUser ? { float: 'right' } : { float: 'left' }}>
                <div className={isCurrentUser ? 'chat-message-content-isCurrentUser' : 'chat-message-content'}>
                  <span className={isCurrentUser ? 'chat-message-content-text-isCurrentUser' : 'chat-message-content-text'}><Linkify>{text}</Linkify></span>
                  <span className={isCurrentUser ? 'chat-message-content-text-date-isCurrentUser' : 'chat-message-content-text-date'}>{moment(new Date(createdAt)).format('h:mm a')}</span>
                </div>
              </Grid.Row>
            </Grid.Column>
            {
              isCurrentUser && (
                <Grid.Column width={1}>
                  <Grid.Row style={{ float: 'right' }}>
                    <Avatar name={user.name} size={20} />
                  </Grid.Row>
                </Grid.Column>
              )
            }
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default withTranslation()(Message);
