import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Grid, List, Table } from 'semantic-ui-react';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  fetchCurrentReadingSessionEvents,
  clearCurrentReadingSessionEvents
} from '../../modules/reader';

class ReadingSessionEventsFeed extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    const { idToken, currentBook, session } = this.props;
    const { readerId } = this.props.match.params;
    this.props.fetchCurrentReadingSessionEvents(idToken, { bookId: currentBook._id, readerId: readerId, sessionId: session._id });
  }

  componentWillUnmount() {
    this.props.clearCurrentReadingSessionEvents();
  }

  getVerb = (logEvent) => {
    const {readingSessionEventData} = this.props;
    var verb = readingSessionEventData[logEvent.event].name;
    if (logEvent.part) {
      verb += ' ' + logEvent.part.title;
    }
    return verb;
  }

  renderGridView = (event) => {
    return (
      <Grid
        columns='equal'
        stackable={false}
        style={{paddingLeft: 0}}>
        <Grid.Row>
          <Grid.Column style={{paddingLeft: 0}}>
            {this.getVerb(event)}
          </Grid.Column>
          <Grid.Column>
            {new Date(event.timestamp).toLocaleString()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  renderTableView = (event) => {
    return (
      <Table compact>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{this.getVerb(event)}</Table.Cell>
            <Table.Cell>{new Date(event.timestamp).toLocaleString()}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }

  render() {
    const { session, readingSessionEvents } = this.props;
    const currentReadingSessionEvents = readingSessionEvents[session._id] || [];
    if (!currentReadingSessionEvents || !Array.isArray(currentReadingSessionEvents)) return null;
    return (
      <List relaxed>
        {
          currentReadingSessionEvents.map(event => (
            <List.Item key={event._id}>
              {this.renderGridView(event)}
            </List.Item>
          ))
        }
      </List>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  currentBook: state.book.currentBook,
  currentReader: state.reader.currentReader,
  readingSessionEventData: state.reader.readingSessionEventData,
  readingSessionEvents: state.reader.readingSessionEvents
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchCurrentReadingSessionEvents,
  clearCurrentReadingSessionEvents,
  changePage: newPage => push(newPage)
}, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadingSessionEventsFeed));
