import ReactGA from 'react-ga';

const categories = {
  ALERTS: 'Alerts',
  BOOK_MANAGEMENT: 'Book Management',
  MANUSCRIPT_INBOX: 'Manuscript Inbox',
  READING: 'Reading',
  UPLOAD: 'Upload',
};

const trackPageView = (page) => {
  ReactGA.pageview(page);
};

const trackEvent = (eventData) => {
  ReactGA.event(eventData);
  if (eventData.action) {
  }
};

const trackException = (exceptionData) => {
  ReactGA.exception(exceptionData);
};

const trackModalView = (view) => {
  ReactGA.modalview(view);
};

const timing = (timingData) => {
  ReactGA.timing(timingData);
};

const trackErrorEvent = (eventData) => {
  ReactGA.exception(eventData);
};

export default {
  trackException,
  trackPageView,
  trackErrorEvent,
  trackEvent,
  trackModalView,
  timing,
  categories
};
