import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Header, Table, Button, Label, Popup, Icon} from 'semantic-ui-react';

// pop-ups
import {toast} from 'react-toastify';

// i18n
import {withTranslation} from 'react-i18next';

// redux
import {push} from 'connected-react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {} from '../../../modules/book';

import InviteManuscriptCollaboratorModal from './inviteManuscriptCollaboratorModal';
import {PaddedWrapper} from '../../../style';
import BetaIcon from 'src/components/BetaIcon';

export class ManuscriptCollaborators extends Component {
  constructor(props) {
    super(props);
    this.state = {
      manuscriptCollaborators: []
    };
  }

  componentDidMount() {
    this.fetchCollaborators();
  }

  fetchCollaborators = () => {
    const {idToken, currentBook} = this.props;
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${currentBook._id}/members`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => res.json())
      .then(manuscriptCollaborators => {
        this.setState({
          manuscriptCollaborators
        });
      })
      .catch(() => {})
      .finally(() => {});
  };

  removeCollaborator = (event, data) => {
    const userId = data['data-user-id'];
    const {idToken, currentBook, t} = this.props;
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${currentBook._id}/members/${userId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => {
        if (res.status === 200) {
          // remove the collaborator from the ui as well
          this.setState(prevState => ({
            manuscriptCollaborators: [
              ...prevState.manuscriptCollaborators.filter(
                collaborator => collaborator.userId !== userId
              )
            ]
          }));
        } else {
          throw new Error(res.status);
        }
      })
      .catch(() => {
        toast.error(t('SomethingWentWrong'));
      })
      .finally(() => {});
  };

  render() {
    const {t} = this.props;
    const {manuscriptCollaborators = []} = this.state;

    return (
      <PaddedWrapper>
        <Header
          content={
            <span>
              {t('Collaborators')}
              <BetaIcon />
            </span>
          }
          subheader={t('InviteCollaboratorMsg')}
        />
        {manuscriptCollaborators.length > 0 && (
          <Table
            selectable
            singleLine
            basic
            unstackable
            headerRow={['Name', 'Email']}
            tableData={manuscriptCollaborators}
            renderBodyRow={collaborator => (
              <Table.Row key={collaborator._id}>
                <Table.Cell key='name'>{collaborator.displayName}</Table.Cell>
                <Table.Cell key='email'>{collaborator.email}</Table.Cell>
                <Table.Cell key='options' collapsing>
                  <Button
                    data-user-id={collaborator.userId}
                    basic
                    icon='trash alternate'
                    onClick={this.removeCollaborator}
                  />
                </Table.Cell>
              </Table.Row>
            )}
            footerRow={
              <Table.Row>
                <Table.Cell colSpan={3} key='email'>
                  <InviteManuscriptCollaboratorModal />
                </Table.Cell>
              </Table.Row>
            }
          />
        )}
        {manuscriptCollaborators.length === 0 && (
          <InviteManuscriptCollaboratorModal />
        )}
      </PaddedWrapper>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  currentBook: state.book.currentBook
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: newPage => push(newPage)
    },
    dispatch
  );

export default withTranslation()(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ManuscriptCollaborators)
  )
);
