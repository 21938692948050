import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Header, Grid, Container, Loader, Form
} from 'semantic-ui-react';
// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import gaTracker from '../../utils/gaTracker';

import {
  fetchCurrentReader,
  clearCurrentReader
} from '../../modules/reader';

import ReadingSessionsFeed from './readingSessionsFeed';
import ReadingGraph from './readingGraph';

class Reader extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    gaTracker.trackPageView(this.props.match.path);
    const { idToken, currentBook } = this.props;
    const { readerId } = this.props.match.params;

    this.props.fetchCurrentReader(idToken, { bookId: currentBook._id, readerId: readerId })
  }

  componentWillUnmount() {
    this.props.clearCurrentReader();
  }

  render() {
    const { currentReader, currentBook, updatingReader } = this.props;
    const reader = currentReader;
    if (!currentReader) {
      return (
        <Container>
          <Loader />
        </Container>
      );
    } else {
      return (
        <Container>
          <Grid columns='equal' stackable>
            <Grid.Row>
              <Grid.Column>
                <Header content={reader.name} />
                { reader.invitation.status === 'SENT' && <span>Not yet started. (Invited on {new Date(reader.invitation.sentAt).toLocaleDateString()}) {updatingReader === reader._id && 'Sending invitation'}</span>}
                { reader.invitation.status === 'CREATED' && <span>Not yet started. (No invitation sent. {updatingReader === reader._id ? 'Sending invitation' : <Link to="#" onClick={this.sendInvitation} data-reader-id={reader._id}>Send invitation.</Link>})</span>}
                { (reader.invitation.status === 'ACCEPTED' && currentBook !== undefined) &&
                  <ReadingGraph lastRead={reader.lastRead} chapters={currentBook.parts}/>
                }
                {
                  reader.invitation.status === 'ABANDONED' &&
                  <div>
                    <span>Abandoned reading on {new Date(reader.invitation.abandonedAt).toLocaleDateString()}</span><br/>
                    {
                      reader.exitReason &&
                      <Form>
                        {/* <Form.Field>
                          <strong>Reason for abandoning:</strong><br/>
                          {reader.exitReason.reason}
                        </Form.Field> */}
                        <Form.Field>
                          <strong>Feedback:</strong><br/>
                          {reader.exitReason.feedback}
                        </Form.Field>
                        {/* {
                          reader.exitReason.exitPart &&
                          <Form.Field>
                            <strong>Abandoned at:</strong><br/>
                            {reader.exitReason.exitPart.title}
                          </Form.Field>
                        } */}
                      </Form>
                    }
                  </div>
                }

              </Grid.Column>
              <Grid.Column>
                <Header content='Activity' />
                <ReadingSessionsFeed />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      );
    }
  }

}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  currentBook: state.book.currentBook,
  updatingReader: state.reader.updatingReader,
  currentReader: state.reader.currentReader
})

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchCurrentReader,
  clearCurrentReader,
  changePage: (newPage) => push(newPage)
}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Reader))
