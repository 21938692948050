const hasFeatureAccess = (userProfile, featureName) => {
  if (featureName === 'ai') {
    console.log('userProfile', featureName, userProfile);
  }
  if (
    userProfile !== undefined &&
    userProfile.betaFeatures !== undefined &&
    userProfile.betaFeatures[featureName]
  ) {
    return true;
  }
  return false;
};

export default {
  hasFeatureAccess
};
