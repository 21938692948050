import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { List } from 'semantic-ui-react';
import moment from 'moment';
// i18n
import { withTranslation } from 'react-i18next';
// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  approveJoinRequest,
  declineJoinRequest,
  sendSurveyReminder
} from '../../../modules/reader';

export class JoinRequestItem extends Component {
  // lifecycle methods
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  // custom methods
  approveJoinRequest = (joinRequestId, contentVersion) => {
    const { idToken, joinRequests } = this.props;
    const joinRequest = joinRequests.find(entry => entry._id === joinRequestId);
    if (joinRequest) {
      this.props.approveJoinRequest(idToken, joinRequest, { contentVersion });
    }
  }

  declineJoinRequest = (event, data) => {
    const { idToken, joinRequests } = this.props;
    var joinRequest = joinRequests.find(joinRequest => {
      return joinRequest._id === data["data-reader-id"];
    })
    if (joinRequest) {
      this.props.declineJoinRequest(idToken, joinRequest);
    }
  }

  sendSurveyReminder = (joinRequest) => {
    const { idToken } = this.props;
    this.props.sendSurveyReminder(idToken, joinRequest);
  }

  // rendering
  render() {
    const { t, reader } = this.props;
    return (
      <List.Item>
        <List.Content floated='left' verticalAlign='middle'>
          <List.Header>
            {reader.name}
          </List.Header>
          {
            reader.invitation.requestedAt !== undefined && (
              <div>
                {t('RequestedAt')}: {moment(reader.invitation.requestedAt).fromNow()}<br />
              </div>
            )
          }
          {/* {
            reader.readerSignupSurveyQuestions &&
            reader.readerSignupSurveyQuestions.map((question, index) => {
              return (
                <div key={index} style={{ fontSize: '0.8em' }}>
                  <strong>
                    {question.question}
                  </strong>
                  <br />
                  <em>
                    {question.answer ? question.answer.answer : t('NoAnswer')}
                  </em>
                </div>
              )
            })
          } */}
          {
            reader.readerSignupSurveyQuestions && reader.readerSignupSurveyQuestions.some(question => question.answer === undefined) &&
            // some questions have not been answered
            <span style={{ fontSize: '0.8em', cursor: 'pointer' }}>
              {
                <Link to="#" onClick={() => { this.sendSurveyReminder(reader) }}>
                  {t('SendSurveyReminder')}
                </Link>
              }
              {
                reader.signupSurveyReminderSent &&
                <span>
                  {' '}({t('ReminderSentAt')} {moment(reader.signupSurveyReminderSent).fromNow()})
                </span>
              }
            </span>
          }
        </List.Content>
      </List.Item>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  currentBook: state.book.currentBook,
  joinRequests: state.reader.joinRequests
});

const mapDispatchToProps = dispatch => bindActionCreators({
  approveJoinRequest,
  declineJoinRequest,
  sendSurveyReminder,
  changePage: (newPage) => push(newPage),
}, dispatch)

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(JoinRequestItem)));
