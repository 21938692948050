import React, { Component } from 'react';
import {
  Label
} from 'semantic-ui-react';

class GenreList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      genres, noInfoMessage
    } = this.props;
    if (!genres || genres.length === 0) {
      return (
        <span>
          {noInfoMessage}
        </span>
      );
    }
    genres.sort((a, b) => {
      if (a < b) { return -1; }
      if (a > b) { return 1; }
      return 0;
    });
    return genres.map(genre => (
      <Label
        key={genre}
        circular
        style={{ marginRight: 5, marginTop: 5 }}
      >
        {genre}
      </Label>
    ));
  }
}

export default GenreList;
