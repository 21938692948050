import React, { Component } from 'react';
import queryString from 'query-string';
import {
  Search
} from 'semantic-ui-react';

// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// i18n
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

let searchTimeout;
const searchTimeoutDelay = 1000;

export class DiscoverSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: ''
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const query = queryString.parse(location.search);
    const { search } = query;
    this.setState({
      searchTerm: search
    });
  }

  handleSearchChange = (event, data) => {
    this.setState({
      searchTerm: data.value
    }, () => {
      const self = this;
      if (searchTimeout) {
        // clear any old timeout
        clearTimeout(searchTimeout);
      }
      searchTimeout = setTimeout(function() {
        self.triggerSearch();
      }, searchTimeoutDelay)
    });
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      // Enter
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      this.triggerSearch();
    }
  }

  triggerSearch = () => {
    const { searchTerm } = this.state;
    const { location, history } = this.props;
    const query = queryString.parse(location.search);
    query.search = searchTerm;
    history.replace(`${location.pathname}?${queryString.stringify(query)}`);
  };

  render() {
    const { searchTerm } = this.state;
    const { t, loadingFeedback } = this.props;

    return (
      <Search
        value={searchTerm}
        input={{ fluid: true, loading: loadingFeedback }}
        placeholder={t('FeedbackSearch')}
        fluid
        onSearchChange={this.handleSearchChange}
        onKeyDown={this.handleKeyDown}
        open={false}
      />
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  loadingOpportunities: state.comment.loadingFeedback
});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(DiscoverSearch)));
