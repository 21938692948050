import {createMedia} from '@artsy/fresnel';

const AppMedia = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 769,
    computer: 1024
  }
});

export const {Media, MediaContextProvider, createMediaStyle} = AppMedia;
