import i18n from '../utils/i18n/i18n';
import { UPDATE_PROFILE } from './user';

export const SHOW_FIXED_MENU_CHANGED = 'app/SHOW_FIXED_MENU_CHANGED';
export const TOGGLE_APP_SIDEBAR = 'app/TOGGLE_APP_SIDEBAR';
export const TOGGLE_COMMENTS_SIDEBAR_VISIBLE = 'app/TOGGLE_COMMENTS_SIDEBAR_VISIBLE';
export const READING_STYLE_WHITE = 'app/READING_STYLE_WHITE';
export const READING_STYLE_INVERTED = 'app/READING_STYLE_SEPIA';
export const READING_STYLE_SEPIA = 'app/READING_STYLE_SEPIA';
export const CHANGE_READING_STYLE = 'app/CHANGE_READING_STYLE';
export const READING_MODE_BOOK = 'app/READING_MODE_BOOK';
export const READING_MODE_SCROLL = 'app/READING_MODE_SCROLL';
export const REMOVE_NOTIFICATION = 'app/REMOVE_NOTIFICATION';
export const UPDATE_BROWSE_MENU = 'app/UPDATE_BROWSE_MENU';
export const SET_IS_EMBEDDED = 'app/SET_IS_EMBEDDED';

export const defaultNotificationDuration = 2000; // milliseconds
export const initialState = {
  isEmbedded: false,
  sidebarVisible: false,
  showFixedMenu: true,
  showBrowseMenu: false,
  readingStyle: READING_STYLE_WHITE,
  readingMode: READING_MODE_SCROLL,
  defaultColorMode: 'sepia',
  colorModes: [
    {
      name: 'dark',
      icon: 'lightbulb',
      iconSelected: 'lightbulb outline',
      color: 'black',
      className: 'color-mode-dark'
    },
    {
      name: 'sepia',
      icon: 'lightbulb',
      iconSelected: 'lightbulb outline',
      color: 'brown',
      className: 'color-mode-sepia'
    },
    {
      name: 'bright',
      icon: 'lightbulb outline',
      iconSelected: 'lightbulb',
      color: 'black',
      className: 'color-mode-bright'
    }
  ],
  fontSizes: [
    {
      name: 'small',
      size: 1
    },
    {
      name: 'normal',
      size: 1.12
    },
    {
      name: 'large',
      size: 1.4
    },
    {
      name: 'big',
      size: 1.6
    }
  ],
  defaultFontSize: 'normal',
  partIcons: {
    Chapter: 'file alternate outline',
    Survey: 'question circle outline'
  },
  commentsSidebarVisible: false
};

// reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_FIXED_MENU_CHANGED:
      return {
        ...state,
        showFixedMenu: action.showFixedMenu
      };
    case CHANGE_READING_STYLE:
      return {
        ...state,
        readingStyle: action.readingStyle
      };
    case TOGGLE_COMMENTS_SIDEBAR_VISIBLE:
      return {
        ...state,
        commentsSidebarVisible: action.newState !== undefined ? action.newState : !state.commentsSidebarVisible
      };
    case UPDATE_BROWSE_MENU:
      return {
        ...state,
        showBrowseMenu: action.show
      };
    case UPDATE_PROFILE: {
      const { profile } = action;
      let messages = [];
      if (profile.subscription && profile.subscription.status === 'past_due') {
        messages.push({
          error: true,
          message: i18n.t('PastDueMessage'),
          actionPath: '/profile',
          actionText: i18n.t('ChangePaymentInformation')
        });
      }
      return {
        ...state,
        alerts: messages
      };
    }
    case TOGGLE_APP_SIDEBAR:
      // set to action.showAppSidebar if available, otherwise opposite of previous value
      return {
        ...state,
        sidebarVisible: (action.showAppSidebar !== undefined)
          ? action.showAppSidebar
          : !state.sidebarVisible
      };
    case SET_IS_EMBEDDED:
      return {
        ...state,
        isEmbedded: action.isEmbedded
      };
    default:
      return state;
  }
};

// actions
export const toggleShowFixedMenu = showFixedMenu => (dispatch) => {
  dispatch({
    type: SHOW_FIXED_MENU_CHANGED,
    showFixedMenu
  });
};

export const changeReadingStyle = newReadingStyle => (dispatch) => {
  dispatch({
    type: CHANGE_READING_STYLE,
    readingStyle: newReadingStyle
  });
};

export const toggleCommentsSidebar = showCommentsSidebar => (dispatch) => {
  dispatch({
    type: TOGGLE_COMMENTS_SIDEBAR_VISIBLE,
    newState: showCommentsSidebar
  });
};

export const toggleAppSidebar = showAppSidebar => (dispatch) => {
  dispatch({
    type: TOGGLE_APP_SIDEBAR,
    showAppSidebar
  });
};

export const toggleBrowseMenu = show => (dispatch) => {
  dispatch({
    type: UPDATE_BROWSE_MENU,
    show
  });
};

export const setIsEmbedded = isEmbedded => (dispatch) => {
  dispatch({
    type: SET_IS_EMBEDDED,
    isEmbedded
  });
};
