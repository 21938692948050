import React, { Component } from 'react';
import {
  Table, Header, Button
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';

// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  fetchAccountUsers,
  removeAccountUser
} from '../../../modules/account';

import InviteAccountMemberModal from './inviteAccountMemberModal';
import { authUtil } from '../../../utils';

export class AccountMembersTable extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchAccountUsers();
  }

  fetchAccountUsers = async () => {
    const { userProfile } = this.props;
    const idToken = await authUtil.getFreshIdToken();
    if (userProfile) {
      this.props.fetchAccountUsers({
        idToken,
        accountId: userProfile.account && userProfile.account._id
      });
    }
  }

  deleteUser = async (event, data) => {
    const idToken = await authUtil.getFreshIdToken();
    this.props.removeAccountUser({
      idToken,
      accountId: data['data-account-id'],
      userId: data['data-user-id']
    });
  }

  render() {
    const {
      userProfile, user, t, accountUsers
    } = this.props;
    if (user !== undefined && userProfile !== undefined) {
      return (
        <React.Fragment>
          <Header size='small' content={t('Members')} />
          <Table>
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell>{t('Name')}</Table.HeaderCell>
                <Table.HeaderCell>{t('Role')}</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                accountUsers && accountUsers.map(accountUser => (
                  <Table.Row key={accountUser._id}>
                    <Table.Cell>{accountUser.user.displayName || t('Unnamed')}</Table.Cell>
                    <Table.Cell>{t(`member_role_${accountUser.role}`)}</Table.Cell>
                    <Table.Cell collapsing>
                      {
                        accountUser.user._id !== userProfile._id && (
                          <Button
                            circular
                            icon='trash'
                            data-user-id={accountUser.user._id}
                            data-account-id={accountUser.account}
                            onClick={this.deleteUser}
                          />
                        )
                      }
                    </Table.Cell>
                  </Table.Row>
                ))
              }
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan='3'>
                  <InviteAccountMemberModal />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </React.Fragment>
      );
    }
    return null;
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  readerBonusList: state.readerBonus.readerBonusList,
  accountUsers: state.account.accountUsers
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchAccountUsers,
  removeAccountUser
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountMembersTable)));
