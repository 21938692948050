import React, {Component} from 'react';
import {Card, Icon, Label, Divider, List, Responsive} from 'semantic-ui-react';
import {withTranslation} from 'react-i18next';
import {Media} from 'src/utils/Media';

import PricingPlan from './pricingPlan';
import ProductToSMessage from '../../../components/payments/productToSMessage';

export class ProductCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localisedPricing: {}
    };
  }

  componentDidMount() {
    const {product} = this.props;
    const featuresWithPricing = product.featureDescriptions.filter(
      feature => feature.paddleProductId
    );
    if (featuresWithPricing.length) {
      this.getLocalisedPricing(featuresWithPricing);
    }
  }

  getLocalisedPricing = features => {
    features.forEach(feature => {
      window.Paddle.Product.Prices(feature.paddleProductId, pricing => {
        this.setState(prevState => {
          const newLocalisedPricing = {
            ...prevState.localisedPricing
          };
          newLocalisedPricing[feature.paddleProductId] = pricing.price.gross;
          return {
            localisedPricing: newLocalisedPricing
          };
        });
      });
    });
  };

  getPricingPlans = (
    currentProduct,
    currentPricingplanId,
    isCancelled,
    validUntilDate
  ) => {
    const {
      pricingPlans,
      hideButton,
      currentBillingInterval,
      planIntervalFilter,
      isLoggedIn
    } = this.props;
    const filteredPlanList = pricingPlans.filter(pricingPlan => {
      return (
        pricingPlan.billingInterval === planIntervalFilter ||
        pricingPlan.billingInterval === 'none'
      );
    });
    const pricingPlanList = filteredPlanList.map(pricingPlan => (
      <PricingPlan
        key={pricingPlan._id}
        hideButton={hideButton}
        isCurrentPricingPlan={pricingPlan._id === currentPricingplanId}
        currentBillingInterval={currentBillingInterval}
        pricingPlan={pricingPlan}
        onPress={(selectedPricingPlan, localPriceObject) =>
          this.props.onPress(selectedPricingPlan)
        }
        isCancelled={isCancelled}
        isLoggedIn={isLoggedIn}
        validUntilDate={validUntilDate}
      />
    ));
    return <div>{pricingPlanList}</div>;
  };

  render() {
    const {
      product,
      t,
      currentProduct,
      currentPricingplanId,
      isCancelled,
      validUntilDate
    } = this.props;
    const {localisedPricing} = this.state;
    return (
      <Card key={product._id} color={currentProduct ? 'orange' : 'grey'}>
        <Card.Content>
          <Card.Header textAlign='center'>
            {t(product.title).toUpperCase()}
          </Card.Header>
          <Card.Meta textAlign='center' style={{minHeight: 50}}>
            {t(product.description)}
          </Card.Meta>
          {this.getPricingPlans(
            currentProduct,
            currentPricingplanId,
            isCancelled,
            validUntilDate
          )}
          <Divider />
          <Card.Description textAlign='left'>
            {product.featureDescriptions && (
              <List size='mini'>
                {product.featureDescriptions.map(feature => (
                  <List.Item key={feature.name}>
                    <Icon
                      name='check'
                      color={feature.included ? 'green' : 'green'}
                    />
                    <List.Content>
                      {feature.included && (
                        <strong>
                          {t(feature.name)}
                          {!!feature.paddleProductId &&
                            localisedPricing[feature.paddleProductId] && (
                              <span style={{marginLeft: '1em'}}>
                                {localisedPricing[feature.paddleProductId]}{' '}
                                {t('PerMonth')}
                              </span>
                            )}
                        </strong>
                      )}
                      {!feature.included && <em>{t(feature.name)}</em>}
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            )}
          </Card.Description>
        </Card.Content>
        <Media at='tablet'>
          {(className, renderChildren) =>
            renderChildren && (
              <Card.Content extra textAlign='left'>
                <ProductToSMessage className='product-card-tos-message' />
              </Card.Content>
            )
          }
        </Media>
      </Card>
    );
  }
}

export default withTranslation()(ProductCard);
