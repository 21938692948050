import React, {useEffect, useState} from 'react';

import {
  Header,
  Dimmer,
  Container,
  Input,
  List,
  Message,
  Icon,
  Button,
  Grid,
  Popup
} from 'semantic-ui-react';
import styled, {css} from 'styled-components';
import countWords from 'word-character-count';
import {Trans, useTranslation} from 'react-i18next';

import ChapterParser from './chapterParser';
import ux from '../../../../utils/uxAnalyticsUtil';

import './post-processor.css';
import usePostProcessingContext from './postProcessingContext';
import {
  ChapterSidebar,
  SidebarToggle
} from '../../../../components/chapterSidebar';

const ContentBlock = styled.div`
  background-color: ${({theme}) => theme.background.primary};
  padding: 1em;
  > p:not(:last-child):hover {
    padding-bottom: 10px;
    border-bottom: 2px dashed black;
    cursor: pointer;
  }
`;

const ChapterFilePostProcessor = ({onCompleteClicked, onCancelClicked}) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [cutPosition, setCutPosition] = useState();
  const maxSupportedCharacterCount = 200000;
  const {t} = useTranslation();
  const {documentData, setDocumentData} = usePostProcessingContext();

  useEffect(() => {
    ux.trackModalView('upload-post-processor');
    setTimeout(function () {
      // slide sidebar closed to show that it's there
      setSidebarOpen(false);
    }, 500);
  }, []);

  const removePart = event => {
    ux.trackEvent({
      category: 'Imports',
      action: 'clicked-remove-part'
    });
    const {part} = event.currentTarget.dataset;
    const index = parseInt(part, 10);
    const updatedParts = [...documentData.parts];
    // remove part
    updatedParts.splice(index, 1);
    // update parent

    setDocumentData({
      ...documentData,
      maxPartWordCount: updatedParts.reduce(
        (total, comparePart) => Math.max(total, comparePart.wordCount),
        0
      ),
      maxPartCharCount: updatedParts.reduce(
        (total, comparePart) => Math.max(total, comparePart.characterCount),
        0
      ),
      parts: updatedParts
    });
  };

  const showPart = event => {
    ux.trackEvent({
      category: 'Imports',
      action: 'clicked-show-part'
    });
    const {part} = event.currentTarget.dataset;
    const partElement = document.getElementById(`part_${part}`);
    if (partElement) {
      partElement.scrollIntoView({
        behavior: 'auto',
        block: 'start'
      });
    }
  };

  const onDocumentTitleChanged = (event, data) => {
    setDocumentData({
      ...documentData,
      title: data.value
    });
  };

  const onPartTitleChanged = (event, data) => {
    const index = data['data-part'];
    const updatedParts = [...documentData.parts];
    // update part
    const updatedPart = {
      ...documentData.parts[index],
      title: data.value
    };
    updatedParts.splice(index, 1, updatedPart);

    // update parent
    setDocumentData({
      ...documentData,
      parts: updatedParts
    });
  };

  const splitPart = async event => {
    ux.trackEvent({
      category: 'Imports',
      action: 'clicked-split-part'
    });
    // find split position
    const {dataset} = event.currentTarget;
    const partIndex = parseInt(dataset.partIndex, 10);
    const paragraphs = [].slice.call(
      event.currentTarget.querySelectorAll('p'),
      0
    );
    const clickedParagraphIndex = paragraphs.indexOf(event.target);
    if (
      clickedParagraphIndex === paragraphs.length - 1 ||
      clickedParagraphIndex === -1
    ) {
      // dont split after the last paragraph, or if we didn't find one
      return;
    }

    // split part into two; above and below split position
    const aboveSplit = paragraphs.slice(0, clickedParagraphIndex + 1);
    const belowSplit = paragraphs.slice(clickedParagraphIndex + 1);

    // update parent with the new parts list
    const updatedParts = [...documentData.parts];
    // remove part and replace with splits
    const aboveSplitHtml = aboveSplit.reduce((string, element) => {
      const newString = string + element.outerHTML;
      return newString;
    }, '');
    const aboveSplitWordCount = await countWords.WordCount(aboveSplitHtml);

    const aboveSplitPartData = {
      // the old part keeps its title
      title: documentData.parts[partIndex].title,
      html: aboveSplitHtml,
      wordCount: aboveSplitWordCount.WordCount,
      characterCount: aboveSplitWordCount.CharacterCount
    };

    const belowSplitHtml = belowSplit.reduce((string, element) => {
      const newString = string + element.outerHTML;
      return newString;
    }, '');
    const belowSplitWordCount = await countWords.WordCount(belowSplitHtml);

    const belowSplitPartData = {
      // the old part keeps its title
      title: documentData.parts[partIndex].title,
      html: belowSplitHtml,
      wordCount: belowSplitWordCount.WordCount,
      characterCount: belowSplitWordCount.CharacterCount
    };

    updatedParts.splice(partIndex, 1, aboveSplitPartData, belowSplitPartData);
    // update parent
    setDocumentData({
      ...documentData,
      maxPartWordCount: updatedParts.reduce(
        (total, part) => Math.max(total, part.wordCount),
        0
      ),
      maxPartCharCount: updatedParts.reduce(
        (total, part) => Math.max(total, part.characterCount),
        0
      ),
      parts: updatedParts
    });
  };

  const stitchParts = async event => {
    ux.trackEvent({
      category: 'Imports',
      action: 'clicked-stitch-parts'
    });
    // find stitch position
    const {dataset} = event.currentTarget;
    const partIndex = parseInt(dataset.partIndex, 10);

    // join current part with previous part
    const currentPart = documentData.parts[partIndex];
    const previousPart = documentData.parts[partIndex - 1];

    if (currentPart && previousPart) {
      const newPartHtml = (previousPart.html += currentPart.html);

      // count words and charachters
      const wordCountResult = await countWords.WordCount(newPartHtml);

      const newPart = {
        ...previousPart,
        html: newPartHtml,
        wordCount: wordCountResult.WordCount,
        characterCount: wordCountResult.CharacterCount
      };

      // update parent with the new parts list
      const updatedParts = [...documentData.parts];
      // replace both old parts with the new part
      updatedParts.splice(partIndex - 1, 2, newPart);
      // update parent
      setDocumentData({
        ...documentData,
        maxPartWordCount: Math.max(
          documentData.maxPartWordCount,
          wordCountResult.WordCount
        ),
        maxPartCharCount: Math.max(
          documentData.maxPartCharCount,
          wordCountResult.CharacterCount
        ),
        parts: updatedParts
      });
    }
  };

  const hoverPart = event => {
    // find the current paragraph
    const paragraphs = [].slice.call(
      event.currentTarget.querySelectorAll('p'),
      0
    );
    const currentParagraphIndex = paragraphs.indexOf(event.target);
    // set cut position if we're not on the last paragraph of a part
    if (
      currentParagraphIndex >= 0 &&
      currentParagraphIndex < paragraphs.length - 1
    ) {
      const rect = event.target.getBoundingClientRect();
      setCutPosition({
        top: rect.top + rect.height - 10,
        left: rect.right + 20
      });
    } else {
      setCutPosition(undefined);
    }
  };

  return (
    <Dimmer active page>
      {cutPosition && (
        <Icon
          name='cut'
          flipped='horizontally'
          size='large'
          style={{
            position: 'absolute',
            top: cutPosition.top,
            left: cutPosition.left
          }}
        />
      )}
      <ChapterSidebar open={sidebarOpen}>
        <SidebarToggle
          link
          size='large'
          name={sidebarOpen ? 'chevron circle left' : 'chevron circle right'}
          onClick={() => setSidebarOpen(!sidebarOpen)}
        />
        {documentData && documentData.parts && (
          <>
            <List inverted divided selection verticalAlign='middle'>
              <List.Item>
                <List.Content>
                  <List.Header>
                    <ChapterParser documentData={documentData} />
                  </List.Header>
                </List.Content>
              </List.Item>
              {documentData.parts.map((part, index) => (
                <List.Item data-part={index} key={index} onClick={showPart}>
                  <List.Content
                    header={
                      part.characterCount > maxSupportedCharacterCount ? (
                        <Popup
                          flowing
                          wide
                          basic
                          position='top right'
                          content={`${t('ExceedChapterLenghtWarning')} ${t(
                            'CharCountString',
                            {count: part.characterCount}
                          )} / ${maxSupportedCharacterCount}`}
                          trigger={
                            <span>
                              <Icon color='yellow' name='warning sign' />
                              {`${index + 1}${
                                part.title ? `: ${part.title}` : ''
                              }`}{' '}
                              {`(${t('WordCountString', {
                                count: part.wordCount
                              })}
                              )`}
                            </span>
                          }
                        />
                      ) : (
                        `${part.title || `${t('Chapter')} ${index + 1}`} (${t(
                          'WordCountString',
                          {count: part.wordCount}
                        )})`
                      )
                    }
                    style={{textOverflow: 'ellipsis'}}
                  />
                </List.Item>
              ))}
            </List>
          </>
        )}
      </ChapterSidebar>
      <Container
        text
        textAlign='left'
        style={{
          height: '100vh',
          overflowY: 'scroll',
          color: 'black'
        }}>
        <Button.Group
          style={{
            position: 'fixed',
            right: 0,
            top: 0
          }}>
          <Button content={t('Cancel')} onClick={onCancelClicked} />
          <Button
            disabled={
              !documentData ||
              documentData.parts.length === 0 ||
              documentData.maxPartCharCount > maxSupportedCharacterCount
            }
            content={t('CompleteImport')}
            positive
            onClick={onCompleteClicked}
          />
        </Button.Group>
        <Header
          inverted
          size='large'
          content={
            <Input
              fluid
              transparent
              placeholder={documentData.title}
              value={documentData.title}
              onChange={onDocumentTitleChanged}
              inverted
              style={{
                fontWeight: 'bold',
                fontSize: '1.8rem',
                paddingBottom: '0.5rem'
              }}
            />
          }
          subheader={`${t('ChapterCount', {count: documentData.parts.length})}`}
          style={{
            paddingLeft: 10,
            paddingTop: 10
          }}
        />

        {documentData.maxPartCharCount > maxSupportedCharacterCount && (
          <Message warning>
            <Message.Header>{t('LongChapterWarning')}</Message.Header>
            <Trans i18nKey='ImportExeedLenghMessage'>
              <span style={{fontSize: '1em'}}>
                <p>
                  One or more of your chapters appear to be too long to import,
                  make sure you have have followed our
                  <a
                    href='https://betareader.io/2020/03/10/importing-manuscripts/'
                    target='blank'>
                    {' '}
                    import guidelines.{' '}
                  </a>
                </p>
                <p>
                  Tip: you can hower over the chapter text and use the sizzor
                  tool to split larger chapters
                </p>
              </span>
            </Trans>
          </Message>
        )}
        <div>
          {documentData.parts.map((part, index, array) => (
            <div key={index} style={{marginTop: '2rem'}} id={`part_${index}`}>
              {index > 0 && index < array.length && (
                <Grid centered>
                  <Grid.Row>
                    <Icon
                      inverted
                      size='large'
                      name='attach'
                      link
                      data-part-index={index}
                      onClick={stitchParts}
                    />
                  </Grid.Row>
                </Grid>
              )}
              <Input
                fluid
                transparent
                placeholder={`${t('Chapter')} ${index + 1}`}
                value={part.title || ''}
                data-part={index}
                onChange={onPartTitleChanged}
                inverted
                style={{
                  paddingLeft: 10,
                  fontWeight: 'bold',
                  fontSize: '1.8rem',
                  paddingBottom: '0.5rem'
                }}
                action={
                  <Icon
                    link
                    data-part={index}
                    name='trash alternate'
                    inverted
                    style={{
                      paddingLeft: 10,
                      fontWeight: 'bold',
                      fontSize: '1.4rem',
                      marginRight: 10
                    }}
                    onClick={removePart}
                  />
                }
              />
              <Header.Subheader>
                <p style={{color: 'grey'}}>
                  {part.characterCount > maxSupportedCharacterCount && (
                    <Icon color='yellow' name='warning sign' />
                  )}
                  {t('WordCountString', {count: part.wordCount})}
                </p>
              </Header.Subheader>
              <ContentBlock
                className='post-processor-part'
                data-part-index={index}
                onClick={splitPart}
                onMouseOver={hoverPart}
                dangerouslySetInnerHTML={{__html: part.html}}
              />
            </div>
          ))}
        </div>
      </Container>
    </Dimmer>
  );
};

export default ChapterFilePostProcessor;
