import styled, { css } from 'styled-components';
import { Icon } from 'semantic-ui-react';

export const ChapterSidebar = styled.div`
  height: 100vh;
  position: fixed;
  background-color: black;
  top: 0px;
  left: 0px;
  padding-top: 100px;
  padding-left: 10px;
  padding-right: 15px;
  text-align: start;
  overflow-y: scroll;
  max-width: 250px;
  min-width: 250px;
  z-index: 2;
  ${({ open }) => open ? css`
      -webkit-transform: translateX(-10px);
      transform: translateX(-10px);
      visibility: visible;
      transition: all 0.5s ease 0s;
      -webkit-transition: all 0.5s ease 0s;
    ` : css`
      background-color: transparent;
      overflow-y: hidden;
      -webkit-transform: translateX(-218px);
      transform: translateX(-218px);
      visibility: visible;
      transition: all 0.5s ease 0s;
      -webkit-transition: all 0.5s ease 0s;
    `}
`;

export const SidebarToggle = styled(Icon)`
  position: absolute;
  right: -5px!important;
`;