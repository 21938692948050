import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Divider, Form, Header, Rating} from 'semantic-ui-react';
import {withTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {authUtil, uxAnalyticsUtil} from '../../../utils';

const LeaveUserReview = ({
  reviewedUser,
  onReviewChanged,
  onCancel,

  t
}) => {
  const userProfile = useSelector(state => state.user.userProfile);

  const [isLoading, setIsLoading] = useState(false);
  const [reviewId, setReviewId] = useState();
  const [rating, setRating] = useState();
  const [text, setText] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchUserReview();
  }, []);

  const fetchUserReview = async () => {
    if (!reviewedUser) {
      return;
    }
    const idToken = await authUtil.getFreshIdToken();
    setIsLoading(true);
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/users/${reviewedUser._id}/reviews/${userProfile._id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => {
        if (!res.ok) {
          throw new Error(res.error);
        }
        return res.json();
      })
      .then(review => {
        if (review) {
          const {_id, rating, text} = review;
          setReviewId(_id);
          setRating(rating);
          setText(text);
        } else {
          // got no review, leave input open
          setOpen(true);
        }
      })
      .catch(err => {
        console.error('failed', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmit = async () => {
    if (!reviewedUser) {
      return;
    }
    const idToken = await authUtil.getFreshIdToken();
    setIsLoading(true);

    uxAnalyticsUtil.trackEvent({
      category: 'UserReviews',
      action: 'Submitted review',
      value: rating
    });

    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/users/${reviewedUser._id}/reviews`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify({
          rating,
          text
        })
      }
    )
      .then(res => res.json())
      .then(body => {
        if (body.msg) {
          throw new Error(body.msg);
        }
        onToggleOpen();
        onReviewChanged && onReviewChanged();
      })
      .catch(err => {
        toast.error(err.message);
        uxAnalyticsUtil.trackException({
          description: err.message,
          fatal: false
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onRatingChanged = (event, data) => {
    setOpen(true);
    setRating(data.rating);
  };

  const onTextChanged = (event, data) => {
    setText(data.value);
  };

  const onToggleOpen = () => {
    uxAnalyticsUtil.trackEvent({
      category: 'UserReviews',
      action: 'Toggled LeaveReview widget',
      label: open ? 'close' : 'open'
    });
    setOpen(!open);
  };

  // render
  return (
    <React.Fragment>
      <Form loading={isLoading}>
        <Header content={t('YourReview')} />
        <Rating
          style={{marginLeft: 5}}
          maxRating={5}
          rating={rating}
          icon='star'
          onRate={onRatingChanged}
        />
        <Form.Field>
          <Form.TextArea value={text} onChange={onTextChanged} />
        </Form.Field>
        <Button.Group floated='right'>
          <Button content={t('Cancel')} onClick={onCancel} />
          <Button
            content={reviewId === undefined ? t('LeaveReview') : t('Update')}
            onClick={onSubmit}
          />
        </Button.Group>
        <Divider hidden />
      </Form>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(LeaveUserReview));
