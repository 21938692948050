import React, { Component } from 'react';
import {
  Icon, Form, Dropdown, Grid, Divider
} from 'semantic-ui-react';
import '../../../../App.css';

// i18n
import { withTranslation } from 'react-i18next';

const minSelectedOptions = [
  {
    key: 0,
    value: 0,
    text: 0
  },
  {
    key: 1,
    value: 1,
    text: 1
  }
];

class CheckboxOptions extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      minSelected: 1,
      maxSelected: 1,
      options: [`${t('Option')} 1`]
    }
  }

  componentDidMount() {
    const { t } = this.props;
    this.setState({
      options: this.props.options.options || [`${t('Option')} 1`],
      minSelected: this.props.options.minSelected,
      maxSelected: this.props.options.maxSelected,
    }, () => {
      this.updateOptions()
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { t } = nextProps;
    if (nextProps.options !== undefined && nextProps.options !== this.props.options) {
      this.setState({
        options: nextProps.options.options || [`${t('Option')} 1`],
        minSelected: nextProps.options.minSelected,
        maxSelected: nextProps.options.maxSelected,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.options !== this.props.options && this.state.nextFocus !== undefined) {
      const elementId = this.state.nextFocus;
      this.setState({
        nextFocus: undefined
      },() => {
        const inputElement = document.getElementById(elementId);
        if (inputElement) {
          inputElement.focus();
          inputElement.select();
        }
      })
    }
  }

  handleOptionChanged = (optionIndex, newValue) => {
    var updatedOptions = [...this.state.options];
    updatedOptions.splice(optionIndex,1, newValue);
    this.setState({
      options: updatedOptions
    });
  }

  removeOption = (optionIndex) => {
    var updatedOptions = [...this.state.options];
    updatedOptions.splice(optionIndex,1);
    this.setState({
      options: updatedOptions
    }, () => {
      this.updateOptions();
    })
  }

  addNewOption = () => {
    const { t } = this.props;
    const updatedOptions = [...this.state.options];
    updatedOptions.push(`${t('Option')} ${updatedOptions.length + 1}`);
    this.setState({
      options: updatedOptions,
      nextFocus: `${this.props.questionId}-${updatedOptions.length-1}`
    }, () => {
      this.updateOptions();
    })
  }

  updateOptions = () => {
    this.props.onOptionsChanged({
      options: this.state.options,
      minSelected: this.state.minSelected || 1,
      maxSelected: this.state.maxSelected || this.state.options.length,
    });
  }

  render() {
    const { t } = this.props;
    const { options } = this.state;
    if (!options) {
      return null;
    }

    const maxSelectedOptions = [];
    for (var i = 1; i <= options.length; i++) {
      maxSelectedOptions.push(
        {
          key: i,
          value: i,
          text: i
        }
      )
    }
    return (
      <div>
        <Grid columns='equal'>
          <Grid.Column floated='left'>
            <Form.Field
              control={Dropdown}
              label={<span style={{fontWeight:'normal'}}>{t('SelectAtLeast')}</span>}
              inline
              options={minSelectedOptions}
              value={this.state.minSelected}
              onChange={(event, data)=>{this.setState({minSelected: data.value},()=>{this.updateOptions()})}}
              />
          </Grid.Column>
          <Grid.Column floated='right'>
            <Form.Field
              control={Dropdown}
              label={<span style={{fontWeight:'normal'}}>{t('SelectAtMost')}</span>}
              inline
              options={maxSelectedOptions}
              value={this.state.maxSelected}
              onChange={(event, data)=>{this.setState({maxSelected: data.value},()=>{this.updateOptions()})}}
              />
          </Grid.Column>
        </Grid>
        <Divider hidden/>
        {
          options.map((option, index, array) => {
            return (
              <Form.Input
                key={index}
                className={`text-line`}
                transparent
                value={option}
                placeholder='Add option'
                onChange={(event, data)=>{this.handleOptionChanged(index, data.value)}}
                onBlur={this.updateOptions}
              >
                <Icon name='square outline' style={{marginRight: '1em'}}/>
                <input id={`${this.props.questionId}-${index}`}/>
                <div style={{cursor: 'pointer', visibility: array.length === 1 ? 'hidden':'visible'}} onClick={() => {this.removeOption(index)}}>
                  <Icon name='close'/>
                </div>
              </Form.Input>
            )
          })
        }
        <Form.Input
          key='add-option'
          className={`text-line`}
          transparent
          placeholder='Add option'
          onFocus={(event)=>{
            event.target.blur();
            this.addNewOption();
          }}
          onKeyPress={(event) => {
            if (event.which === 9 /*TAB*/) {
              event.target.blur();
              this.addNewOption();
            }
          }}
        >
          <Icon name='square outline' style={{marginRight: '1em', position:'relative', top:0}}/>
          <input />
          <div style={{visibility: 'hidden'}}>
            <Icon name='close'/>
          </div>
        </Form.Input>
      </div>
    );
  }

}

export default withTranslation()(CheckboxOptions);
