import React, { Component } from 'react';
import {
  Dropdown, Header, Form, Button
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { authUtil } from '../../../../utils';

class CountryGetter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      country: props.initialValue || null
    };
  }

  componentDidMount() {
    this.fetchCountryOptions();
  }

  fetchCountryOptions = async () => {
    const { lng } = this.props;
    const idToken = await authUtil.getFreshIdToken();
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/global/countries?lng=${lng || 'en'}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        }
        return res.json();
      })
      .then((countryOptions) => {
        if (countryOptions) {
          this.setState({
            countryOptions
          });
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        // done
      });
  }

  handleCountryChange = (event, data) => {
    this.setState({ country: data.value }, this.submit);
  }

  submit = () => {
    const { country } = this.state;
    if (this.props.onUpdate) {
      this.props.onUpdate({ country })
    }
  }

  // render
  render() {
    const { t, updating, wizardMode } = this.props;
    const { country, countryOptions } = this.state;
    return [
      <Header
        key='header'
        size='small'
        content={t('WhereAreYouFrom')}
        subheader={t('OnYourProfile')}
      />,
      <Form.Field
        control={Dropdown}
        key='input'
        placeholder={t('Country')}
        fluid
        search
        value={country}
        options={countryOptions}
        onChange={this.handleCountryChange}
      />,
      <Button
        style={{ marginTop: '1em' }}
        key='next'
        floated='right'
        compact
        content={wizardMode ? (country === null ? t('Skip') : t('Next')) : t('Save')}
        loading={!!updating}
        onClick={this.submit}
      />

    ];
  }
}

export default withTranslation()(CountryGetter)
