import React, { useState } from 'react';
import {
  Popup, Icon
} from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

import LanguageGetter from '../user/userProfileBuilder/fields/languages';
import {
  updateSearchFilters
} from '../../modules/betaOpportunities';
import useOutsideClick from '../../hooks/useOutsideClick';

const DiscoverFilter = ({
  t
}) => {
  const dispatch = useDispatch();

  const searchFilters = useSelector(state => state.betaOpportunities.searchFilters) || {};

  const [open, setOpen] = useState(false);
  const language = searchFilters?.language ?? [];
  const filterRef = React.useRef();

  useOutsideClick(filterRef, () => {
    // close when clicking outside
    setOpen(false);
  });


  const handleLanguageChange = (languages) => {
    dispatch(updateSearchFilters({
      ...searchFilters,
      language: languages.filter(l => l !== null)
    }))
  }

  const hasFilters = () => {
    return language.length > 0;
  }

  const toggleOpen = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(!open);
  }

  return (
    <Popup
      hoverable
      basic
      position='bottom center'
      open={open}
      trigger={(
        <Icon.Group
          style={{ opacity: 0.4 }}
        >
          <Icon
            name={open ? 'chevron up' : 'chevron down'}
            link
            onClick={toggleOpen}
          />
          {
            hasFilters() && (
              <Icon
                name='add'
                corner='top right'
              />
            )
          }
        </Icon.Group>
      )}
      content={(
        <div style={{ minWidth: 300 }} ref={filterRef}>
          <LanguageGetter
            header={t('Languages')}
            subheader={false}
            initialValue={language}
            onUpdate={handleLanguageChange}
            autoUpdate
          />
        </div>
      )}
    />
  );
}

export default withTranslation()(DiscoverFilter);
