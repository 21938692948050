import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Components
import {
  List, Button, Grid, Header, Input
} from 'semantic-ui-react';

import { withTranslation } from 'react-i18next';

// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  addConversationMember,
  removeConversationMember,
  fetchContacts,
  setCurrentConversation
} from '../../modules/message';

import Avatar from '../../components/avatar/avatar';

export class ConversationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: undefined
    };
  }

  componentDidMount() {
    const { idToken } = this.props;
    if (idToken !== undefined) {
      this.props.fetchContacts(idToken);
    }
  }

  removeMember = (event, data) => {
    const { contacts, currentConversation, idToken } = this.props;
    const member = contacts.find(user => user._id === data['data-member-user']);
    if (member) {
      // we have a conversation member to remove. go ahead and do it.
      if (!currentConversation.isNew) {
        // this is an existing conversation, update with backend
        this.props.removeConversationMember(idToken, { conversation: currentConversation, member });
      }
      if (currentConversation.isNew) {
        // this is a new conversation, simply remove it from the currentConversation's members list
        const memberIndex = currentConversation.members.findIndex(user => user._id === member._id);
        const updatedMembersList = [...currentConversation.members];
        updatedMembersList.splice(memberIndex, 1);
        this.props.setCurrentConversation({
          ...currentConversation,
          members: updatedMembersList
        });
      }
    }
  }

  addMember = (event, data) => {
    const { contacts, currentConversation, idToken } = this.props;
    const member = contacts.find(user => user._id === data['data-member-user']);
    if (member) {
      // we have a conversation member to add. go ahead and do it.
      if (!currentConversation.isNew) {
        // this is an existing conversation, update with backend
        this.props.addConversationMember(idToken, { conversation: currentConversation, member });
      }
      if (currentConversation.isNew) {
        // this is a new conversation, simply remove it from the currentConversation's members list
        this.props.setCurrentConversation({
          ...currentConversation,
          members: [...currentConversation.members, member]
        });
      }
    }
  }

  render() {
    const { t, currentConversation, contacts } = this.props;
    const { search } = this.state;
    if (!currentConversation) {
      return null;
    }

    const { members } = currentConversation;

    // filter out the contacts who are not yet selected
    const remainingContacts = contacts.filter((contact) => {
      const alreadySelected = members.findIndex(user => user._id === contact._id) !== -1;
      let stringMatch = true;
      if (search && search.length > 0) {
        stringMatch = (new RegExp(search, 'i')).test(contact.displayName);
      }
      return !alreadySelected && stringMatch;
    });

    return (
      <Grid columns='equal' container style={{ marginTop: '1em', marginBottom: '1em' }}>
        <Grid.Row>
          <Grid.Column>
            <Header content={`${t('To')}:`} />
            <List selection style={{ width: '100%' }}>
              {
                (!members || members.length === 0) && (
                  <List.Item key='no-members' style={{ width: '100%' }} disabled>
                    <List.Content>
                      <List.Description>
                        {t('AddMembersFromContacts')}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                )
              }
              {
                members && members.map(user => (
                  <List.Item
                    key={user._id}
                    style={{ width: '100%' }}
                    disabled={!currentConversation.isNew}
                    data-member-user={user._id}
                    onClick={this.removeMember}
                  >
                    {
                       currentConversation.isNew && (
                         <List.Content floated='right'>
                           <Button size='small' circular basic icon='remove' />
                         </List.Content>
                       )
                    }
                    <List.Content>
                      <List.Header style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Avatar name={user.displayName} diameter={30} />
                        <span style={{ marginLeft: '1em' }}>
                          {user.displayName}
                        </span>
                      </List.Header>
                    </List.Content>
                  </List.Item>
                ))
              }
            </List>
            {
              currentConversation.isNew && [
                /* Display contacts list to add contacts if conversation is not yet started */
                <Header
                  key='contacts-header'
                  content={(
                    <div>
                      <span>
                        {t('Contacts')}
                      </span>
                      <Input
                        placeholder={t('Search')}
                        transparent
                        style={{ marginLeft: '1em', fontSize: '0.8em', border: 'none' }}
                        onChange={(event, data) => this.setState({ search: data.value })}
                      />
                    </div>
                  )}
                />,
                <List key='contacts-list' selection style={{ width: '100%' }}>
                  {
                    remainingContacts.map(user => (
                      <List.Item
                        key={user._id}
                        style={{ width: '100%' }}
                        disabled={!currentConversation.isNew}
                        data-member-user={user._id}
                        onClick={this.addMember}
                      >
                        {
                          currentConversation.isNew && (
                            <List.Content floated='right'>
                              <Button size='small' circular basic icon='add' />
                            </List.Content>
                          )
                        }
                        <List.Content>
                          <List.Header style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Avatar name={user.displayName} diameter={30} />
                            <span style={{ marginLeft: '1em' }}>
                              {user.displayName}
                            </span>
                          </List.Header>
                        </List.Content>
                      </List.Item>
                    ))
                  }
                  {
                    remainingContacts.length === 0 && (
                      <List.Item key='no-contacts' style={{ width: '100%' }} disabled>
                        <List.Content>
                          <List.Description>
                            {t('NothingHere')}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    )
                  }
                </List>
              ]
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}


// redux stuff
// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  userProfile: state.user.userProfile,
  idToken: state.user.idToken,
  currentConversation: state.message.currentConversation,
  contacts: state.message.contacts
});

const mapDispatchToProps = dispatch => bindActionCreators({
  addConversationMember,
  removeConversationMember,
  fetchContacts,
  setCurrentConversation
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationList)));
