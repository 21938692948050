import React from 'react';
import {useTranslation} from 'react-i18next';
import {Icon, Popup} from 'semantic-ui-react';

const BetaIcon = () => {
  const {t} = useTranslation();
  return (
    <Popup
      content={<span>{t('ThisIsABetaFeature')}</span>}
      trigger={<Icon name='lab' style={{cursor: 'pointer'}} />}
    />
  );
};

export default BetaIcon;
