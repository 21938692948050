import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Form,
  Comment,
  Icon,
  Popup,
  Grid,
  Dropdown,
} from 'semantic-ui-react';
import moment from 'moment';
import { useResizeDetector } from 'react-resize-detector';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import debounce from 'lodash/debounce';

import Avatar from '../../components/avatar/avatar';
import { getReaction } from '../../utils/reactionUtil';
import accessUtil from '../../utils/accessUtil';
import authUtil, { getFreshIdToken } from '../../utils/authUtil';

import {
  setActiveInlineComment,
  submitComment,
  setFilteredReaders,
  updateComment,
  editComment,
  editReply,
  updateReply,
  deleteReply,
  editCommentPlaceHolderText,
  removeInlineCommentPlaceholder,
  deleteComment,
  submitReply,
} from '../../modules/comment';

import TextAreaWithSave from '../../components/inputs/TextAreaWithSave';

const WordCount = styled.div`
  font-size: 0.8em;
  font-style: italic;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  ${({ theme, warning }) => css`
    color: ${warning ? theme.font.warning : theme.font.primary};
  `}
`;

const CommentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const CommentTextWithLineBreak = styled(Comment.Text)`
  margin-top: 5px;
  white-space: pre-line;
  hyphens: auto;
  word-break: break-word;
`;

const StyledComment = styled(Comment)`
  :hover {
    z-index: 999;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
`;

type Props = {
  comment: any;
  enableFilters: boolean;
  isActive: boolean;
  hasReplies: boolean;
  showChapterLinks: boolean;
  onSizeChange?: (commentId: string, height: number) => void;
};

const CommentElement: React.FC<Props> = ({
  comment,
  enableFilters,
  isActive,
  hasReplies,
  showChapterLinks,

  onSizeChange,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { height, ref } = useResizeDetector();

  const currentBook = useSelector(state => state.book.currentBook);
  const currentSpineIndex = useSelector(
    state => state.readerApp.currentSpineIndex
  );
  const commentStatusOptions = useSelector(
    state => state.comment.commentStatusOptions
  );
  const user = useSelector(state => state.user.user);

  const [replyText, setReplyText] = useState('');
  const [newReplyText, setNewReplyText] = useState('');
  const [newComment, setNewComment] = useState(
    comment?._id?.substr(0, 11) === 'placeHolder'
  );
  const [newCommentText, setNewCommentText] = useState(comment?.text);
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  /** Focus this comment if in url */
  useEffect(() => {
    const query = queryString.parse(location.search);
    if (comment._id === query.comment) {
      if (query.reply || query.crid) {
        // TODO: align these urls so that they use the same query parameter for comment and reply
        // a reply id is in the url, make sure that the replies are visible
        setShowReplies(true);
      }
      dispatch(
        setActiveInlineComment({ commentId: comment._id, show: 'sidebar' })
      );
    }
  }, []);

  /** Inform parent about size change */
  const debouncedOnSizeChange = useCallback(
    debounce((commentId: string, newHeight: number) => {
      onSizeChange && onSizeChange(commentId, newHeight);
    }, 100), // Adjust the debounce delay as needed
    []
  );
  useEffect(() => {
    debouncedOnSizeChange(comment._id, height);
  }, [height]);

  const handleReplyTextUpdate = (event, data) => {
    setReplyText(data.value);
  };

  const handleNewCommentTextUpdate = (event, data) => {
    newComment && dispatch(editCommentPlaceHolderText(comment._id, data.value)); // only set comment text in redux on placeholder comment
    setNewCommentText(data.value);
  };

  const onSubmitReply = async () => {
    if (!(replyText?.length > 0)) {
      return;
    }
    const idToken = await authUtil.getFreshIdToken();
    dispatch(submitReply(idToken, comment, { text: replyText }));
    setShowReplyForm(false);
    setReplyText('');
  };

  const onSubmitComment = async comment => {
    setIsSubmitting(true);
    const idToken = await authUtil.getFreshIdToken();
    if (!(newCommentText?.length > 0) || !currentBook) {
      return;
    }
    if (newComment) {
      dispatch(removeInlineCommentPlaceholder(comment._id));
      dispatch(setActiveInlineComment(undefined));
    }
    const currentChapter = currentBook.parts[currentSpineIndex];
    // if we have a chapter to comment on
    if (currentChapter !== undefined) {
      // submit the comment first
      dispatch(
        submitComment(
          idToken,
          currentBook._id,
          currentChapter._id,
          newCommentText,
          {
            kind: 'inline',
            inlineInfo: comment.inlineInfo,
          },
          commentId => {
            setIsSubmitting(false);

            // make sure we blur the focus otherwise we loose the ability to show customToolBar
            if (document.activeElement !== document.body) {
              document.activeElement.blur();
            }
          }
        )
      );
    }
    setNewCommentText('');
  };

  const onDeleteComment = async (event, data) => {
    const idToken = await getFreshIdToken();
    dispatch(deleteComment(idToken, data['data-comment-id']));
  };

  const onDeleteReply = async (event, data) => {
    const idToken = await getFreshIdToken();
    dispatch(
      deleteReply(idToken, {
        bookId: comment.book._id,
        chapterId: comment.part._id,
        commentId: comment._id,
        replyId: data['data-reply-id'],
      })
    );
  };

  const toggleReplyForm = event => {
    preventDefaults(event);
    setShowReplyForm(!showReplyForm);
    setReplyText('');
  };

  const toggleShowReplies = event => {
    preventDefaults(event);
    setShowReplies(!showReplies);
  };

  const commentClicked = event => {
    console.log('commentElement commentClicked', comment);
    preventDefaults(event);
    if (newComment) {
      return;
    }
    // this seems to not be needed
    if (comment.kind === 'InlineComment') {
      dispatch(
        setActiveInlineComment({ commentId: comment._id, show: 'inline' })
      );
    } else {
      // dispatch(
      //   setActiveInlineComment({commentId: comment._id, show: 'inline'})
      // );
    }
    // if we aren't in the content, go there
    if (location.pathname.includes('feedback')) {
      const commentUrl = `/books/${comment.book._id}/content/v${comment.contentVersion.versionNumber}/${comment.part._id}?cid=${comment._id}&kind=${comment.kind}`;
      history.push(commentUrl); // otherwise, do add to history so the user can use the browser back button
    }
  };

  const handleCommentStatusUpdate = async (e, data) => {
    const idToken = await authUtil.getFreshIdToken();
    dispatch(
      updateComment(idToken, {
        bookId: comment.book._id,
        chapterId: comment.part._id,
        commentId: comment._id,
        status: data.value,
      })
    );
  };

  const handleCommentUpdate = async comment => {
    const idToken = await authUtil.getFreshIdToken();
    dispatch(
      updateComment(idToken, {
        bookId: comment.book._id,
        chapterId: comment.part._id,
        commentId: comment._id,
        text: newCommentText,
      })
    );
    setNewCommentText('');
  };

  const handleReplyUpdate = async reply => {
    const idToken = await authUtil.getFreshIdToken();
    dispatch(
      updateReply(idToken, {
        bookId: comment.book._id,
        chapterId: comment.part._id,
        commentId: comment._id,
        replyId: reply._id,
        text: replyText,
      })
    );
    setReplyText('');
    setShowReplyForm(false);
  };

  const onEditCommentText = (e, data) => {
    dispatch(editComment({ commentId: comment._id, editing: true }));
    setNewCommentText(comment.text);
  };

  const onCancelEditCommentText = (e, data) => {
    if (newComment) {
      dispatch(removeInlineCommentPlaceholder(comment._id));
    } else {
      dispatch(editComment({ commentId: comment._id, editing: false }));
    }
    setNewCommentText('');
    dispatch(setActiveInlineComment({}));
  };

  const onEditingReplyText = (e, data) => {
    const reply = comment.replies.find(
      replyObject => replyObject._id === data['data-reply-id']
    );
    if (reply) {
      dispatch(
        editReply({ commentId: comment._id, replyId: reply._id, editing: true })
      );
      setReplyText(reply.text);
      setShowReplyForm(false);
    }
  };

  const onCancelEditingReplyText = (e, data) => {
    if (newComment) {
      dispatch(removeInlineCommentPlaceholder());
    } else {
      dispatch(
        editReply({
          commentId: comment._id,
          replyId: data['data-reply-id'],
          editing: false,
        })
      );
    }
    setNewReplyText('');
  };

  const goToCommentInContent = event => {
    console.log('gotocomments incontnent');
    preventDefaults(event);
    if (!enableFilters) {
      return;
    }
    dispatch(setFilteredReaders(comment.user));
  };

  const preventDefaults = event => {
    event.stopPropagation();
    event.preventDefault();
  };

  if (comment.isLast && comment._id === undefined) {
    return null;
  }

  return (
    <div ref={ref}>
      <StyledComment
        className={isActive ? 'comment-element-active' : 'comment-element'}
        onClick={commentClicked}
      >
        <Comment.Content>
          <CommentHeader>
            <div style={{ width: 25, height: 25 }}>
              <Avatar
                name={comment.user?.displayName ?? t('Unnamed')}
                userId={comment.user?._id ?? null}
                diameter={25}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                <Comment.Author
                  onClick={goToCommentInContent}
                  as="span"
                  style={{ marginLeft: 5, whiteSpace: 'pre', flex: 1 }}
                >
                  {comment.user?.displayName ?? t('Unnamed')}
                </Comment.Author>
                {showChapterLinks && (
                  <Comment.Metadata key="title" style={{ marginLeft: 'auto' }}>
                    <span>
                      {comment.inlineInfo
                        ? t('InlineComment')
                        : t('ChapterComment')}
                    </span>
                    <br />
                    {comment.part?.title !== undefined && (
                      <span>
                        <Link
                          to={`/books/${comment.book._id}/content/v${comment.contentVersion.versionNumber}/${comment.part._id}?cid=${comment._id}`}
                          // onClick={goToCommentInContent}
                        >
                          {comment.part.title}
                        </Link>
                        &nbsp; (v{comment.contentVersion.versionNumber})
                      </span>
                    )}
                  </Comment.Metadata>
                )}
              </div>
              <Comment.Metadata
                style={{
                  fontWeight: 'normal',
                  fontSize: '0.9em',
                  opacity: 0.5,
                  marginLeft: 5,
                }}
              >
                {comment.contentUpdatedAt
                  ? moment(comment.contentUpdatedAt).format('llll')
                  : moment(comment.createdAt).format('llll')}
              </Comment.Metadata>
            </div>
            {user.uid === comment.user?._id && (
              <div
                data-comment-id={comment._id}
                style={{ position: 'absolute', right: 0 }}
              >
                <Dropdown
                  icon={{
                    name: 'ellipsis horizontal',
                    rotated: 'clockwise',
                    color: 'grey',
                  }}
                  direction="left"
                >
                  <Dropdown.Menu className="br-text">
                    {!comment.reaction && (
                      <Dropdown.Item
                        content={
                          <span style={{ fontSize: '0.8em' }}>{t('Edit')}</span>
                        }
                        data-comment-id={comment._id}
                        onClick={onEditCommentText}
                      />
                    )}
                    <Dropdown.Item
                      content={
                        <span style={{ fontSize: '0.8em' }}>{t('Delete')}</span>
                      }
                      data-comment-id={comment._id}
                      onClick={onDeleteComment}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            {comment.warning && (
              <Popup
                trigger={
                  <Icon
                    style={{ marginLeft: 'auto', cursor: 'pointer' }}
                    name="warning sign"
                  />
                }
                content={t(comment.warning.key)}
              />
            )}
          </CommentHeader>
          {!comment.editing && (
            <CommentTextWithLineBreak>
              {/* {comment.reaction && getReaction(comment.reaction)} */}
              {!newComment && comment.text}
            </CommentTextWithLineBreak>
          )}
          <Comment.Actions style={{ cursor: 'pointer' }}>
            {comment.reaction && (
              <Comment.Action>{getReaction(comment.reaction)}</Comment.Action>
            )}
            {!showReplies &&
              !newComment &&
              !comment.editing &&
              accessUtil.isAllowed(currentBook.access, ['edit', 'comment']) && (
                <Comment.Action
                  data-comment-id={comment._id}
                  onClick={toggleReplyForm}
                >
                  {t('EnterReply')}
                </Comment.Action>
              )}
            {hasReplies && [
              !showReplies && (
                <Comment.Action
                  key="show-replies-action"
                  data-comment-id={comment._id}
                  onClick={toggleShowReplies}
                >
                  {t('ShowXReplies', { count: comment.replies.length })}
                </Comment.Action>
              ),
              showReplies && (
                <Comment.Action
                  key="hide-replies-action"
                  data-comment-id={comment._id}
                  onClick={toggleShowReplies}
                >
                  {t('HideXReplies', { count: comment.replies.length })}
                </Comment.Action>
              ),
            ]}
            {user.uid === currentBook.author._id && (
              <Comment.Action
                style={{ float: 'right', whiteSpace: 'nowrap', display: 'bo' }}
              >
                <Dropdown
                  text={t(`Status${(comment.status || '').toUpperCase()}`)}
                  direction="left"
                  inline
                  options={commentStatusOptions}
                  defaultValue={comment.status}
                  onChange={handleCommentStatusUpdate}
                />
              </Comment.Action>
            )}
          </Comment.Actions>
        </Comment.Content>
        {(newComment || comment.editing) && ( // form for inputing new comments
          <Form reply>
            <Form.Field
              control={TextAreaWithSave}
              data-comment-id={comment._id}
              onClick={e => {
                preventDefaults(e);
              }}
              value={comment.editing ? newCommentText : comment.text} // show the local state text only on update edits
              // value={newCommentText} // show the local state text only on update edits
              onChange={handleNewCommentTextUpdate}
              onSave={() => {
                if (newComment) {
                  onSubmitComment(comment);
                } else {
                  handleCommentUpdate(comment);
                }
              }}
              autoFocus
            />
            <Grid padded="horizontally" columns={2}>
              <WordCount warning={newCommentText?.length > 2000}>
                {Number(newCommentText?.length ?? 0).toLocaleString()} /{' '}
                {Number(2000).toLocaleString()}
              </WordCount>
              <Grid.Row>
                <Button
                  data-comment-id={comment._id}
                  disabled={
                    !newCommentText ||
                    newCommentText?.length === 0 ||
                    newCommentText?.length > 2000
                  }
                  onClick={e => {
                    preventDefaults(e);
                    if (newComment) {
                      onSubmitComment(comment);
                    } else {
                      handleCommentUpdate(comment);
                    }
                  }}
                  content={t('LeaveComment')}
                  loading={isSubmitting}
                  basic={
                    !newCommentText ||
                    (newCommentText &&
                      !newCommentText.length > 0 &&
                      newComment) ||
                    (newCommentText &&
                      newCommentText.length > 0 &&
                      !newComment &&
                      newCommentText === comment.text)
                  }
                  primary
                  size="tiny"
                />
                <Button
                  data-comment-id={comment._id}
                  onClick={e => {
                    preventDefaults(e);
                    onCancelEditCommentText(comment);
                  }}
                  content={t('Cancel')}
                  disabled={isSubmitting}
                  basic
                  size="tiny"
                  floated="right"
                />
              </Grid.Row>
            </Grid>
          </Form>
        )}
        {hasReplies && showReplies && (
          <Comment.Group style={{ padding: '1em 0 1em 0em' }}>
            {comment.replies.map((reply, index, array) => (
              <Comment key={reply._id}>
                <CommentHeader>
                  <div style={{ width: 22, height: 22 }}>
                    <Avatar
                      name={
                        reply.user.displayName && reply.user.displayName !== ''
                          ? reply.user.displayName
                          : t('Unnamed')
                      }
                      userId={reply.user._id}
                      diameter={22}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Comment.Author
                        as="span"
                        style={{ marginLeft: 5, whiteSpace: 'pre' }}
                      >
                        {reply.user.displayName && reply.user.displayName !== ''
                          ? reply.user.displayName
                          : t('Unnamed')}
                      </Comment.Author>
                      {user.uid === reply.user._id && (
                        <div
                          data-reply-id={reply._id}
                          style={{ position: 'absolute', right: 0 }}
                        >
                          <Dropdown
                            icon={{
                              name: 'ellipsis horizontal',
                              rotated: 'clockwise',
                              color: 'grey',
                            }}
                            direction="left"
                          >
                            <Dropdown.Menu className="br-text">
                              {!reply.reaction && (
                                <Dropdown.Item
                                  content={
                                    <span style={{ fontSize: '0.8em' }}>
                                      {t('Edit')}
                                    </span>
                                  }
                                  data-reply-id={reply._id}
                                  data-comment-id={comment._id}
                                  onClick={onEditingReplyText}
                                />
                              )}
                              <Dropdown.Item
                                content={
                                  <span style={{ fontSize: '0.8em' }}>
                                    {t('Delete')}
                                  </span>
                                }
                                data-reply-id={reply._id}
                                data-comment-id={comment._id}
                                onClick={onDeleteReply}
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}
                    </div>
                    <Comment.Metadata
                      style={{
                        fontWeight: 'normal',
                        fontSize: '0.9em',
                        opacity: 0.5,
                        marginLeft: 5,
                      }}
                    >
                      {reply.updatedAt !== reply.createdAt
                        ? moment(reply.updatedAt).format('llll')
                        : moment(reply.createdAt).format('llll')}
                    </Comment.Metadata>
                  </div>
                  {reply.reaction && !reply.editing && (
                    <Comment.Metadata>
                      {getReaction(reply.reaction)}
                    </Comment.Metadata>
                  )}
                </CommentHeader>
                <Comment.Content>
                  <CommentTextWithLineBreak>
                    {!reply.editing && reply.text}
                  </CommentTextWithLineBreak>
                  {index === array.length - 1 &&
                    !reply.editing &&
                    accessUtil.isAllowed(currentBook.access, [
                      'edit',
                      'comment',
                    ]) && (
                      <Comment.Actions>
                        <Comment.Action
                          data-comment-id={comment._id}
                          onClick={toggleReplyForm}
                        >
                          {t('EnterReply')}
                        </Comment.Action>
                      </Comment.Actions>
                    )}
                </Comment.Content>
                {reply.editing && ( // inline replyform
                  <Form reply>
                    <Form.TextArea
                      control={TextAreaWithSave}
                      onClick={e => {
                        preventDefaults(e);
                      }}
                      defaultValue={replyText}
                      data-comment-id={comment._id}
                      data-reply-id={reply._id}
                      onChange={handleReplyTextUpdate}
                      onSave={() => {
                        if (reply.editing) {
                          handleReplyUpdate(reply);
                        } else {
                          onSubmitReply();
                        }
                      }}
                    />
                    <Grid padded="horizontally" columns={2}>
                      <Grid.Row>
                        <Button
                          data-comment-id={comment._id}
                          data-reply-id={reply._id}
                          disabled={
                            !replyText || (replyText && !replyText.length > 0)
                          }
                          onClick={e => {
                            preventDefaults(e);
                            if (reply.editing) {
                              handleReplyUpdate(reply);
                            } else {
                              onSubmitReply();
                            }
                          }}
                          loading={isSubmitting}
                          content={t('EnterReply')}
                          basic={
                            !newReplyText ||
                            (newReplyText && !newReplyText.length > 0) ||
                            (newReplyText &&
                              newReplyText.length > 0 &&
                              !reply &&
                              reply.text === newReplyText)
                          }
                          primary
                          size="tiny"
                        />
                        <Button
                          data-comment-id={comment._id}
                          data-reply-id={reply._id}
                          onClick={(e, data) => {
                            preventDefaults(e);
                            onCancelEditingReplyText(e, data);
                          }}
                          disabled={isSubmitting}
                          content={t('Cancel')}
                          basic
                          size="tiny"
                          // color='orange'
                          floated="right"
                        />
                      </Grid.Row>
                    </Grid>
                  </Form>
                )}
              </Comment>
            ))}
          </Comment.Group>
        )}
        {
          // form for adding new replies
          showReplyForm && !newComment && (
            <Form reply>
              <Form.TextArea
                control={TextAreaWithSave}
                data-comment-id={comment._id}
                onClick={e => {
                  preventDefaults(e);
                }}
                value={replyText}
                onChange={handleReplyTextUpdate}
                onSave={() => {
                  onSubmitReply();
                }}
              />
              <Grid padded="horizontally" columns={2}>
                <Grid.Row>
                  <Button
                    data-comment-id={comment._id}
                    disabled={
                      !replyText || (replyText && !replyText.length > 0)
                    }
                    onClick={e => {
                      preventDefaults(e);
                      onSubmitReply();
                    }}
                    content={t('EnterReply')}
                    loading={isSubmitting}
                    basic={
                      !newReplyText ||
                      (newReplyText && !newReplyText.length > 0)
                    }
                    primary
                    size="tiny"
                  />
                  <Button
                    data-comment-id={comment._id}
                    onClick={(e, data) => {
                      preventDefaults(e, data);
                      toggleReplyForm(e, data);
                    }}
                    disabled={isSubmitting}
                    content={t('Cancel')}
                    basic
                    size="tiny"
                    floated="right"
                  />
                </Grid.Row>
              </Grid>
            </Form>
          )
        }
        {comment.copyOf && (
          <span style={{ opacity: 0.8, fontSize: '0.8em', color: 'grey' }}>
            <em>{t('CommentsCopiedFromOriginal')}</em>
          </span>
        )}
      </StyledComment>
    </div>
  );
};
export default CommentElement;
