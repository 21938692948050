import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Segment, Header
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  updateUserProfile,
  updateUser
} from '../../../modules/user';
import { authUtil } from '../../../utils';

import DisplayNameGetter from './fields/displayName';
import CountryGetter from './fields/country';
import YoBGetter from './fields/yob';
import GenresGetter from './fields/genresGetter';
import LanguagesGetter from './fields/languages';
import BioGetter from './fields/bio';
import EmailPreferenceGetter from './fields/emailPreferences';
import ReaderListConsentGetter from './fields/readerListConsent';
import RoleGetter from './fields/role';

class UserProfileBuilder extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      userProfile
    } = this.props;
    if (this.props.onAsked) {
      this.props.onAsked();
    }
  }

  getGenderOptions = () => {
    const { t } = this.props;
    const genderOptions = [
      {
        key: 'f', value: 'f', text: t('female')
      },
      {
        key: 'm', value: 'm', text: t('male')
      },
      {
        key: 'o', value: 'o', text: t('other')
      },
      {
        key: 'na', value: 'na', text: t('DontWantToShare')
      }
    ];
    return genderOptions;
  }

  currentThingToAskFor = () => {
    const { userProfile, updatingUserProfile, missingInformation } = this.props;
    const { countryOptions, languageOptions } = this.state;
    // order of things to ask for
    /*
      displayName
      role
      country
      yob
      preferredGenres
      preferredLanguages
      bio
    */
    if (missingInformation.includes('displayName')) {
      return (
        <DisplayNameGetter
          onUpdate={this.updateProfile}
          updating={updatingUserProfile.includes('displayName')}
          wizardMode
        />
      );
    }
    if (missingInformation.includes('role')) {
      return (
        <RoleGetter
          onUpdate={this.updateProfile}
          updating={updatingUserProfile.includes('role')}
          wizardMode
        />
      );
    }
    if (missingInformation.includes('country')) {
      return (
        <CountryGetter
          options={countryOptions}
          onUpdate={this.updateProfile}
          updating={updatingUserProfile.includes('country')}
          wizardMode
        />
      );
    }
    if (missingInformation.includes('yob')) {
      return (
        <YoBGetter
          onUpdate={this.updateProfile}
          updating={updatingUserProfile.includes('yob')}
          wizardMode
        />
      );
    }
    if (missingInformation.includes('preferredGenres')) {
      return (
        <GenresGetter
          onUpdate={value => this.updateProfile({ preferredGenres: value })}
          updating={updatingUserProfile.includes('preferredGenres')}
          wizardMode
        />
      );
    }
    if (missingInformation.includes('preferredLanguages')) {
      return (
        <LanguagesGetter
          options={languageOptions}
          onUpdate={value => this.updateProfile({ preferredLanguages: value })}
          updating={updatingUserProfile.includes('preferredLanguages')}
          wizardMode
        />
      );
    }
    if (missingInformation.includes('bio')) {
      return (
        <BioGetter
          onUpdate={this.updateProfile}
          updating={updatingUserProfile.includes('bio')}
          wizardMode
        />
      );
    }
    if (missingInformation.includes('emailPreferences')) {
      return (
        <EmailPreferenceGetter
          onUpdate={this.updateProfile}
          emailPreferences={userProfile.emailPreferences}
          updating={updatingUserProfile.includes('emailPreferences')}
          wizardMode
        />
      );
    }
    if (missingInformation.includes('readerListConsent')) {
      return (
        <ReaderListConsentGetter
          onUpdate={this.updateProfile}
          updating={updatingUserProfile.includes('readerListConsent')}
          wizardMode
        />
      );
    }
  }

  updateProfile = async (data) => {
    const idToken = await authUtil.getFreshIdToken();
    this.props.updateUserProfile(idToken, { ...data });
  }

  // render
  render() {
    const { t, missingInformation } = this.props;
    const completed = missingInformation.length === 0;
    return (
      <React.Fragment>
        {
          !completed && (
            <div
              style={{
                minWidth: 'calc(100vw / 2)', maxWidth: 500, padding: '1em'
              }}
            >
              <Header
                content={(
                  <React.Fragment>
                    <span>
                      {t('UpdateProfileHeader')}
                    </span>
                  </React.Fragment>
                )}
              />
              <Segment raised clearing color='orange'>
                {this.currentThingToAskFor()}
              </Segment>
            </div>
          )
        }
        {
          completed && (
            <React.Fragment>
              <Header size='large' content={t('Thanks')} />
            </React.Fragment>
          )
        }
      </React.Fragment>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  userProfile: state.user.userProfile,
  updatingUserProfile: state.user.updatingUserProfile,
  currentLanguage: state.user.currentLanguage
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateUserProfile,
  updateUser,
  changePage: newPage => push(newPage)
}, dispatch)

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileBuilder)))
