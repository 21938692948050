import React, { Component } from 'react';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import {
  Segment, Container, Button, Header, Dimmer, Loader
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  resetActiveAccount
} from '../../../../modules/account';
import {
  getUserProfile
} from '../../../../modules/user';

import {
  authUtil, uxAnalyticsUtil
} from '../../../../utils';

export class AcceptAccountInvitation extends Component {
  constructor(props) {
    super(props);
    const parsedQueryString = queryString.parse(props.location.search);

    this.state = {
      token: parsedQueryString.token,
      account: parsedQueryString.account
    };
  }

  componentDidMount() {
    const { history } = this.props;
    uxAnalyticsUtil.trackPageView(history.location.pathname);
    this.fetchInvitation();
  }

  fetchInvitation = async () => {
    const { idToken, t } = this.props;
    const { account, token } = this.state;
    try {
      this.setState({
        loading: true
      });
      fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/accounts/${account}/invitations/${token}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'br-token': idToken
          }
        })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error();
        })
        .then((invitation) => {
          this.setState({
            invitation
          })
        })
        .catch(() => {
          toast.error(t('SomethingWentWrong'));
        })
        .finally(() => {
          this.setState({
            loading: false
          });
        });
    } catch (e) {
      toast.error(t('SomethingWentWrong'));
    }
  }

  acceptInvitation = () => {
    const {
      t, idToken
    } = this.props;
    const { invitation } = this.state;
    try {
      this.setState({
        isAcceptingInviation: true
      });
      fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/accounts/${invitation.account._id}/accept-invitation`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'br-token': idToken
          },
          body: JSON.stringify({
            token: invitation.token
          })
        })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error();
        })
        .then(async () => {
          toast.info(t('InvitationAccepted'));
          const idToken = await authUtil.getFreshIdToken();
          this.props.getUserProfile(idToken);
          this.props.resetActiveAccount();
          this.props.changePage('/mymanuscripts');
        })
        .catch(() => {
          toast.error(t('SomethingWentWrong'));
        })
        .finally(() => {
          this.setState({
            isAcceptingInviation: false
          });
        });
    } catch (e) {
      toast.error(t('SomethingWentWrong'));
    }
  }

  render() {
    const { userProfile, t } = this.props;
    const { isAcceptingInviation, invitation, loading } = this.state;
    if (loading) {
      return (
        <Segment>
          <Dimmer active>
            <Loader />
          </Dimmer>
        </Segment>
      );
    }
    if (userProfile && invitation) {
      return (
        <Container>
          <Segment placeholder clearing textAlign='center'>
            <Header
              content={t('YouHaveBeenInvitedToJoinAccountX', { x: invitation.invitedBy?.displayName })}
              subheader={t('AcceptAccountInvitationMsg')}
            />
            <Segment.Inline>
              <Button
                positive
                fluid
                content={t('Accept')}
                onClick={this.acceptInvitation}
                loading={isAcceptingInviation}
              />
            </Segment.Inline>
          </Segment>
        </Container>
      );
    }
    return null;
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getUserProfile,
  resetActiveAccount,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceptAccountInvitation)));
