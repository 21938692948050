import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Trans } from 'react-i18next';

const privacyIcons = {
  unpublished: 'eye slash',
  private: 'lock',
  closed: 'unlock',
  listed: 'lock open'
};

const privacyOptions = (bookId) => {
  return (
    [
      {
        key: 'unpublished',
        text: (<span><Icon name='eye slash'/> <Trans i18nKey='PrivacyLevel_state_unpublished'>Unpublished</Trans></span>),
        value: 'unpublished',
        iconName: 'eye slash',
        infoText: (
          <Trans i18nKey='PrivacyLevel_infotext_unpublished'>
            <span>
              Your book is not published. Move the book to <em>private</em>, <em>closed</em> or <em>listed</em> for readers to be able to see and read it.
            </span>
          </Trans>
        )
      },
      {
        key: 'private',
        text: (<span><Icon name='lock'/> <Trans i18nKey='PrivacyLevel_state_private'>Private</Trans></span>),
        value: 'private',
        iconName: 'lock',
        infoText: <Trans i18nKey='PrivacyLevel_infotext_private'><span>Your book is not listed anywhere. Only you can invite readers.</span></Trans>
      },
      {
        key: 'closed',
        text: (<span><Icon name='unlock'/> <Trans i18nKey='PrivacyLevel_state_closed'>Closed</Trans></span>),
        value: 'closed',
        iconName: 'unlock',
        infoText: <Trans i18nKey='PrivacyLevel_infotext_closed'><span>Your book's presentation can be seen by anyone with <a href={`${process.env.REACT_APP_READER_HOST}/discover/${bookId}`}>the link</a>. Readers can request an invitation, but you need to approve each request.</span></Trans>
      },
      {
        key: 'listed',
        text: (<span><Icon name='lock open'/> <Trans i18nKey='PrivacyLevel_state_listed'>Listed</Trans></span>),
        value: 'listed',
        iconName: 'lock open',
        infoText: <Trans i18nKey='PrivacyLevel_infotext_listed'><span>Your book is listed <a href='/discover' target='new'>here</a>. Readers can request an invitation, but you need to approve each request.</span></Trans>
      }
    ]
  );
};


const privacy = {
  privacyOptions,
  get: (book, key) => {
    const option = privacyOptions(book._id).find(o => o.value === book.privacy);
    return option ? option[key] : '';
  },
  privacyIcons
};
export default privacy;
