import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Card, Header, Grid, Menu} from 'semantic-ui-react';
import {Media} from 'src/utils/Media';
import {withTranslation} from 'react-i18next';
import qs from 'qs';
import {push} from 'connected-react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {uxAnalyticsUtil, authUtil} from '../../../utils';

import ProductCard from './productCard';
import CancelSubscriptionModal from './cancelSubscriptionModal';
import ChangeSubscriptionModal from './changeSubscriptionModal';
import PaymentSuccessModal from './paymentSuccessModal';
import Footer from '../../home/footer';
import ProductToSMessage from '../../../components/payments/productToSMessage';

import {getUserProfile} from '../../../modules/user';
import {fetchProducts} from '../../../modules/subscription';

export class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planIntervalFilter: 'monthly'
    };
    this.yearlyButton = null;
  }

  componentDidMount = () => {
    uxAnalyticsUtil.trackPageView(this.props.match.path);
    window.addEventListener('resize', this.updateDimensions);
    const queryObject = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    if (queryObject.coupon !== undefined) {
      this.setState({
        coupon: queryObject.coupon
      });
    }
    this.fetchProducts();
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions);
  };

  componentDidUpdate = prevProps => {
    const {userProfile, products, t} = this.props;
    const {planIntervalFilter} = this.state;
    if (prevProps.userProfile) {
      const previousPricingPlanId =
        prevProps.userProfile.account.subscription.pricingPlan.externalId;
      const currentPricingPlanId =
        userProfile.account.subscription.pricingPlan.externalId;
      // check if we moved plan
      if (previousPricingPlanId !== currentPricingPlanId) {
        toast.info(t('SubscriptionChangeSuccess'));
      }
    }
    // select the active interval button
    if (userProfile && !planIntervalFilter) {
      const currentPlanInterval =
        userProfile.account.subscription.pricingPlan.billingInterval;
      // if current plan is free, set the default active button to monthly
      if (currentPlanInterval === 'none' || 'free') {
        this.setState({planIntervalFilter: 'monthly'});
      } else {
        // If we have a montly or yearly plan set it to planIntervalFilter
        this.setState({planIntervalFilter: currentPlanInterval});
      }
    }
  };

  fetchProducts = async () => {
    const idToken = await authUtil.getFreshIdToken();
    const {userProfile} = this.props;
    this.props.fetchProducts(idToken);
    // select the active interval button
    if (userProfile) {
      const currentPlanInterval =
        userProfile.account.subscription.pricingPlan.billingInterval;
      // if current plan is free, set the default active button to monthly
      if (currentPlanInterval === 'none' || 'free') {
        this.setState({planIntervalFilter: 'monthly'});
      } else {
        // If we have a montly or yearly plan set it to planIntervalFilter
        this.setState({planIntervalFilter: currentPlanInterval});
      }
    }
  };

  updateDimensions = () => {
    this.setState({width: window.innerWidth, height: window.innerHeight});
  };

  handleYearlyButton = node => {
    this.yearlyButton = node;
    this.forceUpdate();
  };

  clickedProductCard = (product, newPricingPlan) => {
    const {isEmbedded, userProfile, t} = this.props;
    const {coupon} = this.state;
    console.log('clickedProductCard', product, newPricingPlan);
    // if user clicked currentProduct
    if (!userProfile) {
      // not logged in. send the user to the sign-up page and then back here
      const path = `/signup?redirect=${encodeURIComponent('/products')}`;
      if (isEmbedded) {
        // the app is embedded. open the link in a new window using window.open()
        window.open(`${process.env.REACT_APP_WEB_HOST}${path}`, '_blank');
      } else {
        // the app is not embedded. use the react changePage to keep browsing history intact
        this.props.changePage(path);
      }
      return;
    }
    if (
      userProfile.account.subscription.pricingPlan.externalId ===
      newPricingPlan.externalId
    ) {
      return;
    }
    // track page view
    uxAnalyticsUtil.trackEvent({
      category: 'Subscriptions',
      action: 'clicked-subscription-plan',
      label: newPricingPlan.internalId
    });

    // if user clicked on free when on a paid plan
    if (
      userProfile.account.subscription.pricingPlan.externalId !==
        newPricingPlan.externalId &&
      newPricingPlan.externalId === 'free'
    ) {
      this.showCancelModal(true);
      return;
    }

    // if user wanted to move from one payed plan to another payed plan
    if (
      userProfile.account.subscription.pricingPlan.externalId !==
        newPricingPlan.externalId &&
      newPricingPlan.externalId !== 'free' &&
      userProfile.account.subscription.pricingPlan.internalId !== 'free'
    ) {
      this.setState({
        newProductId: newPricingPlan.product,
        newPricingPlanId: newPricingPlan.externalId
      });
      this.showChangePlanModal(true);
      return;
    }
    // else if user is on free and want to buy a product
    try {
      window.Paddle.Checkout.open({
        coupon,
        product: newPricingPlan.externalId,
        email: userProfile.email,
        passthrough: JSON.stringify({
          account: userProfile.account._id,
          pricingPlan: newPricingPlan.externalId
        }),
        title: `${t(product.title)} ${t(newPricingPlan.billingInterval)}`,
        message: t(product.description),
        loadCallback: data => this.onCheckoutLoad(data, newPricingPlan),
        successCallback: this.onCheckoutSuccess,
        closeCallback: this.onCheckoutClose
      });
    } catch (e) {
      toast.error(t('SomethingWentWrong'));
    }
  };

  onCheckoutLoad = (checkoutData, pricingPlan) => {
    uxAnalyticsUtil.trackPageView(
      `${this.props.match.path}/${pricingPlan.internalId}`
    );
  };

  onCheckoutSuccess = async data => {
    const idToken = await authUtil.getFreshIdToken();
    this.showPaymentSuccessModal(true, data);
    this.props.getUserProfile(idToken);
  };

  onCheckoutClose = data => {
    const {t} = this.props;
    if (data && data.checkout && data.checkout.completed) {
      this.showPaymentSuccessModal(true, data);
    } else {
      toast.info(t('Cancelled'));
    }
  };

  showChangePlanModal = show => {
    this.setState({
      showChangePlanModal: show
    });
  };

  showPaymentSuccessModal = (show, data) => {
    this.setState({
      showPaymentSuccessModal: show,
      paymentSuccessData: data
    });
  };

  showCancelModal = show => {
    this.setState({
      showCancelModal: show
    });
  };

  renderProducts() {
    const {products, userProfile} = this.props;
    const {planIntervalFilter} = this.state;
    const usersSubscription =
      (userProfile &&
        userProfile.account &&
        userProfile.account.subscription) ||
      {};
    const usersPricingPlan = usersSubscription.pricingPlan || {};
    const usersProduct = usersPricingPlan.product || {};
    if (products.length > 0) {
      const productList = products.map(product => {
        const isCurrentProduct = usersProduct._id === product._id;
        const isCancelled =
          isCurrentProduct && usersSubscription.status === 'deleted';
        const validUntilDate = isCancelled && usersSubscription.validUntilDate;
        return (
          <ProductCard
            key={product._id}
            currentProduct={isCurrentProduct}
            isCancelled={isCancelled}
            validUntilDate={validUntilDate}
            currentPricingplanId={usersPricingPlan._id}
            currentBillingInterval={usersPricingPlan.billingInterval}
            planIntervalFilter={planIntervalFilter}
            product={product}
            pricingPlans={product.pricingPlans}
            onPress={(pricingPlan, localPriceObject) =>
              this.clickedProductCard(product, pricingPlan)
            }
            isLoggedIn={!!userProfile}
          />
        );
      });
      return <Card.Group centered>{productList}</Card.Group>;
    }
    return <Card fluid description='No products' />;
  }

  render() {
    const {isEmbedded, products, t} = this.props;
    const {
      showChangePlanModal,
      newPricingPlanId,
      newProductId,
      showCancelModal,
      planIntervalFilter,
      showPaymentSuccessModal,
      paymentSuccessData
    } = this.state;
    if (products.length > 0) {
      return (
        <Grid centered>
          <Grid.Row>
            <Header as='h2' icon textAlign='center'>
              {t('Subscriptions')}
            </Header>
          </Grid.Row>
          <Grid.Row centered>
            <Menu compact>
              <Menu.Item
                active={planIntervalFilter === 'monthly'}
                onClick={() => this.setState({planIntervalFilter: 'monthly'})}
                color={planIntervalFilter === 'monthly' ? 'green' : 'grey'}
                content={t('Monthly')}
                fitted='vertically'
              />
              <Menu.Item
                active={planIntervalFilter === 'yearly'}
                onClick={() => this.setState({planIntervalFilter: 'yearly'})}
                color={planIntervalFilter === 'yearly' ? 'green' : 'grey'}
                fitted='vertically'
                content={
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}>
                    {t('Yearly')}
                    <span style={{fontSize: '0.7em'}}>
                      ({t('2MonthsFree')}!)
                    </span>
                  </div>
                }
              />
            </Menu>
          </Grid.Row>
          <Grid.Row />
          <Grid.Row />
          <Grid.Row>{this.renderProducts()}</Grid.Row>
          <Grid.Row>
            <Media at='computer'>
              {(className, renderChildren) =>
                renderChildren && <ProductToSMessage centered />
              }
            </Media>
          </Grid.Row>
          <Grid.Row className='footer'>
            {
              // only show footer when in standalone mode
              !isEmbedded && <Footer />
            }
            <ChangeSubscriptionModal
              newProductId={newProductId}
              newPricingPlanId={newPricingPlanId}
              planIntervalFilter={planIntervalFilter}
              open={showChangePlanModal}
              onClose={() => this.showChangePlanModal(false)}
            />
            <CancelSubscriptionModal
              open={showCancelModal}
              onClose={() => this.showCancelModal(false)}
            />
            {showPaymentSuccessModal && (
              <PaymentSuccessModal
                paymentSuccessData={paymentSuccessData}
                open={showPaymentSuccessModal}
                onClose={() => this.showPaymentSuccessModal(false, null)}
              />
            )}
          </Grid.Row>
        </Grid>
      );
    }
    return null;
  }
}

// redux stuff
const mapStateToProps = state => ({
  isEmbedded: state.app.isEmbedded,
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  products: state.subscription.products
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProducts,
      getUserProfile,
      changePage: newPage => push(newPage)
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Products))
);
