import React, { Component } from 'react';
import {
  Route, Switch, withRouter
} from 'react-router-dom';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  updateWritingList
} from '../../modules/book';

// custom modules
import Book from './book';
import NewBook from './newBook';

class Books extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { idToken, userProfile, isFetchingWritingList } = this.props;
    if (!prevProps.userProfile && userProfile && idToken && !isFetchingWritingList) {
      this.props.updateWritingList({idToken, accountId: userProfile.account._id});
    }
  }

  render() {
    return (
      <Switch>
        <Route exact path='/books/new' component={NewBook} />
        <Route path='/books/:bookId' component={Book} />
      </Switch>
    );
  }
}


// redux stuff
const mapStateToProps = state => ({
  userProfile: state.user.userProfile,
  idToken: state.user.idToken,
  writing: state.book.writing,
  isFetchingWritingList: state.book.isFetchingWritingList
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateWritingList,
  changePage: newPage => push(newPage)
}, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Books));
