import React from 'react';
import { Link } from 'react-router-dom';
import { Segment, Header } from 'semantic-ui-react';
import { Trans } from 'react-i18next';

type Props = {
  toggleChapterUpload(): void;
};

const WelcomeMessage: React.FC<Props> = ({ toggleChapterUpload }) => (
  <Segment color="orange" padded>
    <Trans i18nKey="DashboardWelcomeMessage">
      <Header size="medium" style={{ fontWeight: 'normal' }}>
        Welcome to BetaReader.io!
      </Header>
      <p style={{ fontWeight: 'lighter' }}>
        Start by&#39;{' '}
        <Link
          to="/mymanuscripts"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            toggleChapterUpload();
          }}
        >
          uploading
        </Link>{' '}
        or{' '}
        <a href="/books/new" rel="noreferrer noopener">
          adding
        </a>{' '}
        your manuscript
      </p>
      <Header size="small" style={{ fontWeight: 'normal' }}>
        Discovery
      </Header>
      <p style={{ fontWeight: 'lighter' }}>
        Don&#39;t forget to list your manuscript in our&nbsp;
        <a
          href="https://read.betareader.io/discover"
          target="_blank"
          rel="noreferrer noopener"
        >
          discovery
        </a>
        &nbsp; section, to access more beta readers.
      </p>
      <p style={{ fontWeight: 'lighter' }}>
        <a
          href="https://betareader.io/2020/03/10/how-to-share-your-work"
          target="_blank"
          rel="noreferrer noopener"
        >
          Here
        </a>{' '}
        is a quick walkthrough of how to do that.
      </p>
      <Header size="small" style={{ fontWeight: 'normal' }}>
        Support
      </Header>
      <p style={{ fontWeight: 'lighter' }}>
        Please check out our{' '}
        <a
          href="https://betareader.io/help"
          target="_blank"
          rel="noreferrer noopener"
        >
          help
        </a>{' '}
        portal or ping us directly by pressing the (?) at the top right of the
        page.
      </p>
      <p style={{ fontWeight: 'lighter' }}>
        You can always send us a friendly mail at{' '}
        <a href="mailto:hello@betareader.io" rel="noreferrer noopener">
          hello@betareader.io
        </a>
      </p>
    </Trans>
  </Segment>
);

export default WelcomeMessage;
