// const cloudImageBaseUrl = 'https://avwxfsdnen.cloudimg.io/%operation%/%size%/%filter%/%originalImageUrl%'; // CloudImage.io base url
const cloudImageBaseUrl =
  'https://avwxfsdnen.cloudimg.io/v7/%originalImageUrl%?%operations%&w=%width%&h=%height%&%filters%'; // CloudImage.io base url

const defaultWidth = 150;

const getCloudImageURL = options => {
  let imgUrl = cloudImageBaseUrl;
  let width = defaultWidth;
  let height;
  if (typeof options?.size === 'string') {
    const sizes = options?.size?.split('x');
    width = sizes[0];
    height = sizes[1];
  } else if (typeof options?.size === 'number') {
    width = options?.size;
  }
  imgUrl = imgUrl
    .replace('%operations%', options.operation || 'width')
    .replace('%width%', width || 150)
    .replace('%height%', height)
    .replace('%filters%', options.filter || 'n')
    .replace('%originalImageUrl%', options.originalImageUrl);
  return imgUrl;
};

const getDimentions = (
  windowDimentions,
  originalWidth,
  originalHeight,
  newWidthPercent
) => {
  let newDimentions = {
    width: originalWidth,
    height: originalHeight
  };
  // scale down image to fit width of device screen
  if (newDimentions.width > windowDimentions.width && originalHeight) {
    const scaleFactor = windowDimentions.width / originalWidth;
    newDimentions = {
      width: Math.round(originalWidth * scaleFactor),
      height: Math.round(originalHeight * scaleFactor)
    };
  }
  // if the image is scaled in author app, scale down to that then
  if (newWidthPercent) {
    const scaleFactor = newWidthPercent / 100;
    newDimentions = {
      width: Math.round(newDimentions.width * scaleFactor),
      height: Math.round(newDimentions.height * scaleFactor)
    };
  }
  return newDimentions;
};

export default {
  getImageUrl: options => getCloudImageURL(options),
  getDimentions
};
