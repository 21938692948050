import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Components
import {
  Grid, Form, Button, Modal, Icon
} from 'semantic-ui-react';

// i18n
import { withTranslation } from 'react-i18next';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  fetchConversations,
  fetchContacts,
  saveConversation,
  setCurrentConversation,
  closeConversation
} from '../../modules/message';
import { authUtil } from '../../utils';
import featureToggleUtil from '../../utils/featureToggleUtil';

import Avatar from '../../components/avatar/avatar';
import ConversationSettings from './conversationSettings';


export class ConversationHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conversationMemberModalOpen: false
    };
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params?.conversationId !== 'new' && match.params?.conversationId === 'new') {
      this.openConversationMemberModal();
    }
  }

  openConversationMemberModal = () => {
    this.setState({ conversationMemberModalOpen: true });
  }

  closeConversationMemberModal = () => {
    this.setState({ conversationMemberModalOpen: false });
  }

  handleSubjectChange = (event, data) => {
    const { currentConversation } = this.props;
    this.props.setCurrentConversation({
      ...currentConversation,
      subject: data.value,
      hasChanges: true
    });
  }

  handleSubjectKeyDown = (event) => {
    if (event.keyCode === 13) {
      // this is a CMD+Enter or Ctrl+Enter event. Submit reply
      this.saveSubject();
    }
  }

  saveSubject = () => {
    const { idToken, currentConversation } = this.props;
    if (currentConversation._id) {
      // this is an existing conversation, push the update
      this.props.saveConversation(idToken, { conversation: currentConversation });
    } else {
      // do nothing. the current subject will be used when starting the conversation
    }
  }

  handleNewConversationMemberChange = (event, data) => {
    this.setState({
      newConversationMember: data.value
    })
  }

  addConversationMember = (event, data) => {
    const { currentConversation } = this.props;
    this.props.setCurrentConversation({
      ...currentConversation,
      members: [...currentConversation.members, data.value],
      hasChanges: true
    });
  }

  toggleContactsModal = () => {
    this.setState(prevState => ({
      showContactsModal: !prevState.showContactsModal
    }));
  }

  closeConversation = async () => {
    const { currentConversation } = this.props;
    const idToken = await authUtil.getFreshIdToken();

    if (currentConversation && currentConversation._id) {
      const updateData = {
        closed: true
      };
      this.props.closeConversation(idToken, currentConversation._id, updateData);
    }
  }

  render() {
    const {
      t, currentConversation, userProfile
    } = this.props;
    const { conversationMemberModalOpen } = this.state;

    if (!currentConversation) {
      return null;
    }

    if (!userProfile) {
      return null;
    }

    const canEditConversation = currentConversation.admins.includes(userProfile._id);
    if (currentConversation) {
      return (
        <Grid>
          <Grid.Column verticalAlign='middle' style={{ marginLeft: 10 }}>
            <Grid.Row>
              <span
                className='br-text'
                style={{ fontSize: '1.5em' }}
              >
                <Form.Field
                  transparent
                  inline
                  placeholder={t('NameConversation')}
                  control={Form.Input}
                  fluid
                  value={currentConversation.subject}
                  onChange={this.handleSubjectChange}
                  onKeyUp={this.handleSubjectKeyDown}
                  readOnly={!canEditConversation}
                  action={(!currentConversation.isNew && currentConversation.hasChanges) ? (
                    <Button icon='save' loading={currentConversation.saving} type='submit' onClick={this.saveSubject} />
                  ) : null}
                />
              </span>
              <div style={{ whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
                { currentConversation.type && currentConversation.type === 'support'
                  ? [
                    <Avatar key='c_$userAvatar' name={userProfile.displayName} diameter={25} style={{ marginRight: 2 }} />,
                    <Avatar key='c_$supportAvatar' name='support' diameter={25} style={{ marginRight: 2 }} />
                  ]
                  : currentConversation.members.map((member, index) => {
                    if (member) {
                      return <Avatar key={`c_${index}${member._id}`} name={member.displayName} diameter={25} style={{ marginRight: 2 }} />;
                    }
                    return null;
                  })
              }
                { currentConversation.type !== 'support'
                && ([
                  <Button key='buttonOpenConversationModal' onClick={this.openConversationMemberModal} circular icon='users' style={{ fontSize: '0.6em' }} />,
                  <Modal
                    key='conversationMemberModal'
                    open={conversationMemberModalOpen}
                    onClose={this.closeConversationMemberModal}
                    content={<ConversationSettings />}
                    closeIcon
                  />
                ])}
                { canEditConversation && featureToggleUtil.hasFeatureAccess(userProfile, 'closeConversations')
                && (
                  <Button
                    onClick={this.closeConversation}
                    basic
                    style={{ position: 'absolute', right: 10, fontSize: '0.8em' }}
                  >
                    <Icon name='check' />
                    {t('Close')}
                  </Button>
                )
                }
              </div>
            </Grid.Row>
            <Grid.Row>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      );
    }
    return null;
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  userProfile: state.user.userProfile,
  idToken: state.user.idToken,
  conversations: state.message.conversations,
  currentConversation: state.message.currentConversation,
  contacts: state.message.contacts
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchConversations,
  fetchContacts,
  saveConversation,
  setCurrentConversation,
  closeConversation,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationHeader)));
