import React, { Component } from 'react';

// pop-ups
import { toast } from 'react-toastify';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import {
  Button, Form, Input, Segment, Message
} from 'semantic-ui-react';

import ReactGA from 'react-ga';

// i18n
import { withTranslation } from 'react-i18next';
import { authUtil } from '../../utils';

class NewBook extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      loading: false
    };
  }

  createBook = async () => {
    const { changePage, activeAccount, userProfile } = this.props;
    const {
      title
    } = this.state;
    const idToken = await authUtil.getFreshIdToken();
    if (idToken) {
      this.setState({ loading: true });
      fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify({
          title,
          accountId: activeAccount ? activeAccount._id : userProfile.account._id
        })
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.msg || res.err);
          } else {
            return res.json();
          }
        })
        .then((newBook) => {
          ReactGA.event({
            category: 'Book Management',
            action: 'Added New Book'
          });
          // move to the newly created book :)
          if (newBook) {
            changePage(`/books/${newBook._id}`);
          }
        })
        .catch(() => {
          ReactGA.exception({
            description: 'Failed to add book',
            fatal: true
          });
          toast.error('Failed to add book');
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  handleTitleChange = (event, data) => {
    this.setState({ title: data.value });
  }

  render() {
    const { t } = this.props;
    const { title, loading } = this.state;

    const faultyInput = title && title.length > 60;

    return (
      <Segment basic clearing>
        <Form error={!!faultyInput}>
          { title && title.length > 60 && (
            <Message
              error
              content={t('TooLongTitle')}
            />
          )}
          <Form.Field
            control={Input}
            size='large'
            placeholder={t('Title')}
            value={title}
            onChange={this.handleTitleChange}
            action={(
              <Button
                onClick={this.createBook}
                primary
                floated='right'
                disabled={faultyInput || !title}
                content={t('Create')}
                loading={loading}
              />
            )}
          />
        </Form>
      </Segment>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  userProfile: state.user.userProfile,
  activeAccount: state.account.activeAccount
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(NewBook)));
