import React, { Component } from 'react';
import Linkify from 'linkifyjs/react';

import {
  Button, Divider, Header, Icon, Item, Label, List, Modal
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import ReactMarkdown from 'react-markdown';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  fetchInboxes
} from '../../modules/manuscriptInbox';
import { authUtil, uxAnalyticsUtil } from '../../utils';
import BookItem from '../../components/book/bookItem';
import {
  fetchCurrentBook,
  resetCurrentBook
} from '../../modules/book';
import { CenteredContent } from '../../style';

export class ManuscriptSubmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false
    };
  }

  componentDidMount() {
    this.setup();
    this.getInboxes();
    uxAnalyticsUtil.trackPageView(this.props.match.path);
  }

  componentDidUpdate(prevProps) {
    const { currentBook } = this.props;
    if (!currentBook && prevProps.currentBook) {
      this.setup();
    }
  }

  componentWillUnmount() {
    this.props.resetCurrentBook();
  }

  getInboxes = async () => {
      const idToken = await authUtil.getFreshIdToken();
    this.props.fetchInboxes(idToken);
  }

  setup = async () => {
    const {
      t, match, currentBook, location
    } = this.props;
    const idToken = await authUtil.getFreshIdToken();
    const { bookId } = queryString.parse(location.search);
    if (bookId !== 'first' && (!currentBook || currentBook._id !== bookId)) {
      this.props.resetCurrentBook();
      this.props.fetchCurrentBook({ idToken, bookId }, (result) => {
        if (result.errorMessage) {
          this.setState({
            errorMessage: t(result.errorMessage)
          });
        }
      });
    }
  }

  submitManuscriptToInbox = async (e) => {
    const inboxData = e.currentTarget.dataset;
    const { t, currentBook, userProfile } = this.props;
    const idToken = await authUtil.getFreshIdToken();
    uxAnalyticsUtil.trackEvent({
      category: 'Manuscript-inbox',
      action: 'clicked-submit-manuscript',
      label: inboxData.inboxId
    });
    const postData = {
      book: currentBook._id,
      to: inboxData.inboxId,
      from: userProfile.account._id
    };
    this.setState({ sending: inboxData.inboxId });
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/manuscript-inbox/submissions`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify(postData)
      })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        } else {
          return res.json();
        }
      })
      .then((submission) => {
        this.setState({
          success: inboxData.inboxId,
          showMsg: {
            markdown: inboxData.receiptMessageMarkdown
          }
        });

        toast.success(t('ManuscriptSubmissionSuccess'));
      })
      .catch((err) => {
        toast.error(t('FailedToSend', err));
      })
      .finally(() => {
        this.setState({ sending: false });
      });
  }

  goToBook = (event, data) => {
    event.stopPropagation();
    this.props.changePage(`/books/${data['data-book-id']}`);
  }

  goToMyManuscripts = () => {
    this.props.changePage('/mymanuscripts');
  }

  hasSubmitted = ({ inbox, bookId }) => {
    const hasSubmitted = inbox.submissions.length > 0
        && inbox.submissions.filter(submission => submission.book?._id === bookId).length === 1;
    return hasSubmitted;
  }

  canSubmitToInbox = ({ book, inbox }) => {
    if (!book || book.privacy === 'unpublished' || !inbox.enabled) {
      return false;
    }
    if (this.hasSubmitted({ inbox, bookId: book._id })) {
      return false;
    }
    return true;
  }

  render() {
    const { t, inboxes, currentBook } = this.props;
    const { sending, success, showMsg } = this.state;
    if (inboxes.length > 0) {
      return (
        <CenteredContent>
          <Item.Group divided>
            { currentBook ? (
              <BookItem
                key={currentBook._id}
                book={currentBook}
                isAuthor
              />
            ) : <Button color='blue' onClick={this.goToMyManuscripts}>{t('SelectManuscriptToSubmit')}</Button>
          }
          </Item.Group>
          <Divider horizontal>{t('SubmitTo')}</Divider>
          <List divided>
            {
              inboxes.map((inbox) => {
                const canSubmitToInbox = this.canSubmitToInbox({
                  book: currentBook, inbox
                });
                return (
                  <List.Item key={inbox._id}>
                    <Icon name='inbox' size='big' />
                    <List.Content>
                      {
                        success === inbox._id
                          ? <Icon style={{ float: 'right' }} name='check circle' color='green' size='big' />
                          : (
                            <Button
                              loading={sending === inbox._id}
                              data-inbox-id={inbox._id}
                              data-receipt-message-markdown={inbox.receiptMessageMarkdown}
                              onClick={this.submitManuscriptToInbox}
                              floated='right'
                              disabled={!canSubmitToInbox}
                              color={!canSubmitToInbox ? 'grey' : 'blue'}
                              content={t('Submit')}
                            />
                          )
                      }
                      <List.Header>{inbox.title}</List.Header>
                      <List.Description style={{ whiteSpace: 'pre-wrap' }}>
                        <Linkify>
                          {inbox.description}
                        </Linkify>
                      </List.Description>
                      <List.Description>
                        <Divider horizontal />
                      </List.Description>
                      {
                        inbox.submissions.length > 0 && (
                          <List.Description>
                            <span>
                              {t('YourSubmittedManuscripts')}
                              {': '}
                            </span>
                            {
                              inbox.submissions.map((submission) => {
                                if (submission.book) {
                                  return (
                                    <Label
                                      key={submission._id}
                                      data-submission-id={submission._id}
                                      data-book-id={submission.book._id}
                                      content={submission.book.title}
                                      onClick={this.goToBook}
                                    />
                                  );
                                }
                                return null;
                              })
                            }
                          </List.Description>
                        )
                      }
                    </List.Content>
                  </List.Item>
                );
              })
            }
          </List>
          {
            !!showMsg && (
              <Modal
                closeIcon
                open
                onClose={() => this.setState({ showMsg: false })}
              >
                <Header
                  icon={<Icon name='check circle' color='green' size='big' />}
                  content={t('ManuscriptSubmissionSuccess')}
                />
                <Modal.Content>
                  <ReactMarkdown
                    source={showMsg.markdown}
                    linkTarget='_blank'
                  />
                </Modal.Content>
              </Modal>
            )
          }
        </CenteredContent>
      );
    }
    return (
      <CenteredContent>
        <List divided>
          <List.Item key='nothingHere'>
            <List.Header>{t('NothingHere')}</List.Header>
          </List.Item>
        </List>
      </CenteredContent>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  userProfile: state.user.userProfile,
  inboxes: state.manuscriptInbox.inboxes,
  currentBook: state.book.currentBook
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchInboxes,
  fetchCurrentBook,
  resetCurrentBook,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ManuscriptSubmission)));
