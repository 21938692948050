import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';

// semantic components
import {
  Feed, Container, Loader, Grid, Message, Statistic, Segment, Button
} from 'semantic-ui-react';

// time formatting
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

// i18n
import { useTranslation, withTranslation } from 'react-i18next';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';

import Avatar from '../../../../components/avatar/avatar';

import filterBuilder from '../../../../utils/filterBuilder';

import {
  fetchAbandonedReadersData,
  clearAbandonedReadersData
} from '../../../../modules/report';
import { authUtil } from '../../../../utils';

momentDurationFormatSetup(moment);

const AbandonedReadersReport = ({

}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentBook = useSelector(state => state.book.currentBook);
  const readerFilters = useSelector(state => state.report.readerFilters);
  const versionFilter = useSelector(state => state.report.versionFilter);
  const abandonedReadersData = useSelector(state => state.report.abandonedReadersData);
  const fetchingReadingData = useSelector(state => state.report.fetchingReadingData);

  useEffect(() => {
    fetchReport();
  }, [currentBook, readerFilters, versionFilter]);

  const getExitReasonEntries = useCallback(() => {
    const exitReasonEntries = abandonedReadersData.versionData
      .map(versionData => versionData.readerEntries
        .map(readerEntry => (
          <Feed.Event key={readerEntry._id}>
            <Feed.Label>
              <Avatar diameter={30} name={readerEntry.displayName} />
            </Feed.Label>
            <Feed.Content>
              <Feed.Summary>
                <Feed.User>{readerEntry.displayName}</Feed.User>
                <Feed.Date>{moment(readerEntry.abandonedAt).fromNow()} ({t('ReachedPartTitle', { partTitle: readerEntry.exitPartTitle })})</Feed.Date>
              </Feed.Summary>
              <Feed.Extra text>
                {readerEntry.feedback}
              </Feed.Extra>
              <Feed.Meta>
                {`${t('ReasonToQuit')}: ${t(`reason_${readerEntry.reason}`)}`}
              </Feed.Meta>
            </Feed.Content>
          </Feed.Event>
        )));
    return exitReasonEntries;
  }, [abandonedReadersData]);


  const fetchReport = async (type) => {
    const idToken = await authUtil.getFreshIdToken();
    dispatch(fetchAbandonedReadersData(idToken, currentBook._id, versionFilter, readerFilters, type));
  }

  const downloadReport = () => {
    fetchReport('csv');
  }

  if (fetchingReadingData) {
    return (
      <Container>
        <Loader active inline='centered' />
      </Container>
    )
  }
  if (abandonedReadersData) {
    console.log('abandonedReadersData', abandonedReadersData);


    return (
      <Segment basic>
        <Button basic floated='right' icon='download' onClick={downloadReport} />
        <Container>
          <Grid columns='equal' textAlign='center'>
            <Grid.Row>
              <Statistic.Group size='small'>
                <Statistic>
                  <Statistic.Value>{abandonedReadersData.reasonCount?.no_time ?? 0}</Statistic.Value>
                  <Statistic.Label>{t('NotEnoughTime')}</Statistic.Label>
                </Statistic>
                <Statistic>
                  <Statistic.Value>{abandonedReadersData.reasonCount?.lost_interest ?? 0}</Statistic.Value>
                  <Statistic.Label>{t('LostInterest')}</Statistic.Label>
                </Statistic>
                <Statistic>
                  <Statistic.Value>{abandonedReadersData.reasonCount?.other ?? 0}</Statistic.Value>
                  <Statistic.Label>{t('Other')}</Statistic.Label>
                </Statistic>
              </Statistic.Group>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {
                  (!abandonedReadersData || abandonedReadersData?.versionData?.length === 0) && (
                    <Message>
                      {t('NoReadersFound')}
                    </Message>
                  )
                }
                {
                  abandonedReadersData && abandonedReadersData?.versionData?.length > 0 && (
                    <Feed>
                      {getExitReasonEntries()}
                    </Feed>
                  )
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    );
  }
  return null;
}


// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  currentBook: state.book.currentBook,
  fetchingReadingData: state.report.fetchingReadingData,
  abandonedReadersData: state.report.abandonedReadersData,
  readerFilters: state.report.readerFilters
})

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchAbandonedReadersData,
  clearAbandonedReadersData,
  changePage: (newPage) => push(newPage)
}, dispatch)

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AbandonedReadersReport)))
