import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, List, Icon
} from 'semantic-ui-react';
import moment from 'moment';

// i18n
import { withTranslation } from 'react-i18next';

// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  sendInvitationReminder,
  deleteReader
} from '../../../modules/reader';

export class NonStartersList extends Component {
  // lifecycle methods
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.readerEntries.length === 0) {
      this.props.onClose();
    }
  }

  // custom methods
  sendReminder = (reader) => {
    const {idToken} = this.props;
    this.props.sendInvitationReminder(idToken, reader);
  }

  removeReader = (reader) => {
    const {idToken} = this.props;
    this.props.deleteReader(idToken, reader);
  }

  // rendering
  render() {
    const { readerEntries, t, readOnly } = this.props;
    const threeDaysAgo = new Date();
    threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
    return (
      <List selection>
        {
          (readerEntries || []).map((reader, index) => {
            const allowReminding = (reader.invitation.remindedAt === undefined || new Date(reader.invitation.remindedAt) < threeDaysAgo);
            return (
              <List.Item key={index}>
                <List.Content floated='right' verticalAlign='middle'>
                  <Button.Group floated='right'>
                    <Button
                      compact
                      disabled={!allowReminding || readOnly}
                      loading={reader.updating}
                      onClick={() => {
                        this.sendReminder(reader)
                      }}>
                      <Icon name='mail'/> {t('SendReminder')}
                    </Button>
                    <Button
                      compact
                      disabled={readOnly}
                      onClick={() => {this.removeReader(reader)}}>
                      <Icon name='trash alternate'/> {t('Remove')}
                    </Button>
                  </Button.Group>
                  {
                    !allowReminding &&
                    <div style={{fontSize:'0.8em',maxWidth:150}}>{t('YouCanSendAReminderEvery3Days')}</div>
                  }
                </List.Content>
                <List.Header>
                  {reader.name}
                </List.Header>
                <List.Content>
                  <div style={{fontSize:'0.9em'}}>
                    { reader.invitation.requestedAt === undefined &&
                      <span>
                        {t('Created')}: {moment(reader.createdAt).fromNow()}<br/>
                      </span>
                    }
                    { reader.invitation.requestedAt !== undefined &&
                      <span>
                        {t('RequestedToRead')}: {moment(reader.invitation.requestedAt).fromNow()}<br/>
                      </span>
                    }
                    { reader.invitation.sentAt !== undefined &&
                      <span>
                        {t('InvitationSent')}: {moment(reader.invitation.sentAt).fromNow()}<br/>
                      </span>
                    }
                    { reader.invitation.approvedAt !== undefined &&
                      <span>
                        {t('Approved')}: {moment(reader.invitation.approvedAt).fromNow()}<br/>
                      </span>
                    }
                    { reader.invitation.acceptedAt !== undefined &&
                      <span>
                        {t('Accepted')}: {moment(reader.invitation.acceptedAt).fromNow()}<br/>
                      </span>
                    }
                    { reader.invitation.remindedAt !== undefined &&
                      <span>
                        {t('Reminded')} {moment(reader.invitation.remindedAt).fromNow()}<br/>
                      </span>
                    }
                  </div>
                </List.Content>
              </List.Item>
            );
          })
        }
    </List>
    )

  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  currentBook: state.book.currentBook,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  sendInvitationReminder,
  deleteReader,
}, dispatch)

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(NonStartersList)));
