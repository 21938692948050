export const UPDATE_CHAPTER_CHANGE_LOGS = 'changeLog/UPDATE_CHAPTER_CHANGE_LOGS';



const initialState = {
  churrentChapterChangeLogs: []
}

// reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CHAPTER_CHANGE_LOGS:
      return {
        ...state,
        currentChapterChangeLogs: action.changeLogs
      }
    default:
      return state
  }
}

// actions

export const fetchCurrentChapterChangeLogs = (idToken, bookId, chapterId) => {
  return dispatch => {
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/chapters/${chapterId}/change-logs`,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    })
    .then(res => {
      if (res.ok) {
        return res.json()
      } else {
        throw new Error('Failed to delete chapter');
      }
    })
    .then(changeLogs => {
      return dispatch({
        type: UPDATE_CHAPTER_CHANGE_LOGS,
        changeLogs: changeLogs
      })
    })
    .catch(err => {
      console.error(err);
    })
  }

}
