import React, { Component } from 'react';

import {
  BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip
} from 'recharts';
import { List } from 'semantic-ui-react';

const colors = ['#FC9C04', '#FFBB28', '#5B5A54', '#000000', '#CCCCCC'];

class CheckboxSurveyAnswersChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  parseData = (answers) => {
    const { options } = this.props;

    // create the placeholder for all bars
    const newData = options.options.map(option => ({
      answer: option,
      value: 0,
      readers: new Set()
    }));

    // fill placeholders with data
    answers.forEach((answer) => {
      answer.answer.forEach((selectedAnswer) => {
        const entryIndex = newData.findIndex(entry => entry.answer === selectedAnswer);
        if (entryIndex >= 0) {
          newData[entryIndex].value += 1;
          newData[entryIndex].readers.add(answer.reader._id);
        }
      });
    });

    return newData;
  }

  render() {
    const { answers } = this.props;
    if (!answers || answers.length === 0) {
      return (
        <List divided>
          <List.Item key='noanswer'>
            <List.Content>
              No answers yet.
            </List.Content>
          </List.Item>
        </List>
      )
    }
    const parsedData = this.parseData(answers);
    return (
      <ResponsiveContainer width={'100%'} height={250}>
        <BarChart
          layout='vertical'
          data={parsedData}>
          <Bar dataKey='value' fill={colors[1]} onClick={answer => this.props.onFilter([...answer.readers])} />
          <YAxis dataKey='answer' type='category' />
          <XAxis type='number' domain={[0,5]} />
          <Tooltip cursor={{ stroke: colors[0], strokeWidth: 1 }} />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default CheckboxSurveyAnswersChart;
