import React from 'react';

// i18n
import { withTranslation } from 'react-i18next';

// Routes
import { withRouter } from 'react-router-dom';

// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import {
  Menu
} from 'semantic-ui-react';

import {
  toggleAppSidebar
} from '../../modules/app';


const SidebarToggler = ({
  sidebarVisible,

  toggleAppSidebar
}) => {

  return (
    <Menu.Item
      fitted
      key='menu-opener'
      icon='bars'
      onClick={() => toggleAppSidebar(!sidebarVisible)}
    />
  );
}

// redux stuff
const mapStateToProps = state => ({
  sidebarVisible: state.app.sidebarVisible,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleAppSidebar,
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarToggler)));
