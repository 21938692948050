export const UPDATE_READER_INDEX_FILTERS = 'readerIndex/UPDATE_READER_INDEX_FILTERS';
export const UPDATE_READER_LIST = 'readerIndex/UPDATE_READER_LIST';
export const FETCHING_READERS = 'readerIndex/FETCHING_READERS';
export const ADD_TO_READER_LIST = 'readerIndex/ADD_TO_READER_LIST';

const initialState = {
  fetchingReaders: false,
  readerFilters: [{}],
  readers: undefined
};

// reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_READER_INDEX_FILTERS:
      return {
        ...state,
        readerFilters: action.filters
      };
    case UPDATE_READER_LIST:
      return {
        ...state,
        readers: action.readers
      };
    case FETCHING_READERS:
      return {
        ...state,
        fetchingReaders: action.value
      };
    case ADD_TO_READER_LIST: {
      return {
        ...state,
        readers: [...state.readers, ...action.readers]
      };
    }
    default:
      return state;
  }
};

// actions
export const updateReaderFilters = (filters, callback) => (dispatch) => {
  dispatch({
    type: UPDATE_READER_INDEX_FILTERS,
    filters
  });
  if (callback) {
    callback(true);
  }
};

export const fetchReaders = (idToken, query) => (dispatch) => {
  dispatch({
    type: FETCHING_READERS,
    value: true
  });
  fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/reader-index?${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'br-token': idToken
    }
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error('Failed to load reader index');
    })
    .then(readers => dispatch({
      type: UPDATE_READER_LIST,
      readers
    }))
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({
        type: FETCHING_READERS,
        value: false
      });
    });
};

export const fetchMoreReaders = (idToken, query) => (dispatch) => {
  dispatch({
    type: FETCHING_READERS,
    value: true
  });
  fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/reader-index?${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'br-token': idToken
    }
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error('Failed to load reader index');
    })
    .then(readers => dispatch({
      type: ADD_TO_READER_LIST,
      readers
    }))
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({
        type: FETCHING_READERS,
        value: false
      });
    });
};
