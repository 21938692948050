import React, {useEffect, useRef, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {
  Modal,
  Dimmer,
  Button,
  Loader,
  Form,
  Header,
  Radio,
  TextArea
} from 'semantic-ui-react';

import {withTranslation} from 'react-i18next';

// pop-ups
import {toast} from 'react-toastify';

// redux
import {push} from 'connected-react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {cancelSubscription} from '../../../modules/subscription';

import {getUserProfile} from '../../../modules/user';

import {authUtil, uxAnalyticsUtil} from '../../../utils';
import usePrevious from '../../../hooks/usePrevious';

const CANCEL_STAGE = {
  INITIATED: 0,
  ANSWERING_SURVEY: 1,
  CANCELLING: 2,
  DONE: 3
};

const cancelReasons = [
  'technical_issues',
  'too_expensive',
  'finished_my_beta',
  'switching_to_another_product',
  'missing_features',
  'did_not_meet_expectations',
  'other'
];

const CancelSubscriptionModal = ({
  userProfile,
  cancelSubscription,
  getUserProfile,
  onClose,
  open,
  t
}) => {
  const refreshedUserProfileCount = useRef(0);
  const [currentStage, setCurrentStage] = useState(CANCEL_STAGE.INITIATED);
  const [cancelReason, setCancelReason] = useState('');
  const [cancelFeedback, setCancelFeedback] = useState('');
  const previousUserProfile = usePrevious(userProfile);
  const refreshUserProfileTimer = useRef();

  // runs on first render
  useEffect(() => {
    setCurrentStage(CANCEL_STAGE.INITIATED);
    refreshedUserProfileCount.current = 0;
  }, []);

  useEffect(() => {
    if (previousUserProfile) {
      const previousPricingPlanId =
        previousUserProfile.account?.subscription?.pricingPlan?.externalId;
      const currentPricingPlanId =
        userProfile.account.subscription.pricingPlan.externalId;
      if (previousPricingPlanId !== currentPricingPlanId) {
        // if we had a previousUserProfile and the pricing plan id has changed, we have moved plan and can close the modal
        close();
      }
    }
  }, [userProfile?.account?.subscription?.pricingPlan?.externalId]);

  const handleCancelSubscription = async () => {
    const idToken = await authUtil.getFreshIdToken();
    setCurrentStage(CANCEL_STAGE.CANCELLING);
    cancelSubscription(
      {
        idToken,
        subscriptionId: userProfile.account.subscription._id,
        cancelReason,
        cancelFeedback
      },
      response => {
        if (!response.ok) {
          toast.error(t('SomethingWentWrong'));
          uxAnalyticsUtil.trackException({
            description: response,
            fatal: true
          });
          close();
        } else {
          // track cancellation to GA
          uxAnalyticsUtil.trackEvent({
            category: 'Subscriptions',
            action: 'cancelled-subscription',
            label: userProfile.account.product.internalId
          });
          onSuccessfulCancel();
        }
      }
    );
  };

  const refreshUserProfile = async () => {
    if (refreshedUserProfileCount.current < 3) {
      refreshedUserProfileCount.current += 1;
      const idToken = await authUtil.getFreshIdToken();
      getUserProfile(idToken);
    } else {
      clearInterval(refreshUserProfileTimer.current);
      close();
    }
  };

  const onSuccessfulCancel = () => {
    // repeat with the interval of 1.5 seconds
    const self = this;
    refreshUserProfileTimer.current = setInterval(refreshUserProfile, 1500);
  };

  const close = async () => {
    const idToken = await authUtil.getFreshIdToken();
    getUserProfile(idToken);
    clearInterval(refreshUserProfileTimer.current);
    setCurrentStage(CANCEL_STAGE.INITIATED);
    if (onClose) {
      onClose();
    }
  };

  const handleConfirmClick = () => {
    switch (currentStage) {
      case CANCEL_STAGE.INITIATED:
        setCurrentStage(CANCEL_STAGE.ANSWERING_SURVEY);
        break;
      case CANCEL_STAGE.ANSWERING_SURVEY:
        handleCancelSubscription();
        break;
      default:
        break;
    }
  };

  const handleCancelReasonChange = (event, data) => {
    setCancelReason(data.value);
  };

  const handleCancelFeedbackChange = (event, data) => {
    setCancelFeedback(data.value);
  };

  return (
    <Modal
      key='movePlanModal'
      open={open}
      closeOnEscape
      closeOnDimmerClick
      size='tiny'
      onClose={close}>
      <Modal.Header>{t('CancelSubscription')}</Modal.Header>
      <Modal.Content>
        {currentStage === CANCEL_STAGE.INITIATED && (
          <Header content={t('CancelSubscriptionConfirmation')} size='tiny' />
        )}
        {currentStage === CANCEL_STAGE.CANCELLING && (
          <Dimmer active inverted>
            <Loader
              active
              inline='centered'
              content={t('WaitingForConfirmation')}
            />
          </Dimmer>
        )}
        {currentStage === CANCEL_STAGE.ANSWERING_SURVEY && (
          <Form>
            <Header content={t('CancelReason')} size='tiny' />
            {cancelReasons.map(reason => (
              <Form.Field key={reason}>
                <Radio
                  label={t(`cancel_reason_${reason}`)}
                  name='CancelReason'
                  value={reason}
                  checked={cancelReason === reason}
                  onChange={handleCancelReasonChange}
                />
              </Form.Field>
            ))}
            <Form.Field>
              <TextArea
                placeholder={t('TellUsMore')}
                value={cancelFeedback}
                onChange={handleCancelFeedbackChange}
              />
            </Form.Field>
          </Form>
        )}
        {currentStage === CANCEL_STAGE.DONE && (
          <Header content={t('YourSubscriptionHasBeenCancelled')} size='tiny' />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          color='grey'
          basic
          disabled={currentStage === CANCEL_STAGE.CANCELLING}
          onClick={close}>
          {currentStage === CANCEL_STAGE.INITIATED && t('No')}
          {currentStage === CANCEL_STAGE.ANSWERING_SURVEY && t('Back')}
        </Button>
        <Button
          negative
          disabled={currentStage === CANCEL_STAGE.CANCELLING}
          onClick={handleConfirmClick}>
          {currentStage === CANCEL_STAGE.INITIATED && t('Yes')}
          {currentStage === CANCEL_STAGE.ANSWERING_SURVEY &&
            t('CancelSubscription')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      cancelSubscription,
      getUserProfile,
      changePage: newPage => push(newPage)
    },
    dispatch
  );

export default withTranslation()(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CancelSubscriptionModal)
  )
);
