import React, { Component } from 'react';
import { Popup, Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import {
  convertToRaw, Modifier, SelectionState
} from 'draft-js';

// i18n
import { withTranslation } from 'react-i18next';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

export class SplitChapterButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  splitChapter = () => {
    // Find the place to split
    const { editorState } = this.props;
    const currentContentState = editorState.getCurrentContent();
    const currentSelectionState = editorState.getSelection();
    const startOffset = currentSelectionState.getStartOffset();
    const splitBlockLength = currentContentState.getBlockForKey(
      currentSelectionState.getStartKey()
    ).getLength();
    let updatedContentState = currentContentState;
    let updatedSelectionState = currentSelectionState;
    if (splitBlockLength > 0) {
      // If the point of the split is in the middle of a block with some text,
      // split it into two blocks. Otherwise, keep the current state.
      updatedSelectionState = new SelectionState({
        anchorKey: currentSelectionState.getStartKey(), // key of block
        anchorOffset: startOffset,
        focusKey: currentSelectionState.getStartKey(),
        focusOffset: startOffset, // key of block
        hasFocus: true
      });
      updatedContentState = Modifier.splitBlock(currentContentState, updatedSelectionState);
    }
    // find the splitting block
    const firstHalfSplitBlock = updatedContentState.getBlockAfter(
      updatedSelectionState.getStartKey()
    );
    // split content into two parts:

    // first range
    const firstBlock = updatedContentState.getFirstBlock();
    const firstHalfRange = new SelectionState({
      anchorKey: firstHalfSplitBlock.getKey(),
      anchorOffset: 0,
      focusKey: firstBlock.getKey(),
      focusOffset: 0,
      isBackward: true
    });

    // second range
    const lastBlock = updatedContentState.getLastBlock();
    const secondHalfSplitBlock = updatedContentState.getBlockBefore(
      updatedSelectionState.getStartKey()
    );
    const secondHalfRange = new SelectionState({
      anchorKey: secondHalfSplitBlock.getKey(),
      anchorOffset: secondHalfSplitBlock.getLength(),
      focusKey: lastBlock.getKey(),
      focusOffset: lastBlock.getLength()
    });

    // perform the split
    const firstHalf = Modifier.removeRange(updatedContentState, secondHalfRange, 'backward');
    const secondHalf = Modifier.removeRange(updatedContentState, firstHalfRange, 'backward');
    this.props.onSplit({
      contentAboveSplit: firstHalf,
      contentBelowSplit: secondHalf
    });
  }

  render() {
    const { t } = this.props;
    const { isCutting } = this.state;
    return (
      <Popup
        basic
        trigger={(
          <Button
            floated='left'
            circular
            basic
            size='mini'
            icon='cut'
            loading={isCutting}
            onClick={this.splitChapter}
          />
        )}
        content={t('SplitChapter')}
        position='top right'
      />
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  currentBook: state.book.currentBook,
  currentChapter: state.book.currentChapter
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SplitChapterButton)));
