import React from 'react';

import {
  Grid,
  List,
  Segment,
  Container,
  Icon,
  Divider,
  Image
} from 'semantic-ui-react';

const Footer = () => (
  <div>
    <Segment vertical>
      <Divider />
      <Container>
        <Grid stackable columns='equal'>
          <Grid.Row>
            <Grid.Column width={4}>
              <List link>
                <Image src='/assets/images/BR-orange-horizontal-small.png' />
              </List>
            </Grid.Column>
            <Grid.Column width={4}>
              <List link>
                <List.Item
                  as='a'
                  href='https://www.betareader.io/tos'
                  target='_br_tos'>
                  Terms of Service
                </List.Item>
                <List.Item
                  as='a'
                  href='https://www.betareader.io/privacy'
                  target='_br_privacy'>
                  Privacy Policy
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={4}>
              <List link>
                <List.Item
                  as='a'
                  href='https://betareader.io/help'
                  target='_br_help_center'>
                  Help center
                </List.Item>
                <List.Item as='a' href='mailto:support@betareader.io'>
                  Email Us
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={4}>
              <List link>
                <List.Item>
                  <span>&copy; BetaReader Sweden AB 2017-2023</span>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </div>
);

export default Footer;
