import React, { useState } from 'react';
import {
  Form, Modal, Label, Segment, Button, Dropdown
} from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { authUtil } from '../../utils';
import TextAreaWithSave from '../../components/inputs/TextAreaWithSave';
import {
  createBookPost
} from '../../modules/bookPost';
import { toast } from 'react-toastify';

const ContentUpdateConfirmationModal = ({
  creatingBookPost,
  updateText: updateTextFromProps,
  header,
  open,
  affectedReaders,
  sendUpdateOnConfirm,


  createBookPost,
  onCancel,
  onConfirm,
  t,
}) => {
  const [updateText, setUpdateText] = useState(updateTextFromProps ?? '');
  const {
    bookId
  } = useParams();

  const handleBookUpdateTextChange = (event, data) => {
    setUpdateText(data.value);
  }

  const handleConfirm = async (sendUpdate) => {
    const idToken = await authUtil.getFreshIdToken();
    if (sendUpdate) {
      createBookPost(idToken, bookId, { text: updateText }, (success, error) => {
        if (success) {
          setUpdateText('');
          onConfirm && onConfirm();
        } else {
          toast.info(error);
        }
      });
    } else {
      onConfirm && onConfirm();
    }
  }

  const handleCancel = () => {
    setUpdateText('');
    onCancel && onCancel();
  }

  return (
    <Modal open={open} onClose={handleCancel}>
      <Modal.Header>{header || t('AreYouSure?')}</Modal.Header>
      <Modal.Content>
        <Segment basic>
          <Form>
            {
              affectedReaders && (
                <Form.Field>
                  {t('ThisChangeAffectsTheFollowingReaders')}
                  <Label.Group style={{ maxHeight: 200, overflowY: 'scroll' }}>
                    {affectedReaders.map((reader, index) => <Label key={index} content={reader.user.displayName} />)}
                  </Label.Group>
                </Form.Field>
              )
            }
            <Form.Field>
              <label>{t('LetYourReadersKnowWhatYouChanged')}</label>
              <Form.TextArea
                control={TextAreaWithSave}
                placeholder={t('SampleChapterUpdatePost')}
                value={updateText}
                onChange={handleBookUpdateTextChange}
                onSave={handleConfirm}
              />
            </Form.Field>
          </Form>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button.Group>
          <Button content={t('Cancel')} onClick={handleCancel} loading={creatingBookPost} />
          {sendUpdateOnConfirm ? (
            <Button
              content={t('Send')}
              onClick={() => handleConfirm(true)}
              loading={creatingBookPost}
            />
          ) : (
            <>
              <Button.Or />
              <Dropdown
                loading={creatingBookPost}
                floating
                button
                text={t('SaveAnd...')}
                options={[
                  {
                    key: 'confirm-and-send',
                    value: 'confirm-and-send',
                    text: t('Send'),
                    onClick: () => {
                      handleConfirm(true);
                    }
                  },
                  {
                    key: 'confirm-dont-send',
                    value: 'confirm-dont-send',
                    text: t('OnlySave'),
                    onClick: () => {
                      handleConfirm(false);
                    }
                  }
                ]}
              />
            </>
          )}
        </Button.Group>
      </Modal.Actions>
    </Modal>
  );
}

// redux stuff
const mapStateToProps = state => ({
  creatingBookPost: state.bookPost.creatingBookPost,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  createBookPost,
}, dispatch);

export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentUpdateConfirmationModal));
