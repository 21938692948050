// usePaddle.js
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAppSidebar, setIsEmbedded } from '../modules/app';
import { paddleTrackingUtil, appUtil } from '../utils';
import { useHistory } from 'react-router-dom';

const usePaddle = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfile = useSelector(state => state.user.userProfile);

  useEffect(() => {
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.REACT_APP_PADDLE_ENV === 'sandbox'
    ) {
      window.Paddle.Environment.set('sandbox');
    }
    window.Paddle.Setup({
      vendor: parseInt(process.env.REACT_APP_PADDLE_ID),
      eventCallback: eventData => {
        paddleTrackingUtil.track(eventData);
      },
    });

    const unlisten = history.listen(() => {
      if (appUtil.isSmallScreen()) {
        dispatch(toggleAppSidebar(false));
      }
    });

    if (window.self !== window.top) {
      dispatch(setIsEmbedded(true));
    }

    // Cleanup on unmount
    return () => {
      unlisten();
    };
  }, [dispatch, history]);

  useEffect(() => {
    const setupProfitWell = subscriptionId => {
      if (subscriptionId) {
        profitwell('start', { user_id: subscriptionId }); // eslint-disable-line
      }
    };
    if (userProfile) {
      setupProfitWell(userProfile.account?.subscription.externalId);
    }
  }, [
    userProfile,
    userProfile?.language,
    userProfile?.readerSettings?.colorMode,
  ]);
};

export default usePaddle;
