import React from 'react';
import { Image } from 'semantic-ui-react';
import styled from 'styled-components';
import { imageUtil } from '../../../utils';
import BookCover from './index';

export const getCoverImage = (book) =>
  book?.cover?.highRes?.url ??
  'https://storage.googleapis.com/beta-reader-prod.appspot.com/defaults/DEFAULT_COVER_BACKGROUND.png';

const CoverContainer = styled.div`
  background-image: url('${({ background }) => background}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
  margin-top: -1em;
  ${'' /* margin-bottom: 1em; */}
  height: 200px;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
`;

const SharpImageContainer = styled.div`
  height: 200px;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255);
`;

const SharpImage = styled(Image)`
  height: 200px;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255);
`;

const CoverWrapper = ({ book, editable }) => {
  const coverImage = getCoverImage(book);
  return (
    <CoverContainer
      background={imageUtil.getImageUrl({
        operation: 'width',
        size: 150,
        filter: 'f=blur:10',
        originalImageUrl: coverImage,
      })}
    >
      {!editable && (
        <SharpImage
          src={imageUtil.getImageUrl({
            operation: 'width',
            size: 150,
            originalImageUrl: coverImage,
          })}
        />
      )}
      {editable && (
        <SharpImageContainer className='editable'>
          <BookCover style={{ height: '200px!important' }} />
        </SharpImageContainer>
      )}
    </CoverContainer>
  );
};

export default CoverWrapper;
