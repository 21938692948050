import React from 'react';
import {Form, Header} from 'semantic-ui-react';

import {useDispatch} from 'react-redux';

import {clearSurvey} from '../../../../modules/survey';

import TextAnswer from './textAnswer';
import MultipleChoiceAnswer from './multipleChoiceAnswer';
import LinearScaleAnswer from './linearScaleAnswer';
import CheckboxAnswer from './checkboxAnswer';

export const Question = ({
  question,
  onAnswerChanged,
  answer,
  style,
  placeholder,
  fetchedAnswers,
  handleReference,
  tabIndex
}) => {
  const dispatch = useDispatch();
  const handleQuestionReference = ref => {
    if (handleReference) {
      handleReference(ref);
    }
  };

  const validateAnswer = (question, answer) => {
    if (answer === undefined) {
      return false;
    }
    switch (question.kind) {
      case 'CHECKBOX':
        if (
          !!answer &&
          answer.length >= question.options.minSelected &&
          answer.length <= question.options.maxSelected
        ) {
          return true;
        }
        break;
      case 'LONG_TEXT':
        if (!!answer && answer.length > 0) {
          return true;
        }
        break;
      default:
        return answer !== undefined;
    }
    return false;
  };

  const checkIfIsDone = (question, answer) => {
    switch (question.kind) {
      case 'CHECKBOX':
        if (answer.length === question.options.maxSelected) {
          return true;
        }
        break;
      default:
        return true;
    }
    return false;
  };

  const handleAnswerChanged = async (newAnswer, clicked) => {
    if (!validateAnswer(question, newAnswer)) {
      return;
    }
    const isDone = checkIfIsDone(question, newAnswer);
    onAnswerChanged(newAnswer, isDone);
  };

  const handleClearSurvey = () => {
    dispatch(clearSurvey());
  };

  let questionStyle = {
    marginTop: '4em',
    marginBottom: '6em',
    fontSize: '1em'
  };
  if (style) {
    questionStyle = {
      ...questionStyle,
      ...style
    };
  }

  return (
    <div ref={handleQuestionReference}>
      <Form className='question-box' style={questionStyle}>
        <Form.Field>
          <Header
            content={<span>{question.question}</span>}
            subheader={question.description}
            className={`br-text`}
          />
          {fetchedAnswers && (
            <div>
              {question.kind === 'LONG_TEXT' && (
                <TextAnswer
                  placeholder={placeholder}
                  question={question}
                  answer={answer}
                  clearSurvey={handleClearSurvey}
                  onChange={handleAnswerChanged}
                  tabIndex={tabIndex}
                />
              )}
              {question.kind === 'MULTIPLE_CHOICE' && (
                <MultipleChoiceAnswer
                  question={question}
                  answer={answer}
                  clearSurvey={handleClearSurvey}
                  onChange={handleAnswerChanged}
                  tabIndex={tabIndex}
                />
              )}
              {question.kind === 'CHECKBOX' && (
                <CheckboxAnswer
                  question={question}
                  answer={answer}
                  clearSurvey={handleClearSurvey}
                  onChange={handleAnswerChanged}
                  tabIndex={tabIndex}
                />
              )}
              {question.kind === 'LINEAR_SCALE' && (
                <LinearScaleAnswer
                  question={question}
                  answer={answer}
                  clearSurvey={handleClearSurvey}
                  onChange={handleAnswerChanged}
                  tabIndex={tabIndex}
                />
              )}
            </div>
          )}
        </Form.Field>
      </Form>
    </div>
  );
};

export default Question;
