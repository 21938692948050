import React, { Component } from 'react';
import {
  Card, Button, Segment, Statistic
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

export class PricingPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localPriceObject: null
    };
  }

  componentDidMount = () => {
    this.getLocalPrice();
  }

  getLocalPrice = async () => {
    const { pricingPlan } = this.props;
    if (pricingPlan.externalId !== 'free') {
      try {
        await window.Paddle.Product.Prices(pricingPlan.externalId, (priceObject) => {
          this.setState({
            localPriceObject: priceObject
          });
        });
      } catch (e) {
        console.log('error', e);
      }
    }
  }

  render() {
    const {
      isCurrentPricingPlan, pricingPlan, t, hideButton, isCancelled, validUntilDate, isLoggedIn
    } = this.props;
    const { localPriceObject } = this.state;
    let disabledButton = isCurrentPricingPlan;
    let buyButtonColor = 'green';
    let buyButtonText = t('BuyNow');
    let localPrice = '';
    let subscriptionType = '';
    // set the local price once we get it from paddle.js
    if (localPriceObject) {
      localPrice = localPriceObject.price.gross;
      subscriptionType = `/ ${t(localPriceObject.recurring.subscription.type)}`;
    }
    // if we have a free plan override instead with "free message"
    if (pricingPlan.externalId === 'free') {
      localPrice = 'Zero';
      subscriptionType = `${t('NoCreditCardNeeded')}`;
    }
    // set the button color to grey if disabled and on lower plans
    if (disabledButton) {
      buyButtonColor = 'grey';
    }
    //  set the button Text to GoFree on free plans
    if (pricingPlan.externalId === 'free') {
      if (isLoggedIn && !isCurrentPricingPlan) {
        // if logged in and not on free: downgrade
        buyButtonText = t('Downgrade');
      } else {
        // not logged in
        buyButtonText = t('SignIn');
      }
    }
    // override colors and text for current pricingPlan with orange and current text
    if (isCurrentPricingPlan) {
      buyButtonColor = 'orange';
      buyButtonText = t('Current');
    }
    if (pricingPlan) {
      return (
        <Segment vertical>
          <Card.Description textAlign='center' style={{ minHeight: !hideButton ? 100 : null }}>
            <Statistic color='grey' size='small'>
              <Statistic.Value>{localPrice}</Statistic.Value>
              <Statistic.Label>{subscriptionType}</Statistic.Label>
            </Statistic>
          </Card.Description>
          <Card.Description>
            {
              !hideButton
              && (
                <Button
                  color={buyButtonColor}
                  disabled={disabledButton}
                  fluid
                  size='large'
                  className='product-buy-button'
                  content={buyButtonText.toUpperCase()}
                  label={isCancelled ? {
                    basic: true, pointing: 'left', content: `${t('ValidUntil')}: ${moment(validUntilDate).format('L')}`
                  } : null}
                  onClick={() => this.props.onPress(pricingPlan, localPriceObject)}
                />
              )
            }
          </Card.Description>
        </Segment>
      );
    }
    return null;
  }
}

export default withTranslation()(PricingPlan);
