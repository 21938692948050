import React, { Component } from 'react';

import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Dropdown, Icon, Label } from 'semantic-ui-react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import privacy from '../../../config/privacy';
import { saveBook } from '../../../modules/book';
import productCapUtil from '../../../utils/productCapUtil';
import accessUtil from '../../../utils/accessUtil';
import ProductLimitMessage from '../../../components/standard-messages/productLimitMessage';
import { authUtil } from '../../../utils';

const PublishingSettings = ({ style = {}, book }) => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.user.userProfile);
  const currentBook = useSelector((state) => state.book.currentBook);
  const writing = useSelector((state) => state.book.writing);
  const useBook = book || currentBook;

  const updatePrivacySettings = async (event, data) => {
    event.preventDefault();
    event.stopPropagation();
    const newPrivacyValue = data.value;
    if (newPrivacyValue !== 'unpublished' && useBook.wordCount === 0) {
      return toast.error(
        <Trans i18nKey='BookHas0WordsDescription'>
          <span>
            This book has at least one version with 0 words. You can add or
            upload chapters{' '}
          </span>
          <Link to={`/books/${useBook._id}/content`}>here</Link>.
        </Trans>
      );
    }
    const idToken = await authUtil.getFreshIdToken();
    // only try to save the update if it is bound to work
    dispatch(
      saveBook(idToken, {
        _id: useBook._id,
        privacy: newPrivacyValue,
      })
    );
  };

  return (
    <div style={{ ...style }}>
      <Dropdown
        as={Label}
        selection
        value={useBook.privacy}
        onChange={updatePrivacySettings}
        disabled={
          useBook.role !== 'author' &&
          !accessUtil.isAllowed(useBook.access, ['edit'])
        }
        options={privacy.privacyOptions(useBook._id).map((option) => ({
          key: option.key,
          text: option.text,
          value: option.value,
          content: (
            <div style={{ fontSize: '0.8em' }}>
              {/* Show just the option if we can change to this status */}
              {option.text}
            </div>
          ),
        }))}
      />
      <div style={{ fontSize: '0.8em', fontWeight: 'standard', color: 'grey' }}>
        {privacy.get(useBook, 'infoText')}
      </div>
    </div>
  );
};

export default PublishingSettings;
