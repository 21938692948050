import queryString from 'query-string';
import { toast } from 'react-toastify';

export const CHANGE_CURRENT_OPPORTUNITY = 'opportunity/CHANGE_CURRENT_OPPORTUNITY';
export const UPDATE_OPPORTUNITIES_LIST = 'opportunity/UPDATE_OPPORTUNITIES_LIST';
export const APPEND_TO_OPPORTUNITIES_LIST = 'opportunity/APPEND_TO_OPPORTUNITIES_LIST';
export const LOADING_OPPORTUNITIES = 'opportunity/LOADING_OPPORTUNITIES';
export const LOADING_MORE_OPPORTUNITIES = 'opportunity/LOADING_MORE_OPPORTUNITIES';
export const UPDATE_SEARCH_TERM = 'opportunity/UPDATE_SEARCH_TERM';
export const UPDATE_SEARCH_FILTERS = 'opportunity/UPDATE_SEARCH_FILTERS';
export const REMOVE_READER_ENTRY = 'opportunity/REMOVE_READER_ENTRY';
export const UPDATE_OPPORTUNITY_STATE = 'opportunity/UPDATE_OPPORTUNITY_STATE';
export const LOADING_RECOMMENDATIONS = 'opportunity/LOADING_RECOMMENDATIONS';
export const UPDATE_RECOMMENDATIONS_LIST = 'opportunity/UPDATE_RECOMMENDATIONS_LIST';
export const LOADING_PREMIUM = 'opportunity/LOADING_PREMIUM';
export const UPDATE_PREMIUM_LIST = 'opportunity/UPDATE_PREMIUM_LIST';
export const UPDATE_BOOKSBYDIMENSIONS_LIST = 'opportunity/UPDATE_BOOKSBYDIMENSIONS_LIST';
export const APPEND_TO_BOOKSBYDIMENSIONS_LIST = 'opportunity/APPEND_TO_BOOKSBYDIMENSIONS_LIST';
export const APPEND_TO_DIMENSION = 'opportunity/APPEND_TO_DIMENSION';
export const UPDATE_FOLLOW_STATUS = 'opportunity/UPDATE_FOLLOW_STATUS';

const initialState = {
  currentBook: undefined,
  followStatus: {},
  books: [],
  booksByDimension: [],
  loadingOpportunities: false,
  loadingMoreOpportunities: false,
  recommendations: [],
  loadingRecommendations: false,
  premium: [],
  loadingPremium: false,
  searchTerm: '',
  searchFilters: undefined
};

// reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_CURRENT_OPPORTUNITY:
      return {
        ...state,
        currentBook: action.currentBook
      };
    case UPDATE_OPPORTUNITIES_LIST:
      return {
        ...state,
        books: action.books
      };
    case LOADING_OPPORTUNITIES:
      return {
        ...state,
        loadingOpportunities: action.value
      };
    case LOADING_MORE_OPPORTUNITIES:
      return {
        ...state,
        loadingMoreOpportunities: action.value
      };
    case APPEND_TO_OPPORTUNITIES_LIST:
      return {
        ...state,
        books: [...state.books, ...action.books]
      };
    case APPEND_TO_BOOKSBYDIMENSIONS_LIST: {
      return {
        ...state,
        booksByDimension: [...state.booksByDimension, ...action.booksByDimension]
      };
    }
    case APPEND_TO_DIMENSION: {
      const dimensionIndex = state.booksByDimension.findIndex(dimension => dimension.genre === action.dimension.genre);
      const updatedBooksByDimension = [...state.booksByDimension];
      if (dimensionIndex >= 0) {
        // dimension existed
        const previousBooks = updatedBooksByDimension[dimensionIndex].books;
        updatedBooksByDimension[dimensionIndex].books = previousBooks.concat(action.dimension.books);
      } else {
        updatedBooksByDimension.push(action.dimension);
      }

      return {
        ...state,
        booksByDimension: updatedBooksByDimension
      };
    }
    case UPDATE_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.searchTerm
      };
    case UPDATE_SEARCH_FILTERS:
      return {
        ...state,
        searchFilters: action.value
      };
    case UPDATE_FOLLOW_STATUS:
      return {
        ...state,
        followStatus: {
          ...state.followStatus,
          [action.followStatus.book]: {
            ...action.followStatus
          }
        }
      };
    case REMOVE_READER_ENTRY:
      let { reader, ...bookWithoutReader } = state.currentBook;
      return {
        ...state,
        currentBook: bookWithoutReader
      };
    case UPDATE_OPPORTUNITY_STATE:
      return {
        ...state,
        currentBook: {
          ...state.currentBook,
          state: action.state
        }
      };
    case UPDATE_RECOMMENDATIONS_LIST:
      return {
        ...state,
        recommendations: action.recommendations
      };
    case LOADING_RECOMMENDATIONS:
      return {
        ...state,
        loadingRecommendations: action.value
      };
    case UPDATE_PREMIUM_LIST:
      return {
        ...state,
        premium: action.books
      };
    case LOADING_PREMIUM:
      return {
        ...state,
        loadingPremium: action.value
      };
    case UPDATE_BOOKSBYDIMENSIONS_LIST:
      return {
        ...state,
        booksByDimension: action.booksByDimension
      };
    default:
      return state;
  }
};

// actions
export const listOpportunities = (idToken, options) => (dispatch) => {
  dispatch({
    type: LOADING_OPPORTUNITIES,
    value: true
  });
  fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/ereader/discover?${queryString.stringify(options)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'br-token': idToken
    }
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error('Failed to fetch beta reading opportunities');
    })
    .then((books) => {
      if (!options.search && options.dimension && options.dimension === 'genre') {
        return dispatch({
          type: UPDATE_BOOKSBYDIMENSIONS_LIST,
          booksByDimension: books
        });
      }
      return dispatch({
        type: UPDATE_OPPORTUNITIES_LIST,
        books
      });
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({
        type: LOADING_OPPORTUNITIES,
        value: false
      });
    });
};

export const listPremium = (idToken, options) => (dispatch) => {
  dispatch({
    type: LOADING_PREMIUM,
    value: true
  });
  fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/ereader/discover?${queryString.stringify({ ...options, premium: 1 })}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'br-token': idToken
    }
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error('Failed to fetch premium opportunities');
    })
    .then(books => dispatch({
      type: UPDATE_PREMIUM_LIST,
      books
    }))
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({
        type: LOADING_PREMIUM,
        value: false
      });
    });
};

export const loadMoreOpportunities = (idToken, options) => (dispatch) => {
  dispatch({
    type: LOADING_MORE_OPPORTUNITIES,
    value: true
  });
  let url = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/ereader/discover`;
  if (options) {
    url += `?${queryString.stringify(options)}`;
  }
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'br-token': idToken
    }
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error('Failed to fetch beta reading opportunities');
    })
    .then((books) => {
      if (!options.search && options.dimension && options.dimension === 'genre' && Array.isArray(options.genres)) {
        return dispatch({
          type: APPEND_TO_BOOKSBYDIMENSIONS_LIST,
          booksByDimension: books
        });
      }
      if (!options.search && options.dimension && options.dimension === 'genre' && !Array.isArray(options.genres)) {
        return dispatch({
          type: APPEND_TO_DIMENSION,
          dimension: books[0]
        });
      }
      return dispatch({
        type: APPEND_TO_OPPORTUNITIES_LIST,
        books
      });
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({
        type: LOADING_MORE_OPPORTUNITIES,
        value: false
      });
    })
}

export const fetchOpportunity = (idToken, bookId, invitationToken, callback) => (dispatch) => {
  let url = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/ereader/discover/${bookId}`;
  if (invitationToken) {
    url += `?token=${invitationToken}`;
  }
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'br-token': idToken
    }
  })
    .then(res => res.json())
    .then((book) => {
      if (book.err) {
        if (book.msg && callback) {
          return callback(book.msg);
        }
        throw book;
      }
      return dispatch({
        type: CHANGE_CURRENT_OPPORTUNITY,
        currentBook: book
      });
    })
    .catch((error) => {
      toast.error(`${error.err}`, { autoClose: 5000 });
      if (callback) {
        return callback(error.err);
      }
    });
};

export const listRecommendations = (idToken, options) => (dispatch) => {
  dispatch({
    type: LOADING_RECOMMENDATIONS,
    value: true
  });
  fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/ereader/discover/recommendations?${queryString.stringify(options)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'br-token': idToken
    }
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error('Failed to fetch beta reading opportunities');
    })
    .then(recommendations => dispatch({
      type: UPDATE_RECOMMENDATIONS_LIST,
      recommendations
    }))
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch({
        type: LOADING_RECOMMENDATIONS,
        value: false
      });
    });
};

export const updateSearchTerm = searchTerm => (dispatch) => {
  dispatch({
    type: UPDATE_SEARCH_TERM,
    searchTerm
  });
  // dispatch({
  //   type: UPDATE_OPPORTUNITIES_LIST,
  //   books: []
  // });
};

export const updateSearchFilters = filters => (dispatch) => {
  dispatch({
    type: UPDATE_SEARCH_FILTERS,
    value: filters
  });
};
