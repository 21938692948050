import React from 'react';
import {
  Placeholder, Divider, Tab, Item, Label
} from 'semantic-ui-react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import BookItem from '../book/bookItem';
import DownloadVersion from '../../containers/content/downloadVersion';

const ReceivedManuscriptsTabPane = ({ submissions, t }) => (
  <React.Fragment>
    <Item.Group divided>
      {
        submissions.length > 0 && submissions.map((submission) => {
          if (submission.book) {
            return (
              <React.Fragment key={submission.book._id}>
                <BookItem key={submission.createdAt} book={submission.book} />
                <Label style={{ marginBottom: 10 }} basic>
                  {t('SubmittedBy', { _userName: submission.createdBy?.displayName })}
                  <Label.Detail>
                    {moment(submission.createdAt).format('L LTS')}
                  </Label.Detail>
                </Label>
                { submission.ranking && submission.ranking.eligibleForValentineCrime && (
                  <Label style={{ marginBottom: 10 }} basic color='green'>
                    Valentine Crime Approved
                  </Label>
                )}
                <DownloadVersion
                  compact
                  style={{ float: 'right', marginBottom: 10 }}
                  text={t('Download')}
                  bookId={submission.book._id}
                  content={submission.book.content}
                />
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={`bookRemoved ${submission.createdAt}`}>
              <Divider />
              <h1>Book Removed</h1>
              <Label style={{ marginBottom: 10 }} basic>
                {t('SubmittedBy', { _userName: submission.createdBy?.displayName })}
                <Label.Detail>
                  {moment(submission.createdAt).format('L LTS')}
                </Label.Detail>
              </Label>
              { submission.ranking && submission.ranking.eligibleForValentineCrime && (
                <Label style={{ marginBottom: 10 }} basic color='green'>
                  Valentine Crime Approved
                </Label>
              )}
            </React.Fragment>
          );
        })
      }
    </Item.Group>
  </React.Fragment>
);

export default withTranslation()(ReceivedManuscriptsTabPane);
