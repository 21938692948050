import React, { PureComponent } from 'react';

// i18n
import { withTranslation } from 'react-i18next';

// Routes
import { withRouter } from 'react-router-dom';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import {
  Menu, Label
} from 'semantic-ui-react';

export class UpgradeMenuItem extends PureComponent {
  showUpgrade = (role, account, pathname) => {
    return ['WRITER', 'BOTH']
      .includes(role)
      && account
      && account.product
      && account.product.internalId === 'free'
      && pathname !== '/read'
      && !pathname.includes('/books');
  }

  render() {
    const {
      t, userProfile, location
    } = this.props;
    if (!userProfile || !this.showUpgrade(userProfile.role, userProfile.account, location.pathname)) {
      return null;
    }
    return (
      <Menu.Item key='upgrade' fitted link>
        <Label
          size='mini'
          color='orange'
          onClick={() => this.props.changePage('/products')}
        >
          {t('UpgradeSubscriptionTitle').toUpperCase()}
        </Label>
      </Menu.Item>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  userProfile: state.user.userProfile
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(UpgradeMenuItem)));
