import React, { Component } from 'react';

import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
  Visibility,
  Form
} from 'semantic-ui-react';

import { withRouter } from 'react-router-dom';
// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ReactGA from 'react-ga';

class LandingPage extends Component {
  render() {
    return (
      <div>
        <Visibility
          onBottomPassed={this.showFixedMenu}
          onBottomVisible={this.hideFixedMenu}
          once={false}
        >

        </Visibility>
        <Segment vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column width={16}>
                <Button size='huge' fluid style={{backgroundColor: '#ff9800', color: '#fff'}} onClick={() => this.props.changePage('/signup')}>
                  Join our Private Beta
                  <Icon name='right arrow' />
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column floated='left' width={8}>
                <Image
                  shape='rounded'
                  size='huge'
                  src='/assets/images/reader.jpg'
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <Header as='h3' style={{ fontSize: '2em' }}>Make Your Story Shine</Header>
                <p style={{ fontSize: '1.33em' }}>
                  Which beta reader said what? Which feedback is valid and what should I throw away? Use our feedback management tool to make sense of it all.
                </p>
                <Header as='h3' style={{ fontSize: '2em' }}>No More Waiting</Header>
                <p style={{ fontSize: '1.33em' }}>
                  Get notified in real-time as your beta readers make their way through your story and share their feedback with you.
                </p>
                <Header as='h3' style={{ fontSize: '2em' }}>All on Your Terms</Header>
                <p style={{ fontSize: '1.33em' }}>
                  Everything on BetaReader.io is private. You decide who you share your books and stories with, and you can revoke their access with the click of a button.
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>

              <Divider horizontal>Not ready to make the leap?</Divider>
              <Form size='big'
                onSubmit={() => {
                  ReactGA.event({
                      category: 'Growth',
                      action: 'Subscribed to Launch Email',
                  });

                }}
                action='https://betareader.us16.list-manage.com/subscribe/post?u=16045d127a0e5000179333ce1&amp;id=83dbceeac1' method='post' id='mc-embedded-subscribe-form' name='mc-embedded-subscribe-form'>
                <div style={{position: 'absolute', left: '-5000px', ariaHidden:'true'}}>
                  <Form.Input type="text" name="b_16045d127a0e5000179333ce1_83dbceeac1" tabIndex="-1" value="" />
                </div>
                <Form.Input
                  type='email'
                  placeholder='Email Me When You Launch'
                  name='EMAIL'
                  id='mce-EMAIL'
                  action={<Button><Icon name='mail'/></Button>}
                  onFocus={(event, data) => {
                    ReactGA.event({
                        category: 'Growth',
                        action: 'Touched Launch Email Input',
                    });
                  }}
                />
              </Form>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    )
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage)
}, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPage));
