const isMissingInformation = (userProfile) => {
  const informationToAskFor = [];
  if (userProfile) {
    if (!userProfile.displayName
      || userProfile.displayName.length === 0) {
      informationToAskFor.push('displayName');
    }
    if (!userProfile.role) {
      informationToAskFor.push('role');
    }
    if (userProfile.country === undefined) {
      informationToAskFor.push('country');
    }
    if (userProfile.yob === undefined) {
      informationToAskFor.push('yob');
    }
    if (userProfile.preferredGenres === undefined
      || userProfile.preferredGenres.length === 0) {
      informationToAskFor.push('preferredGenres');
    }
    if (['READER', 'BOTH'].includes(userProfile.role)
      && (
        userProfile.preferredLanguages === undefined || userProfile.preferredLanguages.length === 0
      )) {
      informationToAskFor.push('preferredLanguages');
    }
    if (userProfile.bio === undefined) {
      informationToAskFor.push('bio');
    }
    if (userProfile.emailPreferences === undefined
      || userProfile.emailPreferences.receiveBetaSuggestions === undefined) {
      informationToAskFor.push('emailPreferences');
    }
    if (userProfile.readerListConsent === undefined) {
      informationToAskFor.push('readerListConsent');
    }
  }
  return informationToAskFor;
};

const validateEmail = (email) => {
  const regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return regex.test(String(email).toLowerCase());
}

export default {
  isMissingInformation,
  validateEmail
};
