import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { Button, Form, Message, Divider } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import { withTranslation, Trans } from 'react-i18next';

import firebase from '../../config/fire';

const BasicSignIn = ({
  emailLocked,
  defaultEmail,

  t,
}) => {
  const location = useLocation();
  const user = useSelector(state => state.user.user);
  const parsedQueryString = useCallback(
    () => queryString.parse(location.search),
    [location?.search]
  );
  const [redirect, setRedirect] = useState(parsedQueryString().redirect);
  const [email, setEmail] = useState(defaultEmail);
  const [errorMessage, setErrorMessage] = useState();
  const [message, setMessage] = useState();
  const [password, setPassword] = useState('');

  const getRedirectResult = async () => {
    firebase
      .auth()
      .getRedirectResult()
      .then(result => {
        if (result.credential) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          // const token = result.credential.accessToken;
          // ...
        }
        // The signed-in user info.
        localStorage.removeItem('sign-in-mode');
      })
      .catch(error => {
        // Handle Errors here.
        // const errorCode = error.code;
        // The email of the user's account used.
        // const { email } = error;
        // The firebase.auth.AuthCredential type that was used.
        // const credential = error.credential;
        // ...
        setErrorMessage(error.message);
      });
  };

  useEffect(() => {
    getRedirectResult();
  }, []);

  const passwordChanged = (event, data) => {
    setPassword(data.value);
    setErrorMessage(undefined);
  };

  const emailChanged = (event, data) => {
    setEmail(data.value);
    setErrorMessage(undefined);
  };

  const signIn = type => {
    switch (type) {
      case 'email':
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .catch(error => {
            // Handle Errors here.
            // const errorCode = error.code;
            setErrorMessage(error.message);
          });
        return;
      case 'google':
        var provider = new firebase.auth.GoogleAuthProvider();
        firebase
          .auth()
          .signInWithPopup(provider)
          .then(result => {
            window.location.reload();
          });
        break;
      case 'facebook':
        var provider = new firebase.auth.FacebookAuthProvider();
        firebase
          .auth()
          .signInWithPopup(provider)
          .then(result => {
            window.location.reload();
          });
        break;
      case 'twitter':
        var provider = new firebase.auth.TwitterAuthProvider();
        firebase
          .auth()
          .signInWithPopup(provider)
          .then(result => {
            window.location.reload();
          });
        break;
      default:
    }
  };

  const validateForm = () => {
    const valid =
      password !== undefined && password.length > 6 && email !== undefined;
    return valid;
  };

  if (user !== undefined) {
    return <div>Signed in with {user.email}</div>;
  }
  return (
    <div>
      {(user === undefined || user.isAnonymous) && (
        <Form error={errorMessage !== undefined}>
          <Message error attached="bottom" content={errorMessage} />
          {message && <Message info attached="bottom" content={message} />}
          <Form.Group widths="equal">
            <Form.Field>
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder={t('Email')}
                value={email}
                onChange={emailChanged}
                onFocus={(event, data) => {
                  if (emailLocked) event.currentTarget.blur();
                }}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder={t('Password')}
                type="password"
                onChange={passwordChanged}
              ></Form.Input>
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <Button.Group>
                <Button
                  onClick={() => {
                    signIn('email');
                  }}
                  floated="left"
                  color="orange"
                  disabled={!validateForm()}
                >
                  {t('SignIn')}
                </Button>
              </Button.Group>
              <div
                style={{
                  whiteSpace: 'nowrap',
                  position: 'relative',
                  top: 4,
                  float: 'right',
                }}
              >
                <span style={{ display: 'inline-block' }}>
                  {t('OrUse')}&nbsp;
                </span>
                <Button
                  size="mini"
                  circular
                  color="facebook"
                  icon="facebook"
                  style={{ display: 'inline-block' }}
                  onClick={() => {
                    signIn('facebook');
                  }}
                />
                <Button
                  size="mini"
                  circular
                  color="google plus"
                  icon="google"
                  style={{ display: 'inline-block' }}
                  onClick={() => {
                    signIn('google');
                  }}
                />
              </div>
            </Form.Field>
          </Form.Group>
          <Trans i18nKey="ForgotPasswordPrompt">
            <div style={{ float: 'right', fontSize: '0.9em' }}>
              Forgot your password?{' '}
              <strong>
                <a
                  href={`${
                    window.location.protocol + '//' + window.location.host
                  }/reset-password`}
                >
                  Reset it here!
                </a>
              </strong>
            </div>
          </Trans>
          <br />
          <Trans i18nKey="CreateAccountdPrompt">
            <div style={{ float: 'right', fontSize: '0.9em' }}>
              Do you not have an account?{' '}
              <strong>
                <a
                  href={`${
                    window.location.protocol + '//' + window.location.host
                  }/signup?redirect=${encodeURIComponent(
                    window.location.pathname + window.location.search
                  )}`}
                >
                  Create one here!
                </a>
              </strong>
            </div>
          </Trans>
          <Divider hidden clearing />
        </Form>
      )}
    </div>
  );
};

export default withTranslation()(withRouter(BasicSignIn));
