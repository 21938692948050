import React, { Component } from 'react';
import {
  Dropdown, Header, Button
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { authUtil } from '../../../../utils';

class LanguageGetter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedLanguages: props.initialValue || []
    };
  }

  componentDidMount() {
    this.fetchLanguageOptions();
  }

  fetchLanguageOptions = async (lng = 'en') => {
    const idToken = await authUtil.getFreshIdToken();
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/global/languages?lng=${lng}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        }
        return res.json();
      })
      .then((languageOptions) => {
        if (languageOptions) {
          this.setState({
            languageOptions
          });
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        // done
      });
  }

  handleLanguageChange = (event, data) => {
    this.setState({ selectedLanguages: data.value }, () => {
      const { autoUpdate } = this.props;
      if (autoUpdate) this.submit();
    });
  }

  submit = () => {
    const { selectedLanguages, languageOptions } = this.state;
    if (this.props.onUpdate) {
      this.props.onUpdate(selectedLanguages, languageOptions.filter(option => selectedLanguages.includes(option.value)))
    }
  }

  // render
  render() {
    const {
      t, updating, wizardMode, header, subheader, autoUpdate, compact
    } = this.props;
    const { selectedLanguages, languageOptions } = this.state;
    if (compact) {
      return (
        <Dropdown
          key='input'
          placeholder={t('Languages')}
          closeOnChange
          multiple
          search
          selection
          value={selectedLanguages}
          options={languageOptions || []}
          onChange={this.handleLanguageChange}
        />
      );
    }
    return [
      <Header
        key='header'
        size='small'
        content={header || t('WhichLanguagesDoYouRead')}
        subheader={subheader === false ? null : (subheader || t('OnYourProfile'))}
      />,
      languageOptions && (
        <Dropdown
          key='input'
          placeholder={t('Languages')}
          fluid
          closeOnChange
          multiple
          search
          selection
          value={selectedLanguages}
          options={languageOptions}
          onChange={this.handleLanguageChange}
        />
      ),
      !autoUpdate && languageOptions && (
        <Button
          style={{ marginTop: '1em' }}
          key='next'
          floated='right'
          compact
          content={t('Save')}
          disabled={wizardMode && selectedLanguages?.length === 0}
          loading={!!updating}
          onClick={this.submit}
        />
      )
    ];
  }
}

export default withTranslation()(LanguageGetter)
