import {createStore, applyMiddleware, compose} from 'redux';
import {routerMiddleware} from 'connected-react-router';
import thunk from 'redux-thunk';
import {createBrowserHistory as createHistory} from 'history';
import appReducer from './modules';
import socketIoReduxMiddleware from './middleware/socketIoReduxMiddleware';

const rootReducer = (state, action) => {
  // if we log out, we clear state in redux
  if (action.type === 'user/LOGGED_OUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

export const history = createHistory();

const initialState = {};
const enhancers = [];
const middleware = [
  thunk,
  socketIoReduxMiddleware(),
  routerMiddleware(history)
];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(rootReducer, initialState, composedEnhancers);

export default store;
