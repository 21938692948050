export const UPDATE_FEED = 'feed/UPDATE_FEED'

const initialState = {
  feed: []
}

// reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FEED:
      return {
        ...state,
        feed: action.feed
      }
    default:
      return state
  }
}

export const updateFeed = (idToken) => {
  return dispatch => {
    // fetch(`${process.env.REACT_APP_API_HOST}/api/feed`, {
    //     method: 'GET',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'br-token': idToken
    //     }
    //   })
    // .then(res => res.json())
    // .then(feed => {
    //   // if the user had a profile
    //   if(feed) {
        return dispatch({
          type: UPDATE_FEED,
          feed: []
        });
    //   }
    // });
  }
}
