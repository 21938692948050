import React from 'react';
import {
  Search
} from 'semantic-ui-react';

// redux
import { useDispatch, useSelector } from 'react-redux';
// i18n
import { useTranslation, withTranslation } from 'react-i18next';
import { useHistory, useLocation, withRouter } from 'react-router-dom';

import styled, { css } from 'styled-components';

import {
  updateSearchTerm,
} from '../../modules/betaOpportunities';
import { toggleBrowseMenu } from '../../modules/app';
import SearchInput from './searchInput';

const DiscoverSearch = ({}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const loadingOpportunities = useSelector(state => state.betaOpportunities.loadingOpportunities);
  const searchTerm = useSelector(state => state.betaOpportunities.searchTerm);

  const handleSearchChange = (event, data) => {
    if (location.pathname !== '/discover') {
      history.push('/discover');
    }
    dispatch(toggleBrowseMenu(false));
    dispatch(updateSearchTerm(data?.value || ''));
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      // Enter
    }
  }

  const clearSearch = () => {
    dispatch(updateSearchTerm(''));
  }

  return (
    <>
      <SearchInput
        loading={loadingOpportunities}
        value={searchTerm}
        placeholder={t('DiscoverSearch')}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
        open={false}
        onClose={clearSearch}
      />
    </>
  );
}

export default DiscoverSearch;
