import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {
  Button,
  Form,
  Comment,
  Segment,
  Grid,
  Icon,
  Menu
} from 'semantic-ui-react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
// i18n
import {useTranslation} from 'react-i18next';

import {
  removeInlineCommentPlaceholder,
  setActiveInlineComment,
  updateCommentsWithSidebarTop,
  submitComment,
  submitReply
} from '../../modules/comment';

import CommentElement from './commentElement';
import {authUtil, uxAnalyticsUtil} from '../../utils';

/**
 * Updates top attribute of each visible comment in the sidebar, for absolute css placement
 * @param {*} commentsWithOverlappingLayout
 * @returns
 */
const getCommentListLayout = (commentsWithOverlappingLayout: any[]) => {
  const updatedCommentList = [...commentsWithOverlappingLayout].reduce(
    (accumulatedResult, _currentComment) => {
      const _previousComment = accumulatedResult[accumulatedResult.length - 1];
      const _previousBottom =
        (_previousComment?.top ?? 0) + (_previousComment?.height ?? 0); // 0 if non-existent, otherwise top + height

      let _currentTop = Math.max(0, _currentComment?.top); // top should not be less than 0 (would be outside view)
      _currentTop = Math.max(_currentTop, _previousBottom + 5); // top should be at least 5 pixels below the previous one
      accumulatedResult.push({
        ..._currentComment,
        top: _currentTop
      });
      return accumulatedResult;
    },
    []
  );

  return updatedCommentList;
};

const InlineCommentWrapper = styled.div<{top: number}>`
  position: absolute;
  top: ${({top}) => top}px;
  width: var(--sidebar-width);
  display: flex;
  flex-direction: column;
`;

interface Props {
  positioning: 'sidebar' | 'bottom';
  disableLeavingComments?: boolean;
  showOnly?: {type?: string; status?: string[]};
  enableFilters?: boolean;
  compact?: boolean;
}

const InlineCommentsList: React.FC<Props> = ({
  compact,
  disableLeavingComments,
  enableFilters,
  positioning,
  showOnly
}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const currentBook = useSelector(state => state.book.currentBook);
  const currentSpineIndex = useSelector(
    state => state.readerApp.currentSpineIndex
  );
  const comments = useSelector(state => state.comment.comments);
  const filteredReaders = useSelector(state => state.comment.filteredReaders);
  const activeInlineComment = useSelector(
    state => state.comment.activeInlineComment
  );
  const currentLanguage = useSelector(state => state.user.currentLanguage);
  const fetchingComments = useSelector(state => state.comment.fetchingComments);

  const [comment, setComment] = useState<string>();
  const [sidebarCommentElementSizes, setSidebarCommentElementSizes] = useState(
    {}
  );
  const [replyForm, setReplyForm] = useState(undefined);

  const [height, setHeight] = useState(0);

  // get currently filtered comments
  const filteredComments = useMemo(() => {
    let inlineComments = [];

    if (activeInlineComment) {
      return comments?.filter(c => c._id === activeInlineComment);
    }
    // otherwise, go through all
    inlineComments = comments?.filter((comment: any) => {
      if (
        showOnly &&
        showOnly.status &&
        comment.status &&
        !showOnly.status.includes(comment.status)
      ) {
        return false;
      }
      if (
        filteredReaders !== undefined &&
        comment.user._id !== filteredReaders._id
      ) {
        return false;
      }
      if (comment.kind !== 'InlineComment') {
        return false;
      }
      return true;
    });
    return inlineComments;
  }, [comments, filteredReaders, activeInlineComment, showOnly]);

  useEffect(() => {
    setSidebarCommentElementSizes(prev => {
      const updatedSizes: Record<string, number> = {};
      Object.entries(prev).forEach(([commentId, hgt]) => {
        const existingEntry = filteredComments?.find(c => c._id === commentId);
        if (existingEntry) {
          updatedSizes[commentId] = height;
        }
      });
      return updatedSizes;
    }); // reset the sizes when the filter changes
  }, [filteredComments?.length]);

  useEffect(() => {
    /**
     * Returns the total display height of all visible comments in the sidebar
     */
    const totalHeight =
      comments?.reduce(
        (accumulator: number, _comment: any) =>
          accumulator +
          (_comment.layout?.sidebar?.getBoundingClientRect()?.height ?? 0),
        0
      ) ?? 0;
    setHeight(totalHeight);
  }, [comments]);

  const commentMeasures = useMemo(
    () => Object.keys(sidebarCommentElementSizes).length,
    [sidebarCommentElementSizes]
  );

  useEffect(() => {
    const escapeListener = event => {
      if (event.keyCode === 27) {
        deactivate();
      }
    };

    document.addEventListener('keydown', escapeListener, false);

    return () => {
      document.removeEventListener('keydown', escapeListener, false);
    };
  }, []);

  /**
   * Repositions comment elements each time total height changes
   */
  useEffect(() => {
    if (commentMeasures === filteredComments?.length) {
      // only update positions when all visible measures are in
      updateCommentPositions();
    }
  }, [activeInlineComment, commentMeasures, height, filteredComments?.length]);

  const getActiveInlineComment = useCallback(() => {
    if (!comments || !activeInlineComment) return null;
    return comments.find(_comment => _comment._id === activeInlineComment);
  }, [comments, activeInlineComment]);

  const handleCommentUpdate = (e, data) => {
    setComment(data.value);
  };

  const handleShowAllClick = event => {
    event.preventDefault();
    event.stopPropagation();
    deactivate();
  };

  const handleSubmitComment = async (event, data) => {
    const idToken = authUtil.getFreshIdToken();

    dispatch(
      submitComment(
        idToken,
        currentBook._id,
        currentBook.parts[currentSpineIndex]._id,
        comment
      )
    );

    uxAnalyticsUtil.trackEvent({
      category: 'Reading',
      action: 'Left comment'
    });

    setReplyForm(undefined);
    setComment('');
  };

  const _submitReply = async (_comment, reply) => {
    const idToken = await authUtil.getFreshIdToken();
    dispatch(
      submitReply(idToken, _comment, reply, callback => {
        if (callback) {
          updateCommentPositions();
        }
      })
    );
  };

  // adjust the position of comments in the right hand comment list
  const updateCommentPositions = () => {
    // filter out inline comments
    const notFilteredComments: any = [];
    const inlineCommentBlocks = document.getElementsByClassName(
      'inline-comment-block'
    );
    const filteredComments = comments?.filter(_comment => {
      if (activeInlineComment && _comment._id !== activeInlineComment) {
        notFilteredComments.push(_comment);
        return false; // don't include if we have an active comment and this is not the one
      }
      // only show comments with matching statuses
      if (
        showOnly &&
        showOnly.status &&
        _comment.status &&
        !showOnly.status.includes(_comment.status)
      ) {
        notFilteredComments.push(_comment);
        return false;
      }
      // only show comments with matching readers/users
      if (
        filteredReaders !== undefined &&
        _comment.user._id !== filteredReaders._id
      ) {
        notFilteredComments.push(_comment);
        return false;
      }
      // only show inline comments (chapter comments are shown below)
      if (_comment.kind !== 'InlineComment') {
        notFilteredComments.push(_comment);
        return false;
      }
      return true;
    });

    // get the boundary of the editor and update position
    const updatedCommentLayouts: any[] = []; // placeholder for comments with updated position
    const textEditorReference =
      document.getElementsByClassName('DraftEditor-root')[0];
    const textEditorTop = textEditorReference
      ? textEditorReference.getBoundingClientRect().top
      : 0;

    let allComments = [];
    if (filteredComments?.length > 0) {
      // go through all visible comments
      filteredComments.forEach(_comment => {
        // Find the current height of the comment element in the sidebar
        // const sidebarPlacementHeight = Math.round(_comment?.layout?.sidebar?.getBoundingClientRect()?.height ?? 0);
        const sidebarPlacementHeight = Math.round(
          sidebarCommentElementSizes[_comment?._id] ?? 0
        );
        // Find the current top and left position of the comment element in the inline text
        const inlineBlockElement = inlineCommentBlocks.namedItem(
          `br-inline-cid-${_comment._id}`
        );
        const inlinePlacementTop = Math.round(
          inlineBlockElement?.getBoundingClientRect()?.top ?? 0
        );
        const inlinePlacementLeft = Math.round(
          inlineBlockElement?.getBoundingClientRect()?.left ?? 0
        );
        // hardcoded value of the list div top since its not always available at the time
        let commentListTop = 51;
        if (filteredReaders !== undefined) {
          // 25px is the approximate space that the reader filter takes
          commentListTop = 25;
        }
        // create an updated comment with positioning used for the right commentlist
        const top = inlinePlacementTop + commentListTop - textEditorTop;

        const left = inlinePlacementLeft;
        const height = sidebarPlacementHeight;
        const bottom = top + height;
        const newCommentLayout = {
          ..._comment,
          top,
          left,
          height,
          bottom
        };
        updatedCommentLayouts.push(newCommentLayout);
      });
      // sort list
      const commentsSortedByInLineAppearance = sortInlineComments(
        updatedCommentLayouts
      );
      // adjust positioning if comments are overlapping
      const commentListLayout = getCommentListLayout(
        commentsSortedByInLineAppearance
      );
      // add back chaptercomments and remaining inlinecomments, since we want to keep them in state as well to show
      allComments = commentListLayout.concat(notFilteredComments);
    }
    // update state with the updated inline comment elements
    dispatch(updateCommentsWithSidebarTop(allComments));
  };

  const getBottomBarInlineCommentsList = () => {
    const currentComment = getActiveInlineComment();
    if (!currentComment) return null;
    const hasReplies = currentComment.replies?.length > 0;
    return (
      <Segment className='br-text inlineCommentsList'>
        <Grid stackable={false}>
          <Grid.Row columns={1} textAlign='right'>
            <Grid.Column></Grid.Column>
            <Grid.Column verticalAlign='top'>
              <Icon
                style={{fontSize: '1.5em'}}
                link
                onClick={deactivate}
                name='close'
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched columns={3}>
            <Grid.Column width={2} verticalAlign='middle'>
              <Icon
                fitted
                size='big'
                name='chevron left'
                link
                onClick={previousComment}
              />
            </Grid.Column>
            <Grid.Column width={12}>
              <Grid.Row centered>
                <CommentElement
                  compact
                  onSizeChange={handleCommentSizeChange}
                  comment={currentComment}
                  isActive
                  currentLanguage={currentLanguage}
                  hasReplies={hasReplies}
                  replyForm={replyForm}
                  submitReply={_submitReply}
                />
              </Grid.Row>
            </Grid.Column>
            <Grid.Column
              width={2}
              verticalAlign='middle'
              textAlign='right'
              floated='right'>
              <Grid.Row textAlign='right'>
                <Icon
                  fitted
                  size='big'
                  name='chevron right'
                  link
                  onClick={nextComment}
                />
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  };

  const handleCommentSizeChange = (
    commentId: string,
    height: number | undefined
  ) => {
    if (height !== undefined) {
      setSidebarCommentElementSizes(prev => ({
        ...prev,
        [commentId]: height
      }));
    }
  };

  const sortInlineComments = unSortedInlineComments => {
    const sorted = unSortedInlineComments.sort((commentA, commentB) => {
      if (commentA.top === commentB.top) {
        return commentA.left - commentB.left;
      }
      return commentA.top - commentB.top;
    });
    return sorted;
  };

  const deactivate = () => {
    dispatch(setActiveInlineComment({}));
    dispatch(removeInlineCommentPlaceholder());
  };

  const nextComment = () => {
    const currentIndex = comments?.findIndex(
      comment => comment._id === activeInlineComment
    );
    if (currentIndex >= 0) {
      const nextIndex = (currentIndex + 1) % comments.length;
      const nextActiveInlineComment = comments[nextIndex];
      activateComment(nextActiveInlineComment);
    }
  };

  const previousComment = () => {
    const currentIndex = comments?.findIndex(
      comment => comment._id === activeInlineComment
    );
    if (currentIndex >= 0) {
      let nextIndex = (currentIndex - 1) % comments.length;
      if (nextIndex === -1) {
        nextIndex = comments.length - 1;
      }
      const nextActiveInlineComment = comments[nextIndex];
      activateComment(nextActiveInlineComment);
    }
  };

  const activateComment = inlineComment => {
    if (inlineComment && inlineComment.layout && inlineComment.layout.inline) {
      dispatch(
        setActiveInlineComment({commentId: inlineComment._id, show: 'inline'})
      );

      inlineComment.layout.inline.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start'
      });
    }
  };

  const sidebarInlineCommentsList = useMemo(() => {
    if (filteredComments?.length > 0) {
      const commentList = filteredComments.map((_comment, index) => {
        const hasReplies = _comment?.replies?.length > 0;
        const isActive = activeInlineComment === _comment._id;
        let hide = false;
        if (!!activeInlineComment && !isActive) {
          // if there is an active comment, don't show any other comments
          return null;
          hide = true;
        }
        return (
          <InlineCommentWrapper top={_comment.top || 0} key={_comment._id}>
            {!!activeInlineComment &&
              isActive &&
              activeInlineComment !== 'placeHolder' && (
                // if there is an active comment and this is the one,
                // show expand buttons on either side
                <Menu text compact style={{marginBottom: 0}}>
                  <Menu.Item onClick={handleShowAllClick}>
                    {t('ShowAllComments')}
                  </Menu.Item>
                </Menu>
              )}
            <CommentElement
              compact={compact !== undefined}
              isActive={isActive}
              onSizeChange={handleCommentSizeChange}
              comment={_comment}
              currentLanguage={currentLanguage}
              hasReplies={hasReplies}
              replyForm={replyForm}
              submitReply={_submitReply}
              enableFilters={enableFilters}
            />
          </InlineCommentWrapper>
        );
      });
      return commentList;
    } else {
      return null;
    }
  }, [activeInlineComment, filteredComments]);

  if (fetchingComments) return null;
  // filter out inline comments according to current filters
  const lastInlineComment =
    filteredComments && filteredComments[filteredComments.length - 1];
  const inlineCommentsBottomY = lastInlineComment
    ? lastInlineComment.bottom
    : document.documentElement.clientHeight;
  if (positioning === 'sidebar') {
    return (
      <Comment.Group
        style={{
          position: 'relative',
          margin: 0,
          padding: 0,
          height: inlineCommentsBottomY
        }}
        onClick={deactivate}>
        {!disableLeavingComments && (
          <Form reply>
            <Form.TextArea onChange={handleCommentUpdate} value={comment} />
            <Button
              disabled={!comment || comment?.length === 0}
              onClick={handleSubmitComment}
              content={t('LeaveComment')}
              labelPosition='left'
              icon='edit'
              primary
            />
          </Form>
        )}
        {filteredComments?.length === 0 && (
          <Comment content={t('NoCommentsToShow')} />
        )}
        {sidebarInlineCommentsList}
      </Comment.Group>
    );
  } else if (
    positioning === 'bottom' &&
    !!activeInlineComment &&
    getActiveInlineComment()?.kind === 'InlineComment'
  ) {
    return getBottomBarInlineCommentsList();
  }
  return null;
};

export default InlineCommentsList;
