import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Form, Segment
} from 'semantic-ui-react';
import moment from 'moment';

// i18n
import { withTranslation } from 'react-i18next';

import Avatar from '../../../components/avatar/avatar';

export class BroadcastMessageList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t, message } = this.props;
    if (!message) return null;
    return (
      <Form>
        <Form.Field>
          <Form.Input
            size='large'
            placeholder={t('Title')}
            transparent
            value={message.title}
          />
        </Form.Field>
        <Form.Field style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <span style={{ marginRight: 5 }}>
            {t('MessageTo')}
            :
          </span>
          {
            message.recipients.map((recipient, index) => (
              <Avatar
                key={recipient._id}
                diameter={25}
                user={recipient}
                name={recipient.displayName}
                style={{ marginLeft: index > 0 ? -2 : 0 }}
              />
            ))
          }
        </Form.Field>
        <div>
          <Form.Field
            style={{
              paddingBottom: '0.5em',
              borderBottom: '1px solid lightgrey'
            }}
          >
            <span>
              {message.subject}
            </span>
          </Form.Field>
          <Form.Field
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: '0.5em',
              borderBottom: '1px solid lightgrey'
            }}
          >
            <span style={{ flex: 1, fontWeight: 'bold', color: 'green' }}>
              {message.from.displayName}
            </span>
            <span style={{ marginLeft: 'auto' }}>
              {moment(message.sentAt).format('ll')}
            </span>
          </Form.Field>
          <Form.Field>
            <div dangerouslySetInnerHTML={{ __html: message.html }} />
          </Form.Field>
        </div>
      </Form>
    );
  }
}
export default withTranslation()(withRouter((BroadcastMessageList)));
