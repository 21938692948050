import React, { Component } from 'react';
import { withRouter, Redirect, Link } from 'react-router-dom';
import {
  Button,
  Grid,
  Image,
  Form,
  Ref,
  Icon,
  Message,
  Checkbox,
  Divider,
  Menu,
} from 'semantic-ui-react';
import queryString from 'query-string';
import { Media } from 'src/utils/Media';

import ReactGA from 'react-ga';
// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// i18n
import { withTranslation, Trans } from 'react-i18next';
import firebase from '../../config/fire';
import { updateUser, updateUserProfile } from '../../modules/user';
import Registered from './registered';
import SalesPitch from '../../components/standard-messages/salesPitch';

export class SignUp extends Component {
  constructor(props) {
    super(props);
    this.displayNameInput = React.createRef();

    // check if the user got here by invitation
    const parsedQueryString = queryString.parse(props.location.search);
    const { target, invitationToken, lng, email } = parsedQueryString;
    if (target) {
      localStorage.setItem('br-signup-target-url', decodeURI(target));
    }
    if (lng) {
      localStorage.setItem('br-signup-lng', decodeURI(lng));
    }
    if (invitationToken) {
      localStorage.setItem(
        'br-signup-referer-token',
        decodeURI(invitationToken)
      );
    }
    if (email) {
      localStorage.setItem('br-signup-referred-email', decodeURI(email));
    }
    // const self = this;
    this.state = {
      invitationToken,
      displayName: '',
    };
  }

  componentDidMount() {
    const { location } = this.props.history;
    if (localStorage.getItem('sign-in-mode') === 'redirect') {
      localStorage.removeItem('sign-in-mode');
      this.setState({
        creatingAccount: true,
      });
    }
    ReactGA.pageview(location.pathname + location.hash + location.search);
    if (this.displayNameInput && this.displayNameInput.current) {
      this.displayNameInput.current.querySelector('input').focus();
    }
  }

  passwordChanged = (event, data) => {
    this.setState({
      password: data.value,
      passwordError:
        data.value !== undefined && data.value.length > 6
          ? false
          : 'Password needs to be at least 6 characters long',
      errorMessage: undefined,
    });
  };
  repeatPasswordChanged = (event, data) => {
    this.setState({
      repeatPassword: data.value,
      passwordsMatch: data.value === this.state.password,
      errorMessage: undefined,
    });
  };

  emailChanged = (event, data) => {
    this.setState({
      email: data.value,
      errorMessage: undefined,
    });
  };

  signIn = async type => {
    const { t } = this.props;
    if (!this.state.consent) {
      this.setState({
        error: 'consent',
        errorMessage:
          "You must agree to BetaReader's Privacy Policy & Terms of Service in order to sign up.",
      });
    } else {
      this.setState({
        creatingAccount: true,
      });
      switch (type) {
        case 'email':
          if (this.state.password && this.state.password.length >= 6) {
            try {
              const user = await firebase
                .auth()
                .createUserWithEmailAndPassword(
                  this.state.email,
                  this.state.password
                );
              const idToken = await user.getIdToken();
              this.props.updateUserProfile(idToken, {
                role: 'WRITER',
                displayName: this.state.displayName || user.displayName,
                invitationToken: this.state.invitationToken,
              });
            } catch (e) {
              this.setState({
                errorMessage: e.message,
              });
            }
          } else {
            this.setState({
              errorMessage: t('PasswordLength'),
            });
          }
          break;
        case 'apple':
          localStorage.setItem('sign-in-mode', 'redirect');
          try {
            const appleAuthProvider = new firebase.auth.OAuthProvider(
              'apple.com'
            );
            const appleAuthResult = await firebase
              .auth()
              .signInWithRedirect(appleAuthProvider);
            const { user } = appleAuthResult;
            const idToken = await user.getIdToken();
            this.props.updateUserProfile(idToken, {
              role: 'WRITER',
              displayName: this.state.displayName || user.displayName,
              invitationToken: this.state.invitationToken,
            });
          } catch (e) {
            this.setState({
              errorMessage: e.message,
            });
          }
          break;
        case 'google':
          try {
            localStorage.setItem('sign-in-mode', 'redirect');
            const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
            const googleAuthResult = await firebase
              .auth()
              .signInWithRedirect(googleAuthProvider);
            const { user } = googleAuthResult;
            const idToken = await user.getIdToken();
            this.props.updateUserProfile(idToken, {
              role: 'WRITER',
              displayName: this.state.displayName || user.displayName,
              invitationToken: this.state.invitationToken,
            });
          } catch (e) {
            this.setState({
              errorMessage: e.message,
            });
          }
          break;
        case 'facebook':
          try {
            localStorage.setItem('sign-in-mode', 'redirect');
            const facebookAuthProvider =
              new firebase.auth.FacebookAuthProvider();
            const facebookAuthResult = await firebase
              .auth()
              .signInWithRedirect(facebookAuthProvider);
            const { user } = facebookAuthResult;
            const idToken = await user.getIdToken();
            this.props.updateUserProfile(idToken, {
              role: 'WRITER',
              displayName: this.state.displayName || user.displayName,
              invitationToken: this.state.invitationToken,
            });
          } catch (e) {
            this.setState({
              errorMessage: e.message,
            });
          }
          break;
        case 'twitter':
          localStorage.setItem('sign-in-mode', 'redirect');
          try {
            const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
            const twitterAuthResult = await firebase
              .auth()
              .signInWithRedirect(twitterAuthProvider);
            let { user } = twitterAuthResult.user;
            let idToken = await user.getIdToken();
            this.props.updateUserProfile(idToken, {
              role: 'WRITER',
              displayName: this.state.displayName || user.displayName,
              invitationToken: this.state.invitationToken,
            });
          } catch (e) {
            this.setState({
              errorMessage: e.message,
            });
          }
          break;
        default:
      }
      this.setState({
        creatingAccount: false,
      });
    }
  };

  consentChanged = (event, data) => {
    this.setState({
      consent: data.checked,
      error: undefined,
      errorMessage: undefined,
    });
  };

  handleResendVerificationEmail = (event, data) => {
    const { user } = this.props;
    const self = this;
    event.preventDefault();
    event.stopPropagation();
    if (user !== undefined) {
      self.setState({
        verificationEmailMessage: 'Sending...',
      });
      user
        .sendEmailVerification()
        .then(function () {
          self.setState({
            verificationEmailMessage: 'Verification email sent.',
          });
        })
        .catch(function (error) {
          self.setState({
            verificationEmailMessage:
              'Failed to send verification email. ' + error,
          });
        });
    }
  };

  displayNameChanged = event => {
    const newDisplayName = event.currentTarget.value;
    this.setState({
      displayName: newDisplayName,
    });
    localStorage.setItem('br-signup-display-name', newDisplayName);
  };

  render() {
    const {
      verificationEmailMessage,
      consent,
      creatingAccount,
      displayName,
      password,
      errorMessage,
    } = this.state;

    const { location, user, userProfile, t } = this.props;
    if (user !== undefined && !user.isAnonymous && !user.emailVerified) {
      return (
        <Registered
          user={user}
          verificationEmailMessage={verificationEmailMessage}
          handleResendVerificationEmail={e =>
            this.handleResendVerificationEmail(e)
          }
        />
      );
    }
    if (
      user !== undefined &&
      userProfile !== undefined &&
      !user.isAnonymous &&
      user.emailVerified
    ) {
      return (
        <Redirect
          to={
            ['WRITER', 'BOTH'].includes(userProfile.role)
              ? '/mymanuscripts'
              : '/discover'
          }
        />
      );
    }
    return (
      <Grid
        textAlign="center"
        style={{ height: '100%', width: '100%' }}
        stackable
        columns="equal"
        verticalAlign="top"
      >
        <Grid.Row>
          <Grid.Column style={{ maxWidth: 450 }}>
            <Image src="/assets/images/BR-orange-horizontal.png" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Media greaterThan="mobile">
            {(className, renderChildren) =>
              renderChildren && (
                <Grid.Column
                  style={{ width: 450, maxWidth: 450 }}
                  textAlign="left"
                >
                  <SalesPitch />
                </Grid.Column>
              )
            }
          </Media>
          <Grid.Column style={{ maxWidth: 450 }}>
            <Form
              error={errorMessage !== undefined}
              onSubmit={() => {
                this.signIn('email');
              }}
              loading={localStorage.getItem('sign-in-mode') === 'redirect'}
            >
              <Menu text>
                <Menu.Menu position="right">
                  <Menu.Item as={Link} to={`/signin${location.search}`}>
                    {t('SignIn')}
                  </Menu.Item>
                  <Menu.Item
                    active
                    color="orange"
                    as={Link}
                    to={`/signup${location.search}`}
                  >
                    {t('CreateAccount')}
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
              <Message error attached="bottom" content={errorMessage} />
              {user === undefined && (
                <React.Fragment>
                  <Ref innerRef={this.displayNameInput}>
                    <Form.Input
                      size="large"
                      fluid
                      icon="user"
                      iconPosition="left"
                      placeholder={t('Name')}
                      value={displayName}
                      onChange={this.displayNameChanged}
                    />
                  </Ref>
                  <Form.Input
                    size="large"
                    fluid
                    icon="mail"
                    iconPosition="left"
                    placeholder={t('Email')}
                    onChange={this.emailChanged}
                  />
                  <Form.Input
                    size="large"
                    fluid
                    icon="lock"
                    iconPosition="left"
                    placeholder={t('Password')}
                    type="password"
                    error={password !== undefined && password.length < 6}
                    onChange={this.passwordChanged}
                  />
                  <Form.Field
                    error={this.state.error === 'consent'}
                    style={{ textAlign: 'left' }}
                  >
                    <Checkbox
                      toggle
                      checked={consent}
                      label={
                        <label>
                          <Trans i18nKey="IAcceptToC">
                            <span>
                              I accept BetaReader.io's{' '}
                              <a
                                href={'https://betareader.io/tos'}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Privacy Policy
                              </a>{' '}
                              &{' '}
                              <a
                                href={'https://betareader.io/privacy'}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Terms of Service
                              </a>
                            </span>
                          </Trans>
                        </label>
                      }
                      onChange={this.consentChanged}
                    />
                  </Form.Field>
                  <Form.Button
                    disabled={
                      !consent || !displayName || displayName.length === 0
                    }
                    style={{ background: '#FF9B08', color: '#FFF' }}
                    type="submit"
                    fluid
                    loading={
                      creatingAccount ||
                      localStorage.getItem('sign-in-mode') === 'redirect'
                    }
                    content={t('CreateAccount').toUpperCase()}
                  />
                  <Divider horizontal>{t('OrUse')}</Divider>
                  <Form.Field>
                    <Grid>
                      <Grid.Column>
                        <Grid.Row style={{ paddingBottom: '0.9em' }}>
                          <Button
                            disabled={!consent}
                            basic
                            color={!consent ? 'grey' : undefined}
                            circular
                            fluid
                            size="large"
                            onClick={e => {
                              e.preventDefault();
                              this.signIn('facebook');
                            }}
                          >
                            <Button.Content>
                              <Grid>
                                <Grid.Row stretched columns={3}>
                                  <Grid.Column floated="left" width={2}>
                                    <Icon
                                      style={{ color: '#3B5998' }}
                                      name="facebook"
                                    />
                                  </Grid.Column>
                                  <Grid.Column
                                    width={12}
                                    style={{ textAlign: 'center' }}
                                  >
                                    {t('ContinueWithFacebook')}
                                  </Grid.Column>
                                  <Grid.Column width={2} />
                                </Grid.Row>
                              </Grid>
                            </Button.Content>
                          </Button>
                        </Grid.Row>
                        <Grid.Row style={{ paddingBottom: '0.9em' }}>
                          <Button
                            disabled={!consent}
                            basic
                            color={!consent ? 'grey' : undefined}
                            circular
                            fluid
                            size="large"
                            onClick={e => {
                              e.preventDefault();
                              this.signIn('google');
                            }}
                          >
                            <Button.Content>
                              <Grid>
                                <Grid.Row stretched columns={3}>
                                  <Grid.Column floated="left" width={2}>
                                    <Icon
                                      style={{ color: '#db3236' }}
                                      name="google"
                                    />
                                  </Grid.Column>
                                  <Grid.Column
                                    width={12}
                                    style={{ textAlign: 'center' }}
                                  >
                                    {t('ContinueWithGoogle')}
                                  </Grid.Column>
                                  <Grid.Column width={2} />
                                </Grid.Row>
                              </Grid>
                            </Button.Content>
                          </Button>
                        </Grid.Row>
                        <Grid.Row>
                          <Button
                            disabled={!consent}
                            icon
                            labelPosition="right"
                            basic
                            color={!consent ? 'grey' : undefined}
                            circular
                            fluid
                            size="large"
                            onClick={e => {
                              e.preventDefault();
                              this.signIn('apple');
                            }}
                          >
                            <Button.Content>
                              <Grid>
                                <Grid.Row stretched columns={3}>
                                  <Grid.Column floated="left" width={2}>
                                    <Icon
                                      style={{ color: 'grey' }}
                                      name="apple"
                                    />
                                  </Grid.Column>
                                  <Grid.Column
                                    width={12}
                                    style={{ textAlign: 'center' }}
                                  >
                                    {t('ContinueWithApple')}
                                  </Grid.Column>
                                  <Grid.Column width={2} />
                                </Grid.Row>
                              </Grid>
                            </Button.Content>
                          </Button>
                        </Grid.Row>
                      </Grid.Column>
                    </Grid>
                  </Form.Field>
                </React.Fragment>
              )}
            </Form>
          </Grid.Column>
          <Media at="mobile">
            {(className, renderChildren) =>
              renderChildren && (
                <Grid.Column
                  // style={{maxWidth: window.innerWidth}}
                  textAlign="left"
                >
                  <SalesPitch />
                </Grid.Column>
              )
            }
          </Media>
        </Grid.Row>
      </Grid>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  userProfile: state.user.userProfile,
  idToken: state.user.idToken,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateUser,
      updateUserProfile,
      changePage: newPage => push(newPage),
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp))
);
