import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  accessUtil,
  authUtil,
  productCapUtil,
  uxAnalyticsUtil,
} from '../../utils';
import PropTypes from 'prop-types';

import { getCoverImage } from './bookCover/coverWrapper';
import { Button, Header, Label, Segment } from 'semantic-ui-react';
import { fetchCurrentBook } from 'src/modules/book';
import BetaIcon from 'src/components/BetaIcon';
import { CenteredContent } from 'src/style';
import UpgradeToUnlock from 'src/components/payments/UpgradeToUnlock';
import CopyText from 'src/components/sharing/CopyText';

const downloadFile = async (fileUrl: string, fileName: string) => {
  const response = await fetch(fileUrl);
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${fileName}.pdf`;
  a.click();
  window.URL.revokeObjectURL(url);
};

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const TitleContent = styled.h1`
  flex: 1;
  font-size: 1.5em;
  font-weight: bold;
`;
const Right = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
`;

const MyCenteredContent = styled(CenteredContent)`
  padding-left: 1rem;
  padding-right: 1rem;
`;

const MetaData = styled(({ smallScreen, ...rest }) => <div {...rest} />)`
  background-color: white;
  padding: 3px;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1em !important;
  border-radius: 5px !important;
  color: grey;
`;

MetaData.propTypes = {
  smallScreen: PropTypes.bool,
};

type GenerateSummaryProps = {
  idToken: string;
  bookId: string;
  contentId: string;
};

const BookSummary = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentBook = useSelector(state => state.book.currentBook);
  const userProfile = useSelector(state => state.user.userProfile);

  const [isDownloading, setIsDownloading] = useState(false);

  const isFreeUser = productCapUtil.isOnFreePlan({ userProfile });

  const { isLoading: generateSummaryIsLoading, mutateAsync: generateSummary } =
    useMutation(({ idToken, bookId, contentId }: GenerateSummaryProps) => {
      return fetch(
        `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/content/${contentId}/summarize`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'br-token': idToken,
          },
        }
      );
    });

  const lastSummary = useMemo(() => {
    if (!currentBook) {
      return null;
    }

    const contentWithSummary = currentBook.content.find(
      content => !!content.parts.some(part => !!part.autoGeneratedSummary)
    );
    return contentWithSummary
      ? {
          summary: contentWithSummary?.autoGeneratedSummary,
          elevatorPitch: contentWithSummary?.autoGeneratedElevatorPitch,
          lastUpdated: contentWithSummary?.autoGeneratedSummaryUpdatedAt
            ? new Date(contentWithSummary?.autoGeneratedSummaryUpdatedAt)
            : new Date(),
          contentId: contentWithSummary?.id,
          partSummaries:
            contentWithSummary?.parts.map(part => ({
              summary: part.autoGeneratedSummary,
              partId: part.id,
              title: part.title,
            })) ?? [],
        }
      : undefined;
  }, [currentBook]);

  const canEdit = useCallback(() => {
    return accessUtil.isAllowed(currentBook.access, ['edit']);
  }, [currentBook?.access]);

  const isAuthorOrCollaborator = useCallback(
    () => ['author', 'collaborator'].includes(currentBook.role),
    [currentBook?.role]
  );

  const handleGenerateSummaryClick = useCallback(async () => {
    uxAnalyticsUtil.trackEvent({
      category: 'AI',
      action: `clicked-generate-summary`,
    });
    const idToken = await authUtil.getFreshIdToken();
    try {
      await generateSummary({
        idToken,
        bookId: currentBook.id,
        contentId: currentBook.content.at(-1).id,
      });
      dispatch(
        fetchCurrentBook({ idToken, bookId: currentBook._id, token: undefined })
      );
    } catch (error) {
      console.log(error);
    }
  }, [currentBook]);

  const handleDownloadClick = async () => {
    uxAnalyticsUtil.trackEvent({
      category: 'AI',
      action: `clicked-download-summary`,
    });
    setIsDownloading(true);
    const idToken = await authUtil.getFreshIdToken();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${currentBook._id}/content/${lastSummary?.contentId}/synopsis?format=pdf`,
        {
          method: 'GET',
          headers: {
            'br-token': idToken,
          },
        }
      );
      const result = await response.json();
      if (result.url) {
        downloadFile(result.url, `${currentBook.title} synopsis`);
      } else {
        toast.error(`${t('DownloadFailedMsg')}`);
      }
    } catch (e) {
      console.error('failed to download version', e);
      return false;
    } finally {
      setIsDownloading(false);
    }
  };

  if (!currentBook) {
    return null;
  }

  const cover = getCoverImage(currentBook);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{currentBook.title}</title>
        <meta
          property="og:title"
          content={`${t('Read')} ${currentBook.title} ${t('by')} ${
            currentBook.authorName
          } ${t('onBetaReader')}`}
        />
        <meta property="og:site_name" content="BetaReader.io" />
        <meta property="og:description" content={currentBook.description} />
        <meta property="og:image" content={cover} />
      </Helmet>
      <MyCenteredContent>
        <TitleWrapper>
          <TitleContent>
            {t('Synopsis')}
            <BetaIcon />
          </TitleContent>
          {canEdit() && isAuthorOrCollaborator() && (
            <Right>
              <Button.Group>
                <Button
                  disabled={isFreeUser}
                  loading={generateSummaryIsLoading}
                  onClick={handleGenerateSummaryClick}
                  icon={lastSummary ? 'refresh' : 'plus'}
                  content={
                    lastSummary ? t('RefreshSynopsis') : t('CreateSynopsis')
                  }
                />

                <Button
                  disabled={isFreeUser || !lastSummary}
                  icon="download"
                  loading={isDownloading}
                  onClick={handleDownloadClick}
                  content={`${t('Download')}`}
                />
              </Button.Group>
              {isFreeUser && <UpgradeToUnlock />}
            </Right>
          )}
        </TitleWrapper>
        <Right>
          {t('LastUpdated')}:{' '}
          {lastSummary?.lastUpdated?.toLocaleDateString() ?? ''}
        </Right>
        <em>{t('SynopsisDescription')}</em>

        {lastSummary && (
          <>
            <TitleContent>
              {t('ElevatorPitch')}{' '}
              <CopyText
                text={lastSummary.elevatorPitch}
                title={t('ElevatorPitch')}
              />
            </TitleContent>
            <Segment>{lastSummary.elevatorPitch}</Segment>
            <TitleContent>
              {t('Chapters')}{' '}
              <CopyText
                text={lastSummary.partSummaries
                  .map(p => `${p.title}\n${p.summary}`)
                  .join('\n\n')}
                title={t('Chapters')}
              />
            </TitleContent>
            {lastSummary.partSummaries.map(part => (
              <Segment key={part.title}>
                <Header>{part.title}</Header>
                {part.summary}
              </Segment>
            ))}
          </>
        )}
      </MyCenteredContent>
    </>
  );
};

export default BookSummary;
