import React, { Component } from 'react';

// i18n
import { withTranslation } from 'react-i18next';

// Routes
import { withRouter, Switch, Route } from 'react-router-dom';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Submission from './submission';
import Inbox from './inbox';
import ManuscriptInboxList from './manuscriptInboxList';

export class ManuscriptInboxIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
  }

  render() {
    return (
      <Switch>
        <Route exact path='/manuscript-inbox/submission' component={Submission} />
        <Route path='/manuscript-inbox/:inboxId' component={Inbox} />
        <Route path='/manuscript-inbox/' component={ManuscriptInboxList} />
      </Switch>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  userProfile: state.user.userProfile
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ManuscriptInboxIndex)));
