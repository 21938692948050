import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react'
import { Slider } from 'react-semantic-ui-range'

const defaultOptions = {
  minValue: 0, maxValue: 5, minLabel: 0, maxLabel: 5
};

export class LinearScaleAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillUnmount() {
    if (this.props && this.props.clearSurvey) {
      this.props.clearSurvey();
    }
  }

  render() {
    const { question, answer } = this.props;
    const options = {
      ...defaultOptions
    };
    if (question.options !== undefined) {
      options.minLabel = (question.options.minLabel !== undefined && question.options.minLabel.length > 0) ? question.options.minLabel : question.options.minValue;
      options.minValue = question.options.minValue || 0;
      options.maxLabel = (question.options.maxLabel !== undefined && question.options.maxLabel.length > 0) ? question.options.maxLabel : question.options.maxValue;
      options.maxValue = question.options.maxValue || 5;
    }
    return (
      <Grid columns='equal'>
        <Grid.Row>
          <Grid.Column>
            <Slider
              discrete
              color='black'
              inverted={false}
              settings={
                {
                  min: options.minValue,
                  max: options.maxValue,
                  step: 1,
                  start: answer.answer,
                  value: answer.answer,
                  onChange: (value) => {
                    this.props.onChange(value);
                  }
                }
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0, fontSize: '0.7em' }}>
          <Grid.Column floated='left' textAlign='left' verticalAlign='top'>
            <span>
              {options.minLabel}
            </span>
          </Grid.Column>
          <Grid.Column floated='right' textAlign='right' verticalAlign='top'>
            <span>
              {options.maxLabel}
            </span>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default LinearScaleAnswer;
