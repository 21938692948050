import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Header
} from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class UserProfileBuilder extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const self = this;
    setTimeout(() => {
      self.complete();
    }, 1000);
  }

  goToProfile = () => {
    this.props.changePage('/account/profile');
    this.complete();
  }

  complete = () => {
    if (this.props.onCompleted) {
      this.props.onCompleted();
    }
  }

  // render
  render() {
    return (
      <Trans i18nKey='ProfileBuilderThankYouMessage'>
        <Header size='large'>Thank you!</Header>
        <div>You can always update your profile <Link to='/account/profile' onClick={this.goToProfile}>here</Link>.</div>
      </Trans>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileBuilder)));
