import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';
import fire from './config/fire';

const Restricted = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated === true ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            search: `redirect=${encodeURIComponent(
              window.location.pathname + window.location.search
            )}`,
          }}
        />
      )
    }
  />
);

function mapStateToProps(state) {
  return {
    isAuthenticated:
      (!!fire.auth().currentUser && !fire.auth().currentUser.isAnonymous) ||
      (!!localStorage.getItem('br-storage-key') &&
        !localStorage.getItem('br-is-anonymous')),
    idToken: localStorage.getItem('br-storage-key'),
    location: state.location,
  };
}

export default withRouter(connect(mapStateToProps, null)(Restricted));
