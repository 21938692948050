import React, { Component } from 'react';

import {
  Container, Header, Dimmer, Loader, Button, Image, Message
} from 'semantic-ui-react';

import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  fetchCurrentBookAsReader,
  resetCurrentBook
} from '../../modules/book';

import Question from '../content/reader/survey/question';
import { authUtil } from '../../utils';


class ExitSurvey extends Component {

  constructor(props) {
    super(props);

    this.state = {
      reason: '',
      feedback: '',
      exitReasonId: undefined
    };
  }

  componentDidMount() {
    this.getBook();
  }

  componentWillUnmount() {
    this.props.resetCurrentBook();
  }

  getBook = async () => {
    const idToken = await authUtil.getFreshIdToken();
    const { location } = this.props;
    const parsedQueryString = queryString.parse(location.search);
    const bookId = parsedQueryString.book;
    if (idToken) {
      this.props.fetchCurrentBookAsReader(idToken, bookId);
      this.fetchExitReason(idToken, bookId);
    }
  }

  submitExitReason = (idToken, bookId, answers, callback) => {
    const method = answers.exitReasonId ? 'PUT' : 'POST'; // PUT if this exists already, otherwise POST
    let url = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/exit-reasons`;
    if (answers.exitReasonId) {
      url += `/${answers.exitReasonId}`; // add the id to put it to, as well
    }
    fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      },
      body: JSON.stringify(answers)
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to submit exit survey');
        }
        return res.json();
      })
      .then(() => {
        const self = this;
        this.setState({
          exitReasonSubmitted: true
        }, () => {
          setTimeout(() => {
            self.props.changePage('/readinglist');
          }, 4000);
        });
      }).catch((err) => {
        console.error(err);
      });
  };

  fetchExitReason = (idToken, bookId) => {
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/exit-reasons/user-entry`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to submit exit survey');
        }
        return res.json();
      })
      .then((exitReason) => {
        this.setState({
          reason: exitReason.reason,
          feedback: exitReason.feedback,
          exitReasonId: exitReason._id
        });
      }).catch((err) => {
        console.error(err);
      });
  };

  handleWhyExitAnswer = (newAnswer) => {
    this.setState({
      reason: newAnswer
    });
  }

  handleExitFeedbackAnswer = (newAnswer) => {
    this.setState({
      feedback: newAnswer
    });
  }

  handleExitReason = async () => {
    const idToken = await authUtil.getFreshIdToken();
    const { location } = this.props;
    const parsedQueryString = queryString.parse(location.search);
    const bookId = parsedQueryString.book;
    const { reason, feedback, exitReasonId } = this.state;
    if (idToken) {
      this.submitExitReason(idToken, bookId, {
        reason,
        feedback,
        exitReasonId
      });
    }
  }

  render() {
    const {
      t, currentBook, currentFontSize, currentColorMode
    } = this.props;
    const { exitReasonSubmitted, reason, feedback } = this.state;
    const questions = {
      whyExitQuestion: {
        question: t('WhyExitQuestion'),
        kind: 'MULTIPLE_CHOICE',
        options: {
          options: [
            {
              key: 'no_time',
              value: t('NotEnoughTime')
            },
            {
              key: 'lost_interest',
              value: t('LostInterest')
            },
            {
              key: 'other',
              value: t('Other')
            }
          ]
        }
      },
      exitFeedbackQuestion: {
        question: t('ExitFeedbackQuestion'),
        kind: 'LONG_TEXT'
      }
    };
    if (exitReasonSubmitted) {
      return (
        <Dimmer active page>
          <Message
            info
            content={t('ThankYouForYourFeebdack')}
          />
        </Dimmer>
      );
    }
    if (currentBook) {
      if (currentBook.err) {
        return (
          <Message error>
            {currentBook.err}
          </Message>
        );
      }
      return (
        <div>
          <Image
            src={currentBook.cover.highRes.url}
            fluid
            style={{
              objectFit: 'cover', maxHeight: 250, marginTop: -40, filter: 'blur(10px)'
            }}
          />
          <Container className={`br-text font-size-${currentFontSize}`}>
            <Header
              size='large'
              content={`${currentBook.title}`}
              subheader={`${t('by')} ${currentBook.authorName}`}
              className='br-text'
            />
            <Question
              title={t('WhyExitQuestion')}
              style={{ marginBottom: '3em' }}
              onAnswerChanged={this.handleWhyExitAnswer}
              fetchedAnswers
              question={questions.whyExitQuestion}
              answer={{ answer: reason }}
            />
            <Question
              title={t('ExitFeedbackQuestion')}
              style={{ marginTop: '0em', marginBottom: '2em' }}
              onAnswerChanged={this.handleExitFeedbackAnswer}
              placeholder={t('ExitFeedbackPlaceholder')}
              fetchedAnswers
              question={questions.exitFeedbackQuestion}
              answer={{ answer: feedback }}
            />
            <Button
              floated='right'
              inverted={currentColorMode === 'dark'}
              onClick={this.handleExitReason}
            >
              {t('Submit')}
            </Button>
          </Container>
        </div>
      );
    }
    return (
      <Dimmer active>
        <Loader>{t('Loading')}</Loader>
      </Dimmer>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  currentBook: state.book.currentBook
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchCurrentBookAsReader,
  resetCurrentBook,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ExitSurvey)));
