import React, { Component } from 'react';
import { toast } from 'react-toastify';
import {
  Button, Form, Modal
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { uxAnalyticsUtil, productCapUtil } from '../../../utils';
import { MediaQuery } from '../../../constants/size';

const ButtonWrapper = styled.div`
  ${MediaQuery.tablet} {
    float: right;
  }
  display: flex;
  flex-direction: column;
`;

export class InviteAccountMemberModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleEmailChange = (event, data) => {
    this.setState({
      email: data.value
    });
  }

  sendInvite = () => {
    const { idToken, userProfile, t } = this.props;
    const { email } = this.state;
    try {
      this.setState({
        isSendingInvite: true
      });
      fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/accounts/${userProfile.account._id}/invitations`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'br-token': idToken
          },
          body: JSON.stringify({
            email
          })
        })
        .then(res => res.json())
        .then((body) => {
          if (body.err) {
            toast.error(t(body.msg));
          } else {
            toast.info(t('InvitationSent'));
          }
        })
        .catch(() => {
          toast.error(t('SomethingWentWrong'));
        })
        .finally(() => {
          this.setState({
            email: '',
            isSendingInvite: false
          })
        });
    } catch (e) {
      toast.error(t('SomethingWentWrong'));
    }
  };

  closeModal = () => this.setState({ open: false });

  openModal = () => this.setState({ open: true });

  render() {
    const { t, userProfile } = this.props;
    const { email, isSendingInvite, open } = this.state;
    const canInviteAccountMembers = productCapUtil.canInviteAccountMembers({ userProfile });
    return (
      <React.Fragment>
        <ButtonWrapper>
          <Button
            icon='add user'
            content={t('InviteUser')}
            disabled={!canInviteAccountMembers}
            onClick={this.openModal}
          />
          {
            !canInviteAccountMembers && (
              <span style={{ fontWeight: 'light', fontSize: '0.8em', fontStyle: 'italic' }}>
                {t('UpgradeToUnlock')}
              </span>
            )
          }
        </ButtonWrapper>
        <Modal
          open={open}
          onClose={this.closeModal}
          onOpen={() => {
            uxAnalyticsUtil.trackModalView('invite-account-member');
          }}
          closeIcon
        >
          <Modal.Header>{t('InviteUser')}</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <Form>
                <Form.Input
                  fluid
                  placeholder={t('Email')}
                  value={email}
                  onChange={this.handleEmailChange}
                  action={(
                    <Button
                      content={t('Send')}
                      icon='send'
                      loading={isSendingInvite}
                      onClick={this.sendInvite}
                    />
                  )}
                />
                <Form.Field>
                  {t('InvitedMembersMsg')}
                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </React.Fragment>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile
});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteAccountMemberModal)));
