import React from 'react';

import {
  Form, Divider, Icon, Grid
} from 'semantic-ui-react';
// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// i18n
import { withTranslation } from 'react-i18next';
import MultipleChoiceOptions from './multipleChoiceOptions';
import LinearScaleOptions from './linearScaleOptions';
import CheckboxOptions from './checkboxOptions';
import InputWithSave from '../../../../components/inputs/InputWithSave';
import useAutoSaveContext from '../../../../hooks/useAutoSaveContext';

const questionTypeOptions = [
  {
    key: 'LONG_TEXT',
    value: 'LONG_TEXT',
    text: 'Text'
  },
  {
    key: 'MULTIPLE_CHOICE',
    value: 'MULTIPLE_CHOICE',
    text: 'Multiple choice'
  },
  {
    key: 'CHECKBOX',
    value: 'CHECKBOX',
    text: 'Checkbox'
  },
  {
    key: 'LINEAR_SCALE',
    value: 'LINEAR_SCALE',
    text: 'Linear scale'
  }
];

export const Question = ({
  index,
  question,

  deleteQuestion,
  updateQuestion,
  t,
}) => {
  const {
    save
  } = useAutoSaveContext();
  const kind = question.kind || 'LONG_TEXT';
  const handleKindChange = (event, data) => {
    const update = {
      kind: data.value,
      options: undefined,
      index
    };
    updateQuestion(update);
  }
  const handleQuestionChange = (event, data) => {
    updateQuestion({
      question: data.value,
      index: index
    });
  }
  const handleDescriptionChange = (event, data) => {
    updateQuestion({
      description: data.value,
      index: index
    });
  }
  const handleOptionsChanged = (newOptions) => {
    updateQuestion({
      options: newOptions,
      index: index
    });
  }
  const handleDelete = () => {
    deleteQuestion();
  }

  return (
    <Form className='question-box' style={{ border: 'solid 0.5px #cccccc', borderRadius: '3px', padding: '1em', marginBottom: '1em' }}>
      <Form.Group widths='equal' unstackable>
        <Form.Input
          control={InputWithSave}
          onSave={save}
          className='text-line'
          transparent
          size='big'
          value={question.question}
          placeholder={t('Question')}
          onChange={handleQuestionChange}
        />
        <Form.Dropdown
          width={3}
          inline
          button
          compact
          style={{ whiteSpace: 'pre', float: 'right' }}
          options={questionTypeOptions}
          value={question.kind}
          onChange={handleKindChange}
        />
      </Form.Group>
      <Form.Input
        control={InputWithSave}
        onSave={save}
        className='text-line'
        transparent
        size='small'
        value={question.description}
        placeholder={t('Description')}
        onChange={handleDescriptionChange}
      />
      {
        kind === 'LONG_TEXT'
        && (
          <Form.Input
            className='answer'
            transparent
            disabled
            size='small'
            defaultValue={t('Answer')}
          />
        )
      }
      {
        kind === 'MULTIPLE_CHOICE'
        && (
          <MultipleChoiceOptions
            onOptionsChanged={handleOptionsChanged}
            options={question.options || {}}
            questionId={question._id || index}
          />
        )
      }
      {
        kind === 'LINEAR_SCALE'
        && (
          <LinearScaleOptions
            onOptionsChanged={handleOptionsChanged}
            options={question.options || {}}
            questionId={question._id || index}
          />
        )
      }
      {
        kind === 'CHECKBOX'
        && (
          <CheckboxOptions
            onOptionsChanged={handleOptionsChanged}
            options={question.options || {}}
            questionId={question._id || index}
          />
        )
      }
      <Divider clearing hidden />
      <Grid columns='equal'>
        <Grid.Row textAlign='right'>
          <Grid.Column floated='right'>
            <div style={{ cursor: 'pointer' }} onClick={handleDelete}>
              {'Remove '}
              <Icon name='trash alternate outline' floated='right' />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  currentBook: state.book.currentBook
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(Question));
