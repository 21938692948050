import React, { Component } from 'react';
import {
  Modal, Image, Button
} from 'semantic-ui-react';
import imageUtil from '../../utils/imageUtil';

class ImageSlideShow extends Component {
  constructor(props) {
    super(props);
    const { images, startIndex } = props;
    this.state = {
      images: images || [],
      currentIndex: startIndex || 0
    };
  }

  componentDidMount() {
    const { images, startIndex } = this.props;
    this.setState({
      images,
      currentIndex: startIndex || 0
    });
  }

  prevImage = () => {
    this.setState((prevState) => {
      const nextIndex = (prevState.currentIndex - 1) % prevState.images.length;
      return ({
        currentIndex: nextIndex < 0 ? prevState.images.length - 1 : nextIndex
      });
    });
  }

  nextImage = () => {
    this.setState(prevState => ({
      currentIndex: (prevState.currentIndex + 1) % prevState.images.length
    }));
  }

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  onKeyDown = (event) => {
    if (event.keyCode === 37) {
      this.prevImage();
    }
    if (event.keyCode === 39) {
      this.nextImage();
    }
  }

  render() {
    const { images, currentIndex } = this.state;
    const currentImage = images[currentIndex];
    if (!currentImage) {
      return null;
    }
    return (
      <Modal
        open={!!currentImage}
        basic
        onClose={this.onClose}
        closeIcon
        onKeyDown={this.onKeyDown}
      >
        <Modal.Content>
          <Button
            onClick={this.prevImage}
            size='huge'
            icon='chevron left'
            style={{
              position: 'absolute', top: '50%', left: 0, background: 'transparent', color: 'white'
            }}
          />
        </Modal.Content>
        <Modal.Content>
          <Image
            centered
            src={
              imageUtil.getImageUrl({
                operation: 'width',
                size: 600,
                originalImageUrl: currentImage.url
              })
            }
            style={{ maxHeight: window.innerHeight * 0.75 }}
          />
        </Modal.Content>
        <Modal.Content>
          <Button
            onClick={this.nextImage}
            size='massive'
            icon='chevron right'
            style={{
              position: 'absolute', top: '50%', right: 0, background: 'transparent', color: 'white'
            }}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

export default ImageSlideShow;
