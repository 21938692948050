import React from 'react';

import {Link} from 'react-router-dom';
import {
  Segment,
  Header,
  Divider,
  Grid,
  Button,
  Icon,
  Step
} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';

import {Media} from 'src/utils/Media';

import ChapterFileUpload from '../content/chapter/upload/chapterFileUpload';

// stateless component
type Props = {
  onCreateManuscript(): void;
};
const CreateFirstBookSegment: React.FC<Props> = ({onCreateManuscript}) => {
  const {t} = useTranslation();
  return (
    <>
      <Header key='header' content={t('YouDontHaveAnyManuscripts')} />,
      <Segment key='upload-or-create' placeholder>
        <Media
          as={Grid}
          greaterThan='tablet'
          columns={2}
          stackable
          textAlign='center'>
          <Divider vertical>{t('Or')}</Divider>
          <Grid.Row verticalAlign='middle'>
            <Grid.Column>
              <ChapterFileUpload
                createBook
                uploadHeader={t('ImportManuscript')}
                uploadMessage={t('ClickToUploadDoc')}
              />
            </Grid.Column>
            <Grid.Column>
              <Header icon>
                <Icon name='add' />
                {t('StartWriting')}
              </Header>
              <Button primary onClick={onCreateManuscript}>
                {t('CreateManuscript')}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Media>
        <Media at='tablet'>
          {(className, renderChildren) =>
            renderChildren && (
              <div>
                <Segment.Inline>
                  <ChapterFileUpload
                    createBook
                    uploadHeader={t('ImportManuscript')}
                    uploadMessage={t('ClickToUploadDoc')}
                  />
                  <Divider horizontal>{t('Or')}</Divider>
                  <Header textAlign='center' icon>
                    <Icon name='add' />
                    {t('StartWriting')}
                  </Header>
                  <Button as={Link} primary to='/books/new'>
                    {t('CreateManuscript')}
                  </Button>
                </Segment.Inline>
              </div>
            )
          }
        </Media>
      </Segment>
      <Media key='gt-tablet' greaterThan='tablet'>
        {(className, renderChildren) =>
          renderChildren && (
            <Step.Group key='steps' size='tiny' fluid>
              <Step>
                <Icon name='upload' />
                <Step.Content>
                  <Step.Title>{t('ImportManuscript')}</Step.Title>
                  <Step.Description>
                    {t('ImportManuscriptStepDescription')}
                  </Step.Description>
                </Step.Content>
              </Step>
              <Step>
                <Icon name='users' />
                <Step.Content>
                  <Step.Title>{t('InviteReaders')}</Step.Title>
                  <Step.Description>
                    {t('InviteReadersStepDescription')}
                  </Step.Description>
                </Step.Content>
              </Step>
              <Step>
                <Icon name='comments' />
                <Step.Content>
                  <Step.Title>{t('CollectFeedback')}</Step.Title>
                  <Step.Description>
                    {t('CollectFeedbackStepDescription')}
                  </Step.Description>
                </Step.Content>
              </Step>
            </Step.Group>
          )
        }
      </Media>
    </>
  );
};

export default CreateFirstBookSegment;
