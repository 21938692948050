export const DeviceMinWidths = {
  tablet: 768,
  desktop: 1200,
};

export const MaxTextWidth = 768;

export const MediaQuery = {
  /** OBS: make phone styling the default and override it with tablet or desktop media queries */
  phone: `@media only screen and (max-width: ${DeviceMinWidths.tablet}px)`,
  tablet: `@media only screen and (min-width: ${DeviceMinWidths.tablet}px)`,
  desktop: `@media only screen and (min-width: ${DeviceMinWidths.desktop}px)`,
};