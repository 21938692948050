import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Icon, Button, Popup, Label
} from 'semantic-ui-react';
// import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import {
  accessUtil, appUtil, authUtil, bookStatusUtil
} from '../../utils';
import {
  toggleFollowStatus
} from '../../modules/book';
import PropTypes from 'prop-types';

import { CenteredContent } from '../../style';

import EditBookDetails from './detail/editBookDetails';
import ViewBookDetails from './detail/viewBookDetails';
import BookFollowButton from './followers/bookFollowButton';
import Cover, { getCoverImage } from './bookCover/coverWrapper';

const MetaData = styled((({ smallScreen, ...rest }) => <div {...rest} />))`
  background-color: white;
  padding: 3px;
  display: flex!important;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1em!important;
  border-radius: 5px!important;
  color: grey;
`;

MetaData.propTypes = {
  smallScreen: PropTypes.bool
};

const BookOverview = ({}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const currentBook = useSelector(state => state.book.currentBook);
  const followStatus = useSelector(state => state.book.followStatus);


  const canEdit = useCallback(() => {
    return accessUtil.isAllowed(currentBook.access, ['edit']);
  }, [currentBook?.access]);

  const isAuthorOrCollaborator = useCallback(() => ['author', 'collaborator'].includes(currentBook.role), [currentBook?.role]);

  const goToSignIn = () => {
    history.push(`/signin?redirect=${encodeURIComponent(location.pathname + location.search)}`);
  }

  const toggleFollowBook = async (action) => {
    const idToken = await authUtil.getFreshIdToken();
    if (!idToken) {
      // get the user signed in
      goToSignIn();
      return;
    }
    if (isAuthorOrCollaborator()) {
      return; // authors can't follow their own books
    }
    if (action) {
      dispatch(toggleFollowStatus({ idToken, bookId: currentBook._id, action }))
      return;
    }
    // no action provided, just toggle status
    if (followStatus[currentBook._id] && followStatus[currentBook._id].following) {
      dispatch(toggleFollowStatus({ idToken, bookId: currentBook._id, action: 'unfollow' }));
    } else {
      dispatch(toggleFollowStatus({ idToken, bookId: currentBook._id, action: 'follow' }));
    }
  }

  // rendering
  if (!currentBook) {
    return null;
  }
  const cover = getCoverImage(currentBook);

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{currentBook.title}</title>
        <meta property='og:title' content={`${t('Read')} ${currentBook.title} ${t('by')} ${currentBook.authorName} ${t('onBetaReader')}`} />
        <meta property='og:site_name' content='BetaReader.io' />
        <meta property='og:description' content={currentBook.description} />
        <meta property='og:image' content={cover} />
      </Helmet>
      <div>
        <Cover book={currentBook} editable={canEdit()} />
        <CenteredContent>
          <MetaData className='metadata-bar' smallScreen={appUtil.isSmallScreen()}>
            <div>
              <Popup
                content={t('WordCountString', { count: currentBook.wordCount || 0 })}
                trigger={(
                  <span style={{ fontSize: '1em', color: 'grey', marginRight: '15px' }}>
                    <Icon name='font' color='grey' />
                    {
                      currentBook && (
                        <span style={{ fontSize: '1em' }}>
                          {(currentBook.wordCount || 0).toLocaleString()}
                        </span>
                      )
                    }
                  </span>
                )}
              />
              <Popup
                content={t('ChapterCount', { count: currentBook.chapterCount || 0 })}
                trigger={(
                  <span style={{ fontSize: '1em', color: 'grey', marginRight: '15px' }}>
                    <Icon name='list ul' color='grey' />
                    <span style={{ fontSize: '1em' }}>
                      {(currentBook.chapterCount || 0).toLocaleString()}
                    </span>
                  </span>
                )}
              />
              <span style={{ fontSize: '1em', color: 'grey' }}>
                <Icon name='globe' color='grey' />
                <span style={{ fontSize: '1em' }}>
                  {currentBook.language && currentBook.language.charAt(0).toUpperCase() + currentBook.language.slice(1)}
                </span>
              </span>
              {
                currentBook.status && (
                  <Popup
                    content={t(`MSStatusDescription_${currentBook.status}`)}
                    trigger={(
                      <Label size='tiny' color={bookStatusUtil.getStatusColor(currentBook.status)} style={{ marginLeft: 5 }}>
                        {currentBook.status}
                      </Label>
                    )}
                  />
                )
              }
              {
                currentBook.isMature && (
                  <Label size='tiny' color='red' style={{ marginLeft: 5 }}>
                    {t('MatureContent')}
                  </Label>
                )
              }
            </div>
            {canEdit() ? (
              <Button
                size='tiny'
                color='orange'
                compact
                onClick={() => history.push(`/books/${currentBook._id}/content`)}
                content={t('EditContent')}
              />
            ) : (
              <BookFollowButton />
            )}
          </MetaData>
        </CenteredContent>
        <CenteredContent>
          {
            isAuthorOrCollaborator() && (
              <EditBookDetails />
            )
          }
          {
            !isAuthorOrCollaborator() && (
              <ViewBookDetails toggleFollowBook={toggleFollowBook} />
            )
          }
        </CenteredContent>
      </div>
    </>
  );
}

export default BookOverview;
