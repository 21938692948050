import React, {useState, useRef, useEffect} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {
  Container,
  Form,
  Button,
  Label,
  Loader,
  Visibility,
  Popup
} from 'semantic-ui-react';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';
import queryString from 'query-string';
import {useDispatch, useSelector} from 'react-redux';

import {
  setActiveInlineComment,
  fetchComments,
  clearCommentList,
  updateInlineCommentPosition,
  removeComment
} from '../../../modules/comment';

import {
  fetchCurrentChapter,
  showCurrentPartTitle,
  replacePart,
  fetchCurrentBook,
  setCurrentChapter
} from '../../../modules/book';
import TextEditor from '../../../components/textEditor/textEditor';
import ContentUpdateConfirmationModal from '../contentUpdateConfirmationModal';
import {accessUtil, authUtil, uxAnalyticsUtil} from '../../../utils';
import useAutoSaveContext from '../../../hooks/useAutoSaveContext';
import InputWithSave from '../../../components/inputs/InputWithSave';
import styled from 'styled-components';

const UpdateReadersButtonWrapper = styled.div`
  position: fixed;
  right: 10px;
`;

const UpdateReadersButton = styled(Button)`
  box-shadow: 2px 3px 15px -3px #000000 !important;
`;

const PostUpdateWrapper = styled.div`
  font-size: 0.8em;
`;

const EditChapter = ({editTitle}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const currentBook = useSelector(state => state.book.currentBook);
  const currentContentVersion = useSelector(
    state => state.book.currentContentVersion
  );
  const currentChapter = useSelector(state => state.book.currentChapter);
  const comments = useSelector(state => state.comment.comments);
  const activeInlineComment = useSelector(
    state => state.comment.activeInlineComment
  );

  const [readyToEdit, setReadyToEdit] = useState(false);
  const [chapter, setChapter] = useState();
  const [titleChanged, setTitleChanged] = useState(false);
  const [documentData, setDocumentData] = useState(undefined);
  const [fetchingChapter, setFetchingChapter] = useState(false);
  const [wordCount, setWordCount] = useState();
  const [paragraphs, setParagraphs] = useState();
  const [paragraphCount, setParagraphCount] = useState();
  const [charCount, setCharCount] = useState();
  const [title, setTitle] = useState(currentChapter?.title);
  const [inlineComments, setInlineComments] = useState();
  const [changedInlineComments, setChangedInlineComments] = useState([]);
  const [removedInlineComments, setRemovedInlineComments] = useState([]);
  const [showBookPostModal, setShowBookPostModal] = useState();
  const [affectedReaders, setAffectedReaders] = useState([]);
  const [lastCheckedAffectedReaders, setLastCheckedAffectedReaders] =
    useState(undefined);
  const [isReadyToSave, setIsReadyToSave] = useState(false);

  const textEditor = useRef();

  const {bookId, partId, versionNumber} = useParams();

  const {setSaveFn, save, resetAutoSave} = useAutoSaveContext();

  // once when mounted
  useEffect(() => {
    resetAutoSave();
    return () => {
      // when component unmounts
      dispatch(setCurrentChapter(undefined));
      dispatch(showCurrentPartTitle(false));
      setSaveFn(undefined);
    };
  }, []);

  useEffect(() => {
    if (documentData?.differentFromOriginal && isReadyToSave) {
      // when documentData has changed, pass save fn to autosave and update the content binder
      setSaveFn(() => saveChapter);
    }
  }, [documentData]);

  useEffect(() => {
    // when title has changed, pass save fn to autosave and update the content binder
    if (titleChanged && isReadyToSave) {
      setSaveFn(() => saveChapter);
    }
  }, [titleChanged]);

  // fetch chapter when the partId changes
  useEffect(() => {
    if (!fetchingChapter && currentChapter?._id !== partId) {
      setReadyToEdit(false);
      fetchChapter();
    }
  }, [fetchingChapter, currentChapter?._id, partId]);

  // when comments have changed
  useEffect(() => {
    // if (comments) {
    // if we receive a new list of comments
    updateInlineComments();
    // }
  }, [comments]);

  useEffect(() => {
    if (inlineComments) {
      // inline comments have been fetched and prepped, we are now ready to edit the chapter
      setReadyToEdit(true);
    }
  }, [inlineComments]);

  // when the text editor has loaded
  useEffect(() => {
    // handle auto scrolling to comment
    const commentId = queryString.parse(location?.search).cid ?? undefined;
    const commentKind = queryString.parse(location?.search).kind ?? undefined;
    if (commentKind === 'InlineComment') {
      console.log('Activating inline comment from querystring');
      dispatch(setActiveInlineComment({commentId, show: 'sidebar'}));
    }
  }, [textEditor.current]);

  // whenever we get new chapter data
  useEffect(() => {
    setChapter(currentChapter);
    setTitle(currentChapter?.title ?? '');
    setTitleChanged(false);
    setCharCount(currentChapter?.charCount ?? 0);
    setWordCount(currentChapter?.wordCount ?? 0);
    setParagraphCount(currentChapter?.paragraphCount ?? 0);
  }, [currentChapter?._id]);

  const updateInlineComments = () => {
    const inlineComments = comments?.filter(
      comment =>
        comment.kind === 'InlineComment' &&
        !['IGNORE', 'DONE'].includes(comment.status)
    );
    setInlineComments(inlineComments);
  };

  const fetchChapter = async () => {
    setFetchingChapter(true);
    dispatch(clearCommentList());
    const idToken = await authUtil.getFreshIdToken();
    if (idToken) {
      dispatch(
        fetchCurrentChapter(idToken, bookId, partId, status => {
          setFetchingChapter(false);
        })
      );
    }
  };

  const handleTitleChange = (event, data) => {
    setTitle(data.value);
    setTitleChanged(true);
  };

  const saveChapter = async updateText => {
    const idToken = await authUtil.getFreshIdToken();
    let updateData = {
      title
    };
    if (documentData) {
      updateData = {
        ...updateData,
        content: JSON.stringify(documentData.rawContentWithoutComments),
        wordCount: documentData.wordCount,
        paragraphCount: documentData.paragraphCount,
        charCount: documentData.charCount,
        beginning: documentData.content.getPlainText().substring(0, 50),
        paragraphs: documentData.paragraphs,
        changedInlineComments: documentData.changedInlineComments,
        removedInlineComments: documentData.removedInlineComments
      };
    }

    // check if we have any affected readers if we don't know and we checked more than 30s ago
    if (
      affectedReaders.length === 0 &&
      (!lastCheckedAffectedReaders ||
        lastCheckedAffectedReaders < Date.now() - 30000)
    ) {
      const affectedReadersArray = await getAffectedReaders();
      setAffectedReaders(affectedReadersArray);
    }

    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/chapters/${partId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify(updateData)
      }
    )
      .then(res => {
        if (!res.ok) {
          throw new Error(res.error);
        }
        return res.json();
      })
      .then(updatedChapter => {
        uxAnalyticsUtil.trackEvent({
          category: 'Book Management',
          action: 'Edited Chapter'
        });
        dispatch(replacePart(updatedChapter, versionNumber));
        if (
          changedInlineComments.length > 0 ||
          removedInlineComments.length > 0
        ) {
          // if inline comments have changed, fetch the updated ones from the backend again.
          changedInlineComments.forEach(comment => {
            dispatch(updateInlineCommentPosition(comment));
          });
          removedInlineComments.forEach(comment => {
            dispatch(removeComment(comment._id));
          });
        }
      })
      .catch(err => {
        toast.error(t('FailedToSave'));
        console.log('failed', err);
      });
  };

  const sendBookPost = async text => {
    const idToken = await authUtil.getFreshIdToken();
    const postData = new FormData();
    postData.append('text', text);
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${currentBook._id}/posts`,
      {
        method: 'POST',
        headers: {
          'br-token': idToken
        },
        body: postData
      }
    )
      .then(res => {
        if (!res.ok) {
          throw new Error(res.error);
        }
        return res.json();
      })
      .catch(err => {
        console.error(err);
        toast.error(t('FailedToPostBookPost'));
      });
  };

  const onDocumentDataUpdated = newDocumentData => {
    // auto save
    setDocumentData(newDocumentData);
  };

  const getAffectedReaders = async () => {
    const idToken = await authUtil.getFreshIdToken();
    setLastCheckedAffectedReaders(Date.now());
    return fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${currentBook._id}/content/${currentContentVersion._id}/parts/${partId}/affected-readers`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    ).then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error('Failed to get affected readers');
    });
  };

  const openBookPostModal = async () => {
    setShowBookPostModal({
      affectedReaders,
      header: t('CreatePost'),
      sendUpdateOnConfirm: true,
      onConfirm: bookPost => {
        setShowBookPostModal(false);
        sendBookPost(bookPost);
      },
      onCancel: () => setShowBookPostModal(false)
    });
  };

  const prepareToSplitChapter = async ({
    originalChapterData,
    newChapterData
  }) => {
    try {
      // check for affected readers and await author confirmation
      const affectedReaders = await getAffectedReaders();
      if (!affectedReaders || affectedReaders.length === 0) {
        // no affected readers, go ahead with update immediately
        splitChapter({originalChapterData, newChapterData});
      } else {
        // have the user confirm the update before going ahead or aborting
        setShowBookPostModal({
          affectedReaders,
          onConfirm: () => {
            setShowBookPostModal(false);
            splitChapter({
              originalChapterData,
              newChapterData,
              affectedReaders
            });
          },
          onCancel: () => setShowBookPostModal(false)
        });
      }
    } catch (e) {
      toast.error('FailedToUpdatePart', e);
    }
  };

  const splitChapter = async ({
    originalChapterData,
    newChapterData,
    affectedReaders = []
  }) => {
    const idToken = await authUtil.getFreshIdToken();
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${currentBook._id}/chapters/${partId}?action=split`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify({
          originalChapterData: {
            charCount: originalChapterData.charCount,
            content: JSON.stringify(
              originalChapterData.rawContentWithoutComments
            ),
            paragraphCount: originalChapterData.paragraphCount,
            paragraphs: originalChapterData.paragraphs,
            wordCount: originalChapterData.wordCount,
            inlineComments: originalChapterData.remainingInlineComments
          },
          newChapterData: {
            title: `${currentChapter.title}_1`,
            charCount: newChapterData.charCount,
            content: JSON.stringify(newChapterData.rawContentWithoutComments),
            paragraphCount: newChapterData.paragraphCount,
            paragraphs: newChapterData.paragraphs,
            wordCount: newChapterData.wordCount,
            inlineComments: newChapterData.remainingInlineComments
          },
          contentVersionId: currentContentVersion._id,
          affectedUserIds: affectedReaders.map(
            reader => reader.user && reader.user._id
          )
        })
      }
    )
      .then(res => {
        if (!res.ok) {
          throw new Error(res.error);
        }
        return res.json();
      })
      .then(({newChapter}) => {
        uxAnalyticsUtil.trackEvent({
          category: 'Book Management',
          action: 'Split Chapter'
        });
        // reload book and go to the new chapter
        dispatch(
          fetchCurrentBook({idToken, bookId}, () => {
            const newPath = `/books/${currentBook._id}/content/v${currentContentVersion.versionNumber}/${newChapter._id}`;
            history.push(newPath);
          })
        );
      })
      .catch(err => {
        toast.error(t('FailedToSave'));
        console.log('failed', err);
      });
  };

  const onTextEditorInitialized = async () => {
    const idToken = await authUtil.getFreshIdToken();
    setIsReadyToSave(false);
    dispatch(fetchComments(idToken, bookId, partId));
  };

  const onCommentsIngested = () => {
    setIsReadyToSave(true);
  };

  const handleTitleVisibility = (e, {calculations}) => {
    if (!calculations.bottomVisible) {
      dispatch(showCurrentPartTitle(title));
    } else {
      dispatch(showCurrentPartTitle(false));
    }
  };

  if (fetchingChapter) {
    return (
      <Container text style={{height: '100%'}}>
        <Loader />
      </Container>
    );
  }
  return (
    <div
      dir={i18next.dir()}
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 768
      }}>
      {currentChapter !== undefined && (
        <Form style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
          {editTitle !== false && (
            <Form.Group
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}>
              <Visibility
                as={InputWithSave}
                style={{flex: 1}}
                disabled={!accessUtil.isAllowed(currentBook.access, ['edit'])}
                className='text-line'
                transparent
                size='massive'
                placeholder='Title'
                value={title ?? ''}
                onUpdate={handleTitleVisibility}
                onChange={handleTitleChange}
                onSave={save}
              />
              {showBookPostModal && (
                <ContentUpdateConfirmationModal
                  open
                  header={showBookPostModal.header}
                  sendUpdateOnConfirm={showBookPostModal.sendUpdateOnConfirm}
                  affectedReaders={showBookPostModal.affectedReaders}
                  onCancel={showBookPostModal.onCancel}
                  onConfirm={showBookPostModal.onConfirm}
                  cancelButton={t('No')}
                  confirmButton={t('Yes')}
                />
              )}
            </Form.Group>
          )}
          <Label.Group>
            <Label>
              {t('WordCountString', {count: documentData?.wordCount ?? 0})}
            </Label>
            <Label>
              {t('CharCountString', {count: documentData?.charCount ?? 0})}
            </Label>
          </Label.Group>
          {chapter !== undefined && (
            <div ref={textEditor} id={`text-editor-${partId}`}>
              <TextEditor
                manuscriptStyle={currentBook.manuscriptStyle}
                editorMode='editing'
                language={currentBook.language}
                inlineToolbarType='editor'
                content={chapter.content}
                onDocumentDataUpdated={onDocumentDataUpdated}
                onInitialized={onTextEditorInitialized}
                onCommentsIngested={onCommentsIngested}
                onSave={save}
                onSplitChapter={prepareToSplitChapter}
                inlineComments={inlineComments}
                activeInlineComment={activeInlineComment}
                clearActiveInlineComment={() =>
                  dispatch(setActiveInlineComment())
                }
                clearCommentList={() => dispatch(clearCommentList())}
                readyToEdit={readyToEdit}
                readOnly={!accessUtil.isAllowed(currentBook.access, ['edit'])}
              />
            </div>
          )}
          {affectedReaders.length > 0 && (
            <Popup
              content={
                <PostUpdateWrapper>
                  {t('AffectedReadersMessage', {
                    count: affectedReaders.length ?? 0
                  })}
                </PostUpdateWrapper>
              }
              basic
              trigger={
                <UpdateReadersButtonWrapper>
                  <UpdateReadersButton
                    circular
                    color='orange'
                    icon='comment'
                    onClick={openBookPostModal}
                  />
                </UpdateReadersButtonWrapper>
              }
            />
          )}
        </Form>
      )}
    </div>
  );
};

export default EditChapter;
