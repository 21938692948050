// fire.js or fire.ts
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const prodConfig = {
  apiKey: 'AIzaSyAoHHwwhc6NcNDzUnqr99XQDRT9OhHwUXc',
  authDomain: 'app.betareader.io',
  databaseURL: 'https://beta-reader-prod.firebaseio.com',
  projectId: 'beta-reader-prod',
  storageBucket: 'beta-reader-prod.appspot.com',
  messagingSenderId: '731030581916',
};

const devConfig = {
  apiKey: 'AIzaSyC-uMiCqVJT4pxbrtMSegXEyI-BRn5fff8',
  authDomain: 'betareader-dev.firebaseapp.com',
  databaseURL: 'https://betareader-dev.firebaseio.com',
  projectId: 'betareader-dev',
  storageBucket: '',
  messagingSenderId: '696645853516',
  appId: '1:696645853516:web:be7ad1b31c68d674',
};

const config = process.env.NODE_ENV !== 'production' ? devConfig : prodConfig;

if (!firebase.apps.length) {
  console.log('Initializing Firebase');

  firebase.initializeApp(config);
}

export default firebase;
