import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import {
  Table, Loader
} from 'semantic-ui-react';
import moment from 'moment';

// i18n
import { withTranslation } from 'react-i18next';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { authUtil } from '../../../utils';

import Avatar from '../../../components/avatar/avatar';

export class BroadcastMessageList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchReportData();
  }

  fetchReportData = async () => {
    const { t, filter, messageId } = this.props;
    if (filter) {
      this.setState({ loading: true });
      const idToken = await authUtil.getFreshIdToken();
      fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/broadcasts/${messageId}/data?filter=${filter}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'br-token': idToken
          }
        })
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.error);
          } else {
            return res.json();
          }
        })
        .then((reportData) => {
          if (reportData !== undefined) {
            this.setState({
              reportData
            });
          }
        })
        .catch(() => {
          toast.error(t('SomethingWentWrong'));
        })
        .finally(() => {
          this.setState({
            loading: false
          });
        });
    }
  }

  render() {
    const { t } = this.props;
    const { reportData = [], loading } = this.state;

    return (
      <Table basic='very' celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('User')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Sent')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Seen')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            reportData.length === 0 && (
              <Table.Row>
                <Table.Cell>
                  {
                    loading && (<Loader active>{t('Loading')}</Loader>)
                  }
                  {
                    !loading && <span>{t('NothingHere')}</span>
                  }
                </Table.Cell>
              </Table.Row>
            )
          }
          {
            reportData.map(data => (
              <Table.Row key={data._id}>
                <Table.Cell style={{ display: 'flex', flexDirection: 'row' }}>
                  <Avatar userId={data.user._id} name={data.user.displayName} diameter={20} />
                  <Avatar
                    userId={data.user._id}
                    name={data.user.displayName}
                    nameOnly
                    style={{ marginLeft: 5 }}
                  />
                </Table.Cell>
                <Table.Cell>
                  {moment(data.createdAt).format('lll')}
                </Table.Cell>
                <Table.Cell>
                  {moment(data.seenAt).format('lll')}
                </Table.Cell>
              </Table.Row>
            ))
          }
        </Table.Body>
      </Table>
    );
  }
}


// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  userProfile: state.user.userProfile
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(BroadcastMessageList)));
