import React, { Component } from 'react';
import {
  withRouter, Switch, Route
} from 'react-router-dom';

import { withTranslation } from 'react-i18next';

// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Profile from './profile';
import PublicUserProfile from './publicUserProfile';
import Subscription from './subscription';
import ReaderBonus from './readerBonus';
import AccountMembers from './accountMembers';
import AcceptAccountInvitation from './accountMembers/acceptAccountInvitation';

export class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      userProfile, user
    } = this.props;
    if (user !== undefined && userProfile !== undefined) {
      return (
        <div style={{ padding: '1em' }}>
          <Switch>
            <Route
              path='/account/profile'
              render={props => <PublicUserProfile {...props} userProfile={userProfile} />}
            />
            <Route path='/account/settings' component={Profile} />
            <Route path='/account/subscription' component={Subscription} />
            <Route path='/account/reader-bonus' component={ReaderBonus} />
            <Route path='/account/members' component={AccountMembers} />
            <Route path='/account/accept-invitation' component={AcceptAccountInvitation} />
          </Switch>
        </div>
      );
    }
    return null;
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  socialProfiles: state.user.socialProfiles
});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Account)));
