import React, { Component } from 'react';

// Routes
import { withRouter } from 'react-router-dom';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import {
  Menu, Label, Icon, Dropdown
} from 'semantic-ui-react';

import {
  fetchNotifications
} from '../../modules/notification';

import { listInvitations } from '../../modules/reader';

import { authUtil } from '../../utils';

import NotificationsList from './notificationsList';
import InvitationsSubMenuItem from './invitationsSubMenuItem';
import InvitationsList from './invitationsList';

export class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { notifications } = this.props;
    if (!notifications || notifications.length === 0) {
      this.fetchNotifications();
    }
    this.fetchInvitations();
  }

  fetchNotifications = async () => {
    const { notifications } = this.props;
    let after;
    if (notifications && notifications.length > 0) {
      // get the timestamp of the freshest notification
      after = new Date(notifications[0].createdAt).getTime();
    }
    const idToken = await authUtil.getFreshIdToken();
    this.props.fetchNotifications(idToken, { after });
  }

  fetchInvitations = async () => {
    const idToken = await authUtil.getFreshIdToken();
    this.props.listInvitations(idToken);
  }

  onClick = async () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.fetchNotifications();
  }

  showInvitations = () => {
    this.setState({ showInvitations: true });
  }

  onDropDownOpen = (event) => {
    if (event) event.stopPropagation();
    this.fetchNotifications();
    this.hideInvitations();
    this.fetchInvitations();
  }

  hideInvitations = (event) => {
    if (event) event.stopPropagation();
    this.setState({ showInvitations: false });
  }

  render() {
    const {
      unreadCount, fitted, loadingNotifications, invitations
    } = this.props;
    const { showInvitations } = this.state;
    const totalUnreadCount = unreadCount + (Array.isArray(invitations) && invitations.length);
    const dropDownTrigger = (
      !loadingNotifications && (
        <React.Fragment>
          <Icon name='bell' />
          {
            totalUnreadCount > 0 && (
              <Label
                color='red'
                circular
                style={{
                  fontSize: 7, position: 'absolute', top: '-30%'
                }}
              >
                {totalUnreadCount}
              </Label>
            )
          }
        </React.Fragment>
      )
    );

    return (
      <Menu.Item fitted={fitted} key='dropdown-notifications'>

        {
          loadingNotifications && (
            <Icon name='spinner' loading />
          )
        }
        <Dropdown
          onOpen={this.onDropDownOpen}
          fluid
          trigger={<Menu.Item key='browse' fitted>{dropDownTrigger}</Menu.Item>}
          icon={null}
        >
          <Dropdown.Menu
            style={{
              minWidth: '50vw', maxWidth: '70vw', maxHeight: '400px', overflowY: 'scroll', top: 32
            }}
          >
            {
              !showInvitations && invitations && invitations.length > 0 && (
                <InvitationsSubMenuItem invitations={invitations} onSelect={this.showInvitations} />
              )
            }
            {
              showInvitations && invitations && invitations.length > 0
                ? <InvitationsList />
                : <NotificationsList />
            }
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  unreadCount: state.notification.unreadCount,
  notifications: state.notification.notifications,
  invitations: state.reader.invitations,
  loadingInvitations: state.reader.loadingInvitations,
  loadingNotifications: state.notification.loadingNotifications
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchNotifications,
  listInvitations,
  changePage: newPage => push(newPage)
}, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications));
