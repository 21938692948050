import React, { Component } from 'react';
import {
  Grid, Container
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';

// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ux from '../../../utils/uxAnalyticsUtil';

import AccountMembersTable from './accountMembersTable';

export class AccountMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { history } = this.props;
    ux.trackPageView(history.location.pathname);
  }

  render() {
    const { userProfile, user } = this.props;
    if (user !== undefined && userProfile !== undefined) {
      return (
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <AccountMembersTable />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }
    return null;
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile
});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountMembers)));
