import React, {useRef, useState, useEffect, useCallback} from 'react';
import {Button, Icon, StepContent} from 'semantic-ui-react';
import Immutable from 'immutable';

import {
  convertToRaw,
  convertFromRaw,
  EditorState,
  RichUtils,
  Modifier,
  CompositeDecorator,
  SelectionState,
  getDefaultKeyBinding,
  KeyBindingUtil,
  getVisibleSelectionRect
} from 'draft-js';
import DraftOffsetKey from 'draft-js/lib/DraftOffsetKey';
// i18n
import {useTranslation, withTranslation} from 'react-i18next';
// import library for getting device info
// Import plugin editor
import Editor from '@draft-js-plugins/editor';

import CommentBlock from './commentBlock';
import Countable from './countable';
import {imagePlugin, plugins, AlignmentTool} from './plugins';
import styled, {css} from 'styled-components';
// styles
import 'draft-js/dist/Draft.css';
import './textEditor.css';
import editorStyles from './editorStyles.css';
import UploadInlineImage from '../../containers/content/uploadInlineImage';
import SplitChapterButton from '../../containers/content/splitChapterButton';
import {
  BoldButton,
  ItalicButton,
  UnderlineButton,
  HeaderTwoButton,
  HeaderThreeButton,
  ReactionsButtons
} from './inlineToolbarButtons';

import speech, {playbackStates as PlaybackStates} from '../../utils/speechUtil';
import uxAnalyticsUtil from '../../utils/uxAnalyticsUtil';

const StyledEditor = styled(Editor)`
  font-family: ${({manuscriptStyle}) =>
    manuscriptStyle === 'screenplay' ? 'monospace' : 'Georgia'}!important;
  font-size: 3em;
`;

const {hasCommandModifier} = KeyBindingUtil;

const CustomInlineToolbarWrapper = styled.div`
  visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
  position: absolute;
  z-index: 2;
  width: 90%;
  top: ${({top}) => top}px;
`;

const CustomInlineToolbar = styled.div`
  position: absolute;
  display: block;
  height: 20px;
  left: ${({left}) => left}px;
`;

// start decorators
function commentDecoratorStrategy(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    character => {
      const entityKey = character.getEntity();
      if (entityKey) {
      }
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'COMMENT'
      );
    },
    (start, end) => {
      callback(start, end);
    }
  );
}

const decorators = new CompositeDecorator([
  {
    strategy: commentDecoratorStrategy,
    component: CommentBlock
  }
]);
// end decorators

// start custom styling
const customStyleMap = {
  PLAYING: {
    backgroundColor: '#faed27'
  }
};
// end custom styling

const TextEditor = ({
  content,
  language,
  inlineComments,
  activeInlineComment,
  onInitialized,
  readOnly,
  allowComments,
  editorMode = 'reading',
  access,
  manuscriptStyle,
  inlineToolbarType,
  readyToEdit = false,
  onCommentsIngested,

  clearCommentList,
  onSplitChapter,
  onDocumentDataUpdated,
  removeInlineCommentPlaceholder,
  removeEmptyInlineCommentPlaceholder,
  clearActiveInlineComment,
  onSave
}) => {
  const inlineMenuRef = useRef();
  const textEditorRef = useRef();

  const {t} = useTranslation();

  const [currentEditorState, setCurrentEditorState] = useState(null); // initialize to null or the editor will update its parent with empty documentData
  const [documentData, setDocumentData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentBlockLength, setCurrentBlockLength] = useState();
  const [imgButtonPosition, setImgButtonPosition] = useState();
  const [currentSelection, setCurrentSelection] = useState();
  const [inlineMenuPosition, setInlineMenuPosition] = useState();
  const [playbackEditorState, setPlaybackEditorState] = useState();
  const [currentHighlight, setCurrentHighlight] = useState();
  const playbackState = useRef(PlaybackStates.stopped);

  const paragraphStyleFn = useCallback(
    (contentBlock, props) => {
      let className = '';
      const offsetKey = contentBlock.getKey();
      // let index = 0;
      let index = props
        .getEditorState()
        .getCurrentContent()
        .getBlockMap()
        .keySeq()
        .findIndex(k => {
          return k === offsetKey;
        });

      const type = contentBlock.getType();
      if (type === 'unstyled') {
        className += `br-cp br-pn${index}`;
      }

      className += ` br-manuscript-style-${manuscriptStyle}`;

      return className;
    },
    [manuscriptStyle]
  );

  /**
   * Initial on mount and whenever content changes
   */
  useEffect(() => {
    speech.setLanguage(language);

    setIsLoading(true);
    setupEditor(content, newState => {
      playbackState.current = PlaybackStates.stopped;
      setCurrentEditorState(newState.editorState);
      setIsLoading(false);
      onInitialized && onInitialized();
    });

    return () => {
      clearCommentList && clearCommentList();

      try {
        speech.cancel();
      } catch (error) {
        console.log('failed to cancel speech', error);
      }
    };
  }, [content]);

  /**
   * When inline comments change (TODO: could this fit into the general mount effect?)
   */
  useEffect(() => {
    if (!!currentEditorState && inlineComments) {
      ingestInlineComments(currentEditorState, inlineComments);

      // inform parent that comments are now ingested (and we are ready to start saving)
      onCommentsIngested && onCommentsIngested();
    }
  }, [inlineComments?.length]);

  /**
   * Start playback when playbackstate is stopped and playbackeditorstate goes from null to existing
   */
  useEffect(() => {
    if (
      playbackState.current === PlaybackStates.stopped &&
      !!playbackEditorState
    ) {
      const selection = playbackEditorState.getSelection();
      uxAnalyticsUtil.trackEvent({
        category:
          editorMode === 'reading'
            ? uxAnalyticsUtil.categories.READING
            : uxAnalyticsUtil.categories.BOOK_MANAGEMENT,
        action: 'start_speech'
      });
      playbackState.current = PlaybackStates.playing;
      startPlayback(selection.getFocusKey(), selection.getStartOffset());
    }
  }, [playbackEditorState]);

  useEffect(() => {
    if (documentData) {
      onDocumentDataUpdated && onDocumentDataUpdated(documentData);
    }
  }, [documentData]);

  const setupEditor = (rawContent, onEditorSetupComplete) => {
    let blockData = null;
    let _editorState = null;

    try {
      blockData = JSON.parse(rawContent);
    } catch (e) {
      //failed to get content blockData
    }

    // check if there is content and create empty textEditor otherwise load texteditor with content
    if (
      blockData == null ||
      (blockData.constructor === Object && Object.keys(blockData).length === 0)
    ) {
      _editorState = EditorState.createEmpty(decorators);
    } else {
      const contentState = convertFromRaw(blockData);
      _editorState = EditorState.createWithContent(contentState, decorators);
    }

    EditorState.set(_editorState, {decorator: decorators});

    let plainText = _editorState.getCurrentContent().getPlainText();
    let currentContent = _editorState.getCurrentContent();

    const newState = {
      originalCurrentContent: currentContent,
      originalPlainText: plainText,
      editorState: _editorState,
      content: rawContent
    };
    // send back the data instead of calling setState
    onEditorSetupComplete(newState);
  };

  const ingestInlineCommentsOneByOne = (_editorState, _inlineComments) => {
    // if all comments have been ingested.
    let updatedEditorState = _editorState;
    for (let i = 0; i < _inlineComments.length; i += 1) {
      // get the current content state
      let contentState = updatedEditorState.getCurrentContent();
      // get the comment
      const comment = _inlineComments[i];
      // find its selection
      const targetSelection = new SelectionState({
        anchorKey: comment.inlineInfo.startKey, // key of block
        anchorOffset: comment.inlineInfo.startOffset,
        focusKey: comment.inlineInfo.endKey,
        focusOffset: comment.inlineInfo.endOffset, // key of block
        hasFocus: false,
        isBackward: false // isBackward = (focusOffset < anchorOffset)
      });
      // check if there are other comments that overlap this selection
      const existingEntitiesOnThisSelection = getEntities(
        updatedEditorState,
        targetSelection,
        'COMMENT'
      );
      // update any overlapping comment entities with this comment id
      try {
        const result = updateContentStateWithOverlapInformation(
          contentState,
          existingEntitiesOnThisSelection,
          targetSelection,
          comment._id
        );
        if (result.perfectMatch || result.isEdge) {
          // don't create a new entity if we have a perfect match (entity ranges need to be unique)
          updatedEditorState = EditorState.push(
            updatedEditorState,
            contentState,
            'apply-entity'
          );
        } else {
          // otherwise, go ahead and add a new entity to map to this comment
          // create the new comment entity
          contentState = contentState.createEntity('COMMENT', 'MUTABLE', {
            id: comment._id,
            reaction: comment.reaction,
            comments: [comment._id]
          });

          // get the comment's entity key
          const entityKey = contentState.getLastCreatedEntityKey();
          // create a new editor state that includes the new comment entity
          updatedEditorState = EditorState.set(updatedEditorState, {
            currentContent: contentState
          });
          let contentStateWithComment = Modifier.applyEntity(
            contentState,
            targetSelection,
            entityKey
          );
          // push the new editor state to the editor
          updatedEditorState = EditorState.push(
            updatedEditorState,
            contentStateWithComment,
            'apply-entity'
          );
        }
      } catch (e) {
        comment.warning = {
          key: 'InlineCommentNotFound'
        };
      }
    }

    return updatedEditorState;
  };

  const ingestInlineComments = (_editorState, _inlineComments) => {
    if (!_inlineComments || !_editorState) {
      // inform parent that comments are now ingested (and we are ready to start saving)
      return;
    }

    // get a clean editorState without comments
    const cleanEditorState = EditorState.createWithContent(
      convertFromRaw(
        getRawContentStateWithoutComments(_editorState.getCurrentContent())
      ),
      decorators
    );

    // separate out all inline comments at risk of being swallowed by ecompassing comments
    const {regularComments, swallowedComments} =
      separateInlineCommentsIntoWideAndNarrow(_inlineComments);

    // first ingest all the wider comments into the cleanEditorState
    let updatedEditorState = ingestInlineCommentsOneByOne(cleanEditorState, [
      ...regularComments
    ]);
    // then ingest the "swallowed" ones (we need to do it in this order for draftjs not to lose them)
    updatedEditorState = ingestInlineCommentsOneByOne(updatedEditorState, [
      ...swallowedComments
    ]);

    // then, focus and add the selection if we had one
    if (currentSelection) {
      updatedEditorState = EditorState.forceSelection(
        updatedEditorState,
        currentSelection
      );
    }
    setCurrentEditorState(updatedEditorState);
  };

  const updateContentStateWithOverlapInformation = (
    contentState,
    overlappingComments,
    targetSelection,
    commentId
  ) => {
    let perfectMatch = false;
    let overlaps = false;
    let isEdge = false;
    let updatedContentState = contentState;
    if (!overlappingComments) {
      return;
    }
    overlappingComments.forEach(entity => {
      overlaps = true;
      updatedContentState = updatedContentState.mergeEntityData(
        entity.entityKey,
        {
          overlap: true,
          comments: [
            ...(contentState.getEntity(entity.entityKey).getData().comments ||
              []),
            commentId
          ]
        }
      );
      if (
        entity.start === targetSelection.getStartOffset() &&
        entity.end === targetSelection.getEndOffset()
      ) {
        perfectMatch = true;
        updatedContentState = updatedContentState.mergeEntityData(
          entity.entityKey,
          {perfectMatch: true}
        );
      }
      if (
        entity.start === targetSelection.getStartOffset() ||
        entity.end === targetSelection.getEndOffset()
      ) {
        isEdge = true;
        updatedContentState = updatedContentState.mergeEntityData(
          entity.entityKey,
          {isEdge: true}
        );
      }
    });
    return {updatedContentState, perfectMatch, overlaps, isEdge};
  };

  const separateInlineCommentsIntoWideAndNarrow = _inlineComments => {
    let regularComments = [];
    let swallowedComments = [];
    _inlineComments.forEach((comment, index) => {
      var swallowingComments = _inlineComments.filter(otherComment => {
        return (
          otherComment._id !== comment._id &&
          comment.inlineInfo.startKey === otherComment.inlineInfo.startKey &&
          otherComment.inlineInfo.startOffset <=
            comment.inlineInfo.startOffset &&
          otherComment.inlineInfo.endOffset >= comment.inlineInfo.endOffset
        );
      });
      if (swallowingComments.length > 0) {
        swallowedComments.push(comment);
      } else {
        regularComments.push(comment);
      }
    });
    return {regularComments, swallowedComments};
  };

  const getEntities = (editorState, selectionState, entityType = null) => {
    const content = editorState.getCurrentContent();
    const entities = [];
    const currentContentBlock = editorState
      .getCurrentContent()
      .getBlockForKey(selectionState.getStartKey());
    // content.getBlocksAsArray().forEach((block) => {
    if (!currentContentBlock) {
      return;
    }
    let selectedEntity = null;
    currentContentBlock.findEntityRanges(
      character => {
        if (character.getEntity() !== null) {
          const entity = content.getEntity(character.getEntity());
          if (!entityType || (entityType && entity.getType() === entityType)) {
            selectedEntity = {
              entityKey: character.getEntity(),
              blockKey: currentContentBlock.getKey(),
              entity: content.getEntity(character.getEntity())
            };
            return true;
          }
        }
        return false;
      },
      (start, end) => {
        // if the entity's range overlaps with this selection
        if (
          (end >= selectionState.getEndOffset() &&
            start <= selectionState.getEndOffset()) ||
          (start <= selectionState.getStartOffset() &&
            end >= selectionState.getStartOffset()) ||
          (start >= selectionState.getStartOffset() &&
            start <= selectionState.getEndOffset()) ||
          (end <= selectionState.getEndOffset() &&
            start >= selectionState.getStartOffset())
        ) {
          entities.push({...selectedEntity, start, end});
        }
      }
    );
    return entities;
  };

  const clearCommentsFromContent = contentState => {
    contentState.getBlockMap().forEach(block => {
      const blockKey = block.getKey();
      const blockText = block.getText();
      // You need to create a selection for entire length of text in the block
      const selection = SelectionState.createEmpty(blockKey);
      const updatedSelection = selection.merge({
        // anchorOffset is the start of the block
        anchorOffset: 0,
        // focustOffset is the end
        focusOffset: blockText.length
      });
      Modifier.applyEntity(contentState, updatedSelection, null);
    });
    return contentState;
  };

  /*
   * This is a brute force way of clearing out the comments.
   * We should find a way that uses DraftJS's native functions.
   */
  const getRawContentStateWithoutComments = contentState => {
    const rawContentState = convertToRaw(contentState);
    let newRawContentState;
    // filter out only image entitymaps
    let filteredEntityMap = {};
    // create a new array of entityMap with only 'IMAGE' in order to preserve these when clearing the content
    const fiteredEntityMapArray = Object.entries(
      rawContentState.entityMap
    ).filter(entry => entry[1].type === 'IMAGE');
    // create a new entityMap Object from the filtered entityMap array
    fiteredEntityMapArray.forEach(entity => {
      const newEntity = entity[1];
      filteredEntityMap[entity[0]] = newEntity;
    });
    // set the rawContentState to the new filtered entityMap
    newRawContentState = {
      ...rawContentState,
      entityMap: filteredEntityMap
    };

    // filter blocks to only include entityranges that map to an image EG. remove all comments
    const filteredBlocks = newRawContentState.blocks.map(block => {
      const filteredEntityRanges = block.entityRanges.filter(entityRange => {
        // check if we have a match in the entityMap
        const foundEntityMapping = fiteredEntityMapArray.find(
          entry => parseInt(entry[0], 10) === entityRange.key
        );
        // if we get a match return to filter
        if (foundEntityMapping) {
          return entityRange;
        }
        return null;
      });
      const filteredBlock = {
        ...block,
        entityRanges: filteredEntityRanges
      };
      return filteredBlock;
    });
    newRawContentState = {
      ...newRawContentState,
      blocks: filteredBlocks
    };
    // return the filtered newRawContentState with only text and images
    return newRawContentState;
  };

  const getDocumentData = (contentState, lastChangeType, oldContentState) => {
    const plainText = contentState.getPlainText();
    const count = Countable.countPlainText(plainText);

    const paragraphs = [];
    const blockArray = contentState.getBlocksAsArray();
    blockArray.forEach((block, index) => {
      paragraphs[index] = {
        wordCount: Countable.countPlainText(block.text).words
      };
    });

    // find any changes to comment placements
    const foundInlineComments = {};
    const entityType = 'COMMENT';
    contentState.getBlocksAsArray().forEach(block => {
      let selectedEntity = null;
      block.findEntityRanges(
        character => {
          if (character.getEntity() !== null) {
            const entity = contentState.getEntity(character.getEntity());
            if (
              !entityType ||
              (entityType && entity.getType() === entityType)
            ) {
              selectedEntity = {
                entityKey: character.getEntity(),
                blockKey: block.getKey(),
                entity: contentState.getEntity(character.getEntity())
              };
              return true;
            }
          }
          return false;
        },
        (start, end) => {
          const commentId = contentState
            .getEntity(selectedEntity.entityKey)
            .get('data').id;
          // found a comment
          foundInlineComments[commentId] = {
            _id: commentId,
            startKey: selectedEntity.blockKey,
            endKey: selectedEntity.blockKey,
            startOffset: start,
            endOffset: end
          };
        }
      );
    });

    // placeholders for comments that no longer can be found inline
    const removedInlineComments = [];
    // placeholders for comments that can be found inline, but have changed
    const changedInlineComments = [];
    // placeholders for comments that can be found inline, and have not changed
    const unchangedInlineComments = [];

    // look up existing inline comments
    const previousInlineComments = inlineComments || [];
    previousInlineComments.forEach(comment => {
      // check if comment still exists inline
      const inlineComment = foundInlineComments[comment._id];
      if (inlineComment !== undefined) {
        // the comment was found inline
        if (
          inlineComment.startKey !== comment.inlineInfo.startKey ||
          inlineComment.startOffset !== comment.inlineInfo.startOffset ||
          inlineComment.endOffset !== comment.inlineInfo.endOffset
        ) {
          // if any value has changed, put the new values in the changedInlineComments array
          changedInlineComments.push(inlineComment);
        } else {
          unchangedInlineComments.push(inlineComment);
        }
      } else {
        // the comment was not found inline. put it in the removed comments array
        removedInlineComments.push({_id: comment._id});
      }
    });
    const textHasChanged =
      readyToEdit &&
      (currentEditorState.getCurrentContent() !== contentState ||
        Immutable.is(currentEditorState.getCurrentContent(), contentState));
    const documentData = {
      charCount: count.all,
      wordCount: count.words,
      paragraphCount: count.paragraphs,
      differentFromOriginal: textHasChanged,
      paragraphs,
      content: contentState,
      rawContentWithoutComments:
        getRawContentStateWithoutComments(contentState),
      changedInlineComments,
      removedInlineComments,
      // TODO: this seems to switch things around. comment from part a land in part b and vice versa
      remainingInlineComments: Object.keys(foundInlineComments).map(
        key => foundInlineComments[key]
      )
    };
    return documentData;
  };

  // run onEditorStateChange every time something is changed in text
  const onEditorStateChange = newEditorState => {
    const _documentData = getDocumentData(
      newEditorState.getCurrentContent(),
      newEditorState.getLastChangeType(),
      currentEditorState.getCurrentContent()
    );
    setStaticButtonPositions(newEditorState);
    // check if selection was done over several blocks, used to prevent overlay toolbar to show
    let isSelectingOverBlocks = false;
    if (
      newEditorState.getSelection().getStartKey() !==
      newEditorState.getSelection().getEndKey()
    ) {
      isSelectingOverBlocks = true;
    } else if (
      newEditorState.getSelection().getStartKey() ===
      newEditorState.getSelection().getEndKey()
    ) {
      isSelectingOverBlocks = false;
    }

    if (readOnly) {
      // only allow entity changes (adding comments) if editor is readOnly
      if (allowComments) {
        if (
          newEditorState.getLastChangeType() === null ||
          newEditorState.getLastChangeType() === 'apply-entity'
        ) {
          // don't do anything
        } else {
          return;
        }
      } else {
        return;
      }
    }

    setDocumentData(_documentData);
    setCurrentEditorState(newEditorState);
  };

  const handleOnSplit = ({contentAboveSplit, contentBelowSplit}) => {
    const documentDataAboveSplit = getDocumentData(
      contentAboveSplit,
      'split-chapter'
    );
    const documentDataBelowSplit = getDocumentData(
      contentBelowSplit,
      'split-chapter'
    );
    onSplitChapter &&
      onSplitChapter({
        originalChapterData: documentDataAboveSplit,
        newChapterData: documentDataBelowSplit
      });
  };

  const handleBeforeInput = chars => {
    if (readOnly && chars !== undefined && chars.length > 0) {
      return 'handled';
    }
    return 'not-handled';
  };

  const onStartPlayback = async e => {
    setPlaybackEditorState(currentEditorState);
  };

  const editorAreaClickHandler = () => {
    switch (playbackState.current) {
      case PlaybackStates.playing:
        stopPlayback();
        break;
      default:
        const inlineCommentPlaceholderExists =
          inlineComments &&
          inlineComments.filter(
            placeHolderComment =>
              placeHolderComment._id.substr(0, 11) === 'placeHolder'
          ).length > 0;
        if (inlineCommentPlaceholderExists) {
          removeEmptyInlineCommentPlaceholder();
          if (document.activeElement !== document.body) {
            // document.activeElement.blur();
          }
        }
        if (!!activeInlineComment) {
          clearActiveInlineComment();
          // make sure we blur the focus otherwise we loose the ability to show customToolBar
          if (document.activeElement !== document.body) {
            // document.activeElement.blur();
          }
        }
        if (textEditorRef.current) {
          // set textEditor to focus onClick
          const node = textEditorRef.current;
          node.focus();
        }
        break;
    }
  };

  const setStaticButtonPositions = editorState => {
    if (!textEditorRef.current) {
      // nothing to set positions on, abort mission
      return;
    }
    let editorRoot = textEditorRef.current.childNodes[0];
    while (editorRoot.className.indexOf('DraftEditor-root') === -1) {
      editorRoot = editorRoot.parentNode;
    }

    const selection = editorState.getSelection();
    const currentContent = editorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(selection.getStartKey());
    const _currentBlockLength = currentBlock.getText().length;
    const offsetKey = DraftOffsetKey.encode(currentBlock.getKey(), 0, 0);

    const node = document.querySelectorAll(
      `[data-offset-key="${offsetKey}"]`
    )[0];

    // if we cannot find the dom node to base our positioning on, stop here
    if (!node) return;

    const cursorHeight = 8;
    const position = {
      top: node.offsetTop + editorRoot.offsetTop - cursorHeight,
      left: editorRoot.offsetLeft - 80
    };
    setCurrentBlockLength(_currentBlockLength);
    setImgButtonPosition(position);
    setCurrentSelection(selection);

    if (
      selection.focusKey !== selection.anchorKey ||
      selection.focusOffset !== selection.anchorOffset
    ) {
      // if text is selected
      const selectionRect = (0, getVisibleSelectionRect)(window);
      if (!!inlineMenuRef.current && !!selectionRect) {
        // if we have a menu to calculate position onn
        const editorRootRect = editorRoot.getBoundingClientRect();
        const inlineMenuRect = inlineMenuRef.current.getBoundingClientRect();
        let inlineMenuLeft =
          selectionRect.width / 2 +
          selectionRect.left -
          editorRootRect.left -
          inlineMenuRect.width / 2;
        if (inlineMenuLeft < 0) {
          inlineMenuLeft = 0;
        } else if (
          inlineMenuLeft + inlineMenuRect.width >
          editorRootRect.width
        ) {
          inlineMenuLeft = editorRootRect.width - inlineMenuRect.width;
        }

        const topAdjustment = editorMode === 'reading' ? 50 : -30;

        setInlineMenuPosition({
          top: selectionRect.top - editorRootRect.top - topAdjustment,
          left: inlineMenuLeft
        });
      }
    } else {
      // hide inline menu by removing its position
      setInlineMenuPosition(null);
    }
  };

  const keyBindingFn = event => {
    if (['edit'].includes(access)) {
      return getDefaultKeyBinding(event);
    }
    return null;
  };

  const handleKeyCommand = (command, _editorState) => {
    if (readOnly) {
      return 'handled';
    }
    switch (command) {
      case 'save-content':
        onSave && onSave();
        return 'handled';
      case 'tab':
        const newContentState = Modifier.replaceText(
          _editorState.getCurrentContent(),
          _editorState.getSelection(),
          '\t'
        );
        setCurrentEditorState(
          EditorState.push(_editorState, newContentState, 'insert-characters')
        );
        return 'handled';
      case 'playback-option-enter': {
        const newContentState = Modifier.replaceText(
          _editorState.getCurrentContent(),
          _editorState.getSelection(),
          '\n'
        );
        setCurrentEditorState(
          EditorState.push(_editorState, newContentState, 'insert-characters')
        );
        return 'handled';
      }
      default:
        const newState = RichUtils.handleKeyCommand(_editorState, command);
        if (newState) {
          onEditorStateChange(newState);
          return 'handled';
        }
        return 'not-handled';
    }
  };

  const handleKeyBinding = event => {
    if (event.keyCode === 13 && hasCommandModifier(event)) {
      console.log('playback-option-enter');
      return 'playback-option-enter'; // return a unique key binding for Option+Enter
    } else if (
      event.keyCode === 83 /* `S` key */ &&
      (event.metaKey || event.ctrlKey)
    ) {
      return 'save-content';
    } else if (event.keyCode === 9 /** tab key */) {
      return 'tab';
    }
    return getDefaultKeyBinding(event);
  };

  const handlePlaybackKeybinding = event => {
    try {
      speech.cancel();
    } catch (error) {
      console.log('failed to pause', error);
    }
    return 'handled';
  };

  const getHighlightedEditorState = (
    _editorState,
    blockKey,
    startOffset,
    endOffset
  ) => {
    // highlight spoken text in editor
    const selectionState = SelectionState.createEmpty(blockKey);
    const updatedSelection = selectionState.merge({
      focusKey: blockKey,
      anchorOffset: startOffset,
      focusOffset: endOffset
    });
    const updatedEditorState = RichUtils.toggleInlineStyle(
      EditorState.acceptSelection(_editorState, updatedSelection),
      'PLAYING'
    );
    return updatedEditorState;
  };

  const getSelectedEditorState = (
    _editorState,
    blockKey,
    startOffset,
    endOffset
  ) => {
    // highlight spoken text in editor
    const selectionState = SelectionState.createEmpty(blockKey);
    const updatedSelection = selectionState.merge({
      focusKey: blockKey,
      anchorOffset: startOffset,
      focusOffset: endOffset
    });
    const updatedEditorState = EditorState.forceSelection(
      _editorState,
      updatedSelection
    );
    return updatedEditorState;
  };

  const startPlayback = (blockKey, startOffset = 0) => {
    // find next span in editor
    const contentState = playbackEditorState.getCurrentContent();
    const block = contentState.getBlockForKey(blockKey);

    // get move buttons and scroll into view
    const selectedEditorState = getHighlightedEditorState(
      currentEditorState,
      block.getKey(),
      0,
      0
    );
    const offsetKey = DraftOffsetKey.encode(blockKey, 0, 0);
    const blockNode = document.querySelectorAll(
      `[data-offset-key="${offsetKey}"]`
    )[0];
    if (blockNode) {
      // scroll into view
      blockNode.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
    }
    // move buttons if we've changed which block is being spoken
    setStaticButtonPositions(selectedEditorState);

    const text = block.getText().substr(startOffset);
    speech.setLanguage(language);
    speech.speak({
      language,
      words: text,
      onEnd: e => {
        if (playbackState.current === PlaybackStates.playing) {
          const nextBlockKey = contentState.getKeyAfter(blockKey);
          if (nextBlockKey) {
            // next block exists, continue playing
            startPlayback(nextBlockKey);
          } else {
            // otherwise, revert to read mode
            setPlaybackEditorState(null);
            playbackState.current = PlaybackStates.stopped;
          }
        }
      },
      onBoundary: e => {
        const highlightStart = startOffset + e.charIndex;
        const highlightEnd = highlightStart + e.charLength;
        const highlightedEditorState = getHighlightedEditorState(
          currentEditorState,
          block.getKey(),
          highlightStart,
          highlightEnd
        );
        const offsetKey = DraftOffsetKey.encode(blockKey, 0, 0);
        const blockNode = document.querySelectorAll(
          `[data-offset-key="${offsetKey}"]`
        )[0];
        if (blockNode) {
          // scroll into view
          blockNode.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest'
          });
        }
        // move buttons if we've changed which block is being spoken
        setStaticButtonPositions(highlightedEditorState);
        setPlaybackEditorState(highlightedEditorState);
        playbackState.current = PlaybackStates.playing;
        setCurrentHighlight({
          key: block.getKey(),
          startOffset: highlightStart,
          endOffset: highlightEnd
        });
      },
      onError: e => {
        console.log('failed', e);
        const selectStart = startOffset + e.charIndex;
        const selectEnd = selectStart + e.charLength;
        const selectedEditorState = getSelectedEditorState(
          currentEditorState,
          block.getKey(),
          selectStart,
          selectEnd
        );
        setCurrentEditorState(selectedEditorState);
        playbackState.current = PlaybackStates.stopped;
      }
    });

    // setEditorState(editorState);
    playbackState.current = PlaybackStates.playing;
  };

  const stopPlayback = () => {
    console.log('stop playback');
    try {
      let selectedEditorState;
      if (currentHighlight) {
        // if we have a highlight to focus on, enforce that on the rendered editor state
        selectedEditorState = getSelectedEditorState(
          currentEditorState,
          currentHighlight.key,
          currentHighlight.startOffset,
          currentHighlight.endOffset
        );
        // make sure that the inline menu is visible again
        setStaticButtonPositions(selectedEditorState);
      }

      playbackState.current = PlaybackStates.stopped;
      setCurrentEditorState(selectedEditorState ?? currentEditorState);
      speech.cancel(true);
      uxAnalyticsUtil.trackEvent({
        category:
          editorMode === 'reading'
            ? uxAnalyticsUtil.categories.READING
            : uxAnalyticsUtil.categories.BOOK_MANAGEMENT,
        action: 'cancel_speech'
      });
    } catch (error) {
      console.log('failed to cancel speech', error);
    }
  };

  // only render if we have an editor state
  if (!currentEditorState && !playbackEditorState && !readyToEdit) return null;
  if (
    playbackState.current === PlaybackStates.playing &&
    !!playbackEditorState
  ) {
    return (
      <div
        ref={textEditorRef}
        onClick={stopPlayback}
        role='presentation'
        className='jonas'>
        <StyledEditor
          editorState={playbackEditorState}
          keyBindingFn={handlePlaybackKeybinding}
          onChange={onEditorStateChange}
          blockStyleFn={paragraphStyleFn}
          customStyleMap={customStyleMap}
          readOnly
          manuscriptStyle={manuscriptStyle}
          editorStyle={{
            fontFamily: 'monospace!important'
          }}
        />
      </div>
    );
  } else if (!!currentEditorState) {
    return (
      <div
        ref={textEditorRef}
        // className={`${activeInlineComment ? 'editor-blur' : editorStyles.editor} ${isLoading ? 'editor-blur' : ''}`}
        className={`${editorStyles.editor} ${isLoading ? 'editor-blur' : ''}`}
        onClick={editorAreaClickHandler}
        role='presentation'>
        <StyledEditor
          style={{fontFamily: 'monospace', color: 'blue'}}
          // onTab={handleTab}
          editorState={currentEditorState}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={handleKeyBinding}
          handleBeforeInput={handleBeforeInput}
          onChange={onEditorStateChange}
          placeholder={t('OnceUponATime')}
          plugins={plugins}
          blockStyleFn={paragraphStyleFn}
          manuscriptStyle={manuscriptStyle}
        />
        <AlignmentTool />
        {!activeInlineComment && (
          <>
            <CustomInlineToolbarWrapper
              top={inlineMenuPosition?.top ?? 0}
              visible={!!inlineMenuPosition}>
              <CustomInlineToolbar
                ref={inlineMenuRef}
                left={inlineMenuPosition?.left ?? 0}>
                <Button.Group color='black'>
                  {inlineToolbarType === 'editor' && (
                    <>
                      <Button icon='play' onClick={onStartPlayback} />
                      <BoldButton
                        getEditorState={() => currentEditorState}
                        setEditorState={onEditorStateChange}
                      />
                      <ItalicButton
                        getEditorState={() => currentEditorState}
                        setEditorState={onEditorStateChange}
                      />
                      <UnderlineButton
                        getEditorState={() => currentEditorState}
                        setEditorState={onEditorStateChange}
                      />
                      <HeaderTwoButton
                        getEditorState={() => currentEditorState}
                        setEditorState={onEditorStateChange}
                      />
                      <HeaderThreeButton
                        getEditorState={() => currentEditorState}
                        setEditorState={onEditorStateChange}
                      />
                    </>
                  )}
                  {inlineToolbarType === 'reader' && (
                    <>
                      <Button icon='play' onClick={onStartPlayback} />
                      <ReactionsButtons
                        getEditorState={() => currentEditorState}
                        setEditorState={onEditorStateChange}
                      />
                    </>
                  )}
                </Button.Group>
              </CustomInlineToolbar>
            </CustomInlineToolbarWrapper>
          </>
        )}
        {currentBlockLength === 0 && imgButtonPosition && !readOnly && (
          <div
            style={{
              position: 'absolute',
              top: imgButtonPosition.top || 0,
              // right: 0,
              zIndex: 2,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%'
            }}>
            <UploadInlineImage
              editorState={currentEditorState}
              onChange={onEditorStateChange}
              modifier={imagePlugin.addImage}
            />
            <SplitChapterButton
              editorState={currentEditorState}
              onSplit={handleOnSplit}
            />
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default withTranslation()(TextEditor);
