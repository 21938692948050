import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Feed, Icon, Dropdown, TextArea, Button, Image
} from 'semantic-ui-react';
// i18n
import { withTranslation } from 'react-i18next';
import moment from 'moment';
// custom modules
import Avatar from '../../components/avatar/avatar';
import ImageSlideShow from './imageSlideshow';
import imageUtil from '../../utils/imageUtil';
import PostPrivacySelector from '../../components/feed/postPrivacySelector';

class FeedEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editedText: props.event.text,
      privacy: props.event.privacy
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { editMode } = this.state;
    if (editMode && !prevState.editMode && this.textarea) {
      this.textarea.focus();
    }
  }

  toggleEditMode = () => {
    this.setState({
      editMode: !this.state.editMode
    });
  }

  handleEditedTextChange = (event, data) => {
    this.setState({
      editedText: data.value
    });
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 13 && event.metaKey) {
      // this is a CMD+Enter or Ctrl+Enter event. Submit reply
      this.updatePost();
    }
    if (event.keyCode === 27) {
      // this is a CMD+Enter or Ctrl+Enter event. Submit reply
      this.toggleEditMode();
    }
  }

  updatePost = () => {
    const { editedText, privacy } = this.state;
    const { event } = this.props;
    if (this.props.onUpdatePost) {
      this.props.onUpdatePost({ privacy, text: editedText, _id: event._id }, (success) => {
        if (success) {
          this.setState({
            editMode: false
          });
        }
      });
    }
  }

  deleteBookPost = () => {
    const { event } = this.props;
    if (this.props.onDeletePost) {
      this.props.onDeletePost(event);
    }
  }

  openImageSlideshow = (startIndex) => {
    this.setState({
      imageSlideshowActive: true,
      imageSlideshowStart: startIndex
    });
  }

  closeImageSlideshow = () => {
    this.setState({
      imageSlideshowActive: false,
      imageSlideshowStart: undefined
    });
  }

  handlePostPrivacyChange = (event, data) => {
    this.setState({ privacy: data.value }, () => this.updatePost());
  }

  render() {
    const { event, t, editable } = this.props;
    const {
      editMode, editedText, imageSlideshowActive, imageSlideshowStart
    } = this.state;
    return (
      <Feed.Event style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        {
          imageSlideshowActive && (
            <ImageSlideShow
              images={event.images}
              startIndex={imageSlideshowStart}
              onClose={this.closeImageSlideshow}
            />
          )
        }

        <Feed.Label>
          {
            event.kind === 'GroupPost'
            && <Avatar name={event.createdBy.displayName} />
          }
          {
            event.kind === 'BookPost'
            && <Icon name='info circle' />
          }
        </Feed.Label>
        <Feed.Content style={{ flex: 1 }}>
          <Feed.Summary>
            {
              event.kind === 'BookPost'
              && (
                <span>
                  {t('BookUpdateForX')}
                  {
                    event.book.privacy !== 'unpublished' && (
                      <Link to={`/discover/${event.book._id}`}>{event.book.title}</Link>
                    )
                  }
                  {
                    event.book.privacy === 'unpublished' && (
                      <span>{event.book.title} ({t('PrivacyLevel_state_unpublished').toLowerCase()})</span>
                    )
                  }
                </span>
              )
            }
            {
              event.kind === 'GroupPost'
              && event.createdBy.displayName
            }
            <Feed.Date>
              {moment(new Date(event.createdAt)).fromNow()}
              {
                editable && (
                  <PostPrivacySelector
                    value={event.privacy}
                    onChange={this.handlePostPrivacyChange}
                    style={{ marginLeft: 5 }}
                  />
                )
              }
            </Feed.Date>
          </Feed.Summary>
          {
            event.text && (
              <Feed.Extra
                text
                style={{ width: '100%', border: '0px' }}
              >
                {
                  !editMode && event.text
                }
                {
                  editMode && (
                    <Feed.Extra
                      text
                      as={TextArea}
                      style={{ width: '100%', border: '0px' }}
                      value={editedText}
                      onChange={this.handleEditedTextChange}
                      onKeyDown={this.handleKeyDown}
                    />
                  )
                }
              </Feed.Extra>
            )
          }
          {
            event.images && event.images.length > 0
            && (
              <Feed.Extra images>
                <Image.Group size='tiny'>
                  {
                    event.images.map((image, index) => (
                      <Image
                        key={image._id}
                        src={
                          imageUtil.getImageUrl({
                            operation: 'width',
                            size: 150,
                            originalImageUrl: image.url
                          })
                        }
                        alt={image.description}
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.openImageSlideshow(index)}
                      />
                    ))
                  }
                </Image.Group>
              </Feed.Extra>
            )
          }
        </Feed.Content>
        {
          editable && !editMode
          && (
            <Feed.Content style={{ flex: 0, marginLeft: 'auto' }}>
              <Dropdown icon='ellipsis vertical' basic pointing='right'>
                <Dropdown.Menu>
                  <Dropdown.Item icon='edit' text={t('Edit')} onClick={this.toggleEditMode} />
                  <Dropdown.Item icon='trash alternate outline' text={t('Delete')} onClick={this.deleteBookPost} />
                </Dropdown.Menu>
              </Dropdown>
            </Feed.Content>
          )
        }
        {
          editable && editMode
          && (
            <Feed.Content style={{ flex: 0, marginLeft: 'auto' }}>
              <Button.Group basic icon compact vertical>
                <Button icon='cancel' onClick={this.toggleEditMode} />
                <Button icon='save' onClick={this.updatePost} />
              </Button.Group>
            </Feed.Content>
          )
        }
      </Feed.Event>
    );
  }
}
export default withTranslation()(FeedEvent);
