import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Tab, Menu
} from 'semantic-ui-react';

// i18n
import { withTranslation } from 'react-i18next';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  clearCurrentBroadcastMessage,
  fetchBroadcastMessage
} from '../../../modules/message';

import { authUtil } from '../../../utils';

import BroadcastMessage from './broadcastMessage';
import BroadcastMessageReport from './broadcastMessageReport';
import { PaddedWrapper } from '../../../style';

export class BroadcastMessageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchBroadcastMessage();
  }

  componentWillUnmount() {
    this.props.clearCurrentBroadcastMessage();
  }

  fetchBroadcastMessage = async () => {
    const { match } = this.props;
    const idToken = await authUtil.getFreshIdToken();
    const { messageId } = match.params;
    this.props.fetchBroadcastMessage({ idToken, messageId });
  }

  render() {
    const { t, currentBroadcastMessage } = this.props;
    if (!currentBroadcastMessage) return null;

    const panes = [
      {
        menuItem: t('Message'),
        render: () => <Tab.Pane><BroadcastMessage message={currentBroadcastMessage} /></Tab.Pane>
      },
      {
        menuItem: (
          <Menu.Item>
            <span>{t('Seen')}</span>
            <span style={{ marginLeft: 5 }}>
              (
              {(currentBroadcastMessage && currentBroadcastMessage.seenCount) || 0}
              )
            </span>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <BroadcastMessageReport filter='seenAt' messageId={currentBroadcastMessage._id} />
          </Tab.Pane>
        )
      }
    ];
    return (
      <PaddedWrapper>
        <Tab panes={panes} />
      </PaddedWrapper>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  currentBook: state.book.currentBook,
  currentBroadcastMessage: state.message.currentBroadcastMessage,
  fetchingBroadcastMessage: state.message.fetchBroadcastMessage
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage),
  clearCurrentBroadcastMessage,
  fetchBroadcastMessage
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(BroadcastMessageContainer)));
