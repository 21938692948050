import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import EditPart from './editPart';
import ContentRouter from './contentRouter';
import {
  setCurrentContentVersion
} from '../../modules/book';

export class Content extends Component {
  constructor(props) {
    super();
  }

  componentWillUnmount() {
    this.props.setCurrentContentVersion(undefined);
  }

  render() {
    const { match } = this.props;
    return (
      <Switch>
        <Route
          exact
          path={`${match.path}/v:versionNumber/new-survey`}
          render={props => <EditPart {...props} mode='Create' kind='Survey' />}
        />
        <Route
          exact
          path={`${match.path}/v:versionNumber/new-chapter`}
          render={props => <EditPart {...props} mode='Create' kind='Chapter' />}
        />
        <Route path={`${match.path}/v:versionNumber/:partId`} component={EditPart} />
        <Route path={`${match.path}/v:versionNumber`} component={EditPart} />
        <Route path={`${match.path}`} component={ContentRouter} />
      </Switch>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({
  setCurrentContentVersion
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Content);
