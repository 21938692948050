import React, { useState, useContext, createContext } from 'react';

const PostProcessingContext = createContext(undefined);

export const PostProcessingContextProvider = ({ initialValue, onUpdate, children }) => {
  const [documentData, setDocumentData] = useState(initialValue);

  const resetDocumentData = () => {
    setDocumentData(initialValue);
  }
  const providerValue = {
    originalDocumentData: initialValue,
    documentData,
    setDocumentData: (newDocumentData) => {
      setDocumentData(newDocumentData);
      onUpdate(newDocumentData);
    },
    resetDocumentData
  };

  return (
    <PostProcessingContext.Provider value={providerValue}>
      {children}
    </PostProcessingContext.Provider>
  )
};

const usePostProcessingContext = () => {
  return useContext(PostProcessingContext);
};

export default usePostProcessingContext;
