import React, { Component } from 'react';

import qs from 'qs';

import {
  Segment, Divider, Modal, Grid, Form
} from 'semantic-ui-react';
// i18n
import { withTranslation } from 'react-i18next';

// Routes
import { withRouter } from 'react-router-dom';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import InviteReader from './inviteReader';
import ReaderIndexFilterContainer from './readerIndexFilterContainer';
import { authUtil } from '../../utils';
import UserBadge from '../../components/user/userBadge';
import { fetchReaders, fetchMoreReaders } from '../../modules/readerIndex';
import LoadMore from '../../components/lists/loadMore';
import { pick } from '../../shared/utils/randomUtil';

const sortOrder = pick([
  {
    value: 'joinDate',
    weight: 0.95
  },
  {
    value: 'alphabetic',
    weight: 0.05
  }
]).value

export class ReaderIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameFilter: ''
    };
  }

  componentDidMount = () => {
    this.fetchUsers();
  }

  fetchUsers = async () => {
    clearTimeout(this.fetchUsersTimeout);
    const idToken = await authUtil.getFreshIdToken();
    const { nameFilter } = this.state;
    const { readerFilters } = this.props;
    const genreFilters = readerFilters.filter(filters => filters.type === 'genres');
    const languageFilters = readerFilters.filter(filters => filters.type === 'language');
    const genres = {};
    const languages = {};
    let query = {
      name: nameFilter,
      sortOrder
    };
    if (Array.isArray(genreFilters) && genreFilters.length > 0) {
      genreFilters.forEach((filter) => {
        if (filter.option && filter.value) {
          genres[filter.option] = filter.value;
        }
      });
      query = {
        ...query,
        genres
      };
    }
    if (Array.isArray(languageFilters) && languageFilters.length > 0) {
      languageFilters.forEach((filter) => {
        if (filter.option && filter.value) {
          languages[filter.option] = filter.value;
        }
      });
      query = {
        ...query,
        languages
      };
    }
    const stringifiedQueryQs = qs.stringify(query);
    this.props.fetchReaders(idToken, stringifiedQueryQs);
  }

  fetchMoreUsers = async () => {
    const idToken = await authUtil.getFreshIdToken();
    const { nameFilter } = this.state;
    const { readers = [], readerFilters } = this.props;
    const genreFilter = readerFilters.find(filters => filters.type === 'genres');
    const languageFilter = readerFilters.find(filters => filters.type === 'language');
    const genres = {};
    const languages = {};
    let query = {
      skip: readers.length,
      name: nameFilter,
      sortOrder
    };
    if (genreFilter && genreFilter.option && genreFilter.value) {
      genres[genreFilter.option] = genreFilter.value;
      query = {
        ...query,
        genres
      };
    }
    if (languageFilter && languageFilter.option && languageFilter.value) {
      languages[languageFilter.option] = languageFilter.value;
      query = {
        ...query,
        languages
      };
    }
    const stringifiedQueryQs = qs.stringify(query);
    this.props.fetchMoreReaders(idToken, stringifiedQueryQs);
  }

  handleUserFilterChange = (event, data) => {
    this.setState({
      nameFilter: data.value
    }, () => {
      const self = this;
      clearTimeout(this.fetchUsersTimeout);
      this.fetchUsersTimeout = setTimeout(() => {
        self.fetchUsers();
      }, 500);
    });
  }

  /* Checks if the displayName or username matches the nameFilter if any */
  matchesFilter = (reader) => {
    if (!reader._id) return false; // return false if this is not a real reader entry
    const { nameFilter } = this.state;
    if (!nameFilter || nameFilter.length === 0) return true;
    const nameRegex = new RegExp(nameFilter, 'i');
    return reader.displayName.match(nameRegex)
      || (reader.username && reader.username.match(nameRegex));
  }

  render() {
    const { t, readers = [] } = this.props;
    const { nameFilter, showModal, loadingMoreUsers } = this.state;
    return (
      <div style={{ padding: 10 }}>
        <div>
          {t('ReaderIndexInfo')}
        </div>
        <Divider hidden />
        <ReaderIndexFilterContainer onUpdate={this.fetchUsers} />
        <Divider hidden />
        <Form.Input
          placeholder={t('Search')}
          value={nameFilter}
          icon='search'
          onChange={this.handleUserFilterChange}
        />
        <Segment.Group>
          {
            readers.filter(reader => this.matchesFilter(reader)).map(reader => (
              <UserBadge
                key={reader._id}
                user={reader}
                onInvite={() => this.setState({
                  showModal: {
                    inviteReader: reader
                  }
                })}
              />
            ))
          }
        </Segment.Group>
        {
          readers.length > 0 && !readers[readers.length - 1].isLast && (
            <LoadMore
              onLoadMore={this.fetchMoreUsers}
              loading={loadingMoreUsers}
            />
          )
        }
        {
          showModal && (
            <Modal open onClose={() => this.setState({ showModal: false })}>
              <Modal.Content>
                <Modal.Description>
                  {
                    showModal.inviteReader && (
                      <InviteReader
                        reader={showModal.inviteReader}
                        onClose={() => this.setState({ showModal: false })}
                        source={`readerindex_sort_${sortOrder}`}
                      />
                    )
                  }
                  <Divider hidden />
                </Modal.Description>
              </Modal.Content>
            </Modal>
          )
        }
      </div>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  userProfile: state.user.userProfile,
  readers: state.readerIndex.readers,
  readerFilters: state.readerIndex.readerFilters
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchReaders,
  fetchMoreReaders,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ReaderIndex)));
