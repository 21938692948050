import React, { useRef, useState } from 'react';

import {
  AreaChart, Area, XAxis, YAxis,
  Tooltip, ResponsiveContainer
} from 'recharts';
import {
  Header, Dimmer, Loader, Label
} from 'semantic-ui-react';
import styled from 'styled-components';

// redux
import { useSelector } from 'react-redux';

import { reactionUtil } from '../../../../utils';
import { useTranslation } from 'react-i18next';

const reactions = [
  {
    key: 'LIKE',
    translationKey: 'Like',
    color: { R: 152, G: 251, B: 152 }
  },
  {
    key: 'LOVE',
    translationKey: 'Love',
    color: { R: 246, G: 173, B: 198 }
  },
  {
    key: 'LAUGH',
    translationKey: 'Laugh',
    color: { R: 255, G: 255, B: 0 }
  },
  {
    key: 'ANGRY',
    translationKey: 'Angry',
    color: { R: 255, G: 96, B: 96 }
  },
  {
    key: 'SURPRISE',
    translationKey: 'Surprise',
    color: { R: 64, G: 224, B: 208 }
  },
  {
    key: 'SAD',
    translationKey: 'Sad',
    color: { R: 135, G: 206, B: 235 }
  },
  {
    key: 'COMMENT',
    translationKey: 'Comment',
    color: { R: 20, G: 20, B: 20 }
  }
];

const ReactionTooltipDiv = styled.div`
  -webkit-box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
  box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
  text-align: left;
  background: white;
  border-color: grey;
  border-width: 1;
  padding: 1em;
`;

const CustomTooltip = ({ active, payload, external, label, unit, activeReaction }) => {
  const { t } = useTranslation();

  if (active && payload && payload[0]) {
    const currentPayload = payload[0]?.payload;
    if (currentPayload) {
      const { partId, partTitle, versionNumber, partIndex } = currentPayload;
      const tooltip = (
        <ReactionTooltipDiv>
          <strong key={partId}>{t('ChapterInitial')}{partIndex + 1}</strong>: <span>{partTitle} (v{versionNumber})</span><br />
          {
            reactions.map(reaction => (
              <div key={reaction.key} style={{ fontWeight: activeReaction === reaction.key ? 'bold' : 'normal' }}>
                {reactionUtil.getReactionEmoji(reaction.key)}
                {'\t'}
                {t(reaction.translationKey)}
                {': '}
                {currentPayload.reactions[reaction.key]}
                <br />
              </div>
            ))
          }
        </ReactionTooltipDiv>
      )
      return tooltip;
    }
  }

  return null;
};


const ReactionChart = ({
  data,
  syncId,
  title,
  areaColor,
  onClick,
  unit
}) => {
  const { t } = useTranslation();
  const reactionDeactivationTimeout = useRef();

  const fetchingReadingData = useSelector(state => state.report.fetchingReadingData);

  const [activeReaction, setActiveReaction] = useState(null);

  const chartClicked = (data, event) => {
    event.preventDefault();
    event.stopPropagation();
    onClick && onClick(data.activeTooltipIndex);
  }

  return (
    <div>
      {
        fetchingReadingData && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )
      }
      <Header
        sub
        content={title}
      />
      <ResponsiveContainer width='95%' height={200} style={{ textAlign: 'center' }}>
        <AreaChart
          onClick={chartClicked}
          syncId={syncId}
          data={data}
          margin={{
            top: 10, right: 30, left: 0, bottom: 0
          }}
        >
          <XAxis dataKey='name' />
          <YAxis />
          <Tooltip content={<CustomTooltip external={data} unit={unit} />} />
          <Area
            type='monotone'
            dataKey='engagement'
            stroke={areaColor}
            strokeWidth={2}
            fill={areaColor}
            fillOpacity={0.3}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ReactionChart;
