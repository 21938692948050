import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Statistic, Grid, Container, Segment, Message, Feed, Button
} from 'semantic-ui-react';
// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// i18n
import { withTranslation } from 'react-i18next';

import filterBuilder from '../../../../utils/filterBuilder';
import ReviewItem from '../../../../components/review/reviewItem';

import {
  fetchReviewData
} from '../../../../modules/report';

export class ReviewDataReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const {
      idToken, currentBook, reviewData, readerFilters
    } = this.props;
    if (idToken && currentBook && !reviewData) {
      // if there is no fresh reading data
      const cleanedFilters = filterBuilder.getFilterObject(readerFilters);
      this.props.fetchReviewData(idToken, currentBook._id, cleanedFilters)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.readerFilters && nextProps.readerFilters !== this.props.readerFilters) {
      // if the filter just changed
      const cleanedFilters = filterBuilder.getFilterObject(nextProps.readerFilters);
      this.props.fetchReviewData(nextProps.idToken, nextProps.currentBook._id, cleanedFilters)
    }
  }

  downloadReport = () => {
    const { idToken, currentBook, readerFilters } = this.props;
    const cleanedFilters = filterBuilder.getFilterObject(readerFilters);
    this.props.fetchReviewData(idToken, currentBook._id, cleanedFilters, 'csv');
  }

  renderReviewList() {
    const { reviewData, t } = this.props;
    if (reviewData[0].reviews.length > 0) {
      const reviewList = reviewData[0].reviews.map((review, index) => (
          <ReviewItem key={index} reviewData={review} t={t} />
      ));
      return (reviewList);
    }
    return null;
  }

  render() {
    const { reviewData, t } = this.props;
    if (reviewData) {
      return (
        <Segment basic>
          <Button basic floated='right' icon='download' onClick={this.downloadReport} />
          <Container>
            <Grid columns='equal' textAlign='center'>
              <Grid.Row columns={3}>
                <Statistic.Group size='small'>
                  <Statistic size='small'>
                    <Statistic.Value>{reviewData.length > 0  && reviewData[0].avgOverAllRating !== null ? reviewData[0].avgOverAllRating.toFixed(1) : 0}</Statistic.Value>
                    <Statistic.Label>{t('AvgOverallRating')}</Statistic.Label>
                  </Statistic>
                  <Statistic size='small'>
                    <Statistic.Value>{reviewData.length > 0 && reviewData[0].avgPlotRating !== null ? reviewData[0].avgPlotRating.toFixed(1) : 0}</Statistic.Value>
                    <Statistic.Label>{t('AvgPlotRating')}</Statistic.Label>
                  </Statistic>
                  <Statistic size='small'>
                    <Statistic.Value>{reviewData.length > 0 && reviewData[0].avgGrammarRating !== null ? reviewData[0].avgGrammarRating.toFixed(1) : 0}</Statistic.Value>
                    <Statistic.Label>{t('AvgGrammarRating')}</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  { reviewData.length === 0 &&
                    <Message>
                      {t('YouDoNotHaveReviewsYet')}
                    </Message>
                  }
                  { reviewData.length > 0 &&
                    <Feed>
                      {this.renderReviewList()}
                    </Feed>
                  }
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      );
    }
    // else
    return null;
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  currentBook: state.book.currentBook,
  readingProgress: state.report.readingProgress,
  fetchingReadingData: state.report.fetchingReadingData,
  invited: state.report.invited,
  started: state.report.started,
  midPoint: state.report.midPoint,
  finished: state.report.finished,
  sessions: state.report.sessions,
  surveyData: state.report.surveyData,
  readerFilters: state.report.readerFilters,
  reviewData: state.report.reviewData,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchReviewData,
  changePage: (newPage) => push(newPage)
}, dispatch)

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewDataReport)))
