import React, {Component} from 'react';
import {Dropdown, Icon, Popup} from 'semantic-ui-react';

import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {push} from 'connected-react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {clearCommentList, removeFilteredReaders} from '../../modules/comment';
import {setCurrentContentVersion, createNewVersion} from '../../modules/book';

import accessUtil from '../../utils/accessUtil';

export class VersionSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    const {match, currentBook} = this.props;
    if (match) {
      const {versionNumber} = match.params;
      const prevVersionNumber =
        prevProps.match &&
        prevProps.match.params &&
        prevProps.match.params.versionNumber;
      if (versionNumber && prevVersionNumber) {
        const currentContentVersion =
          currentBook &&
          currentBook.content &&
          currentBook.content.find(
            contentVersion =>
              parseInt(contentVersion.versionNumber) === parseInt(versionNumber)
          );
        if (currentContentVersion) {
          this.props.setCurrentContentVersion(currentContentVersion);
        }
      }
    }
  }

  getContentVersion = () => {
    const {
      currentBook,
      t,
      currentContentVersion,
      pointing = 'right'
    } = this.props;
    const hasContent = currentBook.content && currentBook.content.length > 0;
    if (!hasContent) {
      return <span style={{color: 'lightgray'}}>(v1)</span>;
    }
    const contentVersionOptions = currentBook.content.map((content, index) => ({
      key: content._id,
      value: content._id,
      onClick: () => {
        const newPage = `/books/${currentBook._id}/content/v${content.versionNumber}`;
        this.props.changePage(newPage);
      },
      text: `v${content.versionNumber}`
    }));

    const lastVersion = currentBook.content[currentBook.content.length - 1];
    const lastVersionHasParts =
      lastVersion.parts && lastVersion.parts.length > 0;

    const addNewVersionButton = accessUtil.isAllowed(currentBook.access, [
      'edit'
    ]) ? (
      <span>
        <Icon name='add' />
        {t('NewVersion')}
      </span>
    ) : null;

    contentVersionOptions.push({
      key: -1,
      value: -1,
      disabled: !lastVersionHasParts,
      content: lastVersionHasParts ? (
        addNewVersionButton
      ) : (
        <Popup
          on='hover'
          trigger={addNewVersionButton}
          content={t('AllVersionsNeedContent')}
        />
      ),
      onClick: lastVersionHasParts ? this.addNewVersion : null
    });
    return (
      <Dropdown
        inline
        pointing={pointing}
        options={contentVersionOptions}
        value={currentContentVersion ? currentContentVersion._id : null}
      />
    );
  };

  addNewVersion = event => {
    event.preventDefault();
    event.stopPropagation();
    const {idToken, currentBook} = this.props;
    this.props.createNewVersion(
      idToken,
      currentBook._id,
      [],
      newBookVersion => {
        this.props.history.replace(
          `/books/${currentBook._id}/content/v${newBookVersion.content.length}`
        );
      }
    );
  };

  render() {
    return <span>{this.getContentVersion()}</span>;
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  userProfile: state.user.userProfile,
  idToken: state.user.idToken,
  currentBook: state.book.currentBook,
  commentsSidebarVisible: state.app.commentsSidebarVisible,
  currentContentVersion: state.book.currentContentVersion,
  filteredReaders: state.comment.filteredReaders
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearCommentList,
      setCurrentContentVersion,
      createNewVersion,
      removeFilteredReaders,
      changePage: newPage => push(newPage)
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(VersionSelector))
);
