import React from 'react';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import gaTracker from 'src/utils/gaTracker';
import { useHostAppProxy } from 'src/contexts/HostAppProxyContext';

// stateless component
const UpgradeToUnlock = () => {
  const {
    settings: { showPaymentInfo },
  } = useHostAppProxy();
  const { t } = useTranslation();
  return showPaymentInfo ? (
    <Link to={'/products'} style={{ color: 'orange' }}>
      <em>
        {t('UpgradeToUnlock')} <Icon name="lock" />
      </em>
    </Link>
  ) : (
    <span style={{ color: 'orange' }}>
      <em>
        {t('ThisIsAPremiumFeature')} <Icon name="lock" />
      </em>
    </span>
  );
};
export default UpgradeToUnlock;
