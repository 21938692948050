import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Modal, Form, Button, Divider
} from 'semantic-ui-react';
import {
  EditorState, RichUtils, convertToRaw
} from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import draftToHtml from 'draftjs-to-html';
import 'draft-js/dist/Draft.css';
import moment from 'moment';

// i18n
import { withTranslation } from 'react-i18next';
// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Avatar from '../../../components/avatar/avatar';
import {
  broadcastMessage
} from '../../../modules/message';

import { authUtil } from '../../../utils';

export class BroadcastMessage extends Component {
  // lifecycle methods
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      selectedReaders: []
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { readers } = nextProps;
    return {
      ...prevState,
      selectedReaders: readers.filter(reader => reader.selected)
    };
  }

  onEditorStateChange = (editorState) => {
    const plainTextMessage = editorState.getCurrentContent().getPlainText();
    this.setState({ editorState, plainTextMessage });
  };

  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onEditorStateChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  handleSubjectChange = (event, data) => {
    this.setState({
      subject: data.value
    });
  }

  handleMessageTitleChange = (event, data) => {
    this.setState({
      title: data.value
    });
  }

  cannotSend = () => {
    const { subject, plainTextMessage } = this.state;
    return !subject || subject.length <= 0 || !plainTextMessage || plainTextMessage.length <= 0;
  }

  sendMessage = async () => {
    const { currentBook } = this.props;
    const {
      subject, title, selectedReaders, editorState
    } = this.state;
    const plainTextMessage = editorState.getCurrentContent().getPlainText();
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlMessage = draftToHtml(
      rawContentState
    );

    const idToken = await authUtil.getFreshIdToken();
    this.props.broadcastMessage({
      book: currentBook._id,
      idToken,
      recipients: selectedReaders.map(reader => reader.user._id),
      title,
      subject,
      text: plainTextMessage,
      html: htmlMessage
    }, () => {
      this.setState({
        subject: '',
        title: '',
        selectedReaders: [],
        editorState: EditorState.createEmpty(),
        open: false
      });
    });
  }

  render() {
    const {
      t, readOnly, userProfile, sendingBroadcast
    } = this.props;
    const {
      open, subject, title, editorState, selectedReaders
    } = this.state;
    return (
      <Modal
        trigger={(
          <Button
            icon='mail'
            compact
            content={(
              <span>
                {t('NewMessage')}
                {' '}
                (
                {t('beta')}
                )
              </span>
            )}
            disabled={readOnly}
            onClick={() => this.setState({ open: true })}
          />
        )}
        open={open}
        closeIcon
        onClose={() => { this.setState({ open: false }); }}
      >
        <Modal.Content scrolling>
          <Form>
            <Form.Field>
              <Form.Input
                size='large'
                placeholder={t('Title')}
                transparent
                value={title}
                onChange={this.handleMessageTitleChange}
              />
            </Form.Field>
            <Form.Field style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <span style={{ marginRight: 5 }}>
                {t('MessageTo')}
                :
              </span>
              {
                selectedReaders && selectedReaders.map((reader, index) => (
                  <div style={{ width: 25, height: 25 }} key={reader._id}>
                    <Avatar
                      key={reader._id}
                      diameter={25}
                      user={reader.user && reader.user}
                      name={reader.user && reader.user.displayName}
                      style={{ marginLeft: index > 0 ? -2 : 0 }}
                    />
                  </div>
                ))
              }
            </Form.Field>
            <div>
              <Form.Field
                style={{
                  paddingBottom: '0.5em',
                  borderBottom: '1px solid lightgrey'
                }}
              >
                <Form.Input
                  placeholder={t('MessageSubject')}
                  transparent
                  value={subject}
                  onChange={this.handleSubjectChange}
                />
              </Form.Field>
              <Form.Field
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingBottom: '0.5em',
                  borderBottom: '1px solid lightgrey'
                }}
              >
                <span style={{ flex: 1, fontWeight: 'bold', color: 'green' }}>
                  {userProfile && userProfile.displayName}
                </span>
                <span style={{ marginLeft: 'auto' }}>
                  {moment().format('ll')}
                </span>
              </Form.Field>
              <Form.Field>
                <Editor
                  key='editor'
                  editorState={editorState}
                  onChange={this.onEditorStateChange}
                  placeholder={t('MessageBody')}
                  handleKeyCommand={this.handleKeyCommand}
                />
              </Form.Field>
            </div>
            <Form.Field>
              <Form.Button
                floated='right'
                icon='send'
                onClick={this.sendMessage}
                disabled={this.cannotSend()}
                content={t('SendMessage')}
                loading={sendingBroadcast}
              />
            </Form.Field>
            <Divider hidden clearing />
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  userProfile: state.user.userProfile,
  sendingBroadcast: state.message.sendingBroadcast,
  readers: state.reader.readers,
  currentBook: state.book.currentBook
});

const mapDispatchToProps = dispatch => bindActionCreators({
  broadcastMessage,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(BroadcastMessage)));
