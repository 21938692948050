import React, { Component } from 'react';
import {
  Table, Icon, Header
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';

// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  fetchReaderBonusList
} from '../../modules/readerBonus';

export class ReaderBonusOverview extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { userProfile } = this.props;
    if (userProfile) {
      this.fetchReferrals(userProfile.account && userProfile.account._id);
    }
  }

  fetchReferrals = (accountId) => {
    const { idToken } = this.props;
    this.props.fetchReaderBonusList(idToken, accountId);
  }

  render() {
    const {
      userProfile, user, t, readerBonusList
    } = this.props;
    let readerLimit = userProfile.maxReadersPerBook;
    if (readerLimit === -1) {
      // reader limit should be unlimited
      readerLimit = 'max';
    }
    if (user !== undefined && userProfile !== undefined) {
      return (
        <React.Fragment>
          <Header key='header' size='small' content={t('RaiseYourReaderLimit', { x: readerLimit })} />
          <Table verticalAlign='middle' key='table' singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t('Type')}</Table.HeaderCell>
                <Table.HeaderCell />
                <Table.HeaderCell>{t('Status')}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                readerBonusList && readerBonusList.map((bonusEntry, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{t(`referral-type_${bonusEntry.type}`)}</Table.Cell>
                    <Table.Cell>{bonusEntry.description || t('Unnamed')}</Table.Cell>
                    <Table.Cell>
                      {
                        bonusEntry.type === 'READ_AND_REVIEW' && (
                          <span>
                            {`${parseFloat(bonusEntry.status * 100, 10).toFixed(2)}%`}
                            {
                              bonusEntry.status === 1 && (
                                <Icon name='check' color='green' style={{ float: 'right' }} />
                              )
                            }
                          </span>
                        )
                      }
                      {
                        ['REFERRAL', 'SIGNUP_BONUS'].includes(bonusEntry.type) && (
                          <span>
                            {t(bonusEntry.status)}
                            {
                              bonusEntry.status === 'CONFIRMED' && (
                                <Icon name='check' color='green' style={{ float: 'right' }} />
                              )
                            }
                          </span>
                        )
                      }
                    </Table.Cell>
                  </Table.Row>
                ))
              }
            </Table.Body>
          </Table>
          <span style={{ fontSize: '0.8em' }}>
            {t('ReadAndReviewBonusInfo')}
          </span>
        </React.Fragment>
      );
    }
    return null;
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  readerBonusList: state.readerBonus.readerBonusList
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchReaderBonusList
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ReaderBonusOverview)));
