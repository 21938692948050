const isCapitalized = str => {
  // Remove non-alphabetical characters
  str = str.replace(/[^a-zA-Z]/g, '');
  // Check if the remaining characters are capitalized
  return str.length > 0 && str === str.toUpperCase();
};

const capitalizeFirstLetter = str => {
  return str !== undefined ? str.charAt(0).toUpperCase() + str.slice(1) : '';
};

export default {
  capitalizeFirstLetter,
  isCapitalized
};
