import React from 'react';
import '../../App.css';

// i18n
import {withTranslation} from 'react-i18next';

// Routes
import {withRouter, Switch, Route} from 'react-router-dom';

import styled from 'styled-components';
import {Menu} from 'semantic-ui-react';

import AppMenu from './appMenu';
import BookMenu from './bookMenu';
import BookContentMenu from './bookContentMenu';
import {MediaQuery} from '../../constants/size';

const StyledMenu = styled(Menu)`
  ${MediaQuery.phone} {
    height: 100vh;
  }
  ${MediaQuery.tablet} {
    height: 100%;
  }
`;

const ApplicationMenuContainer = ({}) => {
  return (
    <StyledMenu inverted vertical compact>
      <Switch key='submenu'>
        <Route path='/books' component={BookMenu} />
        <Route path='/read' component={BookContentMenu} />
        <Route component={AppMenu} />
      </Switch>
    </StyledMenu>
  );
};

export default withTranslation()(withRouter(ApplicationMenuContainer));
