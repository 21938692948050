import React, { useContext, useEffect, useState, useRef, createContext } from 'react';
import { useHistory, useLocation } from 'react-router';

export const SAVE_STATE = {
  save: 'save',
  saving: 'saving',
  saved: 'saved'
}

const AUTO_SAVE_DELAY_MS = 3000;

const AutoSaveContext = createContext();

export const AutoSaveProvider = ({ children }) => {
  const [saveFn, setSaveFn] = useState(undefined);
  const [saveState, setSaveState] = useState(undefined);
  const autoSaveTimeout = useRef(null);

  useEffect(() => {
    if (saveFn) {
      setSaveState(SAVE_STATE.save);
      clearTimeout(autoSaveTimeout.current);
      autoSaveTimeout.current = setTimeout(function () {
        save();
      }, AUTO_SAVE_DELAY_MS)
    }
  }, [saveFn]);

  const resetAutoSave = () => {
    setSaveFn(undefined);
    setSaveState(SAVE_STATE.saved);
  }

  const save = async () => {
    if (saveFn) {
      try {
        setSaveState(SAVE_STATE.saving);
        await saveFn();
        setSaveFn(undefined);
        setSaveState(SAVE_STATE.saved);
      } catch (error) {
        console.error('Failed to save', error);
        setSaveState(SAVE_STATE.save);
      }
    }
  }

  const providerValue = {
    saveFn,
    saveState,
    save,

    setSaveFn,
    setSaveState,
    resetAutoSave,
  };

  return (
    <AutoSaveContext.Provider value={providerValue}>
      {children}
    </AutoSaveContext.Provider>
  );
};

const useAutoSaveContext = () => {
  return useContext(AutoSaveContext);
};

export default useAutoSaveContext;
