import React from 'react';

import { Message } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

// stateless component
const CurrentBetaIsFullMessage = ({ t }) => (
  (
    <Message color='orange'>
      {t('CurrentBetaIsFullMessage')}
    </Message>
  )
);

export default withTranslation()(CurrentBetaIsFullMessage);
