import { useEffect } from 'react';

// used to identify clicks outside a specific component, e.g. to trigger the closing of a modal
const usePreventBrowserSave = () => {
  useEffect(() => {
    /**
     * Prevents default onSave if CMD + S (Mac) or Ctrl + S (Win) is pressed
     * @param {event} event 
     */
    const doNothingFunction = (event) => {
      // prevent default if save
      if (event.keyCode === 83 && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
      }
    }
    
    document.addEventListener('keydown', doNothingFunction, false);

    return () => {
      document.removeEventListener('keydown', doNothingFunction);
    };
  });
};

export default usePreventBrowserSave;