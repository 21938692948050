import { toast } from 'react-toastify';
import { ReaderInvitation } from 'src/containers/readers/bulkInvitationForm';
import { uxAnalyticsUtil } from '../utils';
import i18n from '../utils/i18n/i18n';

export const UPDATE_READER = 'reader/UPDATE_READER';
export const UPDATE_READERS = 'reader/UPDATE_READERS';
export const UPDATE_READER_LIST = 'reader/UPDATE_READER_LIST';
export const UPDATE_JOIN_REQUEST = 'reader/UPDATE_JOIN_REQUEST';
export const UPDATE_JOIN_REQUEST_LIST = 'reader/UPDATE_JOIN_REQUEST_LIST';
export const REMOVE_FROM_JOIN_REQUESTS = 'reader/REMOVE_FROM_JOIN_REQUESTS';
export const REMOVE_READER = 'reader/REMOVE_READER';
export const ADD_TO_READER_LIST = 'reader/ADD_TO_READER_LIST';
export const UPDATING_READER = 'reader/UPDATING_READER';
export const DONE_UPDATING_READER = 'reader/DONE_UPDATING_READER';
export const SORT_READER_LIST = 'reader/SORT_READER_LIST';
export const TOGGLE_READER_SELECTION = 'reader/TOGGLE_READER_SELECTION';
export const SWITCH_CURRENT_READER = 'reader/SWITCH_CURRENT_READER';
export const SWITCH_CURRENT_READING_SESSIONS =
  'reader/SWITCH_CURRENT_READING_SESSIONS';
export const UPDATE_READING_SESSION_EVENTS =
  'reader/UPDATE_READING_SESSION_EVENTS';
export const CLEAR_READING_SESSION_EVENTS =
  'reader/CLEAR_READING_SESSION_EVENTS';
export const UPDATE_READER_STATISTICS = 'reader/UPDATE_READER_STATISTICS';
export const LOADING_INVITATIONS = 'reader/LOADING_INVITATIONS';
export const RECEIVED_INVITATIONS = 'reader/RECEIVED_INVITATIONS';
export const REMOVE_INVITATION = 'reader/REMOVE_INVITATION';

export const readingSessionEventData = {
  0: {
    name: 'Opened Book',
    icon: 'circle outline',
  },
  1: {
    name: 'Closed Book',
    icon: 'circle',
  },
  2: {
    name: 'Time Spent',
    icon: 'clock',
  },
  3: {
    name: 'Started',
    icon: 'play circle outline',
  },
  4: {
    name: 'Reached 100% of',
    icon: 'stop circle outline',
  },
  5: {
    name: 'Completed Book',
    icon: 'stop circle',
  },
  6: {
    name: 'Started Book',
    icon: 'play circle',
  },
  7: {
    name: 'Reached 25% of',
    icon: 'percent',
  },
  8: {
    name: 'Reached 50% of',
    icon: 'percent',
  },
  9: {
    name: 'Reached 75% of',
    icon: 'percent',
  },
  100: {
    name: 'Scrolled up',
    icon: 'hand pointer',
  },
  101: {
    name: 'Scrolled down',
    icon: 'hand pointer',
  },
};

const getSortValue = (reader, column) => {
  if ('lastSeenAt' === column) {
    if (reader.readingData && reader.readingData.lastSeenAtDate) {
      return new Date(reader.readingData.lastSeenAtDate);
    } else {
      return new Date(-1);
    }
  } else if ('firstSeenAt' === column) {
    if (reader.readingData && reader.readingData.firstSeenAtDate) {
      return new Date(reader.readingData.firstSeenAtDate);
    } else {
      return new Date(-1);
    }
  } else if ('partIndex' === column) {
    if (reader.readingData) {
      return reader.readingData.partIndex || -1;
    }
  } else {
    var value = reader[column];
    if (typeof value === 'string') {
      value = value.toLowerCase();
    }
    return value;
  }
};

export const initialState = {
  readers: [],
  joinRequests: [],
  invitations: [],
  loadingInvtations: false,
  updatingReader: false,
  sorting: { column: 'lastSeenAt', direction: 'descending' },
  currentReader: undefined,
  readingSessionEventData,
  readingSessionEvents: {},
  readerStatistics: {},
};

// reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_READER:
      const updatedReader = action.updatedReader;
      const readers = [...state.readers];
      // find the reader's position in the readers list
      const readerIndex = readers.findIndex(reader => {
        return reader._id === updatedReader._id;
      });
      if (readerIndex >= 0) {
        // replace the old entry with the updated one
        readers.splice(readerIndex, 1, updatedReader);
      }
      return {
        ...state,
        readers,
      };
    case UPDATE_READERS: {
      return {
        ...state,
        readers: action.readers,
      };
    }
    case REMOVE_READER:
      const readerId = action.readerId;
      const readerList = [...state.readers];
      // find the reader's position in the readers list
      const index = readerList.findIndex(reader => {
        return reader._id === readerId;
      });
      if (index >= 0) {
        // splice out the entry to be removed
        readerList.splice(index, 1);
      }
      return {
        ...state,
        readers: readerList,
      };
    case ADD_TO_READER_LIST:
      let newReadersList = [...state.readers];
      newReadersList.push(action.newReader);
      return {
        ...state,
        readers: newReadersList,
      };
    case UPDATE_READER_LIST:
      return {
        ...state,
        readers: action.readers,
      };
    case UPDATING_READER:
      return {
        ...state,
        updatingReader: action.readerId,
      };
    case DONE_UPDATING_READER:
      return {
        ...state,
        updatingReader: undefined,
      };
    case SORT_READER_LIST:
      const sortedReaderList = [...state.readers];
      sortedReaderList.sort(function (a, b) {
        var aVal = getSortValue(a, action.column);
        var bVal = getSortValue(b, action.column);
        // return action.direction === 'ascending' ? aVal - bVal : bVal - aVal;
        if (aVal < bVal) {
          return action.direction === 'ascending' ? -1 : 1;
        }
        if (aVal > bVal) {
          return action.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
      return {
        ...state,
        readers: sortedReaderList,
        sorting: {
          column: action.column,
          direction: action.direction,
        },
      };
    case TOGGLE_READER_SELECTION:
      return {
        ...state,
        readers: [...state.readers].map(reader => {
          if (reader._id === action.readerId) {
            // toggle the selection for the specified reader id
            return {
              ...reader,
              selected: !reader.selected,
            };
          }
          // for all others, simply return the same reader object
          return {
            ...reader,
          };
        }),
      };
    case SWITCH_CURRENT_READER:
      return {
        ...state,
        currentReader: action.newReader,
      };

    case SWITCH_CURRENT_READING_SESSIONS:
      return {
        ...state,
        currentReadingSessions: action.newReadingSessions,
      };
    case UPDATE_READING_SESSION_EVENTS:
      var updatedReadingSessionEvents = { ...state.readingSessionEvents };
      updatedReadingSessionEvents[action.sessionId] = action.events;
      return {
        ...state,
        readingSessionEvents: updatedReadingSessionEvents,
      };
    case CLEAR_READING_SESSION_EVENTS:
      return {
        ...state,
        readingSessionEvents: {},
      };
    case UPDATE_JOIN_REQUEST:
      const updatedJoinRequest = action.updatedJoinRequest;
      const joinRequests = [...state.joinRequests];
      // find the reader's position in the readers list
      const jrIndex = joinRequests.findIndex(joinRequest => {
        return joinRequest._id === updatedJoinRequest._id;
      });
      if (jrIndex >= 0) {
        // replace the old entry with the updated one
        joinRequests[jrIndex] = {
          ...joinRequests[jrIndex],
          ...updatedJoinRequest,
        };
      }
      return {
        ...state,
        joinRequests: joinRequests,
      };
    case UPDATE_JOIN_REQUEST_LIST:
      return {
        ...state,
        joinRequests: action.joinRequests,
      };
    case REMOVE_FROM_JOIN_REQUESTS:
      var updatedJoinRequestsList = [...state.joinRequests].filter(
        joinRequest => {
          return joinRequest._id !== action.joinRequestId;
        }
      );
      return {
        ...state,
        joinRequests: updatedJoinRequestsList,
      };
    case UPDATE_READER_STATISTICS:
      return {
        ...state,
        readerStatistics: action.readerStatistics,
      };
    case LOADING_INVITATIONS:
      return {
        ...state,
        loadingInvtations: action.value,
      };
    case RECEIVED_INVITATIONS:
      return {
        ...state,
        invitations: action.invitations,
      };
    case REMOVE_INVITATION: {
      const updatedInvitations = [...state.invitations];
      // find the invitations position in the invitation list
      const invitationIndex = updatedInvitations.findIndex(
        invitation => invitation._id === action.invitationId
      );
      if (invitationIndex >= 0) {
        // splice out the entry to be removed
        updatedInvitations.splice(invitationIndex, 1);
      }
      return {
        ...state,
        invitations: updatedInvitations,
      };
    }
    default:
      return state;
  }
};

export const createReader = (idToken, readerData, callback) => {
  return dispatch => {
    dispatch({
      type: UPDATING_READER,
      readerId: 'new',
    });
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${readerData.bookId}/readers`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken,
        },
        body: JSON.stringify(readerData),
      }
    )
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        return res.json().then(json => {
          const error = new Error(json.msg || res.statusText);
          error.response = res;
          throw error;
        });
      })
      .then(response => {
        uxAnalyticsUtil.trackEvent({
          category: 'Reader Management',
          action: 'invited-reader',
        });
        dispatch({
          type: ADD_TO_READER_LIST,
          newReader: response,
        });
        if (callback) {
          callback(true);
        }
      })
      .catch(err => {
        if (callback) {
          callback(false);
        }
        toast.error(err.toString());
        console.log('err:', err);
      })
      .finally(() => {
        dispatch({
          type: DONE_UPDATING_READER,
        });
      });
  };
};

// actions
export const updateReader = (idToken, bookId, readerId, data) => {
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/readers/${readerId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken,
        },
        body: JSON.stringify(data),
      }
    )
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        return res.json().then(json => {
          const error = new Error(json.msg || res.statusText);
          error.response = res;
          throw error;
        });
      })
      .then(response => {
        return dispatch({
          type: UPDATE_READER,
          updatedReader: response,
        });
      })
      .catch(err => {
        toast.error(i18n.t('failedToUpdateReader'));
        console.error(err);
      });
  };
};

export const bulkAddReaders = (
  idToken: string,
  bookId: string,
  readers: ReaderInvitation[]
) => {
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/readers`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken,
        },
        body: JSON.stringify({
          action: 'bulk-add-readers',
          bulkInvitations: readers,
        }),
      }
    )
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        return res.json().then(json => {
          const error = new Error(json.msg || res.statusText);
          error.response = res;
          throw error;
        });
      })
      .then(response => {
        return dispatch({
          type: UPDATE_READERS,
          readers: response,
        });
      })
      .catch(err => {
        toast.error(i18n.t('failedToUpdateReader'));
        console.error(err);
      });
  };
};

// actions
export const sendInvitation = (idToken, reader) => {
  return dispatch => {
    dispatch({
      type: UPDATING_READER,
      readerId: reader._id,
    });
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${reader.book}/readers/${reader._id}/actions/send-invitation`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken,
        },
      }
    )
      .then(res => res.json())
      .then(reader => {
        uxAnalyticsUtil.trackEvent({
          category: 'Reader Management',
          action: 're-sent-invitation',
        });
        dispatch({
          type: DONE_UPDATING_READER,
        });
        return dispatch({
          type: UPDATE_READER,
          updatedReader: reader,
        });
      })
      .catch(err => {
        console.error(err);
        dispatch({
          type: DONE_UPDATING_READER,
        });
      });
  };
};

export const sendInvitationReminder = (idToken, reader) => {
  return dispatch => {
    dispatch({
      type: UPDATE_READER,
      updatedReader: {
        ...reader,
        updating: true,
      },
    });
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${reader.book}/readers/${reader._id}/actions/send-invitation-reminder`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken,
        },
      }
    )
      .then(res => res.json())
      .then(updatedReader => {
        uxAnalyticsUtil.trackEvent({
          category: 'Reader Management',
          action: 'sent-invitation-reminder',
        });
        return dispatch({
          type: UPDATE_READER,
          updatedReader: {
            ...reader,
            updating: false,
            invitation: {
              ...updatedReader.invitation,
            },
          },
        });
      })
      .catch(err => {
        console.error(err);
        return dispatch({
          type: UPDATE_READER,
          updatedReader: {
            ...reader,
            updating: false,
          },
        });
      });
  };
};

export const listInvitations = idToken => async dispatch => {
  dispatch({
    type: LOADING_INVITATIONS,
    value: true,
  });
  fetch(
    `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/invitations`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken,
      },
    }
  )
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw Error(`Request listInvitations rejected with status ${res.status}`);
    })
    .then(invitations =>
      dispatch({
        type: RECEIVED_INVITATIONS,
        invitations,
      })
    )
    .catch(err => {
      console.error(err);
    })
    .finally(() => {
      dispatch({
        type: LOADING_INVITATIONS,
        value: false,
      });
    });
};

export const removeInvitation = invitationId => dispatch => {
  dispatch({
    type: REMOVE_INVITATION,
    invitationId,
  });
};

// actions
export const deleteReader = (idToken, reader) => dispatch => {
  fetch(
    `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${reader.book}/readers/${reader._id}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken,
      },
    }
  )
    .then(res => {
      if (!res.ok) {
        throw new Error(res.error);
      } else {
        return res.json();
      }
    })
    .then(readerResponse => {
      const deletedReader = readerResponse.find(
        entry => reader._id === entry._id
      );
      if (!deletedReader) {
        throw new Error('failed to delete reader');
      }
      uxAnalyticsUtil.trackEvent({
        category: 'Reader Management',
        action: 'deleted-reader',
      });
      return dispatch({
        type: REMOVE_READER,
        readerId: deletedReader._id,
      });
    })
    .catch(err => {
      console.error(err);
    });
};

// actions
export const fetchReaders = (idToken, bookId, query) => {
  let queryString = '';
  if (query) {
    queryString += query.map(
      (parameter, index) =>
        `${index === 0 ? '?' : '&'}${parameter.key}=${parameter.value}`
    );
  }
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/readers${queryString}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken,
        },
      }
    )
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        return res.json().then(json => {
          let error = new Error(json.msg || res.statusText);
          error.response = res;
          throw error;
        });
      })
      .then(readers => {
        return dispatch({
          type: UPDATE_READER_LIST,
          readers,
        });
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export const fetchReaderStatistics = (
  idToken: string,
  query?: { key: string; value: any }[]
) => {
  let queryString = '';
  if (query) {
    queryString += query.map(
      (parameter, index) =>
        `${index === 0 ? '?' : '&'}${parameter.key}=${parameter.value}`
    );
  }
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/reports/statistics/readers${queryString}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken,
        },
      }
    )
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        return res.json().then(json => {
          const error = new Error(json.msg || res.statusText);
          error.response = res;
          throw error;
        });
      })
      .then(readerStatistics =>
        dispatch({
          type: UPDATE_READER_STATISTICS,
          readerStatistics,
        })
      )
      .catch(err => console.log(err));
  };
};

// actions
export const sortReaderList = (column, direction) => {
  return dispatch => {
    dispatch({
      type: SORT_READER_LIST,
      column,
      direction,
    });
  };
};

export const toggleReaderSelection = readerId => dispatch => {
  dispatch({
    type: TOGGLE_READER_SELECTION,
    readerId,
  });
};

export const addToReaderList = reader => {
  return dispatch => {
    return dispatch({
      type: ADD_TO_READER_LIST,
      newReader: reader,
    });
  };
};

export const fetchCurrentReader = (idToken, data) => {
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${data.bookId}/readers/${data.readerId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken,
        },
      }
    )
      .then(res => res.json())
      .then(reader => {
        if (reader !== undefined) {
          return dispatch({
            type: SWITCH_CURRENT_READER,
            newReader: reader,
          });
        }
      });
  };
};

export const clearCurrentReader = () => {
  return dispatch => {
    return dispatch({
      type: SWITCH_CURRENT_READER,
      newReader: undefined,
    });
  };
};

export const fetchCurrentReadingSessions = (idToken, data) => {
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${data.bookId}/readers/${data.readerId}/reading-sessions`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken,
        },
      }
    )
      .then(res => res.json())
      .then(readingSessions => {
        if (readingSessions) {
          return dispatch({
            type: SWITCH_CURRENT_READING_SESSIONS,
            newReadingSessions: readingSessions,
          });
        }
      });
  };
};

export const clearCurrentReadingSessions = () => {
  return dispatch => {
    return dispatch({
      type: SWITCH_CURRENT_READING_SESSIONS,
      newReadingSessions: undefined,
    });
  };
};

export const fetchCurrentReadingSessionEvents = (idToken, data) => {
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${data.bookId}/readers/${data.readerId}/reading-sessions/${data.sessionId}/events`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken,
        },
      }
    )
      .then(res => res.json())
      .then(events => {
        if (events) {
          return dispatch({
            type: UPDATE_READING_SESSION_EVENTS,
            sessionId: data.sessionId,
            events,
          });
        }
      });
  };
};

export const clearCurrentReadingSessionEvents = sessionId => {
  return dispatch => {
    return dispatch({
      type: UPDATE_READING_SESSION_EVENTS,
      sessionId,
      events: [],
    });
  };
};

export const resetReaders = () => {
  return dispatch => {
    dispatch({
      type: UPDATE_READER_LIST,
      readers: [],
    });
    dispatch({
      type: CLEAR_READING_SESSION_EVENTS,
    });
  };
};

export const fetchJoinRequests = (idToken, bookId) => {
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/join-requests`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken,
        },
      }
    )
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        return res.json().then(json => {
          var error = new Error(json.msg || res.statusText);
          error.response = res;
          throw error;
        });
      })
      .then(joinRequests =>
        dispatch({
          type: UPDATE_JOIN_REQUEST_LIST,
          joinRequests,
        })
      )
      .catch(err => console.log(err));
  };
};

export const approveJoinRequest = (idToken, joinRequest, options = {}) => {
  console.log('approveJoinRequest', joinRequest, options);
  return dispatch => {
    dispatch({
      type: UPDATING_READER,
      readerId: joinRequest._id,
    });
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${joinRequest.book}/join-requests/${joinRequest._id}/approve`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken,
        },
        body: JSON.stringify(options),
      }
    )
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        return res.json().then(json => {
          let error = new Error(json.msg || res.statusText);
          error.response = res;
          throw error;
        });
      })
      .then(reader => {
        uxAnalyticsUtil.trackEvent({
          category: 'Reader Management',
          action: 'approved-join-request',
        });
        dispatch({
          type: REMOVE_FROM_JOIN_REQUESTS,
          joinRequestId: reader._id,
        });
        dispatch({
          type: UPDATE_READER,
          updatedReader: reader,
        });
        return;
      })
      .catch(err => console.log(err))
      .finally(() => {
        dispatch({
          type: UPDATING_READER,
          readerId: undefined,
        });
      });
  };
};

export const declineJoinRequest = (idToken, joinRequest) => {
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${joinRequest.book}/join-requests/${joinRequest._id}/decline`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken,
        },
      }
    )
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        return res.json().then(json => {
          var error = new Error(json.msg || res.statusText);
          error.response = res;
          throw error;
        });
      })
      .then(joinRequestId => {
        uxAnalyticsUtil.trackEvent({
          category: 'Reader Management',
          action: 'declined-join-request',
        });
        dispatch({
          type: REMOVE_FROM_JOIN_REQUESTS,
          joinRequestId,
        });
        dispatch({
          type: REMOVE_READER,
          readerId: joinRequestId,
        });
        return;
      })
      .catch(err => console.log(err));
  };
};

export const sendSurveyReminder = (idToken, joinRequest) => {
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${joinRequest.book}/join-requests/${joinRequest._id}/send-survey-reminder`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken,
        },
      }
    )
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        return res.json().then(json => {
          var error = new Error(json.msg || res.statusText);
          error.response = res;
          throw error;
        });
      })
      .then(updatedJoinRequest => {
        uxAnalyticsUtil.trackEvent({
          category: 'Reader Management',
          action: 'sent-survey-reminder',
        });
        dispatch({
          type: UPDATE_JOIN_REQUEST,
          updatedJoinRequest,
        });
        dispatch({
          type: UPDATE_READER,
          updatedReader: updatedJoinRequest,
        });
        return;
      })
      .catch(err => console.log(err));
  };
};

export const sendReadingReminder = (idToken, reader) => {
  return dispatch => {
    dispatch({
      type: UPDATE_READER,
      updatedReader: {
        ...reader,
        updating: true,
      },
    });
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${reader.book}/readers/${reader._id}/actions/send-reading-reminder`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken,
        },
      }
    )
      .then(res => {
        if (!res.ok) {
          throw new Error(res.error);
        } else {
          return res.json();
        }
      })
      .then(updatedReader => {
        uxAnalyticsUtil.trackEvent({
          category: 'Reader Management',
          action: 'sent-reading-reminder',
        });
        return dispatch({
          type: UPDATE_READER,
          updating: false,
          updatedReader: {
            ...reader,
            invitation: {
              ...updatedReader.invitation,
            },
          },
        });
      })
      .catch(err => {
        console.log(err);
        return dispatch({
          type: UPDATE_READER,
          updatedReader: {
            ...reader,
            updating: false,
          },
        });
      });
  };
};
