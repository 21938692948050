import React, { Component } from 'react';

import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList, Tooltip } from 'recharts';
import { List } from 'semantic-ui-react'

const colors = ['#FC9C04', '#FFBB28', '#5B5A54', '#000000', '#CCCCCC'];

class LinearScaleSurveyAnswersChart extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  parseData = (answers) => {
    const newData = [];
    const { options } = this.props;

    // create the placeholder for all bars
    for (var i = options.minValue; i <= options.maxValue; i++) {
      var newEntry = {
        value: 0,
        answer: i,
        readers: new Set()
      }
      // if we have labels, use them
      if (i === options.minValue && options.minLabel !== undefined && options.minLabel.length > 0) {
        newEntry.label = `${options.minLabel}`;
      } else if (i === options.maxValue && options.maxLabel !== undefined && options.maxLabel.length > 0) {
        newEntry.label = `${options.maxLabel}`;
      } else {
        newEntry.label = '';
      }
      newData.push(newEntry)
    }

    answers.forEach((answer) => {
      const entryIndex = newData.findIndex(entry => entry.answer === answer.answer);
      if (entryIndex >= 0) {
        newData[entryIndex].value += 1;
        newData[entryIndex].readers.add(answer.reader._id);
      } else {
        let newEntry = {
          answer: answer.answer,
          value: 1,
          readers: new Set()
        }
        newData.push(newEntry);
      }
    });
    return newData;
  }

  render() {
    const { answers } = this.props;
    if (!answers || answers.length === 0) {
      return (
        <List divided>
          <List.Item key='noanswer'>
            <List.Content>
              No answers yet.
            </List.Content>
          </List.Item>
        </List>
      )
    }
    const parsedData = this.parseData(answers);
    return (
      <ResponsiveContainer width={'100%'} height={250}>
        <BarChart data={parsedData}>
          <Bar dataKey='value' fill={colors[1]} onClick={answer => this.props.onFilter([...answer.readers])}>
            <LabelList dataKey='label' position='insideBottom' />
          </Bar>
          <XAxis dataKey='answer' />
          <YAxis />
          <Tooltip cursor={{ stroke: colors[0], strokeWidth: 1 }} />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default LinearScaleSurveyAnswersChart;
