import React, { Component } from 'react';
import queryString from 'query-string';
import {
  Input, Header, Button
} from 'semantic-ui-react';

// pop-ups
import { toast } from 'react-toastify';

// i18n
import { withTranslation } from 'react-i18next';

// sharing
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton
} from 'react-share';

// GA tracking
import uxAnalyticsUtil from '../../utils/uxAnalyticsUtil';
import copyToClipBoardUtil from '../../utils/copyToClipBoardUtil';

export class SharingWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddresses: ''
    };
  }

  // custom methods
  copyURLToClipBoard = () => {
    const { url, t, utmCampaign } = this.props;
    copyToClipBoardUtil.copyItemToClipBoard(`${url}?${queryString.stringify({ utm_campaign: utmCampaign, utm_source: 'link', utm_medium: 'button' })}`);
    toast.info(t('CopiedXToClipboard', { x: url }));
  }

  onClick = (source) => {
    const { utmCampaign } = this.props;
    uxAnalyticsUtil.trackEvent({
      category: 'Sharing',
      action: `clicked-${utmCampaign}`,
      label: source
    });
  }

  onEmailAddressesChanged = (event, data) => {
    this.setState({
      emailAddresses: data.value
    });
  }

  onEmailAddressButtonPress = (event) => {
    if (event.key === 'Enter') {
      this.sendEmailInvites();
    }
  }

  sendEmailInvites = async () => {
    const { emailSharingEndpoint, emailSuccessMessage, emailSuccessMethod } = this.props;
    const { emailAddresses } = this.state;
    if (emailAddresses) {
      this.setState({
        sendingEmails: true
      }, async () => {
        const { idToken, t } = this.props;
        fetch(emailSharingEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'br-token': idToken
          },
          body: JSON.stringify({
            emails: emailAddresses
          })
        })
          .then((res) => {
            if (!res.ok) {
              throw new Error(res.error);
            }
            return res.json();
          })
          .then((readerBonusList) => {
            this.setState({
              emailAddresses: ''
            }, () => {
              if (emailSuccessMessage) {
                toast(emailSuccessMessage)
              }
              if (emailSuccessMethod) {
                emailSuccessMethod(readerBonusList);
              }
            });
          })
          .catch((err) => {
            console.error(err);
            uxAnalyticsUtil.trackException({
              description: err.message,
              fatal: true
            });
            toast.error(t('SomethingWentWrong'));
          })
          .finally(() => {
            this.setState({
              sendingEmails: false
            });
          });
      });
    }
  }

  render() {
    const {
      url, message, body, shareTo, hashtags,
      header, buttonSize, infoText, disabled, utmCampaign, t
    } = this.props;
    const {
      emailAddresses, sendingEmails
    } = this.state;
    if (!!url && !!shareTo) {
      return (
        <div>
          {
            !!header
            && (
              <Header as='h4'>{ header }</Header>
            )
          }
          <div style={{ whiteSpace: 'nowrap' }}>
            {
              shareTo.includes('facebook')
              && (
                <div style={{ display: 'inline-block' }}>
                  <FacebookShareButton
                    url={`${url}?${queryString.stringify({ utm_campaign: utmCampaign, utm_source: 'facebook', utm_medium: 'button' })}`}
                    quote={`${message}: ${url}`}
                    hashtag='#betareaders'
                    disabled={!!disabled}
                  >
                    <Button icon='facebook' color='facebook' circular onClick={() => this.onClick('facebook')} size={buttonSize} disabled={!!disabled} />
                  </FacebookShareButton>
                </div>
              )
            }
            {
              shareTo.includes('twitter')
              && (
                <div style={{ display: 'inline-block' }}>
                  <TwitterShareButton
                    url={`${url}?${queryString.stringify({ utm_campaign: utmCampaign, utm_source: 'twitter', utm_medium: 'button' })}`}
                    title={message}
                    via='betareaderapp'
                    hashtags={hashtags}
                    disabled={!!disabled}
                  >
                    <Button icon='twitter' color='twitter' circular onClick={() => this.onClick('twitter')} size={buttonSize} disabled={!!disabled} />
                  </TwitterShareButton>
                </div>
              )
            }
            {
              shareTo.includes('email')
              && (
                <div style={{ display: 'inline-block' }}>
                  <EmailShareButton
                    url={`${url}?${queryString.stringify({ utm_campaign: utmCampaign, utm_source: 'email', utm_medium: 'button' })}`}
                    subject={message}
                    body={body}
                    disabled={!!disabled}
                  >
                    <Button icon='mail' circular onClick={() => this.onClick('email')} size={buttonSize} disabled={!!disabled} />
                  </EmailShareButton>
                </div>
              )
            }
            {
              shareTo.includes('clipboard')
              && (
                <div style={{ display: 'inline-block' }}>
                  <Button icon='linkify' circular onClick={() => { this.onClick('clipboard'); this.copyURLToClipBoard(); }} size={buttonSize} disabled={!!disabled} />
                </div>
              )
            }

          </div>
          {
            shareTo.includes('copy')
            && (
              <div style={{ marginTop: '1em' }}>
                <Input fluid value={url} action={<Button icon='clone' circular onClick={() => { this.onClick('copy'); this.copyURLToClipBoard(); }} disabled={!!disabled} />} />
              </div>
            )
          }
          {
            shareTo.includes('email-address')
            && (
              <div style={{ marginTop: '1em' }}>
                <Input
                  fluid
                  placeholder={t('Email')}
                  value={emailAddresses}
                  action={<Button icon='send' circular onClick={() => { this.onClick('send-email'); this.sendEmailInvites(); }} disabled={!!disabled || sendingEmails} loading={sendingEmails} />}
                  onChange={this.onEmailAddressesChanged}
                  onKeyDown={this.onEmailAddressButtonPress}
                  disabled={sendingEmails}
                />
              </div>
            )
          }
          {
            !!infoText
            && (
              <div style={{ marginTop: '0.5em', fontSize: '0.9em', opacity: 0.8 }}>
                {infoText}
              </div>
            )
          }
        </div>
      );
    }
    return null;
  }
}

export default withTranslation()(SharingWidget);
