import React, {useEffect} from 'react';
import {Header, Divider} from 'semantic-ui-react';
import {Media} from 'src/utils/Media';
import {useTranslation, withTranslation} from 'react-i18next';

import styled, {css} from 'styled-components';

// own components
import CommentsList from '../comment/commentsList';
import InlineCommentsList from '../comment/inlineCommentsList';
// import InlineCommentsBottomBar from '../comment/inlineCommentsBottomBar';
import EditChapter from './chapter/editChapter';
import EditSurvey from './survey/editSurvey';
import NewChapter from './chapter/newChapter';
import NewSurvey from './survey/newSurvey';
import VersionOverview from './versionOverview';

import {clearCommentList, removeFilteredReaders} from '../../modules/comment';
import {setCurrentContentVersion} from '../../modules/book';

import ReaderFilterLabel from './readerFilterLabel';
import {MediaQuery, DeviceMinWidths, MaxTextWidth} from '../../constants/size';
import {useParams} from 'react-router-dom';
import {toggleAppSidebar} from '../../modules/app';
import {useDispatch, useSelector} from 'react-redux';

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  ${MediaQuery.phone} {
    grid-template-columns: 1fr;
  }
  ${MediaQuery.tablet} {
    ${({sidebarVisible}) =>
      !sidebarVisible
        ? css`
            grid-template-columns: 1fr minmax(0, ${MaxTextWidth}px) 1fr;
          `
        : css`
            grid-template-columns: minmax(0, ${MaxTextWidth}px) 1fr;
          `}
  }
`;

const CenterContentWrapper = styled.div`
  padding-left: 2.2em;
  padding-right: 2.2em;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  ${({fullWidth}) =>
    fullWidth &&
    css`
      grid-column-start: 1;
      grid-column-end: 3;
    `}
`;

const SidebarCommentsWrapper = styled.div`
  ${MediaQuery.phone} {
    display: none;
  }
  ${MediaQuery.tablet} {
    display: inline-block;
    max-width: var(--sidebar-width);
    width: var(--sidebar-width);
  }
`;

const BottomBar = styled.div`
  padding: 0;
  position: absolute;
  bottom: 0;
  z-index: 2;
`;

const ChapterFeedbackWrapper = styled.div``;

export const EditPart = ({mode, kind}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentBook = useSelector(state => state.book.currentBook);
  const sidebarVisible = useSelector(state => state.app.sidebarVisible);
  const currentContentVersion = useSelector(
    state => state.book.currentContentVersion
  );
  const filteredReaders = useSelector(state => state.comment.filteredReaders);

  const {versionNumber, partId} = useParams();
  console.log({versionNumber, partId});
  useEffect(() => {
    return () => {
      dispatch(removeFilteredReaders());
      dispatch(clearCommentList());
    };
  }, [clearCommentList, removeFilteredReaders]);

  useEffect(() => {
    const currentContentVersion = (currentBook?.content ?? []).find(
      ver => parseInt(ver.versionNumber) === parseInt(versionNumber)
    );

    console.log('editPart.currentContentVersion', currentContentVersion);
    if (currentContentVersion) {
      dispatch(setCurrentContentVersion(currentContentVersion));
    }
  }, [versionNumber, partId, currentBook?.content]);

  if (!currentContentVersion) return null;
  const {parts} = currentContentVersion;

  const currentPart = parts.find(part => part._id === partId);
  console.log('editPart.currentPart', currentPart, partId, parts);
  const currentPartKind = currentPart?.kind ?? '';

  switch (mode) {
    case 'Create':
      if (kind === 'Chapter') {
        return (
          <Wrapper>
            {
              // add padding div when sidebar is not visible in order to center text content in grid
              // (this div takes the left-most column)
              !sidebarVisible && <div />
            }
            <NewChapter key='new-chapter' />
          </Wrapper>
        );
      } else if (kind === 'Survey') {
        return <NewSurvey key='new-survey' />;
      } else {
        return null;
      }
    default: {
      console.log('editPart.default', {currentPart, currentPartKind});
      return (
        <Wrapper sidebarVisible={sidebarVisible}>
          {
            // add padding div when sidebar is not visible in order to center text content in grid
            // (this div takes the left-most column)
            !sidebarVisible && <div />
          }
          {mode !== 'Create' && currentPartKind === 'Chapter' && (
            <CenterContentWrapper>
              <EditChapter key='edit-chapter' chapterId={currentPart._id} />
              <ChapterFeedbackWrapper key='chapter-feedback'>
                <Divider key='divider' horizontal />
                <Header key='header' content={t('Feedback')} />
                <CommentsList
                  key='comments'
                  sort='desc'
                  filter='ChapterComment'
                  disableLeavingComments
                  enableLeavingReplies
                />
              </ChapterFeedbackWrapper>
              <Media at='tablet'>
                {(className, renderChildren) =>
                  renderChildren && (
                    <BottomBar>
                      <InlineCommentsList
                        key='comments'
                        disableLeavingComments
                        positioning='bottom'
                        enableFilters
                        showOnly={{
                          type: 'InlineComment',
                          status: ['TODO', 'DOING']
                        }}
                      />
                    </BottomBar>
                  )
                }
              </Media>
            </CenterContentWrapper>
          )}
          {mode !== 'Create' && currentPartKind === 'Survey' && (
            <CenterContentWrapper fullWidth>
              <EditSurvey key='edit-survey' surveyId={currentPart._id} />
            </CenterContentWrapper>
          )}
          {!currentPartKind && mode !== 'Create' && (
            <CenterContentWrapper className='test' fullWidth>
              <VersionOverview key='version-overview' />
            </CenterContentWrapper>
          )}
          {currentPartKind === 'Chapter' && (
            <>
              <SidebarCommentsWrapper className='test2'>
                <Header key='comments-header' as='h4' content={t('Comments')} />
                {filteredReaders && (
                  <ReaderFilterLabel
                    key='active-reader'
                    filteredReaders={filteredReaders}
                    onRemove={() => {
                      dispatch(removeFilteredReaders());
                    }}
                  />
                )}
                <InlineCommentsList
                  key='comments'
                  disableLeavingComments
                  positioning='sidebar'
                  enableFilters
                  showOnly={{
                    type: 'InlineComment',
                    status: ['TODO', 'DOING', 'NEW']
                  }}
                />
              </SidebarCommentsWrapper>
            </>
          )}
        </Wrapper>
      );
    }
  }
};

export default EditPart;
