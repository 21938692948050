import React, { Component } from 'react';
import { Icon, Form } from 'semantic-ui-react';

// i18n
import { withTranslation } from 'react-i18next';

import '../../../../App.css';

class MultipleChoiceOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { t } = this.props;
    this.setState({
      options: this.props.options.options || [`${t('Option')} 1`]
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { t } = nextProps;
    if (nextProps.options !== undefined && nextProps.options !== this.props.options) {
      this.setState({
        options: nextProps.options.options || [`${t('Option')} 1`]
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.options !== this.props.options && this.state.nextFocus !== undefined) {
      const elementId = this.state.nextFocus;
      this.setState({
        nextFocus: undefined
      },() => {
        const inputElement = document.getElementById(elementId);
        if (inputElement) {
          inputElement.focus();
          inputElement.select();
        }
      })
    }
  }

  handleOptionChanged = (optionIndex, newValue) => {
    var updatedOptions = [...this.state.options];
    updatedOptions.splice(optionIndex,1, newValue);
    this.setState({
      options: updatedOptions
    });
  }

  removeOption = (optionIndex) => {
    var updatedOptions = [...this.state.options];
    updatedOptions.splice(optionIndex,1);
    this.setState({
      options: updatedOptions
    }, () => {
      this.updateOptions();
    })
  }

  addNewOption = () => {
    const { t } = this.props;
    const updatedOptions = [...this.state.options];
    updatedOptions.push(`${t('Option')} ${updatedOptions.length + 1}`);
    const newElementId = `${this.props.questionId}-${updatedOptions.length-1}`;
    this.setState({
      options: updatedOptions,
      nextFocus: newElementId
    }, () => {
      this.updateOptions();
    })
  }

  updateOptions = () => {
    this.props.onOptionsChanged({
      options: this.state.options,
      minSelected: 1,
      maxSelected: 1,
    });
  }

  render() {
    const { options } = this.state;
    if (!options) {
      return null;
    }
    return (
      <div>
        {
          options === null ? null : options.map((option, index, array) => {
            const elementId = `${this.props.questionId}-${index}`;
            return (
              <Form.Input
                key={index}
                className={`text-line`}
                transparent
                value={option}
                placeholder='Add option'
                onChange={(event, data)=>{this.handleOptionChanged(index, data.value)}}
                onBlur={this.updateOptions}
              >
                <Icon name='circle outline' style={{marginRight: '1em'}}/>
                <input id={elementId}/>
                <div style={{cursor: 'pointer', visibility: array.length === 1 ? 'hidden':'visible'}} onClick={() => {this.removeOption(index)}}>
                  <Icon name='close'/>
                </div>
              </Form.Input>
            )
          })
        }
        <Form.Input
          key='add-option'
          className={`text-line`}
          transparent
          placeholder='Add option'
          onFocus={(event)=>{
            event.target.blur();
            this.addNewOption();
          }}
          onKeyPress={(event) => {
            if (event.which === 9 /*TAB*/) {
              event.target.blur();
              this.addNewOption();
            }
          }}
        >
          <Icon name='circle outline' style={{marginRight: '1em', position:'relative', top:0}}/>
          <input />
          <div style={{visibility: 'hidden'}}>
            <Icon name='close'/>
          </div>
        </Form.Input>
      </div>
    );
  }
}

export default withTranslation()(MultipleChoiceOptions);
