import { toast } from 'react-toastify';
import i18n from '../utils/i18n/i18n';

export const SET_CONVERSATIONS = 'message/SET_CONVERSATIONS';
export const ADD_CONVERSATION = 'message/ADD_CONVERSATION';
export const UPDATE_CONVERSATION = 'message/UPDATE_CONVERSATION';
export const UPDATE_CONVERSATION_LIST = 'message/UPDATE_CONVERSATION_LIST';
export const UPDATING_CONVERSATION = 'message/UPDATING_CONVERSATION';
export const REMOVE_CONVERSATION = 'message/REMOVE_CONVERSATION';
export const SET_CURRENT_CONVERSATION = 'message/SET_CURRENT_CONVERSATION';
export const SET_CONTACTS = 'message/SET_CONTACTS';
export const SENDING_MESSAGE = 'message/SENDING_MESSAGE';
export const ADD_MESSAGES_TO_CONVERSATION = 'message/ADD_MESSAGES_TO_CONVERSATION';
export const LOADING_CONVERSATION = 'message/LOADING_CONVERSATION';
export const FETCHED_EARLIER_MESSENGES = 'message/FETCHED_EARLIER_MESSENGES';
export const LOADING_EARLIER_MESSENGES = 'message/LOADING_EARLIER_MESSENGES';
export const BROADCASTING_MESSAGE = 'message/BROADCASTING_MESSAGE';
export const FETCHING_BROADCAST_MESSAGES = 'message/FETCHING_BROADCAST_MESSAGES';
export const FETCHING_BROADCAST_MESSAGE = 'message/FETCHING_BROADCAST_MESSAGE';
export const UPDATE_BROADCAST_MESSAGES = 'message/UPDATE_BROADCAST_MESSAGES';
export const UPDATE_CURRENT_BROADCAST_MESSAGE = 'message/UPDATE_CURRENT_BROADCAST_MESSAGE';
export const UPDATE_MESSAGE = 'message/UPDATE_MESSAGE';
export const ON_TYPING_NEW_MESSAGE = 'message/ON_TYPING_NEW_MESSAGE';
export const ON_RECEIVED_TYPING_NEW_MESSAGE = 'message/ON_RECEIVED_TYPING_NEW_MESSAGE';
export const ON_RECEIVED_NEW_MESSAGE = 'message/ON_RECEIVED_NEW_MESSAGE';

export const initialState = {
  sendingBroadcast: false,
  fetchingBroadcastMessages: false,
  fetchingBroadcastMessage: false,
  currentBroadcastMessage: undefined,
  broadcastMessages: [],
  conversations: [],
  currentConversation: undefined,
  contacts: [],
  sendingMessage: false,
  loadingConversation: false,
  loadingEarlierMessenges: false,
  fetchedEerlierMessenges: false, // to handle button press for loading prev messages
  updatingConversation: false
};

// reducers
export default (state = initialState, action) => {
  let updatedConversations;
  switch (action.type) {
    case ADD_MESSAGES_TO_CONVERSATION:
      let updatedConversation = { ...state.currentConversation };
      if (action.placement === 'start') {
        updatedConversation.messages.unshift(...action.messages);
      } else if (action.placement === 'end') {
        updatedConversation.messages.push(...action.messages);
      }
      return {
        ...state,
        currentConversation: updatedConversation
      };
    case FETCHED_EARLIER_MESSENGES:
      return {
        ...state,
        fetchedEerlierMessenges: action.value
      };
    case SENDING_MESSAGE:
      return {
        ...state,
        sendingMessage: action.value
      };
    case LOADING_CONVERSATION:
      return {
        ...state,
        loadingConversation: action.value
      };
    case LOADING_EARLIER_MESSENGES: {
      if (action.value) {
        return {
          ...state,
          loadingEarlierMessenges: action.value,
          fetchedEerlierMessenges: action.value
        };
      }
      return {
        ...state,
        loadingEarlierMessenges: action.value
      };
    }
    case SET_CURRENT_CONVERSATION:
      return {
        ...state,
        currentConversation: action.conversation
      };
    case SET_CONVERSATIONS:
      return {
        ...state,
        conversations: action.conversations
      };
    case ADD_CONVERSATION:
      updatedConversations = [...state.conversations];
      updatedConversations.unshift(action.conversation);
      return {
        ...state,
        conversations: updatedConversations
      };
    case UPDATE_CONVERSATION:
      updatedConversations = [...state.conversations];
      const index = updatedConversations.findIndex(conversation => conversation._id === action.conversation._id);
      if (index) {
        updatedConversations.splice(index, 1, action.conversation);
      }
      return {
        ...state,
        conversations: updatedConversations
      };
    case REMOVE_CONVERSATION:
      updatedConversations = [...state.conversations];
      const indexToClose = state.conversations.findIndex(conversation => conversation._id === action.conversation._id);
      if (indexToClose !== -1) {
        updatedConversations.splice(indexToClose, 1);
      }
      return {
        ...state,
        currentConversation: updatedConversations[0] || undefined,
        conversations: updatedConversations
      };
    case UPDATING_CONVERSATION:
      return {
        ...state,
        updatingConversation: action.value
      };
    case UPDATE_CONVERSATION_LIST: {
      const updatedConversationList = [...state.conversations];
      const conversationIndexToUpdate = updatedConversationList.findIndex(conversation => conversation._id === action.message.conversation);
      if (conversationIndexToUpdate !== -1) {
        const messageIndexToUpdate = updatedConversationList[conversationIndexToUpdate].messages.findIndex(message => message.conversation === action.message.conversation);
        if (messageIndexToUpdate !== -1) {
          updatedConversationList[conversationIndexToUpdate].messages.splice(messageIndexToUpdate, 1, action.message);
        }
      }
      return {
        ...state,
        conversations: updatedConversationList
      };
    }
    case SET_CONTACTS:
      return {
        ...state,
        contacts: action.contacts
      };
    case BROADCASTING_MESSAGE:
      return {
        ...state,
        sendingBroadcast: action.sendingBroadcast
      };
    case FETCHING_BROADCAST_MESSAGES:
      return {
        ...state,
        fetchingBroadcastMessages: action.fetching
      };
    case FETCHING_BROADCAST_MESSAGE:
      return {
        ...state,
        fetchingBroadcastMessage: action.fetching
      };
    case UPDATE_BROADCAST_MESSAGES:
      return {
        ...state,
        broadcastMessages: action.broadcastMessages
      };
    case UPDATE_CURRENT_BROADCAST_MESSAGE:
      return {
        ...state,
        currentBroadcastMessage: action.currentBroadcastMessage
      };
    case UPDATE_MESSAGE:
      let conversation = {
        ...state.currentConversation,
      }
      let updatedMessages = [...state.currentConversation.messages];
      let messageIndex = updatedMessages.findIndex(m => m._id === action.message._id);
      conversation.messages.splice(messageIndex, 1, action.message);
      return {
        ...state,
        currentConversation: {
          ...conversation
        }
      };
    case ON_RECEIVED_TYPING_NEW_MESSAGE: {
      if (state.currentConversation && state.currentConversation._id === action.conversationData.currentConversation) {
        const conversationOnTypingMessage = {
          ...state.currentConversation,
          onTypingNewMessage: action.conversationData
        };
        return {
          ...state,
          currentConversation: conversationOnTypingMessage
        };
      }
      return {
        ...state
      };
    }
    case ON_RECEIVED_NEW_MESSAGE:
      if (state.currentConversation && state.currentConversation._id === action.message.conversation) {
        const updatedConversationWithNewMessage = { ...state.currentConversation };
        updatedConversationWithNewMessage.messages.unshift(action.message);
        return {
          ...state,
          currentConversation: updatedConversationWithNewMessage
        };
      }
      return {
        ...state
      };
    default:
      return state;
  }
};

// actions
export const fetchConversations = (idToken, options) => {
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/conversations`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        } else {
          return res.json();
        }
      })
      .then((conversations) => {
        if (conversations !== undefined) {
          return dispatch({
            type: SET_CONVERSATIONS,
            conversations
          });
        }
        return false;
      })
      .catch((err) => {
        toast.error(i18n.t('FailedToLoadMessages', err));
      });
  };
};

export const fetchEarlierMessages = (idToken, conversationId, skip, limit) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_EARLIER_MESSENGES,
      value: true
    });
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/conversations/${conversationId}?skip=${skip}&limit${limit}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        } else {
          return res.json();
        }
      })
      .then((conversation) => {
        if (conversation !== undefined) {
          dispatch({
            type: LOADING_EARLIER_MESSENGES,
            value: false
          });
          dispatch({
            type: ADD_MESSAGES_TO_CONVERSATION,
            messages: conversation.messages,
            placement: 'end' // add to end
          });
        }
        return false;
      })
      .catch((err) => {
        toast.error(i18n.t('FailedToLoadMessage', err));
      })
      .finally(() => {
        dispatch({
          type: LOADING_EARLIER_MESSENGES,
          value: false
        });
      });
  };
};

export const fetchCurrentConversation = (idToken, conversationId) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_CONVERSATION,
      value: true
    });
    dispatch({
      type: FETCHED_EARLIER_MESSENGES,
      value: false
    });
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/conversations/${conversationId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        } else {
          return res.json();
        }
      })
      .then((conversation) => {
        if (conversation !== undefined) {
          return dispatch({
            type: SET_CURRENT_CONVERSATION,
            conversation
          });
        }
        return false;
      })
      .catch((err) => {
        toast.error(i18n.t('FailedToLoadMessage', err));
      })
      .finally(() => {
        dispatch({
          type: LOADING_CONVERSATION,
          value: false
        });
      });
  };
};

export const addConversationMember = (idToken, data) => {
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/conversations/${data.conversation._id}/members`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify({ memberId: data.member._id })
      })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        } else {
          return res.json();
        }
      })
      .then((members) => {
        if (members !== undefined) {
          return dispatch({
            type: SET_CURRENT_CONVERSATION,
            conversation: {
              ...data.conversation,
              members
            }
          });
        }
        return false;
      })
      .catch((err) => {
        toast.error(i18n.t('FailedToAddMember', err));
      });
  };
};

export const removeConversationMember = (idToken, data) => {
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/conversations/${data.conversation._id}/members/${data.member._id}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        } else {
          return res.json();
        }
      })
      .then((members) => {
        if (members !== undefined) {
          return dispatch({
            type: SET_CURRENT_CONVERSATION,
            conversation: {
              ...data.conversation,
              members
            }
          });
        }
        return false;
      })
      .catch((err) => {
        toast.error(i18n.t('FailedToAddMember', err));
      });
  };
};

export const saveConversation = (idToken, data) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_CONVERSATION,
      conversation: {
        ...data.conversation,
        saving: true
      }
    });
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/conversations/${data.conversation._id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify({ ...data.conversation })
      })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        } else {
          return res.json();
        }
      })
      .then((conversation) => {
        if (conversation !== undefined) {
          return dispatch({
            type: SET_CURRENT_CONVERSATION,
            conversation
          });
        }
        return false;
      })
      .catch((err) => {
        toast.error(i18n.t('FailedToLoadMessage', err));
      })
      .finally(() => {
        dispatch({
          type: LOADING_CONVERSATION,
          value: false
        });
      });
  };
};


export const setCurrentConversation = (conversation) => {
  return (dispatch) => {
    return dispatch({
      type: SET_CURRENT_CONVERSATION,
      conversation
    });
  };
};

export const startConversation = (idToken, messageData, callback) => {
  return (dispatch) => {
    dispatch({
      type: SENDING_MESSAGE,
      value: true
    });
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/conversations`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify(messageData)
      })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        } else {
          return res.json();
        }
      })
      .then((conversation) => {
        if (conversation !== undefined) {
          dispatch({
            type: ADD_CONVERSATION,
            conversation
          });
          dispatch({
            type: SET_CURRENT_CONVERSATION,
            conversation
          });
          if (callback) {
            callback({ success: true });
          }
        }
      })
      .catch((err) => {
        if (callback) {
          callback({ success: false });
        }
        toast.error(i18n.t('FailedToSendMessage', err));
      })
      .finally(() => {
        dispatch({
          type: SENDING_MESSAGE,
          value: false
        });
      });
  };
};

export const replyToConversation = (idToken, conversationId, messageData, callback) => {
  return (dispatch) => {
    dispatch({
      type: SENDING_MESSAGE,
      value: true,
      conversationId,
      messageData
    });
    dispatch({
      type: FETCHED_EARLIER_MESSENGES,
      value: false
    });
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/conversations/${conversationId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify(messageData)
      })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        } else {
          return res.json();
        }
      })
      .then((message) => {
        if (message !== undefined) {
          dispatch({
            type: ADD_MESSAGES_TO_CONVERSATION,
            messages: [message],
            placement: 'start', // add to beginning
            conversationId
          });
          if (callback) {
            callback({ success: true });
          }
        }
      })
      .catch((err) => {
        toast.error(i18n.t('FailedToSendMessage', err));
        if (callback) {
          callback({ success: false });
        }
      })
      .finally(() => {
        dispatch({
          type: SENDING_MESSAGE,
          value: false,
          conversationId
        });
      });
  };
};

export const fetchContacts = (idToken, options) => {
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/contacts`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        } else {
          return res.json();
        }
      })
      .then((contactList) => {
        if (contactList !== undefined) {
          return dispatch({
            type: SET_CONTACTS,
            contacts: contactList
          });
        }
        return false;
      })
      .catch((err) => {
        toast.error(i18n.t('FailedToLoadContacts', err));
      });
  };
};

export const clearCurrentConversation = (selectedPart) => {
  return (dispatch) => {
    return dispatch({
      type: SET_CURRENT_CONVERSATION,
      conversation: undefined
    });
  };
};


export const clearConversations = () => {
  return (dispatch) => {
    return dispatch({
      type: SET_CONVERSATIONS,
      messages: []
    });
  };
};

export const broadcastMessage = ({
  idToken, subject, text, html, recipients, book, title
}, successCallback) => (dispatch) => {
  dispatch({
    type: BROADCASTING_MESSAGE,
    sendingBroadcast: true
  });
  fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/broadcasts`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      },
      body: JSON.stringify({
        book,
        subject,
        text,
        html,
        recipients,
        title
      })
    })
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.error);
      } else {
        return res.json();
      }
    })
    .then(() => {
      if (successCallback) {
        successCallback();
      }
    })
    .catch((err) => {
      toast.error(i18n.t('SomethingWentWrong', err));
    })
    .finally(() => {
      dispatch({
        type: BROADCASTING_MESSAGE,
        sendingBroadcast: false
      });
    });
};

export const fetchBroadcastMessages = ({
  idToken, bookId
}) => (dispatch) => {
  dispatch({
    type: FETCHING_BROADCAST_MESSAGES,
    fetching: true
  });
  fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/broadcasts?bookId=${bookId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    })
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.error);
      } else {
        return res.json();
      }
    })
    .then((broadcastMessages) => {
      dispatch({
        type: UPDATE_BROADCAST_MESSAGES,
        broadcastMessages
      });
    })
    .catch((err) => {
      toast.error(i18n.t('SomethingWentWrong', err));
    })
    .finally(() => {
      dispatch({
        type: FETCHING_BROADCAST_MESSAGES,
        fetching: false
      });
    });
};

export const clearBroadcastMessages = () => {
  return (dispatch) => {
    return dispatch({
      type: UPDATE_BROADCAST_MESSAGES,
      broadcastMessages: []
    });
  };
};

export const fetchBroadcastMessage = ({
  idToken, messageId
}) => (dispatch) => {
  dispatch({
    type: FETCHING_BROADCAST_MESSAGE,
    fetching: true
  });
  fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/broadcasts/${messageId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    })
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.error);
      } else {
        return res.json();
      }
    })
    .then((currentBroadcastMessage) => {
      dispatch({
        type: UPDATE_CURRENT_BROADCAST_MESSAGE,
        currentBroadcastMessage
      });
    })
    .catch((err) => {
      toast.error(i18n.t('SomethingWentWrong', err));
    })
    .finally(() => {
      dispatch({
        type: FETCHING_BROADCAST_MESSAGE,
        fetching: false
      });
    });
};

export const clearCurrentBroadcastMessage = () => {
  return (dispatch) => {
    return dispatch({
      type: UPDATE_CURRENT_BROADCAST_MESSAGE,
      currentBroadcastMessage: undefined
    });
  };
};

export const closeConversation = (idToken, conversationId, updateData) => (dispatch) => {
  fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/conversations/${conversationId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      },
      body: JSON.stringify({ ...updateData })
    })
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.error);
      } else {
        return res.json();
      }
    })
    .then((conversation) => {
      if (conversation !== undefined) {
        dispatch({
          type: REMOVE_CONVERSATION,
          conversation
        });
      }
    })
    .catch((err) => {
      toast.error(i18n.t('FailedToLoadMessage', err));
    });
};

export const markMessageAsSeen = ({ idToken, conversationId, messageId }) => {
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/conversations/${conversationId}/messages/${messageId}?action=seen`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        } else {
          return res.json();
        }
      })
      .then((message) => {
        dispatch({
          type: UPDATE_MESSAGE,
          message
        });
        dispatch({
          type: UPDATE_CONVERSATION_LIST,
          message
        });
      })
      .catch(() => {
      });
  };
};

export const onTypingNewMessage = conversationData => dispatch => (dispatch({
  type: ON_TYPING_NEW_MESSAGE,
  conversationData
}));
