import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {Checkbox, Header} from 'semantic-ui-react';
import {withTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {authUtil} from '../../../utils';

const BookNotificationSettings = ({t}) => {
  const currentBook = useSelector(state => state.book.currentBook);

  const [bookNotificationSettings, setBookNotificationSettings] = useState();

  useEffect(() => {
    fetchBookNotificationSettings(currentBook._id);
  }, []);

  const fetchBookNotificationSettings = async bookId => {
    const idToken = await authUtil.getFreshIdToken();
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/notification-settings?kind=book&b=${bookId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => {
        if (!res.ok) {
          throw new Error(res.error);
        } else {
          return res.json();
        }
      })
      .then(bookNotificationSettings => {
        setBookNotificationSettings(bookNotificationSettings);
      })
      .catch(err => {
        console.log('Failed to fetch notification settings.');
      });
  };

  const updateBookNotificationSettings = async (bookId, updatedSettings) => {
    const idToken = await authUtil.getFreshIdToken();
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/notification-settings?kind=book&b=${bookId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify(updatedSettings)
      }
    )
      .then(res => {
        if (!res.ok) {
          throw new Error(res.error);
        } else {
          return res.json();
        }
      })
      .then(bookNotificationSettings => {
        setBookNotificationSettings(bookNotificationSettings);
      })
      .catch(err => {
        console.log('Failed to fetch notification settings.');
      });
  };

  const handleOnCommentChange = async (event, data) => {
    if (!bookNotificationSettings) return;
    const updatedSettings = {
      ...bookNotificationSettings
    };
    updatedSettings.onComment.email = data.checked;
    updateBookNotificationSettings(currentBook._id, updatedSettings);
  };

  const onSurveyCompletion = async (event, data) => {
    if (!bookNotificationSettings) return;
    const updatedSettings = {
      ...bookNotificationSettings
    };
    updatedSettings.onSurveyCompletion.email = data.checked;
    updateBookNotificationSettings(currentBook._id, updatedSettings);
  };

  return (
    <div>
      <Header size='tiny' content={t('Notifications')} />
      <Checkbox
        label={t('SendEmailOnComments')}
        checked={
          bookNotificationSettings &&
          bookNotificationSettings.onComment &&
          bookNotificationSettings.onComment.email
        }
        toggle
        onChange={handleOnCommentChange}
      />
      <br />
      <Checkbox
        style={{marginTop: 10}}
        label={t('SendEmailOnSurveyComplete')}
        checked={
          bookNotificationSettings &&
          bookNotificationSettings.onSurveyCompletion &&
          bookNotificationSettings.onSurveyCompletion.email
        }
        toggle
        onChange={onSurveyCompletion}
      />
    </div>
  );
};

export default withTranslation()(withRouter(BookNotificationSettings));
