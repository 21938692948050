import React from 'react';
import {Icon} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

interface Props {
  text?: string;
  title: string;
}
const CopyText: React.FC<Props> = ({text, title}) => {
  const {t} = useTranslation();
  const copyText = () => {
    if (!text) return;
    navigator.clipboard.writeText(text);
    toast.info(t('CopiedXToClipboard', {x: title}));
  };
  return <Icon link name='copy outline' onClick={copyText} />;
};
export default CopyText;
