import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Button } from 'semantic-ui-react';
// i18n
import { withTranslation } from 'react-i18next';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  updateCommentFilters
} from '../../../modules/comment';
import {
  fetchStats
} from '../../../modules/book';

import FeedbackSearch from './feedbackSearch';


class FeedbackFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {

  }

  handleStatusChange = (event, data) => {
    const updatedFilters = [...this.props.commentFilters];
    const index = updatedFilters.indexOf(data.value);
    if(data.checked && index < 0) {
      updatedFilters.push(data.value);
    } else if (!data.checked && index >= 0) {
      updatedFilters.splice(index, 1);
    }
    this.props.updateCommentFilters(updatedFilters);
  }

  clearFilters = () => {
    this.props.updateCommentFilters([]);
  }

  render() {
    const { commentFilters, t } = this.props;
    return (
      <Form>
        <Button size='tiny' floated='right' circular compact content={t('RemoveFilters')} onClick={this.clearFilters}/>
        <Form.Group>
          <label>{t('ShowOnly')}:</label>
          <Form.Checkbox checked={commentFilters.indexOf('NEW')>=0} name='NEW' label={t('StatusNEW')} value='NEW' onChange={this.handleStatusChange} />
          <Form.Checkbox checked={commentFilters.indexOf('TODO')>=0} name='TODO' label={t('StatusTODO')} value='TODO' onChange={this.handleStatusChange} />
          <Form.Checkbox checked={commentFilters.indexOf('DOING')>=0} name='DOING' label={t('StatusDOING')} value='DOING' onChange={this.handleStatusChange} />
          <Form.Checkbox checked={commentFilters.indexOf('DONE')>=0} name='DONE' label={t('StatusDONE')} value='DONE' onChange={this.handleStatusChange} />
          <Form.Checkbox checked={commentFilters.indexOf('IGNORE')>=0} name='IGNORE' label={t('StatusIGNORE')} value='IGNORE' onChange={this.handleStatusChange} />
        </Form.Group>
        <Form.Field>
          <FeedbackSearch />
        </Form.Field>
      </Form>
    )
  }
}

// redux stuff
const mapStateToProps = state => ({
  idToken: state.user.idToken,
  readers: state.book.readers,
  chapters: state.book.parts,
  stats: state.book.stats,
  currentBook: state.book.currentBook,
  commentFilters: state.comment.filters
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchStats,
  updateCommentFilters,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackFilter)))
