import React from 'react';
import { ReactComponent as BRLogoAndName } from './assets/betaReaderLogoAndName.svg'
import { Image } from 'semantic-ui-react';
const BetaReaderLogoAndName = ({
}) => {
  const currentDate = new Date();
  const prideMonthEnd = new Date('2021-08-07');
  if (currentDate < prideMonthEnd) {
    return (
      <Image
        src={'/assets/images/rainbow-logo.png'}
      />
    )
  }
  return (
    <BRLogoAndName />
  )
}

export default BetaReaderLogoAndName;