import React from 'react';
import { Input } from 'semantic-ui-react';

const InputWithSave = ({
  onSave,
  onKeyDown,
  ...props
}) => {
  /**
   * Triggers onSave if CMD + S (Mac) or Ctrl + S (Win) is pressed
   * @param {event} event 
   */
  const handleKeyDown = (event) => {
    if (event.keyCode === 83 && (event.metaKey || event.ctrlKey)) {
      onSave && onSave();
    }
    onKeyDown && onKeyDown(event);
  }
  return (
    <Input
      {...props}
      onKeyDown={handleKeyDown}
    />
  )
}

export default InputWithSave;