import React, {useState, useEffect} from 'react';
import {Button, Icon, Loader} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';

// redux
import {useDispatch, useSelector} from 'react-redux';

import {getFreshIdToken} from '../../utils/authUtil';
// redux
import {
  submitComment,
  addNewInlineCommentPlaceholder
} from '../../modules/comment';

const ReactionsComponent = ({getEditorState, children, onOverrideContent}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const userProfile = useSelector(state => state.user.userProfile);
  const currentBook = useSelector(state => state.book.currentBook);
  const currentSpineIndex = useSelector(
    state => state.readerApp.currentSpineIndex
  );

  const [submitting, setSubmitting] = useState(false);
  const [text, setText] = useState();

  useEffect(() => {
    const onWindowClick = () => {
      // Call `onOverrideContent` again with `undefined`
      // so the toolbar can show its regular content again.
      // onOverrideContent(undefined);
    };
    setTimeout(() => {
      window.addEventListener('click', onWindowClick);
    });
    return () => {
      window.removeEventListener('click', onWindowClick);
    };
  }, []);

  const _onReactionClick = (event, reaction) => {
    event.preventDefault();
    event.stopPropagation();
    submitReaction(reaction);
  };

  const _onCommentClick = event => {
    const {clientX, clientY} = event;
    event.preventDefault();
    event.stopPropagation();
    const editorState = getEditorState();
    const selectionState = editorState.getSelection();
    // add placeholder comment
    // console.log('_onCommentClick', {clientX, clientY, target: event.currentTarget});
    dispatch(
      addNewInlineCommentPlaceholder(
        clientX,
        clientY,
        selectionState,
        userProfile
      )
    );
  };

  const submitReaction = async reaction => {
    let updatedEditorState;
    setSubmitting({
      submitting: reaction
    });
    const idToken = await getFreshIdToken();

    let editorState = getEditorState();
    const selectionState = editorState.getSelection();

    const currentChapter = currentBook.parts[currentSpineIndex];
    // if we have a chapter to comment on
    if (currentChapter !== undefined) {
      // submit the comment first
      dispatch(
        submitComment(
          idToken,
          currentBook._id,
          currentChapter._id,
          text,
          {
            reaction: reaction || null,
            kind: 'inline',
            inlineInfo: {
              startKey: selectionState.getStartKey(),
              startOffset: selectionState.getStartOffset(),
              endKey: selectionState.getEndKey(),
              endOffset: selectionState.getEndOffset()
            }
          },
          commentId => {
            // if submission is successful, update the document on the device
            // to enable highlighting of inline comments
            setSubmitting(false);
            // make sure we blur the focus otherwise we loose the ability to show customToolBar
            if (document.activeElement !== document.body) {
              document.activeElement.blur();
            }

            // TODO: make sure that the new reaction has an inline layout dom reference
          }
        )
      );
    }
  };

  // When using a click event inside overridden content, mouse down
  // events needs to be prevented so the focus stays in the editor
  // and the toolbar remains visible  onMouseDown = (event) => event.preventDefault()
  const onMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <>
      {children}
      <Button compact icon onClick={_onCommentClick}>
        <div className='tooltip'>
          <span className='tooltiptext'>{t('EnterComment')}</span>
          <Icon
            name='comment'
            flipped='horizontally'
            style={{fontSize: '1.4em', color: '#f2f2f2'}}
          />
        </div>
      </Button>
      <Button
        compact
        icon
        onClick={e => {
          _onReactionClick(e, 'LIKE');
        }}
        onKeyPress={e => {
          _onReactionClick(e, 'LIKE');
        }}>
        <div className='tooltip'>
          <Loader active={submitting === 'LIKE'} />
          <span className='tooltiptext'>{t('Like')}</span>
          <div role='button' tabIndex={0} style={{fontSize: '1.6em'}}>
            {String.fromCodePoint(0x1f44d)}
          </div>
        </div>
      </Button>
      <Button
        compact
        icon
        onClick={e => {
          _onReactionClick(e, 'LOVE');
        }}
        onKeyPress={e => {
          _onReactionClick(e, 'LOVE');
        }}>
        <div className='tooltip'>
          <Loader active={submitting === 'LOVE'} />
          <span className='tooltiptext'>{t('Love')}</span>
          <div
            role='button'
            tabIndex={0}
            className='reactionIcon reaction-heart'>
            {String.fromCodePoint(0x2764)}
          </div>
        </div>
      </Button>
      <Button
        compact
        icon
        onClick={e => {
          _onReactionClick(e, 'LAUGH');
        }}
        onKeyPress={e => {
          _onReactionClick(e, 'LAUGH');
        }}>
        <div className='tooltip'>
          <Loader active={submitting === 'LAUGH'} />
          <span className='tooltiptext'>{t('Laugh')}</span>
          <div role='button' tabIndex={0} className='reactionIcon'>
            {String.fromCodePoint(0x1f602)}
          </div>
        </div>
      </Button>
      <Button
        compact
        icon
        onClick={e => {
          _onReactionClick(e, 'SURPRISE');
        }}
        onKeyPress={e => {
          _onReactionClick(e, 'SURPRISE');
        }}>
        <div className='tooltip'>
          <Loader active={submitting === 'SURPRISE'} />
          <span className='tooltiptext'>{t('Surprise')}</span>
          <div role='button' tabIndex={0} className='reactionIcon'>
            {String.fromCodePoint(0x1f62e)}
          </div>
        </div>
      </Button>
      <Button
        compact
        icon
        onClick={e => {
          _onReactionClick(e, 'SAD');
        }}
        onKeyPress={e => {
          _onReactionClick(e, 'SAD');
        }}>
        <div className='tooltip'>
          <Loader active={submitting === 'SAD'} />
          <span className='tooltiptext'>{t('Sad')}</span>
          <div role='button' tabIndex={0} className='reactionIcon'>
            {String.fromCodePoint(0x1f625)}
          </div>
        </div>
      </Button>
      <Button
        compact
        icon
        onClick={e => {
          _onReactionClick(e, 'ANGRY');
        }}
        onKeyPress={e => {
          _onReactionClick(e, 'ANGRY');
        }}>
        <div className='tooltip'>
          <Loader active={submitting === 'ANGRY'} />
          <span className='tooltiptext'>{t('Angry')}</span>
          <div role='button' tabIndex={0} className='reactionIcon'>
            {String.fromCodePoint(0x1f621)}
          </div>
        </div>
      </Button>
    </>
  );
};

export default ReactionsComponent;
