import React, { Component } from 'react';
import { Popup, Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

// pop-ups
import { toast } from 'react-toastify';
// i18n
import { withTranslation } from 'react-i18next';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

export class UploadInlineImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: []
    };
  }

  uploadImages = (callback) => {
    const {
      idToken, currentBook, t, currentChapter
    } = this.props;
    const { files } = this.state;
    const postData = new FormData();
    files.forEach((file, index) => {
      postData.append('images', file, `image_${index}`);
    });
    this.setState({
      isPosting: true
    }, () => {
      fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${currentBook._id}/part/${currentChapter._id}/inline-image`, {
        method: 'POST',
        headers: {
          'br-token': idToken
        },
        body: postData
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.error);
          }
          return res.json();
        })
        .then((newImages) => {
          if (newImages) {
            this.addImage(newImages);
            if (callback) {
              callback(true);
            }
          }
        })
        .catch((err) => {
          console.error(err);
          if (callback) {
            callback(false);
          }
          toast.error(t('FailedToUploadImage'));
        })
        .finally(() => {
          this.setState({
            isPosting: false
          });
        });
    });
  }

  onNewFiles = (event) => {
    // Prevent default behavior (Prevent file from being opened)
    let files;
    if (event.target.files) {
      files = [...event.target.files];
    } else if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      files = [...event.dataTransfer.items];
    } else {
      // Use DataTransfer interface to access the file(s)
      files = [...event.dataTransfer.files];
    }
    this.handleFiles(files);
  }

  handleFiles = (files) => {
    if (files.length === 0) {
      return;
    }
    const newFiles = [];
    files.forEach((file) => {
      if (file.getAsFile) {
        newFiles.push(file.getAsFile());
      } else {
        newFiles.push(file);
      }
    });
    this.setState(prevState => ({
      files: [...prevState.files, ...newFiles]
    }), () => {
      this.uploadImages();
    });
  }

  toggleImageUpload = (event) => {
    if (this.fileSelector) {
      this.fileSelector.click();
    }
    event.preventDefault();
    event.stopPropagation();
  }

  fileInputRef = (ref) => {
    this.fileSelector = ref;
  };

  preventDefaults = (event) => {
    event.stopPropagation();
    event.preventDefault();
  }

  addImage = (images) => {
    const { editorState, modifier } = this.props;
    if (images.length > 0) {
      images.forEach((image) => {
        const modifiedState = modifier(
          editorState,
          image.url,
          {
            originalWidth: image.width,
            originalHeight: image.height,
            width: 100 // initial scaling percent
          }
        );
        this.props.onChange(modifiedState);
      });
    }
  };

  render() {
    const { t } = this.props;
    const { isPosting } = this.state;
    return [
      <Popup
        key='button'
        basic
        trigger={(
          <Button
            floated='left'
            circular
            basic
            size='mini'
            icon='image'
            loading={isPosting}
            onClick={this.toggleImageUpload}
          />
        )}
        content={t('UploadImage')}
        position='top right'
      />,
      <input
        key='input'
        ref={this.fileInputRef}
        multiple='multiple'
        accept='image/x-png,image/jpeg'
        type='file'
        name='images'
        onChange={this.onNewFiles}
        style={{
          position: 'fixed',
          width: 0,
          height: 1,
          visibility: 'hidden'
        }}
      />
    ];
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  currentBook: state.book.currentBook,
  currentChapter: state.book.currentChapter
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadInlineImage)));
