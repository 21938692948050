import React, { useCallback } from 'react';

import {
  AreaChart, Area, XAxis, YAxis,
  Tooltip, ResponsiveContainer
} from 'recharts';
import { Header, Dimmer, Loader } from 'semantic-ui-react';

// redux
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CustomTooltip = ({ active, payload, external, label, unit, customValueParser }) => {
  const { t } = useTranslation();

  const getFormattedValue = useCallback((unformattedValue) => {
    if (customValueParser) {
      return customValueParser(unformattedValue);
    }
    // flatten value
    return Math.round(unformattedValue * 100) / 100;
  },[payload, customValueParser])
  if (active && payload && payload[0]) {
    const currentPayload = payload[0]?.payload;
    const dataKey = payload[0]?.dataKey;
    if (currentPayload) {
      const { partId, partTitle, versionNumber, partIndex } = currentPayload;
      const tooltip = (
        <div style={{ textAlign: 'left' }}>
          <strong>{unit}: {getFormattedValue(currentPayload[dataKey])}</strong><br />
          <strong key={partId}>{t('ChapterInitial')}{partIndex + 1}</strong>: <span>{partTitle} (v{versionNumber})</span><br />
        </div>
      )
      return tooltip;
    }
  }

  return null;
};

const ProgressChart = ({
  onClick,
  title,
  unit,
  dataKey,
  payloadKey,
  syncId,
  data,
  lineColor,
  areaColor,
  customValueParser,
  customTick
}) => {
  const { t } = useTranslation();

  const fetchingReadingData = useSelector(state => state.report.fetchingReadingData);

  const chartClicked = (data, event) => {
    event.preventDefault();
    event.stopPropagation();
    onClick && onClick(data.activeTooltipIndex);
  }

  return (
    <div>
      {
        fetchingReadingData && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )
      }
      <Header sub content={title} />
      <ResponsiveContainer width='95%' height={200} style={{ textAlign: 'center' }}>
        <AreaChart
          onClick={chartClicked}
          syncId={syncId}
          data={data}
          margin={{
            top: 10, right: 30, left: 0, bottom: 0
          }}
        >
          <XAxis dataKey='name' />
          <YAxis tick={customTick} />
          <Tooltip content={<CustomTooltip external={data} unit={unit} customValueParser={customValueParser} />} />
          <Area
            type='monotone'
            dataKey={dataKey}
            // name={name}
            stroke={areaColor}
            strokeWidth={2}
            fill={areaColor}
            fillOpacity={0.3}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}
export default ProgressChart;
