import { toast } from 'react-toastify';

// manage url query strings
import queryString from 'query-string';

// download files
import download from 'downloadjs';

import i18n from '../utils/i18n/i18n';

export const UPDATE_READING_DATA = 'report/UPDATE_READING_DATA';
export const UPDATE_SURVEY_DATA = 'report/UPDATE_SURVEY_DATA';
export const UPDATE_REVIEW_DATA = 'report/UPDATE_REVIEW_DATA';
export const FETCHING_READING_DATA = 'report/FETCHING_READING_DATA';
export const DONE_FETCHING_READING_DATA = 'report/DONE_FETCHING_READING_DATA';
export const UPDATE_READING_DATA_FILTERS = 'report/UPDATE_READING_DATA_FILTERS';
export const UPDATE_VERSION_FILTER = 'report/UPDATE_VERSION_FILTER';
export const CHANGE_SELECTED_PART = 'report/CHANGE_SELECTED_PART';
export const UPDATE_ABANDONED_READERS_DATA = 'report/UPDATE_ABANDONED_READERS_DATA';

const initialState = {
  versionData: undefined,
  sessions: undefined,
  invited: undefined,
  started: undefined,
  midPoint: undefined,
  finished: undefined,
  abandoned: undefined,
  fetchingReadingData: false,
  readerFilters: undefined,
  versionFilter: undefined,
  selectedPart: undefined,
  surveyData: undefined,
  reviewData: undefined,
}

// reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_READING_DATA:
      return {
        ...state,
        versionData: action.versionData,
        invited: action.invited,
        started: action.started,
        midPoint: action.midPoint,
        finished: action.finished,
        abandoned: action.abandoned,
        sessions: action.sessions,
      }
    case UPDATE_SURVEY_DATA:
      return {
        ...state,
        surveyData: action.surveyData,
      }
    case UPDATE_REVIEW_DATA:
      return {
        ...state,
        reviewData: action.reviewData,
      }
    case UPDATE_ABANDONED_READERS_DATA:
      return {
        ...state,
        abandonedReadersData: action.abandonedReadersData,
      }
    case FETCHING_READING_DATA:
      return {
        ...state,
        fetchingReadingData: true,
      }
    case DONE_FETCHING_READING_DATA:
      return {
        ...state,
        fetchingReadingData: false,
      }
    case UPDATE_READING_DATA_FILTERS:
      return {
        ...state,
        readerFilters: action.filters
      }
    case UPDATE_VERSION_FILTER:
      return {
        ...state,
        versionFilter: action.newVersionId
      }
    case CHANGE_SELECTED_PART:
      return {
        ...state,
        selectedPart: action.selectedPart
      }
    default:
      return state
  }
}

// actions
export const fetchReadingData = (idToken, bookId, versionFilter, readerFilters, format) => {
  // if (options && options.filter) {
  return (dispatch) => {
    if (format !== 'csv') {
      // we are not downloading
      dispatch({
        type: FETCHING_READING_DATA
      });
    }
    const query = {
      format
    };
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/reports/reading-data-v2?${queryString.stringify(query)}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      },
      body: JSON.stringify({
        bookId,
        versionFilter: Array.isArray(versionFilter) ? versionFilter : [versionFilter],
        readerFilters
      })
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        }
        if (format === 'csv') {
          // download instead of populating redux
          return res.blob().then((file) => {
            download(file, 'reading-data.csv', 'text/csv');
          });
        }
        return res.json().then((data) => {
          // populate redux props
          dispatch({
            type: DONE_FETCHING_READING_DATA
          });
          return dispatch({
            type: UPDATE_READING_DATA,
            versionData: data.versionData,
            invited: data.invited,
            started: data.started,
            midPoint: data.midPoint,
            finished: data.finished,
            abandoned: data.abandoned,
            sessions: data.sessions
          });
        });
      })
      .catch((err) => {
        toast.error(i18n.t('FailedToDownloadReport'));
        console.error(err);
      })
      .finally(() => {
        dispatch({
          type: DONE_FETCHING_READING_DATA
        });
      });
  };
  // }
};

export const fetchSurveyData = (idToken, bookId, versionFilter, readerFilters, format) => {
  return (dispatch) => {
    const query = {
      format
    };
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/reports/survey-data-v2?${queryString.stringify(query)}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      },
      body: JSON.stringify({
        bookId,
        versionFilter: Array.isArray(versionFilter) ? versionFilter : [versionFilter],
        readerFilters
      })
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        }
        if (format === 'csv') {
          // download instead of populating redux
          return res.blob().then((file) => {
            download(file, 'survey-answers.csv', 'text/csv');
          });
        }
        return res.json().then(surveyData => dispatch({
          type: UPDATE_SURVEY_DATA,
          surveyData
        }));
      })
      .catch((err) => {
        toast.error(i18n.t('FailedToDownloadReport'));
        console.error(err);
      });
  };
};

export const fetchReviewData = (idToken, bookId, options, format) => {
  return (dispatch) => {
    const query = {
      b: bookId,
      format
    };
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/reports/review-data?${queryString.stringify(query)}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      },
      body: JSON.stringify({ options })
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        }
        if (format === 'csv') {
          // download instead of populating redux
          return res.blob().then((file) => {
            download(file, 'reviews.csv', 'text/csv');
          });
        }
        return res.json().then(reviewData => dispatch({
          type: UPDATE_REVIEW_DATA,
          reviewData
        }));
      })
      .catch((err) => {
        toast.error(i18n.t('FailedToDownloadReport'));
        console.error(err);
      });
  };
};

export const fetchAbandonedReadersData = (idToken, bookId, versionFilter, readerFilters, format) => {
  return (dispatch) => {
    const query = {
      format
    };
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/reports/abandoned-readers-data-v2?${queryString.stringify(query)}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      },
      body: JSON.stringify({
        bookId,
        versionFilter: Array.isArray(versionFilter) ? versionFilter : [versionFilter],
        readerFilters
      })
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        }
        if (format === 'csv') {
          // download instead of populating redux
          return res.blob().then((file) => {
            download(file, 'survey-answers.csv', 'text/csv');
          });
        }
        return res.json().then(abandonedReadersData => {
          dispatch({
            type: UPDATE_ABANDONED_READERS_DATA,
            abandonedReadersData
          })
        });
      })
      .catch((err) => {
        toast.error(i18n.t('FailedToDownloadReport'));
        console.error(err);
      });
  };
};

export const clearReviewData = () => dispatch => dispatch({
  type: UPDATE_REVIEW_DATA,
  reviewData: undefined
});

export const clearSurveyData = () => dispatch => dispatch({
  type: UPDATE_SURVEY_DATA,
  surveyData: undefined
});

export const clearAbandonedReadersData = () => dispatch => dispatch({
  type: UPDATE_ABANDONED_READERS_DATA,
  abandonedReadersData: undefined
});

export const clearReadingData = (idToken, bookId, options) => dispatch => dispatch({
  type: UPDATE_READING_DATA,
  versionData: undefined,
  invited: undefined,
  started: undefined,
  midPoint: undefined,
  finished: undefined,
  abandoned: undefined,
  sessions: undefined,
  filters: undefined,
  selectedPart: undefined
});

export const updateReaderFilters = (newFilters) => {
  let filters = newFilters;
  if (!filters) {
    filters = [{}];
  }
  return dispatch => dispatch({
    type: UPDATE_READING_DATA_FILTERS,
    filters
  });
};

export const updateVersionFilter = (newVersionId) => {
  return dispatch => dispatch({
    type: UPDATE_VERSION_FILTER,
    newVersionId
  });
};

export const changeSelectedPart = selectedPart => dispatch => dispatch({
  type: CHANGE_SELECTED_PART,
  selectedPart
});
