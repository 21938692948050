import React from 'react';

import { Link } from 'react-router-dom';
import { Segment, Header, Divider } from 'semantic-ui-react'
import { withTranslation, Trans } from 'react-i18next';

import ShareAuthorApp from '../sharing/shareAuthorApp';

// stateless component
const GetMoreReadersMessage = ({ user, userProfile }) => (
  <Segment color='orange'>
    <Trans i18nKey='referralText'>
      <Header size='medium' style={{ fontWeight: 'normal' }}>Get up to 10 extra reader slots by inviting your friends to BetaReader.io!</Header>
      <p style={{ fontWeight: 'lighter' }}>For every friend who creates a validated author account and lists a manuscript thanks to you, we will give you and your friend 1 reader slot each, up to a maximum of 10 slots.</p>
      <p style={{ fontWeight: 'lighter' }}>You can also raise your reader limit by reading and reviewing 100k words via our <Link to='/discover'><strong>reader app</strong></Link>, or by <Link to='/products'><strong>upgrading</strong></Link> your account!</p>
    </Trans>
    <Divider />
    <ShareAuthorApp user={user} userProfile={userProfile} />
  </Segment>
);

export default withTranslation()(GetMoreReadersMessage);
