import { toast } from 'react-toastify';
import qs from 'qs';

import i18n from '../utils/i18n/i18n';

export const ADD_REVIEW = 'review/ADD_REVIEW';
export const FETCHING_REVIEWS = 'review/FETCHING_REVIEWS';
export const SET_REVIEWS = 'review/SET_REVIEWS';
export const APPEND_REVIEWS = 'review/APPEND_REVIEWS';
export const FETCH_REVIEW = 'review/FETCH_REVIEW';
export const UPDATE_REVIEWS = 'review/UPDATE_REVIEWS';
export const FETCHING_REVIEW = 'review/FETCHING_REVIEW';

export const initialState = {
  reviews: [],
  reviewCount: 0,
  activeReview: undefined,
  addReview: true,
  fetchingReview: false,
  fetchingReviews: false
};

// reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_REVIEWS:
      return {
        ...state,
        reviews: action.reviews,
        reviewCount: action.reviewCount || 0
      };
    case APPEND_REVIEWS:
      return {
        ...state,
        reviews: [...state.reviews, ...action.reviews]
      };
    case FETCHING_REVIEWS:
      return {
        ...state,
        fetchingReviews: action.fetchingReviews
      };
    case FETCH_REVIEW:
      return {
        ...state,
        activeReview: action.review
      };
    case FETCHING_REVIEW:
      return {
        ...state,
        fetchingReview: action.value
      };
    case UPDATE_REVIEWS:
      return {
        ...state,
        activeReview: action.review,
        reviews: action.reviews
      };
    case ADD_REVIEW:
      var updatedReviews = [...state.reviews];
      updatedReviews.unshift(action.review);
      return {
        ...state,
        reviews: updatedReviews,
        addReview: false
      };

    default:
      return state;
  }
};

// actions
export const fetchReviews = ({
  idToken, bookId, options = {}
}) => {
  const path = `/books/${bookId}/reviews${qs.stringify(options, { addQueryPrefix: true })}`;
  return (dispatch) => {
    dispatch({
      type: FETCHING_REVIEWS,
      fetchingReviews: true
    });
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}${path}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    })
      .then(res => res.json())
      .then(({ reviews, reviewCount }) => {
        if (reviews.err) {
          throw new Error(`${reviews.msg} [${reviews.err}]`);
        }
        return dispatch({
          type: SET_REVIEWS,
          reviews,
          reviewCount
        });
      })
      .catch((err) => {
        toast.error(i18n.t('SomethingWentWrong'));
        console.error(err);
      })
      .finally(() => {
        dispatch({
          type: FETCHING_REVIEWS,
          fetchingReviews: false
        });
      });
  };
};

export const fetchMoreReviews = ({
  idToken, bookId, options = {}
}) => {
  const path = `/books/${bookId}/reviews${qs.stringify(options, { addQueryPrefix: true })}`;
  return (dispatch) => {
    dispatch({
      type: FETCHING_REVIEWS,
      fetchingReviews: true
    });
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}${path}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    })
      .then(res => res.json())
      .then((reviews) => {
        if (reviews.err) {
          throw new Error(`${reviews.msg} [${reviews.err}]`);
        }
        return dispatch({
          type: APPEND_REVIEWS,
          reviews
        });
      })
      .catch((err) => {
        toast.error(i18n.t('SomethingWentWrong'));
        console.error(err);
      })
      .finally(() => {
        dispatch({
          type: FETCHING_REVIEWS,
          fetchingReviews: false
        });
      });
  };
};

export const clearReviewList = () => dispatch => dispatch({
  type: SET_REVIEWS,
  reviews: [],
  reviewCount: 0
});

export const fetchReview = (idToken, bookId, callback) => (dispatch) => {
  dispatch({
    type: FETCHING_REVIEW,
    value: true
  });
  fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/reviews/user-review`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'br-token': idToken
    }
  })
    .then(res => res.json())
    .then((review) => {
      if (callback) {
        callback(review);
      }
      return dispatch({
        type: FETCH_REVIEW,
        review: review && review.overAllRating ? review : {}
      });
    })
    .finally(() => {
      dispatch({
        type: FETCHING_REVIEW,
        value: false
      });
    });
};

export const submitReview = (idToken, bookId, data, callback) => (dispatch) => {
  fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/reviews`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'br-token': idToken
    },
    body: JSON.stringify({
      overAllRating: data && data.overAllRating ? data.overAllRating : null,
      plotRating: data && data.plotRating ? data.plotRating : null,
      grammarRating: data && data.grammarRating ? data.grammarRating : null,
      title: data && data.title ? data.title : null,
      text: data && data.text ? data.text : null
    })
  })
    .then((review) => {
      if (callback && review) {
        callback(review);
      }
      return dispatch({
        type: ADD_REVIEW,
        review: review
      });
    })
    .catch(error => toast.error('failed to add review', { autoClose: false }));
};

export const updateReview = (idToken, bookId, reviewId, data, callback) => (dispatch) => {
  fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/reviews/${reviewId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'br-token': idToken
    },
    body: JSON.stringify({
      overAllRating: data && data.overAllRating ? data.overAllRating : null,
      plotRating: data && data.plotRating ? data.plotRating : null,
      grammarRating: data && data.grammarRating ? data.grammarRating : null,
      title: data && data.title ? data.title : null,
      text: data && data.text ? data.text : null
    })
  })
    .then((review) => {
      if (callback && review) {
        callback(review);
      }
    })
    .catch((err) => {
      toast.error('failed to update review', { autoClose: false });
      console.log('failed to update review to backend', err);
    });
};

export const clearReview = () => (dispatch) => {
  dispatch({
    type: UPDATE_REVIEWS,
    review: undefined,
    reviews: []
  });
};
