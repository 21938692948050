import {toast} from 'react-toastify';
import draftToHtml from 'draftjs-to-html';
import queryString from 'query-string';

import ReactGA from 'react-ga';
import uxAnalyticsUtil from '../utils/uxAnalyticsUtil';
import i18n from '../utils/i18n/i18n';
import {version} from 'moment';

export const SHOW_CURRENT_PART_TITLE = 'book/SHOW_CURRENT_PART_TITLE';
export const SAVING_CURRENT_BOOK = 'book/SAVING_CURRENT_BOOK';
export const UPDATE_WRITING_LIST = 'book/UPDATE_WRITING_LIST';
export const UPDATE_COLLABORATION_LIST = 'book/UPDATE_COLLABORATION_LIST';
export const UPDATE_READING_LIST = 'book/UPDATE_READING_LIST';
export const FETCH_CURRENT_BOOK_AND_CHAPTER_CONTENT =
  'book/FETCH_CURRENT_BOOK_AND_CHAPTER_CONTENT';
export const UPDATE_CURRENT_BOOK = 'book/UPDATE_CURRENT_BOOK';
export const UPDATE_CURRENT_BOOK_READER_STATUS =
  'book/UPDATE_CURRENT_BOOK_READER_STATUS';
export const FETCH_CURRENT_BOOK = 'book/FETCH_CURRENT_BOOK';
export const FETCHING_CURRENT_BOOK = 'book/FETCHING_CURRENT_BOOK';
export const FETCH_CURRENT_CHAPTER_CONTENT =
  'book/FETCH_CURRENT_CHAPTER_CONTENT';
export const SET_CURRENT_CHAPTER = 'book/SET_CURRENT_CHAPTER';
export const UPDATE_CURRENT_CHAPTER = 'book/UPDATE_CURRENT_CHAPTER';
export const UPDATE_PENDING_BOOK_CHANGES = 'book/UPDATE_PENDING_BOOK_CHANGES';
export const UPDATE_PENDING_PART_CHANGES = 'book/UPDATE_PENDING_PART_CHANGES';
export const SET_SAVE_BUTTON = 'book/SET_SAVE_BUTTON';
export const ADDED_NEW_BOOK = 'book/ADDED_NEW_BOOK';
export const ADD_PENDING_CHAPTERS = 'book/ADD_PENDING_CHAPTERS';
export const APPEND_PENDING_CHAPTERS = 'book/APPEND_PENDING_CHAPTERS';
export const REMOVE_PENDING_CHAPTER = 'book/REMOVE_PENDING_CHAPTER';
export const CHANGE_CHAPTER_UPLOAD_MESSAGE =
  'book/CHANGE_CHAPTER_UPLOAD_MESSAGE';
export const ADD_PART = 'book/ADD_PART';
export const REPLACE_PART = 'book/REPLACE_PART';
export const ADD_PARTS = 'book/ADD_PARTS';
export const FETCH_STATS = 'book/FETCH_STATS';
export const FETCH_RATING = 'book/FETCH_RATING';
export const UPDATE_RATING = 'book/UPDATE_RATING';
export const UPDATE_BOOK = 'book/UPDATE_BOOK';
export const UPDATE_BOOK_DETAILS = 'book/UPDATE_BOOK_DETAILS';
export const DUPLICATED_BOOK = 'book/DUPLICATED_BOOK';
export const DELETED_BOOK = 'book/DELETED_BOOK';
export const CHANGE_PATH = 'book/CHANGE_PATH';
export const UPDATE_PART = 'book/UPDATE_PART';
export const SAVING_DATA = 'book/SAVING_DATA';
export const SET_SWAPPABLE_BOOK = 'book/SET_SWAPPABLE_BOOK';
export const RESET_SWAPPABLE_BOOK = 'book/RESET_SWAPPABLE_BOOK';
export const TOGGLE_CHAPTER_UPLOAD = 'book/TOGGLE_CHAPTER_UPLOAD';
export const UPDATE_CURRENT_CONTENT_VERSION =
  'book/UPDATE_CURRENT_CONTENT_VERSION';
export const REMOVE_CONTENT_VERSION = 'book/REMOVE_CONTENT_VERSION';
export const SET_SWAPPABLES = 'book/SET_SWAPPABLES';
export const UPDATE_READER_STATE = 'book/UPDATE_READER_STATE';
export const REMOVE_READER_ENTRY = 'book/REMOVE_READER_ENTRY';
export const UPDATE_FOLLOW_STATUS = 'book/UPDATE_FOLLOW_STATUS';
export const SPLICE_IN_NEW_CHAPTER = 'book/SPLICE_IN_NEW_CHAPTER';
export const SET_IS_FETCHING_COLLABORATIONS_LIST =
  'book/SET_IS_FETCHING_COLLABORATIONS_LIST';
export const SET_IS_FETCHING_WRITING_LIST = 'book/SET_IS_FETCHING_WRITING_LIST';
export const SET_CONTENT_SAVE_STATE = 'book/SET_CONTENT_SAVE_STATE';
export const SET_SAVE_CONTENT_FN = 'book/SET_SAVE_CONTENT_FN';

export const initialState = {
  writing: undefined,
  isFetchingCollaborationsList: false,
  isFetchingWritingList: false,
  collaborating: [],
  swappables: [],
  followStatus: {},
  fetchedWritingList: false,
  reading: undefined,
  currentBook: undefined,
  swappableBook: undefined,
  pendingBookChanges: {},
  loadingCurrentBook: false,
  currentContentVersion: undefined,
  currentChapter: undefined,
  currentSurvey: undefined,
  currentPart: undefined,
  currentPartTitle: undefined,
  pendingPartChanges: {},
  saveButton: undefined,
  notes: [],
  stats: [],
  rating: undefined,
  showChapterUpload: false,
  contentSaveState: undefined,
  saveContentFn: undefined,
  defaultCoverBackgroundImageURL:
    'https://storage.googleapis.com/beta-reader-prod.appspot.com/defaults/DEFAULT_COVER_BACKGROUND.png'
};

// reducers
export default (state = initialState, action) => {
  let updatedBook = {};
  let versionIndex;
  let pendingChapters;
  let updatedWritingList;
  let updateCurrentContentVersion;
  let updatedChapter;
  switch (action.type) {
    case SAVING_CURRENT_BOOK:
      return {
        ...state,
        currentBook: {
          ...state.currentBook,
          saving: true
        }
      };
    case SET_CONTENT_SAVE_STATE:
      return {
        ...state,
        contentSaveState: action.contentSaveState
      };
    case SET_SAVE_CONTENT_FN:
      return {
        ...state,
        saveContentFn: action.fn
      };
    case UPDATE_CURRENT_CONTENT_VERSION:
      return {
        ...state,
        currentContentVersion: action.version
      };
    case UPDATE_WRITING_LIST:
      return {
        ...state,
        writing: action.list,
        fetchedWritingList: true
      };
    case UPDATE_COLLABORATION_LIST:
      return {
        ...state,
        collaborating: action.list
      };
    case ADDED_NEW_BOOK: {
      const currenWritingList = [...state.writing];
      currenWritingList.unshift(action.newBook);
      return {
        ...state,
        writing: currenWritingList
      };
    }

    case UPDATE_READING_LIST:
      return {
        ...state,
        reading: action.list
      };

    case FETCH_CURRENT_BOOK_AND_CHAPTER_CONTENT:
      return {
        ...state,
        currentBook: action.book,
        currentChapter: action.chapter
      };

    case SET_SWAPPABLES:
      return {
        ...state,
        swappables: action.swappables
      };
    case FETCHING_CURRENT_BOOK:
      return {
        ...state,
        loadingCurrentBook: action.loadingCurrentBook
      };

    case FETCH_CURRENT_BOOK:
    case UPDATE_CURRENT_BOOK:
      return {
        ...state,
        currentBook: action.book
      };
    case UPDATE_CURRENT_BOOK_READER_STATUS:
      return {
        ...state,
        currentBook: {
          ...state.currentBook,
          reader: action.reader
        }
      };
    case FETCH_CURRENT_CHAPTER_CONTENT:
      return {
        ...state,
        currentChapter: action.chapter
      };

    case SET_CURRENT_CHAPTER:
      return {
        ...state,
        currentChapter: action.chapter
      };

    case REPLACE_PART:
      let replaceBook = {
        ...state.currentBook
      };
      versionIndex = 0;
      if (replaceBook.content && replaceBook.content.length > 0) {
        versionIndex = replaceBook.content.findIndex(
          version =>
            parseInt(action.version) === parseInt(version.versionNumber)
        );
      }

      let newPart = action.part;

      if (
        replaceBook &&
        replaceBook.content &&
        replaceBook.content[versionIndex] &&
        versionIndex !== -1 &&
        replaceBook.content[versionIndex].parts
      ) {
        // remove the match and replace it with the new part
        replaceBook.content[versionIndex].parts.splice(
          replaceBook.content[versionIndex].parts.findIndex(
            part => newPart._id === part._id
          ),
          1,
          newPart
        );
      }

      return {
        ...state,
        currentBook: replaceBook
      };

    case ADD_PARTS:
      updatedBook = {...state.currentBook};
      versionIndex = 0;
      if (updatedBook.content && updatedBook.content.length > 0) {
        versionIndex = updatedBook.content.findIndex(
          version => version._id === action.version
        );
      }
      if (
        updatedBook.content &&
        updatedBook.content[versionIndex] &&
        versionIndex !== -1 &&
        updatedBook.content[versionIndex].parts
      ) {
        updatedBook.content[versionIndex].parts.splice(
          updatedBook.content[versionIndex].parts.length,
          0,
          ...action.parts
        );
      }
      return {
        ...state,
        currentBook: updatedBook
      };
    case UPDATE_CURRENT_CHAPTER:
      updateCurrentContentVersion = {...state.currentContentVersion};
      updatedChapter = action.chapter;
      updateCurrentContentVersion.parts.splice(
        updateCurrentContentVersion.parts.findIndex(
          part => updatedChapter._id === part._id
        ),
        1,
        updatedChapter
      );
      return {
        ...state,
        currentContentVersion: updateCurrentContentVersion,
        currentChapter: updatedChapter
      };
    case SPLICE_IN_NEW_CHAPTER:
      updateCurrentContentVersion = {...state.currentContentVersion};
      updateCurrentContentVersion.parts.splice(
        updateCurrentContentVersion.parts.findIndex(
          part => action.afterChapterId === part._id
        ) + 1,
        0,
        action.chapter
      );
      return {
        ...state,
        currentContentVersion: updateCurrentContentVersion
      };
    case FETCH_STATS:
      return {
        ...state,
        stats: action.stats
      };

    case FETCH_RATING:
      return {
        ...state,
        rating: action.rating
      };

    case UPDATE_RATING:
      return {
        ...state,
        rating: action.rating
      };

    case UPDATE_BOOK:
      // clone the existing writing list
      updatedWritingList = state.writing ? [...state.writing] : [];
      if (action.currentBook) {
        // replace the outdated book
        const bookIndex = updatedWritingList.findIndex(
          book => book._id === action.currentBook._id
        );
        updatedWritingList[bookIndex] = {
          ...updatedWritingList[bookIndex],
          ...action.currentBook
        };
      }
      return {
        ...state,
        // only update current book if there is one to update
        currentBook: state.currentBook ? action.currentBook : undefined,
        pendingBookChanges: {},
        savingData: false,
        writing: updatedWritingList
      };

    case UPDATE_BOOK_DETAILS:
      return {
        ...state,
        currentBook: {
          ...state.currentBook,
          ...action.details
        }
      };
    case DUPLICATED_BOOK:
      return {
        ...state,
        currentBook: {
          ...action.newBook
        }
      };

    case DELETED_BOOK:
      updatedWritingList = [...state.writing];
      updatedWritingList.splice(updatedWritingList.findIndex(action.bookId), 1);
      return {
        ...state,
        currentBook: undefined,
        writing: updatedWritingList
      };

    case UPDATE_PART:
      updatedBook = {
        ...state.currentBook,
        content: [...state.currentBook.content]
      };
      updatedBook.content.forEach(version => {
        const partIndex = version.parts.findIndex(
          part => part._id === action.part._id
        );
        if (partIndex > -1) {
          version.parts.splice(partIndex, 1, action.part);
        }
      });
      return {
        ...state,
        currentBook: updatedBook
      };

    case SAVING_DATA:
      return {
        ...state,
        savingData: action.saving
      };

    case ADD_PENDING_CHAPTERS:
      return {
        ...state,
        pendingChapters: action.chapters
      };

    case APPEND_PENDING_CHAPTERS:
      if (state.pendingChapters) {
        pendingChapters = [...state.pendingChapters].concat(action.chapters);
      } else {
        pendingChapters = [...action.chapters];
      }
      return {
        ...state,
        pendingChapters
      };

    case REMOVE_PENDING_CHAPTER:
      pendingChapters = [...state.pendingChapters];
      pendingChapters.splice(action.index, 1);
      if (pendingChapters.length === 0) {
        pendingChapters = null;
      }
      return {
        ...state,
        pendingChapters
      };

    case CHANGE_CHAPTER_UPLOAD_MESSAGE:
      return {
        ...state,
        chapterUploadMessage: action.message
      };

    case UPDATE_PENDING_BOOK_CHANGES:
      return {
        ...state,
        pendingBookChanges: {
          ...state.pendingBookChanges,
          ...action.data
        }
      };

    case UPDATE_PENDING_PART_CHANGES:
      return {
        ...state,
        pendingPartChanges: {
          ...state.pendingPartChanges,
          ...action.data
        }
      };

    case SET_SAVE_BUTTON:
      return {
        ...state,
        saveButton: action.button
      };

    case TOGGLE_CHAPTER_UPLOAD:
      // set chapter upload to a forced value if supplied,
      // or to the opposite of its current value if not supplied
      return {
        ...state,
        showChapterUpload:
          action.showChapterUpload !== undefined
            ? action.showChapterUpload
            : !state.showChapterUpload
      };
    case REMOVE_CONTENT_VERSION:
      updatedBook = {
        ...state.currentBook
      };
      versionIndex = updatedBook.content.findIndex(
        version => version._id === action.versionId
      );
      if (versionIndex >= 0) {
        updatedBook.content.splice(versionIndex, 1);
      }
      return {
        currentBook: updatedBook
      };
    case UPDATE_READER_STATE:
      return {
        ...state,
        currentBook: {
          ...state.currentBook,
          state: action.state
        }
      };
    case REMOVE_READER_ENTRY:
      let {reader, ...bookWithoutReader} = state.currentBook;
      return {
        ...state,
        currentBook: bookWithoutReader
      };
    case UPDATE_FOLLOW_STATUS:
      return {
        ...state,
        followStatus: {
          ...state.followStatus,
          [action.followStatus.book]: {
            ...action.followStatus
          }
        }
      };
    case SET_SWAPPABLE_BOOK:
      return {
        ...state,
        swappableBook: action.book
      };
    case SHOW_CURRENT_PART_TITLE:
      return {
        ...state,
        currentPartTitle: action.title
      };
    case SET_IS_FETCHING_WRITING_LIST:
      return {
        ...state,
        isFetchingWritingList: action.value
      };
    case SET_IS_FETCHING_COLLABORATIONS_LIST:
      return {
        ...state,
        isFetchingCollaborationsList: action.value
      };
    default:
      return state;
  }
};

// actions
export const updateWritingList =
  ({idToken, accountId}) =>
  dispatch => {
    dispatch({
      type: SET_IS_FETCHING_WRITING_LIST,
      value: true
    });
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books?filter=own&account=${accountId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => {
        if (res.status === 500) {
          // unknown server error
          throw new Error('Failed to load book list');
        }
        return res.json();
      })
      .then(list => {
        return dispatch({
          type: UPDATE_WRITING_LIST,
          list
        });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_FETCHING_WRITING_LIST,
          value: false
        });
      });
  };

// actions
export const updateCollaborationList =
  ({idToken}) =>
  dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books?filter=collaborator`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => {
        if (res.status === 500) {
          // unknown server error
          throw new Error('Failed to load book list');
        }
        return res.json();
      })
      .then(list => {
        return dispatch({
          type: UPDATE_COLLABORATION_LIST,
          list
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

export const addedNewBook = book => dispatch => {
  dispatch({
    type: ADDED_NEW_BOOK,
    newBook: book
  });
};

export const updateReadingList = idToken => dispatch => {
  fetch(
    `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books?filter=reading`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    }
  )
    .then(res => res.json())
    .then(list =>
      dispatch({
        type: UPDATE_READING_LIST,
        list: list
      })
    );
};

export const fetchCurrentBookAsReader =
  (idToken, bookId, contentVersionId) => dispatch => {
    let path = `/ereader/books/${bookId}`;
    if (contentVersionId !== undefined) {
      path += `/content/${contentVersionId}`;
    }
    dispatch({
      type: FETCHING_CURRENT_BOOK,
      loadingCurrentBook: true
    });
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}${path}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => {
        if (res.status === 500) {
          // unknown server error
          throw new Error('Failed to load book');
        } else {
          // specific book error. display to users
          return res.json();
        }
      })
      .then(book => {
        if (book?.parts) {
          book.parts.forEach(item => {
            if (item.html === undefined) {
              // TODO: why is this here? can we remove it?
              const html = draftToHtml(item.content, null, null, null);
              item.html = html;
            }
          });
        }
        return dispatch({
          type: FETCH_CURRENT_BOOK,
          book
        });
      })
      .catch(error => {
        if (error.redirect) {
          window.location = error.redirect;
        } else {
          toast.error(`${error.err}`, {autoClose: false});
          uxAnalyticsUtil.trackException({
            description: error.err,
            fatal: true
          });
        }
      })
      .finally(() => {
        dispatch({
          type: FETCHING_CURRENT_BOOK,
          loadingCurrentBook: false
        });
      });
  };

export const fetchCurrentBookAndChapterContent = (
  idToken,
  bookId,
  chapterId
) => {
  return dispatch => {
    fetch(`${process.env.REACT_APP_API_HOST}/api/books/${bookId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    })
      .then(res => {
        return res.json();
      })
      .then(book => {
        if (book !== undefined) {
          fetch(
            `${process.env.REACT_APP_API_HOST}/api/books/${bookId}/chapters/${chapterId}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'br-token': idToken
              }
            }
          )
            .then(res => res.json())
            .then(result => {
              if (!!result && result.chapter !== undefined) {
                return dispatch({
                  type: FETCH_CURRENT_BOOK_AND_CHAPTER_CONTENT,
                  book,
                  chapter: result.chapter
                });
              }
            });
        }
      });
  };
};

export const fetchCurrentBook =
  ({idToken, bookId, token}, callback) =>
  dispatch => {
    dispatch({
      type: FETCHING_CURRENT_BOOK,
      loadingCurrentBook: true
    });
    fetch(
      `${process.env.REACT_APP_API_HOST}${
        process.env.REACT_APP_API_PATH
      }/books/${bookId}?${queryString.stringify({token})}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => res.json())
      .then(book => {
        if (book.err) {
          throw new Error(book.msg ?? book.err);
        }
        if (book !== undefined) {
          dispatch({
            type: FETCH_CURRENT_BOOK,
            book
          });
          if (book.content && book.content.length > 0) {
            dispatch({
              type: UPDATE_CURRENT_CONTENT_VERSION,
              version: book.content[book.content.length - 1] // this will remove the pending book
            });
          }
        }
      })
      .catch(err => {
        console.error(err);
        uxAnalyticsUtil.trackException({
          description: err.toString(),
          fatal: true
        });
        if (callback) {
          return callback({
            errorMessage: err.message
          });
        }
      })
      .finally(() => {
        dispatch({
          type: FETCHING_CURRENT_BOOK,
          loadingCurrentBook: false
        });
        if (callback) {
          return callback(true);
        }
      });
  };

export const fetchSwappableBook = (idToken, bookId) => dispatch => {
  fetch(
    `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    }
  )
    .then(res => {
      if (!res.ok) {
        throw new Error(res.error);
      } else {
        return res.json();
      }
    })
    .then(book => {
      if (book !== undefined) {
        dispatch({
          type: SET_SWAPPABLE_BOOK,
          book
        });
      }
    })
    .catch(err => {
      toast.error(i18n.t('FailedToLoadBook'));
      console.error(err);
      uxAnalyticsUtil.trackException({
        description: err.toString(),
        fatal: true
      });
    })
    .finally(() => {});
};

export const fetchSwappables = idToken => dispatch => {
  fetch(
    `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books?filter=swappable`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    }
  )
    .then(res => res.json())
    .then(swappables =>
      dispatch({
        type: SET_SWAPPABLES,
        swappables
      })
    );
};

export const resetCurrentBook = () => dispatch =>
  dispatch({
    type: UPDATE_BOOK,
    currentBook: undefined
  });

export const resetSwappableBook = () => dispatch =>
  dispatch({
    type: SET_SWAPPABLE_BOOK,
    book: undefined
  });

export const updateCurrentBook = updatedBook => {
  return dispatch => {
    return dispatch({
      type: UPDATE_BOOK,
      currentBook: updatedBook
    });
  };
};

export const fetchCurrentChapterContent = (idToken, bookId, chapterId) => {
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}/api/books/${bookId}/chapters/${chapterId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => res.json())
      .then(result => {
        return dispatch({
          type: FETCH_CURRENT_CHAPTER_CONTENT,
          chapter: result.chapter
        });
      });
  };
};

export const fetchCurrentChapter = (idToken, bookId, chapterId, callback) => {
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/chapters/${chapterId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => res.json())
      .then(chapter => {
        if (!!chapter) {
          dispatch({
            type: SET_CURRENT_CHAPTER,
            chapter
          });
          callback && callback();
          return;
        }
      });
  };
};

export const setCurrentChapter = chapter => {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_CHAPTER,
      chapter
    });
  };
};

export const updateCurrentChapter = updatedChapter => {
  return dispatch => {
    return dispatch({
      type: UPDATE_CURRENT_CHAPTER,
      chapter: updatedChapter
    });
  };
};

export const updatePart = (idToken, book, partData) => {
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${
        process.env.REACT_APP_API_PATH
      }/books/${book._id}/${partData.kind.toLowerCase()}s/${partData._id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify(partData)
      }
    )
      .then(res => res.json())
      .then(part => {
        return dispatch({
          type: UPDATE_PART,
          part
        });
      })
      .catch(err => {
        uxAnalyticsUtil.trackException({
          description: 'Failed to update a part',
          fatal: false
        });
      });
  };
};

export const addPart = (newPart, versionNumber) => {
  return dispatch => {
    dispatch({
      type: ADD_PARTS,
      parts: [newPart],
      version: versionNumber
    });
  };
};

export const replacePart = (updatedPart, versionNumber) => {
  return dispatch => {
    dispatch({
      type: REPLACE_PART,
      part: updatedPart,
      version: versionNumber
    });
  };
};

export const fetchStats = (idToken, bookId, chapterId, filters) => {
  let path = `/api/books/${bookId}`;
  if (chapterId !== undefined) {
    path += `/chapters/${chapterId}`;
  }
  path += '/stats';
  if (filters !== undefined) {
    filters.forEach((filter, index) => {
      if (index === 0) {
        path += '?';
      } else {
        path += '&';
      }
      path += `${filter.filterName}=${filter.filterValue}`;
    });
  }

  return dispatch => {
    fetch(`${process.env.REACT_APP_API_HOST}${path}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    })
      .then(res => res.json())
      .then(stats => {
        if (stats) {
          return dispatch({
            type: FETCH_STATS,
            stats
          });
        }
      });
  };
};

export const fetchRating = (idToken, bookId, chapterId, userId) => {
  const path = `/api/books/${bookId}/chapters/${chapterId}/ratings/${userId}`;

  return dispatch => {
    fetch(`${process.env.REACT_APP_API_HOST}${path}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data !== undefined) {
          return dispatch({
            type: FETCH_RATING,
            rating: data.rating
          });
        }
      });
  };
};

export const updateRating = (idToken, bookId, chapterId, userId, data) => {
  const path = `/api/books/${bookId}/chapters/${chapterId}/ratings/${userId}`;
  return dispatch => {
    fetch(`${process.env.REACT_APP_API_HOST}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      },
      body: JSON.stringify({
        category: data.category,
        rating: data.rating
      })
    })
      .then(res => res.json())
      .then(rating => {
        if (rating) {
          return dispatch({
            type: UPDATE_RATING,
            rating
          });
        }
      });
  };
};

export const updatePendingBookChanges = data => dispatch => {
  dispatch({
    type: UPDATE_PENDING_BOOK_CHANGES,
    data
  });
};

export const updatePendingPartChanges = data => dispatch => {
  dispatch({
    type: UPDATE_PENDING_PART_CHANGES,
    data
  });
};

export const setSaveButton = button => dispatch => {
  dispatch({
    type: SET_SAVE_BUTTON,
    button
  });
};

export const saveBook = (idToken, book) => {
  return dispatch => {
    dispatch({
      type: SAVING_CURRENT_BOOK
    });
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${book._id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify(book)
      }
    )
      .then(res => {
        return res.json();
      })
      .then(updatedBook => {
        if (updatedBook.err) {
          throw new Error(updatedBook.err);
        }
        uxAnalyticsUtil.trackEvent({
          category: 'Book Management',
          action: 'updated-book'
        });
        if (updatedBook !== undefined) {
          dispatch({
            type: UPDATE_BOOK,
            currentBook: updatedBook
          });
          dispatch({
            type: SET_SAVE_BUTTON,
            button: undefined
          });
        }
      })
      .catch(error => {
        uxAnalyticsUtil.trackException({
          description: 'Failed to update a book',
          fatal: false
        });
        toast.error(`${i18n.t('SomethingWentWrong')}: ${error.message}`);
      });
  };
};

export const updateBookDetails = data => {
  return dispatch => {
    dispatch({
      type: UPDATE_BOOK_DETAILS,
      details: data
    });
  };
};

export const deletePart = (idToken, book, partId, currentContentVersion) => {
  const bookId = book._id;
  const contentVersionIndex = book.content.findIndex(contentVersion => {
    return contentVersion._id === currentContentVersion._id;
  });
  const partIndex = book.content[contentVersionIndex].parts.findIndex(part => {
    return part._id === partId;
  });

  if (contentVersionIndex < 0) {
    return;
  }

  const part = book.content[contentVersionIndex].parts[partIndex];

  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${
        process.env.REACT_APP_API_PATH
      }/books/${bookId}/${part.kind.toLowerCase()}s/${partId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => {
        if (res.ok) {
          uxAnalyticsUtil.trackEvent({
            category: 'Book Management',
            action: 'deleted-chapter'
          });

          if (partIndex >= 0) {
            // remove part from content
            var updatedContent = [...book.content[contentVersionIndex].parts];
            updatedContent.splice(partIndex, 1);
            book.content[contentVersionIndex].parts = updatedContent;
          }
          return dispatch({
            type: UPDATE_BOOK,
            currentBook: {...book}
          });
        } else {
          throw new Error('Failed to delete chapter');
        }
      })
      .catch(err => {
        uxAnalyticsUtil.trackException({
          description: 'Failed to delete a chapter',
          fatal: false
        });
      });
  };
};

export const duplicatePart = (idToken, book, partId, currentContentVersion) => {
  const bookId = book._id;
  const contentVersionIndex = book.content.findIndex(
    contentVersion => contentVersion._id === currentContentVersion._id
  );
  const partIndex = book.content[contentVersionIndex].parts.findIndex(
    part => part._id === partId
  );

  if (contentVersionIndex < 0) {
    return;
  }

  const part = book.content[contentVersionIndex].parts[partIndex];

  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${
        process.env.REACT_APP_API_PATH
      }/books/${bookId}/${part.kind.toLowerCase()}s/${partId}/duplicate`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify({
          contentVersionId: currentContentVersion._id
        })
      }
    )
      .then(res => res.json())
      .then(updatedPart => {
        if (updatedPart && !updatedPart.err) {
          let updatedContent = [...book.content[contentVersionIndex].parts];
          updatedContent.splice(partIndex + 1, 0, updatedPart);
          book.content[contentVersionIndex].parts = updatedContent;
          dispatch({
            type: UPDATE_BOOK,
            currentBook: {...book}
          });
        }
      })
      .catch(err => {
        uxAnalyticsUtil.trackException({
          description: 'Failed to duplicate part',
          fatal: false
        });
      });
  };
};

export const movePart = (
  idToken,
  book,
  contentVersion,
  partId,
  newPartIndex
) => {
  if (!contentVersion) {
    return;
  }
  if (newPartIndex < 0 || newPartIndex >= contentVersion.parts.length) {
    return;
  }
  // find the part
  const part = contentVersion.parts.find(entry => entry._id === partId);
  if (!part) {
    return;
  }
  // remove it from the list
  const newPartsList = contentVersion.parts.filter(
    entry => entry._id !== partId
  );

  // put it back in
  newPartsList.splice(newPartIndex, 0, part);

  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${book._id}/content/${contentVersion._id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify({parts: newPartsList})
      }
    )
      .then(res => {
        if (res.ok) {
          uxAnalyticsUtil.trackEvent({
            category: 'Book Management',
            action: 'updated-parts-order'
          });

          return res.json();
        } else {
          throw new Error('Failed to delete chapter');
        }
      })
      .then(updatedBook => {
        return dispatch({
          type: UPDATE_BOOK,
          currentBook: {...updatedBook}
        });
      })
      .catch(err => {
        uxAnalyticsUtil.trackException({
          description: 'Failed to delete a chapter',
          fatal: false
        });
      });
  };
};

export const updateContentVersion = (idToken, options, callback) => {
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${options.bookId}/content/${options.contentVersionId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify({
          parts: options.parts
        })
      }
    )
      .then(res => {
        if (res.ok) {
          uxAnalyticsUtil.trackEvent({
            category: 'Book Management',
            action: 'updated-content-version'
          });
          return res.json();
        }
        throw new Error('Failed to delete chapter');
      })
      .then(updatedBook => {
        dispatch({
          type: UPDATE_BOOK,
          currentBook: updatedBook
        });
        const updatedContentVersion = updatedBook.content.find(
          version => version._id === options.contentVersionId
        );
        dispatch({
          type: UPDATE_CURRENT_CONTENT_VERSION,
          version: updatedContentVersion
        });
        if (callback) {
          callback(true);
        }
      })
      .catch(err => {
        uxAnalyticsUtil.trackException({
          description: 'Failed to delete a chapter',
          fatal: false
        });
        console.error(err);
        if (callback) {
          callback(false);
        }
      });
  };
};

export const duplicateBook = (idToken, book, callback) => {
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${book._id}/duplicate`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify({})
      }
    )
      .then(res => {
        if (res.ok) {
          uxAnalyticsUtil.trackEvent({
            category: 'Book Management',
            action: 'duplicated-book'
          });
          return res.json();
        } else {
          throw new Error('Failed to duplicate book');
        }
      })
      .then(newBook => {
        const newPath = `/books/${newBook._id}`;
        callback(newPath);
      })
      .catch(err => {
        uxAnalyticsUtil.trackException({
          description: 'Failed to delete a chapter',
          fatal: false
        });
      });
  };
};

export const deleteBook = (idToken, book, callback) => dispatch => {
  fetch(
    `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${book._id}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      }
    }
  )
    .then(res => {
      if (res.ok) {
        uxAnalyticsUtil.trackEvent({
          category: 'Book Management',
          action: 'deleted-book'
        });
        callback();
        dispatch({
          type: DELETED_BOOK,
          bookId: book._id
        });
      } else {
        throw new Error('Failed to delete a book');
      }
    })
    .catch(err => {
      uxAnalyticsUtil.trackException({
        description: 'Failed to delete a book',
        fatal: false
      });
    });
};

export const addPendingChapters = chapters => {
  return dispatch => {
    dispatch({
      type: ADD_PENDING_CHAPTERS,
      chapters: chapters
    });
  };
};

export const appendPendingChapters = chapters => {
  return dispatch => {
    dispatch({
      type: APPEND_PENDING_CHAPTERS,
      chapters: chapters
    });
  };
};

export const changeUploadMessage = message => {
  return dispatch => {
    dispatch({
      type: CHANGE_CHAPTER_UPLOAD_MESSAGE,
      message: message
    });
  };
};

export const removePendingChapter = index => {
  return dispatch => {
    dispatch({
      type: REMOVE_PENDING_CHAPTER,
      index: index
    });
  };
};

export const removePendingChapters = () => {
  return dispatch => {
    dispatch({
      type: ADD_PENDING_CHAPTERS,
      chapters: null // this will remove the pending chapters
    });
  };
};

export const toggleChapterUpload = showChapterUpload => {
  return dispatch => {
    dispatch({
      type: TOGGLE_CHAPTER_UPLOAD,
      showChapterUpload
    });
  };
};

export const addChapters = (
  idToken,
  bookId,
  contentVersionId,
  chapters,
  callback
) => {
  return dispatch => {
    dispatch({
      type: ADD_PENDING_CHAPTERS,
      chapters: null // this will remove the pending chapters
    });
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/content/${contentVersionId}/chapters`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify(chapters)
      }
    )
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Failed to bulk upload chapters');
      })
      .then(savedChapters => {
        if (savedChapters !== undefined) {
          if (callback) {
            callback(savedChapters);
          }
          return dispatch({
            type: ADD_PARTS,
            version: contentVersionId,
            parts: savedChapters
          });
        }
      })
      .catch(err => {
        uxAnalyticsUtil.trackException({
          description: err.toString(),
          fatal: false
        });
      })
      .finally(() => {
        dispatch({
          type: SAVING_DATA,
          saving: false
        });
      });
  };
};

export const createNewVersion = (
  idToken,
  bookId,
  chapters = [],
  callback = null
) => {
  return dispatch => {
    dispatch({
      type: ADD_PENDING_CHAPTERS,
      chapters: null // this will remove the pending chapters
    });
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/content`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify(chapters)
      }
    )
      .then(res => {
        if (res.ok) {
          uxAnalyticsUtil.trackEvent({
            category: 'Book Management',
            action: 'created-new-version'
          });
          return res.json();
        }
        throw new Error('Failed to bulk upload new version');
      })
      .then(updatedBook => {
        if (updatedBook !== undefined) {
          if (callback) {
            callback(updatedBook);
          }
          return dispatch({
            type: UPDATE_CURRENT_BOOK,
            book: updatedBook
          });
        }
      })
      .catch(err => {
        dispatch({
          type: SAVING_DATA,
          saving: false
        });
        uxAnalyticsUtil.trackException({
          description: err.toString(),
          fatal: false
        });
      });
  };
};

export const duplicateVersion =
  (
    idToken,
    {bookId, contentVersionId, copyComments, migrateReaders, callback}
  ) =>
  dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/content/${contentVersionId}/duplicate`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify({
          copyComments,
          migrateReaders
        })
      }
    )
      .then(res => {
        if (res.ok) {
          uxAnalyticsUtil.trackEvent({
            category: 'Book Management',
            action: 'duplicated-version'
          });
          return res.json();
        }
        throw new Error('Failed to bulk upload new version');
      })
      .then(updatedBook => {
        if (updatedBook !== undefined) {
          if (callback) {
            callback(updatedBook);
          }
          return dispatch({
            type: UPDATE_CURRENT_BOOK,
            book: updatedBook
          });
        }
      })
      .catch(err => {
        console.error(err);
        toast.error(i18n.t('SomethingWentWrong'));
        uxAnalyticsUtil.trackException({
          description: err.toString(),
          fatal: true
        });
      });
  };

export const deleteVersion = (idToken, bookId, contentVersionId, callback) => {
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/content/${contentVersionId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Failed to bulk delete version');
      })
      .then(() => {
        dispatch({
          type: REMOVE_CONTENT_VERSION,
          versionId: contentVersionId
        });
        toast.success(i18n.t('DeletedVersion'));
        if (callback) {
          return callback();
        }
        return;
      })
      .catch(err => {
        console.error(err);
        toast.error(i18n.t('SomethingWentWrong'));
        uxAnalyticsUtil.trackException({
          description: err.toString(),
          fatal: true
        });
      });
  };
};

export const setCurrentContentVersion = newVersion => dispatch => {
  console.log('setCurrentContentVersion', newVersion);
  dispatch({
    type: UPDATE_CURRENT_CONTENT_VERSION,
    version: newVersion // this will remove the pending book
  });
};

export const createBookAndChapters = (idToken, book, callback) => {
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify(book)
      }
    )
      .then(res => res.json())
      .then(newBook => {
        uxAnalyticsUtil.trackEvent({
          category: 'Book Management',
          action: 'added-book'
        });
        dispatch({
          type: ADDED_NEW_BOOK,
          newBook
        });
        // move to the newly created book :)
        if (callback) {
          callback(newBook);
        }
        return dispatch;
      })
      .catch(err => {
        uxAnalyticsUtil.trackException({
          description: 'Failed to add book',
          fatal: true
        });
      });
  };
};

export const sendJoinBetaRequest =
  (idToken, bookId, options, callback) => dispatch => {
    dispatch({
      type: UPDATE_READER_STATE,
      state: options.swap ? 'sending-swap-request' : 'sending-read-request'
    });
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/ereader/discover/${bookId}/join`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify(options)
      }
    )
      .then(res => {
        if (!res.ok) {
          throw new Error(`Failed to send join beta request for ${bookId}`);
        }
        return res.json();
      })
      .then(updatedBook => {
        dispatch({
          type: UPDATE_CURRENT_BOOK_READER_STATUS,
          reader: updatedBook.reader
        });
        if (callback) {
          callback();
        }
      })
      .catch(err => {
        toast.error(err.toString());
        console.error(err);
        uxAnalyticsUtil.trackException({
          description: err.toString(),
          fatal: true
        });
      });
  };

export const withdrawJoinBetaRequest =
  (idToken, bookId, readerId, callback) => dispatch => {
    dispatch({
      type: UPDATE_READER_STATE,
      state: 'withdrawing'
    });
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/ereader/discover/${bookId}/join-requests/${readerId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => {
        if (!res.status === 200) {
          throw new Error(`Failed to send join beta request for ${bookId}`);
        }
        return res.json();
      })
      .then(() => {
        dispatch({
          type: UPDATE_READER_STATE,
          state: undefined
        });
        dispatch({
          type: REMOVE_READER_ENTRY
        });
        if (callback) {
          callback();
        }
      })
      .catch(err => {
        toast.error('SomethingWentWrong');
        console.error(err);
      });
  };

export const updateJoinBetaRequest =
  (idToken, bookId, options, callback) => dispatch => {
    dispatch({
      type: UPDATE_READER_STATE,
      state: 'updating'
    });
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/ereader/discover/${bookId}/join`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify(options)
      }
    )
      .then(res => {
        if (res.status === 200) {
          return res.json();
        }
        throw new Error(`Failed to update join beta request for ${bookId}`);
      })
      .then(updatedBook => {
        if (callback) {
          if (
            updatedBook &&
            updatedBook.reader &&
            updatedBook.reader.invitation &&
            updatedBook.reader.invitation.status === 'REQUESTED'
          ) {
            callback(false);
          } else {
            callback(true);
          }
        }
        return dispatch({
          type: UPDATE_CURRENT_BOOK,
          book: updatedBook
        });
      })
      .catch(err => {
        console.error(err);
        if (callback) {
          callback(false);
        }
      });
  };

export const getFollowStatus =
  ({idToken, bookId}) =>
  dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/follow-status?book=${bookId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => {
        if (res.status === 200) {
          return res.json();
        }
        throw new Error(`Failed to get follow status for ${bookId}`);
      })
      .then(status =>
        dispatch({
          type: UPDATE_FOLLOW_STATUS,
          followStatus: status
        })
      )
      .catch(err => {
        console.error(err);
      });
  };

export const toggleFollowStatus =
  ({idToken, bookId, action}) =>
  dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/follow-status?book=${bookId}&action=${action}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => {
        if (res.status === 200) {
          return res.json();
        }
        throw new Error(`Failed to update join beta request for ${bookId}`);
      })
      .then(status =>
        dispatch({
          type: UPDATE_FOLLOW_STATUS,
          followStatus: status
        })
      )
      .catch(err => {
        console.error(err);
      });
  };

export const showCurrentPartTitle = title => dispatch => {
  dispatch({
    type: SHOW_CURRENT_PART_TITLE,
    title
  });
};

export const updateContentWithChapterSplitResult = ({
  chapterId,
  updatedOriginalChapter,
  newChapter
}) => {
  return dispatch => {
    // update the current chapter
    dispatch({
      type: UPDATE_CURRENT_CHAPTER,
      chapter: updatedOriginalChapter
    });
    // splice in the new chapter
    dispatch({
      type: SPLICE_IN_NEW_CHAPTER,
      afterChapterId: chapterId,
      chapter: newChapter
    });
  };
};
