import React, { Component } from 'react';
import {
  TextArea, Button, Header, Form
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

class BioGetter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bio: props.initialValue || ''
    };
  }

  handleBioChange = (event, data) => {
    this.setState({ bio: data.value });
  }

  submit = () => {
    const { bio } = this.state;
    if (this.props.onUpdate) {
      this.props.onUpdate({ bio })
    }
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 13 && event.metaKey) {
      // CMD + enter
      this.submit();
    }
  }

  // render
  render() {
    const { t, updating, wizardMode } = this.props;
    const { bio } = this.state;
    return [
      <Header
        key='header'
        size='small'
        content={t('Bio')}
        subheader={t('OnYourProfile')}
      />,
      <Form key='input'>
        <TextArea
          placeholder={t('BioPlaceholder')}
          value={bio}
          onChange={this.handleBioChange}
          onKeyDown={this.handleKeyDown}
        />
      </Form>,
      <Button
        style={{ marginTop: '1em' }}
        key='next'
        floated='right'
        compact
        content={wizardMode ? (bio.length === 0 ? t('Skip') : t('Next')) : t('Save')}
        loading={!!updating}
        onClick={this.submit}
      />
    ];
  }
}

export default withTranslation()(BioGetter)
