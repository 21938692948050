import queryString from 'query-string';

export const LOADING_NOTIFICATIONS = 'notifications/LOADING_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'notifications/SET_NOTIFICATIONS';
export const ADD_OLDER_NOTIFICATIONS = 'notifications/ADD_OLDER_NOTIFICATIONS';
export const ADD_NEWER_NOTIFICATIONS = 'notifications/ADD_NEWER_NOTIFICATIONS';
export const UPDATE_NOTIFICATIONS = 'notifications/UPDATE_NOTIFICATIONS';
export const UPDATE_UNREAD_COUNT = 'notifications/UPDATE_UNREAD_COUNT';
export const DECREASE_UNREAD_COUNT = 'notifications/DECREASE_UNREAD_COUNT';
export const MARK_ALL_NOTIFICATIONS_AS_READ = 'notifications/MARK_ALL_NOTIFICATIONS_AS_READ';

const initialState = {
  notifications: [],
  unreadCount: 0,
  loadingNotifications: false
};

// reducers
export default (state = initialState, action) => {
  let updatedNotifications;
  let updatedUnreadCount;
  switch (action.type) {
    case LOADING_NOTIFICATIONS:
      return {
        ...state,
        loadingNotifications: action.value
      }
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: [...action.notifications]
      };
    case ADD_OLDER_NOTIFICATIONS: {
      const withOlder = state.notifications ? [...state.notifications] : [];
      withOlder.push(...action.notifications);
      return {
        ...state,
        notifications: withOlder
      };
    }
    case ADD_NEWER_NOTIFICATIONS: {
      const withNewer = state.notifications ? [...state.notifications] : [];
      withNewer.unshift(...action.notifications);
      return {
        ...state,
        notifications: withNewer
      };
    }
    case UPDATE_NOTIFICATIONS:
      // create a copy of the notifications list
      updatedNotifications = [...state.notifications];
      // go through all updated notificaitons and replace their corresponding existing ones
      action.notifications.forEach((notification) => {
        const index = updatedNotifications.findIndex(entry => entry._id === notification._id);
        if (index >= 0) {
          updatedNotifications.splice(index, 1, notification);
        }
      });
      return {
        ...state,
        notifications: updatedNotifications
      };
    case UPDATE_UNREAD_COUNT:
      return {
        ...state,
        unreadCount: action.unreadCount
      };
    case DECREASE_UNREAD_COUNT:
      updatedUnreadCount = (state.unreadCount - action.amount) || 0;
      return {
        ...state,
        unreadCount: updatedUnreadCount
      };
    case MARK_ALL_NOTIFICATIONS_AS_READ:
      return {
        ...state,
        unreadCount: 0,
        notifications: [...state.notifications]
          .map(notification => ({ ...notification, read: true }))
      };
    default:
      return state;
  }
};

export const fetchNotifications = (idToken, options) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_NOTIFICATIONS,
      value: true
    });
    const query = options ? queryString.stringify(options) : '';
    fetch(`${process.env.REACT_APP_API_HOST}/api/v2/notifications?${query}`, {
      method: 'GET',
      headers: {
        'br-token': idToken
      }
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        }
        return res.json()
          .then(({ unreadCount, notifications }) => {
            let type;
            if (options.before) {
              type = ADD_OLDER_NOTIFICATIONS;
            } else if (options.after) {
              type = ADD_NEWER_NOTIFICATIONS;
            } else {
              type = SET_NOTIFICATIONS;
            }
            dispatch({
              // if we have before or after timestamps, add to the notifications list
              // otherwise reset it
              type,
              notifications
            });
            dispatch({
              type: UPDATE_UNREAD_COUNT,
              unreadCount
            });
          });
      })
      .catch((e) => {
        console.log('error', e);
      })
      .finally(() => {
        dispatch({
          type: LOADING_NOTIFICATIONS,
          value: false
        });
      });
  };
};

export const markNotificationsAsRead = (idToken, notificationIds) => {
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_API_HOST}/api/v2/notifications/actions/mark-as-read`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      },
      body: JSON.stringify({ notificationIds })
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        }
        return res.json()
          .then((notifications) => {
            dispatch({
              type: UPDATE_NOTIFICATIONS,
              notifications
            });
            dispatch({
              type: DECREASE_UNREAD_COUNT
            })
          });
      })
      .catch(() => {
      })
      .finally(() => {
      });
  };
};

export const markAllNotificationsAsRead = (idToken) => {
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_API_HOST}/api/v2/notifications/actions/mark-as-read`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      },
      body: JSON.stringify({ all: true })
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        }
        dispatch({
          type: MARK_ALL_NOTIFICATIONS_AS_READ
        });
      })
      .catch(() => {
      })
      .finally(() => {
      });
  };
};
