import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';

// Components
import {
  Menu, Modal, Header, Grid
} from 'semantic-ui-react';

import { accessUtil, authUtil, productCapUtil } from '../../utils';

import PublishingSettings from '../book/detail/publishingSettings';
import SharingWidget from '../../components/sharing/sharingWidget';
import InvitationForm from '../readers/invitationForm';
import ProductLimitMessage from '../../components/standard-messages/productLimitMessage';
import JoinBetaFormDesigner from '../readers/signup-form/joinBetaFormDesigner';

import {
  fetchReaders
} from '../../modules/reader';
import {
  updateCurrentBook
} from '../../modules/book';
import { MinWidthSpan } from '../../style';


export class ShareBookMenuItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false
    };
  }

  showItem = pathname => pathname !== '/read';

  toggleSharingModal = () => {
    this.setState(prevState => ({
      openModal: !prevState.openModal
    }), () => {
      this.fetchReaders();
    });
  }

  fetchReaders = async () => {
    const idToken = await authUtil.getFreshIdToken();
    const { currentBook } = this.props;
    if (currentBook && accessUtil.isAllowed(currentBook.access, ['edit'])) {
      this.props.fetchReaders(idToken, currentBook._id);
    }
  }

  updateReaderSignupQuestions = async (questions) => {
    const idToken = await authUtil.getFreshIdToken();
    const { currentBook } = this.props;

    // create the survey object if it doesn't exist already, then load it with the fresh questions
    let updatedSurvey;
    if (currentBook.readerSignupSurvey) {
      updatedSurvey = {
        ...updatedSurvey,
        questions
      };
    } else {
      updatedSurvey = {
        book: currentBook._id,
        questions
      };
    }
    updatedSurvey.placement = 'READER_SIGNUP';
    // save the survey
    fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${currentBook._id}/surveys/reader-signup-survey`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken
      },
      body: JSON.stringify(updatedSurvey)
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error);
        }
        return res.json();
      })
      .then((updatedSurvey) => {
        if (updatedSurvey !== undefined) {
          // now save the book with the new or updated survey
          this.props.updateCurrentBook({
            ...currentBook,
            readerSignupSurvey: updatedSurvey
          })
        }
      })
      .catch((err) => {
        toast.error('SomethingWentWrong');
        console.error(err);
      });
  }

  render() {
    const {
      t, userProfile, activeAccount, location, currentBook, readers
    } = this.props;
    const { openModal } = this.state;

    if (currentBook === undefined
      || currentBook._id === undefined
      || userProfile === undefined
      || !this.showItem(location.pathname)
    ) {
      return null;
    }

    const canAddMoreReaders = productCapUtil.canAddMoreReaders({
      activeAccount, readers, currentBook
    });
    const showProductLimitMessage = !canAddMoreReaders
      && currentBook.privacy !== 'unpublished'
      && currentBook.role === 'author';

    return [
      <Menu.Item
        fitted
        key='share-large-screen'
        link
        content={(
          currentBook.privacy === 'unpublished'
            ? <MinWidthSpan>{t('Publish')}</MinWidthSpan>
            : <MinWidthSpan>{t('Share')}</MinWidthSpan>
        )}
        icon='share'
        onClick={this.toggleSharingModal}
      />,
      <Modal closeIcon key='modal' open={openModal} onClose={this.toggleSharingModal} size='small'>
        <Modal.Header content={t('Share')} subheader={currentBook.title} />
        <Modal.Content>
          <Grid columns='equal' stackable>
            {
              accessUtil.isAllowed(currentBook.access, ['edit']) && (
                <Grid.Row>
                  <Grid.Column>
                    <Header key='privacy-header' sub content={t('PrivacyLevel')} />
                    <PublishingSettings
                      key='settings'
                      currentBook={currentBook}
                    />
                  </Grid.Column>
                </Grid.Row>
              )
            }
            <Grid.Row>
              {
                currentBook.role === 'author' && (
                  <Grid.Column>
                    <Header key='privacy-header' sub content={t('InviteByEmail')} />
                    <InvitationForm
                      key='email-invitation'
                      disabled={!canAddMoreReaders}
                      invitationSent={() => this.toggleSharingModal()}
                    />
                  </Grid.Column>
                )
              }
              <Grid.Column>
                <Header key='privacy-header' sub content={t('InviteByLink')} />
                <SharingWidget
                  disabled={['private', 'unpublished'].includes(currentBook.privacy)}
                  buttonSize='tiny'
                  utmCampaign='share-book'
                  url={`${process.env.REACT_APP_SHARE_API_HOST}/link/${currentBook._id}`}
                  message={`${t('Read')} ${currentBook.title} ${t('by')} ${currentBook.authorName}. ${(currentBook.genres || []).join(', ')}. ${currentBook.wordCount.toLocaleString()} ${t('words')}.`}
                  body={`${t('YouShouldBetaRead')} ${currentBook.title} ${t('by')} ${currentBook.authorName} ${t('onBetaReader')} ${process.env.REACT_APP_SHARE_API_HOST}/link/${currentBook._id}?${queryString.stringify({ utm_campaign: 'share-link', utm_source: 'email', utm_medium: 'button' })}`}
                  shareTo={['facebook', 'twitter', 'clipboard']}
                  hashtags={['writingcommunity', 'betareaders', 'amwriting']}
                  infoText={currentBook.privacy === 'private' ? t('SocialSharingIsNotPossibleForPrivateBooks') : null}
                />
              </Grid.Column>
            </Grid.Row>
            {
              accessUtil.isAllowed(currentBook.access, ['edit']) && (
                <Grid.Row>
                  <Grid.Column>
                    <JoinBetaFormDesigner
                      floated='right'
                      // asModal
                      // trigger='Button'
                      size='small'
                      bookTitle={currentBook.title}
                      questions={currentBook.readerSignupSurvey ? currentBook.readerSignupSurvey.questions : []}
                      onUpdate={this.updateReaderSignupQuestions}
                      readOnly={!accessUtil.isAllowed(currentBook.access, ['edit'])}
                    />
                  </Grid.Column>
                </Grid.Row>
              )
            }
            {
              showProductLimitMessage && (
                <Grid.Row>
                  <Grid.Column>
                    <ProductLimitMessage reachedCap='maxReaders' />
                  </Grid.Column>
                </Grid.Row>
              )
            }
          </Grid>
        </Modal.Content>
      </Modal>
    ];
  }
}

// redux stuff
const mapStateToProps = state => ({
  activeAccount: state.account.activeAccount,
  userProfile: state.user.userProfile,
  currentBook: state.book.currentBook,
  readers: state.reader.readers
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchReaders,
  updateCurrentBook,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareBookMenuItem)));
