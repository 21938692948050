import React, {Component} from 'react';
import qs from 'qs';
import {withRouter} from 'react-router-dom';
import {
  Checkbox,
  Grid,
  Button,
  Header,
  List,
  Image,
  Icon,
  Loader,
  Modal,
  Divider
} from 'semantic-ui-react';
import {withTranslation} from 'react-i18next';
import {Media} from 'src/utils/Media';

// redux
import {push} from 'connected-react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import moment from 'moment';
import {toast} from 'react-toastify';

import styled from 'styled-components';

import {updateUserProfile, fetchCountryOptions} from '../../modules/user';

// utils
import {uxAnalyticsUtil, authUtil} from '../../utils';
import Avatar from '../../components/avatar/avatar';

import GenreList from '../../components/lists/genreList';
import LanguageList from '../../components/lists/languageList';
import DisplayNameGetter from './userProfileBuilder/fields/displayName';
import YoBGetter from './userProfileBuilder/fields/yob';
import GenderGetter from './userProfileBuilder/fields/gender';
import GenresGetter from './userProfileBuilder/fields/genresGetter';
import LanguagesGetter from './userProfileBuilder/fields/languages';
import BioGetter from './userProfileBuilder/fields/bio';
import RoleGetter from './userProfileBuilder/fields/role';
import Country from './userProfileBuilder/fields/country';

import InviteReader from '../readerIndex/inviteReader';
import UserReviews from './userReviews/userReviews';

const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 1em;
`;

const CoverContainer = styled.div`
  min-height: 200px;
  height: 200px;
  width: 100%;
  align-items: center;
  display: flex;
  overflow: hidden;
  position: relative;
`;

const AvatarContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProfileContainer = styled.div`
  flex: 1;
  width: 100%;
`;

const EditIcon = (
  <Icon size='small' name='pencil' style={{marginLeft: 5, opacity: 0.4}} />
);

export class PublicUserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: undefined,
      userData: undefined
    };
  }

  componentDidMount() {
    const {props} = this;
    const {location} = props.history;
    uxAnalyticsUtil.trackPageView(
      location.pathname + location.hash + location.search
    );
    this.reset();
    this.getUserInfo();
    this.getUserData();
    this.getCountryOptions();
    const queryObject = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    if (queryObject.show) {
      this.setState({showModal: queryObject.show});
    }
  }

  reset = () => {
    this.setState({
      userInfo: undefined,
      userData: undefined
    });
  };

  getUserInfo = async () => {
    const {match, t} = this.props;
    const {userId, userProfile} = this.props;
    const matchUserId = match.params.userId;
    if (!matchUserId && !userId && !userProfile) {
      return; // we don't know who to look for
    }
    const idToken = await authUtil.getFreshIdToken();
    this.setState(
      {
        // clear old data and set refreshing to true
        refreshing: true,
        err: undefined
      },
      async () => {
        // has user ID, go ahead and fetch
        fetch(
          `${process.env.REACT_APP_API_HOST}${
            process.env.REACT_APP_API_PATH
          }/users/${matchUserId || userId || userProfile._id}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'br-token': idToken
            }
          }
        )
          .then(res => res.json())
          .then(userInfo => {
            if (userInfo.err) {
              return toast.error(t('SomethingWentWrong'));
            }
            // if the user had a profile
            this.setState({userInfo});
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => {
            this.setState({
              refreshing: false
            });
          });
      }
    );
  };

  getUserData = async () => {
    const {match} = this.props;
    const {userId, userProfile} = this.props;
    const matchUserId = match.params.userId;
    if (!matchUserId && !userId && !userProfile) {
      return; // we don't know who to look for
    }
    const idToken = await authUtil.getFreshIdToken();
    // has user ID, go ahead and fetch
    fetch(
      `${process.env.REACT_APP_API_HOST}${
        process.env.REACT_APP_API_PATH
      }/users/${matchUserId || userId || userProfile._id}/data`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => res.json())
      .then(userData => {
        // if the user had a profile
        this.setState({
          userData
        });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        this.setState({
          refreshing: false
        });
      });
  };

  getCountryOptions = async () => {
    const {userProfile} = this.props;
    const idToken = await authUtil.getFreshIdToken();

    this.props.fetchCountryOptions(
      idToken,
      (userProfile && userProfile.language) || 'en'
    );
  };

  getJoinDate = () => {
    const {userInfo} = this.state;
    if (
      Date.now() - new Date(userInfo.createdAt).getTime() >
      1000 * 60 * 60 * 24 * 30
    ) {
      // if more than a month ago, return the date
      return moment(userInfo.createdAt).format('L');
    }
    // otherwise return a time ago string
    return moment(userInfo.createdAt).fromNow();
  };

  isEditable = () => {
    const {userProfile} = this.props;
    const {userInfo} = this.state;
    return userProfile && userInfo && userInfo._id === userProfile._id;
  };

  showModal = fieldName => {
    if (!this.isEditable()) {
      // don't open the modal if we're not going to be able to edit it
      return;
    }
    this.setState({
      showModal: fieldName
    });
  };

  hideModal = () => {
    this.setState({
      showModal: false
    });
  };

  updateProfile = async data => {
    const {t} = this.props;
    const idToken = await authUtil.getFreshIdToken();
    if (idToken) {
      this.props.updateUserProfile(idToken, {...data}, success => {
        if (success) {
          // refresh user info if update was successful
          this.getUserInfo();
        } else {
          toast.error(t('SomethingWentWrong'));
        }
        // always close the modal
        this.hideModal();
      });
    }
  };

  getGenderLabel = genderKey => {
    const {t} = this.props;
    const genderKeys = {
      m: 'male',
      f: 'female',
      o: 'other',
      na: 'DontWantToShare'
    };
    if (genderKeys[genderKey]) {
      return t(genderKeys[genderKey]);
    }
    return t('NotSpecified');
  };

  handleInviteClick = () => {
    const {userInfo} = this.state;
    this.setState({showModal: {inviteReader: userInfo}});
  };

  handleLeaveReviewClick = event => {
    event.stopPropagation();
    this.setState({showModal: 'review'});
  };

  render() {
    const {userInfo, userData, refreshing, showModal} = this.state;
    const {t, countryOptions, userProfile} = this.props;
    if (refreshing) return <Loader />;
    if (!userInfo || userInfo.err) return null;
    const selectedCountry =
      countryOptions &&
      countryOptions.find(country => {
        const isMatch = country.key === userInfo.country;
        return isMatch;
      });
    // users can edit their own user data
    const editable = this.isEditable();
    return (
      <MainWrapper>
        <CoverContainer>
          {userInfo.coverURL && <Image fluid src={userInfo.coverURL} />}
          <AvatarContainer>
            <Avatar
              name={userInfo.displayName}
              src={userInfo.photoURL}
              diameter={112}
            />
          </AvatarContainer>
        </CoverContainer>
        <ProfileContainer>
          <Grid
            columns='equal'
            style={{paddingLeft: 5, paddingRight: 5}}
            stackable>
            <Grid.Row>
              <Grid.Column>
                <Header
                  content={
                    <div
                      className={editable ? 'editable' : undefined}
                      style={{display: 'flex', alignItems: 'center'}}
                      onClick={() => this.showModal('displayName')}
                      onKeyDown={event => {
                        if (event.keyCode === 13) {
                          this.showModal('displayName');
                        }
                      }}>
                      <span>{userInfo.displayName}</span>
                      {editable && EditIcon}
                      {userData && (
                        <span
                          style={{
                            marginLeft: 10,
                            fontSize: '0.7em',
                            cursor: 'pointer'
                          }}
                          onClick={this.handleLeaveReviewClick}
                          onKeyUp={this.handleLeaveReviewClick}>
                          <Icon
                            name='star'
                            color={userData.averageRating ? 'yellow' : 'grey'}
                            style={{marginRight: 0}}
                          />
                          {userData.averageRating}
                          {userData.reviewCount > 0 && (
                            <React.Fragment>
                              ({userData.reviewCount})
                            </React.Fragment>
                          )}
                        </span>
                      )}
                    </div>
                  }
                  subheader={
                    <div
                      style={{
                        fontWeight: 'normal',
                        fontSize: '0.7em',
                        opacity: 0.8
                      }}>
                      {t('Joined')}
                      {': '}
                      {this.getJoinDate()}
                      {userData && (
                        <React.Fragment>
                          <Media greaterThan='mobile'>
                            {() => <span>{' | '}</span>}
                          </Media>
                          <Media at='mobile'>{() => <br />}</Media>
                          {t('BooksRead', {x: userData.read || 0})}
                          {userData.avgTurnAroundTimeFor1kWords && (
                            <span>
                              <Media greaterThan='mobile'>
                                {() => <span>{' | '}</span>}
                              </Media>
                              <Media at='mobile'>{() => <br />}</Media>
                              {t('AvgTurnAroundTimePer1kWords', {
                                count: Number(
                                  userData.avgTurnAroundTimeFor1kWords
                                )
                              })}
                            </span>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  }
                />
                {
                  // if the current user IS NOT the owner of this profile,
                  // show the invitation button if applicable
                  userProfile._id !== userInfo._id &&
                    userInfo.readerListConsent && (
                      <Button.Group compact>
                        <Button
                          compact
                          icon='mail'
                          content={t('Invite')}
                          onClick={this.handleInviteClick}
                        />
                        {/* <Button
                        compact
                        icon='feed'
                        content={t('Follow')}
                        onClick={this.handleInviteClick}
                      /> */}
                      </Button.Group>
                    )
                }
                {
                  // if the current user IS the owner of this profile,
                  // show the reader database toggle
                  userProfile._id === userInfo._id && (
                    <React.Fragment>
                      <div>
                        <Checkbox
                          label={t('AvailableToRead')}
                          toggle
                          onChange={(event, data) =>
                            this.updateProfile({
                              readerListConsent: data.checked
                            })
                          }
                          checked={userInfo.readerListConsent}
                        />
                      </div>
                      {userInfo.readerListConsent && (
                        <div style={{fontSize: '0.8em'}}>
                          <em>{t('ReaderListConsentInfo')}</em>
                        </div>
                      )}
                    </React.Fragment>
                  )
                }
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Header sub content={t('UserInfo')} />
                <List>
                  <List.Item
                    className={editable ? 'editable' : undefined}
                    style={{display: 'flex', alignItems: 'center'}}
                    onClick={() => this.showModal('yob')}>
                    <Icon name='id badge outline' />
                    {t('Age')}
                    {': '}
                    {userInfo.age || t('NotSpecified')}
                    {editable && EditIcon}
                  </List.Item>
                  <List.Item
                    className={editable ? 'editable' : undefined}
                    style={{display: 'flex', alignItems: 'center'}}
                    onClick={() => this.showModal('gender')}>
                    <Icon name='transgender' />
                    {t('Gender')}
                    {': '}
                    {this.getGenderLabel(userInfo.gender)}
                    {editable && EditIcon}
                  </List.Item>
                  <List.Item
                    className={editable ? 'editable' : undefined}
                    style={{display: 'flex', alignItems: 'center'}}
                    onClick={() => this.showModal('role')}>
                    <Icon name='user' />
                    {t('Role')}
                    {': '}
                    {userInfo.roleKey ? t(userInfo.roleKey) : t('NotSpecified')}
                    {editable && EditIcon}
                  </List.Item>
                  <List.Item
                    className={editable ? 'editable' : undefined}
                    style={{display: 'flex', alignItems: 'center'}}
                    onClick={() => this.showModal('country')}>
                    <Icon name='globe' />
                    {t('Country')}
                    {': '}
                    {(selectedCountry && selectedCountry.name) ||
                      userInfo.country}
                    {editable && EditIcon}
                  </List.Item>
                </List>
                <Header
                  className={editable ? 'editable' : undefined}
                  sub
                  onClick={() => this.showModal('bio')}
                  content={
                    <div>
                      <span>{t('Bio')}</span>
                      {editable && EditIcon}
                    </div>
                  }
                />
                <span style={{wordWrap: 'break-word'}}>
                  {userInfo.bio && userInfo.bio.length > 0
                    ? userInfo.bio
                    : t('NotSpecified')}
                </span>
              </Grid.Column>
              <Grid.Column>
                <Header
                  className={editable ? 'editable' : undefined}
                  sub
                  onClick={() => this.showModal('genres')}
                  content={
                    <div>
                      <span>{t('PreferredGenres')}</span>
                      {editable && EditIcon}
                    </div>
                  }
                />
                <GenreList
                  genres={userInfo.preferredGenres}
                  noInfoMessage={t('NotSpecified')}
                />
                <Header
                  className={editable ? 'editable' : undefined}
                  sub
                  onClick={() => this.showModal('languages')}
                  content={
                    <div>
                      <span>{t('PreferredLanguages')}</span>
                      {editable && EditIcon}
                    </div>
                  }
                />
                <LanguageList
                  languages={userInfo.preferredLanguages}
                  noInfoMessage={t('NotSpecified')}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ProfileContainer>
        {showModal && (
          <Modal open onClose={this.hideModal}>
            <Modal.Content>
              <Modal.Description>
                {showModal === 'displayName' && (
                  <DisplayNameGetter
                    initialValue={userInfo.displayName}
                    onUpdate={this.updateProfile}
                  />
                )}
                {showModal === 'yob' && (
                  <YoBGetter
                    initialValue={userInfo.yob}
                    onUpdate={this.updateProfile}
                  />
                )}
                {showModal === 'gender' && (
                  <GenderGetter
                    initialValue={userInfo.gender}
                    onUpdate={this.updateProfile}
                  />
                )}
                {showModal === 'genres' && (
                  <GenresGetter
                    initialValue={userInfo.preferredGenres}
                    onUpdate={value =>
                      this.updateProfile({preferredGenres: value})
                    }
                  />
                )}
                {showModal === 'languages' && (
                  <LanguagesGetter
                    initialValue={
                      userInfo.preferredLanguages &&
                      userInfo.preferredLanguages.map(lang => lang.key)
                    }
                    onUpdate={value =>
                      this.updateProfile({preferredLanguages: value})
                    }
                  />
                )}
                {showModal === 'bio' && (
                  <BioGetter
                    initialValue={userInfo.bio}
                    onUpdate={this.updateProfile}
                  />
                )}
                {showModal === 'role' && (
                  <RoleGetter
                    initialValue={userInfo.role}
                    onUpdate={this.updateProfile}
                  />
                )}
                {showModal.inviteReader && (
                  <InviteReader
                    reader={showModal.inviteReader}
                    onClose={() => this.setState({showModal: false})}
                  />
                )}
                {showModal === 'country' && (
                  <Country
                    initialValue={userInfo.country}
                    onUpdate={this.updateProfile}
                  />
                )}
                {showModal === 'review' && (
                  <UserReviews
                    reviewedUser={userInfo}
                    averageRating={userData && userData.averageRating}
                    reviewCount={userData && userData.reviewCount}
                    isCurrentUser={userInfo._id === userProfile._id}
                  />
                )}
                <Divider hidden />
              </Modal.Description>
            </Modal.Content>
          </Modal>
        )}
      </MainWrapper>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  userProfile: state.user.userProfile,
  countryOptions: state.user.countryOptions
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateUserProfile,
      fetchCountryOptions,
      changePage: newPage => push(newPage)
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicUserProfile))
);
