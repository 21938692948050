import React, { Component } from 'react';

import { List, TextArea } from 'semantic-ui-react';

import Avatar from '../../../../components/avatar/avatar';

const TextSurveyAnswersList = ({
  answers,
  onFilter
}) => {
  if (!answers || answers.length === 0) {
    return (
      <List divided>
        <List.Item key='noanswer'>
          <List.Content>
            No answers yet.
          </List.Content>
        </List.Item>
      </List>
    )
  }

  return (
    <List divided>
      {
        answers.map((answer) => {
          return (
            <List.Item
              key={answer._id + answer.reader._id}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}
            >
              <Avatar
                name={answer.reader && answer.reader.displayName}
                diameter={25}
                userId={answer.reader && answer.reader.userId}
                src={answer.reader && answer.reader.photoURL}
                onClick={() => onFilter && onFilter([answer.reader._id])}
              />
              <List.Content
                style={{
                  flex: 1,
                  paddingLeft: 5,
                  whiteSpace: 'pre-wrap', /* CSS3 */
                  wordWrap: 'break-word'   /* IE */
                }}
              >
                {answer.answer}
              </List.Content>
            </List.Item>
          )
        })
      }
    </List>
  );

}

export default TextSurveyAnswersList;
