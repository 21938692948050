import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {
  Container, Loader, List
} from 'semantic-ui-react';

import moment from 'moment';
// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ReadingSessionEventsFeed from './readingSessionEventsFeed';

import {
  fetchCurrentReadingSessions,
  clearCurrentReadingSessions
} from '../../modules/reader';

class ReadingSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.startOpen
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    const {session} = this.props;
    const {open} = this.state;
    if (!session) {
      return (
        <Container>
          <Loader />
        </Container>
      );
    } else {
      return (
        <List.Item
          key={session._id}
          onClick={this.toggleOpen}
          as='a'
          >
          <List.Icon
            name={open ? 'chevron down':'chevron right'}
            link
          />
          <List.Content style={{width: '100%'}}>
            <List.Header content={`${session.eventCount || 0} events over ${moment.duration(new Date(session.updatedAt)-new Date(session.createdAt)).humanize()}`}/>
            <List.Description>
              {`${moment(new Date(session.updatedAt)).fromNow()}`}
              {
                open &&
                <ReadingSessionEventsFeed session={session} />
              }
            </List.Description>
          </List.Content>
        </List.Item>
      );
    }
  }

  // custom methods
  toggleOpen = () => {
    this.setState({
      open: !this.state.open
    })
  }

}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  currentBook: state.book.currentBook,
  currentReader: state.reader.currentReader,
  currentReadingSessions: state.reader.currentReadingSessions,
  readingSessionEventData: state.reader.readingSessionEventData
})

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchCurrentReadingSessions,
  clearCurrentReadingSessions,
  changePage: (newPage) => push(newPage)
}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadingSession))
