import React from 'react';
import {List, Dropdown, Button, Icon} from 'semantic-ui-react';
import {Media} from 'src/utils/Media';
import {withTranslation} from 'react-i18next';
import i18n from '../../utils/i18n/i18n';

import GenreGetter from '../user/userProfileBuilder/fields/genresGetter';
import LanguageGetter from '../user/userProfileBuilder/fields/languages';

const filterOptions = [
  {key: 'genres', text: 'Genres', value: 'genres'},
  {key: 'language', text: 'Language', value: 'language'}
];
const filterOperatorOptions = [
  {key: 'in', text: i18n.t('Includes'), value: 'in'},
  {key: 'nin', text: i18n.t('DoesNotInclude'), value: 'nin'},
  {key: 'and', text: i18n.t('IncludesAll'), value: 'and'}
];

class ReaderIndexFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleReaderFilterTypeChange = newType => {
    const {option, value, index} = this.props;
    this.props.handleFilterChange({
      type: newType,
      option: option || 'in',
      value: value || [],
      index
    });
  };

  handleReaderFilterOptionChange = newOption => {
    const {type, value, index} = this.props;
    this.props.handleFilterChange({
      type,
      option: newOption,
      value: value || [],
      index
    });
  };

  handleReaderFilterValueChange = newValue => {
    const {type, option, index} = this.props;
    this.props.handleFilterChange({
      type,
      option,
      value: newValue,
      index
    });
  };

  handleSelectedGenresChanged = selectedGenres => {
    this.handleReaderFilterValueChange(selectedGenres);
  };

  handleSelectedLanguagesChanged = (
    selectedLanguageValues,
    selectedLanguages
  ) => {
    this.handleReaderFilterValueChange(selectedLanguageValues);
  };

  render() {
    const {t, addFilter, removeFilter, type, option, value, index} = this.props;
    return (
      <List.Item>
        <List.Content verticalAlign='middle'>
          <Dropdown
            key={`select-filter-type-${type}`}
            style={{marginBottom: '10px'}}
            button
            compact
            placeholder={t('SelectFilter')}
            options={filterOptions}
            value={type}
            onChange={(event, data) =>
              this.handleReaderFilterTypeChange(data.value)
            }
          />
          {['genres'].includes(type) && (
            <span>
              <Dropdown
                key={`select-filter-option-${type}`}
                button
                compact
                options={filterOperatorOptions}
                value={option || 'in'}
                onChange={(event, data) => {
                  this.handleReaderFilterOptionChange(data.value);
                }}
              />
              <GenreGetter
                header={t('Genres')}
                subheader=' '
                initialValue={value || ''}
                autoUpdate
                hideHeader
                dropDown
                onUpdate={this.handleSelectedGenresChanged}
                hideSaveButton
              />
            </span>
          )}
          {['language'].includes(type) && (
            <span>
              <Dropdown
                button
                compact
                options={filterOperatorOptions}
                value={option || 'in'}
                onChange={(event, data) => {
                  this.handleReaderFilterOptionChange(data.value);
                }}
              />
              <LanguageGetter
                header={t('Languages')}
                subheader=' '
                autoUpdate
                compact
                initialValue={value || ''}
                // initialValue={selectedLanguages && selectedLanguages.map(lang => lang.key)}
                onUpdate={this.handleSelectedLanguagesChanged}
              />
            </span>
          )}
          <Media style={{marginTop: '10px'}} at='mobile'>
            {Array.isArray(value) && value.length > 0 && (
              <Button
                disabled={
                  !value || (Array.isArray(value) && value.length === 0)
                }
                floated='left'
                basic
                size='mini'
                onClick={() => addFilter(index)}
                icon>
                <Icon name='plus' />
              </Button>
            )}
            <Button
              floated='right'
              basic
              size='mini'
              onClick={() => removeFilter(index)}
              icon>
              <Icon name='trash' />
            </Button>
          </Media>
        </List.Content>
        <List.Content floated='right' verticalAlign='middle'>
          <Media style={{marginTop: '10px'}} greaterThan='mobile'>
            <Button size='mini' onClick={() => removeFilter(index)}>
              {t('RemoveFilter')}
            </Button>
            <Button
              disabled={!value || (Array.isArray(value) && value.length === 0)}
              size='mini'
              onClick={() => addFilter(index)}>
              {t('AddFilter')}
            </Button>
          </Media>
        </List.Content>
      </List.Item>
    );
  }
}

export default withTranslation()(ReaderIndexFilter);
