// LogContext.tsx

import React from 'react';

export interface LogContextType {
  logs: string[];
  addLog: (log: string) => void;
}

export const LogContext = React.createContext<LogContextType | undefined>(
  undefined
);

export const LogProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [logs, setLogs] = React.useState<string[]>([]);

  const addLog = (log: string) => {
    setLogs(prevLogs => [...prevLogs, log]);
  };

  return (
    <LogContext.Provider value={{ logs, addLog }}>
      {children}
    </LogContext.Provider>
  );
};

export const useLog = () => {
  const context = React.useContext(LogContext);
  if (!context) {
    throw new Error('useLog must be used within a LogProvider');
  }
  return context;
};
