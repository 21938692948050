import React, { Component } from 'react';
import { toast } from 'react-toastify';
import {
  Button, Modal, Form
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';

// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { uxAnalyticsUtil } from '../../../utils';

export class InviteManuscriptCollaboratorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
  }

  handleEmailChange = (event, data) => {
    this.setState({
      email: data.value
    });
  }

  sendInvite = () => {
    const {
      idToken, t, currentBook
    } = this.props;
    const { email } = this.state;
    try {
      this.setState({
        isSendingInvite: true
      });
      fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${currentBook._id}/invite-members`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'br-token': idToken
          },
          body: JSON.stringify({
            email
          })
        })
        .then(res => res.json())
        .then(() => {
          toast.info(t('InvitationSent'));
        })
        .catch(() => {
          toast.error(t('SomethingWentWrong'));
        })
        .finally(() => {
          this.setState({
            email: '',
            isSendingInvite: false
          });
        });
    } catch (e) {
      toast.error(t('SomethingWentWrong'));
    }
  };

  render() {
    const { t } = this.props;
    const { email, isSendingInvite } = this.state;
    return (
      <Modal
        trigger={(
          <Button
            floated='right'
            icon='add user'
            content={t('InviteCollaborator')}
          />
        )}
        onOpen={() => {
          uxAnalyticsUtil.trackModalView('invite-collaborator');
        }}
        closeIcon
      >
        <Modal.Header>{t('InviteCollaborator')}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Form>
              <Form.Input
                fluid
                placeholder={t('Email')}
                value={email}
                onChange={this.handleEmailChange}
                action={(
                  <Button
                    content={t('Send')}
                    icon='send'
                    loading={isSendingInvite}
                    onClick={this.sendInvite}
                  />
                )}
              />
              <Form.Field>
                {t('InviteCollaboratorMsg')}
              </Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  currentBook: state.book.currentBook
});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteManuscriptCollaboratorModal)));
