import React from 'react';
import { withRouter } from 'react-router-dom';
// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Icon } from 'semantic-ui-react';
import ReactDOMServer from 'react-dom/server';
import DropzoneComponent from 'react-dropzone-component';
import 'react-dropzone-component/styles/filepicker.css';
import 'dropzone/dist/min/dropzone.min.css';

export class CoverFileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropZone: true
    };

    const { idToken, currentBook } = this.props;

    this.componentConfig = {
      iconFiletypes: ['.jpg', '.png'],
      showFiletypeIcon: false,
      multiple: false,
      postUrl: `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${currentBook._id}/cover-art`
    };

    this.djsConfig = {
      createImageThumbnails: false,
      acceptedFiles: 'image/png,image/jpg,image/jpeg',
      dictDefaultMessage: this.props.uploadMessage,
      params: {
          name: 'testfile'
      },
      headers: {
        'br-token': idToken
      },
      previewTemplate: ReactDOMServer.renderToStaticMarkup(
        <div className='dz-preview dz-file-preview'>
          <div className='dz-details'>
            <div className='dz-filename'><span data-dz-name='true'></span></div>
            <img alt='' data-dz-thumbnail='true' />
          </div>
          <div className='dz-progress'><span className='dz-upload' data-dz-uploadprogress='true'></span></div>
          <div className='dz-success-mark'><Icon size='large' name='check' color='olive'/></div>
          <div className='dz-error-mark'><Icon size='large' name='close' color='red'/></div>
          <div className='dz-error-message'><span data-dz-errormessage='true'></span></div>
        </div>
      )
    };
  }

  render() {
    const { onComplete, onClose } = this.props;
    // For a list of all possible events (there are many), see README.md!
    const eventHandlers = {
      complete: onComplete
    };

    if (!this.componentConfig) return null;

    return (
      <div>
        {
          onClose && (
            <Icon key='close-button' link name='close' style={{ float: 'right' }} onClick={onClose} />
          )
        }
        <DropzoneComponent
          key='dropzone'
          config={this.componentConfig}
          eventHandlers={eventHandlers}
          djsConfig={this.djsConfig}
        />
      </div>
    )
  }
}



// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  currentBook: state.book.currentBook
})

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: (newPage) => push(newPage)
}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CoverFileUpload))
