import React, {useState, useEffect, useCallback} from 'react';
import moment from 'moment';

// redux
import {push} from 'connected-react-router';
import {bindActionCreators} from 'redux';
import {connect, useDispatch, useSelector} from 'react-redux';

import styled from 'styled-components';

// pop-ups
import {toast} from 'react-toastify';

import {Link, useHistory} from 'react-router-dom';
import {withRouter} from 'react-router-dom';

import {
  Icon,
  Button,
  Grid,
  Header,
  Divider,
  Label,
  Form,
  Input,
  Dropdown,
  TextArea,
  Checkbox,
  Modal,
  Message
} from 'semantic-ui-react';
import {DateInput} from 'semantic-ui-calendar-react';
import {withTranslation, Trans} from 'react-i18next';

import language from '../../../config/language';
import genreList from '../../../shared/constants/genres';
import {authUtil, accessUtil, bookStatusUtil} from '../../../utils';
import {
  updatePendingBookChanges,
  deleteBook,
  saveBook,
  setSaveButton
} from '../../../modules/book';

import PublishingSettings from './publishingSettings';
import BookNotificationSettings from '../settings/bookNotificationSettings';
import {PaddedWrapper} from '../../../style';
import textUtil from 'src/utils/textUtil';

const DropdownItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const DropdownDescriptionWrapper = styled.span`
  margin-left: 5px;
  font-size: 0.8em;
  opacity: 0.8;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
`;

const UnpaddedColumn = styled(Grid.Column)`
  padding-left: 0px !important;
  padding-right: 0px !important;
`;
const fieldsToCompare = [
  'title',
  'genres',
  'description',
  'feedbackGuidelines',
  'copyRightInfo',
  'language',
  'customAuthorName',
  'status',
  'isMature',
  'pubDate',
  'enableChapterComments',
  'manuscriptStyle'
];

const EditBookDetails = ({t}) => {
  const [pendingBookChanges, setPendingBookChanges] = useState({});
  const [coverUploadActive, setCoverUploadActive] = useState(false);
  const [showDeleteBookModal, setShowDeleteBookModal] = useState(false);

  const currentBook = useSelector(state => state.book.currentBook);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let hasPendingChanges = false;
    fieldsToCompare.forEach(field => {
      if (
        pendingBookChanges[field] !== undefined &&
        pendingBookChanges[field] !== currentBook[field]
      ) {
        hasPendingChanges = true;
      }
    });
    if (hasPendingChanges) {
      enableSaveButton();
    }
  }, [currentBook, pendingBookChanges]);

  const genreOptions = useCallback(() => {
    return genreList.map(genre => {
      return {key: genre, value: genre, text: genre};
    });
  }, [genreList]);

  const availableLanguages = useCallback(() => {
    return language.getAvailableLanguages().map(languageOption => ({
      key: languageOption.code,
      value: languageOption.code,
      text: languageOption.name
    }));
  }, [genreList]);

  const canEdit = useCallback(() => {
    return accessUtil.isAllowed(currentBook.access, ['edit']);
  }, [currentBook?.access]);

  const getFieldValue = field => {
    return pendingBookChanges[field] ?? currentBook[field];
  };

  const titleChanged = (event, data) => {
    if (!canEdit()) {
      return;
    }
    updatePendingBookChanges({
      title: data.value
    });
  };

  const genresChanged = (event, data) => {
    if (!canEdit()) {
      return;
    }
    updatePendingBookChanges({
      genres: data.value
    });
  };

  const descriptionChanged = (event, data) => {
    if (!canEdit()) {
      return;
    }
    updatePendingBookChanges({
      description: data.value
    });
  };

  const feedbackGuidelinesChanged = (event, data) => {
    if (!canEdit()) {
      return;
    }
    updatePendingBookChanges({
      feedbackGuidelines: data.value
    });
  };

  const copyRightInfoChanged = (event, data) => {
    if (!canEdit()) {
      return;
    }
    updatePendingBookChanges({
      copyRightInfo: data.value
    });
  };

  const languageChanged = (event, data) => {
    if (!canEdit()) {
      return;
    }
    updatePendingBookChanges({
      language: data.value
    });
  };

  const customAuthorNameChanged = (event, data) => {
    if (!canEdit()) {
      return;
    }
    updatePendingBookChanges({
      customAuthorName: data.value
    });
  };

  const pubDateChanged = (event, data) => {
    if (!canEdit()) {
      return;
    }
    updatePendingBookChanges({
      pubDate: data.value
    });
  };

  const toggleEnableChapterComments = (event, data) => {
    if (!canEdit()) {
      return;
    }
    updatePendingBookChanges({
      enableChapterComments: data.checked
    });
  };

  const handleStatusChange = (event, data) => {
    console.log(data);
    if (!canEdit()) {
      return;
    }
    updatePendingBookChanges({
      status: data.value
    });
  };

  const handleStyleChange = (event, data) => {
    console.log(data);
    if (!canEdit()) {
      return;
    }
    updatePendingBookChanges({
      manuscriptStyle: data.value
    });
  };

  const toggleIsMature = (event, data) => {
    if (!canEdit()) {
      return;
    }
    updatePendingBookChanges({
      isMature: data.checked
    });
  };

  const updatePendingBookChanges = data => {
    setPendingBookChanges({
      ...pendingBookChanges,
      ...data
    });
  };

  const enableSaveButton = (props = {}) => {
    dispatch(
      setSaveButton(
        <Button
          compact
          primary
          content={t('Save')}
          onClick={savePendingChanges}
          {...props}
        />
      )
    );
  };

  const savePendingChanges = async () => {
    if (!canEdit()) {
      return;
    }
    enableSaveButton({loading: true});
    const idToken = await authUtil.getFreshIdToken();
    dispatch(
      saveBook(idToken, {
        ...pendingBookChanges,
        _id: currentBook._id
      })
    );
  };

  const handleDeleteBook = async () => {
    if (!canEdit()) {
      return;
    }
    const idToken = await authUtil.getFreshIdToken();
    dispatch(
      deleteBook(idToken, currentBook, () => {
        toast.info(
          <span>
            <Icon name='trash alternate outline' />
            {t('DeletedBookX', {x: currentBook.title})}
          </span>
        );
        history.push('/mymanuscripts');
      })
    );
  };

  const statusOptions = [
    {
      key: 'alpha',
      value: 'alpha',
      color: bookStatusUtil.getStatusColor('alpha'),
      content: (
        <DropdownItemWrapper>
          <Label content='alpha' color='orange' />
          <DropdownDescriptionWrapper>
            {t('MSStatusDescription_alpha')}
          </DropdownDescriptionWrapper>
        </DropdownItemWrapper>
      )
    },
    {
      key: 'beta',
      value: 'beta',
      color: bookStatusUtil.getStatusColor('beta'),
      content: (
        <DropdownItemWrapper>
          <Label content='beta' color='teal' />
          <DropdownDescriptionWrapper>
            {t('MSStatusDescription_beta')}
          </DropdownDescriptionWrapper>
        </DropdownItemWrapper>
      )
    }
  ];

  const styleOptions = [
    {
      key: 'book',
      value: 'book',
      style: {fontFamily: 'Georgia'},
      content: (
        <DropdownItemWrapper>
          <Label
            content='Book'
            style={{
              fontFamily: 'Georgia'
            }}
          />
        </DropdownItemWrapper>
      )
    },
    {
      key: 'screenplay',
      value: 'screenplay',
      style: {fontFamily: 'Monospace'},
      content: (
        <DropdownItemWrapper>
          <Label
            content='Screenplay'
            style={{
              fontFamily: 'Monospace'
            }}
          />
        </DropdownItemWrapper>
      )
    }
  ];

  return (
    <PaddedWrapper>
      <div>
        <Header className='br-text'>
          <Form.Input
            transparent
            fluid
            className='book-title'
            input={<input type='text' maxLength={60} />}
            style={{fontWeight: 'bold!important'}}
            placeholder={t('Title')}
            value={getFieldValue('title')}
            onChange={titleChanged}
          />
          <Header.Subheader className='br-text'>
            {`${t('by')} ${currentBook.authorName}`}
          </Header.Subheader>
        </Header>
        {currentBook.privacy !== 'unpublished' &&
          currentBook.wordCount === 0 && (
            <Message
              warning
              content={
                <Trans i18nKey='BookHas0WordsDescription'>
                  <span>
                    This book has at least one version with 0 words. You can add
                    or upload chapters{' '}
                  </span>
                  <Link to={`/books/${currentBook._id}/content`}>here</Link>.
                </Trans>
              }
            />
          )}
      </div>
      <div>
        <div>
          <Form.Field>
            <label>{t('PublishingStatus')}</label>
            <PublishingSettings
              key='publishing-settings'
              currentBook={currentBook}
            />
          </Form.Field>
        </div>
        <Horizontal>
          <Vertical>
            <label>{t('ManuscriptStatus')}</label>
            <Dropdown
              as={Label}
              disabled={!canEdit()}
              text={getFieldValue('status')}
              value={getFieldValue('status')}
              color={
                (
                  statusOptions.find(
                    o => o.value === getFieldValue('status')
                  ) || {}
                ).color
              }
              options={statusOptions}
              placeholder={t('SelectStatus')}
              onChange={handleStatusChange}
            />
          </Vertical>
          <Vertical>
            <label>{t('ManuscriptStyle')}</label>
            <Dropdown
              as={Label}
              disabled={!canEdit()}
              text={textUtil.capitalizeFirstLetter(
                getFieldValue('manuscriptStyle')
              )}
              value={getFieldValue('manuscriptStyle')}
              style={
                styleOptions.find(
                  o => o.value === getFieldValue('manuscriptStyle')
                ).style
              }
              options={styleOptions}
              placeholder={t('SelectManuscriptStyle')}
              onChange={handleStyleChange}
            />
          </Vertical>
        </Horizontal>
      </div>
      <div>
        <div>
          <Form>
            <Form.Field
              control={Dropdown}
              label={
                getFieldValue('genres').length > 5
                  ? t('SelectUpTo5Genres')
                  : t('Genres')
              }
              disabled={!canEdit()}
              className='book-genres'
              error={
                (pendingBookChanges.genres || currentBook.genres).length > 5
              }
              placeholder={t('SelectUpTo5Genres')}
              multiple
              search
              selection
              defaultValue={getFieldValue('genres')}
              options={genreOptions()}
              onChange={genresChanged}
            />
          </Form>
        </div>
      </div>
      <div>
        <Form>
          <Form.Field
            disabled={!canEdit()}
            label={t('Description')}
            className='book-description'
            maxLength={1000}
            control={TextArea}
            open
            rows={8}
            placeholder={t('WhatIsThisStoryAbout')}
            value={getFieldValue('description')}
            onChange={descriptionChanged}
          />
          <Form.Field
            disabled={!canEdit()}
            label={t('FeedbackGuidelines')}
            className='book-feedback-guidelines'
            maxLength={1000}
            control={TextArea}
            placeholder={t('WhatDoYouNeedHelpWith')}
            value={getFieldValue('feedbackGuidelines')}
            onChange={feedbackGuidelinesChanged}
          />
          <Form.Field
            disabled={!canEdit()}
            label={t('CopyrightInfo')}
            className='book-copyright'
            maxLength={1000}
            control={TextArea}
            placeholder={`${t('CopyrightInfo')}...`}
            value={getFieldValue('copyRightInfo')}
            onChange={copyRightInfoChanged}
          />
          <Form.Field
            disabled={!canEdit()}
            label={t('Language')}
            className='book-language'
            onChange={languageChanged}
            control={Dropdown}
            fluid
            selection
            search
            options={availableLanguages()}
            value={getFieldValue('language')}
          />
          <Form.Field
            disabled={!canEdit()}
            label={t('PenName')}
            control={Input}
            input={<input type='text' maxLength={50} />}
            value={getFieldValue('customAuthorName')}
            onChange={customAuthorNameChanged}
            placeholder={t('DefaultNamePlaceholder', {
              displayName: currentBook.author.displayName
            })}
          />
          <Form.Field
            disabled={!canEdit()}
            control={Checkbox}
            label={t('MatureContent')}
            style={{marginTop: 10}}
            onChange={toggleIsMature}
            toggle
            checked={getFieldValue('isMature')}
          />
          <Form.Field
            disabled={!canEdit()}
            label={t('PublicationDate')}
            control={DateInput}
            name='date'
            inlineLabel
            duration={0}
            dateFormat='YYYY-MM-DD'
            placeholder={t('NotSpecified')}
            value={
              getFieldValue('pubDate')
                ? moment(getFieldValue('pubDate')).format('YYYY-MM-DD')
                : ''
            }
            iconPosition='left'
            clearable
            closable
            onChange={pubDateChanged}
            hideMobileKeyboard
          />
          <Form.Field>
            <Header size='tiny' content={t('Feedback')} />
            <Checkbox
              disabled={!canEdit()}
              label={t('EnableComments')}
              checked={getFieldValue('enableChapterComments')}
              toggle
              onChange={toggleEnableChapterComments}
            />
          </Form.Field>
          <BookNotificationSettings />
        </Form>
      </div>
      <div>
        {canEdit() && (
          <div>
            <Divider hidden clearing />
            <Button
              icon='trash alternate outline'
              negative
              floated='left'
              content={t('DeleteBook')}
              onClick={() => setShowDeleteBookModal(true)}
            />
          </div>
        )}
        <Modal
          key='delete-book-modal'
          open={showDeleteBookModal}
          closeOnEscape
          closeOnDimmerClick
          onClose={() => setShowDeleteBookModal(false)}>
          <Modal.Header>{t('DeleteBook')}</Modal.Header>
          <Modal.Content>
            <Trans i18nKey='AreYouSureYouWantToDeleteBookName'>
              <p>
                Are you sure you want to delete{' '}
                <strong>{{bookTitle: currentBook.title}}</strong>?
              </p>
            </Trans>
          </Modal.Content>
          <Modal.Actions>
            <Button secondary onClick={() => setShowDeleteBookModal(false)}>
              {t('No')}
            </Button>
            <Button
              negative
              onClick={handleDeleteBook}
              labelPosition='right'
              icon='trash alternate outline'
              content={t('Yes')}
            />
          </Modal.Actions>
        </Modal>
      </div>
    </PaddedWrapper>
  );
};

export default withTranslation()(withRouter(EditBookDetails));
