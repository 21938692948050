import React, { Component } from 'react';
import {
  Form, Button, Header
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

class ReaderListConsentGetter extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  setReaderListConsent = (hasConsent) => {
    this.setState({ readerListConsent: hasConsent }, () => {
      this.submit();
    });
  }

  submit = () => {
    const { readerListConsent } = this.state;
    if (this.props.onUpdate) {
      this.props.onUpdate({ readerListConsent })
    }
  }


  // render
  render() {
    const { t, updating } = this.props;
    const { readerListConsent } = this.state;
    return (
      <React.Fragment>
        <Header
          size='small'
          content={t('ReaderListConsentHeader')}
          subheader={t('ReaderListConsentInfo')}
        />
        <Form.Field style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Button
            fluid
            toggle
            content={t('Yes')}
            active={readerListConsent === true}
            onClick={() => this.setReaderListConsent(true)}
          />
          <Button
            fluid
            toggle
            content={t('No')}
            active={readerListConsent === false}
            onClick={() => this.setReaderListConsent(false)}
          />
        </Form.Field>
      </React.Fragment>
    );
  }
}

export default withTranslation()(ReaderListConsentGetter)
