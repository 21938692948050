import React, {useState} from 'react';
import {Form, Input, Button, Dropdown, Table, Icon} from 'semantic-ui-react';
import CSVReader from 'react-csv-reader';
import {useMutation, useQueryClient} from '@tanstack/react-query';

import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {bulkAddReaders, fetchReaders} from '../../modules/reader';

import {authUtil} from '../../utils';
import {toast} from 'react-toastify';
import {useMemo} from 'react';

export type ReaderInvitation = {
  email: string;
  foreignId?: string;
  contentVersion?: string;
};

enum CSVMapping {
  Email = 'email',
  ExternalId = 'external_id'
}

type Props = {
  invitationSent(): void;
  disabled?: boolean;
};

type UpdateProps = {
  idToken: string;
  bookId: string;
  invitations: any[];
};
const BulkInvitationForm: React.FC<Props> = ({invitationSent, disabled}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const currentBook = useSelector(state => state.book.currentBook);
  const updatingReader = useSelector(state => state.reader.updatingReader);

  const {isLoading, mutateAsync} = useMutation(
    ({idToken, bookId, invitations}: UpdateProps) => {
      return fetch(
        `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${bookId}/readers`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'br-token': idToken
          },
          body: JSON.stringify({
            bulkInvitations: invitations
          })
        }
      );
    },
    {
      onSuccess: async () => {
        const idToken = await authUtil.getFreshIdToken();
        // Invalidate and refetch
        queryClient.invalidateQueries(['readers']);
        dispatch(fetchReaders(idToken, currentBook._id, []));
        if (invitationSent) {
          invitationSent();
        }
      }
    }
  );

  const [csvData, setCSVData] = useState<any[]>([]);
  const hasErrors = useMemo(
    () => csvData.some(row => !row[CSVMapping.Email]),
    [csvData]
  );
  const csvFields = useMemo(() => {
    if (csvData && csvData[0]) {
      const row = csvData[0];
      const fields = Object.keys(row);
      fields.unshift('');
      return fields;
    }
  }, [csvData]);
  const [csvMapping, setCSVMapping] = useState<
    Record<CSVMapping, string | undefined> | undefined
  >();

  const [contentVersion, setContentVersion] = useState<string | undefined>(
    currentBook.content[0]?._id
  );

  const submit = async () => {
    const idToken = await authUtil.getFreshIdToken();
    if (csvData.length > 0) {
      const invitations = csvData.map(d => ({
        ...d,
        contentVersion: contentVersion
      }));
      await mutateAsync({
        idToken,
        bookId: currentBook._id,
        invitations
      });
    }
  };

  const deleteInvitation = (index: number) => {
    setCSVData(prev => {
      const updatedInvitations = [...prev];
      updatedInvitations.splice(index, 1);
      return updatedInvitations;
    });
  };

  const contentVersionOptions = currentBook.content.map(
    (version: any, index: number) => ({
      key: version._id,
      value: version._id,
      text:
        index === currentBook.content.length - 1
          ? `v${version.versionNumber || 1} (${t('latestVersion')})`
          : `v${version.versionNumber || 1}`
    })
  );

  const handleFileLoaded = (data: any[]) => {
    const firstRow = data[0];
    if (firstRow) {
      const keys = Object.keys(firstRow);
      const mapping: Record<CSVMapping, string | undefined> = {
        [CSVMapping.Email]: undefined,
        [CSVMapping.ExternalId]: undefined
      };
      // try to find email index
      const emailKeyIndex = keys.findIndex(key => key.includes('mail'));
      if (emailKeyIndex > -1) {
        mapping[CSVMapping.Email] = keys[emailKeyIndex];
      }
      // try to find external id index
      const externalIdKeyIndex = keys.findIndex(key => key.includes('id'));
      if (externalIdKeyIndex > -1) {
        mapping[CSVMapping.ExternalId] = keys[externalIdKeyIndex];
      }

      setCSVMapping(mapping);
      setCSVData(data);
    }
  };

  return (
    <Form loading={isLoading}>
      <Form.Field>
        <CSVReader
          cssClass='csv-reader-input'
          label={t('SelectCSVWithReaders') as string}
          onFileLoaded={(data: any[]) => handleFileLoaded(data)}
          onError={() => toast.error(t('SomethingWentWrong') as string)}
          parserOptions={{
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            transformHeader: (header: string) =>
              header.toLowerCase().replace(/\W/g, '_')
          }}
          inputId='reader_csv'
          inputName='Readers'
          inputStyle={{}}
        />
      </Form.Field>
      {csvMapping && (
        <Form.Field>
          <Form.Select
            options={contentVersionOptions}
            value={contentVersion}
            label={t('SelectContentVersion')}
            onChange={(event, data) => {
              setContentVersion(data.value as string);
            }}
          />
        </Form.Field>
      )}
      <Table compact>
        {csvMapping && (
          <>
            <Table.Header>
              <Table.Row>
                {Object.keys(csvMapping).map(key => (
                  <Table.HeaderCell key={key}>
                    <label>
                      {key}
                      <Icon name='arrow right' />
                    </label>
                    <Dropdown
                      labeled
                      placeholder={t('MapCSVField')}
                      selection
                      fluid
                      options={csvFields?.map(field =>
                        field
                          ? {
                              key: field,
                              text: field,
                              value: field
                            }
                          : {
                              key: '',
                              text: t('Ignore') as string,
                              value: ''
                            }
                      )}
                      value={csvMapping[key as unknown as CSVMapping]}
                      onChange={(event, data) => {
                        setCSVMapping(prev =>
                          prev ? {...prev, [key]: data.value} : prev
                        );
                      }}
                    />
                  </Table.HeaderCell>
                ))}
                <Table.HeaderCell> </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          </>
        )}
        <Table.Body>
          {csvData.map((row, index) => {
            const columns = Object.values(CSVMapping);
            return (
              <Table.Row key={index} error={!row[CSVMapping.Email]}>
                {columns.map(column => (
                  <Table.Cell key={column}>
                    <Input
                      fluid
                      disabled={disabled}
                      placeholder={column}
                      value={
                        row[
                          csvMapping?.[column as keyof typeof csvMapping] ?? ''
                        ]
                      }
                      onChange={(event, data) => {
                        const updatedCsvData = [...csvData];
                        updatedCsvData[index] = {
                          ...updatedCsvData[index],
                          [column]: data.value
                        };
                        setCSVData(updatedCsvData);
                      }}
                    />
                  </Table.Cell>
                ))}
                <Table.Cell width={1}>
                  <Button
                    key='delete'
                    icon='delete'
                    loading={!!updatingReader}
                    onClick={() => {
                      deleteInvitation(index);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>

      {csvData.length > 0 && (
        <Form.Field>
          <Form.Button
            disabled={hasErrors}
            key='save'
            icon='send'
            content={t('Send')}
            loading={!!updatingReader}
            onClick={submit}
          />
        </Form.Field>
      )}
    </Form>
  );
};

export default BulkInvitationForm;
