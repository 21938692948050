export const UPDATE_INBOX_LIST = 'manuscriptInbox/UPDATE_INBOX_LIST';
export const SET_CURRENT_MANUSCRIPT_INBOX = 'manuscriptInbox/SET_CURRENT_MANUSCRIPT_INBOX';
export const LOADING_CURRENT_INBOX = 'manuscriptInbox/LOADING_CURRENT_INBOX';
export const UPDATE_RANKED_SUBMISSIONS = 'manuscriptInbox/UPDATE_RANKED_SUBMISSIONS';

export const initialState = {
  inboxes: [],
  currentManuscriptInbox: undefined,
  fetchingCurrentInbox: false
};

// reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_INBOX_LIST:
      return {
        ...state,
        inboxes: action.inboxes
      };
    case LOADING_CURRENT_INBOX:
      return {
        ...state,
        fetchingCurrentInbox: action.value
      };
    case SET_CURRENT_MANUSCRIPT_INBOX:
      return {
        ...state,
        currentManuscriptInbox: action.inbox
      };
    case UPDATE_RANKED_SUBMISSIONS: {
      const updatedCurrentManuscriptInbox = {
        ...state.currentManuscriptInbox,
        submissions: action.rankedSubmissions
      };
      return {
        ...state,
        currentManuscriptInbox: updatedCurrentManuscriptInbox
      };
    }
    default:
      return state;
  }
};

// actions
export const fetchInboxes = idToken => (dispatch) => {
  fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/manuscript-inboxes`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'br-token': idToken
    }
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.error);
      } else {
        return res.json();
      }
    })
    .then((inboxes) => {
      dispatch({
        type: UPDATE_INBOX_LIST,
        inboxes
      });
    })
    .catch((error) => {
      console.error('Failed to fetch inboxes', error);
    });
};

export const fetchCurrentManuscriptInbox = (idToken, { _id }) => (dispatch) => {
  dispatch({
    type: LOADING_CURRENT_INBOX,
    value: true
  });
  fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/manuscript-inboxes/${_id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'br-token': idToken
    }
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.error);
      } else {
        return res.json();
      }
    })
    .then((inbox) => {
      dispatch({
        type: SET_CURRENT_MANUSCRIPT_INBOX,
        inbox
      });
    })
    .catch((error) => {
      console.error('Failed to fetch manuscriptInbox', error);
    })
    .finally(() => {
      dispatch({
        type: LOADING_CURRENT_INBOX,
        value: false
      });
    });
};

export const fetchRankedSubmissions = (idToken, { _id, sortBy }) => (dispatch) => {
  dispatch({
    type: LOADING_CURRENT_INBOX,
    value: true
  });
  fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/experimental/manuscript-inbox/${_id}/submissions?sortBy=${sortBy}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'br-token': idToken
    }
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.error);
      } else {
        return res.json();
      }
    })
    .then((rankedSubmissions) => {
      console.log('rankedsubs', rankedSubmissions);
      dispatch({
        type: UPDATE_RANKED_SUBMISSIONS,
        rankedSubmissions
      });
    })
    .catch((error) => {
      console.error('Failed to fetch manuscriptInbox', error);
    })
    .finally(() => {
      dispatch({
        type: LOADING_CURRENT_INBOX,
        value: false
      });
    });
};
