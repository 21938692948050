import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Table
} from 'semantic-ui-react';
import moment from 'moment';

// i18n
import { withTranslation } from 'react-i18next';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  clearBroadcastMessages,
  fetchBroadcastMessages
} from '../../../modules/message';

import { authUtil } from '../../../utils';
import { PaddedWrapper } from '../../../style';

export class BroadcastMessageList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchBroadcastMessages();
  }

  componentWillUnmount() {
    this.props.clearBroadcastMessages();
  }

  fetchBroadcastMessages = async () => {
    const { currentBook } = this.props;
    const idToken = await authUtil.getFreshIdToken();
    this.props.fetchBroadcastMessages({ idToken, bookId: currentBook._id })
  }

  openMessage = (event) => {
    const { currentBook } = this.props;
    const { messageId } = event.currentTarget.dataset;
    this.props.changePage(`/books/${currentBook._id}/messages/${messageId}`)
  }

  render() {
    const { t, currentBook, broadcastMessages } = this.props;
    // const readOnly = !accessUtil.isAllowed(currentBook.access, ['edit']);
    if (!currentBook) return null;

    return (
      <PaddedWrapper>
        <Table singleLine selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('Title')}</Table.HeaderCell>
              <Table.HeaderCell>{t('Sent')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              broadcastMessages && broadcastMessages.map(message => (
                <Table.Row
                  key={message._id}
                  data-message-id={message._id}
                  onClick={this.openMessage}
                >
                  <Table.Cell>{message.title}</Table.Cell>
                  <Table.Cell collapsing>{moment(message.sentAt).format('ll')}</Table.Cell>
                </Table.Row>
              ))
            }
          </Table.Body>
        </Table>
      </PaddedWrapper>
    );
  }
}


// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  currentBook: state.book.currentBook,
  broadcastMessages: state.message.broadcastMessages,
  fetchingBroadcastMessages: state.message.fetchBroadcastMessages
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage),
  clearBroadcastMessages,
  fetchBroadcastMessages
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(BroadcastMessageList)));
