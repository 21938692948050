import React, { Component } from 'react';
import {
  withRouter
} from 'react-router-dom';
import {
  Menu
} from 'semantic-ui-react';

// i18n
import { withTranslation } from 'react-i18next';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class SubMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      items, location, changePage
    } = this.props;
    if (!items || items.length === 0) return null;
    return (
      <Menu.Menu style={{ paddingTop: '1px!important' }}>
        {
          items && items.map((item) => {
            if (!item) return null;
            return (
              <Menu.Item
                key={item.key}
                style={{ textAlign: 'left' }}
                name={item.name}
                content={item.name}
                icon={item.icon}
                active={location.pathname === item.path}
                onClick={() => changePage(item.path)}
              />
            );
          })
        }
      </Menu.Menu>
    );
  }
}


// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  currentBook: state.book.currentBook
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SubMenu)));
