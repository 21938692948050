// Multiply elements by its weight and create new array
const _weight = (arr) => {
  return [].concat(...arr.map((obj) => Array(Math.ceil(obj.weight * 100)).fill(obj))); 
}

const pick = (arr) => {
  let weighted = _weight(arr);
  return weighted[Math.floor(Math.random() * weighted.length)]
}

module.exports = {
  pick
}