import React, { Component } from 'react';
import {
  Grid, Header, Segment,
  Container, Icon
} from 'semantic-ui-react';

// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// i18n
import { withTranslation } from 'react-i18next';

import SubscriptionWidget from '../billing/subscriptions/subscription';
import ux from '../../utils/uxAnalyticsUtil';

export class Subscription extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { history } = this.props;
    ux.trackPageView(history.location.pathname);
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid columns={1} stackable>
          <Grid.Column>
            <Header content={t('Subscription')} icon={<Icon name='credit card outline' />} />
            <Segment clearing>
              <SubscriptionWidget />
            </Segment>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  socialProfiles: state.user.socialProfiles
});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(Subscription));
