import React, { useEffect, useCallback, useState } from 'react'
import {
  Button, Header, Icon, Segment, Label, Divider, List, Dropdown
} from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
// i18n
import { useTranslation } from 'react-i18next';
// redux
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchReaders
} from '../../../modules/reader';

import {
  updateReaderFilters,
  updateVersionFilter,
  clearReadingData,
  clearSurveyData,
  clearReviewData
} from '../../../modules/report';

import ReaderFilter from './readerFilter';
import { authUtil } from '../../../utils';

const ReaderJourneyFilterContainer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentBook = useSelector(state => state.book.currentBook);
  const readers = useSelector(state => state.reader.readers);
  const readerFilters = useSelector(state => state.report.readerFilters) ?? [{}];
  // current version filter. fall back to most recent version if not set
  const versionFilter = useSelector(state => state.report.versionFilter);
  const fetchingReadingData = useSelector(state => state.report.fetchingReadingData);

  const getFiltersFromQueryString = useCallback(() => {
    let filters = undefined;
    const string = location.search.replace("?")
    var query = queryString.parse(string);
    if (query.filters !== undefined) {
      try {
        filters = JSON.parse(query.filters);
      } catch (err) {
        console.error('Failed to parse filter', err);
      }
    }
    return filters;
  }, [location.search]);

  const [showReaderFilters, setShowReaderFilters] = useState(getFiltersFromQueryString() !== undefined);
  const [versionOptions, setVersionOptions] = useState([]);

  useEffect(() => {
    const fetchReadersAsync = async () => {
      const idToken = await authUtil.getFreshIdToken();
      dispatch(fetchReaders(idToken, currentBook._id, [{ key: 'exclude', value: [] }]));
      fetchVersionOptions();
    }
    if (currentBook) {
      fetchReadersAsync();
      // set the current version filter to all versions
      const mostRecentContentId = currentBook?.content && currentBook.content[currentBook.content.length - 1]?._id;
      dispatch(updateVersionFilter([mostRecentContentId]));
    }
  }, [currentBook]);

  const fetchVersionOptions = async () => {
    const idToken = await authUtil.getFreshIdToken();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/reports/settings/version-options?b=${currentBook._id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      });
      const options = await response.json();
      setVersionOptions(options.map((option, index) => ({
        key: option._id,
        text: `v${option.versionNumber || index + 1}`,
        value: option._id,
        description: `${t('Readers')}: ${option.readerCount ?? 0}`
      })));
    } catch (error) {
      console.log('Failed to get version options', error.message);
    }
  }

  const getReaderOptions = useCallback(() => readers?.map(reader => ({
    key: reader._id,
    text: reader.name,
    value: reader._id,
    description: `${reader.invitation.status.toLowerCase()}`
  })) ?? [], [readers]);

  const addFilter = (index) => {
    const updatedReaderFilters = [...readerFilters];
    updatedReaderFilters.splice(index + 1, 0, {});
    updateFilterList(updatedReaderFilters);
  }

  const removeFilter = (index) => {
    const updatedReaderFilters = [...readerFilters];
    updatedReaderFilters.splice(index, 1);
    if (updatedReaderFilters.length === 0) {
      updatedReaderFilters.push({});
    }
    updateFilterList(updatedReaderFilters);
  }

  const handleFilterChange = ({ index, operator, value }) => {
    const updatedReaderFilters = [...readerFilters];
    updatedReaderFilters[index] = { operator, value };
    dispatch(clearReadingData());
    dispatch(clearSurveyData());
    dispatch(clearReviewData());
    updateFilterList(updatedReaderFilters);
  }

  const handleVersionChange = (event, data) => {
    if (data.value?.length > 0) {
      // don't allow empty version filters
      dispatch(updateVersionFilter(data.value));
    }
  }

  const updateFilterList = (newFilters) => {
    dispatch(updateReaderFilters(newFilters, currentBook._id));
  }

  const getReaderFilterText = useCallback(() => {
    if (readerFilters?.length === 1 && Object.keys(readerFilters[0]).length === 0 && readerFilters[0].constructor === Object) {
      return 'All readers'
    } else if (readerFilters?.length > 0) {
      return `${readerFilters.length} reader filter${readerFilters.length > 1 ? 's' : ''}`
    } else {
      return 'No filters'
    }
  }, [readerFilters]);

  const getVersionFilterText = useCallback(() => {
    if (versionFilter?.length === 0) {
      const currentVersion = currentBook?.content?.find(contentVersion => contentVersion._id === versionFilter);
      const versionText = t('VersionName', { x: currentVersion?.versionNumber });
      return versionText;
    } else if (versionFilter?.length === 1 && !!versionFilter[0]) {
      const currentId = versionFilter[0];
      return `v${currentBook.content.find(content => content._id === currentId)?.versionNumber}`;
    } else {
      return t('XVersions', { count: versionFilter?.length })
    }
  }, [versionFilter, currentBook?.content]);

  const getFilterText = () => {
    return `${getVersionFilterText()} | ${getReaderFilterText()}`
  }

  const toggleReaderFilters = (event, data) => {
    event.preventDefault();
    event.stopPropagation();
    setShowReaderFilters(!showReaderFilters);
  }

  return (
    <>
      {showReaderFilters ? (
        <>
          <Button compact basic onClick={toggleReaderFilters}>
            <Icon name={fetchingReadingData ? 'refresh' : 'filter'} loading={fetchingReadingData} />
            {getFilterText()}
          </Button>
          <List selection>
            <List.Item>
              <List.Header>
                {t('Version')}
                {': '}
                <Dropdown
                  inline
                  multiple
                  selectOnBlur
                  options={versionOptions}
                  value={versionFilter}
                  onChange={handleVersionChange}
                />
              </List.Header>
            </List.Item>
            {
              readerFilters.map((readerFilter, index) => (
                <ReaderFilter
                  key={index}
                  index={index}
                  type={readerFilter.type}
                  operator={readerFilter.operator}
                  value={readerFilter.value}
                  removeFilter={removeFilter}
                  addFilter={addFilter}
                  readerOptions={getReaderOptions()}
                  versionOptions={versionOptions}
                  handleFilterChange={handleFilterChange}
                />
              ))
            }
          </List>
        </>
      ) : (
        <Button compact basic disabled={fetchingReadingData} onClick={toggleReaderFilters}>
          <Icon name={fetchingReadingData ? 'refresh' : 'filter'} loading={fetchingReadingData} />
          {getFilterText()}
        </Button>
      )}
      <Divider key='divider' hidden />
    </>
  )
}

// redux stuff
export default ReaderJourneyFilterContainer;
