import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Message } from 'semantic-ui-react';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// i18n
import { withTranslation, Trans } from 'react-i18next';
import Cookies from 'js-cookie';

import AppAlertMessage from './appAlertMessage';

import { authUtil } from '../../utils';


class AppAlerts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alerts: undefined
    };
  }

  componentDidMount() {
    const { userProfile } = this.props;
    if (userProfile) {
      this.fetchAppAlerts();
    }
  }

  componentDidUpdate(prevProps) {
    const { idToken, userProfile } = this.props;
    if (idToken && userProfile && (userProfile !== prevProps.userProfile || idToken !== prevProps.idToken)) {
      this.fetchAppAlerts();
    }
  }

  fetchAppAlerts = async () => {
    const { userProfile } = this.props;
    const idToken = await authUtil.getFreshIdToken();

    if (idToken) {
      fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/alerts?lng=${userProfile.language}&channel=author-web`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.error);
          }
          return res.json();
        })
        .then((alerts) => {
          const nonDismissedAlerts = alerts.filter(alert => Cookies.get(`br-dismissed-alert[${alert._id}]`) === undefined);
          this.setState({
            alerts: nonDismissedAlerts
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  handleDismiss = (alertId, daysToExpire = 5) => {
    // persist the dismissal in a cookie for 5 days
    Cookies.set(`br-dismissed-alert[${alertId}]`, 'dismissed', { expires: daysToExpire });
    this.setState((prevState) => {
      const updatedAlerts = [...prevState.alerts].filter(alert => alert._id !== alertId);
      return {
        alerts: updatedAlerts
      };
    });
  }

  handleResendVerificationEmail = (event) => {
    const { user } = this.props;
    const self = this;
    event.preventDefault();
    event.stopPropagation();
    if (user !== undefined) {
      self.setState({
        verificationEmailMessage: 'Sending...'
      });
      user.sendEmailVerification().then(() => {
        self.setState({
          verificationEmailMessage: 'Verification email sent.'
        });
      }).catch((error) => {
        self.setState({
          verificationEmailMessage: 'Failed to send verification email. ' + error
        });
      });
    }
  }

  render() {
    const { alerts, verificationEmailMessage } = this.state;
    const { user } = this.props;
    const { emailVerified } = (user || {});
    return (
      <div>
        {
          (user && !emailVerified) && (
            <Message warning>
              <Trans i18nKey='EmailNotVerifiedPrompt'>
                Email not verified. <Link to='#' onClick={this.handleResendVerificationEmail}>Resend</Link> verification email.
              </Trans>
              {' '}
              {verificationEmailMessage}
            </Message>
          )
        }
        {
          alerts && alerts.map((alert, index) => (
            <AppAlertMessage
              key={alert._id}
              alert={alert}
              onDismiss={this.handleDismiss}
            />
          ))
        }
      </div>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  idToken: state.user.idToken,
  user: state.user.user,
  userProfile: state.user.userProfile
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AppAlerts)));
