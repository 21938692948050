import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Container, Header, Loader, Grid, Card} from 'semantic-ui-react';
import queryString from 'query-string';
// redux
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
// i18n
import {withTranslation} from 'react-i18next';

import ReviewItem from '../../../components/review/reviewItemWithUser';
import LoadMore from '../../../components/lists/loadMore';
// import ReviewFilter from './reviewFilter';
import {uxAnalyticsUtil, authUtil} from '../../../utils';
import {
  fetchReviews,
  fetchMoreReviews,
  clearReviewList
} from '../../../modules/review';

const limit = 50;

class Feedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notes: [],
      ratings: [],
      statistics: [],
      isLoading: false,
      search: undefined
    };
  }

  componentDidMount() {
    const {match} = this.props;
    uxAnalyticsUtil.trackPageView(match.path);
    this.fetchReviews();
  }

  componentDidUpdate(prevProps, prevState) {
    const {sort, search} = this.state;
    if (sort !== prevState.sort || search !== prevState.search) {
      this.fetchReviews();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {location} = nextProps;
    if (!location) {
      return;
    }
    const query = queryString.parse(location.search);
    const {search} = query;
    if (prevState.search !== search) {
      return {
        ...prevState,
        search
      };
    }
    return {
      ...prevState
    };
  }

  fetchReviews = async () => {
    const {user, match} = this.props;
    const idToken = await authUtil.getFreshIdToken();
    const {bookId} = match.params;
    const {search, sort = 'desc'} = this.state;
    if (idToken !== undefined && user !== undefined) {
      this.props.fetchReviews({
        idToken,
        bookId,
        options: {
          sort,
          search,
          limit
        }
      });
    }
  };

  fetchMoreReviews = async () => {
    const {user, match, reviews} = this.props;
    const idToken = await authUtil.getFreshIdToken();
    const {bookId} = match.params;
    const {search, sort = 'desc'} = this.state;
    if (idToken !== undefined && user !== undefined) {
      this.props.fetchMoreReviews(idToken, bookId, {
        sort,
        search,
        limit,
        skip: reviews.length
      });
    }
  };

  render() {
    const {isLoading} = this.state;
    const {reviews, t} = this.props;
    if (!isLoading) {
      return (
        <Container textAlign='center'>
          <Grid centered stackable columns={1}>
            <Grid.Row>
              <Grid.Column>
                <Header content={t('Reviews')} />
                {/* <ReviewFilter book={book} /> */}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Card.Group>
                  {reviews &&
                    reviews.map(review => (
                      <ReviewItem key={review._id} reviewData={review} />
                    ))}
                </Card.Group>
                {reviews.length > 0 && !reviews[reviews.length - 1].isLast && (
                  <LoadMore
                    onLoadMore={this.fetchMoreReviews}
                    loading={isLoading}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      );
    }
    return (
      <Container>
        <Loader active inline='centered' />
      </Container>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  currentBook: state.book.currentBook,
  reviews: state.review.reviews,
  reviewCount: state.review.reviewCount
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchReviews,
      fetchMoreReviews,
      clearReviewList
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Feedback))
);
