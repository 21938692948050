import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import {
  Form,
  Radio,
  Grid,
  Header,
  Message,
  Segment,
  Checkbox,
  Button,
  Dropdown,
} from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import genres from '../../shared/constants/genres';

import {
  updateUserProfile,
  updateUser,
  getUserProfile,
  refreshUserProfile,
} from '../../modules/user';

// utils
import { uxAnalyticsUtil, authUtil } from '../../utils';

import language from '../../config/language';
import DeleteAccountModal from './deleteAccount/deleteAccountModal';

const usernameRegex = /^[a-z0-9]+$/i;
const refererTokenKey = 'br-referer-token';

export const Profile = ({
  user,
  userProfile,
  updatingUserProfile,

  updateUser,
  updateUserProfile,
  t,
}) => {
  const [profileChanged, setProfileChanged] = useState(false);
  const [savingProfile, setSavingProfile] = useState(false);
  const [refererToken, setRefererToken] = useState();
  const [genreOptions, setGenreOptions] = useState();
  const [email, setEmail] = useState(user?.email ?? '');
  const [emailError, setEmailError] = useState(undefined);
  const [emailVerified, setEmailVerified] = useState(user?.emailVerified ?? '');
  const [newUser, setNewUser] = useState(userProfile?.newUser ?? undefined);
  const [uid, setUid] = useState(userProfile?.uid);
  const [preferredGenres, setPreferredGenres] = useState(
    userProfile?.preferredGenres ?? undefined
  );
  const [displayName, setDisplayName] = useState(
    userProfile?.displayName ?? ''
  );
  const [username, setUsername] = useState(userProfile?.username ?? '');
  const [usernameError, setUsernameError] = useState(undefined);
  const [role, setRole] = useState(userProfile?.role ?? undefined);
  const [receiveCompanyUpdates, setReceiveCompanyUpdates] = useState(
    userProfile?.emailPreferences?.receiveCompanyUpdates ?? false
  );
  const [receiveBookActivityUpdates, setReceiveBookActivityUpdates] = useState(
    userProfile?.emailPreferences?.receiveBookActivityUpdates ?? false
  );
  const [receiveBetaSuggestions, setReceiveBetaSuggestions] = useState(
    userProfile?.emailPreferences?.receiveBetaSuggestions ?? false
  );
  const [receiveMessageUpdates, setReceiveMessageUpdates] = useState(
    userProfile?.emailPreferences?.receiveMessageUpdates ?? false
  );
  const [verificationEmailMessage, setVerificationnEmailMessage] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');

  const passwordLongEnough = useCallback(
    () => newPassword?.length > 5,
    [newPassword]
  );
  const passwordMatch = useCallback(
    () => newPassword === repeatNewPassword,
    [newPassword, repeatNewPassword]
  );

  const saveProfileTimeout = useRef();

  const location = useLocation();

  // on first load
  useEffect(() => {
    // track page view
    uxAnalyticsUtil.trackPageView(
      location.pathname + location.hash + location.search
    );

    // set initial values
    const localStorageToken = localStorage.getItem(refererTokenKey);
    if (localStorageToken) {
      localStorage.removeItem(refererTokenKey);
    }
    const genreOptions = genres.map((genre) => ({
      key: genre,
      value: genre,
      text: genre,
    }));
    setRefererToken(refererToken);
    setGenreOptions(genreOptions);
  }, []);

  // whenever the user or userprofile props change
  useEffect(() => {
    if (!profileChanged || savingProfile) {
      setEmail(user?.email ?? undefined);
      setEmailVerified(user?.emailVerified ?? undefined);
      setPreferredGenres(userProfile?.preferredGenres ?? undefined);
      setPreferredGenres(userProfile?.uid ?? undefined);
      setNewUser(userProfile?.newUser ?? undefined);
      setDisplayName(userProfile?.displayName ?? undefined);
      setUsername(userProfile?.username ?? undefined);
      setRole(userProfile?.role ?? undefined);
      setReceiveCompanyUpdates(
        userProfile?.emailPreferences?.receiveCompanyUpdates ?? false
      );
      setReceiveBookActivityUpdates(
        userProfile?.emailPreferences?.receiveBookActivityUpdates ?? false
      );
      setReceiveBetaSuggestions(
        userProfile?.emailPreferences?.receiveBetaSuggestions ?? false
      );
      setReceiveMessageUpdates(
        userProfile?.emailPreferences?.receiveMessageUpdates ?? false
      );
      setSavingProfile(false);
    }
  }, [user, userProfile]);

  const isUsernameInvalid = (username) =>
    !username ||
    username.trim().length === 0 ||
    username.trim().length > 20 ||
    username.trim().length < 3 ||
    !username.match(usernameRegex);

  const usernameChanged = (event, data) => {
    const newName = data.value;
    if (newName > 0 && !newName.match(usernameRegex)) {
      return;
    }
    setUsername(newName);
    setProfileChanged(true);
    setUsernameError(isUsernameInvalid(newName));
  };

  const saveUsername = async () => {
    const idToken = await authUtil.getFreshIdToken();
    updateUserProfile(idToken, { username }, (success) => {
      if (!success) {
        // if we failed, reset username to the previous value
        setUsername(userProfile.username);
      }
    });
  };

  const roleChanged = async (event, data) => {
    // setRole(data.value);
    const idToken = await authUtil.getFreshIdToken();
    updateUserProfile(idToken, { role: data.value }, (success) => {
      if (!success) {
        // if we failed, reset username to the previous value
        toast.error(t('SomethingWentWrong'));
        setRole(userProfile.role);
      }
    });
  };

  const emailChanged = (event, data) => {
    setEmail(data.value);
    setProfileChanged(true);
  };

  const updateEmail = () => {
    if (user !== undefined && email !== undefined && email !== user.email) {
      // Updates the user attributes:
      user.updateEmail(email).then(
        function () {
          user
            .sendEmailVerification()
            .then(function () {
              // Email sent.
              updateUser(user);
            })
            .catch(function (error) {
              // An error happened.
              console.log('failed to update email', error);
            });
        },
        function (error) {
          // An error happened.
          setEmailError(error.message);
        }
      );
    }
  };

  const receiveCompanyUpdatesChanged = async (event, data) => {
    const idToken = await authUtil.getFreshIdToken();
    const updatedProfile = {
      emailPreferences: userProfile.emailPreferences,
    };
    updatedProfile.emailPreferences.receiveCompanyUpdates = data.checked;
    updatedProfile.emailPreferences.receiveCompanyUpdatesText = t(
      'ReceiveCompanyUpdates'
    );
    updateUserProfile(idToken, updatedProfile);
  };

  const receiveBookActivityUpdatesChanged = async (event, data) => {
    const idToken = await authUtil.getFreshIdToken();
    const updatedProfile = {
      emailPreferences: userProfile.emailPreferences,
    };
    updatedProfile.emailPreferences.receiveBookActivityUpdates = data.checked;
    updatedProfile.emailPreferences.receiveBookActivityUpdatesText = t(
      'ReceiveBookActivityUpdates'
    );
    updateUserProfile(idToken, updatedProfile);
  };

  const receiveBetaSuggestionsChanged = async (event, data) => {
    const idToken = await authUtil.getFreshIdToken();
    const updatedProfile = {
      emailPreferences: userProfile.emailPreferences,
    };
    updatedProfile.emailPreferences.receiveBetaSuggestions = data.checked;
    updatedProfile.emailPreferences.receiveBetaSuggestionsText =
      t('ReceiveBookEmails');
    updateUserProfile(idToken, updatedProfile);
  };

  const receiveMessageUpdatesChanged = async (event, data) => {
    const idToken = await authUtil.getFreshIdToken();
    const updatedProfile = {
      emailPreferences: userProfile.emailPreferences,
    };
    updatedProfile.emailPreferences.receiveMessageUpdates = data.checked;
    updateUserProfile(idToken, updatedProfile);
  };

  const newPasswordChanged = (event, data) => {
    const thePassword = data.value;
    setNewPassword(thePassword);
  };

  const repeatNewPasswordChanged = (event, data) => {
    const thePassword = data.value;
    setRepeatNewPassword(thePassword);
  };

  const updatePassword = () => {
    if (passwordLongEnough() && passwordMatch() && user !== undefined) {
      user
        .updatePassword(newPassword)
        .then(() => {
          setNewPassword(undefined);
          setRepeatNewPassword(undefined);
        })
        .catch((error) => {
          console.log('failed to update password');
          uxAnalyticsUtil.trackException({
            description: `failed to update password ${error.message}`,
            fatal: false,
          });
        });
    }
  };

  const saveProfile = () => {
    clearTimeout(saveProfileTimeout.current);
    saveProfileTimeout.current = setTimeout(async () => {
      setSavingProfile(true);
      const profileData = {};
      if (preferredGenres !== undefined) {
        profileData.preferredGenres = preferredGenres;
      }
      if (displayName !== undefined) {
        profileData.displayName = displayName;
      }
      if (username !== undefined) {
        profileData.username = username;
      }
      if (role !== undefined) {
        profileData.role = role;
      }
      const idToken = await authUtil.getFreshIdToken();
      updateUserProfile(idToken, profileData);
    }, 1500);
  };

  const handleLanguageChange = async (event, data) => {
    const idToken = await authUtil.getFreshIdToken();
    updateUserProfile(idToken, { language: data.value });
  };

  // render
  if (user !== undefined && userProfile !== undefined) {
    return (
      <React.Fragment>
        {email === null && (
          <Message warning>
            <Header content={t('WeNeedYourEmail')} />
            {t('MissingEmailMsg')}
          </Message>
        )}
        {emailError !== undefined && (
          <Message warning>
            email error
            {emailError}
          </Message>
        )}
        <Grid columns={2} stackable>
          <Grid.Column>
            <Header content={t('Settings')} icon='setting' />
            <Segment clearing>
              <Form
                onSubmit={updateEmail}
                error={email === null || email === ''}
              >
                <Form.Group widths='equal'>
                  <Form.Field>
                    <Form.Input
                      fluid
                      label={t('Email')}
                      placeholder={t('Email')}
                      value={email || ''}
                      onChange={emailChanged}
                      error={
                        email === null || email === '' || emailError === null
                      }
                      action={
                        email !== userProfile.email ? (
                          <Button icon='save' />
                        ) : null
                      }
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
              <Form onSubmit={saveUsername} error={usernameError}>
                <Form.Group widths='equal'>
                  <Form.Field>
                    <Form.Input
                      fluid
                      label={t('Username')}
                      placeholder={t('Username')}
                      value={username}
                      onChange={usernameChanged}
                      error={usernameError}
                      action={
                        username !== userProfile.username ? (
                          <Button
                            icon='save'
                            loading={
                              updatingUserProfile?.includes('username') ?? false
                            }
                          />
                        ) : null
                      }
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
              <Form>
                <Form.Group inline>
                  <label>{t('Role')}</label>
                  <Form.Field>
                    <Radio
                      label={t('RoleWriter')}
                      name='radioGroup'
                      value='WRITER'
                      checked={role === 'WRITER'}
                      onChange={roleChanged}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label={t('RoleReader')}
                      name='radioGroup'
                      value='READER'
                      checked={role === 'READER'}
                      onChange={roleChanged}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label={t('RoleBoth')}
                      name='radioGroup'
                      value='BOTH'
                      checked={role === 'BOTH'}
                      onChange={roleChanged}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Field
                  label={t('Language')}
                  onChange={handleLanguageChange}
                  control={Dropdown}
                  floating
                  inline
                  options={language.languageOptions}
                  defaultValue={userProfile ? userProfile.language : 'en'}
                ></Form.Field>
                <Form.Field>
                  {!!userProfile &&
                    !!userProfile.language &&
                    !['en', 'se', 'pt'].includes(userProfile.language) && (
                      <Trans i18nKey='BetaLanguageMessage'>
                        <em>
                          We are working on improved language support, and are
                          super grateful for any help we can get. If you find
                          any errors on the site, please don't hesitate to
                          contact us on:{' '}
                          <a href='mailto:hello@betareader.io'>
                            hello@betareader.io
                          </a>
                        </em>
                      </Trans>
                    )}
                </Form.Field>
              </Form>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Header content={t('Email')} icon='mail' />
            <Segment clearing style={{ zIndex: 0 }}>
              <Form>
                <Form.Field
                  label={t('ReceiveCompanyUpdates')}
                  control={Checkbox}
                  toggle
                  onChange={receiveCompanyUpdatesChanged}
                  checked={userProfile.emailPreferences.receiveCompanyUpdates}
                />
                <Form.Field
                  label={t('ReceiveBookActivityUpdates')}
                  control={Checkbox}
                  toggle
                  onChange={receiveBookActivityUpdatesChanged}
                  checked={
                    userProfile.emailPreferences.receiveBookActivityUpdates
                  }
                />
                <Form.Field
                  label={t('ReceiveBookEmails')}
                  control={Checkbox}
                  toggle
                  onChange={receiveBetaSuggestionsChanged}
                  checked={userProfile.emailPreferences.receiveBetaSuggestions}
                />
                <Form.Field
                  label={t('ReceiveMessageUpdates')}
                  control={Checkbox}
                  toggle
                  onChange={receiveMessageUpdatesChanged}
                  checked={userProfile.emailPreferences.receiveMessageUpdates}
                />
              </Form>
            </Segment>
            <Segment clearing>
              <Form.Field>
                <DeleteAccountModal />
              </Form.Field>
            </Segment>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
  return null;
};

// redux stuff
const mapStateToProps = (state) => ({
  user: state.user.user,
  userProfile: state.user.userProfile,
  updatingUserProfile: state.user.updatingUserProfile,
  socialProfiles: state.user.socialProfiles,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserProfile,
      updateUser,
      getUserProfile,
      refreshUserProfile,
      changePage: (newPage) => push(newPage),
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile))
);
