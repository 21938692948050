import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Feed, Container } from 'semantic-ui-react';
// i18n
import { withTranslation } from 'react-i18next';
// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// pop-ups
import { toast } from 'react-toastify';
// custom modules
import FeedEvent from '../feed/feedEvent';
import NewBookPost from './newBookPost';
import { CenteredContent, PaddedWrapper } from '../../style';

let dragCounter = 0;

export class BookPosts extends Component {

  constructor(props) {
    super(props);

    this.state = {
      feed: []
    };
  }

  componentDidMount() {
    this.fetchBookPosts(this.props);
  }

  fetchBookPosts = async (props) => {
    const { idToken, currentBook } = props;
    this.setState({
      isLoading: true
    }, () => {
      fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${currentBook._id}/posts`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.error);
          }
          return res.json();
        })
        .then((feed) => {
          if (feed) {
            this.setState({
              feed
            });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.setState({
            isLoading: false
          })
        });
    });
  }

  postBookPost = (postData, callback) => {
    const { idToken, currentBook, t } = this.props;
    this.setState({
      isPosting: true
    }, () => {
      fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${currentBook._id}/posts`, {
        method: 'POST',
        headers: {
          'br-token': idToken
        },
        body: postData
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.error);
          }
          return res.json();
        })
        .then((newBookPost) => {
          if (newBookPost) {
            if (callback) {
              callback(true);
            }
            this.setState({
              feed: [newBookPost, ...this.state.feed]
            });
          }
        })
        .catch((err) => {
          console.error(err);
          if (callback) {
            callback(false);
          }
          toast.error(t('FailedToPostBookPost'));
        })
        .finally(() => {
          this.setState({
            isPosting: false
          })
        });
    });
  }

  updateBookPost = (bookPost, callback) => {
    const { idToken, currentBook, t } = this.props;
    this.setState({
      isPosting: true
    }, () => {
      fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${currentBook._id}/posts/${bookPost._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify(bookPost)
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.error);
          }
          return res.json();
        })
        .then((updatedBookPost) => {
          if (updatedBookPost) {
            if (callback) {
              callback(true);
            }
            const updatedPosts = [...this.state.feed];
            updatedPosts.splice(
              updatedPosts.findIndex(post => post._id === bookPost._id),
              1,
              updatedBookPost
            );
            this.setState({
              feed: updatedPosts
            });
          }
        })
        .catch((err) => {
          console.error(err);
          if (callback) {
            callback(false);
          }
          toast.error(t('FailedToPostBookPost'));
        })
        .finally(() => {
          this.setState({
            isPosting: false
          })
        });
    });
  }

  deleteBookPost = (bookPost, callback) => {
    const { idToken, currentBook, t } = this.props;
    this.setState({
      isPosting: true
    }, () => {
      fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${currentBook._id}/posts/${bookPost._id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        },
        body: JSON.stringify(bookPost)
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.error);
          }
          return res.json();
        })
        .then(() => {
          const updatedFeed = [...this.state.feed];
          updatedFeed.splice(
            updatedFeed.findIndex(post => post._id === bookPost._id),
            1
          );
          this.setState({
            feed: updatedFeed
          });
        })
        .catch((err) => {
          console.error(err);
          if (callback) {
            callback(false);
          }
          toast.error(t('FailedToDeletePost'));
        })
        .finally(() => {
          this.setState({
            isPosting: false
          })
        });
    });
  }

  preventDefaults = (event) => {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragEnter = (event) => {
    this.preventDefaults(event);
    dragCounter += 1;
    if (dragCounter > 0) {
      this.setState({
        dragging: true
      });
    }
  }

  onDragLeave = (event) => {
    this.preventDefaults(event);
    dragCounter -= 1;
    if (dragCounter === 0) {
      this.setState({
        dragging: false
      });
    }
  }

  onDrop = (event) => {
    this.preventDefaults(event);
    dragCounter = 0;
    this.setState({
      dragging: false
    });
  }

  render() {
    const {
      feed, isPosting, dragging
    } = this.state;
    const { user } = this.props;
    return (
      <div onDragOver={this.preventDefaults} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} className={dragging ? 'drag' : null}>
        <CenteredContent>
          <PaddedWrapper>
            <NewBookPost
              onNewBookPost={this.postBookPost}
              isPosting={isPosting === true}
              dragging={dragging}
            />
            <Feed>
              {
                feed && feed
                  .filter(event => !event.isLast)
                  .map(event => (
                    <FeedEvent
                      key={event._id}
                      event={event}
                      onUpdatePost={this.updateBookPost}
                      editable={user !== undefined && user.uid === event.createdBy._id}
                      onDeletePost={this.deleteBookPost}
                    />
                  )
                  )
              }
            </Feed>
          </PaddedWrapper>
        </CenteredContent>
      </div>
    );
  }
}


// redux stuff
const mapStateToProps = state => ({
  idToken: state.user.idToken,
  user: state.user.user,
  currentBook: state.book.currentBook
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: (newPage) => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(BookPosts)));
