import React from 'react';
import { Header, Image, Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const GoodbyePage = ({ t }) => {
  return (
    <Wrapper>
      <Header size='huge'>
        {t('GoodbyeHeader')}
      </Header>
      <Icon name='heart' size='massive' color='red' />
      <Header size='medium' color='grey'>
        {t('GoodbyeMessage')}
      </Header>
      <span>
        {'Check out the '}
        <a href='https://betareader.io/blog'>
          Beta Blog
        </a>
      </span>
    </Wrapper>
  )
}

export default withTranslation()(withRouter(GoodbyePage));