import styled from 'styled-components';
import { Label } from 'semantic-ui-react';
import { MediaQuery } from '../constants/size';

export const CenteredContent = styled.div`
  max-width: ${({ maxWidth = 768 }) => `${maxWidth}px`}; 
  margin: 0 auto;
`;

export const EllipsisSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const NoWrapLabel = styled(Label)`
  white-space: nowrap;
`;

export const PaddedWrapper = styled.div`
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
`;

export const MinWidthSpan = styled.span`
  ${MediaQuery.phone} {
    display: none;
  }
`;
