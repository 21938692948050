import React, {useEffect, useState, useRef, useCallback} from 'react';
import {Button, Form, Comment} from 'semantic-ui-react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import styled, {css} from 'styled-components';
import {
  submitComment,
  submitReply,
  clearCommentList
} from '../../modules/comment';

import CommentElement from './commentElement';
import {authUtil, uxAnalyticsUtil} from '../../utils';
import TextAreaWithSave from '../../components/inputs/TextAreaWithSave';

const WordCount = styled.div`
  font-size: 0.8em;
  font-style: italic;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  ${({theme, warning}) => css`
    color: ${warning ? theme.font.warning : theme.font.primary};
  `}
`;

const CommentsList = ({
  compact,
  filter,
  disableLeavingComments,
  showChapterLinks
}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const currentBook = useSelector(state => state.book.currentBook);
  const currentSpineIndex = useSelector(
    state => state.readerApp.currentSpineIndex
  );
  const activeInlineComment = useSelector(
    state => state.comment.activeInlineComment
  );
  const currentLanguage = useSelector(state => state.user.currentLanguage);
  const comments = useSelector(state => state.comment.comments);
  const commentFilters = useSelector(state => state.comment.filters);

  const [replyForm, setReplyForm] = useState();
  const [showReplies, setShowReplies] = useState(false);
  const [comment, setComment] = useState('');
  const commentListReference = useRef();
  const commentElementReferences = useRef({});

  const getFilteredComments = useCallback(() => {
    let filteredComments = comments && [...comments];
    if (commentFilters?.length > 0) {
      // filter out specific comments if any other filters are specified
      filteredComments = filteredComments.filter(
        comment => commentFilters.indexOf(comment.status) >= 0
      );
    }
    return filteredComments;
  }, [commentFilters, comments]);

  const canSave = useCallback(
    () => comment?.length > 0 && comment?.length <= 2000,
    [comment]
  );

  useEffect(
    () => () => {
      dispatch(clearCommentList());
    },
    []
  );

  const handleCommentUpdate = (e, data) => {
    setComment(data.value);
  };

  const handleSubmitComment = async (event, data) => {
    if (!canSave()) return;
    const idToken = await authUtil.getFreshIdToken();
    dispatch(
      submitComment(
        idToken,
        currentBook._id,
        currentBook.parts[currentSpineIndex]._id,
        comment
      )
    );

    uxAnalyticsUtil.trackEvent({
      category: uxAnalyticsUtil.categories.READING,
      action: 'Left comment'
    });

    setReplyForm(undefined);
    setComment('');
  };

  const handleSubmitReply = async (commentObject, reply) => {
    const idToken = await authUtil.getFreshIdToken();
    dispatch(submitReply(idToken, commentObject, reply));
  };

  const handleDomReference = ref => {
    commentListReference.current = ref;
  };

  const handleCommentElementRef = (commentId, element) => {
    commentElementReferences.current[commentId] = element;
  };

  const getCommentList = inboundComments => {
    let comments = inboundComments;
    if (filter) {
      comments = comments.filter(
        c => c.kind === filter || c.kind === undefined
      );
    }
    const commentList =
      comments.length > 0
        ? comments.map((c, index, array) => {
            const hasReplies = c.replies && c.replies.length > 0;
            const isActive =
              activeInlineComment !== undefined &&
              activeInlineComment._id === c._id;
            if (!c._id) return null;
            return (
              <div
                style={{marginTop: 5}}
                id={`br-comment-cid-${c._id}`}
                ref={ref => {
                  handleCommentElementRef(c._id, ref);
                }}
                key={c._id}>
                <CommentElement
                  showChapterLinks={showChapterLinks}
                  compact={compact !== undefined}
                  key={clearCommentList._id}
                  isActive={isActive}
                  comment={c}
                  currentLanguage={currentLanguage}
                  hasReplies={hasReplies}
                  replyForm={replyForm}
                  submitReply={handleSubmitReply}
                  handleDomReference={handleCommentElementRef}
                />
              </div>
            );
          })
        : '';
    return commentList;
  };

  return (
    <div ref={handleDomReference}>
      <Comment.Group>
        {!disableLeavingComments && (
          <Form reply>
            <TextAreaWithSave
              onChange={handleCommentUpdate}
              onSave={handleSubmitComment}
              value={comment}
            />
            <WordCount warning={comment?.length > 2000}>
              {Number(comment?.length ?? 0).toLocaleString()} /{' '}
              {Number(2000).toLocaleString()}
            </WordCount>
            <Button
              onClick={handleSubmitComment}
              disabled={!(comment?.length > 0) || comment?.length > 2000}
              content={t('LeaveComment')}
              labelPosition='left'
              icon='edit'
              primary
            />
          </Form>
        )}
        {getFilteredComments()?.length === 0 && (
          <Comment content={t('NoCommentsToShow')} />
        )}
        {getFilteredComments()?.length > 0 &&
          getCommentList(getFilteredComments())}
      </Comment.Group>
    </div>
  );
};

export default CommentsList;
