import React from 'react';
import {useTranslation} from 'react-i18next';
import {Segment, Grid, Statistic, Icon} from 'semantic-ui-react';
import {Media} from 'src/utils/Media';

type Props = {
  readerStatistics?: any;
};
const ActiveReaders: React.FC<Props> = ({readerStatistics}) => {
  const {t} = useTranslation();
  return (
    <div>
      <Segment.Group horizontal>
        <Segment className='mymanuscripts-analytics-top-segment'>
          <Grid columns={2} stackable verticalAlign='middle'>
            <Grid.Column>
              <Media greaterThan='mobile'>
                {(className, renderChildren) =>
                  renderChildren && (
                    <Grid.Row>
                      <span className='mymanuscripts-analytics-header-large'>
                        {t('CurrentlyReading')}
                      </span>
                    </Grid.Row>
                  )
                }
              </Media>
              <Media greaterThan='mobile'>
                {(className, renderChildren) =>
                  renderChildren && (
                    <Grid.Row>
                      <br />
                      <Icon name='chart line' size='big' color='grey' />
                    </Grid.Row>
                  )
                }
              </Media>
              <Media at='mobile'>
                {(className, renderChildren) =>
                  renderChildren && (
                    <Grid.Row className='mymanuscripts-analytics-header-small'>
                      {t('CurrentlyReading')}
                    </Grid.Row>
                  )
                }
              </Media>
            </Grid.Column>
            <Media greaterThan='mobile'>
              {(className, renderChildren) =>
                renderChildren && (
                  <Grid.Column verticalAlign='middle'>
                    <Statistic color='teal'>
                      <Statistic.Value>
                        {readerStatistics.currentlyReading
                          ? readerStatistics.currentlyReading
                          : 0}
                      </Statistic.Value>
                    </Statistic>
                  </Grid.Column>
                )
              }
            </Media>
            <Media at='mobile'>
              {(className, renderChildren) =>
                renderChildren && (
                  <Grid.Column
                    className='mymanuscripts-analytics-value'
                    verticalAlign='bottom'>
                    {readerStatistics.currentlyReading
                      ? readerStatistics.currentlyReading
                      : 0}
                  </Grid.Column>
                )
              }
            </Media>
          </Grid>
        </Segment>
        <Segment className='mymanuscripts-analytics-top-segment'>
          <Grid columns={2} stackable verticalAlign='middle'>
            <Grid.Column>
              <Media greaterThan='mobile'>
                {(className, renderChildren) =>
                  renderChildren && (
                    <Grid.Row>
                      <span className='mymanuscripts-analytics-header-large'>
                        {t('WeeklyReaders')}
                      </span>
                    </Grid.Row>
                  )
                }
              </Media>
              <Media greaterThan='mobile'>
                {(className, renderChildren) =>
                  renderChildren && (
                    <Grid.Row>
                      <br />
                      <Icon
                        name='calendar alternate outline'
                        size='big'
                        color='grey'
                      />
                    </Grid.Row>
                  )
                }
              </Media>
              <Media at='mobile'>
                {(className, renderChildren) =>
                  renderChildren && (
                    <Grid.Row className='mymanuscripts-analytics-header-small'>
                      {t('WeeklyReaders')}
                    </Grid.Row>
                  )
                }
              </Media>
            </Grid.Column>
            <Media greaterThan='mobile'>
              {(className, renderChildren) =>
                renderChildren && (
                  <Grid.Column verticalAlign='middle'>
                    <Statistic color='teal'>
                      <Statistic.Value>
                        {readerStatistics.totalReadersLastWeek
                          ? readerStatistics.totalReadersLastWeek
                          : 0}
                      </Statistic.Value>
                    </Statistic>
                  </Grid.Column>
                )
              }
            </Media>
            <Media at='mobile'>
              {(className, renderChildren) =>
                renderChildren && (
                  <Grid.Column
                    className='mymanuscripts-analytics-value'
                    verticalAlign='bottom'>
                    {readerStatistics.totalReadersLastWeek
                      ? readerStatistics.totalReadersLastWeek
                      : 0}
                  </Grid.Column>
                )
              }
            </Media>
          </Grid>
        </Segment>
        <Segment className='mymanuscripts-analytics-top-segment'>
          <Grid columns={2} stackable verticalAlign='middle'>
            <Grid.Column>
              <Media greaterThan='mobile'>
                {(className, renderChildren) =>
                  renderChildren && (
                    <Grid.Row className={className}>
                      <span className='mymanuscripts-analytics-header-large'>
                        {t('TotalReaders')}
                      </span>
                    </Grid.Row>
                  )
                }
              </Media>
              <Media greaterThan='mobile'>
                {(className, renderChildren) =>
                  renderChildren && (
                    <Grid.Row className={className}>
                      <br />
                      <Icon name='users' size='big' color='grey' />
                    </Grid.Row>
                  )
                }
              </Media>
              <Media at='mobile'>
                {(className, renderChildren) =>
                  renderChildren && (
                    <Grid.Row
                      className={`mymanuscripts-analytics-header-small ${className}`}>
                      {t('TotalReaders')}
                    </Grid.Row>
                  )
                }
              </Media>
            </Grid.Column>
            <Media greaterThan='mobile'>
              {(className, renderChildren) =>
                renderChildren && (
                  <Grid.Column verticalAlign='middle' className={className}>
                    <Statistic color='teal'>
                      <Statistic.Value>
                        {readerStatistics.totalReaders
                          ? readerStatistics.totalReaders
                          : 0}
                      </Statistic.Value>
                    </Statistic>
                  </Grid.Column>
                )
              }
            </Media>
            <Media at='mobile'>
              {(className, renderChildren) =>
                renderChildren && (
                  <Grid.Column
                    className={`mymanuscripts-analytics-value ${className}`}
                    verticalAlign='bottom'>
                    {readerStatistics.totalReaders
                      ? readerStatistics.totalReaders
                      : 0}
                  </Grid.Column>
                )
              }
            </Media>
          </Grid>
        </Segment>
      </Segment.Group>
    </div>
  );
};

export default ActiveReaders;
