import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import {Message} from 'semantic-ui-react';

// redux
import {push} from 'connected-react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// i18n
import {withTranslation} from 'react-i18next';

import {uxAnalyticsUtil} from '../../utils';

class AppAlertMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {alert} = this.props;
    uxAnalyticsUtil.trackEvent({
      category: uxAnalyticsUtil.categories.ALERTS,
      action: 'Displayed app alert',
      label: alert._id,
      nonInteraction: true
    });
  }

  onDismiss = (event, data) => {
    const {alert} = this.props;
    uxAnalyticsUtil.trackEvent({
      category: uxAnalyticsUtil.categories.ALERTS,
      action: 'Dismissed app alert',
      label: alert._id
    });
    if (this.props.onDismiss) {
      this.props.onDismiss(alert._id, alert.daysToExpire);
    }
  };

  getLink = action => {
    if (action.path !== undefined) {
      return (
        <Link style={{paddingLeft: '1em'}} to={action.path}>
          {action.text}
        </Link>
      );
    }
    // no path, assume regular url
    return (
      <a
        style={{paddingLeft: '1em'}}
        href={action.url}
        target={action.target ? action.target : '_self'}
        onClick={this.handleDismiss}>
        {' '}
        {action.text}
      </a>
    );
  };

  render() {
    const {alert} = this.props;
    return (
      <Message
        color={alert.color}
        onDismiss={alert.dismissable ? this.onDismiss : null}
        style={{marginTop: 0, marginBottom: 0}}>
        <Message.Content>
          {alert.message}
          {alert.action && this.getLink(alert.action)}
        </Message.Content>
      </Message>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  idToken: state.user.idToken,
  user: state.user.user,
  userProfile: state.user.userProfile
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: newPage => push(newPage)
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AppAlertMessage))
);
