import React, { Component } from 'react';
import { Form, Icon, Grid } from 'semantic-ui-react'

export class CheckboxAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillUnmount() {
    if (this.props && this.props.clearSurvey) {
      this.props.clearSurvey();
    }
  }

  toggleAnswer = (option) => {
    const { question, answer } = this.props;
    let answers = (answer !== undefined && answer.answer !== undefined) ? [...answer.answer] : [];
    // remove any erroneous answers
    answers = answers.filter(answer => question.options.options.includes(answer));
    const answerIndex = answers.findIndex(existingAnswer => existingAnswer === option);
    if (answerIndex < 0) {
      // the answer didn't exist already
      if (answers.length === question.options.maxSelected) {
        // replace the last answer if we've reached max
        answers.splice(answers.length-1,1,option);
      } else if (answers.length < question.options.maxSelected){
        // otherwise, add it if it won't make us exceed maxSelected
        answers.push(option);
      }
    } else {
      // otherwise (if the answer existed), remove the answer
      answers.splice(answerIndex, 1);
    }
    this.props.onChange(answers, true);
  }

  render() {
    const { question, answer } = this.props;
    return (
      <Grid columns={3} stackable>
        {
          question.options.options.map((option, index) => {
            const isAnswered = answer !== undefined && answer.answer !== undefined && answer.answer.includes(option);
            return (
              <Grid.Column
                key={index}>
                <Form.Field
                  style={{cursor: 'pointer'}}
                  onClick={(event)=>{
                    event.preventDefault();
                    this.toggleAnswer(option);
                  }}
                  onKeyDown={(event) => { this.handleKeyDown(event, option); }}
                >
                  <Icon name={isAnswered ? 'check square outline' : 'square outline'} style={{ marginRight: '1em' }} />
                  <span>
                    {option}
                  </span>
                </Form.Field>
              </Grid.Column>
            );
          })
        }
      </Grid>
    );
  }
}

export default CheckboxAnswer;
