import React, { useState, useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import {
  Image, Modal, Button, Grid
} from 'semantic-ui-react';

// i18n
import { withTranslation } from 'react-i18next';

// redux
import { useDispatch, useSelector } from 'react-redux';

import {
  saveBook,
  fetchCurrentBook
} from '../../../modules/book';

import { imageUtil, accessUtil, authUtil } from '../../../utils';
import CoverFileUpload from './coverFileUpload';

const BookCover = ({
  t
}) => {
  const currentBook = useSelector(state => state.book.currentBook);
  const params = useParams();
  const dispatch = useDispatch();

  const [showDeleteCoverModal, setShowDeleteCoverModal] = useState(false);
  const [uploadActive, setUploadActive] = useState(false);

  const readOnly = !accessUtil.isAllowed(currentBook?.access, ['edit']);

  useEffect(() => {
    if (currentBook?.cover?._id === 'default_cover') {
      setUploadActive(true);
    }
  }, [currentBook?.cover?._id]);

  const deleteCover = async () => {
    const idToken = await authUtil.getFreshIdToken();
    if (!accessUtil.isAllowed(currentBook.access, ['edit'])) {
      return;
    }
    dispatch(saveBook(idToken, {
      _id: currentBook._id,
      cover: null
    }));
    setUploadActive(true);
  }

  const toggleDeleteCoverModal = (showModal) => {
    if (typeof showModal !== 'boolean') {
      // toggle to opposite if undefined
      setShowDeleteCoverModal(!showDeleteCoverModal);
    } else {
      // set to specified
      setShowDeleteCoverModal(showModal);
    }
  }

  const coverUploadComplete = async () => {
    const idToken = await authUtil.getFreshIdToken();
    dispatch(fetchCurrentBook({ idToken, bookId: params.bookId }));
    setUploadActive(false);
  }

  if (!currentBook) return null;

  let imageProps;

  if (currentBook?.cover?._id !== 'default_cover' && currentBook?.cover?.highRes) {
    imageProps = {
      centered: true,
      height: 200,
      width: 'auto',
      src: imageUtil.getImageUrl({
        operation: 'height',
        size: 200,
        originalImageUrl: currentBook.cover.highRes.url
      })
    };
    if (!readOnly) {
      imageProps.label = {
        as: 'a',
        corner: 'right',
        icon: 'trash alternate outline',
        onClick: toggleDeleteCoverModal
      };
    }
  }

  return (
    <div style={{ height: 200, padding: 0 }}>
      {
        !uploadActive && imageProps && (
          <Image {...imageProps} />
        )
      }
      {
        uploadActive && (
          <div style={{ background: 'lightgrey', height: '100%', width: 200 / 1.6 }}>
            <CoverFileUpload
              style={{ display: 'inline-block' }}
              uploadMessage={t('CoverUploadMsg')}
              onComplete={coverUploadComplete}
            />
          </div>
        )
      }
      <Modal
        key='deleteCoverModal'
        open={showDeleteCoverModal}
        closeOnEscape
        closeOnDimmerClick
        size='tiny'
        onClose={toggleDeleteCoverModal}
      >
        <Modal.Header>{t('DeleteCover')}</Modal.Header>
        <Modal.Content>
          <p>{t('DeleteCoverConfirmation')}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            secondary
            onClick={toggleDeleteCoverModal}
            content={t('No')}
          />
          <Button
            negative
            onClick={() => {
              toggleDeleteCoverModal(false);
              deleteCover();
            }}
            labelPosition='right'
            icon='trash alternate outline'
            content={t('Yes')}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );

}

export default withTranslation()(withRouter(BookCover));
