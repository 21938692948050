import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Divider, Header, Rating
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  updateUserProfile,
  updateUser
} from '../../../modules/user';
import Avatar from '../../../components/avatar/avatar';

const UserReview = ({
  isLastInList,
  review,

  t,
}) => {
  if (review) {
    return (
      <React.Fragment>
        <Divider section />
        <Header
          style={{ display: 'flex', alignItems: 'center' }}
          content={(
            <React.Fragment>
              <div style={{ width: 25, height: 25 }}>
                <Avatar
                  userId={review.reviewer._id}
                  name={review.reviewer.displayName}
                  diameter={25}
                />
              </div>
              <span style={{ fontSize: '0.8em' }}>{review.reviewer.displayName}</span>
            </React.Fragment>
          )}
        />
        <Rating icon='star' maxRating={5} rating={review.rating} />
        <div>
          {review.text}
        </div>
        {
          isLastInList && (
            <Divider section />
          )
        }
      </React.Fragment>
    );
  }
}

export default withTranslation()(withRouter(UserReview));
