import React from 'react';

import { Visibility, Menu, Icon } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

// stateless component
const LoadMore = ({ t, onLoadMore, loading }) => (
  <Visibility
    offset={[10, 10]}
    once={false}
    onTopVisible={onLoadMore}
    as={Menu}
    text
    style={{ display: 'flex', justifyContent: 'center' }}
  >
    <Menu.Item
      onClick={onLoadMore}
      content={(
        <span>
          {
            !loading && t('LoadMore')
          }
          {
            loading && (
              <Icon name='spinner' loading />
            )
          }
        </span>
      )}
    />
  </Visibility>
);

export default withTranslation()(LoadMore);
