import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Components
import {
  List
} from 'semantic-ui-react';

// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  fetchConversations,
  fetchContacts
} from '../../modules/message';

import Avatar from '../../components/avatar/avatar';

export class ConversationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { idToken } = this.props;
    if (idToken !== undefined) {
      this.props.fetchConversations(idToken);
    }
  }

  isMessageRead = (seenBy) => {
    const { userProfile } = this.props;
    const isRead = seenBy?.find(seenByDoc => seenByDoc.user._id === userProfile._id);
    return !!isRead;
  }

  render() {
    const {
      conversations, currentConversation, collapsed, userProfile
    } = this.props;

    return (
      <List selection>
        {
          conversations.map(conversation => (
            <List.Item
              key={conversation._id}
              active={currentConversation && currentConversation._id === conversation._id}
              onClick={() => { this.props.changePage(`/inbox/conversations/${conversation._id}`); }}
              style={{ display: 'flex', flexDirection: 'row' }}
            >

              <div style={{ width: '3em', position: 'relative' }}>
                <div style={{ width: 20, height: 20 }}>
                  { conversation.type && conversation.type === 'support'
                    ? <Avatar key='c_$userAvatarLeft' name={userProfile.displayName} diameter={25} style={{ marginRight: 2 }} />
                    : <Avatar name={conversation.members[0].displayName} diameter={20} />
                  }
                </div>
                {
                  conversation.members.length > 1 && (
                    <div
                      style={{
                        width: 20, height: 20, position: 'relative', left: 10, bottom: 10
                      }}
                    >
                      { conversation.type && conversation.type === 'support'
                        ? <Avatar key='c_$supportAvatarLeft' name='support' diameter={25} style={{ marginRight: 2 }} />
                        : (
                          <Avatar
                            name={conversation.members[conversation.members.length - 1].displayName}
                            diameter={20}
                          />
                        )
                     }
                    </div>
                  )
                }
              </div>
              {
                !collapsed && (
                  <div style={{ flex: 1, maxWidth: 50, flexBasis: 'auto' }}>
                    <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                      { conversation.subject }
                    </span>
                    <br />
                    {
                      conversation.messages && conversation.messages[0]
                      && (
                        <span
                          style={{
                            fontSize: '0.8em',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {conversation.messages[0].from && conversation.messages[0].from.displayName} : {conversation.messages[0].text}
                        </span>
                      )
                    }
                  </div>
                )
              }
              { conversation?.messages?.length > 0 && !this.isMessageRead(conversation?.messages[0].seenBy) &&
                <div
                  style={{ position: 'absolute', right: 20}}
                >
                  <span className='dot'></span>
                </div>
              }
            </List.Item>
          ))
        }
      </List>
    );
  }
}


// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  userProfile: state.user.userProfile,
  idToken: state.user.idToken,
  conversations: state.message.conversations,
  currentConversation: state.message.currentConversation
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchConversations,
  fetchContacts,
  changePage: newPage => push(newPage)
}, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationList));
