export const CREATING_BOOK_POST = 'post/CREATING_BOOK_POST';

const initialState = {
  creatingBookPost: false,
}

// reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case CREATING_BOOK_POST:
      return {
        ...state,
        creatingBookPost: action.value
      };
    default:
      return state
  }
}

export const createBookPost = (idToken, bookId, postData, callback) => {
  return (dispatch) => {
    dispatch({
      type: CREATING_BOOK_POST,
      value: true
    });
    fetch(`${process.env.REACT_APP_API_HOST}/api/v2/books/${bookId}/posts`, {
      method: 'POST',
      headers: {
        'Content-Type': postData.images ? 'multipart/form-data' : 'application/json',
        'br-token': idToken
      },
      body: JSON.stringify(postData)
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.error)
        }
        return res.json();
      })
      .then((postData) => {
        callback(true)
      })
      .catch((err) => {
        callback(false, err);
      })
      .finally(() => {
        dispatch({
          type: CREATING_BOOK_POST,
          value: false
        });
      });
  }
}
