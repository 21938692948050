import React, {Component} from 'react';
import {Label, Header, Form, Input, Button, Dropdown} from 'semantic-ui-react';
import {withTranslation} from 'react-i18next';

import genres from '../../../../shared/constants/genres';

class GenresGetter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGenres: props.initialValue || [],
      searchString: ''
    };
  }

  handleFilterKey = event => {
    if (event.keyCode === 13) {
      // Enter
      const visibleGenres = this.getVisibleGenres();
      if (visibleGenres.length === 1) {
        // if only one genre was visible, assume that the user wants to select it
        this.toggleGenre(visibleGenres[0]);
      }
    }
  };

  handleFilterChange = (event, data) => {
    this.setState({
      searchString: data.value
    });
  };

  handleDropdownChange = (event, data) => {
    const {autoUpdate} = this.props;
    this.setState(
      {
        selectedGenres: data.value
      },
      () => {
        if (autoUpdate) this.submit();
      }
    );
  };

  handleGenreClick = event => {
    const genre = event.target.getAttribute('data-genre');
    this.toggleGenre(genre);
  };

  toggleGenre = genre => {
    const {autoUpdate} = this.props;
    this.setState(
      prevState => {
        const newGenres = [...prevState.selectedGenres];
        const index = newGenres.findIndex(g => g === genre);
        if (index === -1) {
          newGenres.push(genre);
        } else {
          newGenres.splice(index, 1);
        }
        return {
          ...prevState,
          selectedGenres: [...newGenres],
          searchString: '' // reset search string to show all genres
        };
      },
      () => {
        if (autoUpdate) this.submit();
      }
    );
  };

  submit = () => {
    const {selectedGenres} = this.state;
    if (this.props.onUpdate) {
      const cleanedGenreList = selectedGenres.filter(
        genre => !!genre && genre.length > 0
      );
      this.props.onUpdate(cleanedGenreList);
    }
  };

  getVisibleGenres = () => {
    const {searchString} = this.state;
    const visibleGenres = genres.filter(genre =>
      genre.toLowerCase().startsWith(searchString.toLowerCase())
    );
    return visibleGenres;
  };

  getDropDownOptions = () => {
    const visibleGenres = genres.map((genre, index) => {
      const dropDownObject = {
        key: index,
        text: genre,
        value: genre
      };
      return dropDownObject;
    });
    return visibleGenres;
  };

  // render
  render() {
    const {
      t,
      updating,
      wizardMode,
      header,
      subheader,
      autoUpdate,
      hideHeader,
      dropDown,
      initialValue
    } = this.props;
    const {selectedGenres, searchString} = this.state;
    const visibleGenres = this.getVisibleGenres();
    if (dropDown) {
      return (
        <Dropdown
          key='select-genre-dropdown'
          placeholder={t('SelectGenres')}
          defaultValue={initialValue}
          multiple
          search
          selection
          options={this.getDropDownOptions()}
          onChange={this.handleDropdownChange}
        />
      );
    }
    return (
      <>
        <div>
          {!hideHeader && (
            <Header
              key='header'
              size='small'
              content={header || t('WhichGenresDoYouPrefer')}
              subheader={subheader || t('OnYourProfile')}
            />
          )}
          <Input
            key='filter'
            fluid
            placeholder={t('Filter')}
            transparent
            onChange={this.handleFilterChange}
            onKeyDown={this.handleFilterKey}
            value={searchString}
          />
          <div style={{overflow: 'auto', maxHeight: '50vh'}}>
            <Form.Field key='selector'>
              {visibleGenres &&
                visibleGenres.map(genre => (
                  <Label
                    key={genre}
                    circular
                    style={{marginRight: 5, marginTop: 5, cursor: 'pointer'}}
                    color={
                      selectedGenres.findIndex(sg => genre === sg) > -1
                        ? 'teal'
                        : undefined
                    }
                    data-genre={genre}
                    onClick={this.handleGenreClick}>
                    {genre}
                  </Label>
                ))}
            </Form.Field>
          </div>
          {!autoUpdate && (
            <Button
              key='next'
              style={{marginTop: 10}}
              compact
              floated='right'
              content={wizardMode ? t('Next') : t('Save')}
              loading={!!updating}
              disabled={!selectedGenres || selectedGenres.length === 0}
              onClick={this.submit}
            />
          )}
        </div>
      </>
    );
  }
}

export default withTranslation()(GenresGetter);
