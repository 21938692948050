import React, { Component } from 'react';

import {
  Feed, Visibility, Icon, Menu, Container, Loader, Segment
} from 'semantic-ui-react'

// redux
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { withRouter } from 'react-router-dom';

// i18n
import { withTranslation } from 'react-i18next';

import FeedEvent from './feedEvent';
import { authUtil } from '../../utils';
import { PaddedWrapper } from '../../style';

class BetaReaderFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feed: [],
      isLoading: false,
      isUpdating: false
    };
  }

  componentDidMount() {
    this.getFeed();
  }

  getFeed = async () => {
    const idToken = await authUtil.getFreshIdToken();
    this.setState({
      isLoading: true
    }, () => {
      fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/feed`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.error);
          }
          return res.json();
        })
        .then((feed) => {
          if (feed) {
            this.setState({
              feed
            });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.setState({
            isLoading: false
          });
        });
    });
  }

  updateFeed = () => {
    const { idToken } = this.props;
    const { feed } = this.state;
    this.setState({
      isUpdating: true
    }, () => {
      fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/feed?skip=${feed.length}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.error);
          }
          return res.json();
        })
        .then((moreFeed) => {
          if (moreFeed) {
            this.setState({
              feed: [...feed, ...moreFeed]
            });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.setState({
            isUpdating: false
          });
        });
    });
  }

  render() {
    const { t } = this.props;
    const { feed, isUpdating, isLoading } = this.state;

    if (isLoading) {
      return <Loader active />;
    }

    const feedArray = feed.filter(event => !!event._id);

    if (feedArray.length === 0) {
      return (
        <PaddedWrapper>
          <Segment basic content={t('NothingHere')} />
        </PaddedWrapper>
      );
    }
    return (
      <PaddedWrapper>
        <Feed>
          {
            feedArray.filter(event => !!event._id)
              .map(event => (
                <FeedEvent
                  key={event._id}
                  event={event}
                />
              ))
          }
        </Feed>
        {
          feed.length > 0 && !feed[feed.length - 1].isLast && (
            <Visibility
              offset={[10, 10]}
              once={false}
              onTopVisible={this.updateFeed}
              as={Menu}
              text
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Menu.Item
                onClick={this.updateFeed}
                content={(
                  <span>
                    {
                      !isUpdating && t('LoadMore')
                    }
                    {
                      isUpdating && (
                        <Icon name='spinner' loading />
                      )
                    }
                  </span>
                )}
              />
            </Visibility>
          )
        }
      </PaddedWrapper>
    );
  }
}

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(BetaReaderFeed)));
